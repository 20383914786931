import _ from "lodash";

export const optionLabel = (option: any, key: string = "name") =>
  _.get(option, key);

export const optionSelected = (option: any, value: any, key: string = "id") =>
  /*eslint-disable eqeqeq*/
  _.get(option, key) == _.get(value, key);

export const postalLabel = (option: any) => {
  return `${option.urban} ${option.sub_district} ${option.city} ${option.postal_code}`;
};

export const transformConstantToOptions = (constant: {
  [key: string]: string;
}) =>
  Object.keys(constant).map((key) => ({
    key,
    value: constant[key],
  }));

export const optionValueFromConstant = (
  key: string,
  constant: {
    [key: string]: string;
  }
) => ({
  key,
  value: constant[key],
});
