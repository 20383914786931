import React, { useEffect } from "react";
import { Box, Button, Grid } from "@material-ui/core";
import { VuiSearch } from "../../../../../../@VodeaUI/components";
import {
  $clone,
  $cloneState,
  mapDataTableParams,
} from "../../../../../../utilities/helpers/global";
import { useState } from "@hookstate/core/dist";
import VuiDataTable, {
  Column,
  Options,
} from "../../../../../../@VodeaUI/VuiDataTable/Index";
import { defaultDataTableOption } from "../../../../../../utilities/Option";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import VuiSelect from "../../../../../../@VodeaUI/components/Input/VuiSelect/Index";
import People from "../../../../../../layouts/Apps/People";
import VuiCardContent from "../../../../../../@VodeaUI/components/VuiCardContent";
import EmploymentDataRepository from "../../../../../../repositories/EmploymentDataRepository";
import _ from "lodash";
import { useIsMounted } from "../../../../../../utilities/helpers/hooks";
import { useSelector } from "react-redux";

const EmploymentData: React.FC<any> = () => {
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const { user } = useSelector(({ vodea }: any) => {
    return {
      user: vodea.auth.user,
    };
  });

  const columns = useState<Column[]>([
    {
      key: "valid_from",
      label: "Effective Date",
      type: "date",
      dateFormat: "DD MMMM YYYY",
    },
    {
      key: "name",
      label: "Status",
      render: (row) =>
        _.get(row, "user.employment_status_user.employment_status.name", "-"),
    },
    {
      key: "department",
      label: "Department",
      render: (row) => _.get(row, "user.role_user.role.department.name", "-"),
    },
    {
      key: "position",
      label: "Position",
      render: (row) => _.get(row, "user.role_user.role.name", "-"),
    },
    {
      key: "id",
      label: "",
      sortable: false,
      render: (row) => {
        return (
          <Box display="flex" justifyContent={"flex-end"}>
            <Link to={`detail/${row.id}`}>
              <Button className="btnView" variant="contained" color="primary">
                View
              </Button>
            </Link>
          </Box>
        );
      },
    },
  ]);

  const orderBy = useState("all");
  const loading = useState(false);
  const options = useState<Options>({
    ...defaultDataTableOption,
    sortedBy: "desc",
    orderBy: "created_at",
  });
  const tableData = useState<any[]>([]);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = () => {
    loading.set(true);

    EmploymentDataRepository.all({
      ...mapDataTableParams($cloneState(options)),
      with: [
        "user.roleUser.role.department",
        "user.employmentStatusUser.employmentStatus",
      ],
      user: user.id,
    })
      .then((resp: any) => {
        if (isMounted.current) {
          tableData.set(resp.data.data);
          options.page.set(resp.meta.current_page - 1);
          options.total.set(resp.meta.total);
          loading.set(false);
        }
      })
      .catch((err: any) => {
        if (isMounted.current) {
          loading.set(false);
        }
      });
  };

  const orderByOptions = [
    {
      key: "all",
      label: "All",
    },
    {
      key: "a-z",
      label: "A-Z",
    },
  ];

  return (
    <People>
      <div>
        <Grid item xs={12}>
          <VuiCardContent title="Employment Data">
            <Box p={2}>
              <Grid item xs={12}>
                <Grid
                  container
                  spacing={3}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Grid item>
                    <VuiSearch
                      state={options.search}
                      callback={() => {
                        options.page.set(0);
                        loadData();
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <VuiSelect
                      label="Category"
                      state={orderBy}
                      options={orderByOptions}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Grid item xs={12}>
              <VuiDataTable
                loading={loading.get()}
                columns={columns.value}
                options={$clone(options.value)}
                data={tableData.value}
                onChangeOptions={(values: Options) => {
                  options.set(values);
                  loadData();
                }}
              />
            </Grid>
          </VuiCardContent>
        </Grid>
      </div>
    </People>
  );
};

export default EmploymentData;
