import React, { useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { VuiSearch } from "../../../@VodeaUI/components";
import {
  Add as AddIcon,
  BlockOutlined,
  CheckCircleOutlineOutlined,
  EditOutlined as EditOutlinedIcon,
} from "@material-ui/icons";
import { $clone } from "../../../utilities/helpers/global";
import { useState } from "@hookstate/core/dist";
import VuiDataTable, {
  Column,
  Options,
} from "../../../@VodeaUI/VuiDataTable/Index";
import { defaultDataTableOption } from "../../../utilities/Option";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import VuiSelect from "../../../@VodeaUI/components/Input/VuiSelect/Index";
import { useStyles } from "../../../components/Status/style";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import MuiButton from "../../../components/atoms/MuiButton";
import { TitleHeader } from "../../../components/molecules";

const Facility: React.FC<any> = () => {
  const classes = useStyles();
  const columns = useState<Column[]>([
    { key: "created_at", label: "Request Date" },
    { key: "resource", label: "Resource" },
    { key: "participant", label: "Participant" },
    {
      key: "status",
      label: "Status",
      sortable: false,
      render: (row) => {
        return (
          <div className={classes.statusWrapper}>
            <div className={row.status}>{row.status}</div>
          </div>
        );
      },
    },
    {
      key: "id",
      label: "",
      sortable: false,
      render: (row) => {
        return (
          <Box display="flex" justifyContent={"flex-end"}>
            <Link to={"/apps/facility/" + row.name}>
              <Button className="btnView" variant="contained" color="primary">
                Lihat
              </Button>
            </Link>
          </Box>
        );
      },
    },
    {
      key: "more",
      label: "",
      sortable: false,
      render: (row) => {
        return (
          <Box display="flex" justifyContent={"center"}>
            <Tooltip
              title={
                <Button variant="contained" startIcon={<DeleteOutlineIcon />}>
                  Hapus
                </Button>
              }
              interactive
              placement={"bottom-start"}
            >
              <IconButton aria-label="suspend">
                <MoreHorizOutlinedIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
  ]);

  const orderBy = useState("all");
  const loading = useState(false);
  const options = useState<Options>({
    ...defaultDataTableOption,
    sortedBy: "desc",
    orderBy: "date",
  });

  const data = [
    {
      resource: "Overtime",
      created_at: "28 December 2020",
      name: "Yonkers",
      status: "Approved",
      used_on: "28 October 2000",
      participant: "2 Days",
    },
    {
      resource: "Overtime",
      created_at: "28 December 2020",
      name: "Hartford",
      status: "Waiting",
      used_on: "28 October 2000",
      participant: "2 Days",
    },
    {
      resource: "Time Off",
      created_at: "28 December 2020",
      name: "Tampa",
      status: "Approved",
      used_on: "28 October 2000",
      participant: "2 Days",
    },
    {
      resource: "Time Off",
      created_at: "28 December 2020",
      name: "Dallas",
      status: "Waiting",
      used_on: "28 October 2000",
      participant: "2 Days",
    },
    {
      resource: "Time Off",
      created_at: "28 December 2020",
      name: "Dallas",
      status: "Waiting",
      used_on: "28 October 2000",
      participant: "2 Days",
    },
  ];
  const objParams = useParams();

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = () => {
    loading.set(false);

    if (objParams.id !== undefined) {
    }
  };

  const orderByOptions = [
    {
      key: "all",
      label: "All",
    },
    {
      key: "a-z",
      label: "A-Z",
    },
  ];

  return (
    <>
      <TitleHeader
        title={"Facility"}
        subtitle={"List of facility"}
        component={
          <Box display="flex">
            <Box marginRight={1}>
              <Link to={"/apps/facility/resource/create"}>
                <MuiButton label="Add Resource" />
              </Link>
            </Box>
            <Box>
              <Link to={"/apps/facility/booking/create"}>
                <MuiButton label="Request Booking" />
              </Link>
            </Box>
          </Box>
        }
      />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper variant="elevation" elevation={1}>
            <Box p={2}>
              <Grid
                container
                spacing={3}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Grid item>
                  <VuiSearch
                    state={options.search}
                    callback={() => {
                      options.page.set(0);
                      loadData();
                    }}
                  />
                </Grid>
                <Grid item>
                  <VuiSelect
                    label="Urutkan"
                    state={orderBy}
                    options={orderByOptions}
                  />
                </Grid>
              </Grid>
              <Box marginTop={2}>
                <Grid container>
                  <Grid item>
                    <Grid container spacing={3}>
                      <Grid item xs={4} md={4}>
                        <VuiSelect
                          label="Category"
                          state={orderBy}
                          options={orderByOptions}
                        />
                      </Grid>
                      <Grid item xs={4} md={4}>
                        <VuiSelect
                          label="Year"
                          state={orderBy}
                          options={orderByOptions}
                        />
                      </Grid>
                      <Grid item xs={4} md={4}>
                        <VuiSelect
                          label="Resource"
                          state={orderBy}
                          options={orderByOptions}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Box>

            <VuiDataTable
              loading={loading.get()}
              columns={columns.value}
              options={$clone(options.value)}
              data={data}
              onChangeOptions={(values: Options) => {
                options.set(values);
                loadData();
              }}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default Facility;
