import * as yup from "yup";

export const reimbursementItemSchema = () => {
  return yup.object().shape({
    reimbursement_formula_policy_id: yup.mixed().required().label("category"),
  });
};

export const validationSchema = () => {
  let shapes = {
    user_id: yup.mixed().required().label("employee"),
    reimbursement_policy_id: yup
      .mixed()
      .required()
      .label("reimbursement policy"),
    description: yup.string().required().label("purpose"),
    date: yup.mixed().required().label("date"),
    reimbursement_request_items: yup.array().required().min(1),
    // .of(reimbursementItemSchema()),
  };

  return yup.object().shape(shapes);
};
