import { api, createCancelTokenHandler } from "../@VodeaUI";

const endPoint = () => window._env_.REACT_APP_PASSPORT_END_POINT;

const Repository = {
  all: function (params: any = null) {
    return api.get(`${endPoint()}/api/user-payroll`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.all.name].handleRequestCancellation()
          .token,
    });
  },
  show: function (id: number | string, params: any = null) {
    return api.get(`${endPoint()}/api/user-payroll/${id}`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.show.name].handleRequestCancellation()
          .token,
    });
  },
  create: function (payload: any, params: any = null) {
    return api.post(`${endPoint()}/api/user-payroll`, payload, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.create.name].handleRequestCancellation()
          .token,
    });
  },
  delete: function (id: number, params: any = null) {
    return api.delete(`${endPoint()}/api/user-payroll/${id}`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.delete.name].handleRequestCancellation()
          .token,
    });
  },
  payroll: function (id: number | string, params: any = null) {
    return api.get(`${endPoint()}/api/user-payroll/${id}`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.payroll.name].handleRequestCancellation()
          .token,
    });
  },
  bulkUpdate: function (payload: any, params: any = null) {
    return api.post(`${endPoint()}/api/employment-data/bulk-update`, payload, {
      params,
      cancelToken:
        cancelTokenHandlerObject[
          this.bulkUpdate.name
        ].handleRequestCancellation().token,
    });
  },
};

const cancelTokenHandlerObject = createCancelTokenHandler(Repository);

export default Repository;
