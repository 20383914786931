import React from "react";
import { Box, Grid, Paper, Typography } from "@material-ui/core";
import { useStyles } from "./style";
import moment from "moment";
import _ from "lodash";
import AllInclusiveIcon from "@material-ui/icons/AllInclusive";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import clsx from "clsx";

interface Props {
  data: any[];
  type: string;
  canSwipe?: boolean;
}

const BoxInformation: React.FC<Props> = ({ data, type, canSwipe = false }) => {
  const classes = useStyles();

  const addSeparatorToValue = (val: any) => {
    return parseFloat(val).toLocaleString();
  };

  return canSwipe ? (
    <div id={"swiper-wrapper"}>
      <Swiper
        spaceBetween={20}
        slidesPerView={1}
        scrollbar={{ draggable: true }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        loop={true}
      >
        {data.map((item, key) => {
          return (
            <SwiperSlide key={key}>
              <Link to={"/apps/time/time-off/time-off-request"}>
                <Paper>
                  <Box className={classes.boxWrapper}>
                    <Typography
                      variant={"subtitle1"}
                      className={clsx(classes.titleColor, classes.lineClamp2)}
                    >
                      {item.label}
                    </Typography>
                    <Typography variant={"h4"} className={classes.fontColor}>
                      <span>{type === "day" ? "" : "Rp "}</span>
                      <span>
                        {type === "day" ? (
                          item.value !== null ? (
                            item.value
                          ) : (
                            <AllInclusiveIcon />
                          )
                        ) : (
                          addSeparatorToValue(item.value)
                        )}
                      </span>
                      <span>{type === "day" ? " Days" : ""}</span>
                    </Typography>
                    <Typography
                      variant={"caption"}
                      className={classes.expiredFont}
                    >
                      Expired on{" "}
                      {item.expired
                        ? moment(item.expired).format("DD MMMM YYYY")
                        : "-"}
                    </Typography>
                  </Box>
                </Paper>
              </Link>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  ) : (
    <Grid container spacing={3} className={classes.widgetListWrapper}>
      {data.map((item, key) => {
        return (
          <Grid item xs={4} key={key}>
            <Paper>
              <Box className={classes.boxWrapper}>
                <Typography
                  variant={"subtitle1"}
                  className={clsx(classes.titleColor, classes.lineClamp2)}
                >
                  {item.label}
                </Typography>
                <Typography variant={"h4"} className={classes.fontColor}>
                  <span>{type === "day" ? "" : "Rp "}</span>
                  <span>
                    {type === "day" ? (
                      item.value !== null ? (
                        item.value
                      ) : (
                        <AllInclusiveIcon />
                      )
                    ) : (
                      addSeparatorToValue(item.value)
                    )}
                  </span>
                  <span>{type === "day" ? " Days" : ""}</span>
                </Typography>
                <Typography variant={"caption"} className={classes.expiredFont}>
                  Expired on{" "}
                  {item.expired
                    ? moment(item.expired).format("DD MMMM YYYY")
                    : "-"}
                </Typography>
              </Box>
            </Paper>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default BoxInformation;
