export const COUNTER_INCREMENT = "[COUNTER] INCREMENT";
export const COUNTER_DECREMENT = "[COUNTER] DECREMENT";
export const COUNTER_RESET = "[COUNTER] RESET";

export function increaseCounter() {
  return {
    type: COUNTER_INCREMENT,
  };
}

export function decreaseCounter() {
  return {
    type: COUNTER_DECREMENT,
  };
}

export function resetCounter() {
  return {
    type: COUNTER_RESET,
  };
}
