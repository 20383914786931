import {
  Box,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import _ from "lodash";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import VuiCardContent from "../../../../@VodeaUI/components/VuiCardContent";
import TitleForm from "../../../../components/molecules/TitleForm";
import AssetRepository from "../../../../repositories/AssetRepository";
import moment from "moment";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  MuiAutoComplete,
  MuiButton,
  MuiDatePicker,
  MuiTextField,
  NumberFieldFormat,
} from "../../../../components/atoms";
import AssetCategoryRepository from "../../../../repositories/AssetCategoryRepository";
import FooterFormAction from "../../../../components/FooterFormAction";
import { IAsset } from "../../../../interfaces/Asset";
import { useParams } from "react-router-dom";
import { useIsMounted } from "../../../../utilities/helpers/hooks";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { AxiosError, AxiosResponse } from "axios";
import { IApiResource } from "../../../../interfaces/ApiResource";
import {
  axiosErrorLoadDataHandler,
  handleAxiosErrorSave,
} from "../../../../utilities/helpers/axios-error.helper";
import { formatFormData } from "../../../../utilities/helpers/form";
import { validationSchema } from "./validation";
import Loading from "../../../../components/Loading";
import useTableStyles from "../../../../styles/use-table.style";
import { handleAxiosSuccessSave } from "../../../../utilities/helpers/axios-success.helper";
import { useNavigate } from "react-router";

const attributeDefaultValues = {
  key: "",
  value: "",
};

const defaultValues: IAsset = {
  name: "",
  number: "",
  acquisition_cost: 0,
  description: "",
  acquisition_date: moment(),
  asset_category_id: null,
  attributes: [],
};

const AssetForm: FC = () => {
  const { id } = useParams();
  const isMounted = useIsMounted();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const tableStyles = useTableStyles();
  const title = id ? "Edit Asset" : "Add Asset";
  const [data, setData] = useState<IAsset>(defaultValues);
  const [isFetchingData, setIsFetchingData] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const loadData = useCallback(async () => {
    if (!id) return;
    if (isMounted.current) setIsFetchingData(true);

    let params: any = {};
    Object.assign(params, {
      with: ["assetCategory"],
    });

    await AssetRepository.show(id, params)
      .then((response: AxiosResponse<IApiResource<IAsset>>) => {
        const { data: responseData } = response.data;
        if (isMounted.current) {
          Object.assign(responseData, {
            asset_category_id: responseData.asset_category,
            attributes: [...responseData.attributes, attributeDefaultValues],
          });
          setIsFetchingData(false);
          setData(responseData);
        }
      })
      .catch((error: AxiosError) => {
        if (isMounted.current) setIsFetchingData(false);
        axiosErrorLoadDataHandler(error, enqueueSnackbar);
      });
  }, []);

  const { control, errors, handleSubmit, setError, reset } = useForm<IAsset>({
    mode: "onChange",
    defaultValues: useMemo(() => {
      (async () => {
        await loadData();
      })();
      return data;
    }, [id, loadData]),
    resolver: yupResolver(validationSchema()),
  });

  useEffect(() => {
    reset(data);
  }, [data]);

  const {
    fields: attributeFields,
    append: attributeAppend,
    remove: attributeRemove,
  } = useFieldArray({
    control,
    name: "attributes",
  });

  const handleAddAttribute = (
    firstInit: boolean = false,
    index: number | null = null
  ) => {
    if (firstInit) {
      attributeAppend(attributeDefaultValues);
    } else {
      if (index !== null && index == attributeFields.length - 1) {
        attributeAppend(attributeDefaultValues);
      }
    }
  };
  const handleRemoveAttribute = (index: number) => attributeRemove(index);

  useMemo(() => {
    handleAddAttribute(true);
  }, []);

  const onSubmit = async (formData: IAsset) => {
    if (isMounted.current) setLoading(true);
    const formattedFormData: IAsset = formatFormData(formData);
    formattedFormData?.attributes.splice(-1);

    if (id) {
      await AssetRepository.update(id, formattedFormData)
        .then((response: AxiosResponse<IApiResource<IAsset>>) => {
          if (isMounted.current) setLoading(false);
          handleAxiosSuccessSave(
            response,
            enqueueSnackbar,
            navigate,
            "/apps/assets"
          );
        })
        .catch((error: AxiosError<IApiResource<IAsset>>) => {
          if (isMounted.current) setLoading(false);
          handleAxiosErrorSave(error, setError, enqueueSnackbar);
        });
    } else {
      await AssetRepository.create(formattedFormData)
        .then((response: AxiosResponse<IApiResource<IAsset>>) => {
          if (isMounted.current) setLoading(false);
          handleAxiosSuccessSave(
            response,
            enqueueSnackbar,
            navigate,
            "/apps/assets"
          );
        })
        .catch((error: AxiosError<IApiResource<IAsset>>) => {
          if (isMounted.current) setLoading(false);
          handleAxiosErrorSave(error, setError, enqueueSnackbar);
        });
    }
  };

  return (
    <>
      <TitleForm title={title} withBackUrl={true} />

      {isFetchingData ? <Loading /> : null}
      <Box style={isFetchingData ? { display: "none" } : {}}>
        <Grid container spacing={3}>
          <Grid item md={8} xs={12}>
            <VuiCardContent title="Information">
              <Box p={2}>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <Controller
                      render={({ value, name, onChange }) => (
                        <MuiTextField
                          label={t("form.assetNumber.label")}
                          onChange={onChange}
                          value={value}
                          name={name}
                          error={_.has(errors, name)}
                          helperText={_.get(errors, `${name}.message`)}
                        />
                      )}
                      name="number"
                      control={control}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Controller
                      render={({ value, name, onChange }) => (
                        <MuiTextField
                          label={t("form.name.label")}
                          onChange={onChange}
                          value={value}
                          name={name}
                          error={_.has(errors, name)}
                          helperText={_.get(errors, `${name}.message`)}
                        />
                      )}
                      name="name"
                      control={control}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Controller
                      render={({ value, name, onChange }) => (
                        <MuiDatePicker
                          value={value}
                          name={name}
                          label={t("form.acquisitionDate.label")}
                          format="DD MMMM YYYY"
                          onChange={onChange}
                          error={_.has(errors, name)}
                          helperText={_.get(errors, `${name}.message`)}
                        />
                      )}
                      name="acquisition_date"
                      control={control}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Controller
                      render={({ value, name, onChange }) => (
                        <MuiTextField
                          label={t("form.acquisitionCost.label")}
                          onChange={onChange}
                          value={value}
                          error={_.has(errors, name)}
                          helperText={_.get(errors, `${name}.message`)}
                          InputProps={{
                            inputComponent: NumberFieldFormat,
                          }}
                        />
                      )}
                      name="acquisition_cost"
                      control={control}
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Controller
                      render={({ value, name, onChange }) => (
                        <MuiTextField
                          label={t("form.description.label")}
                          onChange={onChange}
                          value={value}
                          name={name}
                          error={_.has(errors, name)}
                          helperText={_.get(errors, `${name}.message`)}
                        />
                      )}
                      name="description"
                      control={control}
                    />
                  </Grid>
                </Grid>
              </Box>
            </VuiCardContent>
          </Grid>

          <Grid item md={4} xs={6}>
            <Paper>
              <VuiCardContent title="Group">
                <Box p={2}>
                  <Controller
                    render={({ value, name, onChange }) => (
                      <MuiAutoComplete
                        repository={AssetCategoryRepository}
                        methodName="all"
                        value={value}
                        onSelected={(newValue) => onChange(newValue)}
                        muiTextField={{
                          label: "Category",
                          error: _.has(errors, name),
                          helperText: _.get(errors, `${name}.message`),
                        }}
                      />
                    )}
                    name="asset_category_id"
                    control={control}
                  />
                </Box>
              </VuiCardContent>
            </Paper>
          </Grid>

          <Grid item md={8} xs={12}>
            <VuiCardContent title="Additional Field">
              <Box mb={2}>
                <TableContainer>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow className={tableStyles.tableHeadRow}>
                        <TableCell className={tableStyles.tableHead}>
                          {t("form.fieldName.label")}
                        </TableCell>
                        <TableCell className={tableStyles.tableHead}>
                          {t("form.value.label")}
                        </TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {attributeFields.map((item, index) => {
                        return (
                          <TableRow key={item.id}>
                            <TableCell className={tableStyles.tableCell}>
                              <Controller
                                name={`attributes[${index}].key`}
                                render={({ value, name, onChange }) => (
                                  <MuiTextField
                                    onChange={(newValue) => {
                                      handleAddAttribute(false, index);
                                      onChange(newValue);
                                    }}
                                    value={value}
                                    name={name}
                                    error={_.has(errors, name)}
                                    helperText={_.get(
                                      errors,
                                      `${name}.message`
                                    )}
                                  />
                                )}
                                control={control}
                                defaultValue={item.key}
                              />
                            </TableCell>

                            <TableCell className={tableStyles.tableCell}>
                              <Controller
                                name={`attributes[${index}].value`}
                                render={({ value, name, onChange }) => (
                                  <MuiTextField
                                    onChange={onChange}
                                    value={value}
                                    name={name}
                                    error={_.has(errors, name)}
                                    helperText={_.get(
                                      errors,
                                      `${name}.message`
                                    )}
                                  />
                                )}
                                control={control}
                                defaultValue={item.value}
                              />
                            </TableCell>

                            <TableCell className={tableStyles.tableCell}>
                              {attributeFields.length > 0 &&
                                index != attributeFields.length - 1 && (
                                  <IconButton
                                    onClick={() => handleRemoveAttribute(index)}
                                  >
                                    <DeleteOutlineIcon />
                                  </IconButton>
                                )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </VuiCardContent>
          </Grid>

          <FooterFormAction
            cancelUrl={"/apps/assets"}
            loading={loading}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            labelForm={"Save Asset?"}
          />
        </Grid>
      </Box>
    </>
  );
};

export default AssetForm;
