import React, { useEffect } from "react";
import { Box, Grid, TextField, Typography } from "@material-ui/core";
import { useState } from "@hookstate/core/dist";
import { useForm } from "react-hook-form";
import VuiCardContent from "../../../../../@VodeaUI/components/VuiCardContent";
import _ from "lodash";
import FooterFormAction from "../../../../../components/FooterFormAction";

const CreateBuilding: React.FC<any> = () => {
  const { errors, handleSubmit } = useForm({
    mode: "onChange",
  });

  const loading = useState(false);

  const onSubmit = (formData: any) => {
    loading.set(true);

    console.log("formData", formData);
  };

  return (
    <>
      <Grid container spacing={3} justifyContent="space-between">
        <Grid item xs={12}>
          <Typography variant="h5">Add Building</Typography>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={9}>
          <VuiCardContent title="Add Building">
            <Box p={2}>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <TextField
                    name="building_id"
                    label="Building Name"
                    variant="outlined"
                    fullWidth
                    error={_.has(errors, "building_id")}
                    helperText={_.get(errors, "building_id.message")}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    name="building_id"
                    label="Building Code"
                    variant="outlined"
                    fullWidth
                    error={_.has(errors, "building_id")}
                    helperText={_.get(errors, "building_id.message")}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    name="name"
                    label="Address"
                    variant="outlined"
                    fullWidth
                    error={_.has(errors, "name")}
                    helperText={_.get(errors, "name.message")}
                  />
                </Grid>
              </Grid>
            </Box>
          </VuiCardContent>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={9}>
          <VuiCardContent title="Contact">
            <Box p={2}>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <TextField
                    name="building_id"
                    label="Email"
                    variant="outlined"
                    fullWidth
                    error={_.has(errors, "building_id")}
                    helperText={_.get(errors, "building_id.message")}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    name="building_id"
                    label="Fax"
                    variant="outlined"
                    fullWidth
                    error={_.has(errors, "building_id")}
                    helperText={_.get(errors, "building_id.message")}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    name="building_id"
                    label="Telephone"
                    variant="outlined"
                    fullWidth
                    error={_.has(errors, "building_id")}
                    helperText={_.get(errors, "building_id.message")}
                  />
                </Grid>
              </Grid>
            </Box>
          </VuiCardContent>
        </Grid>

        <Grid item xs={9}>
          <FooterFormAction
            cancelUrl={null}
            loading={loading.get()}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default CreateBuilding;
