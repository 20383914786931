import React from "react";
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";
import { Typography, TypographyProps } from "@material-ui/core";

interface MuiTypographyProps extends TypographyProps {}

const MuiTypography = withStyles((theme: Theme) =>
  createStyles({
    root: {},
  })
)((props: MuiTypographyProps) => <Typography {...props} />);

export default React.memo(MuiTypography);
