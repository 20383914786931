import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import EmployeeRepository from "../../../repositories/EmployeeRepository";
import { AxiosResponse } from "axios";
import _ from "lodash";
import WidgetDashboard from "../../../components/Dashboard/Widget";
import Task from "../../../components/Dashboard/Task";

const Dashboard: React.FC<any> = () => {
  const [data, setData] = useState();

  useEffect(() => {
    loadData().catch(() => {});
  }, []);

  const loadData = async () => {
    let params: any = {};

    EmployeeRepository.account({
      ...params,
    })
      .then((response: AxiosResponse) => {
        setData(_.get(response, "data.data"));
      })
      .catch(() => {});
  };

  return (
    <>
      <Grid container spacing={3} justifyContent="space-between">
        <Grid item>
          <Typography variant="h5">Hello, {_.get(data, "name")}</Typography>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <WidgetDashboard />
        </Grid>
        <Grid item xs={12} md={8}>
          <Task />
        </Grid>
      </Grid>
    </>
  );
};

export default Dashboard;
