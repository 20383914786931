import VuiCardContent from "../../../../../@VodeaUI/components/VuiCardContent";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import { Controller, useForm, useFormContext } from "react-hook-form";
import _ from "lodash";
import { Autocomplete } from "@material-ui/lab";
import {
  optionLabel,
  optionSelected,
} from "../../../../../utilities/helpers/select";
import { KeyboardDatePicker } from "@material-ui/pickers";
import React, { useEffect } from "react";
import {
  constantToOptions,
  constantToOptionsWithId,
} from "../../../../../utilities/helpers/option.helper";
import { useSelector } from "react-redux";
import {
  $clone,
  $cloneState,
  mapParams,
} from "../../../../../utilities/helpers/global";
import { useState } from "@hookstate/core";
import { PayrollOptionInterface } from "../interface";
import SelectRepository from "../../../../../repositories/SelectRepository";
import { AxiosResponse } from "axios";
import { useIsMounted } from "../../../../../@VodeaUI";
import {
  BPJSKesValueOptions,
  JHTValueOptions,
  JPValueOptions,
} from "../../../../../constants";
import CurrencyFormat from "../../../../../@VodeaUI/components/Input/CurrencyFormat";

const Payroll: React.FC<any> = () => {
  const {
    watch,
    control,
    getValues,
    formState: { errors },
  } = useFormContext();
  const isMounted = useIsMounted();
  const { constant } = useSelector(({ constant }: any) => {
    return {
      constant: constant.constant.payload,
    };
  });
  const PTKPStatusOptions = constantToOptionsWithId(
    constant,
    "PAYROLL_PTKP_STATUS_TYPE_OPTIONS"
  );
  const salaryConfigurationOptions = constantToOptions(
    constant,
    "PAYROLL_SALARY_CONFIGURATION_TYPE_OPTIONS"
  );

  const taxConfigurationOptions = constantToOptions(
    constant,
    "PAYROLL_TAX_CONFIGURATION_TYPE_OPTIONS"
  );

  const prorateTypeOptions = constantToOptions(
    constant,
    "PAYROLL_PRORATE_TYPE_OPTIONS"
  );

  const option = useState<PayrollOptionInterface>({
    employmentStatus: {
      options: [],
      loading: false,
      params: {},
      loadData: async () => {
        if (isMounted.current) {
          option.employmentStatus.loading.set(true);
        }
        await SelectRepository.employmentStatus(
          mapParams($cloneState(option.employmentStatus.params))
        )
          .then(({ data }: AxiosResponse) => {
            if (isMounted.current) {
              option.employmentStatus.options.set(data.data);
              option.employmentStatus.loading.set(false);
            }
          })
          .catch(() => {});
      },
      onOpen: () => {
        if (isMounted.current) {
          option.employmentStatus.options.set([]);
          option.employmentStatus.loadData.get()();
        }
      },
    },
    role: {
      options: [],
      loading: false,
      params: {},
      loadData: async () => {
        if (isMounted.current) {
          option.role.loading.set(true);
        }

        const department = getValues("department");

        await SelectRepository.role({
          ...mapParams($cloneState(option.role.params)),
          department: department?.id,
        })
          .then(({ data }: AxiosResponse) => {
            if (isMounted.current) {
              option.role.options.set(data.data);
              option.role.loading.set(false);
            }
          })
          .catch(() => {});
      },
      onOpen: () => {
        if (isMounted.current) {
          option.role.options.set([]);
          option.role.loadData.get()();
        }
      },
    },
    department: {
      options: [],
      loading: false,
      params: {},
      loadData: async () => {
        if (isMounted.current) {
          option.department.loading.set(true);
        }

        await SelectRepository.department({
          ...mapParams($cloneState(option.department.params)),
        })
          .then(({ data }: AxiosResponse) => {
            if (isMounted.current) {
              option.department.options.set(data.data);
              option.department.loading.set(false);
            }
          })
          .catch(() => {});
      },
      onOpen: () => {
        if (isMounted.current) {
          option.department.options.set([]);
          option.department.loadData.get()();
        }
      },
    },
  });

  return (
    <>
      <Box mt={3}>
        <VuiCardContent title="Employment Data">
          <Box p={2}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Controller
                  render={({ value, name, onChange }) => (
                    <Autocomplete
                      value={value}
                      loading={option.department.loading.get()}
                      options={$clone(option.department.options.get())}
                      onOpen={option.department.onOpen.get()}
                      getOptionLabel={(option: any) => optionLabel(option)}
                      getOptionSelected={(option: any, value: any) =>
                        optionSelected(option, value)
                      }
                      onChange={(event: any, newValue: any) => {
                        onChange(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name={name}
                          label="Department"
                          variant="outlined"
                          error={_.has(errors, `department`)}
                          helperText={_.get(errors, `department.message`)}
                        />
                      )}
                    />
                  )}
                  name={`department`}
                  control={control}
                  defaultValue={null}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Controller
                  render={({ value, name, onChange }) => (
                    <Autocomplete
                      value={value}
                      loading={option.role.loading.get()}
                      options={$clone(option.role.options.get())}
                      onOpen={option.role.onOpen.get()}
                      getOptionLabel={(option: any) => optionLabel(option)}
                      getOptionSelected={(option: any, value: any) =>
                        optionSelected(option, value)
                      }
                      onChange={(event: any, newValue: any) => {
                        onChange(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name={name}
                          label="Position"
                          variant="outlined"
                          error={_.has(errors, `position`)}
                          helperText={_.get(errors, `position.message`)}
                        />
                      )}
                    />
                  )}
                  name={`position`}
                  control={control}
                  defaultValue={null}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  render={({ value, name, onChange }) => (
                    <KeyboardDatePicker
                      name={name}
                      disableToolbar
                      fullWidth
                      variant="inline"
                      inputVariant="outlined"
                      label="Joined Date"
                      format="DD MMMM YYYY"
                      error={_.has(errors, "joined_date")}
                      helperText={_.get(errors, "joined_date.message")}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                  name="joined_date"
                  control={control}
                  defaultValue={new Date()}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  render={({ value, name, onChange }) => (
                    <Autocomplete
                      value={value}
                      loading={option.employmentStatus.loading.get()}
                      options={$clone(option.employmentStatus.options.get())}
                      onOpen={option.employmentStatus.onOpen.get()}
                      getOptionLabel={(option: any) => optionLabel(option)}
                      getOptionSelected={(option: any, value: any) =>
                        optionSelected(option, value)
                      }
                      onChange={(event: any, newValue: any) => {
                        onChange(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name={name}
                          label="Employment Status"
                          variant="outlined"
                          error={_.has(errors, `employment_status`)}
                          helperText={_.get(
                            errors,
                            `employment_status.message`
                          )}
                        />
                      )}
                    />
                  )}
                  name={`employment_status`}
                  control={control}
                  defaultValue={null}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Controller
                  render={({ value, name, onChange }) => (
                    <TextField
                      name={name}
                      label="Basic Salary"
                      variant="outlined"
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                      value={value}
                      fullWidth
                      // type={"number"}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">Rp </InputAdornment>
                        ),
                        inputComponent: CurrencyFormat,
                      }}
                      error={_.has(errors, "basic_salary")}
                      helperText={_.get(errors, "basic_salary.message")}
                    />
                  )}
                  name={`basic_salary`}
                  control={control}
                  defaultValue={""}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Controller
                  render={({ value, name, onChange }) => (
                    <Autocomplete
                      value={value}
                      options={taxConfigurationOptions}
                      getOptionLabel={(option: any) => optionLabel(option)}
                      getOptionSelected={(option: any, value: any) =>
                        optionSelected(option, value)
                      }
                      onChange={(event: any, newValue: any) => {
                        onChange(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name={name}
                          label="Payroll Calculation Method"
                          variant="outlined"
                          error={_.has(errors, `payroll_calculation_method`)}
                          helperText={_.get(
                            errors,
                            `payroll_calculation_method.message`
                          )}
                        />
                      )}
                    />
                  )}
                  name={`payroll_calculation_method`}
                  control={control}
                  defaultValue={null}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Controller
                  render={({ value, name, onChange }) => (
                    <Autocomplete
                      value={value}
                      options={salaryConfigurationOptions}
                      getOptionLabel={(option: any) => optionLabel(option)}
                      getOptionSelected={(option: any, value: any) =>
                        optionSelected(option, value)
                      }
                      onChange={(event: any, newValue: any) => {
                        onChange(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name={name}
                          label="Tax Setting"
                          variant="outlined"
                          error={_.has(errors, `salary_configuration_type`)}
                          helperText={_.get(
                            errors,
                            `salary_configuration_type.message`
                          )}
                        />
                      )}
                    />
                  )}
                  name={`salary_configuration_type`}
                  control={control}
                  defaultValue={null}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  render={({ value, name, onChange }) => (
                    <Autocomplete
                      value={value}
                      options={PTKPStatusOptions}
                      getOptionLabel={(option: any) => optionLabel(option)}
                      getOptionSelected={(option: any, value: any) =>
                        optionSelected(option, value)
                      }
                      onChange={(event: any, newValue: any) => {
                        onChange(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name={name}
                          label="PTKP Status"
                          variant="outlined"
                          error={_.has(errors, `ptkp_status`)}
                          helperText={_.get(errors, `ptkp_status.message`)}
                        />
                      )}
                    />
                  )}
                  name={`ptkp_status`}
                  control={control}
                  defaultValue={null}
                />
              </Grid>
            </Grid>
          </Box>
        </VuiCardContent>
      </Box>
      <Box mt={3}>
        <VuiCardContent title="Prorate">
          <Box p={2}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Controller
                  render={({ value, name, onChange }) => (
                    <Autocomplete
                      value={value}
                      options={prorateTypeOptions}
                      getOptionLabel={(option: any) => optionLabel(option)}
                      getOptionSelected={(option: any, value: any) =>
                        optionSelected(option, value)
                      }
                      onChange={(event: any, newValue: any) => {
                        onChange(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name={name}
                          label="Prorate Type"
                          variant="outlined"
                          error={_.has(errors, `prorate_type`)}
                          helperText={_.get(errors, `prorate_type.message`)}
                        />
                      )}
                    />
                  )}
                  name={`prorate_type`}
                  control={control}
                  defaultValue={null}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  render={({ value, name, onChange }) => (
                    <FormControlLabel
                      label="Calculate Public Holiday"
                      control={
                        <Checkbox
                          checked={value}
                          onChange={(e: any) => onChange(e.target.checked)}
                          name={name}
                          color="primary"
                        />
                      }
                    />
                  )}
                  name="calculate_public_holiday"
                  control={control}
                  defaultValue={false}
                />
              </Grid>
            </Grid>
          </Box>
        </VuiCardContent>
      </Box>
      <Box mt={3}>
        <VuiCardContent title="BPJS Configuration">
          <Box p={2}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Controller
                  render={({ value, name, onChange }) => (
                    <TextField
                      name={name}
                      label="BPJS Ketenagakerjaan Number"
                      variant="outlined"
                      onChange={onChange}
                      value={value}
                      fullWidth
                      error={_.has(errors, "bpjs_number")}
                      helperText={_.get(errors, "bpjs_number.message")}
                    />
                  )}
                  name={`bpjs_number`}
                  control={control}
                  defaultValue={""}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  render={({ value, name, onChange }) => (
                    <Autocomplete
                      value={value}
                      loading={false}
                      options={[]}
                      getOptionLabel={(option: any) => optionLabel(option)}
                      getOptionSelected={(option: any, value: any) =>
                        optionSelected(option, value)
                      }
                      onChange={(event: any, newValue: any) => {
                        onChange(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name={name}
                          label="BPJS Ketenagakerjaan Program"
                          variant="outlined"
                          error={_.has(errors, `bpjs_program`)}
                          helperText={_.get(errors, `bpjs_program.message`)}
                        />
                      )}
                    />
                  )}
                  name={`bpjs_program`}
                  control={control}
                  defaultValue={null}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  render={({ value, name, onChange }) => (
                    <Autocomplete
                      value={value}
                      loading={false}
                      options={JHTValueOptions}
                      getOptionLabel={(option: any) => optionLabel(option)}
                      getOptionSelected={(option: any, value: any) =>
                        optionSelected(option, value)
                      }
                      onChange={(event: any, newValue: any) => {
                        onChange(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name={name}
                          label="JHT Covered by Company"
                          variant="outlined"
                          error={_.has(errors, `jht`)}
                          helperText={_.get(errors, `jht.message`)}
                        />
                      )}
                    />
                  )}
                  name={`jht`}
                  control={control}
                  defaultValue={null}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  render={({ value, name, onChange }) => (
                    <Autocomplete
                      value={value}
                      loading={false}
                      options={JPValueOptions}
                      getOptionLabel={(option: any) => optionLabel(option)}
                      getOptionSelected={(option: any, value: any) =>
                        optionSelected(option, value)
                      }
                      onChange={(event: any, newValue: any) => {
                        onChange(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name={name}
                          label="JP Covered by Company"
                          variant="outlined"
                          error={_.has(errors, `jp`)}
                          helperText={_.get(errors, `jp.message`)}
                        />
                      )}
                    />
                  )}
                  name={`jp`}
                  control={control}
                  defaultValue={null}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Controller
                  render={({ value, name, onChange }) => (
                    <KeyboardDatePicker
                      name={name}
                      disableToolbar
                      fullWidth
                      variant="inline"
                      minDate={new Date("2020-01-01")}
                      inputVariant="outlined"
                      label="BPJS Ketenagakerjaan Effective Date"
                      format="DD MMMM YYYY"
                      error={_.has(errors, "bpjs_kes_valid_from")}
                      helperText={_.get(errors, "bpjs_kes_valid_from.message")}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                  name="bpjs_kes_valid_from"
                  control={control}
                  defaultValue={new Date()}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  render={({ value, name, onChange }) => (
                    <TextField
                      name={name}
                      label="BPJS Kesehatan Number"
                      variant="outlined"
                      onChange={onChange}
                      value={value}
                      fullWidth
                      error={_.has(errors, "bpjs_kes_number")}
                      helperText={_.get(errors, "bpjs_kes_number.message")}
                    />
                  )}
                  name={`bpjs_kes_number`}
                  control={control}
                  defaultValue={""}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  render={({ value, name, onChange }) => (
                    <Autocomplete
                      value={value}
                      loading={false}
                      options={[]}
                      // onOpen={option.unitOfMeasure.onOpen.get()}
                      getOptionLabel={(option: any) => optionLabel(option)}
                      getOptionSelected={(option: any, value: any) =>
                        optionSelected(option, value)
                      }
                      onChange={(event: any, newValue: any) => {
                        onChange(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name={name}
                          label="BPJS Kesehatan Program"
                          variant="outlined"
                          error={_.has(errors, `bpjs_kesehatan_program`)}
                          helperText={_.get(
                            errors,
                            `bpjs_kesehatan_program.message`
                          )}
                        />
                      )}
                    />
                  )}
                  name={`bpjs_kesehatan_program`}
                  control={control}
                  defaultValue={null}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  render={({ value, name, onChange }) => (
                    <Autocomplete
                      value={value}
                      loading={false}
                      options={BPJSKesValueOptions}
                      getOptionLabel={(option: any) => optionLabel(option)}
                      getOptionSelected={(option: any, value: any) =>
                        optionSelected(option, value)
                      }
                      onChange={(event: any, newValue: any) => {
                        onChange(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name={name}
                          label="BPJS Kesehatan Covered by Company"
                          variant="outlined"
                          error={_.has(errors, `bpjs_kes_company_paid`)}
                          helperText={_.get(
                            errors,
                            `bpjs_kes_company_paid.message`
                          )}
                        />
                      )}
                    />
                  )}
                  name={`bpjs_kes_company_paid`}
                  control={control}
                  defaultValue={null}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  render={({ value, name, onChange }) => (
                    <KeyboardDatePicker
                      name={name}
                      disableToolbar
                      fullWidth
                      variant="inline"
                      minDate={new Date("2020-01-01")}
                      inputVariant="outlined"
                      label="BPJS Kesehatan Effective Date"
                      format="DD MMMM YYYY"
                      error={_.has(errors, "bpjs_kes_valid_from")}
                      helperText={_.get(errors, "bpjs_kes_valid_from.message")}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                  name="bpjs_kes_valid_from"
                  control={control}
                  defaultValue={new Date()}
                />
              </Grid>
            </Grid>
          </Box>
        </VuiCardContent>
      </Box>
    </>
  );
};

export default Payroll;
