import React, { useCallback, useMemo, useState } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { useParams } from "react-router";
import VuiDataTable, { Column } from "../../../../@VodeaUI/VuiDataTable/Index";
import TitleForm from "../../../../components/molecules/TitleForm";
import { mapDataTableParams } from "../../../../utilities/helpers/global";
import VuiCardContent from "../../../../@VodeaUI/components/VuiCardContent";
import { useSnackbar } from "notistack";
import AssetRepository from "../../../../repositories/AssetRepository";
import { AxiosError, AxiosResponse } from "axios";
import { IApiResource } from "../../../../interfaces/ApiResource";
import { IAsset } from "../../../../interfaces/Asset";
import { axiosErrorLoadDataHandler } from "../../../../utilities/helpers/axios-error.helper";
import useIsMounted from "../../../../utilities/hooks/use-is-mounted.hook";
import AssetLogRepository from "../../../../repositories/AssetLogRepository";
import { IDataTableParams } from "../../../../interfaces/IDataTableParams";
import {
  defaultDataTableParams,
  defaultDateOptions,
  sortedByOptions,
} from "../../../../constants";
import moment from "moment";
import Date from "../../../../components/atoms/Date";
import Loading from "../../../../components/Loading";
import { IAssetLog } from "../../../../interfaces/AssetLog";
import { MuiAutoComplete, MuiSearch } from "../../../../components/atoms";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { FooterAction } from "../../../../components/molecules";
import {
  useMonthOption,
  useYearOption,
} from "../../../../utilities/helpers/option.helper";
import { IConstantOption } from "../../../../interfaces/ConstantOption";

const defaultValues: IAsset = {
  name: "",
  number: "",
  acquisition_cost: 0,
  description: "",
  acquisition_date: moment(),
  asset_category_id: null,
  attributes: [],
};

interface IDataQuery extends IDataTableParams {
  year?: null | IConstantOption;
  month?: null | IConstantOption;
}

const DetailAsset: React.FC<any> = () => {
  const { id } = useParams();
  const isMounted = useIsMounted();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const monthOptions = useMonthOption();
  const yearOptions = useYearOption();
  const [isFetchingData, setIsFetchingData] = useState<boolean>(false);
  const [totalTableData, setTotalTableData] = useState<number>(0);
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [data, setData] = useState<IAsset>(defaultValues);
  const [tableParams, setTableParams] = useState<IDataQuery>({
    ...defaultDataTableParams,
    sortedBy: "desc",
    orderBy: "date",
    month: defaultDateOptions,
    year: defaultDateOptions,
  });
  const [tableData, setTableData] = useState<any[]>([]);

  const loadData = useCallback(async () => {
    if (!id) return;
    if (isMounted.current) setIsFetchingData(true);

    let params: any = {};
    Object.assign(params, {
      with: ["assetCategory"],
    });

    await AssetRepository.show(id, params)
      .then((response: AxiosResponse<IApiResource<IAsset>>) => {
        const { data: responseData } = response.data;
        if (isMounted.current) {
          setIsFetchingData(false);
          setData(responseData);
        }
      })
      .catch((error: AxiosError) => {
        if (isMounted.current) setIsFetchingData(false);
        axiosErrorLoadDataHandler(error, enqueueSnackbar);
      });
  }, []);

  const loadAssetLogData = useCallback(
    async (query: IDataQuery) => {
      if (!id) return;
      if (isMounted.current) setTableLoading(true);

      let params: IDataQuery = {
        ...mapDataTableParams(query),
        asset: id,
      };

      if (query.year && query.year?.id !== "all") {
        Object.assign(params, {
          year: query.year?.id,
        });
      }

      if (query.month && query.month?.id !== "all") {
        Object.assign(params, {
          month: query.month?.id,
        });
      }

      await AssetLogRepository.all(params)
        .then((response: AxiosResponse<IApiResource<IAssetLog[]>>) => {
          const { data: responseData, meta } = response.data;
          if (isMounted.current) {
            setTableLoading(false);
            setTotalTableData(meta.total);
            setTableData(responseData);
          }
        })
        .catch((error: AxiosError) => {
          if (isMounted.current) setTableLoading(false);
          axiosErrorLoadDataHandler(error, enqueueSnackbar);
        });
    },
    [id]
  );

  useMemo(() => {
    (async () => {
      await loadData();
    })();
  }, [id]);

  useMemo(() => {
    (async () => {
      await loadAssetLogData(tableParams);
    })();
  }, [id, tableParams]);

  const handleTableSearch = useCallback((value: string) => {
    if (isMounted.current) {
      setTableParams((prevState) => {
        return {
          ...prevState,
          search: value,
          page: 0,
        };
      });
    }
  }, []);

  const tableColumns: Column[] = [
    { key: "date", label: "Date", type: "date", dateFormat: "DD MMMM YYYY" },
    { key: "description", label: "Information" },
  ];

  return (
    <>
      <TitleForm title="Asset" withBackUrl={true} />

      {isFetchingData ? <Loading /> : null}
      <Box style={isFetchingData ? { display: "none" } : {}}>
        <Grid container spacing={3}>
          <Grid item sm={12} md={4}>
            <VuiCardContent title={"Information"}>
              <Box p={2}>
                <Grid container spacing={2}>
                  <Grid item md={12}>
                    <Typography variant="caption">Name</Typography>
                    <Typography variant="subtitle2">{data.name}</Typography>
                  </Grid>
                  <Grid item md={12}>
                    <Typography variant="caption">Asset Category</Typography>
                    <Typography variant="subtitle2">
                      {data.asset_category?.name}
                    </Typography>
                  </Grid>
                  <Grid item md={12}>
                    <Typography variant="caption">Received Date</Typography>
                    <Typography variant="subtitle2">
                      <Date data={data.acquisition_date} />
                    </Typography>
                  </Grid>
                  {/*<Grid item md={12}>*/}
                  {/*  <Typography variant="caption">Return On</Typography>*/}
                  {/*  <Typography variant="subtitle2">-</Typography>*/}
                  {/*</Grid>*/}
                </Grid>
              </Box>
            </VuiCardContent>
          </Grid>

          <Grid item sm={12} md={8}>
            <VuiCardContent title={"Asset Log"}>
              <Box p={2}>
                <Grid
                  container
                  spacing={3}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Grid item>
                    <MuiSearch
                      value={tableParams.search}
                      callback={handleTableSearch}
                    />
                  </Grid>
                  <Grid item md={4}>
                    <MuiAutoComplete
                      isAsync={false}
                      value={sortedByOptions.find(
                        (option) => option.id === tableParams.sortedBy
                      )}
                      constantOptions={sortedByOptions}
                      onSelected={(newValue) => {
                        setTableParams({
                          ...tableParams,
                          sortedBy: newValue.id,
                        });
                      }}
                      muiTextField={{
                        label: t("form.sort.label"),
                      }}
                    />
                  </Grid>
                </Grid>

                <Box marginTop={2}>
                  <Grid container spacing={3}>
                    <Grid item md={4} sm={12}>
                      <MuiAutoComplete
                        isAsync={false}
                        value={tableParams.month}
                        constantOptions={monthOptions}
                        onSelected={(newValue) => {
                          setTableParams({
                            ...tableParams,
                            page: 0,
                            month: newValue,
                          });
                        }}
                        muiTextField={{
                          label: t("form.month.label"),
                        }}
                      />
                    </Grid>
                    <Grid item md={4} sm={12}>
                      <MuiAutoComplete
                        isAsync={false}
                        value={tableParams.year}
                        constantOptions={yearOptions}
                        onSelected={(newValue) => {
                          setTableParams({
                            ...tableParams,
                            page: 0,
                            year: newValue,
                          });
                        }}
                        muiTextField={{
                          label: t("form.year.label"),
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>

              <VuiDataTable
                loading={tableLoading}
                columns={tableColumns}
                options={{
                  ...tableParams,
                  total: totalTableData,
                }}
                data={tableData}
                onChangeOptions={(values: IDataTableParams) => {
                  setTableParams(values);
                }}
              />
            </VuiCardContent>
          </Grid>

          <FooterAction
            cancelUrl={"/apps/assets"}
            onSubmit={() => navigate("/apps/assets")}
            labelForm={"Back To Asset List"}
            submitBtnLabel={"Back"}
          />
        </Grid>
      </Box>
    </>
  );
};

export default DetailAsset;
