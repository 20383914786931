import React, { useCallback, useMemo } from "react";
import { Box, Grid, Paper } from "@material-ui/core";
import {
  $clone,
  $cloneState,
  handleAxiosError,
  mapDataTableParams,
} from "../../../../utilities/helpers/global";
import { useState } from "@hookstate/core/dist";
import VuiDataTable, {
  Column,
  Options,
} from "../../../../@VodeaUI/VuiDataTable/Index";
import {
  defaultDataTableOption,
  defaultDeleteConfirmOption,
} from "../../../../utilities/Option";
import { Link } from "react-router-dom";
import { useIsMounted } from "../../../../@VodeaUI";
import PublicHolidayRepository from "../../../../repositories/PublicHolidayRepository";
import { AxiosError, AxiosResponse } from "axios";
import { useSnackbar } from "notistack";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { useConfirm } from "material-ui-confirm";
import { TitleHeader } from "../../../../components/molecules";
import MuiButton from "../../../../components/atoms/MuiButton";
import { MuiIconButton, MuiSearch } from "../../../../components/atoms";
import { useTranslation } from "react-i18next";

const PublicHoliday: React.FC<any> = () => {
  const isMounted = useIsMounted();
  const data = useState([]);
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const loading = useState(false);
  const options = useState<Options>({
    ...defaultDataTableOption,
    sortedBy: "desc",
    orderBy: "date",
  });

  const confirm = useConfirm();
  const handleDelete = (data: any) => {
    confirm(defaultDeleteConfirmOption)
      .then(() => deleteResource(data.id))
      .catch(() => {});
  };

  const deleteResource = (id: number) => {
    const loadingKey = enqueueSnackbar("Please wait...", {
      variant: "default",
      autoHideDuration: 5000,
      preventDuplicate: true,
    });

    PublicHolidayRepository.delete(id)
      .then((resp: any) => {
        if (isMounted.current) {
          loadData();
          closeSnackbar(loadingKey);
          enqueueSnackbar(resp.data.message, {
            variant: "success",
          });
        }
      })
      .catch((e: any) => {
        if (isMounted.current) {
          closeSnackbar(loadingKey);
          handleAxiosError(e, isMounted.current, enqueueSnackbar);
        }
      });
  };

  const columns: Column[] = [
    {
      key: "name",
      label: "Name",
    },
    {
      key: "date",
      label: "Date",
      type: "date",
      dateFormat: "DD MMMM YYYY",
    },
    {
      key: "id",
      label: "",
      sortable: false,
      render: (row) => {
        return (
          <Box
            display="flex"
            justifyContent={"flex-end"}
            alignItems={"center"}
            className={"aw"}
          >
            <Link to={`/apps/setting/public-holiday/${row.id}`}>
              <MuiButton label={t("View")} />
            </Link>

            <Box ml={1}>
              <MuiIconButton onClick={() => handleDelete(row)}>
                <DeleteOutlineIcon fontSize="small" />
              </MuiIconButton>
            </Box>
          </Box>
        );
      },
    },
  ];

  const loadData = useCallback(async () => {
    loading.set(true);
    await PublicHolidayRepository.all({
      ...mapDataTableParams($cloneState(options)),
    })
      .then((response: AxiosResponse) => {
        const { data: responseData } = response;
        const { data: list, meta } = responseData;

        if (isMounted.current) {
          data.set(list);
          options.page.set(meta.current_page - 1);
          options.total.set(meta.total);
        }
        loading.set(false);
      })
      .catch((e: AxiosError) => {
        if (isMounted.current) {
          loading.set(false);
          handleAxiosError(e, isMounted.current, enqueueSnackbar);
        }
      });
  }, []);

  const handleTableSearch = useCallback((value: string) => {
    if (isMounted.current) {
      options.search.set(value);
      options.page.set(0);
      loadData().then();
    }
  }, []);

  useMemo(() => {
    (async () => {
      await loadData();
    })();
  }, []);

  return (
    <>
      <TitleHeader
        title={"Public Holiday"}
        subtitle={"List of public holiday"}
        component={
          <Link to={"/apps/setting/public-holiday/create"}>
            <MuiButton label="Add Public Holiday" size="large" />
          </Link>
        }
      />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper variant="elevation" elevation={1}>
            <Box p={2}>
              <Grid container spacing={3} alignItems={"center"}>
                <Grid item md={4} xs={12}>
                  <MuiSearch
                    value={$clone(options.search.value)}
                    callback={handleTableSearch}
                  />
                </Grid>
              </Grid>
            </Box>

            <VuiDataTable
              data={data.get()}
              columns={columns}
              options={$clone(options.value)}
              loading={loading.get()}
              onChangeOptions={(values) => {
                options.set(values);
                loadData();
              }}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default PublicHoliday;
