import React, { useCallback, useMemo } from "react";
import { useState } from "@hookstate/core";
import { Grid, Paper } from "@material-ui/core";
import { Link } from "react-router-dom";
import useIsMounted from "../../../../@VodeaUI/hooks/useIsMounted";
import VuiDataTable, {
  Column,
  Options,
} from "../../../../@VodeaUI/VuiDataTable/Index";
import {
  $clone,
  $cloneState,
  mapDataTableParams,
} from "../../../../utilities/helpers/global";
import { defaultDataTableOption } from "../../../../utilities/Option";
import AssignPolicyRepository from "../../../../repositories/AssignPolicyRepository";
import MuiButton from "../../../../components/atoms/MuiButton";
import { TitleHeader } from "../../../../components/molecules";
import { AxiosError, AxiosResponse } from "axios";
import { IApiResource } from "../../../../interfaces/ApiResource";
import { axiosErrorLoadDataHandler } from "../../../../utilities/helpers/axios-error.helper";
import { useSnackbar } from "notistack";
import { useDocumentTitle } from "../../../../@VodeaUI";

interface ITableData {
  created_at: string;
  subject_name: string;
  number: string;
  type: string;
  effective_from: string;
  id: number;
}

interface ISubject {
  id: number;
  name: string;
  valid_from: string;
}

interface IAssignPolicy {
  created_at: string;
  effective_from: string;
  id: number;
  number: string;
  subject: ISubject;
  subject_id: number;
  type: string;
}

const AssignPolicy: React.FC<any> = () => {
  useDocumentTitle("Assign Policy");
  const isMounted = useIsMounted();
  const { enqueueSnackbar } = useSnackbar();
  const options = useState<Options>({
    ...defaultDataTableOption,
    sortedBy: "desc",
    orderBy: "created_at",
  });
  const [tableData, setTableData] = React.useState<ITableData[]>([]);
  const [tableLoading, setTableLoading] = React.useState<boolean>(false);
  const columns: Column[] = [
    { key: "created_at", label: "Date", type: "date" },
    { key: "number", label: "Transaction ID" },
    { key: "subject_name", label: "Policy" },
    { key: "effective_from", label: "Start Date", type: "date" },
    {
      key: "id",
      label: "",
      sortable: false,
      render: (row) => {
        return (
          <Link to={`/apps/setting/assign-policy/${row.id}`}>
            <MuiButton label="View" size={"small"} />
          </Link>
        );
      },
    },
  ];

  const loadData = useCallback(async () => {
    if (isMounted.current) {
      setTableLoading(true);
      setTableData([]);
    }

    await AssignPolicyRepository.all({
      ...mapDataTableParams($cloneState(options)),
    })
      .then((response: AxiosResponse<IApiResource<IAssignPolicy[]>>) => {
        if (isMounted.current) {
          const { data: responseData, meta } = response.data;
          setTableData(
            responseData.map((policy) => ({
              id: policy.id,
              created_at: policy.created_at,
              subject_name: policy.subject.name,
              number: policy.number,
              type: policy.type,
              effective_from: policy.effective_from,
            }))
          );
          options.total.set(meta.total);
          setTableLoading(false);
        }
      })
      .catch((error: AxiosError) => {
        if (isMounted.current) setTableLoading(true);
        axiosErrorLoadDataHandler(error, enqueueSnackbar);
      });
  }, []);

  useMemo(() => {
    (async () => {
      await loadData();
    })();
  }, []);

  const handleTableSearch = useCallback((value: string) => {
    if (isMounted.current) {
      options.search.set(value);
      loadData().then();
    }
  }, []);

  return (
    <>
      <TitleHeader
        title={"Assign Policy"}
        subtitle={"List of policy transaction"}
        component={
          <Link to={"/apps/setting/assign-policy/create"}>
            <MuiButton label="Add Assign Policy" size="large" />
          </Link>
        }
      />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper variant="elevation" elevation={1}>
            {/*<Box p={2}>*/}
            {/*  <Grid*/}
            {/*    container*/}
            {/*    spacing={3}*/}
            {/*    alignItems={"center"}*/}
            {/*    justifyContent={"space-between"}*/}
            {/*  >*/}
            {/*    <Grid item>*/}
            {/*      <MuiSearch*/}
            {/*        value={$clone(options.search.value)}*/}
            {/*        callback={handleTableSearch}*/}
            {/*      />*/}
            {/*    </Grid>*/}
            {/*  </Grid>*/}
            {/*</Box>*/}

            <VuiDataTable
              loading={tableLoading}
              columns={columns}
              options={$clone(options.value)}
              data={tableData}
              onChangeOptions={(values: Options) => {
                options.set(values);
                loadData().then();
              }}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};
export default AssignPolicy;
