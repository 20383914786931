import * as actions from "../../actions/vodea";

const initialState = {
  isLogged: false,
  user: null,
};

const counter = function (state = initialState, action: any) {
  switch (action.type) {
    case actions.AUTH_SET_USER:
      return Object.assign(state, {
        isLogged: !!action.payload,
        user: action.payload,
      });
    default:
      return state;
  }
};

export default counter;
