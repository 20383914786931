import React from "react";
import { Divider, Tab, Tabs } from "@material-ui/core";
import { useStyles } from "../VuiTabs/Style";
import CustomTabLabel from "../../../components/Dashboard/Task/tabs/CustomTabLabel";

function a11yProps(index: any, name: string) {
  return {
    id: `${name}-tab-${index}`,
    "aria-controls": `${name}-tabpanel-${index}`,
  };
}

interface Props {
  name: string;
  value: number;
  tabList: string[];
  badgeCount?: any[];
  hasBadge?: boolean;
  handleTabsChange: (newValue: number) => void;
}

const VuiTabs: React.FC<Props> = ({
  name,
  value,
  tabList,
  handleTabsChange,
  hasBadge = false,
  badgeCount = [],
}) => {
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    handleTabsChange(newValue);
  };
  const classes = useStyles();

  return (
    <>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
      >
        {tabList.map((item, key) =>
          hasBadge ? (
            <Tab
              key={key}
              label={<CustomTabLabel label={item} count={badgeCount[key]} />}
              {...a11yProps(key, name)}
              className={classes.tabs}
            />
          ) : (
            <Tab
              key={key}
              label={item}
              {...a11yProps(key, name)}
              className={classes.tabs}
            />
          )
        )}
      </Tabs>
      <Divider />
    </>
  );
};

export default VuiTabs;
