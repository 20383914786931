import React from "react";
import { ButtonBase, Typography, useTheme } from "@material-ui/core";
import { useLocation } from "react-router";
import classNames from "classnames";
import { useStyles } from "../style";
import {
  SidebarMenuChildInterface,
  SidebarMenuInterface,
  SidebarSectionInterface,
  RouteRegex,
} from "../../../../interfaces";
import _ from "lodash";
import { Link } from "react-router-dom";
import { VuiIcon } from "@vodea/vodea-ui";
import { useSelector } from "react-redux";

const compareMenuWithPermission = (
  sections: Array<SidebarSectionInterface>,
  permissions: string[]
) => {
  const response: SidebarSectionInterface[] = [];

  sections.forEach((section) => {
    const menus: SidebarMenuInterface[] = [];

    section.list.forEach((menu) => {
      if (menu.children) {
        const children: SidebarMenuChildInterface[] = [];

        menu.children.forEach((child, childIndex) => {
          menu.permissions = [
            ...(Array.isArray(menu.permissions)
              ? menu.permissions
              : [menu.permissions]),
            ...(Array.isArray(child.permissions)
              ? child.permissions
              : [child.permissions]),
          ];

          if (
            child.permissions === "any" ||
            (typeof child.permissions === "string" &&
              permissions.includes(child.permissions)) ||
            (Array.isArray(child.permissions) &&
              child.permissions.filter((x: string) => permissions.includes(x))
                .length)
          ) {
            children.push(child);
          }
        });

        menu.children = children;
      }

      if (menu.permissions === "any") {
        menus.push(menu);
      } else if (
        typeof menu.permissions === "string" &&
        permissions.includes(menu.permissions)
      ) {
        menus.push(menu);
      } else if (
        Array.isArray(menu.permissions) &&
        menu.permissions.filter((x: string) => permissions.includes(x)).length
      ) {
        menus.push(menu);
      } else {
        menus.push(menu);
      }
    });

    if (menus.length) {
      response.push({
        name: section.name,
        list: menus,
      });
    }
  });

  return response;
};

interface Props {
  data: Array<SidebarSectionInterface>;
  toggleDrawer(): void;
  approvalCount?: number;
}

const user = {
  permissions: ["test"],
};

const Menu: React.FC<Props> = ({ data, toggleDrawer, approvalCount = 0 }) => {
  const location = useLocation();
  const sections = compareMenuWithPermission(data, user.permissions);
  const currentRouteName = location.pathname;
  const theme = useTheme();
  const classes = useStyles();

  const { user: userLogged } = useSelector(({ vodea }: any) => {
    return {
      user: vodea.auth.user,
    };
  });

  const inertiaClicked = () => {
    if (window.innerWidth <= theme.breakpoints.values.sm) {
      toggleDrawer();
    }
  };

  const compareRouteNameWithRegex = (routeName: any) => {
    const splitCurrentRouteName = currentRouteName.split("/");

    const routeNameWithRouteRegex = routeName.filter(
      (a: any) => typeof a.url !== "undefined" && typeof a.count !== "undefined"
    );

    const test = routeNameWithRouteRegex.map((route: any) => {
      let comparedValue = 0;

      const splitRouteNameUrl = route.url.split("/");
      for (let i = route.count; i >= 0; i--) {
        if (splitCurrentRouteName[i] === splitRouteNameUrl[i]) {
          comparedValue++;
        }
      }

      if (comparedValue - 1 === route.count) {
        return true;
      }

      return false;
    });

    return test.includes(true);
  };

  function instanceOfRouteRegex(object: any) {
    return "url" in object;
  }

  const isLinkActive = (
    routeName: Array<string | RouteRegex> | string | RouteRegex
  ) => {
    if (Array.isArray(routeName)) {
      if (routeName.includes(currentRouteName)) {
        return true;
      } else {
        return compareRouteNameWithRegex(routeName);
      }
    } else if (typeof routeName === "string") {
      return currentRouteName === routeName;
    } else if (instanceOfRouteRegex(routeName)) {
      return compareRouteNameWithRegex([routeName]);
    }
    return false;
  };

  const mapRouteNameWithChildren = (menu: SidebarMenuInterface) => {
    const result = Array.isArray(menu.routeName)
      ? _.cloneDeep(menu.routeName)
      : [_.cloneDeep(menu.routeName)];

    if (menu.children !== undefined) {
      result.push(
        ...menu.children
          .map((child: SidebarMenuChildInterface) =>
            Array.isArray(child.routeName)
              ? _.cloneDeep(child.routeName)
              : [_.cloneDeep(child.routeName)]
          )
          .reduce((accumulator, value) => accumulator.concat(value), [])
      );
    }

    return result;
  };

  return (
    <div className={classes.sidebar}>
      {sections.map((section, index) => {
        return (
          <div key={index}>
            {section.name && (
              <div className="section-title">{section.name}</div>
            )}
            <ul className="nav-list">
              {section.list.map((menu, key) => {
                return (
                  <li
                    className={classNames({
                      "nav-item": true,
                      opened:
                        menu.children &&
                        isLinkActive(mapRouteNameWithChildren(menu)),
                    })}
                    key={key}
                  >
                    {menu.type === "disabled" ? (
                      <>
                        <ButtonBase
                          onClick={inertiaClicked}
                          component={"label"}
                          className={classNames({
                            "nav-link": true,
                            active: isLinkActive(menu.routeName),
                          })}
                        >
                          <div className={`${menu.icon} nav-icon`}>
                            <VuiIcon
                              icon={menu.icon}
                              size={16}
                              color={"#383838"}
                            />
                          </div>
                          <span className="nav-title">{menu.label}</span>
                          {menu.label === "Approval" && approvalCount > 0 && (
                            <Typography
                              variant={"body2"}
                              className={classes.count}
                            >
                              {approvalCount}
                            </Typography>
                          )}
                        </ButtonBase>
                      </>
                    ) : (
                      <Link to={menu.href} onClick={inertiaClicked}>
                        <ButtonBase
                          onClick={inertiaClicked}
                          component={"label"}
                          className={classNames({
                            "nav-link": true,
                            active: isLinkActive(menu.routeName),
                          })}
                        >
                          <div className={`${menu.icon} nav-icon`}>
                            <VuiIcon
                              icon={menu.icon}
                              size={16}
                              color={"#383838"}
                            />
                          </div>
                          <span className="nav-title">{menu.label}</span>
                          {menu.label === "Approval" && approvalCount > 0 && (
                            <Typography
                              variant={"body2"}
                              className={classes.count}
                            >
                              {approvalCount}
                            </Typography>
                          )}
                        </ButtonBase>
                      </Link>
                    )}

                    {menu.children && (
                      <div className="nav-dropdown">
                        <ul className="dropdown-list">
                          {menu.children.map((child, childKey) => {
                            if (child.label === "Employee") {
                              if (
                                userLogged.role_user.role_id == 1 ||
                                userLogged.role_user.role_id == 2
                              ) {
                                return (
                                  <li className="dropdown-item" key={childKey}>
                                    <Link
                                      to={child.href}
                                      onClick={inertiaClicked}
                                    >
                                      <ButtonBase
                                        component={"label"}
                                        className={classNames({
                                          "dropdown-link": true,
                                          active: isLinkActive(child.routeName),
                                        })}
                                      >
                                        <span>{child.label}</span>
                                      </ButtonBase>
                                    </Link>
                                  </li>
                                );
                              } else null;
                            } else {
                              return (
                                <li className="dropdown-item" key={childKey}>
                                  <Link
                                    to={child.href}
                                    onClick={inertiaClicked}
                                  >
                                    <ButtonBase
                                      component={"label"}
                                      className={classNames({
                                        "dropdown-link": true,
                                        active: isLinkActive(child.routeName),
                                      })}
                                    >
                                      <span>{child.label}</span>
                                    </ButtonBase>
                                  </Link>
                                </li>
                              );
                            }
                          })}
                        </ul>
                      </div>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        );
      })}
    </div>
  );
};

export default Menu;
