const CryptoJS = require("crypto-js");

export const encryptedStr = (text: any) => {
  let cipher = CryptoJS.RC4.encrypt(JSON.stringify(text), "!z$C&F)J");

  // console.log('encryptedStr', cipher.toString());
  // decryptedStr(cipher.toString());
  let ciphermodifed = cipher.toString().replace(/\+/g, "$");

  return ciphermodifed;
};

export const decryptedStr = (text: any) => {
  let textModified = text.replace(/\$/g, "+");

  let decrypted = CryptoJS.RC4.decrypt(textModified, "!z$C&F)J");
  let decryptedData = decrypted.toString(CryptoJS.enc.Utf8);

  sessionStorage.setItem('access_token', JSON.parse(decryptedData));
  localStorage.setItem('access_token', JSON.parse(decryptedData));

  return JSON.parse(decryptedData);
};
