import React, { FC, useCallback, useEffect, useMemo } from "react";
import { Box, Grid } from "@material-ui/core";
import VuiCardContent from "../../../../../@VodeaUI/components/VuiCardContent";
import _ from "lodash";
import { Controller, useForm } from "react-hook-form";
import FooterFormAction from "../../../../../components/FooterFormAction";
import { yupResolver } from "@hookform/resolvers/yup";
import moment, { Moment } from "moment";
import { useSnackbar } from "notistack";
import PublicHolidayRepository from "../../../../../repositories/PublicHolidayRepository";
import { useIsMounted } from "../../../../../@VodeaUI";
import TitleForm from "../../../../../components/molecules/TitleForm";
import { useNavigate, useParams } from "react-router-dom";
import { validationSchema } from "../validation";
import { AxiosError, AxiosResponse } from "axios";
import { IApiResource } from "../../../../../interfaces/ApiResource";
import { useTranslation } from "react-i18next";
import { MuiDatePicker, MuiTextField } from "../../../../../components/atoms";
import { formatFormData } from "../../../../../utilities/helpers/form";
import { handleAxiosSuccessSave } from "../../../../../utilities/helpers/axios-success.helper";
import {
  axiosErrorLoadDataHandler,
  handleAxiosErrorSave,
} from "../../../../../utilities/helpers/axios-error.helper";
import { useDocumentTitle } from "../../../../../utilities/hooks/use-document-title";

interface IPublicHoliday {
  name: string;
  date_from: string | Moment;
  date_to: string | Moment;
  date: string | Moment;
}

const defaultValues: IPublicHoliday = {
  name: "",
  date_from: moment(),
  date_to: moment(),
  date: moment(),
};

const PublicHolidayForm: FC = () => {
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  useDocumentTitle(
    id
      ? "preference.publicHoliday.edit.metaTitle"
      : "preference.publicHoliday.create.metaTitle"
  );
  const title = id
    ? t("preference.publicHoliday.edit.title")
    : t("preference.publicHoliday.create.title");
  const { enqueueSnackbar } = useSnackbar();

  const [data, setData] = React.useState<IPublicHoliday>(defaultValues);
  const [loading, setLoading] = React.useState<boolean>(false);

  const loadData = useCallback(async () => {
    if (!id) {
      return;
    }

    await PublicHolidayRepository.show(id)
      .then((response: AxiosResponse<IApiResource<IPublicHoliday>>) => {
        if (isMounted.current) {
          const { data: responseData } = response.data;
          setData(responseData);
        }
      })
      .catch((error: AxiosError) => {
        axiosErrorLoadDataHandler(error, enqueueSnackbar);
      });
  }, []);

  const { reset, handleSubmit, errors, control, setError } =
    useForm<IPublicHoliday>({
      mode: "onChange",
      resolver: yupResolver(validationSchema(t)),
      defaultValues: useMemo(() => {
        (async () => {
          await loadData();
        })();
        return data;
      }, [id, loadData]),
    });

  useEffect(() => {
    reset(data);
  }, [data]);

  const onSubmit = async (data: IPublicHoliday) => {
    setLoading(true);
    const formData = formatFormData(data);

    if (id) {
      delete formData.date_from;
      delete formData.date_to;

      await PublicHolidayRepository.update(id, formData)
        .then((response: AxiosResponse<IApiResource<IPublicHoliday>>) => {
          if (isMounted.current) setLoading(false);
          handleAxiosSuccessSave(
            response,
            enqueueSnackbar,
            navigate,
            "/apps/setting/public-holiday"
          );
        })
        .catch((error: AxiosError) => {
          if (isMounted.current) setLoading(false);
          handleAxiosErrorSave(error, setError, enqueueSnackbar);
        });
    } else {
      await PublicHolidayRepository.create(formData)
        .then((response: AxiosResponse<IApiResource<IPublicHoliday>>) => {
          if (isMounted.current) setLoading(false);
          handleAxiosSuccessSave(
            response,
            enqueueSnackbar,
            navigate,
            "/apps/setting/public-holiday"
          );
        })
        .catch((error: AxiosError) => {
          if (isMounted.current) setLoading(false);
          handleAxiosErrorSave(error, setError, enqueueSnackbar);
        });
    }
  };

  return (
    <>
      <TitleForm title={title} withBackUrl={true} />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <VuiCardContent title="Information">
            <Box p={2}>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <Controller
                    render={({ value, name, onChange }) => (
                      <MuiTextField
                        label={t("form.name.label")}
                        onChange={onChange}
                        value={value}
                        name={name}
                        error={_.has(errors, name)}
                        helperText={_.get(errors, `${name}.message`)}
                      />
                    )}
                    name="name"
                    control={control}
                  />
                </Grid>
                {id ? (
                  <Grid item md={12} xs={12}>
                    <Controller
                      render={({ value, name, onChange }) => (
                        <MuiDatePicker
                          value={value}
                          onChange={onChange}
                          label={t("form.date.label")}
                          error={_.has(errors, name)}
                          helperText={_.get(errors, `${name}.message`)}
                        />
                      )}
                      name="date"
                      control={control}
                    />
                  </Grid>
                ) : null}

                {!id ? (
                  <>
                    <Grid item md={6} xs={12}>
                      <Controller
                        render={({ value, name, onChange }) => (
                          <MuiDatePicker
                            value={value}
                            onChange={onChange}
                            disabled={!!id}
                            label={t("form.startDate.label")}
                            error={_.has(errors, name)}
                            helperText={_.get(errors, `${name}.message`)}
                          />
                        )}
                        name="date_from"
                        control={control}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Controller
                        render={({ value, name, onChange }) => (
                          <MuiDatePicker
                            value={value}
                            onChange={onChange}
                            label={t("form.endDate.label")}
                            error={_.has(errors, name)}
                            helperText={_.get(errors, `${name}.message`)}
                          />
                        )}
                        name="date_to"
                        control={control}
                      />
                    </Grid>
                  </>
                ) : null}
              </Grid>
            </Box>
          </VuiCardContent>
        </Grid>

        <FooterFormAction
          onCancel={"/apps/setting/public-holiday"}
          loading={loading}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          labelForm={t(
            "preference.publicHoliday.labelFooterFormActionWithQuestionMark"
          )}
        />
      </Grid>
    </>
  );
};

export default PublicHolidayForm;
