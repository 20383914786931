import React, { useEffect } from "react";
import People from "../../../../../../../layouts/Apps/People";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { VuiSearch } from "../../../../../../../@VodeaUI/components";
import {
  ArrowForwardIos,
  BlockOutlined,
  CheckCircleOutlineOutlined,
} from "@material-ui/icons";
import {
  $clone,
  $cloneState,
  generateEmployeeNavData,
  mapDataTableParams,
} from "../../../../../../../utilities/helpers/global";
import { useState } from "@hookstate/core/dist";
import { AxiosResponse } from "axios";
import useIsMounted from "../../../../../../../@VodeaUI/hooks/useIsMounted";
import VuiDataTable, {
  Column,
  Options,
} from "../../../../../../../@VodeaUI/VuiDataTable/Index";
import { defaultDataTableOption } from "../../../../../../../utilities/Option";
import { useParams } from "react-router";
import ReimbursementRepository from "../../../../../../../repositories/ReimbursementRepository";
import BoxInformation from "../../../../../../../components/Widget/BoxInformation/index";
import _ from "lodash";
import Status from "../../../../../../../components/Status";
import WidgetRepository from "../../../../../../../repositories/WidgetRepository";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";

const widgetBaseModel = {
  label: "",
  value: "",
  expired: "",
};

const EmployeeReimbursement: React.FC<any> = () => {
  const navigate = useNavigate();
  const columns = useState<Column[]>([
    {
      key: "created_at",
      label: "Request Date",
      type: "date",
      dateFormat: "DD MMMM YYYY",
    },
    { key: "reimbursement_policy_name", label: "Category" },
    {
      key: "date",
      label: "Used Date",
      type: "date",
      dateFormat: "DD MMMM YYYY",
    },
    { key: "amount", label: "Amount", type: "number" },
    {
      key: "status",
      label: "Status",
      render: (data) => {
        let status = _.get(data, "status", "");

        return <Status data={status} />;
      },
    },
    {
      key: "id",
      label: "",
      sortable: false,
      render: (row) => {
        return (
          <Box display="flex" justifyContent={"flex-end"}>
            <IconButton
              onClick={() =>
                navigate(
                  `/apps/people/personal-information/finance/reimbursement/${row.id}`,
                  {
                    state: {
                      type: 2,
                    },
                  }
                )
              }
              aria-label="edit"
            >
              <ArrowForwardIos fontSize="small" />
            </IconButton>

            {row.can_suspend ? (
              <Tooltip title="Suspend">
                <IconButton aria-label="suspend">
                  <BlockOutlined fontSize="small" />
                </IconButton>
              </Tooltip>
            ) : null}

            {row.can_unsuspend ? (
              <Tooltip title="Unsuspend">
                <IconButton aria-label="unsuspend">
                  <CheckCircleOutlineOutlined fontSize="small" />
                </IconButton>
              </Tooltip>
            ) : null}
          </Box>
        );
      },
    },
  ]);
  const isMounted = useIsMounted();
  const loading = useState(false);
  const options = useState<Options>({
    ...defaultDataTableOption,
    sortedBy: "desc",
    orderBy: "date",
  });
  const data = useState([]);
  const objParams = useParams();
  const employeeNavData = generateEmployeeNavData(objParams.id);

  const [reimbursement, setReimbursement] = React.useState<any[]>([
    widgetBaseModel,
  ]);

  useEffect(() => {
    document.title = "Reimbursement";
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = () => {
    loading.set(false);

    let params: any = {
      // user: objParams.id
    };

    if (objParams.id !== undefined) {
      params = {
        ...params,
        user: objParams.id,
      };

      ReimbursementRepository.all({
        ...mapDataTableParams($cloneState(options)),
        ...params,
      })
        .then((response: AxiosResponse) => {
          const { data: responseData } = response;
          const { data: list, meta } = responseData;

          if (isMounted.current) {
            data.set(list);
            options.page.set(meta.current_page - 1);
            options.total.set(meta.total);
            loading.set(false);
          }
        })
        .catch(() => {});

      WidgetRepository.userReimbursement({
        ...params,
      })
        .then((response: AxiosResponse) => {
          const tempReimbursement: any[] = [];

          _.get(response, "data.data", []).forEach((item: any) => {
            if (!moment(item.expired_at).isBefore(moment())) {
              tempReimbursement.push({
                label: item.name,
                value: item.balance,
                expired: item.expired_at,
              });
            }
          });

          setReimbursement(tempReimbursement);
        })
        .catch(() => {});
    } else {
      ReimbursementRepository.account({
        ...params,
        ...mapDataTableParams($cloneState(options)),
      })
        .then((response: AxiosResponse) => {
          const { data: responseData } = response;
          const { data: list, meta } = responseData;

          if (isMounted.current) {
            data.set(list);
            options.page.set(meta.current_page - 1);
            options.total.set(meta.total);
            loading.set(false);
          }
        })
        .catch(() => {});

      WidgetRepository.reimbursement({
        ...params,
      })
        .then((response: AxiosResponse) => {
          const tempReimbursement: any[] = [];

          _.get(response, "data.data", []).forEach((item: any) => {
            if (!moment(item.expired_at).isBefore(moment())) {
              tempReimbursement.push({
                label: item.name,
                value: item.balance,
                expired: item.expired_at,
              });
            }
          });

          setReimbursement(tempReimbursement);
        })
        .catch(() => {});
    }
  };

  return (
    <People navData={employeeNavData}>
      <>
        {reimbursement.length > 0 ? (
          <BoxInformation data={reimbursement} type={"money"} />
        ) : null}

        <Grid container spacing={3} justifyContent="space-between">
          <Grid item>
            <Typography variant={"h5"}>Reimbursement</Typography>
          </Grid>
          <Grid item>
            <Link to={"/apps/finance/reimbursement/reimbursement-request"}>
              <Button variant="contained" color="primary">
                Request Reimbursement
              </Button>
            </Link>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper variant="elevation" elevation={1}>
              <Box p={2}>
                <Grid container spacing={3} alignItems={"center"}>
                  <Grid item md={4} xs={12}>
                    <VuiSearch
                      state={options.search}
                      callback={() => {
                        options.page.set(0);
                        loadData();
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>

              <VuiDataTable
                loading={loading.get()}
                columns={columns.value}
                options={$clone(options.value)}
                data={data.value}
                onChangeOptions={(values: Options) => {
                  options.set(values);
                  loadData();
                }}
              />
            </Paper>
          </Grid>
        </Grid>
      </>
    </People>
  );
};

export default EmployeeReimbursement;
