import React, { useCallback, useEffect, useMemo } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { VuiSearch } from "../../../../@VodeaUI/components";
import {
  Add as AddIcon,
  BlockOutlined,
  CheckCircleOutlineOutlined,
  EditOutlined as EditOutlinedIcon,
} from "@material-ui/icons";
import {
  $clone,
  $cloneState,
  handleAxiosError,
  mapDataTableParams,
} from "../../../../utilities/helpers/global";
import { useState } from "@hookstate/core/dist";
import VuiDataTable, {
  Column,
  Options,
} from "../../../../@VodeaUI/VuiDataTable/Index";
import {
  defaultDataTableOption,
  defaultDeleteConfirmOption,
} from "../../../../utilities/Option";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { useIsMounted } from "../../../../@VodeaUI";
import { useSnackbar } from "notistack";
import { useConfirm } from "material-ui-confirm";
import PublicHolidayRepository from "../../../../repositories/PublicHolidayRepository";
import AnnouncementRepository from "../../../../repositories/AnnouncementRepository";
import { AxiosError, AxiosResponse } from "axios";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import { useStyles } from "../Announcement/style";
import MuiButton from "../../../../components/atoms/MuiButton";
import { TitleHeader } from "../../../../components/molecules";
import { MuiSearch } from "../../../../components/atoms";

const Announcement: React.FC<any> = () => {
  const classes = useStyles();
  const isMounted = useIsMounted();
  const data = useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const loading = useState(false);
  const options = useState<Options>({
    ...defaultDataTableOption,
    sortedBy: "desc",
    orderBy: "date",
  });

  const confirm = useConfirm();
  const handleDelete = (data: any) => {
    confirm(defaultDeleteConfirmOption)
      .then(() => deleteResource(data.id))
      .catch(() => {});
  };

  const deleteResource = (id: number) => {
    const loadingKey = enqueueSnackbar("Please wait...", {
      variant: "default",
      autoHideDuration: 5000,
      preventDuplicate: true,
    });

    AnnouncementRepository.delete(id)
      .then((resp: any) => {
        if (isMounted.current) {
          loadData();
          closeSnackbar(loadingKey);
          enqueueSnackbar(resp.data.message, {
            variant: "success",
          });
        }
      })
      .catch((e: any) => {
        if (isMounted.current) {
          closeSnackbar(loadingKey);
          handleAxiosError(e, isMounted.current, enqueueSnackbar);
        }
      });
  };

  const columns = useState<Column[]>([
    { key: "title", label: "Title" },
    { key: "created_on", label: "Created On" },
    { key: "status", label: "Status" },
    {
      key: "id",
      label: "",
      sortable: false,
      render: (row) => {
        return (
          <Box
            display="flex"
            justifyContent={"flex-end"}
            alignItems={"center"}
            className={"aw"}
          >
            <Link to={`/apps/setting/announcement/add/${row.id}`}>
              <Button variant="contained" color="primary" className={"btnView"}>
                View
              </Button>
            </Link>

            <Tooltip
              title={
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handleDelete(row)}
                >
                  DELETE
                </Button>
              }
              interactive
              placement={"bottom-start"}
              classes={{
                tooltip: classes.backgroundTooltips,
              }}
            >
              <IconButton aria-label="suspend">
                <MoreHorizOutlinedIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
  ]);

  const loadData = useCallback(async () => {
    if (isMounted.current) {
      loading.set(true);
    }

    await AnnouncementRepository.all({
      ...mapDataTableParams($cloneState(options)),
    })
      .then((response: AxiosResponse) => {
        const { data: responseData } = response;
        const { data: list, meta } = responseData;

        if (isMounted.current) {
          data.set(list);
          options.page.set(meta.current_page - 1);
          options.total.set(meta.total);
        }
        loading.set(false);
      })
      .catch((e: AxiosError) => {
        if (isMounted.current) {
          loading.set(false);
          handleAxiosError(e, isMounted.current, enqueueSnackbar);
        }
      });
  }, []);

  useMemo(() => {
    (async () => {
      await loadData();
    })();
  }, []);

  const handleTableSearch = useCallback((value: string) => {
    if (isMounted.current) {
      options.search.set(value);
      options.page.set(0);
      loadData().then();
    }
  }, []);

  return (
    <>
      <TitleHeader
        title={"Announcement"}
        subtitle={"List of announcement"}
        component={
          <Link to={"/apps/setting/announcement/create"}>
            <MuiButton label="Add Announcement" size="large" />
          </Link>
        }
      />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper variant="elevation" elevation={1}>
            <Box p={2}>
              <Grid container spacing={3} alignItems={"center"}>
                <Grid item md={4} xs={12}>
                  <MuiSearch
                    value={$clone(options.search.value)}
                    callback={handleTableSearch}
                  />
                </Grid>
              </Grid>
            </Box>

            <VuiDataTable
              loading={loading.get()}
              columns={columns.value}
              options={$clone(options.value)}
              data={data.value}
              onChangeOptions={(values: Options) => {
                options.set(values);
                loadData();
              }}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default Announcement;
