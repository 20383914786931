import { api } from "../@VodeaUI";

let url = window._env_.REACT_APP_PASSPORT_END_POINT;

const TimeOffRepository = {
  all: function (params: any = null) {
    return api.get(`${url}/api/time-off-request`, {
      params,
    });
  },
  account: function (params: any = null) {
    return api.get(`${url}/api/account/time-off-request`, {
      params,
    });
  },
  approve: function (id: number | string, params: any = null) {
    return api.put(`${url}/api/time-off-request/${id}/approve`, {
      params,
    });
  },
  decline: function (id: number | string, params: any = null) {
    return api.put(`${url}/api/time-off-request/${id}/decline`, {
      params,
    });
  },
  show: function (id: number | string, params: any = null) {
    return api.get(`${url}/api/time-off-request/${id}`, {
      params,
    });
  },
  create: function (payload: any, params: any = null) {
    return api.post(`${url}/api/time-off-request`, payload, {
      params,
    });
  },
  getEmployee: function (params: any = null) {
    return api.get(`${url}/api/select/user`, {
      params,
    });
  },
  getTimeOffPolicy: function (params: any = null) {
    return api.get(`${url}/api/select/time-off-policy`, {
      params,
    });
  },
  getTimeOffBalance: function (params: any = null) {
    return api.get(`${url}/api/time-off-balance`, {
      params,
    });
  },
};

export default TimeOffRepository;
