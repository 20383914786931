import { createTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { lightBlue } from "@material-ui/core/colors";

const drawerWidth = 240;

export const theme = createTheme({
  mixins: {
    toolbar: {
      minHeight: 55,
      "@media (min-width:600px)": {
        minHeight: 55,
      },
    },
  },
  overrides: {
    MuiPaper: {
      elevation1: {
        boxShadow: "0px 2px 8px #0000001a",
      },
      rounded: {
        borderRadius: "15px",
        "&.MuiAutocomplete-paper": {
          borderRadius: "unset",
        },
      },
    },
    MuiDialog: {
      paperScrollPaper: {
        borderRadius: "5px",
      },
    },
    MuiDialogContent: {
      root: {
        padding: "40px 25px",
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: "0.8rem",
        "@media (max-width:600px)": {
          minHeight: 28,
        },
      },
    },
    MuiButton: {
      root: {
        padding: "8px 16px",
      },
    },
    MuiTypography: {
      h6: {
        fontSize: "1rem",
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: "none",
        height: "53px",
        padding: "0 16px",
      },
    },
    MuiTableRow: {
      root: {
        height: "53px",
        padding: "0",
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: "transparent",
      },
    },
    MuiCssBaseline: {
      "@global": {
        ".MuiPickersDatePickerRoot-toolbar": {
          display: "none !important",
        },
      },
    },
  },
  palette: {
    primary: {
      light: "#00C1AE",
      main: "#02827A",
      dark: "#013332",
      contrastText: "#ffffff",
    },
    secondary: {
      light: "#00C1AE",
      main: "#02827A",
      dark: "#707070",
    },

    text: {
      primary: "#383838",
    },
    background: {
      default: "#ffffff",
    },
  },
  typography: {
    fontFamily: "Roboto",
    h5: {
      fontWeight: 500,
    },
    button: {
      fontSize: "0.85rem",
    },
  },
});

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  content: {
    minHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
      marginLeft: 0,
    },
  },
  contentShift: {
    [theme.breakpoints.up("sm")]: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
  },
  contentInside: {
    padding: theme.spacing(3),
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0),
    },
  },
  buttonPrimary: {
    padding: "0.375rem 1rem",
    backgroundColor: theme.palette.primary.dark,
    borderRadius: 8,
    textTransform: "capitalize",
    fontWeight: 400,
    boxShadow: "none",
  },
  buttonTable: {
    padding: "0.325rem 2rem",
    backgroundColor: theme.palette.primary.dark,
    borderRadius: 8,
    textTransform: "capitalize",
    fontWeight: 400,
    boxShadow: "none",
  },
  buttonTablePolicy: {
    backgroundColor: theme.palette.primary.dark,
    borderRadius: 8,
    fontSize: 12,
    textTransform: "capitalize",
    fontWeight: 400,
    boxShadow: "none",
  },
}));
