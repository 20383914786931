import React, { FC, useCallback, useMemo, useState } from "react";
import {
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
} from "@material-ui/core";
import { AxiosError, AxiosResponse } from "axios";
import { IApiResource } from "../../../../interfaces/ApiResource";
import {
  MuiCheckbox,
  MuiLoader,
  MuiSearch,
  MuiTypography,
} from "../../../atoms";
import { ITodoList } from "../../../../interfaces/TodoList";
import useIsMounted from "../../../../utilities/hooks/use-is-mounted.hook";
import {
  axiosErrorLoadDataHandler,
  isAxiosCanceled,
} from "../../../../utilities/helpers/axios-error.helper";
import { useSnackbar } from "notistack";
import AccountTaskRepository from "../../../../repositories/account/AccountTaskRepository";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { axiosSuccessUpdateDataHandler } from "../../../../utilities/helpers/axios-success.helper";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  listItem: {
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
  },
  title: {
    textTransform: "uppercase",
    fontWeight: "bold",
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 16,
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
  },
  search: {
    marginLeft: 16,
  },
});

const Todo: FC = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<ITodoList[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const { enqueueSnackbar } = useSnackbar();
  const isMounted = useIsMounted();

  const loadData = useCallback(async () => {
    if (isMounted.current) setLoading(true);
    await AccountTaskRepository.all({
      ...(searchValue ? { search: searchValue } : {}),
    })
      .then((response: AxiosResponse<IApiResource<ITodoList[]>>) => {
        if (isMounted.current) {
          const { data: responseData } = response.data;
          setData(responseData);
          setLoading(false);
        }
      })
      .catch((error: AxiosError) => {
        if (isMounted.current && !isAxiosCanceled(error)) {
          axiosErrorLoadDataHandler(error, enqueueSnackbar);
          setLoading(false);
        }
      });
  }, [searchValue]);

  const updateTaskData = async (task: ITodoList, value: boolean) => {
    const findTaskIndex = data.findIndex((todo) => todo.id === task.id);
    if (findTaskIndex === -1) {
      return;
    }

    await AccountTaskRepository.update(task.id || 0, {
      task: task.task,
      is_done: value,
    })
      .then((response: AxiosResponse) => {
        axiosSuccessUpdateDataHandler(response, enqueueSnackbar);
        if (isMounted.current) {
          setData((prevState) => {
            const cloneState = [...prevState];
            cloneState[findTaskIndex].is_done = value;
            return cloneState;
          });
        }
      })
      .catch((error: AxiosError) => {
        axiosErrorLoadDataHandler(error, enqueueSnackbar);
      });
  };

  const deleteTask = async (taskId: number) => {
    const findTaskIndex = data.findIndex((todo) => todo.id === taskId);
    if (findTaskIndex === -1) {
      return;
    }

    await AccountTaskRepository.delete(taskId)
      .then((response: AxiosResponse) => {
        axiosSuccessUpdateDataHandler(response, enqueueSnackbar);
        if (isMounted.current) {
          setData((prevState) => {
            const cloneState = [...prevState];
            cloneState.splice(findTaskIndex, 1);
            return cloneState;
          });
        }
      })
      .catch((error: AxiosError) => {
        axiosErrorLoadDataHandler(error, enqueueSnackbar);
      });
  };

  useMemo(() => {
    (async () => {
      await loadData();
    })();
  }, [searchValue]);

  const handleTableSearch = useCallback((value: string) => {
    setSearchValue(value);
  }, []);

  const MuiListItem = (data: ITodoList) => {
    return (
      <ListItem className={classes.listItem}>
        <MuiCheckbox
          checked={data.is_done}
          label={data.task}
          styles={data.is_done ? { textDecoration: "line-through" } : {}}
          onChange={(value) => updateTaskData(data, value)}
        />
        <ListItemSecondaryAction>
          <IconButton edge="end" onClick={() => deleteTask(data.id || 0)}>
            <DeleteOutlineIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    );
  };

  const renderUncompletedData = useMemo(() => {
    return data.length ? (
      data
        .filter((todo) => !todo.is_done)
        .map((todo) => {
          return <MuiListItem {...todo} />;
        })
    ) : (
      <>No matching records found</>
    );
  }, [data, loading]);

  const renderCompletedData = useMemo(() => {
    return data.length ? (
      data
        .filter((todo) => todo.is_done)
        .map((todo) => {
          return <MuiListItem {...todo} />;
        })
    ) : (
      <>No matching records found</>
    );
  }, [data, loading]);

  return (
    <Grid container spacing={3}>
      <Grid item md={4} xs={12}>
        <MuiSearch
          className={classes.search}
          value={searchValue}
          callback={handleTableSearch}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <MuiTypography className={classes.title}>List</MuiTypography>
        {loading ? (
          <MuiLoader styles={{ height: 60 }} />
        ) : (
          <List>{renderUncompletedData}</List>
        )}
      </Grid>
      <Grid item md={12} xs={12}>
        <MuiTypography className={classes.title}>Completed</MuiTypography>
        {loading ? (
          <MuiLoader styles={{ height: 60 }} />
        ) : (
          <List>{renderCompletedData}</List>
        )}
      </Grid>
    </Grid>
  );
};

export default Todo;
