import * as yup from "yup";
import { TFunction } from "react-i18next";

export const validationSchema = (t: TFunction) => {
  let shapes = {
    type: yup.mixed().required().label(t("form.type.label")),
    policy_id: yup.mixed().required().label(t("form.policy.label")),
    formula_component_id: yup.mixed().label(t("form.formula.label")),
    subject_id: yup.mixed().required().label(t("form.policyCode.label")),
    description: yup.string().label(t("form.policyDescription.label")),
  };

  return yup.object().shape(shapes);
};
