const InitialState = {
  type: "",
  data: null,
};

const constant = (state = InitialState, action: any) => {
  switch (action.type) {
    case "SET_CONSTANT_FULFILLED":
      return {
        ...state,
        type: action.type,
        payload: action.payload,
      };

    case "SET_CONSTANT":
      return {
        ...state,
        type: action.type,
        payload: action.payload,
      };

    case "SET_CONSTANT_ERROR":
      return {
        ...state,
        type: action.type,
        error: action.payload,
      };

    default:
      return { ...state };
  }
};

export default constant;
