import React, { useCallback, useEffect } from "react";
import { Box, Grid, IconButton, Paper, Tooltip } from "@material-ui/core";
import { VuiSearch } from "../../../../../@VodeaUI/components";
import {
  $clone,
  $cloneState,
  mapDataTableParams,
} from "../../../../../utilities/helpers/global";
import { useState } from "@hookstate/core/dist";
import VuiDataTable, {
  Column,
  Options,
} from "../../../../../@VodeaUI/VuiDataTable/Index";
import { defaultDataTableOption } from "../../../../../utilities/Option";
import useIsMounted from "../../../../../@VodeaUI/hooks/useIsMounted";
import { Link } from "react-router-dom";
import Policy from "../../../../../layouts/Apps/Preference/Policy";
import VuiCardContent from "../../../../../@VodeaUI/components/VuiCardContent";
import AttendancePolicyRepository from "../../../../../repositories/AttendancePolicyRepository";
import { AxiosError, AxiosResponse } from "axios";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import { MuiAutoComplete, MuiSearch } from "../../../../../components/atoms";
import { sortedByOptions } from "../../../../../constants";
import { useTranslation } from "react-i18next";

const AttendancePolicy: React.FC<any> = () => {
  const isMounted = useIsMounted();
  const { t } = useTranslation();
  const tableColumns = useState<Column[]>([
    { key: "name", label: "POLICY NAME" },
    { key: "code", label: "POLICY CODE" },
    { key: "valid_from", label: "EFFECTIVE ON" },
    {
      key: "id",
      label: "",
      sortable: false,
      render: (row) => {
        return (
          <Box display="flex" justifyContent={"flex-end"}>
            <Link to={`/apps/setting/policy/attendance/${row.id}`}>
              <Tooltip title="Detail">
                <IconButton aria-label="more">
                  <MoreHorizOutlinedIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Link>
          </Box>
        );
      },
    },
  ]);

  const loading = useState(false);
  const options = useState<Options>({
    ...defaultDataTableOption,
    sortedBy: "desc",
    orderBy: "created_at",
  });

  const tableData = useState<any[]>([]);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = () => {
    if (isMounted) {
      loading.set(true);
    }

    AttendancePolicyRepository.all({
      ...mapDataTableParams($cloneState(options)),
    })
      .then((resp: AxiosResponse) => {
        const { data: responseData } = resp;
        const { data, meta } = responseData;

        if (isMounted.current) {
          tableData.set(data);
          options.page.set(meta.current_page - 1);
          options.total.set(meta.total);
          loading.set(false);
        }
      })
      .catch((err: AxiosError) => {
        loading.set(false);
      });
  };

  const handleTableSearch = useCallback((value: string) => {
    if (isMounted.current) {
      options.search.set(value);
      options.page.set(0);
      loadData();
    }
  }, []);

  return (
    <Policy
      url="/apps/setting/policy/attendance/create"
      buttonLabel="Add Attendance Policy"
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <VuiCardContent title="Attendance Policy">
            <Paper variant="elevation" elevation={1}>
              <Box p={2}>
                <Grid container spacing={3} alignItems={"center"}>
                  <Grid item md={12} xs={12}>
                    <Grid
                      container
                      spacing={3}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                    >
                      <Grid item>
                        <MuiSearch
                          value={$clone(options.search.value)}
                          callback={handleTableSearch}
                        />
                      </Grid>
                      <Grid item md={3}>
                        <MuiAutoComplete
                          isAsync={false}
                          value={sortedByOptions.find(
                            (option) =>
                              option.id === $clone(options.sortedBy.value)
                          )}
                          constantOptions={sortedByOptions}
                          onSelected={(newValue) => {
                            options.sortedBy.set(newValue.id);
                            loadData();
                          }}
                          muiTextField={{
                            label: t("form.sort.label"),
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>

              <VuiDataTable
                loading={loading.get()}
                columns={tableColumns.get()}
                options={$clone(options.value)}
                data={tableData.get()}
                onChangeOptions={(values: Options) => {
                  options.set(values);
                  loadData();
                }}
              />
            </Paper>
          </VuiCardContent>
        </Grid>
      </Grid>
    </Policy>
  );
};
export default AttendancePolicy;
