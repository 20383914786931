import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  columnActionButton: {
    textAlign: "right",
  },
  stepWrapper: {
    "& .MuiStepConnector-root": {
      top: "50%",
    },
  },
  footerFormWrapper: {
    marginTop: "20px",
  },
}));
