import _ from "lodash";

export const Capitalize = (str = "") => {
  if (!!str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  } else {
    return str;
  }
};

export const Uppercase = (str = "") => {
  return str.toUpperCase();
};

export const Lowercase = (str = "") => {
  if (!!str) {
    return str.toLowerCase();
  } else {
    return str;
  }
};

export const NumberFormat = (numb: string) => {
  parseFloat(numb).toLocaleString();
};

export const statusFormat = (item: any, col: string, oneCol = false) => {
  if (oneCol) {
    let a = _.upperFirst(_.lowerCase(_.get(item, col)).replace("_g", " ")),
      b = a.split(" ");

    return b[0];
  } else {
    return _.upperFirst(_.lowerCase(_.get(item, col)).replace("_g", " "));
  }
};

export const generatePolicyFromModel = (value: string) => {
  if (value === "App\\Models\\ReimbursementPolicy") {
    return {
      id: "REIMBURSEMENT",
      name: "Reimbursement",
    };
  } else if (value === "App\\Models\\TimeOffPolicy") {
    return {
      id: "TIME_OFF",
      name: "TimeOff",
    };
  } else if (value === "App\\Models\\TimeOffReimbursePolicy") {
    return {
      id: "TIME_OFF_REIMBURSE",
      name: "TimeOffReimburse",
    };
  } else if (value === "App\\Models\\CashAdvancePolicy") {
    return {
      id: "CASH_ADVANCE",
      name: "CashAdvance",
    };
  } else {
    return {
      id: "ATTENDANCE",
      name: "Attendance",
    };
  }
};
