import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { IConstantOption } from "../../../../../../interfaces/ConstantOption";
import { IUser } from "../../../../../../interfaces/User";
import { Controller, FieldErrors, Control } from "react-hook-form";
import {
  MuiCheckbox,
  MuiDatePicker,
  MuiTextField,
  NumberFieldFormat,
} from "../../../../../../components/atoms";
import { IEmployeeList } from "../index";
import _ from "lodash";
import moment from "moment";

const useStyles = makeStyles({
  tableWrapper: {
    "& .MuiTableCell-sizeSmall": {
      padding: "6px 8px",
    },
  },
  tableHead: {
    textTransform: "uppercase",
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
    fontWeight: 600,
    color: "#1A1818",
    "&:nth-child(5), &:nth-child(6), &:nth-child(7)": {
      minWidth: "15rem",
    },
  },
  tableCell: {
    whiteSpace: "nowrap",
    padding: "6px 16px",
  },
  tableRow: {
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
  },
});

interface Props {
  tableColumns: Array<IConstantOption & { type: string }>;
  data: Partial<IEmployeeList & { key: string } & IUser>[];
  errors: FieldErrors;
  control: Control;
}

const EmployeeList = ({ tableColumns, data, errors, control }: Props) => {
  const classes = useStyles();

  const renderEmployee = (
    item: Partial<IEmployeeList & { key: string } & IUser>,
    index: number
  ) => {
    return (
      <TableRow key={index} className={classes.tableRow}>
        <TableCell className={classes.tableCell}>
          <Controller
            name={`transaction_policy_users[${index}].id`}
            defaultValue={item.id}
            control={control}
            render={({ value, name }) => {
              return <input type="hidden" name={name} defaultValue={value} />;
            }}
          />
          <Controller
            name={`transaction_policy_users[${index}].checked`}
            control={control}
            defaultValue={false}
            render={({ value, onChange }) => {
              return <MuiCheckbox checked={value} onChange={onChange} />;
            }}
          />
        </TableCell>
        <TableCell className={classes.tableCell}>
          {item?.name ? item?.name : item?.user?.name}
        </TableCell>
        <TableCell className={classes.tableCell}>
          {item?.department_name
            ? item?.department_name
            : item?.user?.role_user?.role?.department?.name}
        </TableCell>
        <TableCell className={classes.tableCell}>
          {item?.role_name
            ? item?.role_name
            : item?.user?.role_user?.role?.name}
        </TableCell>
        {tableColumns.map((column, columnIndex) => {
          return (
            <TableCell className={classes.tableCell} key={columnIndex}>
              {column.type === "number" ? (
                <Controller
                  name={`transaction_policy_users[${index}].${column.id}`}
                  control={control}
                  defaultValue={item.balance || 0}
                  render={({ value, onChange, name }) => {
                    return (
                      <MuiTextField
                        onChange={onChange}
                        value={value}
                        error={_.has(errors, name)}
                        helperText={_.get(errors, `${name}.message`)}
                        InputProps={{
                          inputComponent: NumberFieldFormat,
                        }}
                      />
                    );
                  }}
                />
              ) : null}
              {column.type === "date" ? (
                <Controller
                  name={`transaction_policy_users[${index}].${column.id}`}
                  control={control}
                  defaultValue={_.get(item, column.id) || moment()}
                  render={({ value, onChange, name }) => {
                    return (
                      <MuiDatePicker
                        value={value}
                        name={name}
                        format="DD MMMM YYYY"
                        onChange={onChange}
                        error={_.has(errors, name)}
                        helperText={_.get(errors, `${name}.message`)}
                      />
                    );
                  }}
                />
              ) : null}
            </TableCell>
          );
        })}
      </TableRow>
    );
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className={classes.tableWrapper}>
            <TableContainer>
              <Table aria-label="simple table">
                <TableHead>
                  <TableCell className={classes.tableHead} />
                  <TableCell className={classes.tableHead}>Employee</TableCell>
                  <TableCell className={classes.tableHead}>
                    Department
                  </TableCell>
                  <TableCell className={classes.tableHead}>Position</TableCell>
                  {tableColumns.map((item, index) => {
                    return (
                      <TableCell key={index} className={classes.tableHead}>
                        {item.name}
                      </TableCell>
                    );
                  })}
                </TableHead>
                <TableBody>
                  {data.length ? (
                    data.map(renderEmployee)
                  ) : (
                    <TableRow className={classes.tableRow}>
                      <TableCell
                        align={"center"}
                        colSpan={4 + tableColumns.length}
                        className={classes.tableCell}
                      >
                        No matching records found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EmployeeList;
