import { Box, Grid, IconButton, TableCell, TableRow } from "@material-ui/core";
import _ from "lodash";
import HistoryIcon from "@material-ui/icons/History";
import MuiButton from "../../../../../../components/atoms/MuiButton";
import { Controller, useForm } from "react-hook-form";
import {
  MuiTextField,
  NumberFieldFormat,
} from "../../../../../../components/atoms";
import VuiNumberFormat from "../../../../../../@VodeaUI/components/Input/VuiNumberFormat";
import EditIcon from "@material-ui/icons/Edit";
import React, { useCallback, useEffect, useState } from "react";
import { useStyles as useTableStyles } from "../../../../../../@VodeaUI/VuiDataTable/Style";
import { ICashAdvanceRequestItem } from "../../../../../../interfaces/CashAdvanceRequestItem";
import { useTranslation } from "react-i18next";
import CashAdvanceRepository from "../../../../../../repositories/CashAdvanceRepository";
import { AxiosError, AxiosResponse } from "axios";
import { handleAxiosErrorSave } from "../../../../../../utilities/helpers/axios-error.helper";
import { useSnackbar } from "notistack";

export interface ChangeAmount {
  rowIndex: number;
  amount: number;
}

interface CashAdvanceItemProps {
  userId: number;
  cashAdvanceId: string | undefined;
  data: ICashAdvanceRequestItem;
  rowIndex: number;
  handleOpenModalCashAdvance: (item: any) => void;
  onChangeAmount: (data: ChangeAmount) => void;
}

const CashAdvanceItem = ({
  userId,
  cashAdvanceId,
  data,
  rowIndex,
  handleOpenModalCashAdvance,
  onChangeAmount,
}: CashAdvanceItemProps) => {
  const tableClasses = useTableStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [onEditAmount, setOnEditAmount] = useState<boolean>(false);
  const handleClickEditAmount = useCallback(() => setOnEditAmount(true), []);
  const handleClickCancelEditAmount = useCallback(
    () => setOnEditAmount(false),
    []
  );

  const { control, errors, handleSubmit, reset, setError } =
    useForm<ICashAdvanceRequestItem>({
      mode: "onChange",
      defaultValues: data,
    });

  const onSubmit = useCallback(
    async (item: ICashAdvanceRequestItem) => {
      if (!data.id) {
        return;
      }

      setSubmitLoading(true);

      const normalizeData = {
        cash_request_amount_id: cashAdvanceId,
        amount: Number(item.amount),
      };

      await CashAdvanceRepository.updateItemAmount(data.id, normalizeData)
        .then((response: AxiosResponse) => {
          onChangeAmount({
            rowIndex: rowIndex,
            amount: Number(item.amount),
          });
          setSubmitLoading(false);
          reset(response.data.data);
          handleClickCancelEditAmount();
        })
        .catch((error: AxiosError) => {
          setSubmitLoading(false);
          handleAxiosErrorSave(error, setError, enqueueSnackbar);
        });
    },
    [cashAdvanceId, data, rowIndex]
  );

  useEffect(() => {
    reset(data);
  }, [data]);

  return (
    <TableRow key={rowIndex} hover className={tableClasses.tableRow}>
      <TableCell component="th" scope="row">
        {_.get(data, "cash_advance_formula_policy.name", "-")}
      </TableCell>
      {_.get(data, "asset_id", null) && (
        <TableCell component="th" scope="row">
          <Grid
            container
            spacing={1}
            justifyContent="center"
            alignItems="center"
          >
            {_.get(data, "asset.name") === undefined ||
            _.get(data, "asset.name") === null ||
            _.get(data, "asset.name") === "" ? (
              <>
                <Grid item xs={12}>
                  {_.get(data, "asset.name", "-")}
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={10}>
                  {_.get(data, "asset.name", "-")}
                </Grid>
                <Grid item xs={2}>
                  <IconButton
                    className={tableClasses.icon}
                    onClick={() =>
                      handleOpenModalCashAdvance(_.get(data, "asset", []))
                    }
                  >
                    <HistoryIcon />
                  </IconButton>
                </Grid>
              </>
            )}
          </Grid>
        </TableCell>
      )}
      <TableCell>{data.description}</TableCell>
      <TableCell>
        {data.attachment ? (
          <a href={data.attachment.url} target="_blank">
            <MuiButton label="View" />
          </a>
        ) : (
          "No Files"
        )}
      </TableCell>
      <TableCell>
        <Box display="flex" alignItems="center" gridGap={12}>
          {onEditAmount ? (
            <>
              <Box py={2}>
                <Controller
                  render={({ value, name, onChange }) => (
                    <MuiTextField
                      onChange={onChange}
                      value={value}
                      label={t("form.amount.label")}
                      error={_.has(errors, name)}
                      helperText={_.get(errors, `${name}.message`)}
                      InputProps={{
                        inputComponent: NumberFieldFormat,
                      }}
                    />
                  )}
                  name={`amount`}
                  control={control}
                  defaultValue={data.amount}
                />
              </Box>

              <Box display="flex" alignItems="center" gridGap={6}>
                <MuiButton
                  label="Save"
                  loading={submitLoading}
                  onClick={handleSubmit(onSubmit)}
                />

                <MuiButton
                  label="Cancel"
                  variant="text"
                  onClick={handleClickCancelEditAmount}
                />
              </Box>
            </>
          ) : (
            <>
              <VuiNumberFormat
                data={_.get(data, "amount", null) || 0}
                value={_.get(data, "amount", null) || 0}
              />

              {userId === 42 && (
                <IconButton onClick={handleClickEditAmount}>
                  <EditIcon fontSize="small" />
                </IconButton>
              )}
            </>
          )}
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default CashAdvanceItem;
