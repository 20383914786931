import React, { useMemo } from "react";
import { passportService } from "../../@VodeaUI";
import { setUser } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";

const usePassportService = (disableAutoFetchUser = false) => {
  const dispatch = useDispatch();
  const { isLogged } = useSelector(({ vodea }: any) => {
    return {
      isLogged: vodea.auth.isLogged,
    };
  });

  const [isOnSignIn, setIsOnSignIn] = React.useState(false);
  const [isOnFetchingUser, setIsOnFetchingUser] = React.useState(
    !disableAutoFetchUser
  );

  const fetchUser = async () => {
    const accessToken = passportService.getAccessToken();
    if (accessToken && !isLogged) {
      setIsOnFetchingUser(true);
      await passportService
        .fetchUser()
        .then((response: any) => dispatch(setUser(response.data.data)))
        .catch(() => {});

      setIsOnFetchingUser(false);
    }
  };

  const login = async (
    username: string,
    password: string,
    scope: string = "*"
  ) => {
    return new Promise(async (resolve, reject) => {
      setIsOnSignIn(true);

      await passportService
        .login(username, password, scope)
        .then((response) => {
          setIsOnFetchingUser(true);
          resolve(response);
        })
        .catch((error) => reject(error));

      setIsOnSignIn(false);
    });
  };

  const setToken = async (token:string) => {
    await passportService.setToken({
      access_token: token,
      refresh_token: '',
      expires_in: 60 * 60 * 24,
      token_type: 'Bearer',
    });

    setIsOnFetchingUser(true);
  }

  useMemo(() => {
    (async () => {
      if (isOnFetchingUser) {
        await fetchUser()
          .then(() => {})
          .catch(() => {});
      }

      setIsOnFetchingUser(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnFetchingUser]);

  return {
    isOnSignIn,
    isOnFetchingUser,
    setToken,
    login,
    fetchUser,
  };
};

export default usePassportService;
