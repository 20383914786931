import React, { useEffect } from "react";
import { Box, Button, Grid, Paper, Typography } from "@material-ui/core";
import { VuiSearch } from "../../../../@VodeaUI/components";
import {
  $clone,
  $cloneState,
  mapDataTableParams,
} from "../../../../utilities/helpers/global";
import { useState } from "@hookstate/core/dist";
import VuiDataTable, {
  Column,
  Options,
} from "../../../../@VodeaUI/VuiDataTable/Index";
import { defaultDataTableOption } from "../../../../utilities/Option";
import { Link, useNavigate } from "react-router-dom";
import VuiSelect from "../../../../@VodeaUI/components/Input/VuiSelect/Index";
import { useStyles } from "../../../../components/Status/style";
import SettlementRepository from "../../../../repositories/SettlementRepository";
import moment from "moment";
import { useIsMounted } from "../../../../@VodeaUI";
import Status from "../../../../components/Status";

const SettlementFinance: React.FC<any> = () => {
  const classes = useStyles();
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const [tableData, setTableData] = React.useState<any>([]);
  const columns = useState<Column[]>([
    { key: "number", label: "Number" },
    {
      key: "created_at",
      label: "Request Date",
      type: "date",
      dateFormat: "DD MMMM YYYY",
    },
    {
      key: "date",
      label: "Used On",
      type: "date",
      dateFormat: "DD MMMM YYYY",
    },
    { key: "amount", label: "Amount" },
    {
      key: "status",
      label: "Status",
      sortable: false,
      render: (row) => <Status data={row.status} />,
    },
    {
      key: "id",
      label: "",
      sortable: false,
      render: (row) => {
        return (
          <Box display="flex" justifyContent={"flex-end"}>
            <Button
              onClick={() =>
                navigate(`${row.id}`, {
                  state: {
                    type: 4,
                  },
                })
              }
              className="btnView"
              variant="contained"
              color="primary"
            >
              View
            </Button>
          </Box>
        );
      },
    },
  ]);

  const orderBy = useState("all");
  const loading = useState(false);
  const options = useState<Options>({
    ...defaultDataTableOption,
    sortedBy: "desc",
    orderBy: "date",
  });

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    if (isMounted.current) {
      loading.set(true);
    }

    SettlementRepository.all({
      ...mapDataTableParams($cloneState(options)),
      only: "my_and_approver",
      with: ["cashAdvanceRequest"],
    })
      .then((response: any) => {
        if (isMounted.current) {
          loading.set(false);
          setTableData(response.data.data);
          options.total.set(response.data.meta.total);
          options.page.set(response.data.meta.current_page - 1);
        }
      })
      .catch((err: any) => {
        if (isMounted.current) {
          loading.set(false);
        }
      });
  };

  const orderByOptions = [
    {
      key: "all",
      label: "All",
    },
    {
      key: "a-z",
      label: "A-Z",
    },
  ];

  return (
    <>
      <Grid container spacing={3} justifyContent="space-between">
        <Grid item>
          <Typography variant={"h5"}>Settlement</Typography>
        </Grid>
        <Grid item>
          <Link to={"/apps/finance/settlement/settlement-request"}>
            <Button variant="contained" color="primary">
              Request Settlement
            </Button>
          </Link>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper variant="elevation" elevation={1}>
            <Box p={2}>
              <Grid
                container
                spacing={3}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Grid item>
                  <VuiSearch
                    state={options.search}
                    callback={() => {
                      options.page.set(0);
                      loadData();
                    }}
                  />
                </Grid>
                <Grid item>
                  <VuiSelect
                    label="Urutkan"
                    state={orderBy}
                    options={orderByOptions}
                  />
                </Grid>
              </Grid>
              <Box marginTop={2}>
                <Grid container>
                  <Grid item>
                    <Grid container spacing={3}>
                      <Grid item xs={4} md={4}>
                        <VuiSelect
                          label="Category"
                          state={orderBy}
                          options={orderByOptions}
                        />
                      </Grid>
                      <Grid item xs={4} md={4}>
                        <VuiSelect
                          label="Range"
                          state={orderBy}
                          options={orderByOptions}
                        />
                      </Grid>
                      <Grid item xs={4} md={4}>
                        <VuiSelect
                          label="Employee"
                          state={orderBy}
                          options={orderByOptions}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Box>

            <VuiDataTable
              loading={loading.get()}
              columns={columns.value}
              options={$clone(options.value)}
              data={tableData}
              onChangeOptions={(values: Options) => {
                options.set(values);
                loadData();
              }}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default SettlementFinance;
