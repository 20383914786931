import { Apps as AppsIcon } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import React from 'react';
import { useStyles } from '../style';
import PopupState, { bindTrigger } from 'material-ui-popup-state';
import { bindPopover } from 'material-ui-popup-state/core';
import { Box, Popover, Tooltip, Typography } from '@material-ui/core';

interface Props {}

const Apps: React.FC<Props> = () => {
  const classes = useStyles();

  const services = [
    {
      name: 'Vodea Cloud',
      caption: 'Account Management',
      logoUrl: '/images/logo-vodea-cloud.png',
    },
    {
      name: 'Vodea CMS',
      caption: 'Content Management System',
      logoUrl: '/images/logo-vodea-cms.png',
    },
    {
      name: 'Vodea HR',
      caption: 'Human Resource System',
      logoUrl: '/images/logo-vodea-hr.png',
    },
  ];

  const renderServices = (service: any, index: number) => {
    return (
      <div className='app-item' key={index}>
        <img className='app-logo' src={service.logoUrl} alt={service.caption} />
        <div className='app-info'>
          <Typography variant={'body2'}>{service.name}</Typography>
          <Typography variant={'caption'}>
            <Box color={'grey.500'}>{service.caption}</Box>
          </Typography>
        </div>
      </div>
    );
  };

  return (
    <>
      <PopupState variant='popover' popupId='app-popover'>
        {popupState => (
          <>
            <Tooltip title='Switch to...' arrow>
              <IconButton
                color='inherit'
                classes={{
                  root: classes.iconBtn,
                }}
                {...bindTrigger(popupState)}
              >
                <AppsIcon />
              </IconButton>
            </Tooltip>

            <Popover
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              classes={{
                paper: classes.app,
              }}
              {...bindPopover(popupState)}
            >
              <div className='app-popover'>
                <span className='app-label'>Switch to</span>
                <div className='app-list'>{services.map(renderServices)}</div>
              </div>
            </Popover>
          </>
        )}
      </PopupState>
    </>
  );
};

export default Apps;
