import React from "react";
import { FormHelperText } from "@material-ui/core";

interface Props {
  error: boolean;
  message: string | undefined;
}

const VuiFormHelperText: React.FC<Props> = ({ error, message = "" }) => {
  return (
    <>
      {error && (
        <FormHelperText variant="outlined" error={error}>
          {message}
        </FormHelperText>
      )}
    </>
  );
};

export default VuiFormHelperText;
