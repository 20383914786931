import React from "react";
import { Box, Grid, Paper, Typography } from "@material-ui/core";
import { useStyles } from "./style";
import _ from "lodash";
import moment from "moment";

interface Props {
  data: any;
  loading: boolean;
  type: string;
}

const SingleInformation: React.FC<Props> = ({ data, type, loading }) => {
  const classes = useStyles();
  const addSeparatorToValue = (val: any) => {
    return parseFloat(val).toLocaleString();
  };

  return (
    <Paper>
      {loading ? (
        <></>
      ) : (
        <Box mb={2} className={classes.boxWrapper}>
          {type === "attendance" ? (
            <Grid container justifyContent={"space-between"}>
              <Grid item>
                <Typography variant={"subtitle1"}>
                  {_.get(data, "label")}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  className={"status " + _.get(data, "status")}
                  variant={"body2"}
                >
                  {_.get(data, "status")}
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <Typography variant={"subtitle1"}>
              {_.get(data, "label")}
            </Typography>
          )}
          <Typography variant={"h4"}>
            {type === "attendance" ? (
              <span>
                {_.get(data, "time_from") == undefined ||
                _.get(data, "time_to") == undefined
                  ? "-"
                  : moment(_.get(data, "time_from"), "HH:mm").format("HH:mm") +
                    " - " +
                    moment(_.get(data, "time_to"), "HH:mm").format("HH:mm")}
              </span>
            ) : (
              addSeparatorToValue(_.get(data, "value", "-"))
            )}
            <span>{type === "attendance" ? "" : " Days"}</span>
          </Typography>
          <Typography variant={"caption"}>
            {type === "attendance" ? "Clock in on " : "Expired On"}{" "}
            {moment(_.get(data, "expired")).format("DD MMMM YYYY")}
          </Typography>
        </Box>
      )}
    </Paper>
  );
};

export default SingleInformation;
