import React from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import NavigationBar from "../../../../components/NavigationBar";
import { Link } from "react-router-dom";

interface Props {
  children?: React.ReactChild | React.ReactChild[];
  url?: string;
  buttonLabel?: String;
}

const PreferencesCompany: React.FC<Props> = ({
  children,
  url,
  buttonLabel,
}) => {
  const data: any[] = [
    {
      label: "General Information",
      href: "/apps/setting/company/general-information",
      path: "general-information",
      permissions: "any",
      children: [],
    },
    {
      label: "Department",
      href: "/apps/setting/company/department",
      path: "department",
      permissions: "any",
      children: [],
    },
    {
      label: "Position",
      href: "/apps/setting/company/position",
      path: "position",
      permissions: "any",
      children: [],
    },
    {
      label: "Building",
      href: "/apps/setting/company/building",
      path: "building",
      permissions: "any",
      children: [],
    },
  ];

  return (
    <>
      <Grid
        container
        spacing={3}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Typography variant={"h5"}>Company</Typography>
        </Grid>
        <Grid item hidden={url === undefined}>
          <Link to={url === undefined ? "" : url}>
            <Button variant="contained" color="primary">
              {buttonLabel}
            </Button>
          </Link>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <NavigationBar data={data} type="setting" />
        </Grid>
        <Grid item xs={12} md={9}>
          {children}
        </Grid>
      </Grid>
    </>
  );
};

export default PreferencesCompany;
