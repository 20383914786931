import React, { useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@material-ui/core";
import { VuiSearch } from "../../../../../../@VodeaUI/components";
import { DeleteOutline as DeleteOutlineIcon } from "@material-ui/icons";
import {
  $clone,
  $cloneState,
  mapDataTableParams,
} from "../../../../../../utilities/helpers/global";
import { useState } from "@hookstate/core";
import VuiDataTable, {
  Column,
  Options,
} from "../../../../../../@VodeaUI/VuiDataTable/Index";
import {
  defaultDataTableOption,
  defaultDeleteConfirmOption,
} from "../../../../../../utilities/Option";
import { Link, useNavigate } from "react-router-dom";
import VuiSelect from "../../../../../../@VodeaUI/components/Input/VuiSelect/Index";
import People from "../../../../../../layouts/Apps/People";
import UserFamilyRepositories from "../../../../../../repositories/UserFamilyRepository";
import UserFamilyRepository from "../../../../../../repositories/UserFamilyRepository";
import { useIsMounted } from "../../../../../../utilities/helpers/hooks";
import { useConfirm } from "material-ui-confirm";
import { useSnackbar } from "notistack";

const FamilyData: React.FC<any> = () => {
  const navigate = useNavigate();
  const isMounted = useIsMounted();
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();

  const handleDelete = (id: any) => {
    confirm(defaultDeleteConfirmOption)
      .then(() => deleteResource(id))
      .catch(() => {});
  };

  const deleteResource = (id: number) => {
    UserFamilyRepository.delete(id)
      .then((resp: any) => {
        if (isMounted.current) {
          loadData();
          enqueueSnackbar(resp.data.message, {
            variant: "success",
          });
        }
      })
      .catch((e: any) => {
        if (isMounted.current && e?.response?.data?.errors) {
          enqueueSnackbar(e.response.data.message, {
            variant: "error",
          });
        }
      });
  };

  const columns = useState<Column[]>([
    { key: "name", label: "Name" },
    {
      key: "date_of_birth",
      label: "Birth Date",
      type: "date",
      dateFormat: "DD MMMM YYYY",
    },
    { key: "relationship", label: "Relationship" },
    { key: "gender", label: "Gender" },
    { key: "job", label: "Job" },
    { key: "religion", label: "Religion" },
    {
      key: "id",
      label: "",
      sortable: false,
      render: (row: any) => {
        return (
          <Box display="flex" justifyContent={"flex-end"}>
            <Button
              onClick={() => navigate(`update/${row.id}`)}
              className="btnView"
              variant="contained"
              color="primary"
            >
              View
            </Button>

            <IconButton onClick={() => handleDelete(row.id)}>
              <DeleteOutlineIcon fontSize="small" />
            </IconButton>

            {/* {row.can_suspend ? (
              <Tooltip title="Suspend">
                <IconButton aria-label="suspend">
                  <BlockOutlined fontSize="small" />
                </IconButton>
              </Tooltip>
            ) : null}

            {row.can_unsuspend ? (
              <Tooltip title="Unsuspend">
                <IconButton aria-label="unsuspend">
                  <CheckCircleOutlineOutlined fontSize="small" />
                </IconButton>
              </Tooltip>
            ) : null} */}
          </Box>
        );
      },
    },
  ]);

  const orderBy = useState("all");
  const loading = useState(false);
  const options = useState<Options>({
    ...defaultDataTableOption,
    sortedBy: "desc",
    orderBy: "created_at",
  });

  const tableData = useState<any[]>([]);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = () => {
    loading.set(true);

    UserFamilyRepositories.all({
      ...mapDataTableParams($cloneState(options)),
    })
      .then((response: any) => {
        const { data: responseData } = response;
        const { data, meta } = responseData;

        if (isMounted.current) {
          tableData.set(data);
          options.page.set(meta.current_page - 1);
          options.total.set(meta.total);
          loading.set(false);
        }
      })
      .catch((error: any) => {
        if (isMounted.current) {
          loading.set(false);
        }
      });
  };

  const orderByOptions = [
    {
      key: "all",
      label: "All",
    },
    {
      key: "a-z",
      label: "A-Z",
    },
  ];

  return (
    <People>
      <div>
        <Box mb={2}>
          <Grid
            container
            alignItems="center"
            spacing={3}
            justifyContent="space-between"
          >
            <Grid item>
              <Typography variant={"h6"}>Family Data</Typography>
            </Grid>
            <Grid item>
              <Link to={"create"}>
                <Button variant="contained" color="primary">
                  Add New Family
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Box>
        <Grid item xs={12}>
          <Paper variant="elevation" elevation={1}>
            <Box p={2}>
              <Grid item xs={12}>
                <Grid
                  container
                  spacing={3}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Grid item>
                    <VuiSearch
                      state={$cloneState(options.search)}
                      callback={(value) => {
                        options.search.set(value);
                        options.page.set(0);
                        loadData();
                      }}
                    />
                  </Grid>
                  {/*<Grid item>*/}
                  {/*  <VuiSelect*/}
                  {/*    label="Sorting"*/}
                  {/*    state={orderBy}*/}
                  {/*    options={orderByOptions}*/}
                  {/*  />*/}
                  {/*</Grid>*/}
                </Grid>
              </Grid>
            </Box>
            <Grid item xs={12}>
              <VuiDataTable
                loading={loading.get()}
                columns={columns.value}
                options={$clone(options.value)}
                data={tableData.get()}
                onChangeOptions={(values: Options) => {
                  options.set(values);
                  loadData();
                }}
              />
            </Grid>
          </Paper>
        </Grid>
      </div>
    </People>
  );
};

export default FamilyData;
