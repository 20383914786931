import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    gridCardContainer : {
        paddingLeft: '8px',
        marginBottom: '8px'
    },

    subtitle1: {
        fontWeight: 600,
        color: "#1A1818",
    },

    subtitle2: {
        fontWeight: 400,
        color: "#707070"
    },

    '.MuiTypography-body1.active' : {
        backgroundColor: 'red'
    },

    '.MuiTypography-body1' : {
        color: 'red'
    }
}));
