import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Checkbox, Grid } from "@material-ui/core";
import VuiCardContent from "../../../../../@VodeaUI/components/VuiCardContent";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import _ from "lodash";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  MuiAutoComplete,
  MuiButton,
  MuiDatePicker,
  MuiTextField,
} from "../../../../../components/atoms";
import { constantToOptions } from "../../../../../utilities/helpers/option.helper";
import { useTranslation } from "react-i18next";
import { IConstantOption } from "../../../../../interfaces/ConstantOption";
import moment, { Moment } from "moment";
import TitleForm from "../../../../../components/molecules/TitleForm";
import ReimbursementPolicyRepository from "../../../../../repositories/ReimbursementPolicyRepository";
import CashAdvancePolicyRepository from "../../../../../repositories/CashAdvancePolicyRepository";
import TimeOffPolicyRepository from "../../../../../repositories/TimeOffPolicyRepository";
import TimeOffReimbursePolicyRepository from "../../../../../repositories/TimeOffReimbursePolicyRepository";
import AttendancePolicyRepository from "../../../../../repositories/AttendancePolicyRepository";
import { backendDate } from "../../../../../utilities/helpers/date.helper";
import { optionLabel } from "../../../../../utilities/helpers/select";
import ModalEmploymentData from "../../../People/Employee/Employment/ModalEmploymentData";
import { IUser } from "../../../../../interfaces/User";
import UserRepository from "../../../../../repositories/UserRepository";
import { AxiosError, AxiosResponse } from "axios";
import { IApiResource } from "../../../../../interfaces/ApiResource";
import useIsMounted from "../../../../../utilities/hooks/use-is-mounted.hook";
import { groupByAttribute } from "../../../../../utilities/helpers/array.helper";
import FooterFormAction from "../../../../../components/FooterFormAction";
import { formatFormData } from "../../../../../utilities/helpers/form";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import ReimbursementRepository from "../../../../../repositories/ReimbursementRepository";
import { useDocumentTitle } from "../../../../../@VodeaUI";
import TimeOffRepository from "../../../../../repositories/TimeOffRepository";
import AssignPolicyRepository from "../../../../../repositories/AssignPolicyRepository";
import { handleAxiosSuccessSave } from "../../../../../utilities/helpers/axios-success.helper";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router";
import {
  axiosErrorLoadDataHandler,
  handleAxiosErrorSave,
} from "../../../../../utilities/helpers/axios-error.helper";
import { generatePolicyFromModel } from "../../../../../utilities/helpers/string";
import { validationSchema } from "./validation";
import { yupResolver } from "@hookform/resolvers/yup";
import EmployeeList from "./EmployeeList";

export interface ITransactionPolicyUser {
  user_id: string;
  checked: boolean;
}

interface ITransactionPolicy {
  entity: string;
  policy_id: IConstantOption | null;
  description: string;
  subject_id: IConstantOption | null;
  type: IConstantOption | null;
  effective_from: string | Moment;
  transaction_policy_users: ITransactionPolicyUser[];
  formula_component_id: IConstantOption | null;
}

const defaultValues: ITransactionPolicy = {
  entity: "",
  description: "",
  type: null,
  subject_id: null,
  policy_id: null,
  effective_from: moment(),
  transaction_policy_users: [],
  formula_component_id: null,
};

interface IRepositoryCode {
  [key: string]: any;
}

const PolicyCode: IRepositoryCode = {
  REIMBURSEMENT: ReimbursementPolicyRepository,
  CASH_ADVANCE: CashAdvancePolicyRepository,
  TIME_OFF: TimeOffPolicyRepository,
  TIME_OFF_REIMBURSE: TimeOffReimbursePolicyRepository,
  ATTENDANCE: AttendancePolicyRepository,
};

const FormulaCode: IRepositoryCode = {
  REIMBURSEMENT: ReimbursementPolicyRepository,
  CASH_ADVANCE: CashAdvancePolicyRepository,
};

const fieldOptions: Array<IConstantOption & { type: string }> = [
  { id: "balance", name: "Balance", type: "number" },
  { id: "effective_from", name: "On", type: "date" },
  { id: "expired_at", name: "Expired", type: "date" },
];

const icon = <CheckBoxOutlineBlankIcon />;
const checkedIcon = <CheckBoxIcon />;

export interface IEmployeeList {
  balance: number;
  effective_from: string;
  expired_at: null | string;
  generated_at?: string;
  id: number;
  pending_balance?: number;
  real_balance?: number;
  reimbursement_formula_policy_id?: number;
  used_balance?: number;
  user_id: number;
  user: IUser;
}

const AssignPolicyForm: React.FC = () => {
  const isMounted = useIsMounted();
  const { id } = useParams();
  const { t } = useTranslation();
  const title = id ? "Edit Assign Policy" : "Add Assign Policy";
  useDocumentTitle(title);
  const { constant } = useSelector(({ constant }: any) => {
    return {
      constant: constant.constant.payload,
    };
  });
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [tableColumns, setTableColumns] = useState<any[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [userTreeData, setUserTreeData] = useState<IUser[]>([]);
  const [data, setData] = useState<ITransactionPolicy>(defaultValues);
  const [realData, setRealData] = useState<IUser[]>([]);
  const [isFetchingUser, setIsFetchingUser] = useState<boolean>(false);
  const transactionTypeOptions: IConstantOption[] = constantToOptions(
    constant,
    "TRANSACTION_POLICY_TYPE_OPTIONS"
  );

  const loadData = useCallback(async () => {
    if (!id) {
      return;
    }

    await AssignPolicyRepository.show(id, {
      with: [
        "subject",
        "transactionPolicyUsers.user.roleUser.role.department",
        "formulaComponent.subject",
      ],
    })
      .then((response: AxiosResponse) => {
        if (isMounted.current) {
          const { data: responseData } = response.data;
          Object.assign(responseData, {
            type:
              transactionTypeOptions.find(
                (type: IConstantOption) => type?.id === responseData?.type
              ) || null,
            policy_id: generatePolicyFromModel(responseData?.entity) || null,
            subject_id: responseData?.subject || null,
            formula_component_id: responseData?.formula_component.subject,
            description: responseData?.description || "",
          });
          setData(responseData);
        }
      })
      .catch((error: AxiosError) => {
        axiosErrorLoadDataHandler(error, enqueueSnackbar);
      });
  }, [id]);

  const { handleSubmit, control, errors, watch, setValue, reset, setError } =
    useForm<ITransactionPolicy>({
      mode: "onChange",
      resolver: yupResolver(validationSchema(t)),
      defaultValues: useMemo(() => {
        (async () => {
          await loadData();
        })();
        return data;
      }, [id, loadData]),
    });

  const { fields: employeeFields } = useFieldArray({
    control,
    keyName: "key",
    name: "transaction_policy_users",
  });

  const watchType = watch("type");
  const watchPolicy = watch("policy_id");
  const watchEffectiveFrom = watch("effective_from");
  const watchSubject = watch("subject_id");
  const watchFormula = watch("formula_component_id");

  const policyOptions = useMemo(() => {
    return constantToOptions(
      constant,
      `TRANSACTION_POLICY_TYPE_ENTITY_OPTIONS.${
        watchType ? watchType?.id : "ASSIGN"
      }`
    );
  }, [watchType]);

  useEffect(() => {
    reset(data);
  }, [data]);

  const loadUserData = useCallback(async () => {
    if (userTreeData.length) {
      return;
    }

    if (isMounted.current) setIsFetchingUser(true);

    await UserRepository.select({
      for: "transaction-policy",
      with: ["roleUser.role.department"],
    })
      .then((response: AxiosResponse<IApiResource<IUser[]>>) => {
        const { data: responseData } = response.data;
        const groupedByDepartment = groupByAttribute(
          responseData,
          "department_name"
        );
        const temp: any[] = [];
        Object.keys(groupedByDepartment).map((key: string) => {
          const groupedByRole = groupByAttribute(
            groupedByDepartment[key],
            "role_name"
          );
          const children: any[] = [];
          Object.keys(groupedByRole).map((role: string) => {
            if (role !== "null") {
              children.push({
                label: role,
                value: `role${role}${groupedByRole[role][0].id}`,
                children: groupedByRole[role].map((user: IUser) => {
                  return {
                    ...user,
                    value: user.id,
                    label: user.name,
                  };
                }),
              });
            }
          });
          if (key !== "null") {
            temp.push({
              label: key,
              value: `department${key}${groupedByDepartment[key][0]?.department_id}`,
              children: children,
            });
          }
        });

        if (isMounted.current) {
          setUserTreeData(temp);
          setRealData(responseData);
          setIsFetchingUser(false);
        }
      })
      .catch((error: AxiosError) => {
        if (isMounted.current) setIsFetchingUser(false);
      });
  }, [userTreeData]);

  const handleOpenModal = useCallback(() => {
    setOpenModal(true);
    loadUserData().then();
  }, [userTreeData]);

  const handleCloseModal = useCallback(() => setOpenModal(false), []);

  const onSubmitEmployee = useCallback(
    async (data) => {
      if (data.length) {
        if (watchType?.id === "UPDATE_BALANCE") {
          const params = {
            user: data.map((user: IUser) => user.id),
            for: "transaction-policy",
            with: ["user.roleUser.role.department"],
            ...(watchPolicy?.id === "REIMBURSEMENT"
              ? { "reimbursement-formula-policy": watchFormula?.id }
              : { "time-off-formula-policy": watchFormula?.id }),
          };

          (watchPolicy?.id === "REIMBURSEMENT"
            ? await ReimbursementRepository.getBalance(params)
            : await TimeOffRepository.getTimeOffBalance(params)
          )
            .then((response: AxiosResponse) => {
              setValue("transaction_policy_users", response.data.data);
            })
            .catch(() => {});
        } else {
          setValue("transaction_policy_users", data);
        }
      }
      handleCloseModal();
    },
    [watchPolicy, watchFormula]
  );

  const onChangeField = (newValue: IConstantOption[]) => {
    setTableColumns(newValue);
  };

  const generateEntity = (policy: string = "") => {
    const defaultEntity = "App\\Models\\";
    if (policy === "REIMBURSEMENT") {
      return defaultEntity + "ReimbursementPolicy";
    } else if (policy === "CASH_ADVANCE") {
      return defaultEntity + "CashAdvancePolicy";
    } else if (policy === "TIME_OFF_REIMBURSE") {
      return defaultEntity + "TimeOffReimbursePolicy";
    } else if (policy === "ATTENDANCE") {
      return defaultEntity + "AttendancePolicy";
    } else {
      return defaultEntity + "TimeOffPolicy";
    }
  };

  const onSubmit = async (data: ITransactionPolicy) => {
    if (isMounted.current) setLoading(true);
    const formData = formatFormData(data);
    formData.type = formData.type.id;
    formData.entity = generateEntity(formData.policy_id);

    if (formData.transaction_policy_users.length) {
      formData.transaction_policy_users = formData.transaction_policy_users.map(
        (user: IEmployeeList & ITransactionPolicyUser) => {
          if (user.checked) {
            return {
              user_id: user.id,
              ...(user.effective_from
                ? { effective_from: backendDate(user.effective_from) }
                : {}),
              ...(user.balance ? { balance: user.balance } : {}),
              ...(user.expired_at
                ? { expired_at: backendDate(user.expired_at) }
                : {}),
            };
          }
        }
      );
    }

    delete formData.policy_id;

    if (id) {
      await AssignPolicyRepository.update(id, formData)
        .then((response: AxiosResponse) => {
          if (isMounted.current) setLoading(false);
          handleAxiosSuccessSave(
            response,
            enqueueSnackbar,
            navigate,
            "/apps/setting/assign-policy"
          );
        })
        .catch((error: AxiosError) => {
          if (isMounted.current) setLoading(false);
          handleAxiosErrorSave(error, setError, enqueueSnackbar);
        });
    } else {
      await AssignPolicyRepository.create(formData)
        .then((response: AxiosResponse) => {
          if (isMounted.current) setLoading(false);
          handleAxiosSuccessSave(
            response,
            enqueueSnackbar,
            navigate,
            "/apps/setting/assign-policy"
          );
        })
        .catch((error: AxiosError) => {
          if (isMounted.current) setLoading(false);
          handleAxiosErrorSave(error, setError, enqueueSnackbar);
        });
    }
  };

  const renderFormula = useMemo(() => {
    return watchPolicy?.id === "REIMBURSEMENT" ||
      watchPolicy?.id === "CASH_ADVANCE" ? (
      <Grid item md={12} xs={12}>
        <Controller
          render={({ value, name, onChange }) => (
            <MuiAutoComplete
              repository={watchPolicy && FormulaCode[watchPolicy.id]}
              disabled={!watchSubject}
              methodName={"formula"}
              params={{
                for: "transaction-policy",
                type: watchType?.id,
              }}
              paramsId={watchSubject?.id}
              value={value}
              onSelected={(newValue) => onChange(newValue)}
              muiTextField={{
                label: t("form.formula.label"),
                error: _.has(errors, name),
                helperText: _.get(errors, `${name}.message`),
              }}
            />
          )}
          name={"formula_component_id"}
          control={control}
        />
      </Grid>
    ) : null;
  }, [watchType, watchPolicy, watchSubject]);

  return (
    <>
      <ModalEmploymentData
        isLoading={isFetchingUser}
        open={openModal}
        handleClose={handleCloseModal}
        data={userTreeData}
        realData={realData}
        callback={onSubmitEmployee}
      />

      <TitleForm
        title={title}
        withBackUrl={true}
        component={
          !id ? (
            <MuiButton onClick={handleOpenModal} label={"Add Employee"} />
          ) : null
        }
      />

      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <VuiCardContent title="Information">
            <Box p={2}>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <Controller
                    name={"type"}
                    control={control}
                    render={({ value, onChange, name }) => {
                      return (
                        <MuiAutoComplete
                          isAsync={false}
                          constantOptions={transactionTypeOptions}
                          value={value}
                          onSelected={(newValue) => {
                            onChange(newValue);
                            setValue("policy_id", null);
                            setValue("subject_id", null);
                          }}
                          muiTextField={{
                            label: t("form.type.label"),
                            error: _.has(errors, name),
                            helperText: _.get(errors, `${name}.message`),
                          }}
                        />
                      );
                    }}
                  />
                </Grid>

                <Grid item md={12} xs={12}>
                  <Controller
                    name={"policy_id"}
                    control={control}
                    render={({ value, onChange, name }) => {
                      return (
                        <MuiAutoComplete
                          isAsync={false}
                          disabled={!watchType}
                          constantOptions={policyOptions}
                          value={value}
                          onSelected={(newValue) => {
                            onChange(newValue);
                            setValue("subject_id", null);
                          }}
                          muiTextField={{
                            label: t("form.policy.label"),
                            error: _.has(errors, name),
                            helperText: _.get(errors, `${name}.message`),
                          }}
                        />
                      );
                    }}
                  />
                </Grid>

                <Grid item md={12} xs={12}>
                  <Controller
                    name="effective_from"
                    control={control}
                    render={({ value, name, onChange }) => (
                      <MuiDatePicker
                        value={value}
                        name={name}
                        label={t("form.effectiveOn.label")}
                        format="DD MMMM YYYY"
                        onChange={onChange}
                        error={_.has(errors, name)}
                        helperText={_.get(errors, `${name}.message`)}
                      />
                    )}
                  />
                </Grid>

                <Grid item md={12} xs={12}>
                  <Controller
                    render={({ value, name, onChange }) => (
                      <MuiAutoComplete
                        repository={watchPolicy && PolicyCode[watchPolicy.id]}
                        params={{
                          for: "transaction-policy",
                          type: watchType?.id,
                          valid_from: backendDate(watchEffectiveFrom),
                        }}
                        disabled={!watchPolicy}
                        methodName={"all"}
                        value={value}
                        onSelected={(newValue) => {
                          onChange(newValue);
                          setValue("formula_component_id", null);
                        }}
                        muiTextField={{
                          label: t("form.policyCode.label"),
                          error: _.has(errors, name),
                          helperText: _.get(errors, `${name}.message`),
                        }}
                      />
                    )}
                    name={"subject_id"}
                    control={control}
                  />
                </Grid>

                {renderFormula}

                <Grid item md={12} xs={12}>
                  <Controller
                    name={"description"}
                    control={control}
                    render={({ value, name, onChange }) => {
                      return (
                        <MuiTextField
                          name={name}
                          onChange={onChange}
                          label={t("form.policyDescription.label")}
                          value={value}
                          error={_.has(errors, name)}
                          helperText={_.get(errors, `${name}.message`)}
                        />
                      );
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </VuiCardContent>
        </Grid>

        <Grid item xs={12} md={8}>
          <VuiCardContent title="Employee List">
            {watchType?.id === "ADD_BALANCE" ||
            watchType?.id === "UPDATE_BALANCE" ? (
              <Box p={2}>
                <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                    <MuiAutoComplete
                      isAsync={false}
                      multiple
                      renderTags={(value) => {
                        return (
                          <Box marginLeft={1} marginTop={0.25}>
                            {`${
                              value.length === fieldOptions.length
                                ? "All"
                                : value.length
                            } Items Selected`}
                          </Box>
                        );
                      }}
                      defaultValue={tableColumns}
                      constantOptions={fieldOptions}
                      disableCloseOnSelect
                      getOptionLabel={(option) => optionLabel(option)}
                      onSelected={onChangeField}
                      renderOption={(option, { selected }) => (
                        <>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.name}
                        </>
                      )}
                      muiTextField={{
                        label: t("form.fields.label"),
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            ) : null}

            <EmployeeList
              data={employeeFields}
              control={control}
              errors={errors}
              tableColumns={tableColumns}
            />
          </VuiCardContent>
        </Grid>

        {!id && (
          <FooterFormAction
            cancelUrl={"/apps/setting"}
            loading={loading}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
          />
        )}
      </Grid>
    </>
  );
};

export default AssignPolicyForm;
