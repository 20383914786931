import * as yup from "yup";

export const validationSchema = () => {
  let shapes = {
    user_id: yup.mixed().required().label("employee"),
    cash_advance_policy_id: yup.mixed().required().label("advance category"),
    description: yup.string().required().label("purpose"),
    date: yup.mixed().required().label("use on"),
  };

  return yup.object().shape(shapes);
};
