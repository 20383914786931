import { api } from "../@VodeaUI";

let url = window._env_.REACT_APP_PASSPORT_END_POINT;

const ReimbursementRepository = {
  select: function (params: any = null) {
    return api.get(`${url}/api/select/payroll-component`, {
      params,
    });
  },
};

export default ReimbursementRepository;
