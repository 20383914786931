import React from "react";
import { useRoutes } from "react-router-dom";
import routes from "./routes";
import "./assets/sass/app.scss";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

const App: React.FC<any> = () => {
  const routing = useRoutes(routes);

  return (
    <>
      {routing} <ToastContainer />
    </>
  );
};

export default App;
