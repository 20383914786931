import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@material-ui/core";
import React, { useEffect, useRef } from "react";
import { useStyles } from "./Style";
import { State, useState } from "@hookstate/core/dist";

interface Props {
  label: string;
  state?: State<string>;
  options: Array<{
    key: any;
    label: string;
  }>;
  fullWidth?: boolean;
  required?: boolean;
  error?: boolean;
  helperText?: string;
}

const VuiSelect: React.FC<Props> = ({
  label,
  state,
  options,
  fullWidth = false,
  required = false,
  error = false,
  helperText = "Field is required",
}) => {
  const classes = useStyles();
  const labelRef = useRef<any>(null);
  const labelWidth = useState(0);

  const handleChange = (event: React.ChangeEvent<{ value: any }>) => {
    if (state) {
      state.set(event.target.value);
    }
  };

  useEffect(() => {
    if (labelRef.current !== undefined) {
      labelWidth.set(labelRef.current.clientWidth);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // @ts-ignore
  return (
    <FormControl
      variant="outlined"
      classes={{
        root: classes.root,
      }}
      fullWidth={fullWidth}
      error={error}
    >
      <InputLabel ref={labelRef} required={required}>
        {label}
      </InputLabel>

      {state ? (
        <Select
          input={
            <OutlinedInput
              labelWidth={labelWidth.get()}
              name={label}
              className={classes.textInput}
            />
          }
          fullWidth
          value={state.get()}
          onChange={handleChange}
        >
          {options.map((item, key) => {
            return (
              <MenuItem key={key} value={item.key}>
                {item.label}
              </MenuItem>
            );
          })}
        </Select>
      ) : (
        <Select
          input={<OutlinedInput labelWidth={labelWidth.get()} name={label} />}
          fullWidth
        >
          {options.map((item, key) => {
            return (
              <MenuItem key={key} value={item.key}>
                {item.label}
              </MenuItem>
            );
          })}
        </Select>
      )}

      {error && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default VuiSelect;
