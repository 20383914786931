import * as actions from "../../actions/vodea";

const initialState = {
  counter: 0,
};

const counter = function (state = initialState, action: any) {
  switch (action.type) {
    case actions.COUNTER_INCREMENT:
      return {
        ...state,
        counter: state.counter + 1,
      };
    case actions.COUNTER_DECREMENT:
      return {
        ...state,
        counter: state.counter - 1,
      };
    case actions.COUNTER_RESET:
      return {
        ...state,
        counter: 0,
      };
    default:
      return state;
  }
};

export default counter;
