import * as yup from "yup";
import { TFunction } from "react-i18next";

export const validationSchema = (t: TFunction) => {
  let shapes = {
    asset_id: yup.mixed().required().label(t("form.assetName.label")),
    user_id: yup.mixed().required().label(t("form.assetUser.label")),
    date: yup.date().required().label(t("form.date.label")),
    description: yup.string().label(t("form.description.label")),
    type: yup.mixed().required().label(t("form.assetType.label")),
  };

  return yup.object().shape(shapes);
};
