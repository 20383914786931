import { ICreator } from "./Creator";
import { ICashAdvancePolicy } from "./CashAdvancePolicy";
import { IApprovalPolicy } from "./ApprovalPolicy";
import { IApprovalRequest } from "./ApprovalRequest";
import { ICashAdvanceRequestItem } from "./CashAdvanceRequestItem";
import moment, { Moment } from "moment";
import { IUser } from "./User";

export interface ICashAdvanceRequest {
  id?: number;
  user_id?: any;
  cash_advance_policy_id: any;
  date: string | Moment | null;
  cash_advance_request_items: Array<ICashAdvanceRequestItem>;
  description: string;

  amount: number;
  creator: ICreator | null;
  user: IUser | null;
  number: string;
  remarks: null | string;
  status: string;
  approval_logs: Array<any>;
  approval_requests: Array<IApprovalRequest>;
  approvals: Array<IApprovalPolicy>;
  cash_advance_policy: ICashAdvancePolicy | null;
  can_approve: boolean;
  can_cancel: boolean;
  can_decline: boolean;
  can_update: boolean;
  updated_at: string | null;
  created_at: string | null;
}

export const cashAdvanceRequest: ICashAdvanceRequest = {
  user_id: null,
  cash_advance_policy_id: null,
  amount: 0,
  creator: null,
  date: moment(),
  description: "",
  number: "",
  remarks: null,
  user: null,
  status: "",
  approval_logs: [],
  approval_requests: [],
  approvals: [],
  cash_advance_policy: null,
  cash_advance_request_items: [],
  can_approve: false,
  can_cancel: false,
  can_decline: false,
  can_update: false,
  updated_at: null,
  created_at: null,
};
