import React, { useCallback, useEffect } from "react";
import { Box, Grid, IconButton, Tooltip } from "@material-ui/core";
import { MoreHoriz } from "@material-ui/icons";
import {
  $clone,
  $cloneState,
  mapDataTableParams,
} from "../../../../../utilities/helpers/global";
import { useState } from "@hookstate/core/dist";
import VuiDataTable, {
  Column,
  Options,
} from "../../../../../@VodeaUI/VuiDataTable/Index";
import { defaultDataTableOption } from "../../../../../utilities/Option";
import useIsMounted from "../../../../../@VodeaUI/hooks/useIsMounted";
import Policy from "../../../../../layouts/Apps/Preference/Policy";
import VuiCardContent from "../../../../../@VodeaUI/components/VuiCardContent";
import { AxiosError, AxiosResponse } from "axios";
import TimeOffPolicyRepository from "../../../../../repositories/TimeOffPolicyRepository";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import {
  MuiAutoComplete,
  MuiButton,
  MuiSearch,
} from "../../../../../components/atoms";
import { sortedByOptions } from "../../../../../constants";
import { useTranslation } from "react-i18next";

const TimeOffPolicy: React.FC<any> = () => {
  const isMounted = useIsMounted();
  const { t } = useTranslation();
  const loading = useState(false);
  const options = useState<Options>({
    ...defaultDataTableOption,
    sortedBy: "desc",
    orderBy: "id",
  });
  const navigate = useNavigate();

  const tableData = useState([]);

  const columns = useState<Column[]>([
    { key: "code", label: "POLICY NAME" },
    { key: "name", label: "POLICY CODE" },
    {
      key: "valid_from",
      label: "EFFECTIVE ON",
      render: (row) => {
        return <div>{moment(row.valid_from).format("DD MMMM YYYY")}</div>;
      },
    },
    {
      key: "detail",
      label: "",
      sortable: false,
      render: (row) => {
        return (
          <Box display="flex" justifyContent={"flex-end"} key={row.id}>
            <Link to={"apps/setting/assign-policy/create"}>
              <MuiButton label="Assign Policy" size="small" />
            </Link>
          </Box>
        );
      },
    },
    {
      key: "id",
      label: "",
      sortable: false,
      render: (row) => {
        return (
          <Box display="flex" justifyContent={"flex-end"} key={row.id}>
            <Tooltip title="Suspend">
              <IconButton
                aria-label="more"
                onClick={() =>
                  navigate(`/apps/setting/policy/time-off/${row.id}`)
                }
              >
                <MoreHoriz fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
  ]);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = () => {
    if (isMounted.current) loading.set(true);
    TimeOffPolicyRepository.all({
      ...mapDataTableParams($cloneState(options)),
    })
      .then((resp: AxiosResponse) => {
        const { data: responseData } = resp;
        const { data, meta } = responseData;

        if (isMounted.current) {
          tableData.set(data);
          options.page.set(meta.current_page - 1);
          options.total.set(meta.total);
          loading.set(false);
        }
      })
      .catch((err: AxiosError) => {
        if (isMounted.current) loading.set(false);
      });
  };

  const handleTableSearch = useCallback((value: string) => {
    if (isMounted.current) {
      options.search.set(value);
      options.page.set(0);
      loadData();
    }
  }, []);

  return (
    <Policy
      url="/apps/setting/policy/time-off/create"
      buttonLabel="Add Time Off Policy"
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <VuiCardContent title="Time off policy">
            <Box p={2}>
              <Grid container spacing={3} alignItems={"center"}>
                <Grid item md={12} xs={12}>
                  <Grid
                    container
                    spacing={3}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Grid item>
                      <MuiSearch
                        value={$clone(options.search.value)}
                        callback={handleTableSearch}
                      />
                    </Grid>
                    <Grid item md={3}>
                      <MuiAutoComplete
                        isAsync={false}
                        value={sortedByOptions.find(
                          (option) =>
                            option.id === $clone(options.sortedBy.value)
                        )}
                        constantOptions={sortedByOptions}
                        onSelected={(newValue) => {
                          options.sortedBy.set(newValue.id);
                          loadData();
                        }}
                        muiTextField={{
                          label: t("form.sort.label"),
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>

            <VuiDataTable
              loading={loading.get()}
              columns={columns.value}
              options={$clone(options.value)}
              data={tableData.get()}
              onChangeOptions={(values: Options) => {
                options.set(values);
                loadData();
              }}
            />
          </VuiCardContent>
        </Grid>
      </Grid>
    </Policy>
  );
};

export default TimeOffPolicy;
