import React, { useEffect } from "react";
import PreferencesPayroll from "../../../../../layouts/Apps/Preference/Payroll";
import { Box, FormControl, Grid, TextField } from "@material-ui/core";
import VuiCardContent from "../../../../../@VodeaUI/components/VuiCardContent";
import _ from "lodash";
import { Controller, useForm } from "react-hook-form";
import { Autocomplete } from "@material-ui/lab";
import SettingRepository from "../../../../../repositories/SettingRepository";
import { AxiosResponse } from "axios";
import { useSelector } from "react-redux";
import { constantToOptions } from "../../../../../utilities/helpers/option.helper";
import { $clone, mapHookErrors } from "../../../../../utilities/helpers/global";
import { none, useState } from "@hookstate/core";
import {
  DataInterface,
  OptionInterface,
  DataCutOffInterface,
} from "./interface";
import FooterFormAction from "../../../../../components/FooterFormAction";
import { useSnackbar } from "notistack";

const initializeData = {
  id: "",
  type: "",
  attributes: {
    day: "",
    holiday: { value: null, label: "" },
  },
};

const initializeDataCutOff = {
  id: "",
  type: "",
  attributes: {
    attendance_period: "",
    payroll_period: "",
  },
};

const SchedulePayroll: React.FC<any> = () => {
  const [data, setData] = React.useState<DataInterface>(initializeData);
  const [dataCutOff, setDataCutOff] =
    React.useState<DataCutOffInterface>(initializeDataCutOff);
  const loading = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const { constant } = useSelector(({ constant }: any) => {
    return {
      constant: constant.constant.payload,
    };
  });

  const { control, errors, setValue, register, setError, reset, handleSubmit } =
    useForm({
      mode: "onChange",
    });

  const option = useState<OptionInterface>({
    holiday: {
      options: [{ value: null, label: "" }],
      loading: false,
      params: {},
      loadData: async () => {
        option.holiday.loading.set(true);
        let temp2 = constantToOptions(
          constant,
          "SETTING_PAYROLL_SCHEDULE_HOLIDAY_TYPE_OPTIONS"
        );

        await option.holiday.options.set(temp2);

        await option.holiday.loading.set(false);
      },
      onOpen: async () => {
        await option.holiday.options.set([]);
        await option.holiday.loadData.get()();
      },
    },
  });

  useEffect(() => {
    option.holiday.onOpen.get()();
    loadData().catch(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data) {
      console.log(data, "data");
      reset(data);
    }

    if (dataCutOff) {
      console.log(dataCutOff, "data cutoff");
      reset(dataCutOff);
    }
  }, [data, dataCutOff]);

  const loadData = async () => {
    let params: any = {};

    SettingRepository.getPayrollSchedule({
      ...params,
    })
      .then(async (response: AxiosResponse) => {
        let tempResponse = _.get(response, "data.data");

        let temp = {
          ...tempResponse,
          attributes: {
            day: tempResponse.attributes.day.toString(),
            holiday: $clone(option.holiday.options.get()).find(
              (h: any) => h.value === tempResponse.attributes.holiday
            ),
          },
        };

        await setData(temp);
        setValue("holiday", temp.attributes.holiday);
        setValue("day", temp.attributes.day.toString());
      })
      .catch((e: any) => {
        console.log(e);
      });

    SettingRepository.getPayrollCutOff({
      ...params,
    })
      .then(async (response: AxiosResponse) => {
        let tempResponse = _.get(response, "data.data");

        let temp = {
          ...tempResponse,
          attributes: {
            attendance_period:
              tempResponse.attributes.attendance_period.toString(),
            payroll_period: tempResponse.attributes.payroll_period.toString(),
          },
        };

        setDataCutOff(temp);
      })
      .catch((e: any) => {
        console.log(e);
      });
  };

  const onSubmit = (formData: any) => {
    loading.set(true);

    console.log("formData", formData);

    let body: any = {
      attributes: {
        day: formData.day,
        holiday: formData.holiday.value,
      },
      id: data.id,
    };

    let bodyCutOff: any = {
      attributes: {
        attendance_period: formData.attributes.attendance_period,
        payroll_period: formData.attributes.payroll_period,
      },
      id: dataCutOff.id,
    };

    SettingRepository.updatePayrollSchedule({ ...body })
      .then(() => {
        loading.set(false);
      })
      .catch((e: any) => {
        if (e?.response?.data?.errors) {
          const errors = mapHookErrors(e.response.data.errors);
          Object.keys(errors).forEach((key: any) => setError(key, errors[key]));

          enqueueSnackbar(e.response.data.message, {
            variant: "error",
          });
        }

        loading.set(false);
      });

    SettingRepository.updatePayrollCutOff({ ...bodyCutOff })
      .then(() => {
        loading.set(false);
      })
      .catch((e: any) => {
        if (e?.response?.data?.errors) {
          const errors = mapHookErrors(e.response.data.errors);
          Object.keys(errors).forEach((key: any) => setError(key, errors[key]));

          enqueueSnackbar(e.response.data.message, {
            variant: "error",
          });

          loading.set(false);
        }
      });
  };

  const hasError = (key: string) => {
    if (_.has(errors, `attributes.${key}`)) {
      return true;
    }

    return _.has(errors, key);
  };

  const getError = (key: string) => {
    if (_.has(errors, `attributes.${key}`)) {
      return _.get(errors, `attributes.${key}.message`);
    }

    return _.get(errors, `${key}.message`);
  };

  return (
    <PreferencesPayroll>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <VuiCardContent title="Payroll Schedule">
            <Box p={2}>
              <Grid container spacing={2}>
                <Grid item md={4} xs={12}>
                  {/* <FormControl fullWidth margin={"normal"}> */}
                  <Controller
                    as={TextField}
                    key="Confirmation Code"
                    name="day"
                    // margin = {"normal"}
                    control={control}
                    variant="outlined"
                    // defaultValue=""
                    label="Payroll will be scheduled on this date"
                    InputLabelProps={{ shrink: true }}
                    fullWidth={true}
                    error={hasError("day")}
                    helperText={getError("day")}
                  />
                  {/* </FormControl> */}
                </Grid>
                <Grid item md={4} xs={12}>
                  <Controller
                    render={({ value, name, onChange }) => {
                      return (
                        <Autocomplete
                          value={value}
                          disableCloseOnSelect
                          loading={option.holiday.loading.get()}
                          options={$clone(option.holiday.options.get())}
                          filterOptions={(options) => options}
                          onOpen={option.holiday.onOpen.get()}
                          getOptionLabel={(option: any) => option?.label}
                          getOptionSelected={(option: any, value: any) =>
                            option?.key === value?.key
                          }
                          onChange={(event: any, newValue: any) =>
                            onChange(newValue)
                          }
                          onInputChange={_.debounce(
                            (event: any, value, reason) => {
                              if (reason === "input") {
                                option.holiday.params.search.set(value || none);
                                option.holiday.onOpen.get()();
                              }
                            },
                            200
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name={name}
                              label="Round Type"
                              variant="outlined"
                              error={_.has(errors, "holiday")}
                              helperText={_.get(errors, "holiday.message")}
                            />
                          )}
                        />
                      );
                    }}
                    name="holiday"
                    control={control}
                    defaultValue={data.attributes.holiday}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <Controller
                    render={({ value, name, onChange }) => {
                      return (
                        <Autocomplete
                          value={value}
                          disableCloseOnSelect
                          loading={option.holiday.loading.get()}
                          options={$clone(option.holiday.options.get())}
                          filterOptions={(options) => options}
                          onOpen={option.holiday.onOpen.get()}
                          getOptionLabel={(option: any) => option.label}
                          getOptionSelected={(option: any, value: any) =>
                            option.key === value.key
                          }
                          onChange={(event: any, newValue: any) =>
                            onChange(newValue)
                          }
                          onInputChange={_.debounce(
                            (event: any, value, reason) => {
                              if (reason === "input") {
                                option.holiday.params.search.set(value || none);
                                option.holiday.onOpen.get()();
                              }
                            },
                            200
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name={name}
                              label="Holiday"
                              variant="outlined"
                              error={_.has(errors, "holiday")}
                              helperText={_.get(errors, "holiday.message")}
                            />
                          )}
                        />
                      );
                    }}
                    name="holiday"
                    control={control}
                    defaultValue={data.attributes.holiday}
                  />
                </Grid>
              </Grid>
            </Box>
          </VuiCardContent>
        </Grid>

        <Grid item xs={12} md={12}>
          <VuiCardContent title="Cut Off">
            <Box p={2}>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <Controller
                    render={({ value, name, onChange }) => {
                      return (
                        <TextField
                          name={name}
                          label="Attendance"
                          InputProps={{ inputProps: { min: 1 } }}
                          value={value}
                          variant="outlined"
                          onChange={onChange}
                          fullWidth
                          error={_.has(errors, "attributes.attendance_period")}
                          helperText={_.get(
                            errors,
                            "attributes.attendance_period.message"
                          )}
                        />
                      );
                    }}
                    name={"attributes.attendance_period"}
                    control={control}
                    defaultValue={""}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Controller
                    render={({ value, name, onChange }) => {
                      return (
                        <TextField
                          name={name}
                          label="Payroll"
                          InputProps={{ inputProps: { min: 1 } }}
                          value={value}
                          variant="outlined"
                          onChange={onChange}
                          fullWidth
                          error={_.has(errors, "attributes.payroll_period")}
                          helperText={_.get(
                            errors,
                            "attributes.payroll_period.message"
                          )}
                        />
                      );
                    }}
                    name={"attributes.payroll_period"}
                    control={control}
                    defaultValue={""}
                  />
                </Grid>
              </Grid>
            </Box>
          </VuiCardContent>
        </Grid>

        {/* <FooterFormAction
          cancelUrl={null}
          loading={loading.get()}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
        /> */}
      </Grid>
    </PreferencesPayroll>
  );
};

export default SchedulePayroll;
