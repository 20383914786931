import React, { useEffect, useState } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Grid, Typography } from "@material-ui/core";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { useLocation, useParams } from "react-router";
import { AxiosResponse } from "axios";
import EmployeeRepository from "../../repositories/EmployeeRepository";
import _ from "lodash";
import MuiAvatar from "@material-ui/core/Avatar";
import { useStyles } from "./style";

const Avatar = withStyles({
  root: {
    width: "45px",
    height: "45px",
  },
})(MuiAvatar);

const Accordion = withStyles({
  root: {
    boxShadow: "none",
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
    "& a": {
      color: "inherit",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    marginBottom: -1,
    minHeight: "unset",
    paddingTop: "6px",
    paddingBottom: "6px",
    paddingLeft: "12px",
    paddingRight: "12px",
    margin: "0",
    color: "#707070",
    "&.item-summary-active": {
      minHeight: "unset",
      backgroundColor: "#eeeeee",
      borderRadius: "6px",
    },
    "&$expanded": {
      minHeight: "unset",
      backgroundColor: "#eeeeee",
      borderRadius: "6px",
      // padding: '6px 12.5px',
    },
    "& .MuiAccordionSummary-content": {
      margin: 0,
      "& .MuiTypography-root": {
        fontSize: "14px",
        "& a": {
          color: "inherit",
          textDecoration: "inherit",
        },
      },
    },
  },
  content: {
    "&$expanded": {
      margin: "0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    display: "inline-block",
    padding: "0",
    paddingTop: "10px",
    paddingLeft: "20px",
    "& .MuiTypography-root": {
      fontSize: "14px",
      marginBottom: "10px",
    },
    "& a": {
      color: "inherit",
    },
  },
}))(MuiAccordionDetails);

const NavigationBar: React.FC<any> = (data, type: String) => {
  const classes = useStyles();
  const params = useParams();
  const url = useLocation();
  const pathnameArray = url.pathname.split("/");
  const pathname = pathnameArray[pathnameArray.length - 1];
  const [expanded, setExpanded] = useState<any>(pathnameArray);
  const [employee, setEmployee] = useState<any>();

  const handleChange =
    (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  useEffect(() => {
    setExpanded(pathnameArray);

    if (data.type === "people") {
      _.get(params, "id") !== undefined ? getEmployee() : getAccount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAccount = () => {
    const parameter = {};

    EmployeeRepository.account({
      ...parameter,
    })
      .then((response: AxiosResponse) => {
        const { data } = response;

        setEmployee(data.data);
      })
      .catch(() => {});
  };

  const getEmployee = () => {
    const parameter = {};

    EmployeeRepository.show(
      {
        ...parameter,
      },
      params.id
    )
      .then((response: AxiosResponse) => {
        const { data } = response;

        setEmployee(data.data);
      })
      .catch(() => {});
  };

  return (
    <Card>
      <CardContent>
        {data.type === "people" ? (
          <Grid
            container
            spacing={3}
            alignItems={"center"}
            className={classes.gridCardContainer}
          >
            <Grid item>
              <Avatar />
            </Grid>
            <Grid item>
              <Typography variant="subtitle1" className={classes.subtitle1}>
                {_.get(employee, "name")}
              </Typography>
              <Typography variant="subtitle2" className={classes.subtitle2}>
                {_.get(employee, "role_name")}
              </Typography>
            </Grid>
          </Grid>
        ) : null}
        <Grid container spacing={3} alignItems={"center"}>
          <Grid item xs={12}>
            {_.get(data, "data", []).map((item: any, index: number) => {
              return (
                <Accordion
                  square
                  key={index}
                  expanded={
                    expanded === item.path ||
                    Boolean(
                      item?.children?.find((child: any) =>
                        expanded?.includes
                          ? expanded?.includes(child?.path)
                          : false
                      )
                    )
                  }
                  onChange={handleChange(item.path)}
                >
                  {item.href !== "" ? (
                    <Link to={item.href}>
                      <AccordionSummary
                        onClick={() => console.log(item.label)}
                        aria-controls={item.path + "-content"}
                        id={item.path + "-header"}
                        className={
                          item.children.length == 0 &&
                          pathnameArray.includes(item.path)
                            ? "item-summary-active"
                            : ""
                        }
                      >
                        <Typography>{item.label}</Typography>
                      </AccordionSummary>
                    </Link>
                  ) : (
                    <AccordionSummary
                      aria-controls={item.path + "-content"}
                      id={item.path + "-header"}
                    >
                      <Typography>{item.label}</Typography>
                    </AccordionSummary>
                  )}
                  {item.children.length > 0 ? (
                    <AccordionDetails>
                      {item.children.map((child: any, index: number) => {
                        return (
                          <Typography
                            color={
                              pathnameArray.includes(child.path)
                                ? "primary"
                                : "textPrimary"
                            }
                            key={index}
                          >
                            <Link
                              to={
                                // params.id !== undefined
                                //   ? child.href + "/" + params.id
                                //   : child.href
                                child.href
                              }
                            >
                              {child.label}
                            </Link>
                          </Typography>
                        );
                      })}
                    </AccordionDetails>
                  ) : null}
                </Accordion>
              );
            })}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default NavigationBar;
