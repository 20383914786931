import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  loadingWrapper: {
    minHeight: 350,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#FFFFFF",
  },
  tableHead: {
    textTransform: "uppercase",
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
    fontWeight: 600,
    color: "#1A1818",
  },
  icon: {
    padding: "unset",
  },
  tableCell: {
    whiteSpace: "nowrap",
  },
  tableRow: {
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
    "& .aw": {
      overflow: "hidden",
    },
    "& .btnView": {
      // visibility: "hidden",
      opacity: "0",
      //todo: hapus height dan padding jadi (1px 5px) jika mau tinggi nya 53
      height: "34px",
      alignSelf: "center",
      padding: "0.325rem 2rem",
      // borderRadius: 8,
      textTransform: "capitalize",
      fontWeight: 400,
      boxShadow: "none",
      marginRight: "25px",
      // transition: "all 0.5s ease-in-out",
      // top: "55px",
    },
    "&:hover": {
      backgoundColor: "red !important",
      "& .btnView": {
        opacity: "1 !important",
        // visibility: "unset",
        // top: "0",
      },
    },
  },
});
