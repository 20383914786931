import React, {useEffect} from 'react';
import IconButton from '@material-ui/core/IconButton';
import PopupState, { bindTrigger } from 'material-ui-popup-state';
import { Badge, Box, Popover, Tooltip, Typography } from '@material-ui/core';
import { Notifications as NotificationsIcon } from '@material-ui/icons';
import { bindPopover } from 'material-ui-popup-state/core';
import { useStyles } from '../style';
import moment from 'moment';
import { State, useState } from '@hookstate/core';

interface NotificationDataInterface {
  title: string;
  url: string;
  message?: string;
  target?: string;
  image_url?: string;
  avatar_url?: string;
}

interface NotificationInterface {
  id: string;
  type: string;
  notifiable_type: string;
  notifiable_id: number;
  data: NotificationDataInterface;
  read_at: string;
  created_at: string;
}

interface Props {}

const Notification: React.FC<Props> = () => {
  const classes = useStyles();
  const notifications = useState<NotificationInterface[]>([]);
  const [totalUnreadNotification, setTotalUnreadNotification] = React.useState<number>(0);

  useEffect(() => {
    setTotalUnreadNotification(0);
  }, [])

  const renderNotification = (notification: State<NotificationInterface>) => {
    const data: any = notification.data.get();

    return (
      <div
        className={`notification-item ${notification.read_at.get() ? '' : 'unread'}`}
        key={notification.id.get()}
        onClick={() => {
          notification.read_at.set(moment().toISOString());
        }}
      >
        {data.avatar_url ? (
          <div className='avatar'>
            <img className='icon' src={data.avatar_url} alt='avatar' />
          </div>
        ) : null}

        {data.image_url ? (
          <div className='image'>
            <img className='icon' src={data.image_url} alt={""}/>
          </div>
        ) : null}

        <div className='details'>
          <h5 className='item-title'>{data.title}</h5>
          <span className='item-subtitle'>{data.message}</span>
          <label className='item-time'>{moment(notification.created_at.get()).fromNow()}</label>
        </div>
      </div>
    );
  };

  return (
    <PopupState variant='popover' popupId='notification-popup-menu'>
      {popupState => (
        <>
          <Tooltip title='Notification' arrow>
            <IconButton
              color='inherit'
              {...bindTrigger(popupState)}
              classes={{
                root: classes.iconBtn,
              }}
            >
              {totalUnreadNotification ? (
                <Badge
                  badgeContent={totalUnreadNotification > 10 ? '10+' : totalUnreadNotification}
                  color='primary'
                  classes={{ badge: classes.badge }}
                >
                  <NotificationsIcon />
                </Badge>
              ) : (
                <NotificationsIcon />
              )}
            </IconButton>
          </Tooltip>

          <Popover
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            classes={{
              paper: classes.notification,
            }}
            {...bindPopover(popupState)}
          >
            <div className='notification-popover'>
              <Box display={'flex'} justifyContent={'space-between'} className='title-wrapper' px={2} py={1} mb={1}>
                <Typography
                  variant='h5'
                  classes={{
                    subtitle1: classes.notificationAction,
                  }}
                >
                  <Box fontWeight='fontWeightBold'>Notification</Box>
                </Typography>

                {/*todo: popup state error*/}
                {/*<PopupState*/}
                {/*  variant="popover"*/}
                {/*  popupId="notification-popup-action"*/}
                {/*>*/}
                {/*  {popupState => (*/}
                {/*    <>*/}
                {/*      <IconButton*/}
                {/*        color="inherit"*/}
                {/*        size={"small"}*/}
                {/*        edge={"end"}*/}
                {/*        {...bindTrigger(popupState)}*/}
                {/*      >*/}
                {/*        <MoreHorizIcon />*/}
                {/*      </IconButton>*/}

                {/*      <Menu*/}
                {/*        anchorOrigin={{*/}
                {/*          vertical: "bottom",*/}
                {/*          horizontal: "right"*/}
                {/*        }}*/}
                {/*        transformOrigin={{*/}
                {/*          vertical: "top",*/}
                {/*          horizontal: "right"*/}
                {/*        }}*/}
                {/*        classes={{*/}
                {/*          paper: classes.notificationAction*/}
                {/*        }}*/}
                {/*        {...bindMenu(popupState)}*/}
                {/*      >*/}
                {/*        <MenuItem>*/}
                {/*          <CheckIcon fontSize="small" />*/}
                {/*          <Box ml={1} onClick={() => readAll()}>*/}
                {/*            Mark all as read*/}
                {/*          </Box>*/}
                {/*        </MenuItem>*/}
                {/*        <MenuItem>*/}
                {/*          <SettingsIcon fontSize="small" />*/}
                {/*          <Box ml={1}>Notification settings</Box>*/}
                {/*        </MenuItem>*/}
                {/*      </Menu>*/}
                {/*    </>*/}
                {/*  )}*/}
                {/*</PopupState>*/}
              </Box>
              <div className='notification-wrapper'>{notifications.map(renderNotification)}</div>
            </div>
          </Popover>
        </>
      )}
    </PopupState>
  );
};

export default Notification;
