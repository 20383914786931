import { makeStyles } from "@material-ui/core/styles";

const useTableStyles = makeStyles({
  tableHeadRow: {
    height: 46,
  },
  tableHead: {
    minWidth: "10rem",
  },
  tableCell: {
    paddingBottom: "1rem",
  },
  fileAttachment: {
    display: "flex",
    alignItems: "center",
  },
  fileAttachmentName: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    width: 100,
  },
  icon: {
    padding: 8,
    marginRight: 8,
    marginLeft: 8,
  },
});

export default useTableStyles;
