import React, { useEffect, useState } from "react";
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";
import { Autocomplete, AutocompleteProps } from "@material-ui/lab";
import { AxiosError, AxiosResponse } from "axios";
import { useSnackbar } from "notistack";
import _ from "lodash";
import { IApiResource } from "../../../interfaces/ApiResource";
import { axiosErrorLoadDataHandler } from "../../../utilities/helpers/axios-error.helper";
import { useIsMounted } from "../../../utilities/helpers/hooks";
import MuiTextField, { MuiTextFieldProps } from "../MuiTextField";
import { IConstantOption } from "../../../interfaces/ConstantOption";

export type TypeFunctionOptionLabel = (option: any) => string;
export type TypeFunctionOptionSelected = (option: any, value: any) => boolean;

interface IParams {
  [key: string]: any;
}

export interface MuiAutoCompleteProps
  extends Omit<
    AutocompleteProps<any, any, any, any>,
    "renderInput" | "options"
  > {
  isAsync?: boolean;
  onSelected: (option: any) => void;
  repository?: any;
  methodName?: string;
  paramsId?: string | number | null;
  isKeepClear?: boolean;
  constantOptions?: Array<any>;
  optionLabel?: string | TypeFunctionOptionLabel;
  optionSelected?: string | TypeFunctionOptionSelected;
  params?: IParams;
  muiTextField?: MuiTextFieldProps;
  additionalOptions?: IConstantOption[];
}

const MuiAutoComplete = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiAutocomplete-inputRoot": {
        padding: 4.5,
        fontSize: 14,
        "& .MuiAutocomplete-input:first-child": {
          paddingLeft: 9.5,
        },
      },
      "& .MuiFormLabel-root": {
        transform: "translate(14px, 16px) scale(1)",
        fontSize: 14,
        "&.MuiInputLabel-shrink": {
          transform: "translate(14px, -5px) scale(0.75)",
        },
      },
      "& .MuiOutlinedInput-notchedOutline": {
        "& legend": {
          fontSize: 10.5,
        },
      },
    },
  })
)((props: MuiAutoCompleteProps) => {
  const {
    isAsync = true,
    constantOptions = [],
    repository = null,
    onSelected,
    methodName = "select",
    isKeepClear = false,
    optionLabel = "name",
    optionSelected = "id",
    params = {},
    paramsId = null,
    muiTextField = {},
    additionalOptions = [],
    ...others
  } = props;
  const isMounted = useIsMounted();
  const { enqueueSnackbar } = useSnackbar();

  const [options, setOptions] = useState<Array<any>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [value, setValue] = useState(null);

  useEffect(() => {
    if (isKeepClear && value) {
      setValue(null);
    }
  }, [isKeepClear, value]);

  const onOpen = async () => {
    if (repository && typeof repository.all !== "function" && isAsync) {
      throw new Error("The repository is invalid.");
    }

    setLoading(true);
    setOptions([]);

    if (isAsync) {
      if (paramsId) {
        await repository[methodName](paramsId, params)
          .then((response: AxiosResponse<IApiResource<any[]>>) => {
            setOptions([...additionalOptions, ...response.data.data]);

            if (isMounted.current) {
              setLoading(false);
            }
          })
          .catch((error: AxiosError) => {
            if (isMounted.current) {
              setLoading(false);
              axiosErrorLoadDataHandler(error, enqueueSnackbar);
            }
          });
      } else {
        await repository[methodName](params)
          .then((response: AxiosResponse<IApiResource<any[]>>) => {
            setOptions([...additionalOptions, ...response.data.data]);

            if (isMounted.current) {
              setLoading(false);
            }
          })
          .catch((error: AxiosError) => {
            if (isMounted.current) {
              setLoading(false);
              axiosErrorLoadDataHandler(error, enqueueSnackbar);
            }
          });
      }
    } else {
      if (isMounted.current) {
        setOptions(constantOptions);
        setLoading(false);
      }
    }
  };

  const onChange = (event: any, newValue: any) => {
    if (isKeepClear) {
      setValue(newValue);
    }

    onSelected(newValue);
  };

  const label = (option: any) =>
    typeof optionLabel == "function"
      ? optionLabel(option)
      : _.get(option, optionLabel);

  const selected = (option: any, value: any) =>
    typeof optionSelected == "function"
      ? optionSelected(option, value)
      : _.get(option, optionSelected) === _.get(value, optionSelected);

  return (
    <Autocomplete
      {...others}
      {...(isKeepClear ? { value } : {})}
      loading={loading}
      options={options}
      onOpen={onOpen}
      onChange={onChange}
      getOptionLabel={(option) => label(option)}
      getOptionSelected={(option, value) => selected(option, value)}
      renderInput={(params) => <MuiTextField {...params} {...muiTextField} />}
    />
  );
});

export default MuiAutoComplete;
