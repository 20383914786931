import React, { useEffect } from "react";
import { Box, Grid } from "@material-ui/core";
import { FormProvider, useForm } from "react-hook-form";
import { useState } from "@hookstate/core/dist";
import { useNavigate, useParams } from "react-router";
import TitleForm from "../../../../components/molecules/TitleForm";
import { useIsMounted } from "../../../../utilities/helpers/hooks";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Stepper from "../../../../components/Stepper";
import Information from "./Information";
import Payroll from "./Payroll";
import FooterFormStepAction from "../../../../components/FooterFormStepAction";
import { VuiIconConstant } from "@vodea/vodea-ui/core/components/VuiIcon/helper";
import { VuiIcon } from "@vodea/vodea-ui/core/components";
import Policy from "./Policy";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  modelSchema,
  modelSchemaInformationShape,
  modelSchemaPayrollShape,
  modelPolicyShape,
} from "./Schema";
import { formatFormData } from "../../../../utilities/helpers/form";
import { mapHookErrors } from "../../../../utilities/helpers/global";
import UserRepository from "../../../../repositories/UserRepository";
import { useSnackbar } from "notistack";
import _ from "lodash";
import moment from "moment";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    stepWrapper: {
      "& .MuiStepConnector-root": {
        top: "50%",
      },
    },
  })
);

const EmployeeAddForm: React.FC<any> = () => {
  const navigate = useNavigate();
  const loading = useState(false);
  const classes = useStyles();
  const activeStep = useState(0);
  const data = useState({});
  const { id } = useParams();
  const isMounted = useIsMounted();
  const { enqueueSnackbar } = useSnackbar();
  const errorInformation = useState(false);
  const errorPayroll = useState(false);
  const errorPolicy = useState(false);

  const steps = [
    { label: "Information", status: errorInformation.get() },
    { label: "Payroll", status: errorPayroll.get() },
    { label: "Policy", status: errorPolicy.get() },
  ];

  const icons: any = {
    1: <VuiIcon icon={VuiIconConstant.INFO} size={16} color={"#383838"} />,
    2: <VuiIcon icon={VuiIconConstant.MONEY} size={16} color={"#383838"} />,
    3: (
      <VuiIcon
        icon={VuiIconConstant.POLICY_DOCUMENT}
        size={16}
        color={"#383838"}
      />
    ),
  };

  const methods = useForm({
    shouldUnregister: false,
    resolver: yupResolver(modelSchema()),
    mode: "onChange",
  });

  const validationFrom = () => {
    setTimeout(() => {
      const informationValidation: any[] = Object.keys(
        modelSchemaInformationShape
      ).map((key: any) => {
        if (_.has(methods.formState.errors, key)) {
          // errorInformation.set(true);
          return true;
        } else {
          return false;
          // errorInformation.set(false);
        }
      });

      if (informationValidation.includes(true)) {
        errorInformation.set(true);
      } else {
        errorInformation.set(false);
      }

      const payrollValidation = Object.keys(modelSchemaPayrollShape).map(
        (key: any) => {
          if (_.has(methods.formState.errors, key)) {
            // errorPayroll.set(true);
            return true;
          } else {
            // errorPayroll.set(false);
            return false;
          }
        }
      );

      if (payrollValidation.includes(true)) {
        errorPayroll.set(true);
      } else {
        errorPayroll.set(false);
      }

      const policyValidation = Object.keys(modelPolicyShape).map((key: any) => {
        if (_.has(methods.formState.errors, key)) {
          // errorPolicy.set(true);
          return true;
        } else {
          // errorPolicy.set(false);
          return false;
        }
      });

      if (policyValidation.includes(true)) {
        errorPolicy.set(true);
      } else {
        errorPolicy.set(false);
      }
    }, 50);
  };

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return <Information errorState={errorInformation} />;
      case 1:
        return <Payroll />;
      case 2:
        return <Policy />;
      default:
        return "Unknown step";
    }
  };

  const onSubmit = async (data: any) => {
    let tempTimeOff: any = [];
    let tempReimbursement: any = [];
    let tempAdvance: any = [];
    data.time_off_policy.map((data: any) => {
      tempTimeOff.push(data.id);
    });

    data.reimbursement_policy.map((data: any) => {
      tempReimbursement.push(data.id);
    });

    data.advance_policy.map((data: any) => {
      tempAdvance.push(data.id);
    });

    // loading.set(true);
    let finalData: any = {
      name: data.name,
      mobile_phone: data.phone,
      email: data.email,
      employment_status_id: data?.employment_status?.id,
      role_id: data?.position?.id,
      user_detail: {
        nik: data.nik,
        marital_status: data.relationship.id,
        gender: data.gender,
        religion: data?.religion?.id,
        blood_type: data.blood_type.name,
        date_of_birth: moment(data.date_of_birth).format("YYYY-MM-DD"),
        place_of_birth: data.place_of_birth,
        identity_type: data?.identity_type?.id,
        identity_number: data.identity_number,
        join_date: moment(data.joined_date).format("YYYY-MM-DD"),
        address: data.address,
        city: data.city,
      },
      user_payroll: {
        salary: data.basic_salary,
        tax_configuration: data?.payroll_calculation_method?.id,
        // taxable_date:
        ptkp_status: data?.ptkp_status?.id,
        salary_configuration_type: data?.salary_configuration_type?.id,
        tax_status: data?.employment_status?.id,
      },
      attendance_policy_id: data?.attendance_policy_id?.id,
      time_off_policy_ids: tempTimeOff,
      reimbursement_policy_ids: tempReimbursement,
      cash_advance_policy_ids: tempAdvance,
    };

    const formData = formatFormData(data);
    await UserRepository.create(finalData)
      .then((resp: any) => {
        if (isMounted.current) {
          enqueueSnackbar(resp.data.message, {
            variant: "success",
          });
          loading.set(false);
          navigate("/apps/people/employee");
        }
      })
      .catch((err: any) => {
        if (isMounted.current && err?.response?.data?.errors) {
          const errors = mapHookErrors(err.response.data.errors);
          // Object.keys(errors).forEach((key: any) => setError(key, errors[key]));

          enqueueSnackbar(err.response.data.message, {
            variant: "error",
          });
          loading.set(false);
        }
      });
  };

  const handlePrevAction = () => {
    if (activeStep.get() > 0) {
      activeStep.set(activeStep.get() - 1);
    } else {
      navigate(-1);
    }
  };

  const handleNextAction = () => {
    methods.trigger();
    validationFrom();
    if (activeStep.get() < 2) {
      activeStep.set(activeStep.get() + 1);
    } else {
      methods.handleSubmit(onSubmit)();
    }
  };

  return (
    <>
      <TitleForm title="Add Employee" withBackUrl={true} />
      <Grid item xs={12} className={classes.stepWrapper}>
        <Stepper steps={steps} activeStep={activeStep.get()} icons={icons} />
      </Grid>
      <Grid item xs={12}>
        <FormProvider {...methods}>
          <form>
            {getStepContent(activeStep.get())}
            <Box mt={3}>
              <FooterFormStepAction
                title={"Add New Employee"}
                leftBtnTitle={activeStep.get() > 0 ? "Previous" : "Back"}
                leftBtnAction={handlePrevAction}
                rightBtnTitle={activeStep.get() < 2 ? "NEXT" : "SUBMIT"}
                rightBtnAction={handleNextAction}
                loading={loading.get()}
              />
            </Box>
          </form>
        </FormProvider>
      </Grid>
    </>
  );
};

export default EmployeeAddForm;
