import React, { useEffect } from "react";
import {
  Box,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { VuiSearch } from "../../../../../@VodeaUI/components";
import {
  $clone,
  $cloneState,
  mapDataTableParams,
} from "../../../../../utilities/helpers/global";
import { useState } from "@hookstate/core/dist";
import VuiDataTable, {
  Column,
  Options,
} from "../../../../../@VodeaUI/VuiDataTable/Index";
import { defaultDataTableOption } from "../../../../../utilities/Option";
import PreferencesCompany from "../../../../../layouts/Apps/Preference/Company";
import { Link } from "react-router-dom";
import DepartmentRepository from "../../../../../repositories/DepartmentRepository";
import { AxiosError, AxiosResponse } from "axios";
import { useIsMounted } from "../../../../../utilities/helpers/hooks";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { useSnackbar } from "notistack";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

const DepartmentCompany: React.FC<any> = () => {
  const isMounted = useIsMounted();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const loading = useState(false);
  const options = useState<Options>({
    ...defaultDataTableOption,
    sortedBy: "desc",
    orderBy: "id",
  });

  const tableId = useState(0);
  const tableData = useState([]);

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = (id: any) => {
    tableId.set(id);
    setOpen(true);
  };

  const deleteResource = () => {
    const loadingKey = enqueueSnackbar("Please wait...", {
      variant: "default",
      autoHideDuration: 5000,
      preventDuplicate: true,
    });

    DepartmentRepository.delete(tableId.get())
      .then((resp: any) => {
        if (isMounted.current) {
          setOpen(false);
          loadData();
          closeSnackbar(loadingKey);
          enqueueSnackbar(resp.data.message, {
            variant: "success",
          });
        }
      })
      .catch((e: any) => {
        if (isMounted.current && e?.response?.data?.errors) {
          closeSnackbar(loadingKey);
          enqueueSnackbar(e.response.data.message, {
            variant: "error",
          });
        }
      });
  };

  const columns = useState<Column[]>([
    { key: "code", label: "ID" },
    { key: "name", label: "Name" },
    {
      key: "parent",
      label: "Head",
      render: (row) => {
        return <Box>{row?.parent?.code || "-"}</Box>;
      },
    },
    {
      key: "",
      label: "",
      sortable: false,
      render: (row) => {
        return (
          <Box display="flex" justifyContent={"flex-end"}>
            <Link to={`/apps/setting/company/department/${row.id}`}>
              <Tooltip title="Detail">
                <IconButton aria-label="more">
                  <MoreHorizOutlinedIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Link>

            <IconButton
              aria-label="delete"
              onClick={() => handleDelete(row.id)}
            >
              <DeleteOutlineIcon fontSize="small" />
            </IconButton>
          </Box>
        );
      },
    },
  ]);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = () => {
    loading.set(true);

    let params: any = {
      with: ["parent"],
    };

    DepartmentRepository.all({
      ...params,
      ...mapDataTableParams($cloneState(options)),
    })
      .then((resp: AxiosResponse) => {
        const { data: responseData } = resp;
        const { data, meta } = responseData;

        if (isMounted.current) {
          tableData.set(data);
          options.page.set(meta.current_page - 1);
          options.total.set(meta.total);
          loading.set(false);
        }
      })
      .catch((err: AxiosError) => {
        loading.set(false);
      });
  };

  return (
    <PreferencesCompany
      url="/apps/setting/company/department/create"
      buttonLabel="Add New Department"
    >
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth={"xs"}
      >
        <DialogTitle id="form-dialog-title">
          Are you sure want to delete?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>This action is cannot be undo.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant="contained" onClick={deleteResource} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper variant="elevation" elevation={1}>
            <Box p={2}>
              <Box marginBottom={1}>
                <Typography variant="h6">Department</Typography>
              </Box>
              <Grid container spacing={3} alignItems={"center"}>
                <Grid item md={4} xs={12}>
                  <VuiSearch
                    state={options.search}
                    callback={() => {
                      options.page.set(0);
                      loadData();
                    }}
                  />
                </Grid>
              </Grid>
            </Box>

            <VuiDataTable
              loading={loading.get()}
              columns={columns.value}
              options={$clone(options.value)}
              data={tableData.get()}
              onChangeOptions={(values: Options) => {
                options.set(values);
                loadData();
              }}
            />
          </Paper>
        </Grid>
      </Grid>
    </PreferencesCompany>
  );
};

export default DepartmentCompany;
