import { Box, Grid, IconButton, Typography } from "@material-ui/core";
import { Control, Controller, useFieldArray } from "react-hook-form";
import { $clone } from "../../../utilities/helpers/global";
import _ from "lodash";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import React, { useEffect } from "react";
import { useState } from "@hookstate/core";
import { ConstantOptionInterface } from "../../../pages/Apps/Preference/Policy/Reimbursement/interface";
import { constantToOptions } from "../../../utilities/helpers/option.helper";
import { useSelector } from "react-redux";
import { IApprovalPolicy } from "../../../interfaces/ApprovalPolicy";
import {
  MuiAutoComplete,
  MuiButton,
  MuiCheckbox,
  MuiTextField,
  NumberFieldFormat,
} from "../../atoms";
import RoleRepository from "../../../repositories/RoleRepository";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { useIsMounted } from "../../../utilities/helpers/hooks";

interface ICashAdvanceApprovalSetting {
  control: Control;
  fieldName: string;
  typeName: string;
  policies: Array<IApprovalPolicy> | any;
  type: any;
  label: string;
  errors: any;
}

const useStyles = makeStyles({
  removeMarginHorizontal: {
    marginRight: 0,
    marginLeft: 0,
  },
});

const CashAdvanceApprovalSetting: React.FC<ICashAdvanceApprovalSetting> = ({
  label = "",
  control,
  errors,
  fieldName = "",
  typeName = "",
  policies,
  type,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const isMounted = useIsMounted();
  const { constant } = useSelector(({ constant }: any) => {
    return {
      constant: constant.constant.payload,
    };
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: fieldName,
    keyName: "key",
  });

  const approvalRequirementTypeOptions = useState<ConstantOptionInterface[]>(
    constantToOptions(constant, "APPROVAL_REQUIREMENT_TYPE_OPTIONS", true)
  );

  const approvalPolicyTypeOptions = useState<ConstantOptionInterface[]>(
    constantToOptions(constant, "APPROVAL_POLICY_TYPE_OPTIONS")
  );

  useEffect(() => {
    if (isMounted.current) {
      approvalRequirementTypeOptions.set(
        constantToOptions(constant, "APPROVAL_REQUIREMENT_TYPE_OPTIONS", true)
      );

      approvalPolicyTypeOptions.set(
        constantToOptions(constant, "APPROVAL_POLICY_TYPE_OPTIONS")
      );
    }
  }, [constant]);

  const addMorePolicy = () =>
    append({
      level: "",
      type: null,
      role_id: null,
    });

  return (
    <Grid container spacing={3}>
      <Grid item md={12} xs={12}>
        <Controller
          render={({ value, name, onChange }) => (
            <MuiAutoComplete
              isAsync={false}
              constantOptions={$clone(approvalRequirementTypeOptions.get())}
              onOpen={() =>
                approvalRequirementTypeOptions.set(
                  constantToOptions(
                    constant,
                    "APPROVAL_REQUIREMENT_TYPE_OPTIONS",
                    true
                  )
                )
              }
              value={value}
              onSelected={(newValue) => onChange(newValue)}
              muiTextField={{
                label: t(label),
                error: _.has(errors, name),
                helperText: _.get(errors, `${name}.message`),
              }}
            />
          )}
          name={typeName}
          control={control}
        />
      </Grid>

      {type && _.get(type, "id") !== "NONE" && (
        <Grid item md={12} xs={12}>
          <Grid container>
            <Grid item md={12} xs={12}>
              <Grid container spacing={2}>
                <Grid item md={3}>
                  <Typography variant={"subtitle2"}>Level</Typography>
                </Grid>
                <Grid item md={3}>
                  <Typography variant={"subtitle2"}>Type</Typography>
                </Grid>
                <Grid item md={4}>
                  <Typography variant={"subtitle2"}>Position</Typography>
                </Grid>
                <Grid item md={2} />
              </Grid>
            </Grid>
            <Grid item md={12} xs={12}>
              {fields.map((field: any, index: number) => {
                return (
                  <Box mt={2} key={field.key}>
                    <Grid container spacing={2}>
                      <Grid item md={3} sm={12}>
                        <Controller
                          name={`${fieldName}[${index}].level`}
                          defaultValue={field.level}
                          control={control}
                          render={({ value, name, onChange }) => {
                            return (
                              <MuiTextField
                                onChange={onChange}
                                value={value}
                                error={_.has(errors, name)}
                                helperText={_.get(errors, `${name}.message`)}
                                InputProps={{
                                  inputComponent: NumberFieldFormat,
                                }}
                              />
                            );
                          }}
                        />
                      </Grid>
                      <Grid item md={3} sm={12}>
                        <Controller
                          render={({ value, name, onChange }) => (
                            <MuiAutoComplete
                              isAsync={false}
                              constantOptions={$clone(
                                approvalPolicyTypeOptions.get()
                              )}
                              onOpen={() =>
                                approvalPolicyTypeOptions.set(
                                  constantToOptions(
                                    constant,
                                    "APPROVAL_POLICY_TYPE_OPTIONS"
                                  )
                                )
                              }
                              value={value}
                              onSelected={(newValue) => onChange(newValue)}
                              muiTextField={{
                                error: _.has(errors, name),
                                helperText: _.get(errors, `${name}.message`),
                              }}
                            />
                          )}
                          name={`${fieldName}[${index}].type`}
                          control={control}
                          defaultValue={field.type}
                        />
                      </Grid>
                      <Grid item md={4} sm={12}>
                        {_.get(policies[index], "type.id") !== "SUPERVISOR" && (
                          <Controller
                            render={({ value, name, onChange }) => (
                              <MuiAutoComplete
                                repository={RoleRepository}
                                value={value}
                                onSelected={(newValue) => onChange(newValue)}
                                muiTextField={{
                                  error: _.has(errors, name),
                                  helperText: _.get(errors, `${name}.message`),
                                }}
                              />
                            )}
                            name={`${fieldName}[${index}].role_id`}
                            control={control}
                            defaultValue={field.role_id}
                          />
                        )}
                      </Grid>
                      <Grid item md={2} justifyContent={"flex-end"}>
                        <Controller
                          name={`${fieldName}[${index}].can_update`}
                          control={control}
                          render={({ value, name, onChange }) => {
                            return (
                              <MuiCheckbox
                                checked={value}
                                customClasses={classes.removeMarginHorizontal}
                                onChange={onChange}
                              />
                            );
                          }}
                        />

                        <IconButton
                          aria-label="delete"
                          onClick={() => remove(index)}
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Box>
                );
              })}
            </Grid>
            <Box mt={2}>
              <MuiButton label={"Add More"} onClick={addMorePolicy} />
            </Box>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default CashAdvanceApprovalSetting;
