import { IDataTableParams } from "../interfaces/IDataTableParams";
import { IConstantOption } from "../interfaces/ConstantOption";

export const defaultDataTableParams: IDataTableParams = {
  search: "",
  page: 0,
  perPage: 10,
  rowsPerPageOptions: [10, 25, 50],
  total: 0,
  orderBy: null,
  sortedBy: null,
};

export const defaultDateOptions: IConstantOption = { id: "all", name: "All" };

export const noneOptions = [{ id: "NONE", name: "None" }];

export const monthOptions = [
  {
    id: 1,
    name: "January",
    key: "01",
  },
  {
    id: 2,
    name: "February",
    key: "02",
  },
  {
    id: 3,
    name: "March",
    key: "03",
  },
  { id: 4, name: "April", key: "04" },
  {
    id: 5,
    name: "May",
    key: "05",
  },
  {
    id: 6,
    name: "June",
    key: "06",
  },
  {
    id: 7,
    name: "July",
    key: "07",
  },
  {
    id: 8,
    name: "August",
    key: "08",
  },
  {
    id: 9,
    name: "September",
    key: "09",
  },
  {
    id: 10,
    name: "October",
    key: "10",
  },
  {
    id: 11,
    name: "November",
    key: "11",
  },
  {
    id: 12,
    name: "December",
    key: "12",
  },
];

export const renewalOptions = [
  {
    id: "MONTHLY",
    name: "Monthly",
  },
  {
    id: "ANNUALLY",
    name: "Annually",
  },
  {
    id: "ANNIVERSARY",
    name: "Anniversary",
  },
];

export const generatedAfterOptions = [
  {
    id: "STATUS_CHANGED",
    name: "Status Changed",
  },
  {
    id: "CERTAIN_MONTH",
    name: "After Month",
  },
];

export const approvalTypeOptions = [
  {
    id: 1,
    name: "Sequential",
    key: "SEQUENTIAL",
  },
  {
    id: 2,
    name: "Non Sequential",
    key: "NON_SEQUENTIAL",
  },
  {
    id: 3,
    name: "Discretion",
    key: "DISCRETION",
  },
  {
    id: 4,
    name: "None",
    key: "NONE",
  },
];

export const approvalRequirementTypeOptions = [
  {
    id: "SEQUENTIAL",
    name: "Sequential",
  },
  {
    id: "NON_SEQUENTIAL",
    name: "Non Sequential",
  },
  {
    id: "DISCRETION",
    name: "Discretion",
  },
  {
    id: "NONE",
    name: "None",
  },
];

export const approvalTypePolicyOptions = [
  {
    id: 1,
    name: "Direct Supervisor",
    key: "SUPERVISOR",
  },
  {
    id: 2,
    name: "Certain Position",
    key: "CERTAIN_POSITION",
  },
];

export const generateOnMonthlyOptions = [
  {
    id: "DEFINED",
    name: "Specific Date",
  },
  {
    id: "ANNIVERSARY",
    name: "Anniversary",
  },
];

export const expiredMonthlyOptions = [
  {
    id: 1,
    name: "Certain Month",
    key: "TOTAL_MONTH",
  },
  {
    id: 2,
    name: "Certain Day and Month",
    key: "DATE",
  },
  {
    id: 3,
    name: "Anniversary",
    key: "ANNIVERSARY",
  },
  {
    id: 4,
    name: "None",
    key: "",
  },
];

export const expiredAnnuallyAndAnniversaryOptions = [
  {
    id: 1,
    name: "Certain Month",
    key: "TOTAL_MONTH",
  },
  {
    id: 2,
    name: "Certain Day and Month",
    key: "DATE",
  },
  {
    id: 3,
    name: "None",
    key: "NONE",
  },
];

export const religionOptions = [
  {
    id: "CATHOLIC",
    name: "Catholic",
  },
  {
    id: "BUDDHA",
    name: "Buddha",
  },
  {
    id: "CHRISTIAN",
    name: "Christian",
  },
  {
    id: "CONFUCIUS",
    name: "Confucius",
  },
  {
    id: "HINDU",
    name: "Hindu",
  },
  {
    id: "ISLAM",
    name: "Islam",
  },
];

export const genderOptions = [
  {
    id: "MALE",
    name: "Male",
  },
  {
    id: "FEMALE",
    name: "Female",
  },
];

//TODO GET FROM API CONSTANT
export const relationOptions = [
  {
    id: "SINGLE",
    name: "Single",
  },
  {
    id: "Married",
    name: "Married",
  },
  {
    id: "WIDOW",
    name: "Widow",
  },
  {
    id: "WIDOWER",
    name: "Widower",
  },
  {
    id: "OTHER",
    name: "Other",
  },
];

export const identityExpiredTypeOptions = [
  {
    id: "LIFETIME",
    name: "Lifetime",
  },
  {
    id: "DATE",
    name: "Date",
  },
];

export const attendanceCategoryOptions = [
  {
    id: "CLOCK_IN",
    name: "Clock In",
  },
  {
    id: "CLOCK_OUT",
    name: "Clock Out",
  },
];

export const JHTValueOptions = [
  {
    id: "3.7",
    name: "3.7%",
  },
  {
    id: "5.7",
    name: "5.7%",
  },
];

export const JPValueOptions = [
  {
    id: "2",
    name: "2%",
  },
  {
    id: "3",
    name: "3%",
  },
];

export const BPJSKesValueOptions = [
  {
    id: "4",
    name: "4%",
  },
  {
    id: "5",
    name: "5%",
  },
];

export const sortedByOptions = [
  {
    id: "asc",
    name: "A-Z",
  },
  {
    id: "desc",
    name: "Z-A",
  },
];

export const bpjsKetProgramOptions = [
  {
    id: "JKK",
    name: "JKK",
  },
  {
    id: "JKM",
    name: "JKM",
  },
  {
    id: "JHT",
    name: "JHT",
  },
  {
    id: "JP",
    name: "JP",
  },
];

export const bpjsKesProgramOptions = [
  {
    id: "BPJS Kesehatan",
    name: "BPJS Kesehatan",
  },
];

export const dayOptions = [
  { name: "Sunday", id: "SUNDAY" },
  { name: "Monday", id: "MONDAY" },
  { name: "Tuesday", id: "TUESDAY" },
  { name: "Wednesday", id: "WEDNESDAY" },
  { name: "Thursday", id: "THURSDAY" },
  { name: "Friday", id: "FRIDAY" },
  { name: "Saturday", id: "SATURDAY" },
];

export const formulaOperatorOptions = [
  {
    id: "+",
    name: "+",
  },
  {
    id: "-",
    name: "-",
  },
  {
    id: "*",
    name: "*",
  },
  {
    id: "/",
    name: "/",
  },
];

export const formulaSymbolOptions = [
  {
    id: "(",
    name: "(",
  },
  {
    id: ")",
    name: ")",
  },
];
