import React, { useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import { useState } from "@hookstate/core/dist";
import VuiSelect from "../../../../../@VodeaUI/components/Input/VuiSelect/Index";
import { Controller, useForm } from "react-hook-form";
import VuiCardContent from "../../../../../@VodeaUI/components/VuiCardContent";
import _ from "lodash";
import FooterFormAction from "../../../../../components/FooterFormAction";
import { KeyboardDatePicker } from "@material-ui/pickers";

const RequestReimbursementTimeOff: React.FC<any> = () => {
  const {
    control,
    errors,
    setValue,
    register,
    setError,
    reset,
    handleSubmit,
  } = useForm({
    mode: "onChange",
  });

  const loading = useState(false);
  const resourceType = useState("1");
  const buildingType = useState("bandung");
  const dayType = useState("sunday");

  const resourceTypeOptions = [
    {
      key: "1",
      label: "Meeting Room A",
    },
    {
      key: "2",
      label: "Meeting Room B",
    },
  ];

  const onSubmit = (formData: any) => {
    loading.set(true);

    console.log("formData", formData);
  };

  return (
    <>
      <Grid container spacing={3} justifyContent="space-between">
        <Grid item xs={12}>
          <Typography variant="h5">Request Reimbursement Time Off</Typography>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item md={9} xs={12}>
          <VuiCardContent title="Reimbursement Request Time Off">
            <Box p={2}>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <VuiSelect
                    label="Employee"
                    state={resourceType}
                    options={resourceTypeOptions}
                    fullWidth={true}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <VuiSelect
                    label="Time Off Reimbursement Policy"
                    state={resourceType}
                    options={resourceTypeOptions}
                    fullWidth={true}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <Controller
                    render={({ value, name, onChange }) => (
                      <KeyboardDatePicker
                        name={name}
                        disableToolbar
                        fullWidth
                        variant="inline"
                        minDate={new Date("2020-01-01")}
                        inputVariant="outlined"
                        label="Date"
                        format="DD/MM/YYYY"
                        error={_.has(errors, "taxable_date")}
                        helperText={_.get(errors, "taxable_date.message")}
                        value={value}
                        onChange={onChange}
                      />
                    )}
                    name="taxable_date"
                    control={control}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    name="building_id"
                    label="Amount"
                    variant="outlined"
                    fullWidth
                    error={_.has(errors, "building_id")}
                    helperText={_.get(errors, "building_id.message")}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    name="building_id"
                    label="Description"
                    variant="outlined"
                    fullWidth
                    error={_.has(errors, "building_id")}
                    helperText={_.get(errors, "building_id.message")}
                  />
                </Grid>
              </Grid>
            </Box>
          </VuiCardContent>
        </Grid>
        <Grid item md={9} xs={12}>
          <FooterFormAction
            cancelUrl={null}
            loading={loading.get()}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default RequestReimbursementTimeOff;
