import { combineReducers } from "redux";
import vodea from "./vodea";
import constant from "./constant";
import { formatterReducer } from "../formatter/reducers";

const createReducer = () =>
  combineReducers({
    vodea,
    constant,
    formatter: formatterReducer,
  });

export default createReducer;
