import React, { useEffect, useMemo } from "react";
import People from "../../../../../../../layouts/Apps/People";
import {
  Box,
  Grid,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { VuiSearch, VuiTabs } from "../../../../../../../@VodeaUI/components";
import {
  ArrowForwardIos,
  BlockOutlined,
  CheckCircleOutlineOutlined,
} from "@material-ui/icons";
import {
  $clone,
  $cloneState,
  calcBusinessDays,
  generateEmployeeNavData,
  insertQuerySearch,
  mapDataTableParams,
  mapParams,
} from "../../../../../../../utilities/helpers/global";
import BoxInformation from "../../../../../../../components/Widget/BoxInformation";
import { useState } from "@hookstate/core";
import TimeOffRepository from "../../../../../../../repositories/TimeOffRepository";
import { AxiosResponse } from "axios";
import useIsMounted from "../../../../../../../@VodeaUI/hooks/useIsMounted";
import VuiDataTable, {
  Column,
  Options,
} from "../../../../../../../@VodeaUI/VuiDataTable/Index";
import moment from "moment";
import { defaultDataTableOption } from "../../../../../../../utilities/Option";
import _ from "lodash";
import { useQuerySearch } from "../../../../../../../utilities/hooks";
import { useParams } from "react-router";
import WidgetRepository from "../../../../../../../repositories/WidgetRepository";
import Status from "../../../../../../../components/Status";
import Button from "@material-ui/core/Button";
import { useStyles } from "../../../../../../../layouts/Apps/style";
import { Link } from "react-router-dom";
import TimeOffHistoryRepository from "../../../../../../../repositories/TimeOffHistoryRepository";
import {
  optionLabel,
  optionSelected,
} from "../../../../../../../utilities/helpers/select";
import { Autocomplete } from "@material-ui/lab";
import {
  AutoCompleteAsyncInterface,
  AutoCompleteInterface,
} from "../../../../../../../interfaces/Autocomplete";
import { useSelector } from "react-redux";
import TimeOffPolicyRepository from "../../../../../../../repositories/TimeOffPolicyRepository";

const tabs = [
  {
    label: "Transaction",
    params: {},
  },
  {
    label: "History",
    params: {},
  },
];

const widgetBaseModel = {
  label: "",
  value: "",
  expired: "",
};

interface OptionInterface {
  monthAttendance: AutoCompleteInterface & AutoCompleteAsyncInterface;
  yearAttendance: AutoCompleteInterface & AutoCompleteAsyncInterface;
  timeOff: AutoCompleteInterface & AutoCompleteAsyncInterface;
}

const EmployeeTimeOff: React.FC<any> = () => {
  const { tab: queryTab } = useQuerySearch();
  const selectedCategory = useState(null);
  const monthNow = useState({
    key: null,
    label: "All",
  });
  const yearNow = useState({
    key: null,
    label: "All",
  });
  const parsedQueryTab: number = isNaN(Number(queryTab)) ? 0 : Number(queryTab);
  const yearOptions = useState<any[]>([]);
  const monthOptions = useState<any[]>([]);
  const defaultTab = _.has(tabs, parsedQueryTab) ? parsedQueryTab : 0;
  const classes = useStyles();
  const { user } = useSelector(({ vodea }: any) => {
    return {
      user: vodea.auth.user,
    };
  });

  const columns = useState<Column[]>([
    {
      key: "time_off_policy",
      label: "Category",
      defaultValue: "-",
      render: (row) => {
        return <div>{row?.time_off_policy.name}</div>;
      },
    },
    {
      key: "created_at",
      label: "Request Date",
      type: "date",
      dateFormat: "DD MMMM YYYY",
      defaultValue: "-",
    },
    {
      key: "start",
      label: "Start Date",
      type: "date",
      dateFormat: "DD MMMM YYYY",
      defaultValue: "-",
    },
    {
      key: "finish",
      label: "Finish Date",
      type: "date",
      dateFormat: "DD MMMM YYYY",
      defaultValue: "-",
    },
    {
      key: "",
      label: "Number Of Days",
      defaultValue: "-",
      render: (row) => {
        return <div>{calcBusinessDays(row?.start, row?.finish)}</div>;
      },
    },
    {
      key: "id",
      label: "",
      sortable: false,
      render: (row) => {
        return (
          <Box display="flex" justifyContent={"flex-end"}>
            <Link
              to={`/apps/people/personal-information/time/time-off/${row.id}`}
            >
              <IconButton aria-label="edit">
                <ArrowForwardIos fontSize="small" />
              </IconButton>
            </Link>

            {row.can_suspend ? (
              <Tooltip title="Suspend">
                <IconButton aria-label="suspend">
                  <BlockOutlined fontSize="small" />
                </IconButton>
              </Tooltip>
            ) : null}

            {row.can_unsuspend ? (
              <Tooltip title="Unsuspend">
                <IconButton aria-label="unsuspend">
                  <CheckCircleOutlineOutlined fontSize="small" />
                </IconButton>
              </Tooltip>
            ) : null}
          </Box>
        );
      },
    },
  ]);

  const historyColumns = useState<Column[]>([
    {
      key: "time_off_policy",
      label: "Category",
      defaultValue: "-",
      render: (row) => {
        return <div>{row.time_off_policy.name}</div>;
      },
    },
    {
      key: "effective_from",
      label: "Effective Date",
      type: "date",
      defaultValue: "-",
      dateFormat: "DD MMMM YYYY",
    },
    {
      key: "expired_at",
      label: "Expired Date",
      type: "date",
      dateFormat: "DD MMMM YYYY",
      defaultValue: "-",
    },
    {
      key: "real_balance",
      label: "Issued",
      defaultValue: "-",
      render: (row) => {
        return <div>{row.real_balance}</div>;
      },
    },
    {
      key: "used_balance",
      defaultValue: "-",
      label: "Used",
      render: (row) => {
        return <div>{row.used_balance}</div>;
      },
    },
    {
      key: "balance",
      defaultValue: "-",
      label: "balance",
      render: (row) => {
        return <div>{row.balance}</div>;
      },
    },
    {
      key: "pending_balance",
      label: "pending",
      defaultValue: "-",
      render: (row) => {
        return <div>{row.pending_balance}</div>;
      },
    },
    {
      key: "",
      label: "Status",
      render: (row) => {
        const now = moment().format("YYYY-MM-DD");
        let status = "";
        if (row.effective_from < now) {
          status = "Pending";
        } else if (
          !row.expired_at ||
          (now >= row.effective_from && now <= row.expired_at)
        ) {
          status = "Active";
        } else {
          status = "Expired";
        }
        return <Status data={status} />;
      },
    },
  ]);

  const isMounted = useIsMounted();
  const loading = useState(false);
  const options = useState<Options>({
    ...defaultDataTableOption,
    sortedBy: "desc",
    orderBy: "created_at",
  });
  const data = useState([]);
  const dataHistory = useState([]);
  const objParams = useParams();
  const employeeNavData = generateEmployeeNavData(objParams.id);
  const [tab, setTab] = React.useState<number>(defaultTab);

  const [annual, setAnnual] = React.useState<any[]>([widgetBaseModel]);

  useEffect(() => {
    document.title = "Time Off";
    insertQuerySearch("tab", tab);
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  const loadData = () => {
    if (isMounted.current) {
      loading.set(true);
      data.set([]);
    }

    let params: any = {
      type: _.get(tabs, `${tab}.key`, null),
      year: _.get(yearNow.get(), "key"),
      month: _.get(monthNow.get(), "key"),
      "time-off-policy": _.get(selectedCategory.get(), "id"),
    };

    if (objParams.id !== undefined) {
      params = {
        ...params,
        user: objParams.id,
      };

      TimeOffRepository.all({
        ...params,
        with: ["user", "timeOffPolicy"],
        ...mapDataTableParams($cloneState(options)),
      })
        .then((response: AxiosResponse) => {
          const { data: responseData } = response;
          const { data: list, meta } = responseData;

          if (isMounted.current) {
            data.set(list);
            options.page.set(meta.current_page - 1);
            options.total.set(meta.total);
            tab == 0 && loading.set(false);
          }
        })
        .catch(() => {});

      TimeOffHistoryRepository.all({
        ...params,
        with: ["timeOffPolicy"],
        ...mapDataTableParams($cloneState(options)),
      })
        .then((response: AxiosResponse) => {
          const { data: responseData } = response;
          const { data: list, meta } = responseData;

          if (isMounted.current) {
            dataHistory.set(list);
            options.page.set(meta.current_page - 1);
            options.total.set(meta.total);
            tab == 1 && loading.set(false);
          }
        })
        .catch(() => {});

      WidgetRepository.userAnnual({
        ...params,
      })
        .then((response: AxiosResponse) => {
          const tempAnnual: any[] = [];
          _.get(response, "data.data", []).forEach((item: any) => {
            if (!moment(item.expired_at).isBefore(moment())) {
              tempAnnual.push({
                label: item.name,
                value: item.balance,
                expired: item.expired_at,
              });
            }
          });

          setAnnual(tempAnnual);
        })
        .catch(() => {});
    } else {
      TimeOffRepository.account({
        ...params,
        ...mapDataTableParams($cloneState(options)),
      })
        .then((response: AxiosResponse) => {
          const { data: responseData } = response;
          const { data: list, meta } = responseData;

          if (isMounted.current) {
            data.set(list);
            options.page.set(meta.current_page - 1);
            options.total.set(meta.total);
            tab == 0 && loading.set(false);
          }
        })
        .catch(() => {});

      TimeOffHistoryRepository.all({
        ...params,
        ...mapDataTableParams($cloneState(options)),
        with: ["timeOffPolicy"],
      })
        .then((response: AxiosResponse) => {
          const { data: responseData } = response;
          const { data: list, meta } = responseData;

          if (isMounted.current) {
            dataHistory.set(list);
            options.page.set(meta.current_page - 1);
            options.total.set(meta.total);
            tab == 1 && loading.set(false);
          }
        })
        .catch(() => {});

      WidgetRepository.annual()
        .then((response: AxiosResponse) => {
          const tempAnnual: any[] = [];

          _.get(response, "data.data", []).forEach((item: any) => {
            if (!moment(item.expired_at).isBefore(moment())) {
              tempAnnual.push({
                label: item.name,
                value: item.balance,
                expired: item.expired_at,
              });
            }
          });

          setAnnual(tempAnnual);
        })
        .catch(() => {});
    }
  };

  const generateYear = () => {
    let tempYear: any[] = [];
    let year = new Date().getFullYear();
    tempYear.push({ key: null, label: "All" });

    year -= 1;
    for (var i = 0; i < 5; i++) {
      tempYear.push({ key: year - i, label: (year - i).toString() });
    }

    if (isMounted.current) yearOptions.set(tempYear);
  };

  const generateMonth = () => {
    {
      let tempMonth: any[] = [];
      tempMonth.push({ key: null, label: "All" });
      moment.months().map((label, value) => {
        tempMonth.push({ key: value + 1, label: label });
      });
      if (isMounted.current) monthOptions.set(tempMonth);
    }
  };

  useMemo(() => {
    generateMonth();
    generateYear();
  }, []);

  const option = useState<OptionInterface>({
    monthAttendance: {
      options: [],
      loading: false,
      params: {},
      loadData: () => {
        if (isMounted.current) {
          option.monthAttendance.loading.set(true);
          option.monthAttendance.options.set($clone(monthOptions.get()));
          option.monthAttendance.loading.set(false);
        }
      },
      onOpen: () => {
        if (isMounted.current) {
          option.monthAttendance.options.set([]);
          option.monthAttendance.loadData.get()();
        }
      },
    },
    yearAttendance: {
      options: [],
      loading: false,
      params: {},
      loadData: () => {
        if (isMounted.current) {
          option.yearAttendance.loading.set(true);
        }

        if (isMounted.current) {
          option.yearAttendance.options.set($clone(yearOptions.get()));
          option.yearAttendance.loading.set(false);
        }
      },
      onOpen: () => {
        if (isMounted.current) {
          option.yearAttendance.options.set([]);
          option.yearAttendance.loadData.get()();
        }
      },
    },
    timeOff: {
      options: [],
      loading: false,
      params: {
        for: "filter",
        user: user?.id,
      },
      loadData: async () => {
        if (isMounted.current) {
          option.timeOff.loading.set(true);
        }
        await TimeOffPolicyRepository.select(
          mapParams($cloneState(option.timeOff.params))
        )
          .then(({ data }: AxiosResponse) => {
            if (isMounted.current) {
              option.timeOff.options.set(data.data);
              option.timeOff.loading.set(false);
            }
          })
          .catch(() => {});
      },
      onOpen: () => {
        if (isMounted.current) {
          option.timeOff.options.set([]);
          option.timeOff.loadData.get()();
        }
      },
    },
  });

  return (
    <People navData={employeeNavData}>
      <>
        {annual.length > 0 ? (
          <BoxInformation data={annual} type={"day"} />
        ) : null}

        <Grid container spacing={3} justifyContent="space-between">
          <Grid item>
            <Typography variant={"h5"}>Time Off</Typography>
          </Grid>
          <Grid item>
            <Link to={"/apps/time/time-off/time-off-request"}>
              <Button
                variant="contained"
                color="primary"
                className={classes.buttonPrimary}
              >
                Request Time Off
              </Button>
            </Link>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box mb={3}>
              <VuiTabs
                name="peopleTabs"
                value={tab}
                tabList={tabs.map((a) => a.label)}
                handleTabsChange={(value: any) => setTab(value)}
              />
            </Box>

            <Paper variant="elevation" elevation={1}>
              <Box p={2}>
                <Grid
                  container
                  spacing={3}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Grid item md={6} xs={12}>
                    <VuiSearch
                      state={$cloneState(options.search)}
                      callback={(value) => {
                        options.search.set(value);
                        options.page.set(0);
                        loadData();
                      }}
                    />
                  </Grid>
                  {/*<Grid item md={3}>*/}
                  {/*  <Autocomplete*/}
                  {/*    value={orderBy.get()}*/}
                  {/*    disableClearable*/}
                  {/*    options={orderByOptions.get()}*/}
                  {/*    getOptionLabel={(option: any) => optionLabel(option)}*/}
                  {/*    getOptionSelected={(option: any, value: any) =>*/}
                  {/*      optionSelected(option, value)*/}
                  {/*    }*/}
                  {/*    onChange={(event: any, newValue: any) => {*/}
                  {/*      orderBy.set(newValue);*/}
                  {/*      loadData();*/}
                  {/*    }}*/}
                  {/*    renderInput={(params) => (*/}
                  {/*      <TextField*/}
                  {/*        {...params}*/}
                  {/*        label="Urutkan"*/}
                  {/*        variant="outlined"*/}
                  {/*        fullWidth*/}
                  {/*      />*/}
                  {/*    )}*/}
                  {/*  />*/}
                  {/*</Grid>*/}
                </Grid>
                <Box marginTop={2}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                      <Autocomplete
                        value={selectedCategory.get()}
                        disableClearable
                        loading={option.timeOff.loading.get()}
                        options={$clone(option.timeOff.options.get())}
                        onOpen={option.timeOff.onOpen.get()}
                        getOptionLabel={(option: any) =>
                          optionLabel(option, "name")
                        }
                        getOptionSelected={(option: any, value: any) =>
                          optionSelected(option, value, "id")
                        }
                        onChange={(event: any, newValue: any) => {
                          selectedCategory.set(newValue);
                          loadData();
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Category"
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Autocomplete
                        value={monthNow.get()}
                        disableClearable
                        loading={option.monthAttendance.loading.get()}
                        options={$clone(option.monthAttendance.options.get())}
                        onOpen={option.monthAttendance.onOpen.get()}
                        getOptionLabel={(option: any) =>
                          optionLabel($clone(option), "label")
                        }
                        getOptionSelected={(option: any, value: any) =>
                          option.key == value.key
                        }
                        onChange={(event: any, newValue: any) => {
                          monthNow.set(newValue);
                          loadData();
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Month"
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Autocomplete
                        value={yearNow.get()}
                        disableClearable
                        loading={option.yearAttendance.loading.get()}
                        options={$clone(option.yearAttendance.options.get())}
                        onOpen={option.yearAttendance.onOpen.get()}
                        getOptionLabel={(option: any) =>
                          optionLabel($clone(option), "label")
                        }
                        getOptionSelected={(option: any, value: any) =>
                          option.key == value.key
                        }
                        onChange={(event: any, newValue: any) => {
                          yearNow.set(newValue);
                          loadData();
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Year"
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>

              <VuiDataTable
                loading={loading.get()}
                columns={tab == 0 ? columns.value : historyColumns.value}
                options={$clone(options.value)}
                data={tab == 0 ? data.value : dataHistory.value}
                onChangeOptions={(values: Options) => {
                  options.set(values);
                  loadData();
                }}
              />
            </Paper>
          </Grid>
        </Grid>
      </>
    </People>
  );
};

export default EmployeeTimeOff;
