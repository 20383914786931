import { AxiosError, AxiosResponse } from "axios";
import { ProviderContext } from "notistack";
import { NavigateFunction } from "react-router";

export const handleAxiosSuccessSave = (
  response: AxiosResponse,
  enqueueSnackbar: ProviderContext["enqueueSnackbar"],
  navigate: NavigateFunction,
  url: string
) => {
  if (response && response.data.success) {
    enqueueSnackbar(response.data.message, {
      variant: "success",
    });
    navigate(url);
  } else {
    enqueueSnackbar(response.data.message, {
      variant: "error",
    });
  }
};

export const axiosSuccessUpdateDataHandler = (
  response: AxiosResponse,
  enqueueSnackbar: ProviderContext["enqueueSnackbar"]
) => {
  enqueueSnackbar(response?.data.message, {
    variant: "success",
    autoHideDuration: 4000,
  });
};
