import * as validator from "yup";

export const modelSchemaInformation = () =>
  validator.object().shape({
    name: validator.string().required().label("Name"),
    gender: validator.string().required().label("Gender"),
    place_of_birth: validator.string().required().label("Place Of Birth"),
    phone: validator.string().required().label("Phone"),
    email: validator.string().required().label("Email"),
    job: validator.string().required().label("Job"),
    identity_number: validator.string().required().label("Identity Number"),
    relationship: validator.mixed().required().label("Relationship"),
    date_of_birth: validator.mixed().required().label("Date Of Birth"),
    blood_type: validator.mixed().required().label("Blood Type"),
    identity_type: validator.mixed().required().label("Identity Type"),
    identity_expired_type: validator
      .mixed()
      .required()
      .label("Identity Expired Type"),
  });

export const modelSchemaInformationShape = {
  name: validator.string().required().label("Name"),
  gender: validator.string().required().label("Gender"),
  place_of_birth: validator.string().required().label("Place Of Birth"),
  phone: validator.string().required().label("Phone"),
  email: validator.string().required().label("Email"),
  job: validator.string().required().label("Job"),
  identity_number: validator.string().required().label("Identity Number"),
  relationship: validator.mixed().required().label("Relationship"),
  date_of_birth: validator.mixed().required().label("Date Of Birth"),
  blood_type: validator.mixed().required().label("Blood Type"),
  identity_type: validator.mixed().required().label("Identity Type"),
  identity_expired_type: validator
    .mixed()
    .required()
    .label("Identity Expired Type"),
};

export const modelSchemaPayrollShape = {
  bpjs_kes_valid_from: validator
    .mixed()
    .required()
    .label("BPJS Ketenagakerjaan Effective Date"),
  department: validator.mixed().required().label("Department"),
  joined_date: validator.mixed().required().label("Joined Date"),
  employment_status: validator.mixed().required().label("Employment Status"),
  // payroll_calculation_method: validator
  //   .mixed()
  //   .required()
  //   .label("Payroll Calculation Method"),
  // salary_configuration_type: validator.mixed().required().label("Tax Setting"),
  // prorate_type: validator.mixed().required().label("Prorate Type"),
  // bpjs_number: validator
  //   .string()
  //   .required()
  //   .label("BPJS Ketenagakerjaan Number"),
  // bpjs_program: validator
  //   .mixed()
  //   .required()
  //   .label("BPJS Ketenagakerjaan Program"),
  // jht: validator.mixed().required().label("JHT Covered by Company"),
  // jp: validator.mixed().required().label("JP Covered by Company"),
  // bpjs_kes_number: validator.string().required().label("BPJS Kesehatan Number"),
  // bpjs_kesehatan_program: validator
  //   .mixed()
  //   .required()
  //   .label("BPJS Kesehatan Program"),
  // bpjs_kes_company_paid: validator
  //   .mixed()
  //   .required()
  //   .label("BPJS Kesehatan Covered by Company"),
};

export const modelPayroll = () =>
  validator.object().shape({
    basic_salary: validator.string().required().label("Basic Salary"),
    department: validator.mixed().required().label("Department"),
    joined_date: validator.mixed().required().label("Joined Date"),
    employment_status: validator.mixed().required().label("Employment Status"),
    payroll_calculation_method: validator
      .mixed()
      .required()
      .label("Payroll Calculation Method"),
    salary_configuration_type: validator
      .mixed()
      .required()
      .label("Tax Setting"),
    prorate_type: validator.mixed().required().label("Prorate Type"),
    bpjs_number: validator
      .string()
      .required()
      .label("BPJS Ketenagakerjaan Number"),
    bpjs_program: validator
      .mixed()
      .required()
      .label("BPJS Ketenagakerjaan Program"),
    jht: validator.mixed().required().label("JHT Covered by Company"),
    jp: validator.mixed().required().label("JP Covered by Company"),
    bpjs_kes_valid_from: validator
      .mixed()
      .required()
      .label("BPJS Ketenagakerjaan Effective Date"),
    bpjs_kes_number: validator
      .string()
      .required()
      .label("BPJS Kesehatan Number"),
    bpjs_kesehatan_program: validator
      .mixed()
      .required()
      .label("BPJS Kesehatan Program"),
    bpjs_kes_company_paid: validator
      .mixed()
      .required()
      .label("BPJS Kesehatan Covered by Company"),
    // bpjs_kes_valid_from: validator
    //   .mixed()
    //   .required()
    //   .label("BPJS Kesehatan Effective Date"),
  });

export const modelPolicy = () =>
  validator.object().shape({
    time_off_policy: validator.mixed().required().label("Time Off Policy"),
    attendance_policy: validator.mixed().required().label("Attendance Policy"),
    reimbursement_policy: validator
      .mixed()
      .required()
      .label("Reimbursement Policy"),
  });

export const modelPolicyShape = {
  time_off_policy: validator.mixed().required().label("Time Off Policy"),
  attendance_policy_id: validator.mixed().required().label("Attendance Policy"),
  reimbursement_policy: validator
    .mixed()
    .required()
    .label("Reimbursement Policy"),
  advance_policy: validator.mixed().required().label("Advance Policy"),
};

export const modelSchema = () =>
  validator.object().shape({
    ...modelSchemaInformationShape,
    ...modelSchemaPayrollShape,
    ...modelPolicyShape,
  });
