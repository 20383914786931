import { api } from "../@VodeaUI";

let url = window._env_.REACT_APP_PASSPORT_END_POINT;

const TimeOffHistoryRepository = {
  all: function (params: any = null) {
    return api.get(`${url}/api/account/time-off-balance`, {
      params,
    });
  },
  show: function (id: number | string, params: any = null) {
    return api.get(`${url}/api/account/time-off-balance/${id}`, {
      params,
    });
  },
  create: function (payload: any, params: any = null) {
    return api.post(`${url}/api/account/time-off-balance`, payload, {
      params,
    });
  },
};

export default TimeOffHistoryRepository;
