import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { KeyboardDatePicker } from "@material-ui/pickers";
import _ from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import VuiSelect from "../../../../@VodeaUI/components/Input/VuiSelect/Index";
import VuiCardContent from "../../../../@VodeaUI/components/VuiCardContent";
import TitleForm from "../../../../components/molecules/TitleForm";
import { useStyles } from "../../../../components/Status/style";
import { IResourceBooking } from "../../../../interfaces/ResourceBooking";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useIsMounted } from "../../../../utilities/helpers/hooks";
import ResourceBookingRepository from "../../../../repositories/ResourceBookingRepository";
import { AxiosError, AxiosResponse } from "axios";
import { IApiResource } from "../../../../interfaces/ApiResource";
import {
  MuiAutoComplete,
  MuiDatePicker,
  MuiIconButtonRounded,
  MuiTextField,
  MuiTimePicker,
} from "../../../../components/atoms";
import ResourceRepository from "../../../../repositories/ResourceRepository";
import { useTranslation } from "react-i18next";
import UserRepository from "../../../../repositories/UserRepository";
import FooterFormAction from "../../../../components/FooterFormAction";
import { IResourceBookingInvitation } from "../../../../interfaces/ResourceBookingInvitation";

const defaultValues: IResourceBooking = {
  name: "",
  description: "",
  date: moment(),
  time_from: moment(),
  time_to: moment(),
  resource_id: null,
  resource_booking_invitations: [],
};

const invitationDefaultValues: IResourceBookingInvitation = {
  email: "",
};

const FacilityForm: React.FC<any> = () => {
  const isMounted = useIsMounted();
  const { id } = useParams();
  const { t } = useTranslation();
  const [data, setData] = useState<IResourceBooking>(defaultValues);
  const [loading, setLoading] = useState<boolean>(false);
  const [isFetchingData, setIsFetchingData] = useState<boolean>(false);

  const loadData = useCallback(async () => {
    if (!id) return;
    if (isMounted.current) setIsFetchingData(true);

    let params = {};
    Object.assign(params, {
      with: ["resourceBookingInvitations"],
    });

    await ResourceBookingRepository.show(id, params)
      .then((response: AxiosResponse<IApiResource<IResourceBooking>>) => {
        if (isMounted.current) {
          setIsFetchingData(false);
        }
      })
      .catch((error: AxiosError) => {
        if (isMounted.current) {
          setIsFetchingData(false);
        }
      });
  }, []);

  const { control, errors, handleSubmit } = useForm<IResourceBooking>({
    mode: "onChange",
    defaultValues: useMemo(() => {
      (async () => {
        await loadData();
      })();
      return data;
    }, [id]),
  });

  const {
    fields: invitationFields,
    append: invitationAppend,
    remove: invitationRemove,
  } = useFieldArray({
    keyName: "key",
    control,
    name: "resource_booking_invitations",
  });

  const handleAddInvitation = () => invitationAppend(invitationDefaultValues);
  const handleRemoveInvitation = (index: number) => invitationRemove(index);

  useMemo(() => {
    if (!id) handleAddInvitation();
  }, []);

  const renderInvitationFields = (item: any, index: number) => {
    return (
      <React.Fragment key={item.key}>
        <Grid item xs={10}>
          <Controller
            name={`resource_booking_invitations[${index}].email`}
            control={control}
            defaultValue={item.email}
            render={({ value, onChange, name }) => {
              return (
                <MuiAutoComplete
                  repository={UserRepository}
                  freeSolo
                  onSelected={(newValue) => {
                    onChange(newValue);
                    handleAddInvitation();
                  }}
                  onChange={onChange}
                  value={value}
                  muiTextField={{
                    error: _.has(errors, name),
                    helperText: _.get(errors, `${name}.message`),
                  }}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <IconButton onClick={() => handleRemoveInvitation(index)}>
            <DeleteOutlineIcon />
          </IconButton>
        </Grid>
      </React.Fragment>
    );
  };

  const onSubmit = (formData: any) => {
    if (isMounted.current) setLoading(true);

    console.log("formData", formData);
  };

  return (
    <>
      <TitleForm title="Request Facility" withBackUrl={true} />

      <Grid container spacing={3}>
        <Grid item md={8} xs={12}>
          <VuiCardContent title="Information">
            <Box p={2}>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <Controller
                    name={"name"}
                    control={control}
                    render={({ value, onChange, name }) => {
                      return (
                        <MuiTextField
                          label={t("form.resourceName.label")}
                          onChange={onChange}
                          value={value}
                          name={name}
                          error={_.has(errors, name)}
                          helperText={_.get(errors, `${name}.message`)}
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Controller
                    name={"resource_id"}
                    control={control}
                    render={({ value, onChange, name }) => {
                      return (
                        <MuiAutoComplete
                          repository={ResourceRepository}
                          methodName="all"
                          onSelected={(newValue) => onChange(newValue)}
                          onChange={onChange}
                          value={value}
                          muiTextField={{
                            label: t("form.bookingName.label"),
                            error: _.has(errors, name),
                            helperText: _.get(errors, `${name}.message`),
                          }}
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Controller
                    render={({ value, name, onChange }) => (
                      <MuiDatePicker
                        value={value}
                        name={name}
                        label={t("form.date.label")}
                        format="DD MMMM YYYY"
                        onChange={onChange}
                        error={_.has(errors, name)}
                        helperText={_.get(errors, `${name}.message`)}
                      />
                    )}
                    name="date"
                    control={control}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Controller
                    render={({ value, name, onChange }) => (
                      <MuiTimePicker
                        value={value}
                        name={name}
                        label={t("form.from.label")}
                        onChange={onChange}
                        error={_.has(errors, name)}
                        helperText={_.get(errors, `${name}.message`)}
                      />
                    )}
                    name="time_from"
                    control={control}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Controller
                    render={({ value, name, onChange }) => (
                      <MuiTimePicker
                        value={value}
                        name={name}
                        label={t("form.to.label")}
                        onChange={onChange}
                        error={_.has(errors, name)}
                        helperText={_.get(errors, `${name}.message`)}
                      />
                    )}
                    name="time_to"
                    control={control}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <Controller
                    name={"description"}
                    control={control}
                    render={({ value, onChange, name }) => {
                      return (
                        <MuiTextField
                          label={t("form.description.label")}
                          onChange={onChange}
                          value={value}
                          name={name}
                          error={_.has(errors, name)}
                          helperText={_.get(errors, `${name}.message`)}
                        />
                      );
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </VuiCardContent>
        </Grid>

        <Grid item md={4} xs={6}>
          <VuiCardContent title="Invite Participant">
            <Box p={2}>
              <Box mb={2}>
                <Typography variant={"body2"}>Participant</Typography>
              </Box>
              <Grid container spacing={3}>
                {invitationFields.map(renderInvitationFields)}
              </Grid>
            </Box>
          </VuiCardContent>
        </Grid>

        <FooterFormAction
          cancelUrl={"/apps/facility"}
          loading={loading}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
        />
      </Grid>
    </>
  );
};

export default FacilityForm;
