import _ from "lodash";
import { Uppercase } from "./string";
import { IConstantOption } from "../../interfaces/ConstantOption";
import moment from "moment";
import { useCallback, useMemo, useState } from "react";

export const constantToOptions = (
  data: any,
  keyLodash: string,
  hasNoneOption: boolean = false
): any[] => {
  const map = _.get(data, keyLodash, []);
  const options = {
    id: "NONE",
    name: "None",
  };

  let result = Object.keys(map).map((key) => ({
    id: Uppercase(map[key]).replace(" ", "_").replace("-", "_"),
    name: map[key],
  }));

  return hasNoneOption ? [options, ...result] : result;
};

export const constantToOptionsWithId = (
  data: any,
  keyLodash: string
): any[] => {
  const map = _.get(data, keyLodash, []);

  let result = Object.keys(map).map((key) => ({
    id: key,
    name: map[key],
  }));

  return result;
};

export const useYearOption = () => {
  let tempYears: IConstantOption[] = [];
  let currentYear = new Date().getFullYear();
  let earliestYear = 2015;
  while (currentYear >= earliestYear) {
    tempYears.push({
      id: currentYear.toString(),
      name: currentYear.toString(),
    });
    currentYear -= 1;
  }

  return tempYears;
};

export const useMonthOption = () => {
  const [option, setOption] = useState<IConstantOption[]>([]);

  const loadMonth = useCallback(() => {
    const temp: IConstantOption[] = [];
    moment.months().map((label, value) => {
      temp.push({ id: (value + 1).toString(), name: label });
    });
    setOption(temp);
  }, []);

  useMemo(() => {
    loadMonth();
  }, []);

  return option;
};
