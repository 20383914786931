import { Box, Grid, Paper, Typography } from "@material-ui/core";
import MuiButton from "../../atoms/MuiButton";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

interface IFooterAction {
  onSubmit: () => void;
  loading?: boolean;
  label?: string;
  cancelUrl?: string | null;
  cancelBtnLabel?: string;
  onCancel?: any;
  labelForm?: string;
  submitBtnLabel?: string;
}

const useStyles = makeStyles({
  labelText: {
    color: "#707070",
  },
});

const FooterAction: FC<IFooterAction> = ({
  cancelUrl,
  cancelBtnLabel = "Cancel",
  loading,
  onCancel,
  onSubmit,
  submitBtnLabel = "Save",
  label = "Save Changes?",
}) => {
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <Paper>
        <Box
          p={2}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="subtitle2" className={classes.labelText}>
            {label}
          </Typography>
          <Box display="flex" alignItems="center">
            <Box marginRight={2}>
              {cancelUrl ? (
                <Link to={cancelUrl}>
                  <MuiButton
                    label={cancelBtnLabel}
                    variant="text"
                    size="large"
                  />
                </Link>
              ) : null}

              {onCancel ? (
                <MuiButton
                  label={cancelBtnLabel}
                  variant="text"
                  size="large"
                  onClick={onCancel}
                />
              ) : null}
            </Box>
            <Box>
              <MuiButton
                label={submitBtnLabel}
                size="large"
                onClick={onSubmit}
                loading={loading}
              />
            </Box>
          </Box>
        </Box>
      </Paper>
    </Grid>
  );
};

export default React.memo(FooterAction);
