import React, { FC, useCallback, useMemo, useState } from "react";
import CashAdvanceModal from "../../../Approval/CashAdvanceModal";
import { Link, useParams } from "react-router-dom";
import {
  Box,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import TitleForm from "../../../../../components/molecules/TitleForm";
import MuiButton from "../../../../../components/atoms/MuiButton";
import VuiCardContent from "../../../../../@VodeaUI/components/VuiCardContent";
import VuiNumberFormat from "../../../../../@VodeaUI/components/Input/VuiNumberFormat";
import { totalCurrencyFormat } from "../../../../../utilities/helpers/global";
import Status from "../../../../../components/Status";
import { InfoOutlined } from "@material-ui/icons";
import CashAdvanceRepository from "../../../../../repositories/CashAdvanceRepository";
import { AxiosError, AxiosResponse } from "axios";
import { IApiResource } from "../../../../../interfaces/ApiResource";
import { axiosErrorLoadDataHandler } from "../../../../../utilities/helpers/axios-error.helper";
import { useSnackbar } from "notistack";
import { useIsMounted } from "../../../../../utilities/helpers/hooks";
import { makeStyles } from "@material-ui/core/styles";
import Datetime from "../../../../../components/atoms/DateTime";
import {
  cashAdvanceRequest,
  ICashAdvanceRequest,
} from "../../../../../interfaces/CashAdvanceRequest";
import DateDay from "../../../../../components/atoms/DateDay";
import { useStyles as useTableStyles } from "../../../../../@VodeaUI/VuiDataTable/Style";
import DeclineForm from "../../../Approval/DeclineForm";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import FooterAction from "../../../../../components/molecules/FooterAction";
import Loading from "../../../../../components/Loading";
import { ICashAdvanceRequestItem } from "../../../../../interfaces/CashAdvanceRequestItem";
import CashAdvanceItem, { ChangeAmount } from "./CashAdvanceItem";

const useStyles = makeStyles({
  title: {
    color: "#707070",
  },
  detailTitle: {
    fontWeight: 600,
    marginTop: 8,
  },
  informationBox: {
    boxShadow: "0px 2px 8px #0000001a",
    position: "absolute",
    right: 50,
    padding: 12,
    zIndex: 10,
    backgroundColor: "white",
    borderRadius: 8,
    marginTop: 4,
  },
  noteText: {
    maxWidth: 150,
    overflowWrap: "break-word",
  },
});

export const DetailRequestAdvance: FC = () => {
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const isMounted = useIsMounted();
  const classes = useStyles();
  const tableClasses = useTableStyles();
  const { user } = useSelector(({ vodea }: any) => {
    return {
      user: vodea.auth.user,
    };
  });
  const [isFetchingData, setIsFetchingData] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<ICashAdvanceRequest>(cashAdvanceRequest);
  const [cashAdvanceRequestItems, setCashAdvanceRequestItems] = useState<
    ICashAdvanceRequestItem[]
  >([]);
  const [canApproved, setCanApproved] = useState<boolean>(false);
  const navigate = useNavigate();
  const [defaultDataCashAdvance, setDataCashAdvance] = useState<any>([]);
  const [showCashAdvanceModal, setShowCashAdvanceModal] =
    useState<boolean>(false);
  const [openApprovalInformation, setOpenApprovalInformation] =
    useState<boolean>(false);
  const [indexApprovalInformation, setIndexApprovalInformation] =
    useState<number>(0);
  const [showDeclineForm, setShowDeclineForm] = useState<boolean>(false);

  const loadData = useCallback(async () => {
    if (!id) {
      return;
    }

    if (isMounted.current) {
      setIsFetchingData(true);
    }

    let params: any = {};
    Object.assign(params, {
      with: [
        "cashAdvanceRequestItems.attachment",
        "approvalLogs.role",
        "approvalLogs.approver",
        "user",
        "cashAdvanceRequestItems.asset.cashAdvanceRequestItems.cashAdvanceRequest.user",
      ],
    });

    await CashAdvanceRepository.show(params, id)
      .then((response: AxiosResponse<IApiResource<ICashAdvanceRequest>>) => {
        if (isMounted.current) {
          const { data: responseData } = response.data;
          setData(responseData);
          setCashAdvanceRequestItems(responseData.cash_advance_request_items);
          responseData?.approvals.map((item: any) => {
            if (item.role_id === user.role_user.role_id) {
              if (item.status === "WAITING") {
                setCanApproved(true);
              }
            }
          });
          setIsFetchingData(false);
        }
      })
      .catch((error: AxiosError) => {
        if (isMounted.current) setIsFetchingData(false);
        axiosErrorLoadDataHandler(error, enqueueSnackbar);
      });
  }, []);

  useMemo(() => {
    (async () => {
      await loadData();
    })();
  }, [id]);

  const handleOpenModalCashAdvance = (data: any) => {
    setDataCashAdvance(data);
    setShowCashAdvanceModal(true);
  };
  const handleCloseModalCashAdvance = () => setShowCashAdvanceModal(false);
  const handleOpenDrawerCashAdvance = () => setShowCashAdvanceModal(true);

  const openInformation = (index: number) => {
    setOpenApprovalInformation(!openApprovalInformation);
    setIndexApprovalInformation(index);
    if (index != indexApprovalInformation) {
      setOpenApprovalInformation(true);
      setIndexApprovalInformation(index);
    }
  };
  const handleCloseModal = () => setShowDeclineForm(false);
  const handleOpenModal = () => setShowDeclineForm(true);

  const handleSaveData = async () => {
    if (isMounted.current) {
      setIsLoading(true);
    }

    await CashAdvanceRepository.approve(id)
      .then((response: AxiosResponse) => {
        if (isMounted.current) {
          setIsLoading(false);
          enqueueSnackbar(response.data.message, {
            variant: "success",
          });
          navigate("/apps/approval");
        }
      })
      .catch((error: AxiosError) => {
        if (isMounted.current) setIsLoading(false);
        axiosErrorLoadDataHandler(error, enqueueSnackbar);
      });
  };

  const handleChangeAmount = useCallback((item: ChangeAmount) => {
    setCashAdvanceRequestItems((prevState) => {
      const cloneState = [...prevState];
      cloneState[item.rowIndex].amount = item.amount;
      return cloneState;
    });
  }, []);

  return (
    <>
      <TitleForm
        title={"Cash Advance Request"}
        withBackUrl
        component={
          data.can_update ? (
            <Link to={"edit"}>
              <MuiButton label="Edit" size="large" />
            </Link>
          ) : null
        }
      />
      {isFetchingData && <Loading />}
      <Box mt={2} style={isFetchingData ? { display: "none" } : {}}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <VuiCardContent
              title="Information"
              subtitle={data.number}
              hasSubtitle
            >
              <Box p={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Box mb={2}>
                      <Typography variant="caption" className={classes.title}>
                        Request Date
                      </Typography>
                      <Typography>
                        <Datetime data={data.created_at} />
                      </Typography>
                    </Box>
                    <Box mb={2}>
                      <Typography variant="caption" className={classes.title}>
                        Employee
                      </Typography>
                      <Typography>{data?.user?.name}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box mb={2}>
                      <Typography variant="caption" className={classes.title}>
                        Advance Category
                      </Typography>
                      <Typography>{data?.cash_advance_policy?.name}</Typography>
                    </Box>
                    <Box mb={2}>
                      <Typography variant="caption" className={classes.title}>
                        Used Date
                      </Typography>
                      <Typography>
                        <DateDay data={data.date} />
                      </Typography>
                    </Box>
                    <Box mb={2}>
                      <Typography variant="caption" className={classes.title}>
                        Purpose
                      </Typography>
                      <Typography>{data.description}</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </VuiCardContent>

            <Box mt={3}>
              <VuiCardContent title="Detail">
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow hover>
                      <TableCell className={tableClasses.tableHead}>
                        Category
                      </TableCell>
                      {data.cash_advance_request_items.filter(
                        (item) => item.asset_id !== null
                      ).length > 0 && (
                        <TableCell className={tableClasses.tableHead}>
                          Asset
                        </TableCell>
                      )}
                      <TableCell className={tableClasses.tableHead}>
                        Description
                      </TableCell>
                      <TableCell className={tableClasses.tableHead}>
                        File
                      </TableCell>
                      <TableCell className={tableClasses.tableHead}>
                        Amount (IDR)
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {cashAdvanceRequestItems.map((item, index: number) => (
                      <CashAdvanceItem
                        key={`${index}_item`}
                        cashAdvanceId={id}
                        data={item}
                        rowIndex={index}
                        handleOpenModalCashAdvance={handleOpenModalCashAdvance}
                        onChangeAmount={handleChangeAmount}
                        userId={user.id}
                      />
                    ))}
                  </TableBody>
                </Table>
                <Box p={2}>
                  <Box display="flex" justifyContent="flex-end">
                    <Box marginRight={10}>
                      <Typography variant="body2">Total Advance</Typography>
                    </Box>
                    <strong>
                      <VuiNumberFormat
                        data={totalCurrencyFormat(
                          cashAdvanceRequestItems,
                          "amount"
                        )}
                        value={totalCurrencyFormat(
                          cashAdvanceRequestItems,
                          "amount"
                        )}
                      />
                    </strong>
                  </Box>
                </Box>
              </VuiCardContent>
            </Box>
          </Grid>

          <Grid item xs={12} md={4}>
            <VuiCardContent title="Approval Status">
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow hover>
                    <TableCell className={tableClasses.tableHead}>
                      Name
                    </TableCell>
                    <TableCell className={tableClasses.tableHead}>
                      Status
                    </TableCell>
                    <TableCell className={tableClasses.tableHead} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.approvals.map((item: any, index: number) => {
                    return (
                      <TableRow
                        hover
                        key={index}
                        className={tableClasses.tableRow}
                      >
                        <TableCell className={tableClasses.tableRow}>
                          {item.status === "WAITING"
                            ? item.role_name
                            : item.approver_name}
                        </TableCell>
                        <TableCell className={tableClasses.tableRow}>
                          <Status data={item.status} />
                        </TableCell>
                        <TableCell className={tableClasses.tableRow}>
                          {item.status !== "WAITING" && (
                            <>
                              <IconButton
                                className={tableClasses.icon}
                                onClick={() => openInformation(index)}
                              >
                                <InfoOutlined />
                              </IconButton>
                              {openApprovalInformation &&
                                indexApprovalInformation == index && (
                                  <Box className={classes.informationBox}>
                                    <Typography
                                      variant="caption"
                                      className={classes.title}
                                    >
                                      Action Date
                                    </Typography>
                                    <Typography variant="subtitle2">
                                      <Datetime data={item.timestamp} />
                                    </Typography>
                                    {data.remarks &&
                                      item.status === "DECLINED" && (
                                        <Box mt={1}>
                                          <Typography
                                            variant="caption"
                                            className={classes.title}
                                          >
                                            Notes
                                          </Typography>
                                          <Typography
                                            variant="subtitle2"
                                            className={classes.noteText}
                                          >
                                            {data.remarks}
                                          </Typography>
                                        </Box>
                                      )}
                                  </Box>
                                )}
                            </>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </VuiCardContent>
          </Grid>
        </Grid>
      </Box>

      {canApproved && !isFetchingData ? (
        <Box marginTop={5}>
          <FooterAction
            loading={isLoading}
            label={"Approve This Request?"}
            submitBtnLabel={"Approve"}
            onSubmit={handleSaveData}
            cancelBtnLabel={"Decline"}
            onCancel={handleOpenModal}
          />
        </Box>
      ) : null}

      <DeclineForm
        open={showDeclineForm}
        handleClose={handleCloseModal}
        submitText={"Decline"}
        url={"/apps/approval"}
        decline={"cash-advance-request"}
        id={id}
      />

      <CashAdvanceModal
        open={showCashAdvanceModal}
        handleOpen={handleOpenDrawerCashAdvance}
        handleClose={handleCloseModalCashAdvance}
        data={defaultDataCashAdvance}
      />
    </>
  );
};
