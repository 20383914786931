import React, { useCallback, useEffect, useMemo } from "react";

import { useState } from "@hookstate/core/dist";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { Autocomplete } from "@material-ui/lab";
import { KeyboardDatePicker } from "@material-ui/pickers";
import _ from "lodash";
import moment from "moment";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import CancelIcon from "@material-ui/icons/Cancel";
import { AxiosError, AxiosResponse } from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";

import VuiCardContent from "../../../../../@VodeaUI/components/VuiCardContent";
import TitleForm from "../../../../../components/molecules/TitleForm";
import CashAdvanceRepository from "../../../../../repositories/CashAdvanceRepository";
import UserRepository from "../../../../../repositories/UserRepository";
import MediaRepository from "../../../../../repositories/MediaRepositories";
import { OptionInterface } from "./interface";
import {
  $clone,
  $cloneState,
  mapParams,
} from "../../../../../utilities/helpers/global";
import { useIsMounted } from "../../../../../@VodeaUI";
import FooterFormAction from "../../../../../components/FooterFormAction";
import { useGlobalStyles } from "../../../../../utilities/styles";
import { formatFormData } from "../../../../../utilities/helpers/form";
import VuiNumberFormat from "../../../../../@VodeaUI/components/Input/VuiNumberFormat";
import { useSelector } from "react-redux";
import CurrencyFormat from "../../../../../@VodeaUI/components/Input/CurrencyFormat";
import { ICashAdvanceFormulaPolicy } from "../../../../../interfaces/CashAdvanceFormulaPolicy";
import { validationSchema } from "./validation";
import {
  axiosErrorLoadDataHandler,
  handleAxiosErrorSave,
} from "../../../../../utilities/helpers/axios-error.helper";
import { IApiResource } from "../../../../../interfaces/ApiResource";
import { ICashAdvanceRequestItem } from "../../../../../interfaces/CashAdvanceRequestItem";
import Loading from "../../../../../components/Loading";
import {
  cashAdvanceRequest,
  ICashAdvanceRequest,
} from "../../../../../interfaces/CashAdvanceRequest";

const useStyles = makeStyles({
  tableHead: {
    minWidth: "10rem",
  },
  tableCell: {
    paddingBottom: "1rem",
  },
  fileAttachment: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    marginRight: 8,
    padding: 0,
  },
});

const itemBaseModel = {
  cash_advance_formula_policy_id: null,
  amount: 0,
  description: "",
  asset_id: null,
  attachment: null,
  attachment_id: null,
  maximum: 0,
};

const RequestAdvance: React.FC<any> = () => {
  const [data, setData] =
    React.useState<ICashAdvanceRequest>(cashAdvanceRequest);
  const { id } = useParams();
  const [isFetchingData, setIsFetchingData] = React.useState<boolean>(false);
  const { user } = useSelector(({ vodea }: any) => {
    return {
      user: vodea.auth.user,
    };
  });

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const globalClasses = useGlobalStyles();
  const isMounted = useIsMounted();
  const classes = useStyles();

  const loadData = useCallback(async () => {
    if (!id) {
      return;
    }

    if (isMounted.current) {
      setIsFetchingData(true);
    }

    let params: any = {};
    Object.assign(params, {
      with: [
        "cashAdvanceRequestItems.attachment",
        "approvalLogs.role",
        "approvalLogs.approver",
        "cashAdvanceRequestItems.asset.cashAdvanceRequestItems.cashAdvanceRequest.user",
        "user",
      ],
    });

    await CashAdvanceRepository.show(params, id)
      .then((response: AxiosResponse<IApiResource<ICashAdvanceRequest>>) => {
        if (isMounted.current) {
          const { data: responseData } = response.data;

          const cashAdvanceRequestItems: ICashAdvanceRequestItem[] =
            responseData?.cash_advance_request_items.map((item) => {
              return {
                ...item,
                cash_advance_formula_policy_id:
                  item.cash_advance_formula_policy || null,
                attachment_id: item.attachment || null,
                asset_id: item.asset || null,
                maximum: item?.cash_advance_formula_policy?.is_unlimited
                  ? "Unlimited"
                  : item?.cash_advance_formula_policy?.formula_component
                      ?.components[0]?.value,
              };
            });

          Object.assign(responseData, {
            user_id: responseData.user || null,
            cash_advance_policy_id: responseData.cash_advance_policy || null,
            cash_advance_request_items: cashAdvanceRequestItems || [],
          });

          setData(responseData);
          setIsFetchingData(false);
        }
      })
      .catch((error: AxiosError) => {
        if (isMounted.current) {
          setIsFetchingData(false);
          axiosErrorLoadDataHandler(error, enqueueSnackbar);
        }
      });
  }, []);

  const { control, errors, handleSubmit, setError, setValue, watch, reset } =
    useForm<ICashAdvanceRequest>({
      mode: "onChange",
      defaultValues: useMemo(() => {
        (async () => {
          await loadData();
        })();
        return data;
      }, [id, loadData]),
      resolver: yupResolver(validationSchema()),
    });

  useEffect(() => {
    reset(data);
  }, [data]);

  const watchUser = watch("user_id");
  const watchDescription = watch("description");
  const watchCashAdvancePolicy = watch("cash_advance_policy_id");

  const {
    fields: itemFields,
    append: itemAppend,
    remove: itemRemove,
  } = useFieldArray({
    control,
    keyName: "key",
    name: "cash_advance_request_items",
  });

  const watchRequestItems = watch("cash_advance_request_items");

  const userId = useState<number | string | null>(null);
  const categoryId = useState<number | string | null>(null);

  const [loading, setLoading] = React.useState<boolean>(false);
  const [hasAsset, setHasAsset] = React.useState<boolean>(false);

  const handleAddItem = () => itemAppend(itemBaseModel);

  useEffect(() => {
    if (user && !id) {
      setTimeout(() => {
        userId.set(user.id);
        setValue("user_id", user);
      }, 200);
    }
  }, [user]);

  const option = useState<OptionInterface>({
    employee: {
      options: [],
      loading: false,
      params: {
        for: "cash-advance",
      },
      loadData: async () => {
        if (isMounted.current) {
          option.employee.loading.set(true);
        }
        await UserRepository.select(
          mapParams($cloneState(option.employee.params))
        )
          .then(({ data }: AxiosResponse) => {
            if (isMounted.current) {
              option.employee.options.set(data.data);
              option.employee.loading.set(false);
            }
          })
          .catch(() => {});
      },
      onOpen: () => {
        if (isMounted.current) {
          option.employee.options.set([]);
          option.employee.loadData.get()();
        }
      },
    },
    category: {
      options: [],
      loading: false,
      params: {
        for: "cash-advance",
      },
      loadData: async () => {
        if (isMounted.current) {
          option.category.loading.set(true);
        }
        await CashAdvanceRepository.allPolicy({
          ...mapParams($cloneState(option.category.params)),
          user: userId.get(),
        })
          .then(({ data }: AxiosResponse) => {
            if (isMounted.current) {
              option.category.options.set(data.data);
              option.category.loading.set(false);
            }
          })
          .catch(() => {});
      },
      onOpen: () => {
        if (isMounted.current) {
          option.category.options.set([]);
          option.category.loadData.get()();
        }
      },
    },
    formula: {
      options: [],
      loading: false,
      params: {
        for: "cash-advance",
      },
      loadData: async () => {
        if (isMounted.current) {
          option.formula.loading.set(true);
        }
        await CashAdvanceRepository.getFormula(categoryId.get(), {
          ...mapParams($cloneState(option.formula.params)),
          user: userId.get(),
        })
          .then(({ data }: AxiosResponse) => {
            if (isMounted.current) {
              option.formula.options.set(data.data);
              option.formula.loading.set(false);
            }
          })
          .catch(() => {});
      },
      onOpen: () => {
        if (isMounted.current) {
          option.formula.options.set([]);
          option.formula.loadData.get()();
        }
      },
    },
    asset: {
      options: [],
      loading: false,
      params: {
        for: "cash-advance",
      },
      loadData: async () => {
        if (isMounted.current) {
          option.asset.loading.set(true);
        }
        await CashAdvanceRepository.getAsset({
          ...mapParams($cloneState(option.asset.params)),
          user: userId.get(),
        })
          .then(({ data }: AxiosResponse) => {
            if (isMounted.current) {
              option.asset.options.set(data.data);
              option.asset.loading.set(false);
            }
          })
          .catch(() => {});
      },
      onOpen: () => {
        if (isMounted.current) {
          option.asset.options.set([]);
          option.asset.loadData.get()();
        }
      },
    },
  });

  const onSubmit = async (data: ICashAdvanceRequest) => {
    if (isMounted.current) {
      setLoading(true);
    }

    const requestItems: ICashAdvanceRequestItem[] = [];
    const formData = formatFormData(data);

    if (formData?.cash_advance_request_items?.length > 0) {
      formData?.cash_advance_request_items.forEach(
        (item: ICashAdvanceRequestItem) => {
          if (item.cash_advance_formula_policy_id) {
            if (item.asset_id) {
              requestItems.push({
                amount: item?.amount,
                asset_id: item?.asset_id?.id,
                description: item?.description,
                cash_advance_formula_policy_id:
                  item?.cash_advance_formula_policy_id?.id,
                attachment_id: item?.attachment_id?.id || null,
              });
            } else {
              requestItems.push({
                amount: item?.amount,
                description: item?.description,
                cash_advance_formula_policy_id:
                  item?.cash_advance_formula_policy_id?.id,
                attachment_id: item?.attachment_id?.id || null,
              });
            }
          }
        }
      );

      formData.cash_advance_request_items = requestItems;
    }

    if (id) {
      await CashAdvanceRepository.update(id, formData)
        .then((response: any) => {
          if (isMounted.current) {
            setLoading(false);
            enqueueSnackbar(response.data.message, {
              variant: "success",
            });
            navigate("/apps/finance/advance");
          }
        })
        .catch((error: AxiosError) => {
          if (isMounted.current) {
            setLoading(false);
            handleAxiosErrorSave(error, setError, enqueueSnackbar);
          }
        });
    } else {
      await CashAdvanceRepository.create(formData)
        .then((response: any) => {
          if (isMounted.current) {
            setLoading(false);
            enqueueSnackbar(response.data.message, {
              variant: "success",
            });
            navigate("/apps/finance/advance");
          }
        })
        .catch((error: AxiosError) => {
          if (isMounted.current) {
            setLoading(false);
            handleAxiosErrorSave(error, setError, enqueueSnackbar);
          }
        });
    }
  };

  const handleUploadFile = async (index: any, files: any) => {
    let formData = new FormData();
    formData.append("file", files[0]);
    formData.append("path", "cash-advance-request");
    formData.append("disk", "gcs");

    await MediaRepository.create(formData)
      .then((response: any) => {
        setValue(
          `cash_advance_request_items[${index}].attachment_id`,
          response.data.data
        );
      })
      .catch((error: any) => {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      });
  };

  const handleMaximumValueBaseOnFormula = (formula: any, index: number) => {
    if (formula.is_unlimited) {
      setValue(`cash_advance_request_items[${index}].maximum`, "Unlimited");
    } else {
      setValue(
        `cash_advance_request_items[${index}].maximum`,
        formula.formula_component?.components[0]?.value
      );
    }
  };

  const checkHasAsset = () => {
    if (isMounted.current) {
      setHasAsset(false);
      if (watchRequestItems.length > 0) {
        watchRequestItems.forEach((item: any) => {
          if (item?.cash_advance_formula_policy_id?.is_asset_required) {
            setHasAsset(true);
          }
        });
      }
    }
  };

  useEffect(() => {
    checkHasAsset();
  }, [watchRequestItems]);

  const handleAddCategory = (
    value: ICashAdvanceFormulaPolicy,
    index: number
  ) => {
    if (value && index === itemFields.length - 1) {
      handleAddItem();
    }
  };

  const calculateTotalAmountItem = (
    items: Array<ICashAdvanceRequestItem> = []
  ) => {
    let totalAmount: number = 0;
    if (items.length > 0) {
      items.map(
        (item) =>
          (totalAmount +=
            typeof item.amount === "string"
              ? isNaN(parseFloat(item.amount))
                ? 0
                : parseFloat(item.amount)
              : item.amount)
      );
    }
    return totalAmount;
  };

  return (
    <>
      <TitleForm title="Request Advance" withBackUrl={true} />
      {isFetchingData ? <Loading /> : null}
      <Grid
        container
        spacing={3}
        style={isFetchingData ? { display: "none" } : {}}
      >
        <Grid item md={8} xs={12}>
          <VuiCardContent title="Information">
            <Box p={2}>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <Controller
                    render={({ value, name, onChange }) => {
                      return (
                        <Autocomplete
                          value={value}
                          disabled={!!id}
                          disableClearable
                          loading={option.employee.loading.get()}
                          options={$clone(option.employee.options.get())}
                          onOpen={option.employee.onOpen.get()}
                          getOptionLabel={(option) => option.name}
                          getOptionSelected={(option, value) =>
                            option.id === value.id
                          }
                          onChange={(event: any, newValue: any) => {
                            onChange(newValue);
                            userId.set(newValue.id);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name={name}
                              label="Employee"
                              variant="outlined"
                              error={_.has(errors, `user_id`)}
                              helperText={_.get(errors, `user_id.message`)}
                            />
                          )}
                        />
                      );
                    }}
                    name="user_id"
                    control={control}
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <Controller
                    render={({ value, name, onChange }) => (
                      <Autocomplete
                        value={value}
                        disableClearable
                        loading={option.category.loading.get()}
                        disabled={!!id}
                        options={$clone(option.category.options.get())}
                        onOpen={option.category.onOpen.get()}
                        getOptionLabel={(option) => option.name}
                        getOptionSelected={(option, value) =>
                          option.id === value.id
                        }
                        onChange={(event: any, newValue: any) => {
                          onChange(newValue);
                          categoryId.set(newValue?.id);
                          if (itemFields.length == 0) handleAddItem();
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name={name}
                            label="Advance Policy"
                            variant="outlined"
                            error={_.has(errors, `cash_advance_policy_id`)}
                            helperText={_.get(
                              errors,
                              `cash_advance_policy_id.message`
                            )}
                          />
                        )}
                      />
                    )}
                    name="cash_advance_policy_id"
                    control={control}
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <Controller
                    render={({ value, name, onChange }) => (
                      <KeyboardDatePicker
                        name={name}
                        disableToolbar
                        fullWidth
                        variant="inline"
                        inputVariant="outlined"
                        label="Use On"
                        minDate={new Date()}
                        format="DD MMMM YYYY"
                        error={_.has(errors, "date")}
                        helperText={_.get(errors, "date.message")}
                        value={value}
                        onChange={onChange}
                      />
                    )}
                    name="date"
                    control={control}
                  />
                </Grid>

                <Grid item md={12} xs={12}>
                  <Controller
                    render={({ value, name, onChange }) => (
                      <TextField
                        name={name}
                        label="Purpose"
                        value={value}
                        variant="outlined"
                        fullWidth
                        onChange={onChange}
                        error={_.has(errors, "description")}
                        helperText={_.get(errors, "description.message")}
                      />
                    )}
                    name="description"
                    control={control}
                  />
                </Grid>
              </Grid>
            </Box>
          </VuiCardContent>

          {watchCashAdvancePolicy && (
            <Box mt={3}>
              <VuiCardContent title="Detail">
                <Box mb={2}>
                  <TableContainer>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.tableHead}>
                            Category
                          </TableCell>
                          {hasAsset && (
                            <TableCell className={classes.tableHead}>
                              Asset
                            </TableCell>
                          )}
                          <TableCell className={classes.tableHead}>
                            Maximum (IDR)
                          </TableCell>
                          <TableCell className={classes.tableHead}>
                            Description
                          </TableCell>
                          <TableCell className={classes.tableHead}>
                            Amount (IDR)
                          </TableCell>
                          <TableCell>File</TableCell>
                          <TableCell />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {itemFields.map((item: any, index: number) => {
                          return (
                            <TableRow key={item.key}>
                              <TableCell className={classes.tableCell}>
                                <Controller
                                  render={({ value, name, onChange }) => (
                                    <Autocomplete
                                      value={value}
                                      disableClearable
                                      loading={option.formula.loading.get()}
                                      options={$clone(
                                        option.formula.options.get()
                                      )}
                                      disabled={!!id}
                                      onOpen={option.formula.onOpen.get()}
                                      getOptionLabel={(option) => option.name}
                                      getOptionSelected={(option, value) =>
                                        option.id === value.id
                                      }
                                      onChange={(
                                        event: any,
                                        newValue: ICashAdvanceFormulaPolicy
                                      ) => {
                                        onChange(newValue);
                                        handleMaximumValueBaseOnFormula(
                                          newValue,
                                          index
                                        );
                                        handleAddCategory(newValue, index);
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          name={name}
                                          variant="outlined"
                                          error={_.has(
                                            errors,
                                            `cash_advance_request_items[${index}].cash_advance_formula_policy_id`
                                          )}
                                          helperText={_.get(
                                            errors,
                                            `cash_advance_request_items[${index}].cash_advance_formula_policy_id.message`
                                          )}
                                        />
                                      )}
                                    />
                                  )}
                                  defaultValue={
                                    item.cash_advance_formula_policy_id
                                  }
                                  name={`cash_advance_request_items[${index}].cash_advance_formula_policy_id`}
                                  control={control}
                                />
                              </TableCell>

                              {hasAsset &&
                              watchRequestItems[index]
                                ?.cash_advance_formula_policy_id
                                ?.is_asset_required == 1 ? (
                                <TableCell className={classes.tableCell}>
                                  <Controller
                                    render={({ value, name, onChange }) => (
                                      <Autocomplete
                                        value={value}
                                        disableClearable
                                        loading={option.asset.loading.get()}
                                        options={$clone(
                                          option.asset.options.get()
                                        )}
                                        onOpen={option.asset.onOpen.get()}
                                        getOptionLabel={(option) => option.name}
                                        getOptionSelected={(option, value) =>
                                          option.id === value.id
                                        }
                                        onChange={(
                                          event: any,
                                          newValue: any
                                        ) => {
                                          onChange(newValue);
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            name={name}
                                            variant="outlined"
                                            error={_.has(
                                              errors,
                                              `cash_advance_request_items[${index}].asset_id`
                                            )}
                                            helperText={_.get(
                                              errors,
                                              `cash_advance_request_items[${index}].asset_id.message`
                                            )}
                                          />
                                        )}
                                      />
                                    )}
                                    defaultValue={item.asset_id}
                                    name={`cash_advance_request_items[${index}].asset_id`}
                                    control={control}
                                  />
                                </TableCell>
                              ) : watchRequestItems[index]
                                  ?.cash_advance_formula_policy_id
                                  ?.is_asset_required == 0 && hasAsset ? (
                                <TableCell />
                              ) : hasAsset ? (
                                <TableCell />
                              ) : null}

                              <TableCell className={classes.tableCell}>
                                <Controller
                                  name={`cash_advance_request_items[${index}].maximum`}
                                  render={({ value, name, onChange }) => {
                                    return value === "Unlimited" ? (
                                      <Typography variant="body2">
                                        {value}
                                      </Typography>
                                    ) : (
                                      <VuiNumberFormat data={value} />
                                    );
                                  }}
                                  control={control}
                                  defaultValue={item.maximum}
                                />
                              </TableCell>

                              <TableCell className={classes.tableCell}>
                                <Controller
                                  name={`cash_advance_request_items[${index}].description`}
                                  render={({ value, name, onChange }) => (
                                    <TextField
                                      name={name}
                                      value={value}
                                      variant="outlined"
                                      onChange={(event) => {
                                        onChange(event.target.value);
                                      }}
                                      error={_.has(
                                        errors,
                                        `cash_advance_request_items[${index}].description`
                                      )}
                                      helperText={_.get(
                                        errors,
                                        `cash_advance_request_items[${index}].description.message`
                                      )}
                                    />
                                  )}
                                  control={control}
                                  defaultValue={item.description}
                                />
                              </TableCell>

                              <TableCell className={classes.tableCell}>
                                <Controller
                                  name={`cash_advance_request_items[${index}].amount`}
                                  render={({ value, name, onChange }) => (
                                    <TextField
                                      name={name}
                                      value={value}
                                      variant="outlined"
                                      InputProps={{
                                        inputComponent: CurrencyFormat,
                                      }}
                                      onChange={(event) => {
                                        onChange(event.target.value);
                                      }}
                                      error={_.has(
                                        errors,
                                        `cash_advance_request_items[${index}].amount`
                                      )}
                                      helperText={_.get(
                                        errors,
                                        `cash_advance_request_items[${index}].amount.message`
                                      )}
                                    />
                                  )}
                                  control={control}
                                  defaultValue={item.amount}
                                />
                              </TableCell>

                              <TableCell className={classes.tableCell}>
                                <Controller
                                  name={`cash_advance_request_items[${index}].attachment_id`}
                                  control={control}
                                  render={({ value, name, onChange }) => {
                                    return value ? (
                                      value.name ? (
                                        <div className={classes.fileAttachment}>
                                          <IconButton
                                            className={classes.icon}
                                            onClick={() =>
                                              setValue(
                                                `cash_advance_request_items[${index}].attachment_id`,
                                                null
                                              )
                                            }
                                          >
                                            <CancelIcon />
                                          </IconButton>
                                          <div>{value?.name}</div>
                                        </div>
                                      ) : (
                                        <CircularProgress />
                                      )
                                    ) : (
                                      <Button
                                        variant="contained"
                                        component="label"
                                        color="primary"
                                        className={globalClasses.buttonPrimary}
                                      >
                                        <input
                                          name={name}
                                          type="file"
                                          hidden
                                          className="btn btn-primary"
                                          id="raised-button-file"
                                          onChange={(event: any) => {
                                            onChange(event.target.files);
                                            handleUploadFile(
                                              index,
                                              event.target.files
                                            );
                                          }}
                                        />
                                        Attach
                                      </Button>
                                    );
                                  }}
                                  defaultValue={item.attachment_id}
                                />
                              </TableCell>

                              <TableCell className={classes.tableCell}>
                                {itemFields.length > 0 &&
                                  index != itemFields.length - 1 && (
                                    <Button
                                      component="label"
                                      onClick={() => {
                                        itemRemove(index);
                                      }}
                                    >
                                      <DeleteOutlineIcon />
                                    </Button>
                                  )}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </VuiCardContent>
            </Box>
          )}
        </Grid>

        <Grid item md={4} xs={12}>
          <VuiCardContent title="Summary">
            <Box p={2}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="caption">Request Date</Typography>
                  <Typography variant="subtitle2">
                    {moment().format("DD MMMM YYYY")}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="caption">Employee</Typography>
                  <Typography variant="subtitle2">
                    {watchUser?.name || "-"}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="caption">Advance Policy</Typography>
                  <Typography variant="subtitle2">
                    {watchCashAdvancePolicy?.name || "-"}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="caption">Purpose</Typography>
                  <Typography variant="subtitle2">
                    {watchDescription || "-"}
                  </Typography>
                </Grid>
                {watchCashAdvancePolicy && (
                  <Grid item xs={12}>
                    <Typography variant="caption">Total Amount</Typography>
                    <Typography variant="subtitle2">
                      <VuiNumberFormat
                        data={calculateTotalAmountItem(watchRequestItems)}
                        value={calculateTotalAmountItem(watchRequestItems)}
                        decimalScale={3}
                      />
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Box>
          </VuiCardContent>
        </Grid>

        <FooterFormAction
          cancelUrl={"/apps/finance/advance"}
          loading={loading}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          labelForm={"Submit Request Advance?"}
        />
      </Grid>
    </>
  );
};

export default RequestAdvance;
