import { api, createCancelTokenHandler } from "../@VodeaUI";

let url = window._env_.REACT_APP_PASSPORT_END_POINT;

const MediaRepository = {
  all: function (params: any = null) {
    return api.get(`${url}/api/media`, {
      params,
    });
  },
  show: function (id: number, params: any = null) {
    return api.get(`${url}/api/media/${id}`, {
      params,
    });
  },
  create: function (payload: any, params: any = null) {
    return api.post(`${url}/api/media`, payload, {
      params,
    });
  },
 
};

export default MediaRepository;

const cancelTokenHandlerObject = createCancelTokenHandler(MediaRepository);
