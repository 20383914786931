import { api, createCancelTokenHandler } from "../@VodeaUI";

let url = window._env_.REACT_APP_PASSPORT_END_POINT;

const CashAdvanceRepository = {
  all: function (params: any = null) {
    return api.get(`${url}/api/cash-advance-request`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.all.name].handleRequestCancellation()
          .token,
    });
  },
  account: function (params: any = null) {
    return api.get(`${url}/api/account/cash-advance-request`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.account.name].handleRequestCancellation()
          .token,
    });
  },
  show: function (params: any = null, id: any) {
    return api.get(`${url}/api/cash-advance-request/${id}`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.show.name].handleRequestCancellation()
          .token,
    });
  },
  allPolicy: function (params: any = null) {
    return api.get(`${url}/api/cash-advance-policy`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[
          this.allPolicy.name
        ].handleRequestCancellation().token,
    });
  },
  approve: function (id: number | string, params: any = null) {
    return api.put(`${url}/api/cash-advance-request/${id}/approve`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.approve.name].handleRequestCancellation()
          .token,
    });
  },
  create: function (payload: any, params: any = null) {
    return api.post(`${url}/api/cash-advance-request`, payload, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.create.name].handleRequestCancellation()
          .token,
    });
  },
  update: function (id: number | string, payload: any, params: any = null) {
    return api.put(`${url}/api/cash-advance-request/${id}`, payload, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.update.name].handleRequestCancellation()
          .token,
    });
  },
  getFormula: function (id: number | string | null, params: any = null) {
    return api.get(`${url}/api/select/cash-advance-policy/${id}/formula`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[
          this.getFormula.name
        ].handleRequestCancellation().token,
    });
  },
  getAsset: function (params: any = null) {
    return api.get(`${url}/api/select/asset`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.getAsset.name].handleRequestCancellation()
          .token,
    });
  },
  updateItemAmount: function (id: number, payload: any) {
    return api.put(`${url}/api/cash-advance-request-item/${id}`, payload, {
      cancelToken:
        cancelTokenHandlerObject[
          this.updateItemAmount.name
        ].handleRequestCancellation().token,
    });
  },
};

const cancelTokenHandlerObject = createCancelTokenHandler(
  CashAdvanceRepository
);

export default CashAdvanceRepository;
