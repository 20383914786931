import React, { useEffect } from "react";
import { Box, Button, Grid, Paper, Typography } from "@material-ui/core";
import { useState } from "@hookstate/core";
import _ from "lodash";
import { Link, useNavigate } from "react-router-dom";
import { VuiSearch, VuiTabs } from "../../../@VodeaUI/components";
import VuiSelect from "../../../@VodeaUI/components/Input/VuiSelect/Index";
import VuiDataTable, {
  Column,
  Options,
} from "../../../@VodeaUI/VuiDataTable/Index";
import {
  $clone,
  $cloneState,
  calcBusinessDays,
  insertQuerySearch,
  mapDataTableParams,
} from "../../../utilities/helpers/global";
import { useIsMounted, useQuerySearch } from "../../../utilities/helpers/hooks";
import { defaultDataTableOption } from "../../../utilities/Option";
import ApprovalRepository from "../../../repositories/ApprovalRepository";
import moment from "moment";
import VuiNumberFormat from "../../../@VodeaUI/components/Input/VuiNumberFormat";

const tabs = [
  {
    label: "Time Off",
    params: "time-off",
    entity: "App\\Models\\TimeOffRequest",
    count: 0,
  },
  {
    label: "Attendance",
    params: "attendance",
    entity: "App\\Models\\AttendanceRequest",
    count: 0,
  },
  {
    label: "Reimbursement",
    params: "reimbursement",
    entity: "App\\Models\\ReimbursementRequest",
    count: 0,
  },
  {
    label: "Advance",
    params: "advance",
    entity: "App\\Models\\CashAdvanceRequest",
    count: 0,
  },
  {
    label: "Settlement",
    params: "settlement",
    entity: "App\\Models\\Settlement",
    count: 0,
  },
];

const orderByOptions = [
  {
    key: "all",
    label: "All",
  },
  {
    key: "a-z",
    label: "A-Z",
  },
];

const Approval: React.FC<any> = () => {
  const navigate = useNavigate();
  const isMounted = useIsMounted();
  const { tab: queryTab } = useQuerySearch();
  const parsedQueryTab: number = isNaN(Number(queryTab)) ? 0 : Number(queryTab);
  const defaultTab = _.has(tabs, parsedQueryTab) ? parsedQueryTab : 0;
  const tabBadges = useState<any[]>([]);
  const tab = useState(defaultTab);
  const orderBy = useState("all");
  const loading = useState<boolean>(false);
  const options = useState<Options>({
    ...defaultDataTableOption,
    sortedBy: "asc",
    orderBy: "created_at",
  });
  const tableData = useState<any[]>([]);

  useEffect(() => {
    insertQuerySearch("tab", tab.get());
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab.get()]);

  const handleTabsChange = (value: any) => {
    if (isMounted.current) {
      tab.set(value);
    }
  };

  const loadData = async () => {
    loading.set(true);
    let params: any = {
      with: ["subject.user", "user"],
      entity: _.get(tabs, `${tab.get()}.entity`, null),
    };
    const entity = _.get(tabs, `${tab.get()}.params`, null);
    if (entity === "time-off") {
      params.with.push("subject.timeOffPolicy");
    }

    if (entity === "attendance") {
      params.with.push("subject.attendancePolicy");
    }

    if (entity === "reimbursement") {
      params.with.push("subject.reimbursementPolicy");
    }

    if (entity === "advance") {
      params.with.push("subject.cashAdvancePolicy");
    }

    await ApprovalRepository.account({
      ...params,
      ...mapDataTableParams($cloneState(options)),
    })
      .then((resp: any) => {
        if (isMounted.current) {
          tableData.set(resp.data.data);
          options.page.set(resp.data.meta.current_page - 1);
          options.total.set(resp.data.meta.total);
          loading.set(false);
        }
      })
      .catch((err: any) => {
        if (isMounted.current) {
          loading.set(false);
        }
      });
  };

  const loadBadgeData = async () => {
    await ApprovalRepository.account()
      .then((resp: any) => {
        const data = resp.data.data;
        const temp = tabs;
        if (isMounted.current) {
          tabs.forEach((item: any, index: number) => {
            const filterEntity = data.filter(
              (x: any) => x.entity === item.entity
            );
            temp[index].count = filterEntity.length;
          });
        }
        tabBadges.set(temp);
      })
      .catch((err: any) => {});
  };

  useEffect(() => {
    loadBadgeData();
  }, []);

  const timeOffColumns = useState<Column[]>([
    {
      key: "created_at",
      label: "Request Date",
      render: (row) => {
        return (
          <Typography variant={"body2"}>
            {row.subject?.created_at
              ? moment(row.subject?.created_at).format("DD MMMM YYYY")
              : "-"}
          </Typography>
        );
      },
    },
    {
      key: "category",
      label: "Category",
      render: (row) => {
        return (
          <Typography variant={"body2"}>
            {_.get(row, "subject.time_off_policy.name", "-")}
          </Typography>
        );
      },
    },
    {
      key: "name",
      label: "Name",
      render: (row) => {
        return (
          <Typography variant={"body2"}>
            {_.get(row, "subject.user.name", "-")}
          </Typography>
        );
      },
    },
    {
      key: "from",
      label: "From",
      render: (row) => {
        return (
          <Typography variant={"body2"}>
            {row?.subject?.start
              ? moment(row?.subject?.start).format("DD MMMM YYYY")
              : "-"}
          </Typography>
        );
      },
    },
    {
      key: "days",
      label: "Number of Days",
      render: (row) => {
        return (
          <Typography variant={"body2"}>
            {calcBusinessDays(row.subject?.start, row.subject?.finish)} Day(s)
          </Typography>
        );
      },
    },
    {
      key: "id",
      label: "",
      sortable: false,
      render: (row) => {
        return (
          <Box display="flex" justifyContent={"flex-end"}>
            <Button
              onClick={() =>
                navigate(`detail/${row.subject_id}`, {
                  state: {
                    type: 0,
                  },
                })
              }
              className="btnView"
              variant="contained"
              color="primary"
            >
              View
            </Button>
          </Box>
        );
      },
    },
  ]);

  const attendanceColumns = useState<Column[]>([
    {
      key: "created_at",
      label: "Request Date",
      render: (row) => {
        return (
          <Typography variant={"body2"}>
            {row.subject?.created_at
              ? moment(row.subject?.created_at).format("DD MMMM YYYY")
              : "-"}
          </Typography>
        );
      },
    },
    {
      key: "name",
      label: "Name",
      render: (row) => {
        return (
          <Typography variant={"body2"}>
            {_.get(row, "subject.user.name", "-")}
          </Typography>
        );
      },
    },
    {
      key: "days",
      label: "Attendance Request",
      render: (row) => {
        return (
          <Typography variant={"body2"}>
            {row.subject?.clock_in && row.subject?.clock_in
              ? "Clock In and Clock Out"
              : row.subject?.clock_out
              ? "Clock Out"
              : row.subject?.clock_in
              ? "Clock In"
              : ""}
          </Typography>
        );
      },
    },
    {
      key: "id",
      label: "",
      sortable: false,
      render: (row) => {
        return (
          <Box display="flex" justifyContent={"flex-end"}>
            <Button
              onClick={() =>
                navigate(`detail/${row.subject_id}`, {
                  state: {
                    type: 1,
                  },
                })
              }
              className="btnView"
              variant="contained"
              color="primary"
            >
              View
            </Button>
          </Box>
        );
      },
    },
  ]);

  const reimbursementColumns = useState<Column[]>([
    {
      key: "created_at",
      label: "Request Date",
      render: (row) => {
        return (
          <Typography variant={"body2"}>
            {row.subject?.created_at
              ? moment(row.subject?.created_at).format("DD MMMM YYYY")
              : "-"}
          </Typography>
        );
      },
    },
    {
      key: "category",
      label: "Category",
      render: (row) => {
        return (
          <Typography variant={"body2"}>
            {_.get(row, "subject.reimbursement_policy.name", "-")}
          </Typography>
        );
      },
    },
    {
      key: "name",
      label: "Name",
      render: (row) => {
        return (
          <Typography variant={"body2"}>
            {_.get(row, "subject.user.name", "-")}
          </Typography>
        );
      },
    },
    {
      key: "amount",
      label: "Amount (IDR)",
      render: (row) => {
        return (
          <VuiNumberFormat
            data={_.get(row, "subject.amount", "-")}
            value={_.get(row, "subject.amount", "-")}
          />
        );
      },
    },
    {
      key: "id",
      label: "",
      sortable: false,
      render: (row) => {
        return (
          <Box display="flex" justifyContent={"flex-end"}>
            <Button
              onClick={() =>
                navigate(`detail/${row.subject_id}`, {
                  state: {
                    type: 2,
                  },
                })
              }
              className="btnView"
              variant="contained"
              color="primary"
            >
              View
            </Button>
          </Box>
        );
      },
    },
  ]);

  const advanceColumns = useState<Column[]>([
    {
      key: "created_at",
      label: "Request Date",
      render: (row) => {
        return (
          <Typography variant={"body2"}>
            {row.subject?.created_at
              ? moment(row.subject?.created_at).format("DD MMMM YYYY")
              : "-"}
          </Typography>
        );
      },
    },
    {
      key: "category",
      label: "Category",
      render: (row) => {
        return (
          <Typography variant={"body2"}>
            {_.get(row, "subject.cash_advance_policy.name", "-")}
          </Typography>
        );
      },
    },
    {
      key: "name",
      label: "Name",
      render: (row) => {
        return (
          <Typography variant={"body2"}>
            {_.get(row, "subject.user.name", "-")}
          </Typography>
        );
      },
    },
    {
      key: "amount",
      label: "Amount (IDR)",
      render: (row) => {
        return (
          <VuiNumberFormat
            data={_.get(row, "subject.amount", "-")}
            value={_.get(row, "subject.amount", "-")}
          />
        );
      },
    },
    {
      key: "id",
      label: "",
      sortable: false,
      render: (row) => {
        return (
          <Box display="flex" justifyContent={"flex-end"}>
            <Link to={`/apps/finance/advance/${row.subject_id}`}>
              <Button className="btnView" variant="contained" color="primary">
                View
              </Button>
            </Link>
          </Box>
        );
      },
    },
  ]);

  const settlementColumns = useState<Column[]>([
    {
      key: "created_at",
      label: "Request Date",
      render: (row) => {
        return (
          <Typography variant={"body2"}>
            {row.subject?.created_at
              ? moment(row.subject?.created_at).format("DD MMMM YYYY")
              : "-"}
          </Typography>
        );
      },
    },
    {
      key: "number",
      label: "Number",
      render: (row) => {
        return (
          <Typography variant={"body2"}>
            {_.get(row, "subject.number", "-")}
          </Typography>
        );
      },
    },
    {
      key: "name",
      label: "Name",
      render: (row) => {
        return (
          <Typography variant={"body2"}>
            {_.get(row, "subject.user.name", "-")}
          </Typography>
        );
      },
    },
    {
      key: "amount",
      label: "Amount (IDR)",
      render: (row) => {
        return (
          <VuiNumberFormat
            data={_.get(row, "subject.amount", "-")}
            value={_.get(row, "subject.amount", "-")}
          />
        );
      },
    },
    {
      key: "id",
      label: "",
      sortable: false,
      render: (row) => {
        return (
          <Box display="flex" justifyContent={"flex-end"}>
            <Button
              onClick={() =>
                navigate(`detail/${row.subject_id}`, {
                  state: {
                    type: 4,
                  },
                })
              }
              className="btnView"
              variant="contained"
              color="primary"
            >
              View
            </Button>
          </Box>
        );
      },
    },
  ]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item>
          <Typography variant={"h5"}>Approval</Typography>
        </Grid>
      </Grid>
      <Box mt={2}>
        <VuiTabs
          name="approvalTabs"
          value={tab.get()}
          hasBadge={true}
          badgeCount={tabBadges.get()}
          tabList={tabs.map((a) => a.label)}
          handleTabsChange={(value: any) => handleTabsChange(value)}
        />
        <Box mt={4}>
          <Paper variant="elevation" elevation={1}>
            <Box p={2}>
              <Grid
                container
                spacing={3}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Grid item>
                  <VuiSearch
                    state={$cloneState(options.search)}
                    callback={() => {
                      options.page.set(0);
                      loadData();
                    }}
                  />
                </Grid>
                <Grid item>
                  <VuiSelect
                    label="Sort"
                    state={orderBy}
                    options={orderByOptions}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box mt={2}>
              <VuiDataTable
                loading={loading.get()}
                columns={
                  tab.get() == 0
                    ? timeOffColumns.value
                    : tab.get() == 1
                    ? attendanceColumns.value
                    : tab.get() == 2
                    ? reimbursementColumns.value
                    : tab.get() == 3
                    ? advanceColumns.value
                    : settlementColumns.value
                }
                options={$clone(options.value)}
                data={tableData.get()}
                onChangeOptions={(values: Options) => {
                  options.set(values);
                  loadData();
                }}
              />
            </Box>
          </Paper>
        </Box>
      </Box>
    </>
  );
};

export default Approval;
