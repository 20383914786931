import { api } from "../@VodeaUI";

let url = window._env_.REACT_APP_PASSPORT_END_POINT;

const ApprovalRepository = {
    all: function(params: any = null) {
        return api.get(`${url}/api/approval-request`, {
            params
        });
    },
    account: function(params: any = null){
        return api.get(`${url}/api/account/approval-request`, {
            params
        });
    },
};

export default ApprovalRepository;
