import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 200,
  },
  textInput: {
    borderRadius:  8,
  }
}))
