import { api } from "../@VodeaUI";

let url = window._env_.REACT_APP_PASSPORT_END_POINT;

const SettlementRepository = {
  all: function (params: any = null) {
    return api.get(`${url}/api/settlement`, {
      params,
    });
  },
  account: function (params: any = null) {
    return api.get(`${url}/api/account/settlement`, {
      params,
    });
  },
  show: function (params: any = null, id: any) {
    return api.get(`${url}/api/settlement/${id}`, {
      params,
    });
  },
  approve: function (id: number | string, params: any = null) {
    return api.put(`${url}/api/settlement/${id}/approve`, {
      params,
    });
  },
  create: function (payload: any, params: any = null) {
    return api.post(`${url}/api/settlement`, payload, {
      params,
    });
  },
  getCategory: function (params: any = null) {
    return api.get(`${url}/api/cash-advance-request`, {
      params,
    });
  },
  getDetail: function (id: number | string, params: any = null) {
    return api.get(`${url}/api/cash-advance-request/${id}`, {
      params,
    });
  },
};

export default SettlementRepository;
