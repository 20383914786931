import React from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
} from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import { useState } from "@hookstate/core/dist";
import _ from "lodash";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { useGlobalStyles } from "../../../utilities/styles";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import axios from "../../../@VodeaUI/services/api.service";
import { useIsMounted } from "../../../@VodeaUI";

interface Props {
  open: boolean;
  handleClose: any;
  submitText?: string;
  id?: string | number;
  url?: string;
  decline?: string;
}

const useStyles = makeStyles({
  dialogFooter: {
    paddingTop: 10,
    paddingBottom: 25,
    justifyContent: "center",
  },
});

const endPoint = window._env_.REACT_APP_PASSPORT_END_POINT;

const DeclineForm: React.FC<Props> = ({
  open,
  handleClose,
  submitText = "Submit",
  id,
  url = "/",
  decline,
}) => {
  const { control, handleSubmit, errors } = useForm({
    mode: "onChange",
  });

  const isMounted = useIsMounted();
  const loading = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const classes = useStyles();
  const globalClasses = useGlobalStyles();

  const onSubmit = handleSubmit((formData: any) => {
    if (isMounted.current) {
      loading.set(true);
    }
    axios
      .put(`${endPoint}/api/${decline}/${id}/decline`, {
        remarks: formData.reason,
      })
      .then((resp: any) => {
        if (isMounted.current) {
          loading.set(false);
        }
        enqueueSnackbar(resp.data.message, {
          variant: "success",
        });
        navigate(url);
      })
      .catch((err: any) => {
        if (isMounted.current) {
          loading.set(false);
        }
      });
  });

  return (
    <>
      <Dialog open={open} fullWidth maxWidth="xs" onClose={handleClose}>
        <DialogContent>
          <Box mb={2}>
            <Typography variant="h6">Decline Reason</Typography>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box>
                <Typography>Are you sure to decline this request</Typography>
              </Box>
            </Box>
          </Box>
          <Box>
            <Controller
              render={({ value, name, onChange }) => (
                <TextField
                  name={name}
                  label="Reason"
                  variant="outlined"
                  onChange={onChange}
                  value={value}
                  fullWidth
                  error={_.has(errors, `reason`)}
                  helperText={_.get(errors, `reason.message`)}
                />
              )}
              name={`reason`}
              control={control}
              defaultValue={""}
            />
          </Box>
        </DialogContent>
        <DialogActions className={classes.dialogFooter}>
          <Button size="small" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            size="small"
            color="primary"
            className={clsx(
              globalClasses.buttonPrimary,
              globalClasses.buttonLg
            )}
            onClick={onSubmit}
            disabled={loading.get()}
          >
            {loading.get() ? (
              <CircularProgress color="inherit" size={20} />
            ) : (
              submitText
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeclineForm;
