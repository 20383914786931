import React from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDocumentTitle } from "../../../../@VodeaUI";
import {
  Box,
  Button,
  Divider,
  Container,
  TextField,
  Typography,
  FormControl,
  CardContent,
  Paper,
} from "@material-ui/core";
import { useStyles } from "../../Style";
import { Images } from "../../../../assets/images";
import { Link } from "react-router-dom";
import UserRepository from "../../../../repositories/UserRepository";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router";
import AuthenticationRepository from "../../../../repositories/AuthenticationRepository";

const schema = yup.object().shape({
  email: yup.string().required(),
});

const appHost = () => window._env_.REACT_APP_PUBLIC_APP_HOST;

const AuthForgotPassword: React.FC<any> = () => {
  const classes = useStyles();
  useDocumentTitle("Login");

  const { handleSubmit, errors, control } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
    },
  });

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const onSubmit = (formData: any) => {
    AuthenticationRepository.forgotPassword({
      email: formData.email,
      url: `${appHost()}/auth/password/email`,
    })
      .then((response: any) => {
        enqueueSnackbar("Successfully send link reset to your email", {
          variant: "success",
        });
        navigate("/auth/login");
      })
      .catch((error: any) => {
        enqueueSnackbar(error.message, {
          variant: "success",
        });
      });
  };

  return (
    <>
      <div className={classes.contentWrapper}>
        <Container maxWidth="sm">
          <Paper
            classes={{
              root: classes.cardPaper,
            }}
          >
            <CardContent
              classes={{
                root: classes.cardContent,
              }}
            >
              <Box mb={4}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box mr={2}>
                    <Typography
                      classes={{
                        root: classes.title,
                      }}
                      variant="h5"
                    >
                      Forgot Password
                    </Typography>
                    <Typography>
                      Input your email address to get reset link
                    </Typography>
                  </Box>

                  <div>
                    <img
                      className={classes.brandLogo}
                      src={Images.logo}
                      alt="vodea logo"
                    />
                  </div>
                </Box>
              </Box>

              <Box>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <FormControl fullWidth margin={"normal"}>
                    <Controller
                      as={TextField}
                      name="email"
                      control={control}
                      variant="outlined"
                      defaultValue=""
                      label="Email"
                      fullWidth={true}
                      error={!!errors?.email}
                      helperText={errors?.email?.message}
                    />
                  </FormControl>

                  <FormControl fullWidth margin={"normal"}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="secondary"
                      fullWidth
                    >
                      Reset
                    </Button>
                  </FormControl>

                  <Divider
                    classes={{
                      root: classes.divider,
                    }}
                  />

                  <Typography
                    classes={{
                      root: classes.textLink,
                    }}
                    variant="body1"
                  >
                    Have an account?
                    <Link className="link" to="/auth/login">
                      Sign in
                    </Link>
                  </Typography>
                </form>
              </Box>
            </CardContent>
          </Paper>
        </Container>
      </div>
    </>
  );
};

export default AuthForgotPassword;
