import React from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDocumentTitle } from "../../../@VodeaUI";
import { usePassportService } from "../../../utilities/hooks";
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  FormControl,
  CardContent,
  Paper,
  CircularProgress,
} from "@material-ui/core";
import { useStyles } from "../Style";
import { Images } from "../../../assets/images";
import { Link } from "react-router-dom";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const schema = yup.object().shape({
  username: yup.string().required(),
  password: yup.string().min(6).required(),
});

const AuthLogin: React.FC<any> = () => {
  const classes = useStyles();
  useDocumentTitle("Login");
  const { login, isOnSignIn, isOnFetchingUser } = usePassportService(true);

  const [showPassword, setShowPassword] = React.useState(false);

  const { handleSubmit, errors, control } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      username: "",
      password: "",
    },
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  React.useEffect(() => {
    // TODO : change login redirect
    // window.location.replace('http://localhost:3001/');
  }, []);

  const onSubmit = (formData: any) =>
    login(formData.username, formData.password);

  return (
    <>
      <div className={classes.contentWrapper}>
        <Container maxWidth="sm">
          <Paper
            classes={{
              root: classes.cardPaper,
            }}
          >
            <CardContent
              classes={{
                root: classes.cardContent,
              }}
            >
              <Box mb={4}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box mr={2}>
                    <Typography
                      classes={{
                        root: classes.title,
                      }}
                      variant="h5"
                    >
                      Sign In
                    </Typography>
                    <Typography>Sign in on the internal platform</Typography>
                  </Box>

                  <div>
                    <img
                      className={classes.brandLogo}
                      src={Images.logo}
                      alt="vodea logo"
                    />
                  </div>
                </Box>
              </Box>

              <Box>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <FormControl fullWidth margin={"normal"}>
                    <Controller
                      as={TextField}
                      name="username"
                      control={control}
                      variant="outlined"
                      defaultValue=""
                      label="Email"
                      fullWidth={true}
                      error={!!errors?.username}
                      helperText={errors?.username?.message}
                    />
                  </FormControl>
                  <div className={classes.passwordInputWrapper}>
                    <FormControl fullWidth margin={"normal"}>
                      <Controller
                        as={TextField}
                        name="password"
                        control={control}
                        variant="outlined"
                        defaultValue=""
                        label="Password"
                        type={showPassword ? "text" : "password"}
                        fullWidth={true}
                        error={!!errors?.password}
                        helperText={errors?.password?.message}
                      />
                    </FormControl>
                    <div
                      className={"icon-wrapper"}
                      onClick={handleClickShowPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </div>
                  </div>

                  <FormControl fullWidth margin={"normal"}>
                    <Box textAlign="right">
                      <Link className={classes.link} to="/auth/password/reset">
                        Forgot Password?
                      </Link>
                    </Box>
                  </FormControl>

                  <FormControl fullWidth margin={"normal"}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                      disabled={isOnSignIn || isOnFetchingUser}
                    >
                      {isOnSignIn || isOnFetchingUser ? (
                        <>
                          <Box mr={1}>
                            <CircularProgress color="inherit" size={20} />
                          </Box>{" "}
                          {isOnSignIn ? " SIGNING..." : " FETCHING USER..."}
                        </>
                      ) : (
                        "Sign in"
                      )}
                    </Button>
                  </FormControl>
                </form>
              </Box>
            </CardContent>
          </Paper>
        </Container>
      </div>
    </>
  );
};

export default AuthLogin;
