import _ from "lodash";
import moment from "moment";

export const formatFormData = (data: any) => {
  let returnData: any = {};

  Object.keys(data).forEach(function (key) {
    returnData[key] = data[key];

    if (data[key] !== null) {
      if (key === "is_visible") {
        returnData[key] = Number(_.get(data[key], "id", 0));
      } else if (key.substr(key.length - 3) === "_id") {
        returnData[key] = _.get(data[key], "id", data[key]);
      } else if (key.substr(key.length - 4) === "_ids") {
        returnData[key] = _.map(data[key], "id");
      } else if (moment.isMoment(data[key])) {
        returnData[key] = data[key].format("YYYY-MM-DD");
      } else if (data[key] instanceof Date) {
        returnData[key] = moment(data[key]).format("YYYY-MM-DD");
      } else if (key.substr(key.length - 6) === "_range") {
        let fromKey = key.substr(0, key.length - 6) + "_from";
        let toKey = key.substr(0, key.length - 6) + "_to";

        returnData[fromKey] = data[key]["start"];
        returnData[toKey] = data[key]["end"];

        if (moment.isMoment(data[key]["start"])) {
          returnData[fromKey] = data[key]["start"].format("YYYY-MM-DD");
        }

        if (moment.isMoment(data[key]["end"])) {
          returnData[toKey] = data[key]["end"].format("YYYY-MM-DD");
        }
      }
    }
  });

  return returnData;
};
