import VuiCardContent from "../../../../../@VodeaUI/components/VuiCardContent";
import { Box, Grid, TextField } from "@material-ui/core";
import { Controller, useForm, useFormContext } from "react-hook-form";
import _ from "lodash";
import { Autocomplete } from "@material-ui/lab";
import {
  optionLabel,
  optionSelected,
} from "../../../../../utilities/helpers/select";
import React, { useEffect } from "react";
import { constantToOptions } from "../../../../../utilities/helpers/option.helper";
import { useSelector } from "react-redux";
import {
  $clone,
  $cloneState,
  mapParams,
} from "../../../../../utilities/helpers/global";
import { useState } from "@hookstate/core";
import { PolicyOptionInterface } from "../interface";
import SelectRepository from "../../../../../repositories/SelectRepository";
import { AxiosResponse } from "axios";
import { useIsMounted } from "../../../../../@VodeaUI";

const Policy: React.FC<any> = () => {
  const {
    watch,
    control,
    formState: { errors },
    setValue,
  } = useFormContext();
  const { constant } = useSelector(({ constant }: any) => {
    return {
      constant: constant.constant.payload,
    };
  });
  const isMounted = useIsMounted();
  const timeOffPolicyOptions = useState<any[]>([]);

  const loadTimeOffPolicyOptions = async () => {
    await SelectRepository.timeOffPolicy()
      .then(({ data }: AxiosResponse) => {
        if (isMounted.current) {
          timeOffPolicyOptions.set(data.data);
          const isDefaultData: any[] = [];
          data.data.map((item: any) => {
            if (item.is_default) {
              isDefaultData.push(item);
            }
          });
          setValue("time_off_policy", isDefaultData);
        }
      })
      .catch(() => {});
  };

  useEffect(() => {
    loadTimeOffPolicyOptions();
  }, []);

  const option = useState<PolicyOptionInterface>({
    attendancePolicy: {
      options: [],
      loading: false,
      params: {},
      loadData: async () => {
        if (isMounted.current) {
          option.attendancePolicy.loading.set(true);
        }

        await SelectRepository.attendancePolicy({
          ...mapParams($cloneState(option.attendancePolicy.params)),
        })
          .then(({ data }: AxiosResponse) => {
            if (isMounted.current) {
              option.attendancePolicy.options.set(data.data);
              option.attendancePolicy.loading.set(false);
            }
          })
          .catch(() => {});
      },
      onOpen: () => {
        if (isMounted.current) {
          option.attendancePolicy.options.set([]);
          option.attendancePolicy.loadData.get()();
        }
      },
    },
    reimbursementPolicy: {
      options: [],
      loading: false,
      params: {},
      loadData: async () => {
        if (isMounted.current) {
          option.reimbursementPolicy.loading.set(true);
        }

        await SelectRepository.reimbursementPolicy({
          ...mapParams($cloneState(option.reimbursementPolicy.params)),
        })
          .then(({ data }: AxiosResponse) => {
            if (isMounted.current) {
              option.reimbursementPolicy.options.set(data.data);
              option.reimbursementPolicy.loading.set(false);
            }
          })
          .catch(() => {});
      },
      onOpen: () => {
        if (isMounted.current) {
          option.reimbursementPolicy.options.set([]);
          option.reimbursementPolicy.loadData.get()();
        }
      },
    },
    cashAdvancePolicy: {
      options: [],
      loading: false,
      params: {},
      loadData: async () => {
        if (isMounted.current) {
          option.cashAdvancePolicy.loading.set(true);
        }

        await SelectRepository.cashAdvancePolicy({
          ...mapParams($cloneState(option.cashAdvancePolicy.params)),
        })
          .then(({ data }: AxiosResponse) => {
            if (isMounted.current) {
              option.cashAdvancePolicy.options.set(data.data);
              option.cashAdvancePolicy.loading.set(false);
            }
          })
          .catch(() => {});
      },
      onOpen: () => {
        if (isMounted.current) {
          option.cashAdvancePolicy.options.set([]);
          option.cashAdvancePolicy.loadData.get()();
        }
      },
    },
  });

  return (
    <>
      <Box mt={3}>
        <VuiCardContent title="Policy Configuration">
          <Box p={2}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Controller
                  render={({ value, name, onChange }) => (
                    <Autocomplete
                      value={value}
                      multiple
                      options={$clone(timeOffPolicyOptions.get())}
                      getOptionLabel={(option: any) => optionLabel(option)}
                      getOptionSelected={(option: any, value: any) =>
                        optionSelected(option, value)
                      }
                      onChange={(event: any, newValue: any) => {
                        onChange(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name={name}
                          label="Time Off Policy"
                          variant="outlined"
                          error={_.has(errors, `time_off_policy`)}
                          helperText={_.get(errors, `time_off_policy.message`)}
                        />
                      )}
                    />
                  )}
                  name={`time_off_policy`}
                  control={control}
                  defaultValue={[]}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  render={({ value, name, onChange }) => (
                    <Autocomplete
                      value={value}
                      loading={option.attendancePolicy.loading.get()}
                      options={$clone(option.attendancePolicy.options.get())}
                      onOpen={option.attendancePolicy.onOpen.get()}
                      getOptionLabel={(option: any) => optionLabel(option)}
                      getOptionSelected={(option: any, value: any) =>
                        optionSelected(option, value)
                      }
                      onChange={(event: any, newValue: any) => {
                        onChange(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name={name}
                          label="Attendance Policy"
                          variant="outlined"
                          error={_.has(errors, `attendance_policy_id`)}
                          helperText={_.get(
                            errors,
                            `attendance_policy_id.message`
                          )}
                        />
                      )}
                    />
                  )}
                  name={`attendance_policy_id`}
                  control={control}
                  defaultValue={null}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  render={({ value, name, onChange }) => (
                    <Autocomplete
                      value={value}
                      multiple
                      loading={option.reimbursementPolicy.loading.get()}
                      options={$clone(option.reimbursementPolicy.options.get())}
                      onOpen={option.reimbursementPolicy.onOpen.get()}
                      getOptionLabel={(option: any) => optionLabel(option)}
                      getOptionSelected={(option: any, value: any) =>
                        optionSelected(option, value)
                      }
                      onChange={(event: any, newValue: any) => {
                        onChange(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name={name}
                          label="Reimbursement Policy"
                          variant="outlined"
                          error={_.has(errors, `reimbursement_policy`)}
                          helperText={_.get(
                            errors,
                            `reimbursement_policy.message`
                          )}
                        />
                      )}
                    />
                  )}
                  name={`reimbursement_policy`}
                  control={control}
                  defaultValue={[]}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  render={({ value, name, onChange }) => (
                    <Autocomplete
                      value={value}
                      multiple
                      loading={option.cashAdvancePolicy.loading.get()}
                      options={$clone(option.cashAdvancePolicy.options.get())}
                      onOpen={option.cashAdvancePolicy.onOpen.get()}
                      getOptionLabel={(option: any) => optionLabel(option)}
                      getOptionSelected={(option: any, value: any) =>
                        optionSelected(option, value)
                      }
                      onChange={(event: any, newValue: any) => {
                        onChange(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name={name}
                          label="Advance Policy"
                          variant="outlined"
                          error={_.has(errors, `advance_policy`)}
                          helperText={_.get(errors, `advance_policy.message`)}
                        />
                      )}
                    />
                  )}
                  name={`advance_policy`}
                  control={control}
                  defaultValue={[]}
                />
              </Grid>
            </Grid>
          </Box>
        </VuiCardContent>
      </Box>
    </>
  );
};

export default Policy;
