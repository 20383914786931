import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import _ from "lodash";
import React, { useEffect } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import VuiCardContent from "../../../@VodeaUI/components/VuiCardContent";
import Status from "../../../components/Status";
import { useIsMounted } from "../../../utilities/helpers/hooks";
import DeclineForm from "./DeclineForm";
import { makeStyles } from "@material-ui/core/styles";
import { InfoOutlined } from "@material-ui/icons";
import { useState } from "@hookstate/core/dist";
import {
  calcBusinessDays,
  totalCurrencyFormat,
} from "../../../utilities/helpers/global";
import TitleForm from "../../../components/molecules/TitleForm";
import { useStyles as useTableStyles } from "../../../@VodeaUI/VuiDataTable/Style";
import TimeOffRepository from "../../../repositories/TimeOffRepository";
import moment from "moment";
import { useSelector } from "react-redux";
import { useGlobalStyles } from "../../../utilities/styles";
import clsx from "clsx";
import { useSnackbar } from "notistack";
import AttendanceRequestRepository from "../../../repositories/AttendanceRequestRepository";
import ReimbursementRepository from "../../../repositories/ReimbursementRepository";
import CashAdvanceRepository from "../../../repositories/CashAdvanceRepository";
import SettlementRepository from "../../../repositories/SettlementRepository";
import VuiNumberFormat from "../../../@VodeaUI/components/Input/VuiNumberFormat";
import CashAdvanceModal from "./CashAdvanceModal";
import HistoryIcon from "@material-ui/icons/History";

const type = [
  "Time Off Request",
  "Attendance Request",
  "Reimbursement Request",
  "Cash Advance Request",
  "Settlement Request",
];

const useStyles = makeStyles({
  title: {
    color: "#707070",
  },
  detailTitle: {
    fontWeight: 600,
    marginTop: 8,
  },
  informationBox: {
    boxShadow: "0px 2px 8px #0000001a",
    position: "absolute",
    right: 50,
    padding: 12,
    zIndex: 10,
    backgroundColor: "white",
    borderRadius: 8,
    marginTop: 4,
  },
  noteText: {
    maxWidth: 150,
    overflowWrap: "break-word",
  },
});

const ApprovalDetail: React.FC<any> = () => {
  const navigate = useNavigate();
  const isMounted = useIsMounted();
  const { id } = useParams();
  const classes = useStyles();
  const tableClasses = useTableStyles();
  const globalClasses = useGlobalStyles();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const requestType = useState<number>(_.get(state, "type", 0));
  const [openApprovalInformation, setOpenApprovalInformation] =
    React.useState<boolean>(false);
  const [indexApprovalInformation, setIndexApprovalInformation] =
    React.useState<number>(0);
  const data = useState<any>({});
  const [showDeclineForm, setShowDeclineForm] = React.useState<boolean>(false);
  const [showCashAdvanceModal, setShowCashAdvanceModal] =
    React.useState<boolean>(false);
  const [canApproved, setCanApproved] = React.useState<boolean>(false);
  const { user } = useSelector(({ vodea }: any) => {
    return {
      user: vodea.auth.user,
    };
  });

  const [defaultDataCashAdvance, setDataCashAdvance] = React.useState<any>([]);

  const loadData = async () => {
    let params: any = {};
    Object.assign(params, {
      with: ["approvalLogs.role", "approvalLogs.approver"],
    });
    if (requestType.get() == 0) {
      await TimeOffRepository.show(id, params)
        .then((response: any) => {
          const responseData = response.data.data;
          if (isMounted.current) {
            data.set(responseData);
            responseData.approvals.map((item: any) => {
              if (item.role_id === user.role_user.role_id) {
                if (item.status === "WAITING") {
                  setCanApproved(true);
                }
              }
            });
          }
        })
        .catch((error: any) => {
          enqueueSnackbar(error.message, {
            variant: "error",
          });
        });
    }
    if (requestType.get() == 1) {
      await AttendanceRequestRepository.show(id, {
        ...params,
        with: ["user", "attendancePolicy"],
      })
        .then((response: any) => {
          const responseData = response.data.data;
          if (isMounted.current) {
            data.set(responseData);
            responseData.approvals.map((item: any) => {
              if (item.role_id === user.role_user.role_id) {
                if (item.status === "WAITING") {
                  setCanApproved(true);
                }
              }
            });
          }
        })
        .catch((error: any) => {
          enqueueSnackbar(error.message, {
            variant: "error",
          });
        });
    }
    if (requestType.get() == 2) {
      await ReimbursementRepository.show(
        {
          ...params,
          with: ["reimbursementRequestItems.attachment"],
        },
        id
      )
        .then((response: any) => {
          const responseData = response.data.data;
          if (isMounted.current) {
            data.set(responseData);
            responseData.approvals.map((item: any) => {
              if (item.role_id === user.role_user.role_id) {
                if (item.status === "WAITING") {
                  setCanApproved(true);
                }
              }
            });
          }
        })
        .catch((error: any) => {
          enqueueSnackbar(error.message, {
            variant: "error",
          });
        });
    }
    if (requestType.get() == 3) {
      await CashAdvanceRepository.show(
        {
          ...params,
          with: [
            "cashAdvanceRequestItems.asset.cashAdvanceRequestItems.cashAdvanceRequest.user",
            "cashAdvanceRequestItems.attachment",
          ],
        },
        id
      )
        .then((response: any) => {
          const responseData = response.data.data;
          if (isMounted.current) {
            data.set(responseData);
            responseData.approvals.map((item: any) => {
              if (item.role_id === user.role_user.role_id) {
                if (item.status === "WAITING") {
                  setCanApproved(true);
                }
              }
            });
          }
        })
        .catch((error: any) => {
          enqueueSnackbar(error.message, {
            variant: "error",
          });
        });
    }
    if (requestType.get() == 4) {
      await SettlementRepository.show(
        {
          with: [
            "approvalLogs.role",
            "approvalLogs.approver",
            "settlementItems.cashAdvanceRequestItem.cashAdvanceFormulaPolicy",
            "settlementItems.attachment",
            "cashAdvanceRequest",
            "user",
          ],
        },
        id
      )
        .then((response: any) => {
          const responseData = response.data.data;
          if (isMounted.current) {
            data.set(responseData);
            responseData.approvals.map((item: any) => {
              if (item.role_id === user.role_user.role_id) {
                if (item.status === "WAITING") {
                  setCanApproved(true);
                }
              }
            });
          }
        })
        .catch((error: any) => {
          enqueueSnackbar(error.message, {
            variant: "error",
          });
        });
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const handleSaveData = () => {
    if (requestType.get() == 0) {
      TimeOffRepository.approve(id)
        .then((resp: any) => {
          enqueueSnackbar(resp.data.message, {
            variant: "success",
          });
          navigate("/apps/approval");
        })
        .catch((err: any) => {
          enqueueSnackbar(err.message, {
            variant: "error",
          });
        });
    }
    if (requestType.get() == 1) {
      AttendanceRequestRepository.approve(id)
        .then((resp: any) => {
          enqueueSnackbar(resp.data.message, {
            variant: "success",
          });
          navigate("/apps/approval");
        })
        .catch((err: any) => {
          enqueueSnackbar(err.message, {
            variant: "error",
          });
        });
    }
    if (requestType.get() == 2) {
      ReimbursementRepository.approve(id)
        .then((resp: any) => {
          enqueueSnackbar(resp.data.message, {
            variant: "success",
          });
          navigate("/apps/approval");
        })
        .catch((err: any) => {
          enqueueSnackbar(err.message, {
            variant: "error",
          });
        });
    }
    if (requestType.get() == 3) {
      CashAdvanceRepository.approve(id)
        .then((resp: any) => {
          enqueueSnackbar(resp.data.message, {
            variant: "success",
          });
          navigate("/apps/approval");
        })
        .catch((err: any) => {
          enqueueSnackbar(err.message, {
            variant: "error",
          });
        });
    }
    if (requestType.get() == 4) {
      SettlementRepository.approve(id)
        .then((resp: any) => {
          enqueueSnackbar(resp.data.message, {
            variant: "success",
          });
          navigate("/apps/approval");
        })
        .catch((err: any) => {
          enqueueSnackbar(err.message, {
            variant: "error",
          });
        });
    }
  };

  const openInformation = (index: number) => {
    setOpenApprovalInformation(!openApprovalInformation);
    setIndexApprovalInformation(index);
    if (index != indexApprovalInformation) {
      setOpenApprovalInformation(true);
      setIndexApprovalInformation(index);
    }
  };

  const renderTimeOffInformation = () => {
    return (
      <VuiCardContent
        title="Information"
        subtitle={_.get(data.get(), "number", "")}
        hasSubtitle={true}
      >
        <Box p={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box mb={2}>
                <Typography variant="caption" className={classes.title}>
                  Request Date
                </Typography>
                <Typography>
                  {_.get(data.get(), "created_at", null)
                    ? moment(_.get(data.get(), "created_at")).format(
                        "DD MMMM YYYY, HH:mm"
                      )
                    : "-"}
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography variant="caption" className={classes.title}>
                  Employee
                </Typography>
                <Typography>
                  {_.get(data.get(), "creator.name", "-")}
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography variant="caption" className={classes.title}>
                  Phone Number
                </Typography>
                <Typography>{_.get(data.get(), "contact", "-")}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box mb={2}>
                <Typography variant="caption" className={classes.title}>
                  Time Off Category
                </Typography>
                <Typography>
                  {_.get(data.get(), "time_off_policy.name", "-")}
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography variant="caption" className={classes.title}>
                  Request For
                </Typography>
                <Typography>
                  {calcBusinessDays(
                    _.get(data.get(), "start"),
                    _.get(data.get(), "finish")
                  )}{" "}
                  Day (s)
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography variant="caption" className={classes.title}>
                  From
                </Typography>
                <Typography>
                  {moment(_.get(data.get(), "start")).format("DD MMMM YYYY")}
                  {_.get(data.get(), "is_half_day_start")
                    ? " - Half Day"
                    : " - Full Day"}
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography variant="caption" className={classes.title}>
                  To
                </Typography>
                <Typography>
                  {moment(_.get(data.get(), "finish")).format("DD MMMM YYYY")}
                  {_.get(data.get(), "is_half_day_finish")
                    ? " - Half Day"
                    : " - Full Day"}
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography variant="caption" className={classes.title}>
                  Reason
                </Typography>
                <Typography>{_.get(data.get(), "description", "-")}</Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </VuiCardContent>
    );
  };

  const renderAttendanceInformation = () => {
    return (
      <VuiCardContent
        title="Information"
        subtitle={_.get(data.get(), "number")}
        hasSubtitle={true}
      >
        <Box p={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box mb={2}>
                <Typography variant="caption" className={classes.title}>
                  Request Date
                </Typography>
                <Typography>
                  {_.get(data.get(), "created_at", null)
                    ? moment(_.get(data.get(), "created_at")).format(
                        "DD MMMM YYYY, HH:mm"
                      )
                    : "-"}
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography variant="caption" className={classes.title}>
                  Employee
                </Typography>
                <Typography>{_.get(data.get(), "user.name", "-")}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box mb={2}>
                <Typography variant="caption" className={classes.title}>
                  Attendance Category
                </Typography>
                <Typography>
                  {_.get(data.get(), "attendance_policy.name", "-")}
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography variant="caption" className={classes.title}>
                  Date
                </Typography>
                <Typography>
                  {_.get(data.get(), "date", null)
                    ? moment(_.get(data.get(), "date")).format(
                        "dddd, DD MMMM YYYY"
                      )
                    : "-"}
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography variant="caption" className={classes.title}>
                  Clock In Time
                </Typography>
                <Typography>
                  {_.get(data.get(), "clock_in", null)
                    ? moment(
                        "1991-11-11 " + _.get(data.get(), "clock_in")
                      ).format("HH:mm")
                    : "-"}
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography variant="caption" className={classes.title}>
                  Clock Out Time
                </Typography>
                <Typography>
                  {_.get(data.get(), "clock_out", null)
                    ? moment(
                        "1991-11-11 " + _.get(data.get(), "clock_out")
                      ).format("HH:mm")
                    : "-"}
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography variant="caption" className={classes.title}>
                  Reason
                </Typography>
                <Typography>{_.get(data.get(), "description", "-")}</Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </VuiCardContent>
    );
  };

  const renderReimbursementInformation = () => {
    return (
      <VuiCardContent
        title="Information"
        subtitle={_.get(data.get(), "number")}
        hasSubtitle={true}
      >
        <Box p={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box mb={2}>
                <Typography variant="caption" className={classes.title}>
                  Request Date
                </Typography>
                <Typography>
                  {_.get(data.get(), "created_at", null)
                    ? moment(_.get(data.get(), "created_at")).format(
                        "DD MMMM YYYY, HH:mm"
                      )
                    : "-"}
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography variant="caption" className={classes.title}>
                  Employee
                </Typography>
                <Typography>
                  {_.get(data.get(), "creator.name", "-")}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box mb={2}>
                <Typography variant="caption" className={classes.title}>
                  Reimbursement Category
                </Typography>
                <Typography>
                  {_.get(data.get(), "reimbursement_policy.name", "-")}
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography variant="caption" className={classes.title}>
                  Used Date
                </Typography>
                <Typography>
                  {_.get(data.get(), "date", null)
                    ? moment(_.get(data.get(), "date")).format(
                        "dddd, DD MMMM YYYY"
                      )
                    : "-"}
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography variant="caption" className={classes.title}>
                  Purpose
                </Typography>
                <Typography>{_.get(data.get(), "description", "-")}</Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </VuiCardContent>
    );
  };

  const renderCashAdvanceInformation = () => {
    return (
      <VuiCardContent
        title="Information"
        subtitle={_.get(data.get(), "number")}
        hasSubtitle={true}
      >
        <Box p={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box mb={2}>
                <Typography variant="caption" className={classes.title}>
                  Request Date
                </Typography>
                <Typography>
                  {_.get(data.get(), "created_at", null)
                    ? moment(_.get(data.get(), "created_at")).format(
                        "DD MMMM YYYY, HH:mm"
                      )
                    : "-"}
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography variant="caption" className={classes.title}>
                  Employee
                </Typography>
                <Typography>
                  {_.get(data.get(), "creator.name", "-")}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box mb={2}>
                <Typography variant="caption" className={classes.title}>
                  Advance Category
                </Typography>
                <Typography>
                  {_.get(data.get(), "cash_advance_policy.name", "-")}
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography variant="caption" className={classes.title}>
                  Used Date
                </Typography>
                <Typography>
                  {_.get(data.get(), "date", null)
                    ? moment(_.get(data.get(), "date")).format(
                        "dddd, DD MMMM YYYY"
                      )
                    : "-"}
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography variant="caption" className={classes.title}>
                  Purpose
                </Typography>
                <Typography>{_.get(data.get(), "description", "-")}</Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </VuiCardContent>
    );
  };

  const renderSettlementInformation = () => {
    return (
      <VuiCardContent
        title="Information"
        subtitle={_.get(data.get(), "number")}
        hasSubtitle={true}
      >
        <Box p={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box mb={2}>
                <Typography variant="caption" className={classes.title}>
                  Request Date
                </Typography>
                <Typography>
                  {" "}
                  {_.get(data.get(), "created_at", null)
                    ? moment(_.get(data.get(), "created_at")).format(
                        "DD MMMM YYYY, HH:mm"
                      )
                    : "-"}
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography variant="caption" className={classes.title}>
                  Employee
                </Typography>
                <Typography>{_.get(data.get(), "user.name", "-")}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box mb={2}>
                <Typography variant="caption" className={classes.title}>
                  Advance Category
                </Typography>
                <Typography>
                  {_.get(data.get(), "cash_advance_request.number", "-")}
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography variant="caption" className={classes.title}>
                  Used Date
                </Typography>
                <Typography>
                  {_.get(data.get(), "date", null)
                    ? moment(_.get(data.get(), "date")).format(
                        "dddd, DD MMMM YYYY"
                      )
                    : "-"}
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography variant="caption" className={classes.title}>
                  Purpose
                </Typography>
                <Typography>{_.get(data.get(), "description", "-")}</Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </VuiCardContent>
    );
  };

  const renderApprovalStatus = () => {
    return (
      <VuiCardContent title="Approval Status">
        <Table aria-label="simple table">
          <TableHead>
            <TableRow hover>
              <TableCell className={tableClasses.tableHead}>Name</TableCell>
              <TableCell className={tableClasses.tableHead}>Status</TableCell>
              <TableCell className={tableClasses.tableHead} />
            </TableRow>
          </TableHead>
          <TableBody>
            {_.get(data.get(), "approvals", []).map(
              (item: any, index: number) => {
                return (
                  <TableRow hover key={index} className={tableClasses.tableRow}>
                    <TableCell className={tableClasses.tableRow}>
                      {item.status === "WAITING"
                        ? item.role_name
                        : item.approver_name}
                    </TableCell>
                    <TableCell className={tableClasses.tableRow}>
                      <Status data={item.status} />
                    </TableCell>
                    <TableCell className={tableClasses.tableRow}>
                      {item.status !== "WAITING" && (
                        <>
                          <IconButton
                            className={tableClasses.icon}
                            onClick={() => openInformation(index)}
                          >
                            <InfoOutlined />
                          </IconButton>
                          {openApprovalInformation &&
                            indexApprovalInformation == index && (
                              <Box className={classes.informationBox}>
                                <Typography
                                  variant="caption"
                                  className={classes.title}
                                >
                                  Action Date
                                </Typography>
                                <Typography variant="subtitle2">
                                  {item.timestamp
                                    ? moment(item.timestamp).format(
                                        "DD MMMM YYYY, HH:mm"
                                      )
                                    : "-"}
                                </Typography>
                                {_.get(data.get(), "remarks", null) &&
                                  item.status === "DECLINED" && (
                                    <Box mt={1}>
                                      <Typography
                                        variant="caption"
                                        className={classes.title}
                                      >
                                        Notes
                                      </Typography>
                                      <Typography
                                        variant="subtitle2"
                                        className={classes.noteText}
                                      >
                                        {_.get(data.get(), "remarks", "-")}
                                      </Typography>
                                    </Box>
                                  )}
                              </Box>
                            )}
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                );
              }
            )}
          </TableBody>
        </Table>
      </VuiCardContent>
    );
  };

  const renderReimbursementDetail = () => {
    return (
      <Box mt={3}>
        <VuiCardContent title="Detail">
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className={tableClasses.tableHead}>
                  Category
                </TableCell>
                <TableCell className={tableClasses.tableHead}>
                  Description
                </TableCell>
                <TableCell className={tableClasses.tableHead}>
                  Amount (IDR)
                </TableCell>
                <TableCell className={tableClasses.tableHead}>File</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(requestType.get() == 2
                ? _.get(data.get(), "reimbursement_request_items", [])
                : requestType.get() == 3
                ? _.get(data.get(), "cash_advance_request_items", [])
                : requestType.get() == 4
                ? _.get(data.get(), "settlement_items", [])
                : []
              ).map((item: any, index: number) => (
                <TableRow key={index} hover className={tableClasses.tableRow}>
                  <TableCell component="th" scope="row">
                    {item.reimbursement_formula_policy.name}
                  </TableCell>
                  <TableCell>{item.description}</TableCell>
                  <TableCell>
                    <VuiNumberFormat data={item.amount} value={item.amount} />
                  </TableCell>
                  <TableCell>
                    {item?.attachment?.url ? (
                      <a href={_.get(item, "attachment.url")} target="_blank">
                        <Button
                          variant="contained"
                          color="primary"
                          className={clsx(
                            globalClasses.buttonPrimary,
                            globalClasses.buttonLg,
                            globalClasses.actionText
                          )}
                        >
                          View
                        </Button>
                      </a>
                    ) : (
                      "No Files"
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {requestType.get() == 4 && (
            <Box p={2}>
              <Box display="flex" justifyContent="flex-end">
                <Box marginRight={10}>
                  <Typography variant="body2">Total Advance</Typography>
                </Box>
                <strong>
                  <VuiNumberFormat
                    data={_.get(data.get(), "cash_advance_request.amount", 0)}
                    value={_.get(data.get(), "cash_advance_request.amount", 0)}
                  />
                </strong>
              </Box>
            </Box>
          )}
          <Box p={2}>
            <Box display="flex" justifyContent="flex-end">
              <Box marginRight={10}>
                <Typography variant="body2">
                  Total{" "}
                  {requestType.get() == 2
                    ? "Reimbursement"
                    : requestType.get() == 3
                    ? "Advance"
                    : requestType.get() == 4
                    ? "Settlement"
                    : ""}
                </Typography>
              </Box>
              <strong>
                <VuiNumberFormat
                  data={totalCurrencyFormat(
                    requestType.get() == 2
                      ? _.get(data.get(), "reimbursement_request_items", [])
                      : requestType.get() == 3
                      ? _.get(data.get(), "cash_advance_request_items", [])
                      : requestType.get() == 4
                      ? _.get(data.get(), "settlement_items", [])
                      : [],
                    "amount"
                  )}
                  value={totalCurrencyFormat(
                    requestType.get() == 2
                      ? _.get(data.get(), "reimbursement_request_items", [])
                      : requestType.get() == 3
                      ? _.get(data.get(), "cash_advance_request_items", [])
                      : requestType.get() == 4
                      ? _.get(data.get(), "settlement_items", [])
                      : [],
                    "amount"
                  )}
                />
              </strong>
            </Box>
          </Box>
          {requestType.get() == 4 && (
            <Box p={2}>
              <Box display="flex" justifyContent="flex-end">
                <Box marginRight={10}>
                  <Typography variant="body2">Variance</Typography>
                </Box>
                <strong>
                  <VuiNumberFormat
                    data={
                      _.get(data.get(), "cash_advance_request.amount", 0) -
                      _.get(data.get(), "amount", 0)
                    }
                    value={
                      _.get(data.get(), "cash_advance_request.amount", 0) -
                      _.get(data.get(), "amount", 0)
                    }
                  />
                </strong>
              </Box>
            </Box>
          )}
        </VuiCardContent>
      </Box>
    );
  };

  const renderCashAdvanceDetail = () => {
    return (
      <Box mt={3}>
        <VuiCardContent title="Detail">
          <Table aria-label="simple table">
            <TableHead>
              <TableRow hover>
                <TableCell className={tableClasses.tableHead}>
                  Category
                </TableCell>
                {_.get(data.get(), "cash_advance_request_items", []).every(
                  (item: any) => item.asset_id
                ).length > 0 && (
                  <TableCell className={tableClasses.tableHead}>
                    Asset
                  </TableCell>
                )}
                <TableCell className={tableClasses.tableHead}>
                  Description
                </TableCell>
                <TableCell className={tableClasses.tableHead}>File</TableCell>
                <TableCell className={tableClasses.tableHead}>
                  Amount (IDR)
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {_.get(data.get(), "cash_advance_request_items", []).map(
                (item: any, index: number) => (
                  <TableRow key={index} hover className={tableClasses.tableRow}>
                    <TableCell component="th" scope="row">
                      {_.get(item, "cash_advance_formula_policy.name", "-")}
                    </TableCell>
                    {_.get(item, "asset_id", null) && (
                      <TableCell component="th" scope="row">
                        <Grid
                          container
                          spacing={1}
                          justifyContent="center"
                          alignItems="center"
                        >
                          {_.get(item, "asset.name") === undefined ||
                          _.get(item, "asset.name") === null ||
                          _.get(item, "asset.name") === "" ? (
                            <>
                              <Grid item xs={12}>
                                {_.get(item, "asset.name", "-")}
                              </Grid>
                            </>
                          ) : (
                            <>
                              <Grid item xs={10}>
                                {_.get(item, "asset.name", "-")}
                              </Grid>
                              <Grid item xs={2}>
                                <IconButton
                                  className={tableClasses.icon}
                                  onClick={() =>
                                    handleOpenModalCashAdvance(
                                      _.get(item, "asset", [])
                                    )
                                  }
                                >
                                  <HistoryIcon />
                                </IconButton>
                              </Grid>
                            </>
                          )}
                        </Grid>
                      </TableCell>
                    )}
                    <TableCell>{_.get(item, "description", "-")}</TableCell>
                    <TableCell>
                      {item.attachment ? (
                        <a href={_.get(item, "attachment.url")} target="_blank">
                          <Button
                            variant="contained"
                            color="primary"
                            className={clsx(
                              globalClasses.buttonPrimary,
                              globalClasses.buttonLg,
                              globalClasses.actionText
                            )}
                          >
                            View
                          </Button>
                        </a>
                      ) : (
                        "No Files"
                      )}
                    </TableCell>
                    <TableCell>
                      {_.get(item, "amount", null) ? (
                        <VuiNumberFormat
                          data={_.get(item, "amount")}
                          value={_.get(item, "amount")}
                        />
                      ) : (
                        "-"
                      )}
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
          <Box p={2}>
            <Box display="flex" justifyContent="flex-end">
              <Box marginRight={10}>
                <Typography variant="body2">Total Advance</Typography>
              </Box>
              <strong>
                <VuiNumberFormat
                  data={totalCurrencyFormat(
                    _.get(data.get(), "cash_advance_request_items", []),
                    "amount"
                  )}
                  value={totalCurrencyFormat(
                    _.get(data.get(), "cash_advance_request_items", []),
                    "amount"
                  )}
                />
              </strong>
            </Box>
          </Box>
        </VuiCardContent>
      </Box>
    );
  };

  const renderSettlementDetail = () => {
    return (
      <Box mt={3}>
        <VuiCardContent title="Detail">
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className={tableClasses.tableHead}>
                  Category
                </TableCell>
                <TableCell className={tableClasses.tableHead}>
                  Description
                </TableCell>
                <TableCell className={tableClasses.tableHead}>
                  Amount (IDR)
                </TableCell>
                <TableCell className={tableClasses.tableHead}>File</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {_.get(data.get(), "settlement_items", []).map(
                (item: any, index: number) => (
                  <TableRow key={index} hover className={tableClasses.tableRow}>
                    <TableCell component="th" scope="row">
                      {_.get(
                        item,
                        "cash_advance_request_item.cash_advance_formula_policy.name",
                        "-"
                      )}
                    </TableCell>
                    <TableCell>
                      {_.get(
                        item,
                        "cash_advance_request_item.description",
                        "-"
                      )}
                    </TableCell>
                    <TableCell>
                      {_.get(item, "amount", null) ? (
                        <VuiNumberFormat
                          data={_.get(item, "amount")}
                          value={_.get(item, "amount")}
                        />
                      ) : (
                        "-"
                      )}
                    </TableCell>
                    <TableCell>
                      {item.attachment ? (
                        <a href={_.get(item, "attachment.url")} target="_blank">
                          <Button
                            variant="contained"
                            color="primary"
                            className={clsx(
                              globalClasses.buttonPrimary,
                              globalClasses.buttonLg,
                              globalClasses.actionText
                            )}
                          >
                            View
                          </Button>
                        </a>
                      ) : (
                        "No Files"
                      )}
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
          <Box p={2}>
            <Grid container>
              <Grid item md={7} />
              <Grid item md={5}>
                <Box
                  justifyContent={"flex-end"}
                  alignItems={"center"}
                  display={"flex"}
                >
                  <Box textAlign={"right"} marginRight={10}>
                    <Typography variant="body2" className={classes.detailTitle}>
                      Total Advance
                    </Typography>
                    <Typography variant="body2" className={classes.detailTitle}>
                      Total Settlement
                    </Typography>
                    <Typography variant="body2" className={classes.detailTitle}>
                      Variance
                    </Typography>
                  </Box>
                  <Box textAlign={"right"}>
                    <VuiNumberFormat
                      className={classes.detailTitle}
                      data={_.get(data.get(), "cash_advance_request.amount", 0)}
                      value={_.get(
                        data.get(),
                        "cash_advance_request.amount",
                        0
                      )}
                    />
                    <VuiNumberFormat
                      className={classes.detailTitle}
                      data={_.get(data.get(), "amount", 0)}
                      value={_.get(data.get(), "amount", 0)}
                    />
                    <VuiNumberFormat
                      className={classes.detailTitle}
                      data={
                        _.get(data.get(), "cash_advance_request.amount", 0) -
                        _.get(data.get(), "amount", 0)
                      }
                      value={
                        _.get(data.get(), "cash_advance_request.amount", 0) -
                        _.get(data.get(), "amount", 0)
                      }
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </VuiCardContent>
      </Box>
    );
  };

  const handleCloseModal = () => setShowDeclineForm(false);
  const handleOpenModal = () => setShowDeclineForm(true);

  const handleCloseModalCashAdvance = () => setShowCashAdvanceModal(false);
  const handleOpenDrawerCashAdvance = () => setShowCashAdvanceModal(true);
  const handleOpenModalCashAdvance = (data: any) => {
    setDataCashAdvance(data);
    setShowCashAdvanceModal(true);
  };

  return (
    <>
      <TitleForm title={type[requestType.get()]} withBackUrl={true} />

      <Box mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            {requestType.get() == 0
              ? renderTimeOffInformation()
              : requestType.get() == 1
              ? renderAttendanceInformation()
              : requestType.get() == 2
              ? renderReimbursementInformation()
              : requestType.get() == 3
              ? renderCashAdvanceInformation()
              : renderSettlementInformation()}
            {requestType.get() == 2 && renderReimbursementDetail()}
            {requestType.get() == 3 && renderCashAdvanceDetail()}
            {requestType.get() == 4 && renderSettlementDetail()}
          </Grid>
          <Grid item xs={12} md={4}>
            {renderApprovalStatus()}
          </Grid>
        </Grid>

        {canApproved && (
          <Box marginTop={5}>
            <Paper>
              <Box
                p={2}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="subtitle2">
                  Approve This Request?
                </Typography>
                <Box display="flex" alignItems="center">
                  <Box marginRight={2}>
                    <Button
                      variant="text"
                      className={globalClasses.actionText}
                      onClick={handleOpenModal}
                    >
                      Decline
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSaveData}
                      className={clsx(
                        globalClasses.buttonPrimary,
                        globalClasses.buttonLg,
                        globalClasses.actionText
                      )}
                    >
                      Approve
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Paper>
          </Box>
        )}

        <DeclineForm
          open={showDeclineForm}
          handleClose={handleCloseModal}
          submitText={"Decline"}
          url={"/apps/approval"}
          decline={
            requestType.get() == 0
              ? "time-off-request"
              : requestType.get() == 1
              ? "attendance-request"
              : requestType.get() == 2
              ? "reimbursement-request"
              : requestType.get() == 3
              ? "cash-advance-request"
              : "settlement"
          }
          id={id}
        />

        <CashAdvanceModal
          open={showCashAdvanceModal}
          handleOpen={handleOpenDrawerCashAdvance}
          handleClose={handleCloseModalCashAdvance}
          data={defaultDataCashAdvance}
        />
      </Box>
    </>
  );
};

export default ApprovalDetail;
