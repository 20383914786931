import React, { useEffect } from "react";
import TitleForm from "../../../../../../components/molecules/TitleForm";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import VuiCardContent from "../../../../../../@VodeaUI/components/VuiCardContent";
import { makeStyles } from "@material-ui/core/styles";
import { $clone } from "../../../../../../utilities/helpers/global";
import { useState } from "@hookstate/core";
import { defaultDataTableOption } from "../../../../../../utilities/Option";
import AttendanceRepository from "../../../../../../repositories/AttendanceRepository";
import { useLocation, useParams } from "react-router-dom";
import _ from "lodash";
import { useIsMounted } from "../../../../../../utilities/helpers/hooks";
import moment from "moment";
import { useGlobalStyles } from "../../../../../../utilities/styles";
import { useNavigate } from "react-router";

const useStyles = makeStyles({
  title: {
    fontWeight: 700,
    color: "#383838",
    fontSize: "0.9rem",
    textTransform: "uppercase",
  },
  titleInformation: {
    fontWeight: 400,
    color: "#383838",
    fontSize: "0.8rem",
  },
  titleValue: {
    fontWeight: 500,
    color: "#4B4D4C",
    fontSize: "0.8rem",
  },
  divider: {
    borderBottom: `1px solid rgba(224, 224, 224, 1)`,
    "&:last-child": {
      borderBottom: 0,
    },
  },
});

const AttendanceDetail = () => {
  const data = useState<any[]>([]);
  const { date } = useParams();
  const isMounted = useIsMounted();
  const globalClasses = useGlobalStyles();
  const navigate = useNavigate();
  const { state } = useLocation();
  const classes = useStyles();
  const informationData = useState({
    date: null,
    name: "-",
  });

  const loadData = async () => {
    await AttendanceRepository.showAttendance({
      date: date,
      // user: _.get(state, "userId", 0),
      with: ["user"],
    }).then((resp: any) => {
      const responseData = resp.data.data;
      if (isMounted.current) {
        console.log(responseData);
        data.set(responseData);
        informationData.date.set(responseData[0].timestamp);
        informationData.name.set(responseData[0].user.name);
      }
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <TitleForm title={"Attendance"} withBackUrl={true} />
      <Grid container spacing={3}>
        <Grid item md={4} xs={12}>
          <VuiCardContent title="Information">
            <Box p={2}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={12}>
                  <Typography variant="h6" className={classes.titleInformation}>
                    Date
                  </Typography>
                  <Typography variant="h6" className={classes.titleValue}>
                    {informationData.date.get()
                      ? moment(informationData.date.get()).format(
                          "DD MMMM YYYY"
                        )
                      : "-"}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Typography variant="h6" className={classes.titleInformation}>
                    Employee
                  </Typography>
                  <Typography variant="h6" className={classes.titleValue}>
                    {informationData.name.get()}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </VuiCardContent>
        </Grid>

        <Grid item md={8} xs={12}>
          <VuiCardContent title="Detail">
            <Box
              p={2}
              style={{ borderBottom: "1px solid rgba(224, 224, 224, 1)" }}
            >
              <Grid container spacing={1}>
                <Grid item xs={2}>
                  <Typography variant="subtitle2" className={classes.title}>
                    Time
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="subtitle2" className={classes.title}>
                    Type
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle2" className={classes.title}>
                    Using
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="subtitle2" className={classes.title}>
                    Location
                  </Typography>
                </Grid>
                <Grid item xs={2} />
              </Grid>
            </Box>
            {data.get().map((item: any) => {
              return (
                <Box p={2} className={classes.divider}>
                  <Grid container spacing={1}>
                    <Grid item xs={2}>
                      <Typography variant="subtitle2">
                        {moment(item.timestamp).format("HH:mm")}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="subtitle2">{item.type}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography variant="subtitle2">
                        {item.attendance_from}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography variant="subtitle2">
                        {item.address}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      {item?.attendance_request_id ? (
                        <Button
                          variant="contained"
                          className={globalClasses.buttonPrimary}
                          color="primary"
                          onClick={() =>
                            navigate(`${item.attendance_request_id}`, {
                              state: {
                                type: 1,
                              },
                            })
                          }
                        >
                          View
                        </Button>
                      ) : null}
                    </Grid>
                  </Grid>
                </Box>
              );
            })}
          </VuiCardContent>
        </Grid>
      </Grid>
    </>
  );
};

export default AttendanceDetail;
