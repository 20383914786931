import { string, object, date } from "yup";
import { TFunction } from "react-i18next";

export const validationSchema = (t: TFunction) => {
  let shapes = {
    name: string().required().label(t("form.name.label")),
    date_from: date().label(t("form.startDate.label")),
    date_to: date().label(t("form.endDate.label")),
    date: date().label(t("form.date.label")),
  };

  return object().shape(shapes);
};
