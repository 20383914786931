import { api, createCancelTokenHandler } from "../@VodeaUI";

const endPoint = () => window._env_.REACT_APP_PASSPORT_END_POINT;

const Repository = {
  show: function (params: any = null) {
    return api.get(`${endPoint()}/api/setting/company`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.show.name].handleRequestCancellation()
          .token,
    });
  },
  update: function (payload: any, params: any = null) {
    return api.put(`${endPoint()}/api/setting/COMPANY`, payload, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.update.name].handleRequestCancellation()
          .token,
    });
  },
};

const cancelTokenHandlerObject = createCancelTokenHandler(Repository);

export default Repository;
