import React, { FC, useCallback, useEffect, useState } from "react";
import { Box, BoxProps } from "@material-ui/core";
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";
import _ from "lodash";
import { useIsMounted } from "../../../utilities/helpers/hooks";

interface IMuiStatusLabelStyled extends BoxProps {}
interface IMuiStatusLabel {
  status: string;
}

const MuiStatusLabelStyled = withStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: "5px 12px",
      borderRadius: 5,
      fontSize: 12,
      textTransform: "capitalize",
      display: "inline-block",
      "&.Completed, &.Approved": {
        backgroundColor: "rgba(158, 179, 153, 0.2)",
        color: "#9EB399",
      },
      "&.Pending, &.Waiting": {
        backgroundColor: "rgba(255, 157, 0, 0.2)",
        color: "#FF9D00",
      },
      "&.Rejected, &.Declined": {
        backgroundColor: "rgba(255, 0, 0, 0.2)",
        color: "#ff0000",
      },
    },
  })
)((props: IMuiStatusLabelStyled) => <Box {...props} />);

const MuiStatusLabel: FC<IMuiStatusLabel> = ({ status }) => {
  const isMounted = useIsMounted();
  const [stats, setStats] = useState<string>("");
  const upperFirst = useCallback((item: string) => {
    if (isMounted.current) {
      setStats(_.upperFirst(_.lowerCase(item).replace(/_/g, " ")));
    }
  }, []);

  useEffect(() => {
    upperFirst(status);
  }, [status]);

  return <MuiStatusLabelStyled className={stats}>{stats}</MuiStatusLabelStyled>;
};

export default React.memo(MuiStatusLabel);
