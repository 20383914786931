import React, { useEffect } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import VuiCardContent from "../../../../../@VodeaUI/components/VuiCardContent";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import TitleForm from "../../../../../components/molecules/TitleForm";
import FooterFormAction from "../../../../../components/FooterFormAction";
import { useState } from "@hookstate/core";
import { useParams } from "react-router-dom";
import {
  $cloneState,
  mapParams,
} from "../../../../../utilities/helpers/global";
import { OptionInterface } from "./interface";
import TimeOffPolicyRepository from "../../../../../repositories/TimeOffPolicyRepository";
import { AxiosError, AxiosResponse } from "axios";
import { useIsMounted } from "../../../../../utilities/helpers/hooks";
import moment from "moment";
import AttendancePolicyRepository from "../../../../../repositories/AttendancePolicyRepository";
import _ from "lodash";
import { useSnackbar } from "notistack";
import { PopUpAssignPolicy } from "../../../../../components/molecules";
import Loading from "../../../../../components/Loading";
import {
  ErrorText,
  MuiAutoComplete,
  MuiButton,
  MuiDatePicker,
  MuiTextField,
  MuiTimePicker,
  NumberFieldFormat,
} from "../../../../../components/atoms";
import { validationSchema } from "./validation";
import { yupResolver } from "@hookform/resolvers/yup";
import ApprovalSetting from "../../../../../components/Policy/ApprovalSetting";
import { dayOptions, noneOptions } from "../../../../../constants";
import { ConstantOptionInterface } from "../Reimbursement/interface";
import { constantToOptions } from "../../../../../utilities/helpers/option.helper";
import { useSelector } from "react-redux";
import { handleAxiosErrorSave } from "../../../../../utilities/helpers/axios-error.helper";

const approvalTypePolicyOptions = [
  {
    id: 1,
    name: "Direct Supervisor",
    key: "SUPERVISOR",
  },
  {
    id: 2,
    name: "Certain Position",
    key: "CERTAIN_POSITION",
  },
];

const AttendancePolicyForm: React.FC<any> = () => {
  const { id } = useParams();
  const isMounted = useIsMounted();
  const title = id ? "Edit Attendance Policy" : "Add Attendance Policy";
  const { enqueueSnackbar } = useSnackbar();
  const [onFetchData, setOnFetchData] = React.useState<boolean>(false);
  const [showModalSucceed, setShowModalSucceed] =
    React.useState<boolean>(false);
  const handleCloseModalSucceed = () => setShowModalSucceed(false);
  const { constant } = useSelector(({ constant }: any) => {
    return {
      constant: constant.constant.payload,
    };
  });
  const approvalRequirementTypeOptions = useState<ConstantOptionInterface[]>(
    constantToOptions(constant, "APPROVAL_REQUIREMENT_TYPE_OPTIONS", true)
  );

  const { errors, handleSubmit, control, watch, setValue, setError } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema()),
    defaultValues: {
      name: "",
      code: "",
      description: "",
      valid_from: moment().add(1, "day").format("YYYY-MM-DD"),
      is_flexible: false,
      is_default: false,
      tolerance: false,
      tolerance_value: 0,
      approval_requirement_type: noneOptions[0],
      approval_policies: [],
      attendance_break_policies: [],
      attendance_work_hour_policies: [],
    },
  });

  const {
    fields: breakFields,
    append: breakAppend,
    remove: breakRemove,
  } = useFieldArray({
    control,
    name: "attendance_break_policies",
  });

  const {
    fields: workHourFields,
    append: workHourAppend,
    remove: workHourRemove,
  } = useFieldArray({
    control,
    name: "attendance_work_hour_policies",
  });

  const option = useState<OptionInterface>({
    role: {
      options: [],
      loading: false,
      params: {},
      loadData: async () => {
        if (isMounted.current) {
          option.role.loading.set(true);
        }
        await TimeOffPolicyRepository.allRole(
          mapParams($cloneState(option.role.params))
        )
          .then(({ data }: AxiosResponse) => {
            if (isMounted.current) {
              option.role.options.set(data.data);
              option.role.loading.set(false);
            }
          })
          .catch(() => {});
      },
      onOpen: () => {
        if (isMounted.current) {
          option.role.options.set([]);
          option.role.loadData.get()();
        }
      },
    },
  });

  const watchApprovalType = watch("approval_requirement_type");
  const watchApprovalPolicies = watch("approval_policies");
  const watchTolerance = watch("tolerance");
  const watchFlexible = watch("is_flexible");

  const loading = useState<boolean>(false);
  const onSubmit = (formData: any) => {
    if (isMounted.current) loading.set(true);
    let body: any = {};

    body = {
      ...formData,
      is_flexible: Number(formData?.is_flexible),
      is_default: Number(formData?.is_default),
      tolerance: Number(formData?.tolerance),
      approval_requirement_type: formData?.approval_requirement_type?.id,
    };

    if (
      formData?.attendance_break_policies &&
      formData?.attendance_break_policies.length > 0
    ) {
      body = {
        ...body,
        attendance_break_policies: formData?.attendance_break_policies.map(
          (policy: any) => {
            return {
              day: policy?.day?.id,
              time_from: moment(policy?.time_from).format("HH:mm:ss"),
              time_to: moment(policy?.time_to).format("HH:mm:ss"),
            };
          }
        ),
      };
    }

    if (
      formData?.attendance_work_hour_policies &&
      formData?.attendance_work_hour_policies.length > 0
    ) {
      body = {
        ...body,
        attendance_work_hour_policies:
          formData?.attendance_work_hour_policies.map((policy: any) => {
            return {
              day: policy?.day?.id,
              time_from: moment(policy?.time_from).format("HH:mm:ss"),
              time_to: moment(policy?.time_to).format("HH:mm:ss"),
            };
          }),
      };
    }

    if (formData?.approval_policies && formData?.approval_policies.length > 0) {
      body = {
        ...body,
        approval_policies: formData?.approval_policies.map((approval: any) => {
          if (approval?.type?.key === "SUPERVISOR") {
            return {
              level: approval?.level,
              type: approval?.type?.id,
            };
          } else {
            return {
              level: approval?.level,
              type: approval?.type?.id,
              role_id: approval?.role_id?.id,
            };
          }
        }),
      };
    }

    if (formData?.tolerance && !formData?.is_flexible) {
      body = {
        ...body,
        tolerance_value: formData?.tolerance_value,
      };
    }

    if (body?.approval_requirement_type === "NONE") {
      delete body?.approval_requirement_type;
    }

    if (id) {
      delete body?.valid_from;
    }

    (id
      ? AttendancePolicyRepository.update(id, body)
      : AttendancePolicyRepository.create(body)
    )
      .then((response: AxiosResponse) => {
        if (isMounted.current) {
          enqueueSnackbar(response.data.message, {
            variant: "success",
          });
          loading.set(false);
          setShowModalSucceed(true);
        }
      })
      .catch((error: AxiosError) => {
        if (isMounted.current && error?.response?.data?.errors) {
          handleAxiosErrorSave(error, setError, enqueueSnackbar);
          loading.set(false);
        }
      });
  };

  const loadData = async () => {
    if (!id) {
      return;
    }

    if (isMounted.current) {
      setOnFetchData(true);
    }

    AttendancePolicyRepository.show(id, {
      with: ["approvalPolicies.role"],
    })
      .then((response: any) => {
        if (isMounted.current) {
          setOnFetchData(false);
          const data = response.data.data;
          _.forEach(data, (value, name) => {
            setValue(name, value);
            if (name === "attendance_break_policies") {
              setValue(
                name,
                value.map((val: any) => {
                  return {
                    day: dayOptions.find((day: any) => day.id === val.day),
                    time_from: new Date(`1980-01-01T${val.time_from}`),
                    time_to: new Date(`1980-01-01T${val.time_to}`),
                  };
                })
              );
            }

            if (name === "attendance_work_hour_policies") {
              setValue(
                name,
                value.map((val: any) => {
                  return {
                    day: dayOptions.find((day: any) => day.id === val.day),
                    time_from: new Date(`1980-01-01T${val.time_from}`),
                    time_to: new Date(`1980-01-01T${val.time_to}`),
                  };
                })
              );
            }

            if (name === "approval_requirement_type") {
              setValue(
                name,
                approvalRequirementTypeOptions.find(
                  (approval: any) => approval.id === value
                )
              );
            }

            if (name === "approval_policies") {
              setValue(
                name,
                value.map((val: any) => {
                  if (val.type === "SUPERVISOR") {
                    return {
                      level: val.level,
                      type: approvalTypePolicyOptions.find(
                        (policy) => policy.key === val.type
                      ),
                    };
                  } else {
                    return {
                      level: val.level,
                      type: approvalTypePolicyOptions.find(
                        (policy) => policy.key === val.type
                      ),
                      role_id: val.role,
                    };
                  }
                })
              );
            }
          });
        }
      })
      .catch((error: any) => {
        if (isMounted.current) {
          setOnFetchData(false);
        }
      });
  };

  useEffect(() => {
    loadData();
  }, [id]);

  const addWorkHour = () => {
    workHourAppend({
      day: null,
      time_from: new Date(),
      time_to: new Date(),
    });
  };

  const addBreak = () => {
    breakAppend({
      day: null,
      time_from: new Date(),
      time_to: new Date(),
    });
  };

  return (
    <>
      <TitleForm title={title} withBackUrl={true} />

      <PopUpAssignPolicy
        open={showModalSucceed}
        onClose={handleCloseModalSucceed}
        url={"/apps/setting/policy/attendance"}
      />

      {onFetchData ? <Loading /> : null}
      <Grid
        container
        spacing={3}
        style={onFetchData ? { display: "none" } : {}}
      >
        <Grid item xs={12} md={4}>
          <VuiCardContent title="Information">
            <Box p={2}>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <Controller
                    render={({ value, name, onChange }) => {
                      return (
                        <MuiTextField
                          name={name}
                          onChange={onChange}
                          label="Attendance Policy Name"
                          value={value}
                          error={_.has(errors, "name")}
                          helperText={_.get(errors, "name.message")}
                        />
                      );
                    }}
                    name={"name"}
                    control={control}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <Controller
                    render={({ value, name, onChange }) => {
                      return (
                        <MuiTextField
                          name={name}
                          onChange={onChange}
                          label="Policy Code"
                          value={value}
                          error={_.has(errors, "code")}
                          helperText={_.get(errors, "code.message")}
                        />
                      );
                    }}
                    name={"code"}
                    control={control}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <Controller
                    render={({ value, name, onChange }) => (
                      <MuiDatePicker
                        disabled={!!id}
                        value={value}
                        name={name}
                        label={"Effective On"}
                        format="DD MMMM YYYY"
                        onChange={onChange}
                        error={_.has(errors, "valid_from")}
                        helperText={_.get(errors, "valid_from.message")}
                      />
                    )}
                    name="valid_from"
                    control={control}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <Controller
                    render={({ value, name, onChange }) => {
                      return (
                        <MuiTextField
                          name={name}
                          onChange={onChange}
                          label="Policy Description"
                          value={value}
                          error={_.has(errors, "description")}
                          helperText={_.get(errors, "description.message")}
                        />
                      );
                    }}
                    name={"description"}
                    control={control}
                  />
                </Grid>
              </Grid>
            </Box>
          </VuiCardContent>
        </Grid>

        <Grid item xs={12} md={8}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <VuiCardContent title="Policy Configuration">
                <Box p={2}>
                  <Grid container spacing={3}>
                    <Grid item md={12} xs={12}>
                      <Grid container>
                        <Grid item md={12} xs={12}>
                          <Controller
                            name={"is_default"}
                            control={control}
                            render={({ value, name, onChange }) => {
                              return (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      onChange={(e) =>
                                        onChange(e.target.checked)
                                      }
                                      checked={value}
                                      name={name}
                                      color="primary"
                                    />
                                  }
                                  label="Default attendance for new employee"
                                />
                              );
                            }}
                          />
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <Controller
                            name={"is_flexible"}
                            control={control}
                            render={({ value, name, onChange }) => {
                              return (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      onChange={(e) =>
                                        onChange(e.target.checked)
                                      }
                                      checked={value}
                                      name={name}
                                      color="primary"
                                    />
                                  }
                                  label="Flexible"
                                />
                              );
                            }}
                          />
                        </Grid>

                        {!watchFlexible && (
                          <Grid item md={12} xs={12}>
                            <Controller
                              name={"tolerance"}
                              control={control}
                              render={({ value, name, onChange }) => {
                                return (
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        onChange={(e) =>
                                          onChange(e.target.checked)
                                        }
                                        checked={value}
                                        name={name}
                                        color="primary"
                                      />
                                    }
                                    label="Tolerance"
                                  />
                                );
                              }}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </Grid>

                    {watchTolerance ? (
                      <Grid item md={12} xs={12}>
                        <Controller
                          render={({ value, name, onChange }) => {
                            return (
                              <MuiTextField
                                name={name}
                                onChange={onChange}
                                label="Minute"
                                value={value}
                                error={_.has(errors, name)}
                                helperText={_.get(errors, `${name}.message`)}
                                InputProps={{
                                  inputComponent: NumberFieldFormat,
                                }}
                              />
                            );
                          }}
                          name={"tolerance_value"}
                          control={control}
                        />
                      </Grid>
                    ) : null}
                  </Grid>
                </Box>
              </VuiCardContent>
            </Grid>

            <Grid item xs={12} md={12}>
              <VuiCardContent title="Work Time">
                <Box p={2}>
                  <Grid container spacing={3}>
                    <Grid item md={12} xs={12}>
                      <Grid container spacing={2} alignItems="center">
                        {workHourFields.length > 0 && (
                          <>
                            <Grid item md={3} xs={12}>
                              <Typography variant="h6">Day</Typography>
                            </Grid>
                            <Grid item md={4} xs={12}>
                              <Typography variant="h6">Clock In</Typography>
                            </Grid>
                            <Grid item md={4} xs={12}>
                              <Typography variant="h6">Clock Out</Typography>
                            </Grid>

                            <Grid item md={12} sm={12}>
                              {workHourFields.map(
                                (field: any, index: number) => {
                                  return (
                                    <Box key={field.id}>
                                      <Grid
                                        container
                                        spacing={2}
                                        alignItems={"center"}
                                      >
                                        <Grid item md={3} xs={12}>
                                          <Controller
                                            render={({ value, onChange }) => (
                                              <MuiAutoComplete
                                                isAsync={false}
                                                constantOptions={dayOptions}
                                                value={value}
                                                onSelected={(newValue) =>
                                                  onChange(newValue)
                                                }
                                                muiTextField={{
                                                  error: _.has(
                                                    errors,
                                                    `attendance_work_hour_policies[${index}].day`
                                                  ),
                                                  helperText: _.get(
                                                    errors,
                                                    `attendance_work_hour_policies[${index}].day.message`
                                                  ),
                                                }}
                                              />
                                            )}
                                            name={`attendance_work_hour_policies[${index}].day`}
                                            control={control}
                                            defaultValue={field.day}
                                          />
                                        </Grid>
                                        <Grid item md={4} xs={12}>
                                          <Controller
                                            render={({
                                              value,
                                              name,
                                              onChange,
                                            }) => {
                                              return (
                                                <MuiTimePicker
                                                  name={name}
                                                  value={value}
                                                  onChange={onChange}
                                                  error={_.has(
                                                    errors,
                                                    `attendance_work_hour_policies[${index}].time_from`
                                                  )}
                                                  helperText={_.get(
                                                    errors,
                                                    `attendance_work_hour_policies[${index}].time_from.message`
                                                  )}
                                                />
                                              );
                                            }}
                                            name={`attendance_work_hour_policies[${index}].time_from`}
                                            control={control}
                                            defaultValue={field.time_from}
                                          />
                                        </Grid>
                                        <Grid item md={4} xs={12}>
                                          <Controller
                                            render={({
                                              value,
                                              name,
                                              onChange,
                                            }) => {
                                              return (
                                                <MuiTimePicker
                                                  name={name}
                                                  value={value}
                                                  onChange={onChange}
                                                  error={_.has(
                                                    errors,
                                                    `attendance_work_hour_policies[${index}].time_to`
                                                  )}
                                                  helperText={_.get(
                                                    errors,
                                                    `attendance_work_hour_policies[${index}].time_to.message`
                                                  )}
                                                />
                                              );
                                            }}
                                            name={`attendance_work_hour_policies[${index}].time_to`}
                                            control={control}
                                            defaultValue={field.time_to}
                                          />
                                        </Grid>
                                        <Grid item md={1} xs={2}>
                                          <IconButton
                                            onClick={() =>
                                              workHourRemove(index)
                                            }
                                          >
                                            <DeleteIcon />
                                          </IconButton>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                  );
                                }
                              )}
                            </Grid>
                          </>
                        )}

                        <ErrorText
                          errors={errors}
                          field={workHourFields}
                          fieldName="attendance_work_hour_policies"
                        />

                        <Grid item md={12} xs={12}>
                          <MuiButton label="Add More" onClick={addWorkHour} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </VuiCardContent>
            </Grid>

            <Grid item xs={12} md={12}>
              <VuiCardContent title="Break Time">
                <Box p={2}>
                  <Grid container spacing={3}>
                    <Grid item md={12} xs={12}>
                      <Grid container spacing={2} alignItems="center">
                        {breakFields.length > 0 && (
                          <>
                            <Grid item md={3} xs={12}>
                              <Typography variant="h6">Day</Typography>
                            </Grid>
                            <Grid item md={4} xs={12}>
                              <Typography variant="h6">Break In</Typography>
                            </Grid>
                            <Grid item md={4} xs={12}>
                              <Typography variant="h6">Break Out</Typography>
                            </Grid>

                            <Grid item md={12} sm={12}>
                              {breakFields.map((field: any, index: number) => {
                                return (
                                  <Box key={field.id}>
                                    <Grid
                                      container
                                      spacing={2}
                                      alignItems={"center"}
                                    >
                                      <Grid item md={3} xs={12}>
                                        <Controller
                                          render={({ value, onChange }) => (
                                            <MuiAutoComplete
                                              isAsync={false}
                                              constantOptions={dayOptions}
                                              value={value}
                                              onSelected={(newValue) =>
                                                onChange(newValue)
                                              }
                                              muiTextField={{
                                                error: _.has(
                                                  errors,
                                                  `attendance_break_policies[${index}].day`
                                                ),
                                                helperText: _.get(
                                                  errors,
                                                  `attendance_break_policies[${index}].day.message`
                                                ),
                                              }}
                                            />
                                          )}
                                          name={`attendance_break_policies[${index}].day`}
                                          control={control}
                                          defaultValue={field.day}
                                        />
                                      </Grid>
                                      <Grid item md={4} xs={12}>
                                        <Controller
                                          render={({
                                            value,
                                            name,
                                            onChange,
                                          }) => {
                                            return (
                                              <MuiTimePicker
                                                name={name}
                                                value={value}
                                                onChange={onChange}
                                                error={_.has(
                                                  errors,
                                                  `attendance_break_policies[${index}].time_from`
                                                )}
                                                helperText={_.get(
                                                  errors,
                                                  `attendance_break_policies[${index}].time_from.message`
                                                )}
                                              />
                                            );
                                          }}
                                          name={`attendance_break_policies[${index}].time_from`}
                                          control={control}
                                          defaultValue={field.time_from}
                                        />
                                      </Grid>
                                      <Grid item md={4} xs={12}>
                                        <Controller
                                          render={({
                                            value,
                                            name,
                                            onChange,
                                          }) => {
                                            return (
                                              <MuiTimePicker
                                                name={name}
                                                value={value}
                                                onChange={onChange}
                                                error={_.has(
                                                  errors,
                                                  `attendance_break_policies[${index}].time_to`
                                                )}
                                                helperText={_.get(
                                                  errors,
                                                  `attendance_break_policies[${index}].time_to.message`
                                                )}
                                              />
                                            );
                                          }}
                                          name={`attendance_break_policies[${index}].time_to`}
                                          control={control}
                                          defaultValue={field.time_to}
                                        />
                                      </Grid>
                                      <Grid item md={1} xs={2}>
                                        <IconButton
                                          onClick={() => breakRemove(index)}
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      </Grid>
                                    </Grid>
                                  </Box>
                                );
                              })}
                            </Grid>
                          </>
                        )}

                        <ErrorText
                          errors={errors}
                          field={breakFields}
                          fieldName="attendance_break_policies"
                        />

                        <Grid item md={12} xs={12}>
                          <MuiButton label="Add More" onClick={addBreak} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </VuiCardContent>
            </Grid>

            <Grid item xs={12} md={12}>
              <VuiCardContent title="Approval Setting">
                <Box p={2}>
                  <ApprovalSetting
                    control={control}
                    policies={watchApprovalPolicies}
                    type={watchApprovalType}
                    typeName="approval_requirement_type"
                    fieldName="approval_policies"
                    label="Approval Attendance"
                    errors={errors}
                  />
                </Box>
              </VuiCardContent>
            </Grid>
          </Grid>
        </Grid>

        <FooterFormAction
          cancelUrl={"/apps/setting/policy/attendance"}
          loading={loading.get()}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
        />
      </Grid>
    </>
  );
};

export default AttendancePolicyForm;
