import LayoutDefault from "../layouts/Default";
import { Navigate } from "react-router-dom";
import NotFound from "../pages/NotFound";
import LayoutAuth from "../layouts/Auth";
import Login from "../pages/Auth/Login";
import Register from "../pages/Auth/Register";
import AuthForgotPassword from "../pages/Auth/Password/Reset";
import AuthResetPassword from "../pages/Auth/Password/Email";
import React from "react";

const routes = [
  {
    path: "/",
    element: <LayoutDefault />,
    children: [
      { path: "/", element: <Navigate to={"/auth/login"} /> },
      { path: "*", element: <NotFound />, title: "Ups, don't cry." },
    ],
  },
  {
    path: "auth",
    element: <LayoutAuth />,
    children: [
      { path: "/", element: <Navigate to={"login"} /> },
      { path: "login", element: <Login />, title: "Login" },
      { path: "register", element: <Register />, title: "Register" },
    ],
  },
  {
    path: "auth/password",
    // element: <LayoutAuth disableRedirect={true} />,
    element: <LayoutAuth />,
    children: [
      { path: "/", element: <Navigate to={"reset"} /> },
      {
        path: "reset",
        element: <AuthForgotPassword />,
        title: "Reset Password",
      },
      {
        path: "email",
        element: <AuthResetPassword />,
        title: "Reset Password",
      },
    ],
  },
];

export default routes;
