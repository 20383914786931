import React, { useEffect } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import { useState } from "@hookstate/core/dist";
import { useNavigate, useParams } from "react-router";
import People from "../../../../../../layouts/Apps/People";
import VuiCardContent from "../../../../../../@VodeaUI/components/VuiCardContent";
import PinForm from "./PinForm";
import EmploymentDataRepository from "../../../../../../repositories/EmploymentDataRepository";
import moment from "moment";
import { useGlobalStyles } from "../../../../../../utilities/styles";
import clsx from "clsx";
import _ from "lodash";
import { Controller } from "react-hook-form";
import { useIsMounted } from "../../../../../../utilities/helpers/hooks";
import { useStyles } from "./Style";
import VuiNumberFormat from "../../../../../../@VodeaUI/components/Input/VuiNumberFormat";
import CloseIcon from "@material-ui/icons/Close";
import PinInput from "react-pin-input";
import { useSnackbar } from "notistack";
import { handleAxiosError } from "../../../../../../utilities/helpers/global";

const EmploymentDataDetail: React.FC<any> = () => {
  const loading = useState<boolean>(false);
  const isMounted = useIsMounted();
  const classes = useStyles();
  const [showPinForm, setShowPinForm] = React.useState(false);
  const data = useState({
    valid_from: "",
    department: "",
    role: "",
    salary: "",
    status: "",
  });
  const { id } = useParams();
  const [showPayroll, setShowPayroll] = React.useState<boolean>(false);
  const [pin, setPin] = React.useState("");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const globalClasses = useGlobalStyles();

  useEffect(() => {
    if (id) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmitPayroll = () => {
    pin == ""
      ? enqueueSnackbar("PIN Required", {
          variant: "error",
        })
      : loadData(true);
  };

  const loadData = async (withPin: boolean = false) => {
    if (withPin) {
      loading.set(true);
      await EmploymentDataRepository.show(id, {
        with: [
          "user.roleUser.role.department",
          "user.employmentStatusUser.employmentStatus",
        ],
        pin: pin,
      })
        .then((resp: any) => {
          const responseData = resp.data.data;
          let temp: any = {};
          temp = {
            valid_from: _.get(responseData, "valid_from"),
            department: _.get(
              responseData,
              "user.role_user.role.department.name"
            ),
            role: _.get(responseData, "user.role_user.role.name"),
            salary: _.get(responseData, "salary"),
            status: _.get(
              responseData,
              "user.employment_status_user.employment_status.name",
              "-"
            ),
          };
          if (isMounted.current) {
            data.set(temp);
            setShowPinForm(false);
            setShowPayroll(true);
            loading.set(false);
          }
        })
        .catch((err: any) => {
          if (isMounted.current) {
            loading.set(false);
            handleAxiosError(err, isMounted.current, enqueueSnackbar);
          }
        });
    } else {
      await EmploymentDataRepository.show(id, {
        with: [
          "user.roleUser.role.department",
          "user.employmentStatusUser.employmentStatus",
        ],
      })
        .then((resp: any) => {
          const responseData = resp.data.data;
          let temp: any = {};
          temp = {
            valid_from: _.get(responseData, "valid_from"),
            department: _.get(
              responseData,
              "user.role_user.role.department.name"
            ),
            role: _.get(responseData, "user.role_user.role.name"),
            salary: _.get(responseData, "salary"),
            status: _.get(
              responseData,
              "user.employment_status_user.employment_status.name",
              "-"
            ),
          };
          if (isMounted.current) {
            data.set(temp);
          }
        })
        .catch((err: any) => {});
    }
  };

  return (
    <People>
      <div>
        <Grid item xs={12}>
          <VuiCardContent title="Employment Data">
            <Box p={2}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="caption">Effective Date</Typography>
                  <Typography>
                    {data.valid_from.get()
                      ? moment(data.valid_from.get()).format("DD MMMM YYYY")
                      : "-"}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="caption">Employment Status</Typography>
                  <Typography>{data.status.get() || "-"}</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="caption">Department</Typography>
                  <Typography>{data.department.get() || "-"}</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="caption">Payroll</Typography>
                  <Typography>
                    {showPayroll ? (
                      <VuiNumberFormat
                        data={data.salary.get()}
                        value={data.salary.get()}
                        prefix={"Rp "}
                      />
                    ) : (
                      "Rp *******"
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="caption">Position</Typography>
                  <Typography>{data.role.get() || "-"}</Typography>
                </Grid>
                {!showPayroll && (
                  <Grid item xs={12}>
                    <Button
                      onClick={() => setShowPinForm(true)}
                      variant="contained"
                      color="primary"
                      className={clsx(
                        globalClasses.buttonPrimary,
                        globalClasses.buttonLg
                      )}
                    >
                      Enter PIN
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Box>
          </VuiCardContent>
        </Grid>
      </div>

      <Dialog
        open={showPinForm}
        fullWidth
        maxWidth="xs"
        onClose={() => setShowPinForm(false)}
      >
        <DialogContent>
          <Box mb={2}>
            <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
              <IconButton
                aria-label="close"
                size="small"
                onClick={() => setShowPinForm(false)}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            </Box>

            <Typography variant="h6" className={classes.titleDialog}>
              Payroll PIN
            </Typography>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box>
                <Typography className={classes.subTitleDialog}>
                  You are about to see your payroll detail
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box>
            <PinInput
              length={6}
              initialValue=""
              type="numeric"
              inputMode="number"
              secret
              onChange={(value, index) => setPin(value)}
              inputStyle={{
                border: "1px solid transparent",
                borderBottomColor: "#707070",
                fontSize: "2.5rem",
              }}
              inputFocusStyle={{
                border: "1px solid transparent",
                borderBottomColor: "#707070",
              }}
              onComplete={(value, index) => {
                setPin(value);
              }}
              autoSelect={true}
              regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
            />
            {/*
            <TextField
              label="Payroll PIN"
              variant="outlined"
              type={"password"}
              onChange={(e) => setPin(e.target.value)}
              defaultValue={pin}
              fullWidth
            />
            */}
          </Box>
        </DialogContent>
        <DialogActions className={classes.dialogAction}>
          <Box p={2}>
            <Button
              size="small"
              onClick={() => setShowPinForm(false)}
              className={classes.gapButton}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="small"
              color="primary"
              className={clsx(
                globalClasses.buttonPrimary,
                globalClasses.buttonLg
              )}
              onClick={handleSubmitPayroll}
              disabled={loading.get()}
            >
              {loading.get() ? (
                <CircularProgress color="inherit" size={20} />
              ) : (
                "Submit"
              )}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </People>
  );
};

export default EmploymentDataDetail;
