import { api } from "../@VodeaUI";

let url = window._env_.REACT_APP_PASSPORT_END_POINT;

const AttendanceRepository = {
  all: function (params: any = null) {
    return api.get(`${url}/api/view/attendance`, {
      params,
    });
  },
  account: function (params: any = null) {
    return api.get(`${url}/api/account/view/attendance`, {
      params,
    });
  },
  show: function (params: any = null) {
    return api.get(`${url}/api/view/attendance`, {
      params,
    });
  },
  showAttendance: function (params: any = null) {
    return api.get(`${url}/api/account/attendance`, {
      params,
    });
  },
};

export default AttendanceRepository;
