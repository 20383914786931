import React from "react";
import { Box, Grid, TextField, Typography } from "@material-ui/core";
import { useState } from "@hookstate/core/dist";
import VuiSelect from "../../../../../@VodeaUI/components/Input/VuiSelect/Index";
import { useForm } from "react-hook-form";
import VuiCardContent from "../../../../../@VodeaUI/components/VuiCardContent";
import _ from "lodash";
import FooterFormAction from "../../../../../components/FooterFormAction";

const CreateResource: React.FC<any> = () => {
  const { errors, handleSubmit } = useForm({
    mode: "onChange",
  });

  const loading = useState(false);
  const resourceType = useState("building");
  const buildingType = useState("bandung");
  const dayType = useState("sunday");

  const resourceTypeOptions = [
    {
      key: "building",
      label: "Building",
    },
    {
      key: "other",
      label: "Other",
    },
  ];

  const buildingOptions = [
    {
      key: "bandung",
      label: "Bandung",
    },
    {
      key: "headQuarter",
      label: "Head Quarter",
    },
  ];

  const dayOptions = [
    {
      key: "sunday",
      label: "Sunday",
    },
    {
      key: "monday",
      label: "Monday",
    },
    {
      key: "tuesday",
      label: "Tuesday",
    },
    {
      key: "wednesday",
      label: "Wednesday",
    },
    {
      key: "thursday",
      label: "Thursday",
    },
    {
      key: "friday",
      label: "Friday",
    },
    {
      key: "saturday",
      label: "Saturday",
    },
  ];

  const onSubmit = (formData: any) => {
    loading.set(true);

    console.log("formData", formData);
  };

  return (
    <>
      <Grid container spacing={3} justifyContent="space-between">
        <Grid item xs={12}>
          <Typography variant="h5">Add Resource</Typography>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} md={9}>
          <VuiCardContent title="Add Resource">
            <Box p={2}>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <VuiSelect
                    label="Resource Type"
                    state={resourceType}
                    options={resourceTypeOptions}
                    fullWidth={true}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    name="building_id"
                    label="Resource Code"
                    variant="outlined"
                    fullWidth
                    error={_.has(errors, "building_id")}
                    helperText={_.get(errors, "building_id.message")}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <VuiSelect
                    label="Building Name"
                    state={buildingType}
                    options={buildingOptions}
                    fullWidth={true}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    name="name"
                    label="Resource Name"
                    variant="outlined"
                    fullWidth
                    error={_.has(errors, "name")}
                    helperText={_.get(errors, "name.message")}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    name="description"
                    label="Description"
                    variant="outlined"
                    fullWidth
                    error={_.has(errors, "description")}
                    helperText={_.get(errors, "description.message")}
                  />
                </Grid>
              </Grid>
            </Box>
          </VuiCardContent>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} md={9}>
          <VuiCardContent title="Availability">
            <Box p={2}>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <TextField
                    name="building_id"
                    label="Capacity"
                    variant="outlined"
                    fullWidth
                    error={_.has(errors, "building_id")}
                    helperText={_.get(errors, "building_id.message")}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <VuiSelect
                    label="Building Name"
                    state={buildingType}
                    options={buildingOptions}
                    fullWidth={true}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    id="outlined-number"
                    label="Duration"
                    type="number"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    id="outlined-number"
                    label="Max Request"
                    type="number"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <VuiSelect
                    label="Day"
                    state={dayType}
                    options={dayOptions}
                    fullWidth={true}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <TextField
                    label="From"
                    type="time"
                    defaultValue="07:30"
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 300, // 5 min
                    }}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <TextField
                    label="To"
                    type="time"
                    defaultValue="07:30"
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 300, // 5 min
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </VuiCardContent>
        </Grid>

        <Grid item xs={12} md={9}>
          <FooterFormAction
            cancelUrl={null}
            loading={loading.get()}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default CreateResource;
