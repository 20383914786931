import IconButton from '@material-ui/core/IconButton';
import { Menu as MenuIcon } from '@material-ui/icons';
import { Hidden } from '@material-ui/core';
import React from 'react';
import { useStyles } from '../style';

interface Props {
  logo: string;
  appName: {
    first: string;
    second?: string;
  };
  handleClick(): void;
}

const Logo: React.FC<Props> = ({ logo, appName, handleClick }) => {
  const classes = useStyles();

  return (
    <>
      <IconButton
        edge='start'
        className={classes.menuButton}
        color='inherit'
        aria-label='menu'
        onClick={handleClick}
        classes={{ root: classes.iconBtn }}
      >
        <MenuIcon />
      </IconButton>

      <Hidden xsDown>
        <div className={classes.brandWrapper}>
          <img className='logo' src={logo} alt={'logo'} />
          {/* <Typography variant='h6'>
            <b>{appName.first}</b>
            {appName.second}
          </Typography> */}
          {/* <span className='brand-name'>{appName}</span> */}
        </div>
      </Hidden>
    </>
  );
};

export default Logo;
