import React, { useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { VuiSearch, VuiTabs } from "../../../../@VodeaUI/components";
import {
  Add as AddIcon,
  BlockOutlined,
  CheckCircleOutlineOutlined,
  EditOutlined as EditOutlinedIcon,
} from "@material-ui/icons";
import { $clone } from "../../../../utilities/helpers/global";
import { useState } from "@hookstate/core/dist";
import VuiDataTable, {
  Column,
  Options,
} from "../../../../@VodeaUI/VuiDataTable/Index";
import { defaultDataTableOption } from "../../../../utilities/Option";
import { useParams } from "react-router";
import { Link } from "react-router-dom";

const tabs = [
  {
    label: "Resource",
    params: {},
  },
  {
    label: "Building",
    params: {},
  },
];

const TabPanel: React.FC<any> = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const Resource: React.FC<any> = () => {
  const columnsResource = useState<Column[]>([
    { key: "name", label: "Code" },
    { key: "from", label: "Name" },
    { key: "to", label: "Capacity" },
    {
      key: "id",
      label: "",
      sortable: false,
      render: (row) => {
        return (
          <Box display="flex" justifyContent={"flex-end"}>
            <IconButton aria-label="edit">
              <EditOutlinedIcon fontSize="small" />
            </IconButton>

            {row.can_suspend ? (
              <Tooltip title="Suspend">
                <IconButton aria-label="suspend">
                  <BlockOutlined fontSize="small" />
                </IconButton>
              </Tooltip>
            ) : null}

            {row.can_unsuspend ? (
              <Tooltip title="Unsuspend">
                <IconButton aria-label="unsuspend">
                  <CheckCircleOutlineOutlined fontSize="small" />
                </IconButton>
              </Tooltip>
            ) : null}
          </Box>
        );
      },
    },
  ]);
  const columnsBuilding = useState<Column[]>([
    { key: "name", label: "Code" },
    { key: "from", label: "Name" },
    { key: "to", label: "Address" },
    {
      key: "id",
      label: "",
      sortable: false,
      render: (row) => {
        return (
          <Box display="flex" justifyContent={"flex-end"}>
            <IconButton aria-label="edit">
              <EditOutlinedIcon fontSize="small" />
            </IconButton>

            {row.can_suspend ? (
              <Tooltip title="Suspend">
                <IconButton aria-label="suspend">
                  <BlockOutlined fontSize="small" />
                </IconButton>
              </Tooltip>
            ) : null}

            {row.can_unsuspend ? (
              <Tooltip title="Unsuspend">
                <IconButton aria-label="unsuspend">
                  <CheckCircleOutlineOutlined fontSize="small" />
                </IconButton>
              </Tooltip>
            ) : null}
          </Box>
        );
      },
    },
  ]);

  const loading = useState(false);
  const options = useState<Options>({
    ...defaultDataTableOption,
    sortedBy: "desc",
    orderBy: "date",
  });
  const data = useState([]);
  const objParams = useParams();
  const tab = useState(0);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = () => {
    loading.set(false);

    if (objParams.id !== undefined) {
    }
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item>
          <VuiTabs
            name="productTabs"
            value={tab.get()}
            tabList={tabs.map((a) => a.label)}
            handleTabsChange={(value: any) => tab.set(value)}
          />
        </Grid>
      </Grid>

      <Box mt={2}>
        <TabPanel value={tab.get()} index={0}>
          <Grid container spacing={3} justifyContent="space-between">
            <Grid item>
              <Typography variant={"h5"}>Resource</Typography>
            </Grid>

            <Grid item>
              <Link to={"/apps/booking/resource/add-resource"}>
                <Button
                  startIcon={<AddIcon />}
                  variant="contained"
                  color="primary"
                >
                  Add Resource
                </Button>
              </Link>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper variant="elevation" elevation={1}>
                <Box p={2}>
                  <Grid
                    container
                    spacing={3}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Grid item>
                      <VuiSearch
                        state={options.search}
                        callback={() => {
                          options.page.set(0);
                          loadData();
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>

                <VuiDataTable
                  loading={loading.get()}
                  columns={columnsResource.value}
                  options={$clone(options.value)}
                  data={data.value}
                  onChangeOptions={(values: Options) => {
                    options.set(values);
                    loadData();
                  }}
                />
              </Paper>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={tab.get()} index={1}>
          <Grid container spacing={3} justifyContent="space-between">
            <Grid item>
              <Typography variant={"h5"}>Building</Typography>
            </Grid>

            <Grid item>
              <Link to={"/apps/booking/resource/add-building"}>
                <Button
                  startIcon={<AddIcon />}
                  variant="contained"
                  color="primary"
                >
                  Add Building
                </Button>
              </Link>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper variant="elevation" elevation={1}>
                <Box p={2}>
                  <Grid
                    container
                    spacing={3}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Grid item>
                      <VuiSearch
                        state={options.search}
                        callback={() => {
                          options.page.set(0);
                          loadData();
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>

                <VuiDataTable
                  loading={loading.get()}
                  columns={columnsBuilding.value}
                  options={$clone(options.value)}
                  data={data.value}
                  onChangeOptions={(values: Options) => {
                    options.set(values);
                    loadData();
                  }}
                />
              </Paper>
            </Grid>
          </Grid>
        </TabPanel>
      </Box>
    </>
  );
};

export default Resource;
