import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";

const badgeStyles = makeStyles({
  count: {
    marginLeft: 10,
    backgroundColor: "#013332",
    padding: "3px 14px",
    borderRadius: 25,
    fontSize: 10,
    color: "white",
    marginBottom: 4,
  },
});

const CustomTabLabel = ({ label, count }: any) => {
  const classes = badgeStyles();
  return (
    <Box display={"flex"} alignItems={"center"}>
      <Typography variant={"body2"}>{label}</Typography>
      {count?.count > 0 && (
        <Typography variant={"body2"} className={classes.count}>
          {count?.count}
        </Typography>
      )}
    </Box>
  );
};

export default CustomTabLabel;
