import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import PreferencesCompany from "../../../../../layouts/Apps/Preference/Company";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
} from "@material-ui/core";
import VuiCardContent from "../../../../../@VodeaUI/components/VuiCardContent";
import _ from "lodash";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { AxiosResponse, AxiosError } from "axios";
import FooterFormAction from "../../../../../components/FooterFormAction";
import CompanyRepository from "../../../../../repositories/CompanyRepository";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

const GeneralInformationCompany: React.FC<any> = () => {
  const { handleSubmit, control, errors, setValue } = useForm({
    mode: "onChange",
  });

  const navigate = useNavigate();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = (data: any) => {
    setLoading(true);
    CompanyRepository.update({
      attributes: {
        ...data,
      },
    })
      .then((resp: AxiosResponse) => {
        enqueueSnackbar(resp.data.message, {
          variant: "success",
        });
        setLoading(false);
      })
      .catch((err: any) => {
        enqueueSnackbar(err?.response?.data.message, {
          variant: "error",
        });
        setLoading(false);
      });
  };

  const getData = () => {
    CompanyRepository.show()
      .then((resp: AxiosResponse) => {
        _.forEach(resp.data.data.attributes, (value, name) => {
          setValue(name, value);
        });
      })
      .catch((err: AxiosError) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <PreferencesCompany>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <VuiCardContent title="General Information">
            <Box p={2}>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <Controller
                    render={({ onChange, name, value }) => {
                      return (
                        <TextField
                          name={name}
                          onChange={onChange}
                          label="Name"
                          variant="outlined"
                          value={value || ""}
                          fullWidth
                          error={_.has(errors, "name")}
                          helperText={_.get(errors, "name.message")}
                        />
                      );
                    }}
                    name="name"
                    control={control}
                    defaultValue={""}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Controller
                    render={({ onChange, name, value }) => {
                      return (
                        <TextField
                          name={name}
                          label="Initial"
                          variant="outlined"
                          value={value || ""}
                          onChange={onChange}
                          fullWidth
                          error={_.has(errors, "initial")}
                          helperText={_.get(errors, "initial.message")}
                        />
                      );
                    }}
                    name="initial"
                    control={control}
                    defaultValue={""}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <Controller
                    render={({ onChange, name, value }) => {
                      return (
                        <TextField
                          name={name}
                          label="Email"
                          variant="outlined"
                          value={value || ""}
                          type="email"
                          onChange={onChange}
                          fullWidth
                          error={_.has(errors, "email")}
                          helperText={_.get(errors, "email.message")}
                        />
                      );
                    }}
                    name="email"
                    control={control}
                    defaultValue={""}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <Controller
                    render={({ onChange, name, value }) => {
                      return (
                        <TextField
                          name={name}
                          label="Telephone"
                          variant="outlined"
                          value={value || ""}
                          onChange={onChange}
                          fullWidth
                          error={_.has(errors, "telephone")}
                          helperText={_.get(errors, "telephone.message")}
                        />
                      );
                    }}
                    name="telephone"
                    control={control}
                    defaultValue={""}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <Controller
                    render={({ onChange, name, value }) => {
                      return (
                        <TextField
                          name={name}
                          label="Fax"
                          variant="outlined"
                          value={value || ""}
                          onChange={onChange}
                          type={"number"}
                          fullWidth
                          error={_.has(errors, "fax")}
                          helperText={_.get(errors, "fax.message")}
                        />
                      );
                    }}
                    name="fax"
                    control={control}
                    defaultValue={""}
                  />
                </Grid>
              </Grid>
            </Box>
          </VuiCardContent>
        </Grid>

        <Grid item xs={12}>
          <VuiCardContent title="Address">
            <Box p={2}>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <Controller
                    render={({ onChange, name, value }) => {
                      return (
                        <TextField
                          name={name}
                          label="Address"
                          variant="outlined"
                          value={value || ""}
                          onChange={onChange}
                          fullWidth
                          error={_.has(errors, "address")}
                          helperText={_.get(errors, "address.message")}
                        />
                      );
                    }}
                    name="address"
                    control={control}
                    defaultValue={0}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <Controller
                    render={({ onChange, name, value }) => {
                      return (
                        <TextField
                          name={name}
                          label="City"
                          variant="outlined"
                          value={value || ""}
                          onChange={onChange}
                          fullWidth
                          error={_.has(errors, "city")}
                          helperText={_.get(errors, "city.message")}
                        />
                      );
                    }}
                    name="city"
                    control={control}
                    defaultValue={""}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <Controller
                    render={({ onChange, name, value }) => {
                      return (
                        <TextField
                          name={name}
                          label="Province"
                          variant="outlined"
                          value={value || ""}
                          onChange={onChange}
                          fullWidth
                          error={_.has(errors, "province")}
                          helperText={_.get(errors, "province.message")}
                        />
                      );
                    }}
                    name="province"
                    control={control}
                    defaultValue={""}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <Controller
                    render={({ onChange, name, value }) => {
                      return (
                        <TextField
                          name={name}
                          label="Postal Code"
                          variant="outlined"
                          value={value || ""}
                          onChange={onChange}
                          fullWidth
                          error={_.has(errors, "postal_code")}
                          helperText={_.get(errors, "postal_code.message")}
                        />
                      );
                    }}
                    name="postal_code"
                    control={control}
                    defaultValue={""}
                  />
                </Grid>
              </Grid>
            </Box>
          </VuiCardContent>
        </Grid>

        <Grid item xs={12}>
          <VuiCardContent title="BPJS Configuration">
            <Box p={2}>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <Controller
                    render={({ onChange, name, value }) => {
                      return (
                        <TextField
                          name={name}
                          label="BPJS Ketenagakerjaan Number"
                          variant="outlined"
                          value={value || ""}
                          onChange={onChange}
                          fullWidth
                          error={_.has(errors, "bpjs_tk_number")}
                          helperText={_.get(errors, "bpjs_tk_number.message")}
                        />
                      );
                    }}
                    name="bpjs_tk_number"
                    control={control}
                    defaultValue={""}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Controller
                    render={({ onChange, name, value }) => {
                      return (
                        <TextField
                          name={name}
                          label="JKK"
                          variant="outlined"
                          value={value || ""}
                          onChange={onChange}
                          fullWidth
                          error={_.has(errors, "jkk")}
                          helperText={_.get(errors, "jkk.message")}
                        />
                      );
                    }}
                    name="jkk"
                    control={control}
                    defaultValue={""}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Grid container spacing={3}>
                    <Grid item md={3} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(event, newValue) =>
                              console.log(newValue)
                            }
                            name="jht"
                            color="primary"
                          />
                        }
                        label="JHT"
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(event, newValue) =>
                              console.log(newValue)
                            }
                            name="jp"
                            color="primary"
                          />
                        }
                        label="JP"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={12} xs={12}>
                  <Controller
                    render={({ onChange, name, value }) => {
                      return (
                        <TextField
                          name={name}
                          label="BPJS Kesehatan Number"
                          variant="outlined"
                          value={value || ""}
                          onChange={onChange}
                          fullWidth
                          error={_.has(errors, "bpjs_kes_number")}
                          helperText={_.get(errors, "bpjs_kes_number.message")}
                        />
                      );
                    }}
                    name="bpjs_kes_number"
                    control={control}
                    defaultValue={""}
                  />
                </Grid>
              </Grid>
            </Box>
          </VuiCardContent>
        </Grid>

        <Grid item xs={12}>
          <VuiCardContent title="Tax Configuration">
            <Box p={2}>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <Controller
                    render={({ onChange, name, value }) => {
                      return (
                        <TextField
                          name={name}
                          label="NPWP"
                          variant="outlined"
                          value={value || ""}
                          onChange={onChange}
                          fullWidth
                          error={_.has(errors, "npwp")}
                          helperText={_.get(errors, "npwp.message")}
                        />
                      );
                    }}
                    name="npwp"
                    control={control}
                    defaultValue={""}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Controller
                    render={({ value, name, onChange }) => (
                      <KeyboardDatePicker
                        name={name}
                        disableToolbar
                        fullWidth
                        variant="inline"
                        minDate={new Date("2020-01-01")}
                        inputVariant="outlined"
                        label="Taxable Date"
                        format="DD/MM/YYYY"
                        error={_.has(errors, "tax_date")}
                        helperText={_.get(errors, "tax_date.message")}
                        value={value}
                        onChange={onChange}
                      />
                    )}
                    name="tax_date"
                    control={control}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Controller
                    render={({ onChange, name, value }) => {
                      return (
                        <TextField
                          name={name}
                          label="Tax Person"
                          variant="outlined"
                          value={value || ""}
                          onChange={onChange}
                          fullWidth
                          error={_.has(errors, "tax_person")}
                          helperText={_.get(errors, "tax_person.message")}
                        />
                      );
                    }}
                    name="tax_person"
                    control={control}
                    defaultValue={""}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Controller
                    render={({ onChange, name, value }) => {
                      return (
                        <TextField
                          name={name}
                          label="Tax Person NPWP"
                          variant="outlined"
                          value={value || ""}
                          onChange={onChange}
                          fullWidth
                          error={_.has(errors, "tax_person_npwp")}
                          helperText={_.get(errors, "tax_person_npwp.message")}
                        />
                      );
                    }}
                    name="tax_person_npwp"
                    control={control}
                    defaultValue={""}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Controller
                    render={({ onChange, name, value }) => {
                      return (
                        <TextField
                          name={name}
                          label="KLU"
                          variant="outlined"
                          value={value || ""}
                          onChange={onChange}
                          fullWidth
                          error={_.has(errors, "klu")}
                          helperText={_.get(errors, "klu.message")}
                        />
                      );
                    }}
                    name="klu"
                    control={control}
                    defaultValue={""}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Controller
                    render={({ onChange, name, value }) => {
                      return (
                        <TextField
                          name={name}
                          label="UMR"
                          variant="outlined"
                          value={value.toLocaleString() || ""}
                          onChange={onChange}
                          fullWidth
                          error={_.has(errors, "umr")}
                          helperText={_.get(errors, "umr.message")}
                        />
                      );
                    }}
                    name="umr"
                    control={control}
                    defaultValue={""}
                  />
                </Grid>
              </Grid>
            </Box>
          </VuiCardContent>
        </Grid>

        <FooterFormAction
          cancelUrl={null}
          loading={loading}
          onSubmit={onSubmit}
          handleSubmit={handleSubmit}
        />
      </Grid>
    </PreferencesCompany>
  );
};

export default GeneralInformationCompany;
