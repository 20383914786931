import React, { useEffect } from "react";
import { useState } from "@hookstate/core/dist";
import {
  Box,
  Button,
  Divider,
  Grid,
  makeStyles,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { useParams } from "react-router";
import { Options } from "../../../../../@VodeaUI/VuiDataTable/Index";
import { defaultDataTableOption } from "../../../../../utilities/Option";
import { useStyles } from "../../../../../components/Dashboard/Task/tabs/style";
import TitleForm from "../../../../../components/molecules/TitleForm";
import VuiCardContent from "../../../../../@VodeaUI/components/VuiCardContent";
import TimeOffRepository from "../../../../../repositories/TimeOffRepository";
import moment from "moment";
import { calcBusinessDays } from "../../../../../utilities/helpers/global";
import Status from "../../../../../components/Status";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import DeclineForm from "../../../Approval/DeclineForm";

interface DataInterface {
  created_at: string;
  creator_name: string;
  time_off_policy_name: string;
  is_half_day_start: boolean;
  is_half_day_finish: boolean;
  start: string;
  finish: string;
  description: string;
  contact: string;
  approvals: Array<object>;
}

const DataBaseModel = {
  created_at: "",
  creator_name: "",
  time_off_policy_name: "",
  is_half_day_start: false,
  is_half_day_finish: false,
  start: "",
  finish: "",
  description: "",
  contact: "",
  approvals: [],
};

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStylesModal = makeStyles((theme: any) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: "center",
  },
}));

const RequestAttendanceDetail: React.FC<any> = () => {
  const classes = useStyles();
  const { id } = useParams();
  const classesModals = useStylesModal();
  const [modalStyle] = React.useState(getModalStyle);
  const options = useState<Options>({
    ...defaultDataTableOption,
    sortedBy: "desc",
    orderBy: "date",
  });
  const { user } = useSelector(({ vodea }: any) => {
    return {
      user: vodea.auth.user,
    };
  });

  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [canApproved, setCanApproved] = React.useState<boolean>(false);
  const data = useState<DataInterface>(DataBaseModel);

  const handleSaveData = (type: string) => {
    console.log(type);
  };

  const handleCloseModal = () => setOpenModal(false);
  const handleOpenModal = () => setOpenModal(true);

  const loadData = async () => {
    TimeOffRepository.show(id, {
      with: ["approvalLogs.approver", "approvalLogs.role"],
    })
      .then((resp: any) => {
        const responseData = resp.data.data;
        let temp: any = {};
        temp = {
          created_at: responseData.created_at,
          creator_name: responseData.creator.name,
          time_off_policy_name: responseData.time_off_policy.name,
          start: responseData.start,
          finish: responseData.finish,
          is_half_day_start: responseData.is_half_day_start,
          is_half_day_finish: responseData.is_half_day_finish,
          description: responseData.description,
          contact: responseData.contact,
          approvals: responseData.approvals,
        };
        data.set(temp);
        responseData.approvals.map((item: any) => {
          if (item.role_id === user.role_user.role_id) {
            if (item.status === "WAITING") {
              setCanApproved(true);
            }
          }
        });
      })
      .catch((err: any) => {});
  };

  useEffect(() => {
    loadData();
  }, []);

  const orderByOptions = [
    {
      key: "all",
      label: "All",
    },
    {
      key: "a-z",
      label: "A-Z",
    },
  ];

  return (
    <>
      <Box p={2}>
        <Box>
          <TitleForm title="Time Off Request | 10012" withBackUrl={true} />
        </Box>

        <DeclineForm
          open={openModal}
          handleClose={handleCloseModal}
          submitText={"Decline"}
          url={"/apps/people/personal-information/time/time-off"}
          decline={"time-off-request"}
          id={id}
        />

        <Box marginTop={2}>
          <Grid container spacing={3}>
            <Grid item xs={6} md={4}>
              <Paper>
                <Box p={2}>
                  <Typography variant="h6">Information</Typography>
                </Box>
                <Divider />
                <Box p={2}>
                  <Box color="text.disabled">Request Date</Box>
                  <Typography variant="subtitle1">
                    {data.created_at.get()
                      ? moment(data.created_at.get()).format(
                          "DD MMMM YYYY, HH:mm"
                        )
                      : "-"}
                  </Typography>
                  <Box color="text.disabled" marginTop={1}>
                    Employee
                  </Box>
                  <Typography variant="subtitle1" className={classes.name}>
                    {data.creator_name.get() || "-"}
                  </Typography>
                  <Box color="text.disabled" marginTop={1}>
                    Time Off Category
                  </Box>
                  <Typography variant="subtitle1">
                    {data.time_off_policy_name.get() || "-"}
                  </Typography>
                  <Box color="text.disabled" marginTop={1}>
                    Request For
                  </Box>
                  <Typography variant="subtitle1">
                    {calcBusinessDays(
                      data.start.get(),
                      data.finish.get(),
                      data.is_half_day_start.get(),
                      data.is_half_day_finish.get()
                    )}{" "}
                    Days
                  </Typography>
                  <Box color="text.disabled" marginTop={1}>
                    From
                  </Box>
                  <Typography variant="subtitle1">
                    {data.start.get()
                      ? moment(data.start.get()).format("DD MMMM YYYY")
                      : "-"}
                    {data.is_half_day_start.get()
                      ? " - Half Day"
                      : " - Full Day"}
                  </Typography>
                  <Box color="text.disabled" marginTop={1}>
                    To
                  </Box>
                  <Typography variant="subtitle1">
                    {data.finish.get()
                      ? moment(data.finish.get()).format("DD MMMM YYYY")
                      : "-"}
                    {data.is_half_day_finish.get()
                      ? " - Half Day"
                      : " - Full Day"}
                  </Typography>
                  <Box color="text.disabled" marginTop={1}>
                    Reason
                  </Box>
                  <Typography variant="subtitle1">
                    {data.description.get() || "-"}
                  </Typography>
                  <Box color="text.disabled" marginTop={1}>
                    Phone Number
                  </Box>
                  <Typography variant="subtitle1">
                    {data.contact.get() || "-"}
                  </Typography>
                </Box>
              </Paper>
            </Grid>

            <Grid item xs={6} md={8}>
              <VuiCardContent title={"Approval Status"}>
                <Box mb={1}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Position</TableCell>
                          <TableCell>Name</TableCell>
                          <TableCell>On</TableCell>
                          <TableCell>Status</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data.approvals
                          .get()
                          .map((item: any, index: number) => {
                            return (
                              <TableRow key={index}>
                                <TableCell>{item.role_name}</TableCell>
                                <TableCell>
                                  <Typography variant="subtitle2">
                                    {item.approver_name || "-"}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography variant="subtitle2">
                                    {item.timestamp || "-"}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Status data={item.status} />
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </VuiCardContent>
            </Grid>
          </Grid>

          {canApproved && (
            <Box marginTop={5}>
              <Paper>
                <Box
                  p={2}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box>
                    <Typography variant="subtitle1">
                      Approve This Request?
                    </Typography>
                  </Box>
                  <Box>
                    <Box display="flex" alignItems="center">
                      <Box marginRight={2}>
                        <Button
                          variant="text"
                          color="primary"
                          onClick={handleOpenModal}
                        >
                          Decline
                        </Button>
                      </Box>
                      <Box>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleSaveData("approve")}
                        >
                          Approve
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Paper>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default RequestAttendanceDetail;
