import React, { useEffect } from "react";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { useIsMounted, VuiSearch } from "../../../../@VodeaUI";
import { useState } from "@hookstate/core/dist";
import {
  $clone,
  $cloneState,
  mapDataTableParams,
} from "../../../../utilities/helpers/global";
import VuiDataTable, {
  Column,
  Options,
} from "../../../../@VodeaUI/VuiDataTable/Index";
import { defaultDataTableOption } from "../../../../utilities/Option";
import ApprovalRepository from "../../../../repositories/ApprovalRepository";
import _ from "lodash";
import moment from "moment";
import Status from "../../../Status";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router";
import MuiButton from "../../../atoms/MuiButton";

const useStyles = makeStyles({
  marginRightUnset: {
    marginRight: "unset !important",
  },
});

const Approval: React.FC<any> = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const loading = useState<boolean>(false);
  const isMounted = useIsMounted();

  const options = useState<Options>({
    ...defaultDataTableOption,
    sortedBy: "desc",
    orderBy: "created_at",
  });

  const data = useState<any[]>([]);

  const loadData = async () => {
    loading.set(true);
    await ApprovalRepository.account({
      ...mapDataTableParams($cloneState(options)),
      with: ["subject.user", "user"],
    })
      .then((resp: any) => {
        if (isMounted.current) {
          data.set(resp.data.data);
          options.page.set(resp.data.meta.current_page - 1);
          options.total.set(resp.data.meta.total);
          loading.set(false);
        }
      })
      .catch((err: any) => {
        if (isMounted.current) {
          loading.set(false);
        }
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  const columns = useState<Column[]>([
    {
      key: "entity",
      label: "CATEGORY",
      render: (row: any) => {
        return row?.entity
          .split("\\")
          .pop()
          .replace("Request", "")
          .replace(/([A-Z])/g, " $1");
      },
    },
    {
      key: "created_at",
      label: "REQUEST DATE",
      render: (row) => {
        return (
          <Typography variant={"body2"}>
            {row.subject?.created_at
              ? moment(row.subject?.created_at).format("DD MMMM YYYY, HH:mm")
              : "-"}
          </Typography>
        );
      },
    },
    {
      key: "name",
      label: "NAME",
      render: (row) => {
        return _.get(row, "subject.user.name", "-");
      },
    },
    {
      key: "status",
      label: "STATUS",
      render: (row) => {
        const status = _.get(row, "subject.status", "");
        return <Status data={status} />;
      },
    },
    {
      key: "detail",
      label: "",
      render: (row) => {
        let typeApproval: number = 0;
        if (row.entity === "App\\Models\\TimeOffRequest") {
          typeApproval = 0;
        } else if (row.entity === "App\\Models\\AttendanceRequest") {
          typeApproval = 1;
        } else if (row.entity === "App\\Models\\ReimbursementRequest") {
          typeApproval = 2;
        } else if (row.entity === "App\\Models\\CashAdvanceRequest") {
          typeApproval = 3;
        } else {
          typeApproval = 4;
        }
        return (
          <Box display="flex" justifyContent={"flex-end"}>
            {typeApproval == 3 ? (
              <Link to={`/apps/finance/advance/${row.subject_id}`}>
                <MuiButton label="View" size="small" />
              </Link>
            ) : (
              <MuiButton
                label="View"
                size="small"
                onClick={() =>
                  navigate(`/apps/approval/detail/${row.subject_id}`, {
                    state: {
                      type: typeApproval,
                    },
                  })
                }
              />
            )}
          </Box>
        );
      },
    },
  ]);

  return (
    <>
      <Box p={2}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid item md={4} xs={12}>
              <VuiSearch
                state={options.search}
                callback={() => {
                  options.page.set(0);
                  loadData();
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <VuiDataTable
        loading={loading.get()}
        columns={columns.value}
        options={$clone(options.value)}
        data={data.value}
        onChangeOptions={(values: Options) => {
          options.set(values);
          loadData();
        }}
      />
    </>
  );
};

export default Approval;
