import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  notFoundSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '90vh',
    position: 'relative',
    overflow: 'hidden',

    '& h1': {
      margin: 0,
      fontSize: '135px',
      transition: 'all 0.5s',
      transitionDelay: '0.5s',
    },
    '& p': {
      margin: 0,
      fontSize: '25px',
      transition: 'all 0.5s',
      transitionDelay: '0.5s',
    },
  },
}));
