import React from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import { useGlobalStyles } from "../utilities/styles";
import clsx from "clsx";

interface Props {
  title: string;
  leftBtnTitle: string;
  leftBtnAction: any;
  rightBtnTitle: string;
  rightBtnAction: any;
  loading: boolean;
}

const FooterFormStepAction: React.FC<Props> = ({
  title,
  leftBtnTitle,
  leftBtnAction,
  rightBtnTitle,
  rightBtnAction,
  loading,
}) => {
  const globalClasses = useGlobalStyles();
  return (
    <Grid item xs={12}>
      <Paper variant="elevation" elevation={1}>
        <Box
          p={2}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="subtitle2">{title}</Typography>
          <Box display="flex">
            <Button onClick={leftBtnAction} size="small">
              {leftBtnTitle}
            </Button>

            <Box ml={1}>
              <Button
                className={clsx(
                  globalClasses.buttonPrimary,
                  globalClasses.buttonLg
                )}
                variant="contained"
                color="primary"
                size="small"
                disabled={loading}
                onClick={rightBtnAction}
                type={"button"}
              >
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  rightBtnTitle
                )}
              </Button>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Grid>
  );
};

export default FooterFormStepAction;
