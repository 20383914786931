import * as yup from "yup";
import { TFunction } from "react-i18next";

export const validationSchema = (t: TFunction) => {
  let shapes = {
    asset_id: yup.mixed().required().label(t("form.assetName.label")),
    date: yup.date().required().label(t("form.date.label")),
    description: yup.string().label(t("form.description.label")),
    asset_category_id: yup
      .mixed()
      .required()
      .label(t("form.assetCategory.label")),
  };

  return yup.object().shape(shapes);
};
