import React, { useEffect } from "react";
import { useState } from "@hookstate/core/dist";
import { Box, Grid, TextField, Typography } from "@material-ui/core";
import People from "../../../../../../layouts/Apps/People";
import VuiCardContent from "../../../../../../@VodeaUI/components/VuiCardContent";
import { useForm } from "react-hook-form";
import _ from "lodash";
import EmployeeRepository from "../../../../../../repositories/EmployeeRepository";
import { AxiosResponse } from "axios";
import { defaultDataTableOption } from "../../../../../../utilities/Option";
import { Options } from "../../../../../../@VodeaUI/VuiDataTable/Index";
import useIsMounted from "../../../../../../@VodeaUI/hooks/useIsMounted";
import { useParams } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";

const useStyles = makeStyles({
  title: {
    fontWeight: 400,
    color: "#BABABA",
    fontSize: "0.75rem",
  },
  titleValue: {
    fontWeight: 500,
    color: "#4B4D4C",
    fontSize: "0.9rem",
  },
});

interface UserDetailInterface {
  absence_number: Number | null;
  address: String | null;
  bank_account_holder: String | null;
  bank_account_number: String | null;
  bank_id: Number | null;
  blood_type: String | null;
  city: String | null;
  date_of_birth: Date | null;
  gender: String | null;
  id: Number | null;
  identity_expired: Date | null;
  identity_number: String | null;
  identity_type: String | null;
  join_date: Date | null;
  marital_status: String | null;
  place_of_birth: String | null;
  postal_code: String | null;
  religion: String | null;
  resign_date: Date | null;
  resign_description: String | null;
  user_id: Number | null;
  employee_id: Number | null;
  attendence_id: Number | null;
}

interface UserPayrollInterface {
  bpjs_kes_company_paid: Number | null;
  bpjs_kes_family: String | null;
  bpjs_kes_number: Number | null;
  bpjs_kes_valid_from: String | null;
  bpjs_tk_company_paid: Number | null;
  bpjs_tk_number: Number | null;
  bpjs_tk_valid_from: Date | null;
  id: Number | null;
  is_bpjs_kes: Boolean | false;
  is_bpjs_tk: Boolean | false;
  is_jht: Boolean | false;
  is_jp: Boolean | false;
  is_prorate: Boolean | null;
  npwp: String | null;
  overtime_status: String | null;
  payroll_components: UserPayrollPayrollComponentInterface[] | null;
  ptkp_status: String | null;
  salary: String | null;
  salary_configuration_type: String | null;
  tax_configuration: String | null;
  tax_status: String | null;
  taxable_date: String | null;
  type: String | null;
  user_id: Number | null;
  valid_from: Date | null;
}

interface UserPayrollPayrollComponentInterface {
  payroll_component_id: Number | null;
  amount: Number | null;
}

interface DataInterface {
  department_name: String;
  email: String;
  id: Number | String;
  mobile_phone: Number | String;
  name: String;
  photo: Object | null;
  photo_id: Number | null;
  role_name: String;
  telephone: String | null;
  user_detail: UserDetailInterface;
  user_payroll: UserPayrollInterface;
}

const baseData = {
  department_name: "",
  email: "",
  id: "",
  mobile_phone: "",
  name: "",
  photo: null,
  photo_id: null,
  role_name: "",
  telephone: null,
  user_detail: {
    absence_number: null,
    address: null,
    bank_account_holder: null,
    bank_account_number: null,
    bank_id: null,
    blood_type: null,
    city: null,
    date_of_birth: null,
    gender: null,
    id: null,
    identity_expired: null,
    identity_number: null,
    identity_type: null,
    join_date: null,
    marital_status: null,
    place_of_birth: null,
    postal_code: null,
    religion: null,
    resign_date: null,
    resign_description: null,
    user_id: null,
    attendence_id: null,
    employee_id: null,
  },
  user_payroll: {
    bpjs_kes_company_paid: null,
    bpjs_kes_family: null,
    bpjs_kes_number: null,
    bpjs_kes_valid_from: null,
    bpjs_tk_company_paid: null,
    bpjs_tk_number: null,
    bpjs_tk_valid_from: null,
    id: null,
    is_bpjs_kes: false,
    is_bpjs_tk: false,
    is_jht: false,
    is_jp: false,
    is_prorate: null,
    npwp: null,
    overtime_status: null,
    payroll_components: [],
    ptkp_status: null,
    salary: null,
    salary_configuration_type: null,
    tax_configuration: null,
    tax_status: null,
    taxable_date: null,
    type: null,
    user_id: null,
    valid_from: null,
  },
};

const PersonalInformation: React.FC<any> = () => {
  const classes = useStyles();
  const { errors } = useForm({
    mode: "onChange",
  });

  const isMounted = useIsMounted();
  const objParams = useParams();
  const loading = useState(false);
  const data = useState<DataInterface>(baseData);
  const options = useState<Options>({
    ...defaultDataTableOption,
    sortedBy: "desc",
    orderBy: "created_at",
  });

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = () => {
    loading.set(true);

    let params: any = {
      with: ["photo", "userDetail", "userPayroll"],
    };

    if (objParams.id !== undefined) {
      EmployeeRepository.show(
        {
          ...params,
        },
        objParams.id
      )
        .then((response: AxiosResponse) => {
          const { data: responseData } = response;
          const { data: list, meta } = responseData;

          if (isMounted.current) {
            data.set(list);
            options.page.set(meta.current_page - 1);
            options.total.set(meta.total);
            loading.set(false);
          }
        })
        .catch(() => {});
    } else {
      EmployeeRepository.account({
        ...params,
      })
        .then((response: AxiosResponse) => {
          const { data: responseData } = response;
          const { data: list, meta } = responseData;

          if (isMounted.current) {
            data.set(list);
            options.page.set(meta.current_page - 1);
            options.total.set(meta.total);
            loading.set(false);
          }
        })
        .catch(() => {});
    }
  };

  return (
    <People>
      <div>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <VuiCardContent title="Personal Data">
              <Box p={2}>
                <Grid container spacing={3}>
                  <Grid item md={4} xs={12}>
                    <Typography variant="h6" className={classes.title}>
                      Employee ID
                    </Typography>
                    <Typography variant="h6" className={classes.titleValue}>
                      {data.user_detail.employee_id.get()
                        ? data.user_detail.employee_id.get()
                        : "-"}
                    </Typography>
                  </Grid>
                  <Grid item md={8} xs={12}>
                    <Typography variant="h6" className={classes.title}>
                      Attendence ID
                    </Typography>
                    <Typography variant="h6" className={classes.titleValue}>
                      {data.user_detail.attendence_id.get()
                        ? data.user_detail.attendence_id.get()
                        : "-"}
                    </Typography>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Typography variant="h6" className={classes.title}>
                      Employee Name
                    </Typography>
                    <Typography variant="h6" className={classes.titleValue}>
                      {data.name.get() ? data.name.get() : "-"}
                    </Typography>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Typography variant="h6" className={classes.title}>
                      Phone
                    </Typography>
                    <Typography variant="h6" className={classes.titleValue}>
                      {data.mobile_phone.get() ? data.mobile_phone.get() : "-"}
                    </Typography>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Typography variant="h6" className={classes.title}>
                      Email
                    </Typography>
                    <Typography variant="h6" className={classes.titleValue}>
                      {data.email.get() ? data.email.get() : "-"}
                    </Typography>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Typography variant="h6" className={classes.title}>
                      Marital Status
                    </Typography>
                    <Typography variant="h6" className={classes.titleValue}>
                      {data.user_detail.marital_status.get()
                        ? data.user_detail.marital_status.get()
                        : "-"}
                    </Typography>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Typography variant="h6" className={classes.title}>
                      Religion
                    </Typography>
                    <Typography variant="h6" className={classes.titleValue}>
                      {data.user_detail.religion.get()
                        ? data.user_detail.religion.get()
                        : "-"}
                    </Typography>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Typography variant="h6" className={classes.title}>
                      Gender
                    </Typography>
                    <Typography variant="h6" className={classes.titleValue}>
                      {data.user_detail.gender.get()
                        ? data.user_detail.gender.get()
                        : "-"}
                    </Typography>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Typography variant="h6" className={classes.title}>
                      Date of Birth
                    </Typography>
                    <Typography variant="h6" className={classes.titleValue}>
                      {data.user_detail.date_of_birth.get()
                        ? moment(data.user_detail.date_of_birth.get()).format(
                            "DD MMMM YYYY"
                          )
                        : "-"}
                    </Typography>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Typography variant="h6" className={classes.title}>
                      Blood Type
                    </Typography>
                    <Typography variant="h6" className={classes.titleValue}>
                      {data.user_detail.blood_type.get()
                        ? data.user_detail.blood_type.get()
                        : "-"}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </VuiCardContent>
          </Grid>
          <Grid item xs={12}>
            <VuiCardContent title="Identity Card">
              <Box p={2}>
                <Grid container spacing={3}>
                  <Grid item md={4} xs={12}>
                    <Typography variant="h6" className={classes.title}>
                      Identity Type
                    </Typography>
                    <Typography variant="h6" className={classes.titleValue}>
                      {data.user_detail.identity_type.get()
                        ? data.user_detail.identity_type.get()
                        : "-"}
                    </Typography>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Typography variant="h6" className={classes.title}>
                      Identity Expired Date
                    </Typography>
                    <Typography variant="h6" className={classes.titleValue}>
                      {data.user_detail.identity_expired.get()
                        ? data.user_detail.identity_expired.get()
                        : "-"}
                    </Typography>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Typography variant="h6" className={classes.title}>
                      Identity Number
                    </Typography>
                    <Typography variant="h6" className={classes.titleValue}>
                      {data.user_detail.identity_number.get()
                        ? data.user_detail.identity_number.get()
                        : "-"}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </VuiCardContent>
          </Grid>
          <Grid item xs={12}>
            <VuiCardContent title="Address">
              <Box p={3}>
                <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                    <Typography variant="h6" className={classes.title}>
                      Address
                    </Typography>
                    <Typography variant="h6" className={classes.titleValue}>
                      {data.user_detail.address.get()
                        ? data.user_detail.address.get()
                        : "-"}
                    </Typography>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Typography variant="h6" className={classes.title}>
                      City
                    </Typography>
                    <Typography variant="h6" className={classes.titleValue}>
                      {data.user_detail.city.get()
                        ? data.user_detail.city.get()
                        : "-"}
                    </Typography>
                  </Grid>
                  <Grid item md={8} xs={12}>
                    <Typography variant="h6" className={classes.title}>
                      Postal Code
                    </Typography>
                    <Typography variant="h6" className={classes.titleValue}>
                      {data.user_detail.postal_code.get()
                        ? data.user_detail.postal_code.get()
                        : "-"}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </VuiCardContent>
          </Grid>
        </Grid>
      </div>
    </People>
  );
};

export default PersonalInformation;
