import { api } from "../@VodeaUI";

let url = window._env_.REACT_APP_PASSPORT_END_POINT;

const ReimbursementRepository = {
  all: function (params: any = null) {
    return api.get(`${url}/api/reimbursement-request`, {
      params,
    });
  },
  account: function (params: any = null) {
    return api.get(`${url}/api/account/reimbursement-request`, {
      params,
    });
  },
  show: function (params: any = null, id: any) {
    return api.get(`${url}/api/reimbursement-request/${id}`, {
      params,
    });
  },
  showData: function (id: number | string, params: any = null) {
    return api.get(`${url}/api/reimbursement-request/${id}`, {
      params,
    });
  },
  approve: function (id: number | string, params: any = null) {
    return api.put(`${url}/api/reimbursement-request/${id}/approve`, {
      params,
    });
  },
  getCategory: function (params: any = null) {
    return api.get(`${url}/api/reimbursement-policy`, {
      params,
    });
  },
  getBalance: function (params: any = null) {
    return api.get(`${url}/api/reimbursement-balance`, {
      params,
    });
  },
  getFormula: function (id: number | string, params: any = null) {
    return api.get(`${url}/api/select/reimbursement-policy/${id}/formula`, {
      params,
    });
  },
  create: function (payload: any, params: any = null) {
    return api.post(`${url}/api/reimbursement-request`, payload, {
      params,
    });
  },
  selectFormula: function (params: any = null) {
    return api.get(`${url}/api/select/formula`, {
      params,
    });
  },
  selectPayroll: function (params: any = null) {
    return api.get(`${url}/api/select/payroll-component`, {
      params,
    });
  },
};

export default ReimbursementRepository;
