import React from "react";
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";
import {
  KeyboardDatePicker,
  KeyboardDatePickerProps,
} from "@material-ui/pickers";

interface IMuiDatePicker extends KeyboardDatePickerProps {}

const MuiDatePicker = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiInputBase-root": {
        borderRadius: theme.spacing(1),
        "& .MuiInputBase-input": {
          fontSize: 14,
          padding: 14,
        },
      },
      "& .MuiOutlinedInput-adornedEnd": {
        paddingRight: 0,
      },
      "& .MuiInputLabel-outlined": {
        transform: "translate(14px, 18px) scale(1)",
        fontSize: 14,
        "&.MuiInputLabel-shrink": {
          transform: "translate(14px, -5px) scale(0.75)",
        },
      },
      "& .MuiOutlinedInput-notchedOutline": {
        "& legend": {
          fontSize: 10.5,
        },
      },
      "& .MuiSvgIcon-root": {
        width: "0.9em",
        height: "0.9em",
      },
    },
  })
)((props: IMuiDatePicker) => (
  <KeyboardDatePicker
    {...props}
    format={props.format || "DD MMMM YYYY"}
    disableToolbar
    fullWidth
    variant="inline"
    inputVariant="outlined"
  />
));

export default React.memo(MuiDatePicker);
