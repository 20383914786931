import { mapHookFormErrors } from "./hook-form.helper";
import { AxiosError } from "axios";
import { ProviderContext } from "notistack";
import _ from "lodash";

export const isAxiosCanceled = (error: AxiosError): boolean => {
  return _.get(error, "message.isAxiosCanceled");
};

export const axiosErrorSaveHandler = (
  e: any,
  setError: any,
  enqueueSnackbar: any,
  t: any
) => {
  if (e?.response?.data?.message) {
    const errors = mapHookFormErrors(e.response.data.message);
    Object.keys(errors).forEach((key: any) => setError(key, errors[key]));
  } else {
    const errorTranslation = e?.response?.status
      ? `common:error.${e.response.status}`
      : "common:error.other";

    enqueueSnackbar(t(errorTranslation), {
      variant: "error",
    });
  }
};

export const axiosErrorLoadDataHandler = (
  error: AxiosError,
  enqueueSnackbar: ProviderContext["enqueueSnackbar"]
) => {
  enqueueSnackbar(error?.response?.data.message, {
    variant: "error",
    autoHideDuration: 4000,
  });
};

export const handleAxiosErrorSave = (
  error: AxiosError,
  setError: any,
  enqueueSnackbar: any
) => {
  if (error?.response?.data?.errors) {
    const errors: any = mapHookFormErrors(error?.response?.data?.errors);
    Object.keys(errors).forEach((key: any) => setError(key, errors[key]));
    const flatten: any[] = Object.values(error?.response?.data?.errors).flat();
    if (flatten.length > 0) {
      enqueueSnackbar(flatten[0], {
        variant: "error",
      });
    } else {
      enqueueSnackbar(error.message, {
        variant: "error",
      });
    }
  } else {
    enqueueSnackbar(error.message, {
      variant: "error",
    });
  }
};
