import React, { useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { VuiSearch } from "../../../../@VodeaUI/components";
import { BlockOutlined, CheckCircleOutlineOutlined } from "@material-ui/icons";
import {
  $clone,
  $cloneState,
  mapDataTableParams,
} from "../../../../utilities/helpers/global";
import { useState } from "@hookstate/core/dist";
import VuiDataTable, {
  Column,
  Options,
} from "../../../../@VodeaUI/VuiDataTable/Index";
import { defaultDataTableOption } from "../../../../utilities/Option";
import { Link, useNavigate } from "react-router-dom";
import VuiSelect from "../../../../@VodeaUI/components/Input/VuiSelect/Index";
import TimeOffRepository from "../../../../repositories/TimeOffRepository";
import { useIsMounted } from "../../../../utilities/helpers/hooks";
import _ from "lodash";
import Status from "../../../../components/Status";

const TimeOffModule: React.FC<any> = () => {
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const columns = useState<Column[]>([
    {
      key: "user",
      label: "Name",
      defaultValue: "-",
      render: (row) => _.get(row, "user.name"),
    },
    {
      key: "created_at",
      label: "Request Date",
      defaultValue: "-",
      type: "date",
      dateFormat: "DD MMMM YYYY",
    },
    {
      key: "time_off_policy",
      label: "Category",
      defaultValue: "-",
      render: (row) => _.get(row, "time_off_policy.name"),
    },
    {
      key: "start",
      label: "Start Date",
      type: "date",
      dateFormat: "DD MMMM YYYY",
      defaultValue: "-",
    },
    {
      key: "finish",
      label: "Finish Date",
      type: "date",
      dateFormat: "DD MMMM YYYY",
      defaultValue: "-",
    },
    {
      key: "status",
      label: "Status",
      render: (row) => {
        return <Status data={row.status} />;
      },
    },
    {
      key: "id",
      label: "",
      sortable: false,
      render: (row) => {
        return (
          <Box
            display="flex"
            justifyContent={"flex-end"}
            alignItems={"center"}
            className={"aw"}
          >
            <Button
              variant="contained"
              onClick={() =>
                navigate(`${row.id}`, {
                  state: {
                    type: 0,
                  },
                })
              }
              color="primary"
              className={"btnView"}
            >
              View
            </Button>

            {row.can_suspend ? (
              <Tooltip title="Suspend">
                <IconButton aria-label="suspend">
                  <BlockOutlined fontSize="small" />
                </IconButton>
              </Tooltip>
            ) : null}

            {row.can_unsuspend ? (
              <Tooltip title="Unsuspend">
                <IconButton aria-label="unsuspend">
                  <CheckCircleOutlineOutlined fontSize="small" />
                </IconButton>
              </Tooltip>
            ) : null}
          </Box>
        );
      },
    },
  ]);

  const orderBy = useState("all");
  const loading = useState(false);
  const options = useState<Options>({
    ...defaultDataTableOption,
    sortedBy: "desc",
    orderBy: "created_at",
  });
  const tableData = useState<any[]>([]);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = () => {
    if (isMounted.current) loading.set(true);

    TimeOffRepository.all({
      ...mapDataTableParams($cloneState(options)),
      with: ["user", "timeOffPolicy"],
      only: "my_and_approver",
    })
      .then((resp: any) => {
        if (isMounted.current) {
          tableData.set(resp.data.data);
          options.page.set(resp.data.meta.current_page - 1);
          options.total.set(resp.data.meta.total);
          loading.set(false);
        }
      })
      .catch((err: any) => {
        if (isMounted.current) {
          loading.set(false);
        }
      });
  };

  const orderByOptions = [
    {
      key: "all",
      label: "All",
    },
    {
      key: "a-z",
      label: "A-Z",
    },
  ];

  return (
    <>
      <Grid container spacing={3} justifyContent="space-between">
        <Grid item>
          <Typography variant={"h5"}>Time Off</Typography>
        </Grid>
        <Grid item>
          <Link to={"/apps/time/time-off/time-off-request"}>
            <Button variant="contained" color="primary">
              Request Time Off
            </Button>
          </Link>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper variant="elevation" elevation={1}>
            <Box p={2}>
              <Grid
                container
                spacing={3}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Grid item>
                  <VuiSearch
                    state={options.search}
                    callback={() => {
                      options.page.set(0);
                      loadData();
                    }}
                  />
                </Grid>
                <Grid item>
                  <VuiSelect
                    label="Urutkan"
                    state={orderBy}
                    options={orderByOptions}
                  />
                </Grid>
              </Grid>
              <Box marginTop={2}>
                <Grid container>
                  <Grid item>
                    <Grid container spacing={3}>
                      <Grid item xs={4} md={4}>
                        <VuiSelect
                          label="Category"
                          state={orderBy}
                          options={orderByOptions}
                        />
                      </Grid>
                      <Grid item xs={4} md={4}>
                        <VuiSelect
                          label="Range"
                          state={orderBy}
                          options={orderByOptions}
                        />
                      </Grid>
                      <Grid item xs={4} md={4}>
                        <VuiSelect
                          label="Employee"
                          state={orderBy}
                          options={orderByOptions}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Box>

            <VuiDataTable
              loading={loading.get()}
              columns={columns.value}
              options={$clone(options.value)}
              data={tableData.get()}
              onChangeOptions={(values: Options) => {
                options.set(values);
                loadData();
              }}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default TimeOffModule;
