import React from "react";
import NumberFormat from "react-number-format";

const NumberFieldFormat = (props: any) => {
  const { inputRef, onChange, placeholder, value, ...other } = props;

  return (
    <NumberFormat
      {...other}
      value={value}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange(values.value);
      }}
      placholder={placeholder || "0"}
      thousandSeparator={"."}
      decimalSeparator={","}
    />
  );
};

export default React.memo(NumberFieldFormat);
