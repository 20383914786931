import React, { useEffect } from "react";
import { Box, Button, Grid, IconButton, Paper } from "@material-ui/core";
import { VuiSearch } from "../../../../@VodeaUI/components";
import { $clone } from "../../../../utilities/helpers/global";
import VuiSelect from "../../../../@VodeaUI/components/Input/VuiSelect/Index";
import DataTable from "../../../../components/DataTable";
import { ArrowForward } from "@material-ui/icons";
import { useState } from "@hookstate/core";
import { useStyles } from "../style";
import PayrollRepository from "../../../../repositories/PayrollRepository";
import VuiDataTable, {
  Column,
  Options,
} from "../../../../@VodeaUI/VuiDataTable/Index";
import { defaultDataTableOption } from "../../../../utilities/Option";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";

const Salary: React.FC<any> = () => {
  const classes = useStyles();
  const search = useState<string>("");
  const orderBy = useState("all");
  const loading = useState(false);

  const data = [
    {
      name: "Ashley Beethoven",
      position: "Marketing Manager",
      department: "Marketing",
      tax_type: "Gross-up | TK/0",
      basic_salary: "6.000.000",
    },
    {
      name: "Alena Uillmer",
      position: "Marketing Supervisor",
      department: "Marketing",
      tax_type: "Gross-up | TK/0",
      basic_salary: "5.000.000",
    },
  ];

  const orderByOptions = [
    {
      key: "all",
      label: "All",
    },
    {
      key: "a-z",
      label: "A-Z",
    },
  ];
  const month = useState("all");
  const monthOptions = [
    {
      key: "all",
      label: "All",
    },
    {
      key: "1",
      label: "January",
    },
    {
      key: "2",
      label: "February",
    },
    {
      key: "3",
      label: "March",
    },
    {
      key: "4",
      label: "April",
    },
    {
      key: "5",
      label: "Mei",
    },
  ];

  useEffect(() => {
    PayrollRepository.all().then((resp: any) => {});
  }, []);

  const options = useState<Options>({
    ...defaultDataTableOption,
    sortedBy: "desc",
    orderBy: "date",
  });

  const columns = useState<Column[]>([
    { key: "name", label: "NAME" },
    { key: "position", label: "POSITION" },
    { key: "department", label: "DEPARTMENT" },
    { key: "tax_type", label: "TAX TYPE" },
    { key: "basic_salary", label: "BASIC SALARY (IDR)" },
    {
      key: "id",
      label: "",
      sortable: false,
      render: (row) => {
        return (
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <Button className="btnView" variant="contained" color="primary">
              Edit
            </Button>
          </Box>
        );
      },
    },
  ]);

  const loadData = async () => {
    loading.set(true);
  };

  return (
    <>
      <Grid container spacing={3} justifyContent="space-between">
        <Grid item xs={12}>
          <Box mt={2}>
            <Paper>
              <Box p={2}>
                <Grid container spacing={3} alignItems={"center"}>
                  <Grid item md={12} xs={12}>
                    <Grid
                      container
                      spacing={3}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                    >
                      <Grid item>
                        <VuiSearch state={$clone(search.get())} />
                      </Grid>
                      <Grid item>
                        <VuiSelect
                          label="Urutkan"
                          state={orderBy}
                          options={orderByOptions}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Box marginRight={1}>
                        <VuiSelect
                          label="Category"
                          state={month}
                          options={monthOptions}
                        />
                      </Box>
                      <Box marginRight={1}>
                        <VuiSelect
                          label="Ranger"
                          state={month}
                          options={monthOptions}
                        />
                      </Box>
                      <Box>
                        <VuiSelect
                          label="Employee"
                          state={month}
                          options={monthOptions}
                        />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              <VuiDataTable
                loading={loading.get()}
                columns={columns.value}
                options={$clone(options.value)}
                data={data}
                onChangeOptions={(values: Options) => {
                  options.set(values);
                  loadData();
                }}
              />
            </Paper>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Salary;
