import React from "react";
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";
import { IconButton, IconButtonProps } from "@material-ui/core";

export interface MuiIconButtonProps extends IconButtonProps {}

const MuiIconButton = withStyles((theme: Theme) =>
  createStyles({
    root: {},
  })
)((props: MuiIconButtonProps) => <IconButton {...props} />);

export default React.memo(MuiIconButton);
