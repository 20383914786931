import React, { useEffect } from "react";
import { useState } from "@hookstate/core/dist";
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import People from "../../../../../../../layouts/Apps/People";
import VuiCardContent from "../../../../../../../@VodeaUI/components/VuiCardContent";
import { useForm } from "react-hook-form";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import EmployeeRepository from "../../../../../../../repositories/EmployeeRepository";
import { AxiosResponse } from "axios";
import { defaultDataTableOption } from "../../../../../../../utilities/Option";
import VuiDataTable, {
  Column,
  Options,
} from "../../../../../../../@VodeaUI/VuiDataTable/Index";
import useIsMounted from "../../../../../../../@VodeaUI/hooks/useIsMounted";
import { useParams } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import FooterFormAction from "../../../../../../../components/FooterFormAction";
import { VuiSearch } from "../../../../../../../@VodeaUI";
import VuiSelect from "../../../../../../../@VodeaUI/components/Input/VuiSelect/Index";
import { $clone } from "../../../../../../../utilities/helpers/global";
import { DeleteOutline as DeleteOutlineIcon } from "@material-ui/icons";

const useStyles = makeStyles({
  title: {
    fontWeight: 400,
    color: "#BABABA",
    fontSize: "0.75rem",
  },
  titleValue: {
    fontWeight: 500,
    color: "#4B4D4C",
    fontSize: "0.9rem",
  },
});

interface UserDetailInterface {
  absence_number: Number | null;
  address: String | null;
  bank_account_holder: String | null;
  bank_account_number: String | null;
  bank_id: Number | null;
  blood_type: String | null;
  city: String | null;
  date_of_birth: Date | null;
  gender: String | null;
  id: Number | null;
  identity_expired: Date | null;
  identity_number: String | null;
  identity_type: String | null;
  join_date: Date | null;
  marital_status: String | null;
  place_of_birth: String | null;
  postal_code: String | null;
  religion: String | null;
  resign_date: Date | null;
  resign_description: String | null;
  user_id: Number | null;
  employee_id: Number | null;
  attendence_id: Number | null;
}

interface UserPayrollInterface {
  bpjs_kes_company_paid: Number | null;
  bpjs_kes_family: String | null;
  bpjs_kes_number: Number | null;
  bpjs_kes_valid_from: String | null;
  bpjs_tk_company_paid: Number | null;
  bpjs_tk_number: Number | null;
  bpjs_tk_valid_from: Date | null;
  id: Number | null;
  is_bpjs_kes: Boolean | false;
  is_bpjs_tk: Boolean | false;
  is_jht: Boolean | false;
  is_jp: Boolean | false;
  is_prorate: Boolean | null;
  npwp: String | null;
  overtime_status: String | null;
  payroll_components: UserPayrollPayrollComponentInterface[] | null;
  ptkp_status: String | null;
  salary: String | null;
  salary_configuration_type: String | null;
  tax_configuration: String | null;
  tax_status: String | null;
  taxable_date: String | null;
  type: String | null;
  user_id: Number | null;
  valid_from: Date | null;
}

interface UserPayrollPayrollComponentInterface {
  payroll_component_id: Number | null;
  amount: Number | null;
}

interface DataInterface {
  department_name: String;
  email: String;
  id: Number | String;
  mobile_phone: Number | String;
  name: String;
  photo: Object | null;
  photo_id: Number | null;
  role_name: String;
  telephone: String | null;
  user_detail: UserDetailInterface;
  user_payroll: UserPayrollInterface;
}

const baseData = {
  department_name: "",
  email: "",
  id: "",
  mobile_phone: "",
  name: "",
  photo: null,
  photo_id: null,
  role_name: "",
  telephone: null,
  user_detail: {
    absence_number: null,
    address: null,
    bank_account_holder: null,
    bank_account_number: null,
    bank_id: null,
    blood_type: null,
    city: null,
    date_of_birth: null,
    gender: null,
    id: null,
    identity_expired: null,
    identity_number: null,
    identity_type: null,
    join_date: null,
    marital_status: null,
    place_of_birth: null,
    postal_code: null,
    religion: null,
    resign_date: null,
    resign_description: null,
    user_id: null,
    attendence_id: null,
    employee_id: null,
  },
  user_payroll: {
    bpjs_kes_company_paid: null,
    bpjs_kes_family: null,
    bpjs_kes_number: null,
    bpjs_kes_valid_from: null,
    bpjs_tk_company_paid: null,
    bpjs_tk_number: null,
    bpjs_tk_valid_from: null,
    id: null,
    is_bpjs_kes: false,
    is_bpjs_tk: false,
    is_jht: false,
    is_jp: false,
    is_prorate: null,
    npwp: null,
    overtime_status: null,
    payroll_components: [],
    ptkp_status: null,
    salary: null,
    salary_configuration_type: null,
    tax_configuration: null,
    tax_status: null,
    taxable_date: null,
    type: null,
    user_id: null,
    valid_from: null,
  },
};

const EmployeeEmploymentData: React.FC<any> = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { errors } = useForm({
    mode: "onChange",
  });

  const isMounted = useIsMounted();
  const { id } = useParams();
  const loading = useState(false);
  const data = useState<any[]>([]);
  const options = useState<Options>({
    ...defaultDataTableOption,
    sortedBy: "desc",
    orderBy: "created_at",
  });

  const columns = useState<Column[]>([
    { key: "effective_date", label: "Effective Date" },
    { key: "name", label: "Name" },
    { key: "department", label: "Department" },
    { key: "position", label: "Position" },
    {
      key: "id",
      label: "",
      sortable: false,
      render: (row) => {
        return (
          <Box display="flex" justifyContent={"flex-end"}>
            <Button
              onClick={() => navigate(`detail/${row.id}`)}
              className="btnView"
              variant="contained"
              color="primary"
            >
              View
            </Button>

            <Box ml={1}>
              <IconButton aria-label="edit">
                <DeleteOutlineIcon fontSize="small" />
              </IconButton>
            </Box>

            {/* {row.can_suspend ? (
              <Tooltip title="Suspend">
                <IconButton aria-label="suspend">
                  <BlockOutlined fontSize="small" />
                </IconButton>
              </Tooltip>
            ) : null}

            {row.can_unsuspend ? (
              <Tooltip title="Unsuspend">
                <IconButton aria-label="unsuspend">
                  <CheckCircleOutlineOutlined fontSize="small" />
                </IconButton>
              </Tooltip>
            ) : null} */}
          </Box>
        );
      },
    },
  ]);

  const employeeNavData: any[] = [
    {
      label: "General",
      href: "",
      path: "general",
      permissions: "any",
      children: [
        {
          label: "Personal Data",
          href: `/apps/people/employee/employee-detail/${id}/general/personal-data`,
          path: "personal-data",
          permissions: "any",
        },
        {
          label: "Employment Data",
          href: `/apps/people/employee/employee-detail/${id}/general/employment-data`,
          path: "employment-data",
          permissions: "any",
        },
        {
          label: "Family Data",
          href: `/apps/people/employee/employee-detail/${id}/general/family-data`,
          path: "family-data",
          permissions: "any",
        },
      ],
    },
    {
      label: "Time",
      href: "",
      path: "Time",
      permissions: "any",
      children: [
        {
          label: "Attendance",
          href: `/apps/people/employee/employee-detail/time/attendance/${id}`,
          path: "attendance",
          permissions: "any",
        },
        {
          label: "Time Off",
          href: `/apps/people/employee/employee-detail/time/time-off/${id}`,
          path: "time-off",
          permissions: "any",
        },
      ],
    },
    {
      label: "Finance",
      href: "",
      path: "finance",
      permissions: "any",
      children: [
        {
          label: "Reimbursement",
          href: `/apps/people/employee/employee-detail/finance/reimbursement/${id}`,
          path: "reimbursement",
          permissions: "any",
        },
        {
          label: "Advance",
          href: `/apps/people/employee/employee-detail/finance/advance/${id}`,
          path: "advance",
          permissions: "any",
        },
        {
          label: "Settlement",
          href: `/apps/people/employee/employee-detail/finance/settlement/${id}`,
          path: "settlement",
          permissions: "any",
        },
      ],
    },
    // {
    //     label: 'Asset',
    //     href: '',
    //     path: 'asset',
    //     permissions: 'any',
    //     children: []
    // },
    // {
    //     label: 'Facility',
    //     href: '',
    //     path: 'facility',
    //     permissions: 'any',
    //     children: []
    // },
    // {
    //     label: 'File',
    //     href: '',
    //     path: 'file',
    //     permissions: 'any',
    //     children:[]
    // },
  ];

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = () => {
    loading.set(true);

    let params: any = {
      with: ["photo", "userDetail", "userPayroll"],
    };

    if (id !== undefined) {
      EmployeeRepository.show(
        {
          ...params,
        },
        id
      )
        .then((response: AxiosResponse) => {
          const { data: responseData } = response;
          const { data: list, meta } = responseData;

          if (isMounted.current) {
            data.set(list);
            options.page.set(meta.current_page - 1);
            options.total.set(meta.total);
            loading.set(false);
          }
        })
        .catch(() => {});
    } else {
      EmployeeRepository.account({
        ...params,
      })
        .then((response: AxiosResponse) => {
          const { data: responseData } = response;
          const { data: list, meta } = responseData;

          if (isMounted.current) {
            data.set(list);
            options.page.set(meta.current_page - 1);
            options.total.set(meta.total);
            loading.set(false);
          }
        })
        .catch(() => {});
    }
  };

  return (
    <>
      <People navData={employeeNavData} title={"Employee Detail"}>
        <VuiCardContent title="Employment Data">
          <Box p={2}>
            <Grid item xs={12}>
              <Grid
                container
                spacing={3}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Grid item>
                  <VuiSearch
                    state={options.search}
                    callback={() => {
                      options.page.set(0);
                      loadData();
                    }}
                  />
                </Grid>
                {/*<Grid item>*/}
                {/*  <VuiSelect*/}
                {/*    label="Category"*/}
                {/*    state={orderBy}*/}
                {/*    options={orderByOptions}*/}
                {/*  />*/}
                {/*</Grid>*/}
              </Grid>
            </Grid>
          </Box>
          <Grid item xs={12}>
            <VuiDataTable
              loading={loading.get()}
              columns={columns.value}
              options={$clone(options.value)}
              data={data.value}
              onChangeOptions={(values: Options) => {
                options.set(values);
                loadData();
              }}
            />
          </Grid>
        </VuiCardContent>
      </People>
    </>
  );
};

export default EmployeeEmploymentData;
