import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    widgetListWrapper: {
        marginBottom: '15px'
    },
    boxWrapper: {
        padding: '10px 20px',

        '& .status': {
            padding: '5px',
            marginBottom: '5px',
            borderRadius: '5px',

            '&.On': {
                backgroundColor: 'rgba(158, 179, 153, 0.2)',
                color: '#9EB399',
            },
            '&.Pending': {
                backgroundColor: 'rgba(255, 157, 0, 0.2)',
                color: '#FF9D00',
            },
            '&.Late': {
                backgroundColor: 'rgba(255, 0, 0, 0.2)',
                color: '#ff0000',
            },
        }
    }
}));
