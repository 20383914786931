import React, { useEffect } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import { $clone } from "../../../utilities/helpers/global";
import _ from "lodash";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { useState } from "@hookstate/core";
import { ConstantOptionInterface } from "../../../pages/Apps/Preference/Policy/Reimbursement/interface";
import ReimbursementRepository from "../../../repositories/ReimbursementRepository";
import { useIsMounted } from "../../../utilities/helpers/hooks";
import * as yup from "yup";
import { useSelector } from "react-redux";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Capitalize } from "../../../utilities/helpers/string";
import {
  MuiAutoComplete,
  MuiButton,
  MuiCheckbox,
  MuiTextField,
  NumberFieldFormat,
} from "../../atoms";
import { constantToOptions } from "../../../utilities/helpers/option.helper";
import {
  formulaOperatorOptions,
  formulaSymbolOptions,
} from "../../../constants";
import { IReimbursementFormulaPolicy } from "../../../interfaces/ReimbursementFormulaPolicy";

interface IFormAddReimbursementFormula {
  open: boolean;
  handleClose: () => void;
  handleSave: (value: IReimbursementFormulaPolicy) => void;
  formulaData: null | any;
}

const validationSchema = () => {
  let shapes = {
    name: yup.string().required().label("component name"),
    code: yup.string().required().label("component code"),
  };

  return yup.object().shape(shapes);
};

const defaultValues = {
  code: "",
  name: "",
  description: "",
  is_unlimited: false,
  is_mandatory_file: false,
  is_asset_required: false,
  formula_components: [],
};

const componentDefaultValues = {
  type: null,
  value: null,
};

const FormAddReimbursementFormula: React.FC<IFormAddReimbursementFormula> = ({
  open,
  handleClose,
  handleSave,
  formulaData = null,
}) => {
  const { constant } = useSelector(({ constant }: any) => {
    return {
      constant: constant.constant.payload,
    };
  });

  const formulaComponentOptions = useState<ConstantOptionInterface[]>(
    constantToOptions(constant, "FORMULA_COMPONENT_AMOUNT_TYPE_OPTIONS", true)
  );
  const isMounted = useIsMounted();

  const { control, watch, handleSubmit, errors, reset } =
    useForm<IReimbursementFormulaPolicy>({
      mode: "onChange",
      resolver: yupResolver(validationSchema()),
      defaultValues: defaultValues,
    });

  useEffect(() => {
    if (formulaData && isMounted.current) {
      const tempComponents: any[] = [];
      if (
        formulaData.formula_component.components &&
        formulaData.formula_component.components.length > 0
      ) {
        formulaData.formula_component.components.forEach((component: any) => {
          tempComponents.push({
            ...component,
            type: {
              id: component.type,
              name: Capitalize(component.type),
            },
          });
        });
      }
      reset({
        ...formulaData,
        formula_components: tempComponents,
      });
    } else {
      reset(defaultValues);
    }
  }, [formulaData]);

  const {
    fields: componentFields,
    append: componentAppend,
    remove: componentRemove,
  } = useFieldArray({
    control,
    keyName: "key",
    name: "formula_components",
  });

  const watchFormulaComponents = watch("formula_components");
  const watchIsUnlimited = watch("is_unlimited");

  const onSubmit = (data: IReimbursementFormulaPolicy) => {
    handleSave(data);
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        <Box display="flex" justifyContent="center" alignItems="center">
          {formulaData ? "Edit Component" : "Add New Component"}
        </Box>
      </DialogTitle>

      <DialogContent style={{ padding: 25 }}>
        <DialogContentText>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <Controller
                control={control}
                name={"name"}
                render={({ value, onChange, name }) => {
                  return (
                    <MuiTextField
                      name={name}
                      onChange={onChange}
                      label="Component Name"
                      value={value}
                      error={_.has(errors, name)}
                      helperText={_.get(errors, `${name}.message`)}
                    />
                  );
                }}
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <Controller
                control={control}
                name={"code"}
                render={({ value, onChange, name }) => {
                  return (
                    <MuiTextField
                      name={name}
                      onChange={onChange}
                      label="Component Code"
                      value={value}
                      error={_.has(errors, name)}
                      helperText={_.get(errors, `${name}.message`)}
                    />
                  );
                }}
              />
            </Grid>

            <Grid item md={12} xs={12}>
              <Controller
                control={control}
                name={"description"}
                render={({ value, onChange, name }) => {
                  return (
                    <MuiTextField
                      name={name}
                      onChange={onChange}
                      label="Description"
                      value={value}
                      error={_.has(errors, name)}
                      helperText={_.get(errors, `${name}.message`)}
                    />
                  );
                }}
              />
            </Grid>

            <Grid item md={12} xs={12}>
              <Controller
                name={"is_asset_required"}
                control={control}
                render={({ value, onChange }) => {
                  return (
                    <MuiCheckbox
                      label={"Required Document"}
                      checked={value}
                      onChange={onChange}
                    />
                  );
                }}
              />
            </Grid>

            <Grid item md={12} xs={12}>
              <Controller
                name={"is_mandatory_file"}
                control={control}
                render={({ value, name, onChange }) => {
                  return (
                    <MuiCheckbox
                      label={"Connected to assets"}
                      checked={value}
                      onChange={onChange}
                    />
                  );
                }}
              />
            </Grid>

            <Grid item md={12} xs={12}>
              <Controller
                name={"is_unlimited"}
                control={control}
                render={({ value, onChange }) => {
                  return (
                    <MuiCheckbox
                      label={"Formula Has Unlimited Balance"}
                      checked={value}
                      onChange={onChange}
                    />
                  );
                }}
              />
            </Grid>

            {!watchIsUnlimited && (
              <Grid item md={12} xs={12}>
                <Typography color="textPrimary" variant="h6">
                  Formula Request Amount
                </Typography>
              </Grid>
            )}

            {!watchIsUnlimited && (
              <Grid item md={12} xs={12}>
                <Grid container spacing={2}>
                  {componentFields.map((item, index: number) => {
                    return (
                      <>
                        <Grid item md={5} xs={5}>
                          <Controller
                            control={control}
                            defaultValue={item.type}
                            name={`formula_components[${index}].type`}
                            render={({ value, onChange, name }) => {
                              return (
                                <MuiAutoComplete
                                  isAsync={false}
                                  constantOptions={$clone(
                                    formulaComponentOptions.get()
                                  )}
                                  onOpen={() =>
                                    formulaComponentOptions.set(
                                      constantToOptions(
                                        constant,
                                        "FORMULA_COMPONENT_AMOUNT_TYPE_OPTIONS",
                                        true
                                      )
                                    )
                                  }
                                  value={value}
                                  onSelected={(newValue) => onChange(newValue)}
                                  muiTextField={{
                                    error: _.has(errors, name),
                                    helperText: _.get(
                                      errors,
                                      `${name}.message`
                                    ),
                                  }}
                                />
                              );
                            }}
                          />
                        </Grid>

                        <Grid item md={5} xs={5}>
                          {_.get(watchFormulaComponents[index], "type.id") ===
                          "PAYROLL_COMPONENT" ? (
                            <Controller
                              control={control}
                              defaultValue={item.value}
                              name={`formula_components[${index}].value`}
                              render={({ value, onChange, name }) => {
                                return (
                                  <MuiAutoComplete
                                    repository={ReimbursementRepository}
                                    params={{
                                      type: "allowance",
                                    }}
                                    methodName="selectPayroll"
                                    value={value}
                                    onSelected={(newValue) =>
                                      onChange(newValue)
                                    }
                                    muiTextField={{
                                      label: "Value",
                                      error: _.has(errors, name),
                                      helperText: _.get(
                                        errors,
                                        `${name}.message`
                                      ),
                                    }}
                                  />
                                );
                              }}
                            />
                          ) : _.get(
                              watchFormulaComponents[index],
                              "type.id"
                            ) === "FORMULA" ? (
                            <Controller
                              name={`formula_components[${index}].value`}
                              control={control}
                              defaultValue={item.value}
                              render={({ value, onChange, name }) => {
                                return (
                                  <MuiAutoComplete
                                    repository={ReimbursementRepository}
                                    params={{
                                      type: "allowance",
                                    }}
                                    methodName="selectFormula"
                                    value={value}
                                    onSelected={(newValue) =>
                                      onChange(newValue)
                                    }
                                    muiTextField={{
                                      label: "Value",
                                      error: _.has(errors, name),
                                      helperText: _.get(
                                        errors,
                                        `${name}.message`
                                      ),
                                    }}
                                  />
                                );
                              }}
                            />
                          ) : _.get(
                              watchFormulaComponents[index],
                              "type.id"
                            ) === "OPERATOR" ? (
                            <Controller
                              name={`formula_components[${index}].value`}
                              control={control}
                              defaultValue={item.value}
                              render={({ value, onChange, name }) => {
                                return (
                                  <MuiAutoComplete
                                    isAsync={false}
                                    constantOptions={formulaOperatorOptions}
                                    value={value}
                                    onSelected={(newValue) =>
                                      onChange(newValue)
                                    }
                                    muiTextField={{
                                      label: "Value",
                                      error: _.has(errors, name),
                                      helperText: _.get(
                                        errors,
                                        `${name}.message`
                                      ),
                                    }}
                                  />
                                );
                              }}
                            />
                          ) : _.get(
                              watchFormulaComponents[index],
                              "type.id"
                            ) === "SYMBOL" ? (
                            <Controller
                              name={`formula_components[${index}].value`}
                              control={control}
                              defaultValue={item.value}
                              render={({ value, onChange, name }) => {
                                return (
                                  <MuiAutoComplete
                                    isAsync={false}
                                    constantOptions={formulaSymbolOptions}
                                    value={value}
                                    onSelected={(newValue) =>
                                      onChange(newValue)
                                    }
                                    muiTextField={{
                                      label: "Value",
                                      error: _.has(errors, name),
                                      helperText: _.get(
                                        errors,
                                        `${name}.message`
                                      ),
                                    }}
                                  />
                                );
                              }}
                            />
                          ) : (
                            <Controller
                              name={`formula_components[${index}].value`}
                              control={control}
                              defaultValue={item.value}
                              render={({ value, onChange, name }) => {
                                return (
                                  <MuiTextField
                                    onChange={onChange}
                                    value={value}
                                    label="Value"
                                    error={_.has(errors, name)}
                                    helperText={_.get(
                                      errors,
                                      `${name}.message`
                                    )}
                                    InputProps={{
                                      inputComponent: NumberFieldFormat,
                                    }}
                                  />
                                );
                              }}
                            />
                          )}
                        </Grid>

                        <Grid item md={2} xs={2}>
                          {componentFields.length != 1 && (
                            <IconButton
                              onClick={() => {
                                componentRemove(index);
                              }}
                            >
                              <DeleteOutlineIcon />
                            </IconButton>
                          )}
                        </Grid>
                      </>
                    );
                  })}
                  <Grid item md={12} xs={12}>
                    <MuiButton
                      label={"Add More"}
                      onClick={() => componentAppend(componentDefaultValues)}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </DialogContentText>
      </DialogContent>

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        marginBottom={2}
      >
        <MuiButton onClick={handleClose} variant="text" label="Cancel" />
        <MuiButton onClick={handleSubmit(onSubmit)} label="Submit" />
      </Box>
    </Dialog>
  );
};

export default React.memo(FormAddReimbursementFormula);
