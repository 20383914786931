import React from "react";
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";
import { IconButton, IconButtonProps } from "@material-ui/core";

export interface MuiIconButtonRoundedProps extends IconButtonProps {}

const MuiIconButtonRounded = withStyles((theme: Theme) =>
  createStyles({
    root: {
      border: `2px solid ${theme.palette.text.primary}`,
      borderRadius: "50%",
      height: 30,
      width: 30,
      padding: theme.spacing(0.5),
      "& svg": {
        fontSize: "1rem !important",
      },

      "& .type-small": {
        height: 25,
        width: 25,
      },
    },
  })
)((props: MuiIconButtonRoundedProps) => <IconButton {...props} />);

export default React.memo(MuiIconButtonRounded);
