import { api } from "../@VodeaUI";

let url = window._env_.REACT_APP_PASSPORT_END_POINT;

const WidgetRepository = {
  annual: function (params: any = null) {
    return api.get(`${url}/api/account/widget/time-off-balance`, {
      params,
    });
  },
  userAnnual: function (params: any = null) {
    return api.get(`${url}/api/widget/time-off-balance`, {
      params,
    });
  },
  attendance: function (params: any = null) {
    return api.get(`${url}/api/account/widget/attendance-policy`, {
      params,
    });
  },
  userAttendance: function (params: any = null) {
    return api.get(`${url}/api/widget/attendance-policy`, {
      params,
    });
  },
  reimbursement: function (params: any = null) {
    return api.get(`${url}/api/account/widget/reimbursement-balance`, {
      params,
    });
  },
  userReimbursement: function (params: any = null) {
    return api.get(`${url}/api/widget/reimbursement-balance`, {
      params,
    });
  },
  historyTimeOff: function (params: any = null) {
    return api.get(`${url}/api/time-off-balance`, {
      params,
    });
  },
};

export default WidgetRepository;
