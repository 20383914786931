import { api } from "../@VodeaUI";

const url = window._env_.REACT_APP_PASSPORT_END_POINT;

const SelectRepository = {
  employmentStatus: function (params: any = null) {
    return api.get(`${url}/api/select/employment-status`, {
      params,
    });
  },
  role: function (params: any = null) {
    return api.get(`${url}/api/select/role`, {
      params,
    });
  },
  department: function (params: any = null) {
    return api.get(`${url}/api/department`, {
      params,
    });
  },
  timeOffPolicy: function (params: any = null) {
    return api.get(`${url}/api/time-off-policy`, {
      params,
    });
  },
  attendancePolicy: function (params: any = null) {
    return api.get(`${url}/api/attendance-policy`, {
      params,
    });
  },
  reimbursementPolicy: function (params: any = null) {
    return api.get(`${url}/api/reimbursement-policy`, {
      params,
    });
  },
  cashAdvancePolicy: function (params: any = null) {
    return api.get(`${url}/api/cash-advance-policy`, {
      params,
    });
  },
  cashAdvanceFormula: function (id: number, params: any = null) {
    return api.get(`${url}/api/select/cash-advance-policy/${id}/formula`, {
      params,
    });
  },
  reimbursementFormula: function (id: number, params: any = null) {
    return api.get(`${url}/api/select/reimbursement-policy/${id}/formula`, {
      params,
    });
  },
  assets: function (params: any = null) {
    return api.get(`${url}/api/select/asset`, {
      params,
    });
  },
};

export default SelectRepository;
