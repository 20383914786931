import { useState } from "@hookstate/core/dist";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import _ from "lodash";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, useParams } from "react-router-dom";
import VuiCardContent from "../../../../../@VodeaUI/components/VuiCardContent";
import { defaultDataTableOption } from "../../../../../utilities/Option";
import TitleForm from "../../../../../components/molecules/TitleForm";
import VuiSelect from "../../../../../@VodeaUI/components/Input/VuiSelect/Index";
import { Options, Column } from "../../../../../@VodeaUI/VuiDataTable/Index";
import { IResource } from "../../../../../interfaces/Resource";

const defaultValues: IResource = {
  code: "",
  name: "",
  description: "",
  building_id: null,
  type: null,
  capacity: 0,
  max_request: 0,
  max_duration: 0,
  resource_availabilities: [],
};

const ResourceForm: React.FC<any> = () => {
  const { id } = useParams();

  const { control, errors, handleSubmit } = useForm({
    mode: "onChange",
  });
  const title = id ? "Add Resource" : "Edit Resource";

  const columns = useState<Column[]>([
    {
      key: "participant",
      label: "Participant",
      render: (row) => {
        return (
          <VuiSelect
            label="Participant"
            state={resourceType}
            options={resourceTypeOptions}
            fullWidth={true}
          />
        );
      },
    },
    {
      key: "id",
      label: "",
      sortable: false,
      render: (row) => {
        return (
          <Box display="flex" justifyContent={"center"}>
            <DeleteOutlineIcon></DeleteOutlineIcon>
          </Box>
        );
      },
    },
  ]);

  const loading = useState(false);
  const resourceType = useState("1");

  const resourceTypeOptions = [
    {
      key: "1",
      label: "Meeting Room A",
    },
    {
      key: "2",
      label: "Meeting Room B",
    },
  ];

  const onSubmit = (formData: any) => {
    loading.set(true);

    console.log("formData", formData);
  };

  return (
    <>
      <TitleForm title={title} withBackUrl={true} />

      <Grid container spacing={3}>
        <Grid item md={8} xs={12}>
          <VuiCardContent title="Information">
            <Box p={2}>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    name="building_id"
                    label="Resource Code"
                    variant="outlined"
                    fullWidth
                    error={_.has(errors, "building_id")}
                    helperText={_.get(errors, "building_id.message")}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    name="building_id"
                    label="Resource Category"
                    variant="outlined"
                    fullWidth
                    error={_.has(errors, "building_id")}
                    helperText={_.get(errors, "building_id.message")}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    name="building_id"
                    label="Resource Type"
                    variant="outlined"
                    fullWidth
                    error={_.has(errors, "building_id")}
                    helperText={_.get(errors, "building_id.message")}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    name="building_id"
                    label="Resource Name"
                    variant="outlined"
                    fullWidth
                    error={_.has(errors, "building_id")}
                    helperText={_.get(errors, "building_id.message")}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    name="building_id"
                    label="Building Name"
                    variant="outlined"
                    fullWidth
                    error={_.has(errors, "building_id")}
                    helperText={_.get(errors, "building_id.message")}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    name="building_id"
                    label="Capacity"
                    variant="outlined"
                    fullWidth
                    error={_.has(errors, "building_id")}
                    helperText={_.get(errors, "building_id.message")}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    name="building_id"
                    label="Description"
                    variant="outlined"
                    fullWidth
                    error={_.has(errors, "building_id")}
                    helperText={_.get(errors, "building_id.message")}
                  />
                </Grid>
              </Grid>
            </Box>
          </VuiCardContent>
        </Grid>
        <Grid item md={4} xs={6}>
          <VuiCardContent title="Availability">
            <Box p={2}>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <TextField
                    name="building_id"
                    label="Day"
                    variant="outlined"
                    fullWidth
                    error={_.has(errors, "building_id")}
                    helperText={_.get(errors, "building_id.message")}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <Controller
                    render={({ value, name, onChange }) => (
                      <TextField
                        id="time"
                        fullWidth
                        label="From"
                        type="time"
                        variant="outlined"
                        defaultValue="07:30"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 300, // 5 min
                        }}
                      />
                    )}
                    name="taxable_date"
                    control={control}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <Controller
                    render={({ value, name, onChange }) => (
                      <TextField
                        id="time"
                        fullWidth
                        label="To"
                        type="time"
                        variant="outlined"
                        defaultValue="07:30"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 300, // 5 min
                        }}
                      />
                    )}
                    name="taxable_date"
                    control={control}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    name="building_id"
                    label="Duration"
                    variant="outlined"
                    fullWidth
                    error={_.has(errors, "building_id")}
                    helperText={_.get(errors, "building_id.message")}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    name="building_id"
                    label="Max Request"
                    variant="outlined"
                    fullWidth
                    error={_.has(errors, "building_id")}
                    helperText={_.get(errors, "building_id.message")}
                  />
                </Grid>
              </Grid>
            </Box>
          </VuiCardContent>
        </Grid>
        <Grid item md={12} xs={12}>
          <Box marginTop={1}>
            <Paper>
              <Box
                p={2}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box>
                  <Typography variant="subtitle1">Submit Resource ?</Typography>
                </Box>
                <Box>
                  <Box display="flex" alignItems="center">
                    <Box marginRight={2}>
                      <Button variant="text" color="primary">
                        Cancel
                      </Button>
                    </Box>
                    <Box>
                      <Button variant="contained" color="primary">
                        Submit
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Paper>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default ResourceForm;
