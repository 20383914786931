import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import { SnackbarProvider } from "notistack";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { useSelector } from "react-redux";
import Loading from "../../components/Loading";
import { theme } from "./style";
import { usePassportService } from "../../utilities/hooks";

const LayoutApps: React.FC<any> = ({ disableRedirect }) => {
  const { isOnFetchingUser } = usePassportService();
  const { isLogged } = useSelector(({ vodea }: any) => {
    return {
      isLogged: vodea.auth.isLogged,
    };
  });

  if (isOnFetchingUser) {
    return <Loading />;
  }

  //console.log(isLogged);

  if (isLogged && !disableRedirect) {
    return <Navigate to={"/apps/home"} />;
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <SnackbarProvider
          maxSnack={3}
          autoHideDuration={2500}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <Outlet />
        </SnackbarProvider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default LayoutApps;
