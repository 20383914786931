import React, { useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { VuiSearch } from "../../../../@VodeaUI/components";
import {
  Add as AddIcon,
  BlockOutlined,
  CheckCircleOutlineOutlined,
  EditOutlined as EditOutlinedIcon,
} from "@material-ui/icons";
import { $clone } from "../../../../utilities/helpers/global";
import { useState } from "@hookstate/core/dist";
import VuiDataTable, {
  Column,
  Options,
} from "../../../../@VodeaUI/VuiDataTable/Index";
import { defaultDataTableOption } from "../../../../utilities/Option";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import VuiSelect from "../../../../@VodeaUI/components/Input/VuiSelect/Index";

const ReimbursementTimeOff: React.FC<any> = () => {
  const columns = useState<Column[]>([
    { key: "name", label: "Name" },
    { key: "from", label: "Request Date" },
    { key: "to", label: "Category" },
    { key: "to", label: "Start Date" },
    { key: "to", label: "Finish Date" },
    { key: "to", label: "Number Of Days" },
    {
      key: "id",
      label: "",
      sortable: false,
      render: (row) => {
        return (
          <Box display="flex" justifyContent={"flex-end"}>
            <IconButton aria-label="edit">
              <EditOutlinedIcon fontSize="small" />
            </IconButton>

            {row.can_suspend ? (
              <Tooltip title="Suspend">
                <IconButton aria-label="suspend">
                  <BlockOutlined fontSize="small" />
                </IconButton>
              </Tooltip>
            ) : null}

            {row.can_unsuspend ? (
              <Tooltip title="Unsuspend">
                <IconButton aria-label="unsuspend">
                  <CheckCircleOutlineOutlined fontSize="small" />
                </IconButton>
              </Tooltip>
            ) : null}
          </Box>
        );
      },
    },
  ]);

  const orderBy = useState("all");
  const loading = useState(false);
  const options = useState<Options>({
    ...defaultDataTableOption,
    sortedBy: "desc",
    orderBy: "date",
  });
  const data = useState([]);
  const objParams = useParams();

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = () => {
    loading.set(false);

    if (objParams.id !== undefined) {
    }
  };

  const orderByOptions = [
    {
      key: "all",
      label: "All",
    },
    {
      key: "a-z",
      label: "A-Z",
    },
  ];

  return (
    <>
      <Grid container spacing={3} justifyContent="space-between">
        <Grid item>
          <Typography variant={"h5"}>Reimbursement Time Off</Typography>
        </Grid>
        <Grid item>
          <Link
            to={
              "/apps/time/reimbursement-time-off/reimbursement-time-off-request"
            }
          >
            <Button variant="contained" color="primary">
              Request New
            </Button>
          </Link>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper variant="elevation" elevation={1}>
            <Box p={2}>
              <Grid
                container
                spacing={3}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Grid item xs={4} md={4}>
                  <VuiSearch
                    state={options.search}
                    callback={() => {
                      options.page.set(0);
                      loadData();
                    }}
                  />
                </Grid>
                <Grid item>
                  <Grid container spacing={3}>
                    <Grid item xs={6} md={4}>
                      <VuiSelect
                        label="Month"
                        state={orderBy}
                        options={orderByOptions}
                      />
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <VuiSelect
                        label="Year"
                        state={orderBy}
                        options={orderByOptions}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <VuiSelect
                        label="Category"
                        state={orderBy}
                        options={orderByOptions}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>

            <VuiDataTable
              loading={loading.get()}
              columns={columns.value}
              options={$clone(options.value)}
              data={data.value}
              onChangeOptions={(values: Options) => {
                options.set(values);
                loadData();
              }}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default ReimbursementTimeOff;
