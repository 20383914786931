import _ from "lodash";
import { Grid, Typography } from "@material-ui/core";
import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  errorText: {
    color: "#f44336",
  },
});

interface IErrorText {
  field: any;
  errors: any;
  fieldName: string;
}

const ErrorText: FC<IErrorText> = ({ field, errors, fieldName = "" }) => {
  const classes = useStyles();

  return _.isArray(field) ? (
    field.length < 1 && _.has(errors, fieldName) ? (
      <Grid item md={12} xs={12}>
        <Typography className={classes.errorText} variant="body2">
          {_.get(errors, `${fieldName}.message`)}
        </Typography>
      </Grid>
    ) : null
  ) : _.has(errors, fieldName) ? (
    <Grid item md={12} xs={12}>
      <Typography className={classes.errorText} variant="body2">
        {_.get(errors, `${fieldName}.message`)}
      </Typography>
    </Grid>
  ) : null;
};

export default React.memo(ErrorText);
