import React, { useEffect } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { Controller, useForm } from "react-hook-form";
import _ from "lodash";
import { mapHookErrors } from "../../../../../../utilities/helpers/global";
import {
  optionLabel,
  optionSelected,
} from "../../../../../../utilities/helpers/select";
import { useState } from "@hookstate/core/dist";
import { useNavigate, useParams } from "react-router";
import People from "../../../../../../layouts/Apps/People";
import VuiCardContent from "../../../../../../@VodeaUI/components/VuiCardContent";
import FooterFormAction from "../../../../../../components/FooterFormAction";
import useIsMounted from "../../../../../../@VodeaUI/hooks/useIsMounted";
import { useSnackbar } from "notistack";
import UserFamilyRepository from "../../../../../../repositories/UserFamilyRepository";
import { formatFormData } from "../../../../../../utilities/helpers/form";
import { constantToOptions } from "../../../../../../utilities/helpers/option.helper";
import { useSelector } from "react-redux";
import moment from "moment";

interface FamilyDataInterface {
  name: string;
  mobile_phone: string;
  email: string;
  gender: any;
  religion: any;
  relationship: any;
  date_of_birth: any;
  blood_type: any;
  place_of_birth: string;
  job: string;
  is_same_address: any;
  address: string;
  city: string;
  postal_code: any;
}

const FamilyDataForm: React.FC<any> = () => {
  const navigate = useNavigate();
  const loading = useState(false);
  const { id } = useParams();
  const { constant, user } = useSelector(({ constant, vodea }: any) => {
    return {
      constant: constant.constant.payload,
      user: vodea.auth.user,
    };
  });
  const isMounted = useIsMounted();
  const { enqueueSnackbar } = useSnackbar();
  const relationshipOptions = constantToOptions(
    constant,
    "RELATIONSHIP_TYPE_OPTIONS"
  );
  const religionOptions = constantToOptions(constant, "RELIGIONS");
  const bloodTypeOptions = constantToOptions(constant, "BLOOD_TYPE_OPTIONS");

  const { control, handleSubmit, errors, setError, setValue, watch } =
    useForm<FamilyDataInterface>({
      mode: "onChange",
      defaultValues: {
        name: "",
        mobile_phone: "",
        email: "",
        gender: "MALE",
        religion: null,
        relationship: null,
        date_of_birth: new Date(),
        blood_type: null,
        place_of_birth: "",
        job: "",
        is_same_address: false,
        postal_code: "",
        city: "",
        address: "",
      },
    });

  const watchIsSameAddress = watch("is_same_address");

  useEffect(() => {
    if (id) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (data: any) => {
    loading.set(true);
    const body = formatFormData(data);
    if (typeof body.relationship !== "string") {
      body.relationship = body.relationship.id;
    }

    body.user_id = user.id;

    if (typeof body.religion !== "string") {
      body.religion = body.religion.id;
    }

    if (typeof body.blood_type !== "string") {
      body.blood_type = body.blood_type.id;
    }

    if (body.date_of_birth) {
      body.date_of_birth = moment(body.date_of_birth).format("YYYY-MM-DD");
    }

    if (body.is_same_address) {
      body.address = _.get(user, "user_detail.address");
      body.postal_code = _.get(user, "user_detail.postal_code");
      body.city = _.get(user, "user_detail.city");
    }

    (id
      ? UserFamilyRepository.update(id, { ...body })
      : UserFamilyRepository.create({ ...body })
    )
      .then((response: any) => {
        if (isMounted.current) {
          enqueueSnackbar(response.data.message, {
            variant: "success",
          });
          loading.set(false);
          navigate("/apps/people/personal-information/family-data");
        }
      })
      .catch((err: any) => {
        if (isMounted.current && err?.response?.data?.errors) {
          const errors = mapHookErrors(err.response.data.errors);
          Object.keys(errors).forEach((key: any) => setError(key, errors[key]));

          enqueueSnackbar(err.response.data.message, {
            variant: "error",
          });
          loading.set(false);
        }
      });
  };

  const loadData = () => {
    UserFamilyRepository.show(id)
      .then((resp: any) => {
        const data = resp.data.data;
        _.forEach(data, (value, name) => {
          if (name === "relationship") {
            setValue(
              name,
              relationshipOptions.find((item: any) => item.id === value)
            );
          } else if (name === "blood_type") {
            setValue(
              name,
              bloodTypeOptions.find((item: any) => item.id === value)
            );
          } else if (name === "religion") {
            setValue(
              name,
              religionOptions.find((item: any) => item.id === value)
            );
          } else {
            setValue(name, value);
          }
        });
        if (
          _.get(data, "address") === _.get(user, "user_detail.address") &&
          _.get(data, "postal_code") ===
            _.get(user, "user_detail.postal_code") &&
          _.get(data, "city") === _.get(user, "user_detail.city")
        ) {
          setValue("is_same_address", true);
        }
      })
      .catch((err: any) => {});
  };

  return (
    <>
      <People>
        <div>
          <Grid item xs={12}>
            <VuiCardContent title="Family Data">
              <Box p={2}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Controller
                      render={({ value, name, onChange }) => (
                        <TextField
                          name={name}
                          label="Name"
                          variant="outlined"
                          onChange={onChange}
                          value={value}
                          fullWidth
                          error={_.has(errors, "name")}
                          helperText={_.get(errors, "name.message")}
                        />
                      )}
                      name={`name`}
                      control={control}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      render={({ value, name, onChange }) => (
                        <TextField
                          name={name}
                          label="Phone"
                          variant="outlined"
                          onChange={onChange}
                          value={value}
                          fullWidth
                          error={_.has(errors, "mobile_phone")}
                          helperText={_.get(errors, "mobile_phone.message")}
                        />
                      )}
                      name={`mobile_phone`}
                      control={control}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      render={({ value, name, onChange }) => (
                        <TextField
                          name={name}
                          label="Email"
                          variant="outlined"
                          onChange={onChange}
                          value={value}
                          fullWidth
                          error={_.has(errors, "email")}
                          helperText={_.get(errors, "email.message")}
                        />
                      )}
                      name={`email`}
                      control={control}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Controller
                      render={({ value, name, onChange }) => (
                        <Autocomplete
                          value={value}
                          loading={false}
                          options={relationshipOptions}
                          // onOpen={option.unitOfMeasure.onOpen.get()}
                          getOptionLabel={(option: any) => optionLabel(option)}
                          getOptionSelected={(option: any, value: any) =>
                            optionSelected(option, value)
                          }
                          onChange={(event: any, newValue: any) => {
                            onChange(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name={name}
                              label="Relationship"
                              variant="outlined"
                              error={_.has(errors, `relationship`)}
                              helperText={_.get(errors, `relationship.message`)}
                            />
                          )}
                        />
                      )}
                      name={`relationship`}
                      control={control}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Controller
                      render={({ value, name, onChange }) => (
                        <Autocomplete
                          value={value}
                          loading={false}
                          options={religionOptions}
                          // onOpen={option.unitOfMeasure.onOpen.get()}
                          getOptionLabel={(option: any) => optionLabel(option)}
                          getOptionSelected={(option: any, value: any) =>
                            optionSelected(option, value)
                          }
                          onChange={(event: any, newValue: any) => {
                            onChange(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name={name}
                              label="Religion"
                              variant="outlined"
                              error={_.has(errors, `religion`)}
                              helperText={_.get(errors, `religion.message`)}
                            />
                          )}
                        />
                      )}
                      name={`religion`}
                      control={control}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography variant="body2">Gender</Typography>
                    <Controller
                      render={({ value, name, onChange }) => (
                        <RadioGroup
                          row
                          onChange={(event, newValue: any) =>
                            onChange(newValue)
                          }
                          value={value}
                        >
                          <FormControlLabel
                            label="Male"
                            value={"MALE"}
                            name={name}
                            checked={value === "MALE"}
                            control={<Radio color="primary" />}
                          />
                          <FormControlLabel
                            label="Female"
                            name={name}
                            value={"FEMALE"}
                            checked={value === "FEMALE"}
                            control={<Radio color="primary" />}
                          />
                        </RadioGroup>
                      )}
                      name={"gender"}
                      control={control}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Controller
                      render={({ value, name, onChange }) => (
                        <KeyboardDatePicker
                          name={name}
                          disableToolbar
                          fullWidth
                          variant="inline"
                          minDate={new Date("2020-01-01")}
                          inputVariant="outlined"
                          label="Date of Birth"
                          format="DD MMMM YYYY"
                          error={_.has(errors, "date_of_birth")}
                          helperText={_.get(errors, "date_of_birth.message")}
                          value={value}
                          onChange={onChange}
                        />
                      )}
                      name="date_of_birth"
                      control={control}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Controller
                      render={({ value, name, onChange }) => (
                        <TextField
                          name={name}
                          label="Place of Birth"
                          variant="outlined"
                          onChange={onChange}
                          value={value}
                          fullWidth
                          error={_.has(errors, "place_of_birth")}
                          helperText={_.get(errors, "place_of_birth.message")}
                        />
                      )}
                      name={`place_of_birth`}
                      control={control}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Controller
                      render={({ value, name, onChange }) => (
                        <Autocomplete
                          value={value}
                          loading={false}
                          options={bloodTypeOptions}
                          // onOpen={option.unitOfMeasure.onOpen.get()}
                          getOptionLabel={(option: any) => optionLabel(option)}
                          getOptionSelected={(option: any, value: any) =>
                            optionSelected(option, value)
                          }
                          onChange={(event: any, newValue: any) => {
                            onChange(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name={name}
                              label="Blood Type"
                              variant="outlined"
                              error={_.has(errors, `blood_type`)}
                              helperText={_.get(errors, `blood_type.message`)}
                            />
                          )}
                        />
                      )}
                      name={`blood_type`}
                      control={control}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      render={({ value, name, onChange }) => (
                        <TextField
                          name={name}
                          label="Job"
                          variant="outlined"
                          onChange={onChange}
                          value={value}
                          fullWidth
                          error={_.has(errors, "job")}
                          helperText={_.get(errors, "job.message")}
                        />
                      )}
                      name={`job`}
                      control={control}
                    />
                  </Grid>
                </Grid>
              </Box>
            </VuiCardContent>
            <Box mt={3}>
              <VuiCardContent title="Address">
                <Box p={2}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Controller
                        render={({ value, name, onChange }) => (
                          <FormControlLabel
                            label="Same with my address"
                            control={
                              <Checkbox
                                checked={value}
                                onChange={(e: any) =>
                                  onChange(e.target.checked)
                                }
                                name={name}
                                color="primary"
                              />
                            }
                          />
                        )}
                        name="is_same_address"
                        control={control}
                      />
                    </Grid>

                    {!watchIsSameAddress && (
                      <Grid item xs={12}>
                        <Controller
                          render={({ value, name, onChange }) => (
                            <TextField
                              name={name}
                              label="Address"
                              variant="outlined"
                              onChange={onChange}
                              value={value}
                              fullWidth
                              error={_.has(errors, "address")}
                              helperText={_.get(errors, "address.message")}
                            />
                          )}
                          name={`address`}
                          control={control}
                        />
                      </Grid>
                    )}
                    {!watchIsSameAddress && (
                      <Grid item xs={12} md={6}>
                        <Controller
                          render={({ value, name, onChange }) => (
                            <TextField
                              name={name}
                              label="City"
                              variant="outlined"
                              onChange={onChange}
                              value={value}
                              fullWidth
                              error={_.has(errors, "city")}
                              helperText={_.get(errors, "city.message")}
                            />
                          )}
                          name={`city`}
                          control={control}
                        />
                      </Grid>
                    )}

                    {!watchIsSameAddress && (
                      <Grid item xs={12} md={6}>
                        <Controller
                          render={({ value, name, onChange }) => (
                            <TextField
                              name={name}
                              label="Postal Code"
                              variant="outlined"
                              onChange={onChange}
                              value={value}
                              fullWidth
                              error={_.has(errors, "postal_code")}
                              helperText={_.get(errors, "postal_code.message")}
                            />
                          )}
                          name={`postal_code`}
                          control={control}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Box>
              </VuiCardContent>
            </Box>
          </Grid>
        </div>
      </People>
      <Box mt={3}>
        <FooterFormAction
          labelForm="Save Family Data"
          onCancel={() => navigate(-1)}
          loading={loading.get()}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
        />
      </Box>
    </>
  );
};

export default FamilyDataForm;
