import React, { useEffect } from "react";
import PreferencesCompany from "../../../../../layouts/Apps/Preference/Company";
import { Box, Divider, Grid, TextField } from "@material-ui/core";
import VuiCardContent from "../../../../../@VodeaUI/components/VuiCardContent";
import _ from "lodash";
import { Controller, useForm } from "react-hook-form";
import TitleForm from "../../../../../components/molecules/TitleForm";
import FooterFormAction from "../../../../../components/FooterFormAction";
import { useState } from "@hookstate/core";
import useIsMounted from "../../../../../@VodeaUI/hooks/useIsMounted";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router";
import { useSnackbar } from "notistack";
import BuildingRepository from "../../../../../repositories/BuildingRepository";
import { mapHookErrors } from "../../../../../utilities/helpers/global";
import { AxiosError, AxiosResponse } from "axios";

const BuildingForm: React.FC<any> = () => {
  const { errors, handleSubmit, control, setValue, setError } = useForm({
    mode: "onChange",
  });

  const { id } = useParams();
  const navigate = useNavigate();
  const isMounted = useIsMounted();
  const loading = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = (formData: any) => {
    loading.set(true);

    if (id) {
      BuildingRepository.update(id, { ...formData })
        .then((resp: any) => {
          navigate("/apps/setting/company/building");
          enqueueSnackbar(resp.data.message, {
            variant: "success",
          });
        })
        .catch((e: any) => {
          if (e?.response?.data?.errors) {
            const errors = mapHookErrors(e.response.data.errors);
            Object.keys(errors).forEach((key: any) =>
              setError(key, errors[key])
            );

            enqueueSnackbar(e.response.data.message, {
              variant: "error",
            });
          }

          if (isMounted.current) {
            loading.set(false);
          }
        });
    } else {
      BuildingRepository.create({ ...formData })
        .then((resp: any) => {
          navigate("/apps/setting/company/building");
          enqueueSnackbar(resp.data.message, {
            variant: "success",
          });
        })
        .catch((e: any) => {
          if (e?.response?.data?.errors) {
            const errors = mapHookErrors(e.response.data.errors);
            Object.keys(errors).forEach((key: any) =>
              setError(key, errors[key])
            );

            enqueueSnackbar(e.response.data.message, {
              variant: "error",
            });
          }

          if (isMounted.current) {
            loading.set(false);
          }
        });
    }
  };

  const loadData = () => {
    BuildingRepository.show(id)
      .then((response: AxiosResponse) => {
        const { data: responseData } = response;
        const { data } = responseData;
        _.forEach(data, (value, name) => {
          setValue(name, value);
        });
      })
      .catch((err: AxiosError) => {});
  };

  useEffect(() => {
    if (id) {
      loadData();
    }
  }, []);

  return (
    <PreferencesCompany>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <VuiCardContent>
            <Box ml={2}>
              <TitleForm
                title={id ? "Edit Building" : "Add Building"}
                withBackUrl={true}
              />
            </Box>
            <Divider />
            <Box p={2}>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <Controller
                    render={({ value, name, onChange }) => {
                      return (
                        <TextField
                          name={name}
                          onChange={onChange}
                          value={value || ""}
                          label="Building ID"
                          variant="outlined"
                          fullWidth
                          error={_.has(errors, "code")}
                          helperText={_.get(errors, "code.message")}
                        />
                      );
                    }}
                    name={"code"}
                    control={control}
                    defaultValue={""}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Controller
                    render={({ value, name, onChange }) => {
                      return (
                        <TextField
                          name={name}
                          onChange={onChange}
                          value={value || ""}
                          label="Building Name"
                          variant="outlined"
                          fullWidth
                          error={_.has(errors, "name")}
                          helperText={_.get(errors, "name.message")}
                        />
                      );
                    }}
                    name={"name"}
                    control={control}
                    defaultValue={""}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <Controller
                    render={({ value, name, onChange }) => {
                      return (
                        <TextField
                          name={name}
                          onChange={onChange}
                          value={value || ""}
                          label="Description"
                          variant="outlined"
                          fullWidth
                          error={_.has(errors, "description")}
                          helperText={_.get(errors, "description.message")}
                        />
                      );
                    }}
                    name={"description"}
                    control={control}
                    defaultValue={""}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Controller
                    render={({ onChange, name, value }) => {
                      return (
                        <TextField
                          name={name}
                          label="Telephone"
                          variant="outlined"
                          value={value || ""}
                          onChange={onChange}
                          fullWidth
                          error={_.has(errors, "telephone")}
                          helperText={_.get(errors, "telephone.message")}
                        />
                      );
                    }}
                    name="telephone"
                    control={control}
                    defaultValue={""}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Controller
                    render={({ onChange, name, value }) => {
                      return (
                        <TextField
                          name={name}
                          label="Fax"
                          variant="outlined"
                          value={value || ""}
                          onChange={onChange}
                          type={"number"}
                          fullWidth
                          error={_.has(errors, "fax")}
                          helperText={_.get(errors, "fax.message")}
                        />
                      );
                    }}
                    name="fax"
                    control={control}
                    defaultValue={""}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <Controller
                    render={({ value, name, onChange }) => {
                      return (
                        <TextField
                          name={name}
                          onChange={onChange}
                          value={value || ""}
                          label="Address"
                          variant="outlined"
                          fullWidth
                          error={_.has(errors, "address")}
                          helperText={_.get(errors, "address.message")}
                        />
                      );
                    }}
                    name={"address"}
                    control={control}
                    defaultValue={""}
                  />
                </Grid>
              </Grid>
            </Box>
          </VuiCardContent>
        </Grid>
        <FooterFormAction
          cancelUrl={`/apps/setting/company/building`}
          loading={loading.get()}
          onSubmit={onSubmit}
          handleSubmit={handleSubmit}
          labelForm={"Save Policy"}
        />
      </Grid>
    </PreferencesCompany>
  );
};

export default BuildingForm;
