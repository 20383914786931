import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import _ from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useMemo } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import VuiCardContent from "../../../../../@VodeaUI/components/VuiCardContent";
import TitleForm from "../../../../../components/molecules/TitleForm";
import UserRepository from "../../../../../repositories/UserRepository";
import { yupResolver } from "@hookform/resolvers/yup";
import ReimbursementRepository from "../../../../../repositories/ReimbursementRepository";
import CancelIcon from "@material-ui/icons/Cancel";
import MediaRepository from "../../../../../repositories/MediaRepositories";
import { AxiosError, AxiosResponse } from "axios";
import { useIsMounted } from "../../../../../@VodeaUI";
import { makeStyles } from "@material-ui/core/styles";
import FooterFormAction from "../../../../../components/FooterFormAction";
import { formatFormData } from "../../../../../utilities/helpers/form";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import VuiNumberFormat from "../../../../../@VodeaUI/components/Input/VuiNumberFormat";
import { IReimbursementRequest } from "../../../../../interfaces/ReimbursementRequest";
import { useSelector } from "react-redux";
import {
  ErrorText,
  MuiAutoComplete,
  MuiButton,
  MuiDatePicker,
  MuiTextField,
  NumberFieldFormat,
} from "../../../../../components/atoms";
import { useTranslation } from "react-i18next";
import { handleAxiosErrorSave } from "../../../../../utilities/helpers/axios-error.helper";
import Date from "../../../../../components/atoms/Date";
import { IReimbursementRequestItem } from "../../../../../interfaces/ReimbursementRequestItem";
import { ICashAdvanceFormulaPolicy } from "../../../../../interfaces/CashAdvanceFormulaPolicy";
import { validationSchema } from "./validation";

const useStyles = makeStyles({
  tableHead: {
    minWidth: "10rem",
  },
  tableCell: {
    paddingBottom: "1rem",
  },
  fileAttachment: {
    display: "flex",
    alignItems: "center",
  },
  fileAttachmentName: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    width: 100,
  },
  icon: {
    padding: 8,
    marginRight: 8,
    marginLeft: 8,
  },
});

const categoryBaseModel = {
  reimbursement_formula_policy_id: null,
  amount: 0,
  balance: 0,
  description: "",
  attachment_id: null,
};

const defaultValue: IReimbursementRequest = {
  user_id: null,
  reimbursement_policy_id: null,
  description: "",
  reimbursement_request_items: [],
  date: moment(),
};

const RequestReimbursement: React.FC<any> = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const isMounted = useIsMounted();
  const { user } = useSelector(({ vodea }: any) => {
    return {
      user: vodea.auth.user,
    };
  });
  const classes = useStyles();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [isFetchingData, setIsFetchingData] = React.useState<boolean>(false);
  const [data, setData] = React.useState<IReimbursementRequest>(defaultValue);
  const loadData = useCallback(async () => {
    if (!id) return;
    if (isMounted.current) setIsFetchingData(true);

    let params: any = {};
    Object.assign(params, {
      with: [
        "approvalLogs.role",
        "approvalLogs.approver",
        "reimbursementItems.asset.reimbursementItems.reimbursement.user",
        "reimbursementRequestItems.attachment",
        "user",
      ],
    });

    await ReimbursementRepository.showData(id, params)
      .then((response: any) => {
        const { data: responseData } = response.data;
        if (isMounted.current) {
          setData(responseData);
        }
      })
      .catch((error: any) => {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      });
  }, []);

  const {
    control,
    errors,
    handleSubmit,
    setValue,
    watch,
    getValues,
    setError,
  } = useForm<IReimbursementRequest>({
    mode: "onChange",
    defaultValues: useMemo(() => {
      (async () => {
        await loadData();
      })();
      return data;
    }, [id]),
    resolver: yupResolver(validationSchema()),
  });

  const {
    fields: categoryFields,
    remove: categoryRemove,
    append: categoryAppend,
  } = useFieldArray({
    keyName: "key",
    control,
    name: "reimbursement_request_items",
  });

  const handleAddItem = () => categoryAppend(categoryBaseModel);
  const watchDescription = watch("description");
  const watchUser = watch("user_id");
  const watchRequestItems = watch("reimbursement_request_items");
  const watchPolicy = watch("reimbursement_policy_id");

  useEffect(() => {
    if (watchPolicy && watchRequestItems.length == 0) {
      handleAddItem();
    }
  }, [watchRequestItems.length, watchPolicy]);

  useMemo(() => {
    if (user && !id) {
      setTimeout(() => {
        setValue("user_id", user);
      }, 200);
    }
  }, [user]);

  const onSubmit = async (data: IReimbursementRequest) => {
    if (isMounted.current) setLoading(true);
    const formData = formatFormData(data);

    const cloneItems =
      formData.reimbursement_request_items.length > 1
        ? formData.reimbursement_request_items.filter(
            (item: IReimbursementRequestItem) =>
              item.reimbursement_formula_policy_id
          )
        : formData.reimbursement_request_items;

    formData.reimbursement_request_items = cloneItems.map(
      (item: IReimbursementRequestItem) => ({
        amount: item?.amount,
        description: item?.description,
        reimbursement_formula_policy_id:
          item.reimbursement_formula_policy_id?.id,
        attachment_id:
          typeof item.attachment_id === "number"
            ? item.attachment_id
            : item.attachment_id?.id,
      })
    );

    await ReimbursementRepository.create(formData)
      .then((response: AxiosResponse) => {
        if (isMounted.current) setLoading(false);
        enqueueSnackbar(response.data.message, {
          variant: "success",
        });
        navigate("/apps/finance/reimbursement");
      })
      .catch((error: AxiosError) => {
        if (isMounted.current) setLoading(false);
        handleAxiosErrorSave(error, setError, enqueueSnackbar);
      });
  };

  const handleValueBalanceBaseOnCategory = async (
    formulaId: any,
    index: number
  ) => {
    await ReimbursementRepository.getBalance({
      user: watchUser?.id,
      "reimbursement-formula-policy": formulaId,
    }).then((response: AxiosResponse) => {
      const data = response.data.data;
      let totalBalance;

      const selectedFormula = getValues(
        `reimbursement_request_items[${index}].reimbursement_formula_policy_id`
      );

      if (_.get(selectedFormula, "is_unlimited")) {
        setValue(`reimbursement_request_items[${index}].balance`, "∞");
      } else if (data.length > 1) {
        totalBalance = data.reduce(
          (acc: any, curr: any) => acc + (curr["balance"] || 0),
          0
        );
      } else {
        totalBalance = data[0]?.balance;
      }

      setValue(`reimbursement_request_items[${index}].balance`, totalBalance);
    });
  };

  const handleUploadFile = (index: any, files: any) => {
    let formData = new FormData();
    formData.append("file", files[0]);
    formData.append("path", "reimbursement-request");
    formData.append("disk", "gcs");

    MediaRepository.create(formData)
      .then((response: AxiosResponse) => {
        setValue(
          `reimbursement_request_items[${index}].attachment_id`,
          response.data.data
        );
      })
      .catch((error: AxiosError) => {
        setValue(`reimbursement_request_items[${index}].attachment_id`, null);
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      });
  };

  const handleClickAttach = () => {
    const domAttachment = document.getElementById("raised-button-file");
    if (domAttachment) domAttachment.click();
  };

  const calculateTotalAmountItem = (
    items: Array<IReimbursementRequestItem> = []
  ) => {
    let totalAmount: number = 0;
    if (items.length > 0) {
      items.map(
        (item) =>
          (totalAmount +=
            typeof item.amount === "string"
              ? isNaN(parseFloat(item.amount))
                ? 0
                : parseFloat(item.amount)
              : item.amount)
      );
    }
    return totalAmount;
  };

  const handleAddCategory = (
    value: ICashAdvanceFormulaPolicy,
    index: number
  ) => {
    if (value && index === categoryFields.length - 1) {
      handleAddItem();
    }
  };

  return (
    <>
      <TitleForm title="Request Reimbursement" withBackUrl={true} />

      <Grid container spacing={3}>
        <Grid item md={8} xs={12}>
          <VuiCardContent title="Information">
            <Box p={2}>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <Controller
                    render={({ value, name, onChange }) => (
                      <MuiAutoComplete
                        repository={UserRepository}
                        params={{
                          for: "reimbursement",
                        }}
                        value={value}
                        onSelected={(newValue) => {
                          onChange(newValue);
                          setValue("reimbursement_policy_id", null);
                          setValue("reimbursement_request_items", []);
                        }}
                        muiTextField={{
                          label: t("form.employee.label"),
                          error: _.has(errors, name),
                          helperText: _.get(errors, `${name}.message`),
                        }}
                      />
                    )}
                    name="user_id"
                    control={control}
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <Controller
                    render={({ value, name, onChange }) => (
                      <MuiAutoComplete
                        repository={ReimbursementRepository}
                        params={{
                          for: "reimbursement",
                          user: watchUser?.id,
                        }}
                        methodName="getCategory"
                        value={value}
                        onSelected={(newValue) => {
                          onChange(newValue);
                          setValue("reimbursement_request_items", []);
                        }}
                        muiTextField={{
                          label: t("form.reimbursementPolicy.label"),
                          error: _.has(errors, name),
                          helperText: _.get(errors, `${name}.message`),
                        }}
                      />
                    )}
                    name="reimbursement_policy_id"
                    control={control}
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <Controller
                    render={({ value, name, onChange }) => (
                      <MuiDatePicker
                        value={value}
                        onChange={onChange}
                        label={t("form.usedDate.label")}
                        error={_.has(errors, name)}
                        helperText={_.get(errors, `${name}.message`)}
                      />
                    )}
                    name="date"
                    control={control}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <Controller
                    render={({ value, name, onChange }) => (
                      <MuiTextField
                        name={name}
                        onChange={onChange}
                        label={t("form.purpose.label")}
                        value={value}
                        error={_.has(errors, name)}
                        helperText={_.get(errors, `${name}.message`)}
                      />
                    )}
                    name="description"
                    control={control}
                  />
                </Grid>
              </Grid>
            </Box>
          </VuiCardContent>

          {watchPolicy && (
            <Box mt={3}>
              <VuiCardContent title="Detail">
                {watchRequestItems.length > 0 && (
                  <Box mb={2}>
                    <TableContainer>
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell className={classes.tableHead}>
                              Category
                            </TableCell>
                            <TableCell className={classes.tableHead}>
                              Balance
                            </TableCell>
                            <TableCell className={classes.tableHead}>
                              Description
                            </TableCell>
                            <TableCell className={classes.tableHead}>
                              Amount (IDR)
                            </TableCell>
                            <TableCell>File</TableCell>
                            <TableCell />
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {categoryFields.map((item, index) => {
                            return (
                              <TableRow key={item.key}>
                                <TableCell className={classes.tableCell}>
                                  <Controller
                                    render={({ value, name, onChange }) => (
                                      <MuiAutoComplete
                                        repository={ReimbursementRepository}
                                        params={{
                                          for: "reimbursement",
                                          user: watchUser?.id,
                                        }}
                                        paramsId={watchPolicy?.id}
                                        methodName="getFormula"
                                        value={value}
                                        onSelected={(newValue) => {
                                          onChange(newValue);
                                          handleValueBalanceBaseOnCategory(
                                            newValue.id,
                                            index
                                          );
                                          handleAddCategory(newValue, index);
                                        }}
                                        muiTextField={{
                                          label: t("form.category.label"),
                                          error: _.has(errors, name),
                                          helperText: _.get(
                                            errors,
                                            `${name}.message`
                                          ),
                                        }}
                                      />
                                    )}
                                    defaultValue={
                                      item.reimbursement_formula_policy_id
                                    }
                                    name={`reimbursement_request_items[${index}].reimbursement_formula_policy_id`}
                                    control={control}
                                  />
                                </TableCell>

                                <TableCell className={classes.tableCell}>
                                  <Controller
                                    render={({ value }) => {
                                      return value === "∞" ? (
                                        <Typography variant="body2">
                                          {value}
                                        </Typography>
                                      ) : (
                                        <VuiNumberFormat
                                          data={value}
                                          value={value}
                                        />
                                      );
                                    }}
                                    control={control}
                                    name={`reimbursement_request_items[${index}].balance`}
                                    defaultValue={item.balance}
                                  />
                                </TableCell>

                                <TableCell className={classes.tableCell}>
                                  <Controller
                                    render={({ value, name, onChange }) => (
                                      <MuiTextField
                                        name={name}
                                        onChange={onChange}
                                        label={t("form.description.label")}
                                        value={value}
                                        error={_.has(errors, name)}
                                        helperText={_.get(
                                          errors,
                                          `${name}.message`
                                        )}
                                      />
                                    )}
                                    control={control}
                                    name={`reimbursement_request_items[${index}].description`}
                                    defaultValue={item.description}
                                  />
                                </TableCell>

                                <TableCell className={classes.tableCell}>
                                  <Controller
                                    render={({ value, name, onChange }) => (
                                      <MuiTextField
                                        onChange={onChange}
                                        value={value}
                                        label={t("form.amount.label")}
                                        error={_.has(errors, name)}
                                        helperText={_.get(
                                          errors,
                                          `${name}.message`
                                        )}
                                        InputProps={{
                                          inputComponent: NumberFieldFormat,
                                        }}
                                      />
                                    )}
                                    name={`reimbursement_request_items[${index}].amount`}
                                    control={control}
                                    defaultValue={item.amount}
                                  />
                                </TableCell>

                                <TableCell className={classes.tableCell}>
                                  <Controller
                                    name={`reimbursement_request_items[${index}].attachment_id`}
                                    control={control}
                                    render={({ value, name, onChange }) => {
                                      return value ? (
                                        value.name ? (
                                          <div
                                            className={classes.fileAttachment}
                                          >
                                            <IconButton
                                              className={classes.icon}
                                              onClick={() =>
                                                setValue(
                                                  `reimbursement_request_items[${index}].attachment_id`,
                                                  null
                                                )
                                              }
                                            >
                                              <CancelIcon />
                                            </IconButton>
                                            <div
                                              className={
                                                classes.fileAttachmentName
                                              }
                                            >
                                              {value?.name}
                                            </div>
                                          </div>
                                        ) : (
                                          <CircularProgress />
                                        )
                                      ) : (
                                        <MuiButton
                                          children={
                                            <input
                                              type="file"
                                              hidden
                                              className="btn btn-primary"
                                              id="raised-button-file"
                                              onChange={(event: any) => {
                                                onChange(event.target.files);
                                                handleUploadFile(
                                                  index,
                                                  event.target.files
                                                );
                                              }}
                                            />
                                          }
                                          onClick={handleClickAttach}
                                          label="Attach"
                                        />
                                      );
                                    }}
                                    defaultValue={item.attachment_id}
                                  />
                                </TableCell>

                                <TableCell className={classes.tableCell}>
                                  {categoryFields.length > 0 &&
                                    index != categoryFields.length - 1 && (
                                      <IconButton
                                        onClick={() => {
                                          categoryRemove(index);
                                        }}
                                      >
                                        <DeleteOutlineIcon />
                                      </IconButton>
                                    )}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                )}
              </VuiCardContent>
            </Box>
          )}
        </Grid>

        <Grid item md={4} xs={12}>
          <VuiCardContent title="Summary">
            <Box p={2}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="caption">Request Date</Typography>
                  <Typography variant="subtitle2">
                    <Date data={moment().format("YYYY-MM-DD")} />
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="caption">Employee</Typography>
                  <Typography variant="subtitle2">
                    {watchUser?.name || "-"}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="caption">
                    Reimbursement Policy
                  </Typography>
                  <Typography variant="subtitle2">
                    {watchPolicy?.name || "-"}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="caption">Purpose</Typography>
                  <Typography variant="subtitle2">
                    {watchDescription || "-"}
                  </Typography>
                </Grid>
                {watchPolicy && (
                  <Grid item xs={12}>
                    <Typography variant="caption">Total Amount</Typography>
                    <Typography variant="subtitle2">
                      <VuiNumberFormat
                        data={calculateTotalAmountItem(watchRequestItems)}
                        value={calculateTotalAmountItem(watchRequestItems)}
                        decimalScale={3}
                      />
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Box>
          </VuiCardContent>
        </Grid>

        <Grid item md={12} xs={12}>
          <FooterFormAction
            cancelUrl={"/apps/finance/reimbursement"}
            loading={loading}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            labelForm={"Submit Request Reimbursement?"}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default RequestReimbursement;
