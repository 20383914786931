import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  titleContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",

    "& .heading": {
      display: "flex",
      flexDirection: "column",

      "& .title": {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      },
    },

    "& .action": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      alignItems: "center",

      "& .action-button": {
        marginRight: 16,
      },
      "& .action-button:last-child": {
        marginRight: 0,
      },
    },
  },
  childDetail: {
    "& .item": {
      backgroundColor: "#F2F2F2",
      "& .item-header": {
        textTransform: "uppercase",
        fontWeight: "600",
        borderBottom: "1px solid rgba(224, 224, 224, 1);",
        padding: "7px 10px",
      },
      "& .item-body": {
        padding: "10px",
      },
      "& .item-header-avatar": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      },
    },
  },
  root: {
    "& >": {
      borderBottom: "unset",
      borderTop: "unset",
    },
  },
}));
