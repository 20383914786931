import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { CssBaseline, ThemeProvider, Toolbar } from "@material-ui/core";
import { SnackbarProvider } from "notistack";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { useDispatch, useSelector } from "react-redux";
import { theme, useStyles } from "./style";
import { usePassportService } from "../../utilities/hooks";
import Loading from "../../components/Loading";
import Header from "../../components/AppsLayout/Header";
import Sidebar from "../../components/AppsLayout/Sidebar";
import { HeaderInterface, SidebarInterface } from "../../interfaces";
import { Images } from "../../assets/images";
import clsx from "clsx";
import { useLocation } from "react-router";
import { VuiIconConstant } from "@vodea/vodea-ui/core/components/VuiIcon/helper";
import ConstantRepository from "../../repositories/ConstantRepository";
import { AxiosResponse } from "axios";
import { setConstantFulfilled } from "../../store/actions";
import { ConfirmProvider } from "material-ui-confirm";
import ApprovalRepository from "../../repositories/ApprovalRepository";

const headerData: HeaderInterface = {
  logo: Images.logoApps,
  appName: {
    first: "vodea",
    second: "people",
  },
  useApps: false,
};

const upperSidebarData: SidebarInterface = {
  sections: [
    {
      list: [
        {
          label: "Approval",
          href: "/apps/approval",
          icon: VuiIconConstant.CHECKLIST,
          routeName: "/apps/approval",
          type: "link",
          permissions: "any",
        },
        {
          label: "To Do List",
          href: "/apps/todo-list",
          icon: VuiIconConstant.TASK,
          routeName: "/apps/todo-list",
          type: "link",
          permissions: "any",
        },
      ],
    },
  ],
};

const sidebarData: SidebarInterface = {
  sections: [
    {
      list: [
        {
          label: "Dashboard",
          href: "/apps/home",
          icon: VuiIconConstant.HOME,
          routeName: "/apps/home",
          type: "link",
          permissions: "any",
        },
        {
          label: "People",
          href: "/apps/people",
          routeName: [
            {
              count: 2,
              url: "/apps/people",
            },
          ],
          icon: VuiIconConstant.PEOPLE,
          permissions: "any",
          children: [
            {
              label: "Personal Information",
              href: "/apps/people/personal-information",
              routeName: [
                {
                  count: 3,
                  url: "/apps/people/personal-information",
                },
              ],
              permissions: "any",
            },
            {
              label: "Employee",
              href: "people/employee",
              routeName: [
                {
                  count: 3,
                  url: "/apps/people/employee",
                },
              ],
              permissions: "any",
            },
            {
              label: "Directory",
              href: "people/directory",
              routeName: ["/apps/people/directory"],
              permissions: "any",
            },
          ],
        },
        {
          label: "Time",
          href: "/apps/time",
          icon: VuiIconConstant.CALENDAR,
          routeName: ["/apps/time"],
          permissions: "any",
          children: [
            {
              label: "Attendance",
              href: "/apps/time/attendance",
              // routeName: ["/apps/time/attendance"],
              routeName: [
                {
                  count: 3,
                  url: "/apps/time/attendance",
                },
              ],
              permissions: "any",
            },
            {
              label: "Time Off",
              href: "/apps/time/time-off",
              // routeName: ["/apps/time/time-off"],
              routeName: [
                {
                  count: 3,
                  url: "/apps/time/time-off",
                },
              ],
              permissions: "any",
            },
            {
              label: "Substitute Time Off",
              href: "/apps/time/reimbursement-time-off",
              // routeName: ["/apps/time/reimbursement-time-off"],
              routeName: [
                {
                  count: 3,
                  url: "/apps/time/reimbursement-time-off",
                },
              ],
              permissions: "any",
            },
          ],
        },
        {
          label: "Finance",
          href: "/apps/finance/reimbursement",
          icon: VuiIconConstant.WALLET,
          routeName: ["/apps/finance/reimbursement"],
          permissions: "any",
          children: [
            {
              label: "Reimbursement",
              href: "/apps/finance/reimbursement",
              routeName: [
                {
                  count: 3,
                  url: "/apps/finance/reimbursement",
                },
              ],
              // routeName: ["/apps/finance/reimbursement"],
              permissions: "any",
            },
            {
              label: "Advance",
              href: "/apps/finance/advance",
              routeName: [
                {
                  count: 3,
                  url: "/apps/finance/advance",
                },
              ],
              // routeName: ["/apps/finance/advance"],
              permissions: "any",
            },
            {
              label: "Settlement",
              href: "/apps/finance/settlement",
              routeName: [
                {
                  count: 3,
                  url: "/apps/finance/settlement",
                },
              ],
              // routeName: ["/apps/finance/settlement"],
              permissions: "any",
            },
          ],
        },
        //TODO hide Payroll
        // {
        //   label: "Payroll",
        //   href: "/apps/payroll",
        //   icon: VuiIconConstant.PAYROLL,
        //   routeName: ["apps.payroll.index", "apps.payroll.run"],
        //   type: "",
        //   permissions: "any",
        // },
        {
          label: "Asset",
          href: "/apps/assets",
          icon: VuiIconConstant.STEERING_WHEEL,
          routeName: "/apps/assets",
          type: "link",
          permissions: "any",
        },
        {
          label: "Facility",
          href: "/apps/facility",
          icon: VuiIconConstant.BRIEFCASE,
          routeName: "/apps/facility",
          type: "link",
          permissions: "any",
        },
        // {
        //   label: "Booking",
        //   href: "/apps/booking",
        //   icon: VuiIconConstant.BRIEFCASE,
        //   routeName: "/apps/booking",
        //   type: "link",
        //   permissions: "any",
        // },
        // {
        //   label: "Preference",
        //   href: "/apps/setting",
        //   icon: VuiIconConstant.ADJUST,
        //   routeName: ["/apps/setting"],
        //   permissions: "any",
        // },
      ],
    },
  ],
};

const LayoutApps: React.FC<any> = () => {
  const location = useLocation();

  const classes = useStyles();
  let savedToggle = localStorage.getItem("toggle") || "true";
  const [approvalCount, setApprovalCount] = useState(0);

  const loadApprovalBadgeCount = async () => {
    await ApprovalRepository.account()
      .then((resp: any) => {
        const data = resp.data.data;
        setApprovalCount(data.length);
      })
      .catch((err: any) => {});
  };

  useEffect(() => {
    loadApprovalBadgeCount();
  }, []);

  const [open, setOpen] = useState(
    savedToggle ? JSON.parse(savedToggle) : true
  );
  const { isOnFetchingUser } = usePassportService();
  const { isLogged } = useSelector(({ vodea }: any) => {
    return {
      isLogged: vodea.auth.isLogged,
      user: vodea.auth.user,
    };
  });
  const dispatch = useDispatch();

  useEffect(() => {
    loadConstant();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadConstant = () => {
    let params: any = {};

    ConstantRepository.all({
      ...params,
    })
      .then((response: AxiosResponse) => {
        dispatch(setConstantFulfilled(response.data));
      })
      .catch(() => {});
  };

  if (isOnFetchingUser) {
    return <Loading />;
  }

  if (!isLogged) {
    return <Navigate to={"/auth/login"} />;
  }

  const toggleDrawer = () => {
    setOpen(!open);
    localStorage.setItem("toggle", open ? "false" : "true");
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <SnackbarProvider
          maxSnack={3}
          autoHideDuration={2500}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <ConfirmProvider>
            <div className={classes.root}>
              <Header data={headerData} handleClick={toggleDrawer} />
              <Sidebar
                data={sidebarData}
                open={open}
                toggleDrawer={toggleDrawer}
                upperSidebarData={upperSidebarData}
                approvalCount={approvalCount}
              />
              <main
                style={{ overflow: "hidden" }}
                className={clsx(classes.content, {
                  [classes.contentShift]: open,
                })}
              >
                <Toolbar />
                <div className={classes.contentInside}>
                  <Outlet />
                </div>
              </main>
            </div>
          </ConfirmProvider>
        </SnackbarProvider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default LayoutApps;
