import React from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDocumentTitle } from "../../../@VodeaUI";
import {
  Box,
  Button,
  CardContent,
  Container,
  Divider,
  FormControl,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { useStyles } from "../Style";
import { Images } from "../../../assets/images";
import { Link } from "react-router-dom";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const schema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().required(),
  password: yup.string().min(6).required(),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref('password'), null], 'confirm password do not match')
    .required(),
});

const AuthRegister: React.FC<any> = () => {
  const classes = useStyles();
  useDocumentTitle('Register');

  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmationPassword, setShowConfirmationPassword] = React.useState(false);

  const { handleSubmit, errors, control } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      email: '',
      password: '',
      password_confirmation: '',
    },
  });

  const onSubmit = (formData: any) => alert('register');

  const handleClickShowPassword = (type: String) => {
    if (type === 'password') {
      setShowPassword(!showPassword);
    } else if (type === 'confirmationPassword') {
      setShowConfirmationPassword(!showConfirmationPassword);
    }
  };

  return (
    <>
      <div className={classes.contentWrapper}>
        <Container maxWidth='sm'>
          <Paper
            classes={{
              root: classes.cardPaper,
            }}
          >
            <CardContent
              classes={{
                root: classes.cardContent,
              }}
            >
              <Box mb={4}>
                <Box display='flex' alignItems='center' justifyContent='space-between'>
                  <Box mr={2}>
                    <Typography
                      classes={{
                        root: classes.title,
                      }}
                      variant='h5'
                    >
                      Register
                    </Typography>
                    <Typography>Register on the internal platform</Typography>
                  </Box>

                  <div>
                    <img className={classes.brandLogo} src={Images.logo} alt='vodea logo' />
                  </div>
                </Box>
              </Box>

              <Box>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <FormControl fullWidth margin={'normal'}>
                    <Controller
                      as={TextField}
                      name='name'
                      control={control}
                      variant='outlined'
                      defaultValue=''
                      label='Full Name'
                      fullWidth={true}
                      error={!!errors?.name}
                      helperText={errors?.name?.message}
                    />
                  </FormControl>
                  <FormControl fullWidth margin={'normal'}>
                    <Controller
                      as={TextField}
                      name='email'
                      control={control}
                      variant='outlined'
                      defaultValue=''
                      label='Email'
                      fullWidth={true}
                      error={!!errors?.email}
                      helperText={errors?.email?.message}
                    />
                  </FormControl>
                  <div className={classes.passwordInputWrapper}>
                    <FormControl fullWidth margin={'normal'}>
                      <Controller
                          as={TextField}
                          name='password'
                          control={control}
                          variant='outlined'
                          defaultValue=''
                          label='Password'
                          fullWidth={true}
                          type={showPassword ? 'text' : 'password'}
                          error={!!errors?.password}
                          helperText={errors?.password?.message}
                      />
                    </FormControl>
                    <div className={"icon-wrapper"} onClick={e => handleClickShowPassword('password')}>
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </div>
                  </div>
                  <div className={classes.passwordInputWrapper}>
                    <FormControl fullWidth margin={'normal'}>
                      <Controller
                          as={TextField}
                          name='password_confirmation'
                          control={control}
                          variant='outlined'
                          defaultValue=''
                          label='Confirm Password'
                          fullWidth={true}
                          type={showConfirmationPassword ? 'text' : 'password'}
                          error={!!errors?.password_confirmation}
                          helperText={errors?.password_confirmation?.message}
                      />
                    </FormControl>
                    <div className={"icon-wrapper"} onClick={e => handleClickShowPassword('confirmationPassword')}>
                      {showConfirmationPassword ? <Visibility /> : <VisibilityOff />}
                    </div>
                  </div>

                  <FormControl fullWidth margin={'normal'}>
                    <Button type='submit' variant='contained' color='secondary' fullWidth>
                      Sign Up
                    </Button>
                  </FormControl>

                  <Divider
                    classes={{
                      root: classes.divider,
                    }}
                  />

                  <Typography
                    classes={{
                      root: classes.textLink,
                    }}
                    variant='body1'
                  >
                    Have an account?
                    <Link className='link' to='/auth/Login'>
                      Sign in
                    </Link>
                  </Typography>
                </form>
              </Box>
            </CardContent>
          </Paper>
        </Container>
      </div>
    </>
  );
};

export default AuthRegister;
