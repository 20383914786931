import React from "react";
import { Grid, Typography, Paper, Box } from "@material-ui/core";
import { useState } from "@hookstate/core/dist";
import { VuiTabs } from "../../../@VodeaUI/components";
import { Approval, Todo } from "./tabs";

const tabs = [
  {
    label: "Approval",
    params: {},
  },
  {
    label: "To Do List",
    params: {},
  },
];

const TabPanel: React.FC<any> = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const Task: React.FC<any> = () => {
  const tab = useState(0);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item>
          <Typography variant={"h5"}>Task</Typography>
        </Grid>
      </Grid>
      <Box mt={2}>
        <Box mb={2}>
          <VuiTabs
            name="productTabs"
            value={tab.get()}
            tabList={tabs.map((a) => a.label)}
            handleTabsChange={(value: any) => tab.set(value)}
          />
        </Box>
        <Paper>
          <TabPanel value={tab.get()} index={0}>
            <Approval />
          </TabPanel>
          <TabPanel value={tab.get()} index={1}>
            <Todo />
          </TabPanel>
        </Paper>
      </Box>
    </>
  );
};

export default Task;
