import React from "react";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import {
  EditOutlined as EditOutlinedIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from "@material-ui/icons";
import { useStyles } from "./style";
import _ from "lodash";
import { useState } from "@hookstate/core";
import { CircularProgress } from "@material-ui/core";
import { AxiosResponse } from "axios";
import { useIsMounted } from "../../../../utilities/helpers/hooks";
import { $clone } from "../../../../utilities/helpers/global";
import { useSnackbar } from "notistack";

interface ActionColumnProp {
  data: any;
}

const ActionColumn: React.FC<ActionColumnProp> = ({ data }) => (
  <Box display="flex" justifyContent={"flex-end"}>
    <IconButton aria-label="edit">
      <EditOutlinedIcon fontSize="small" />
    </IconButton>
  </Box>
);

interface ExpandedRowInterface {
  data: any;
  openCollapse: boolean;
  parentLevel: number;
}

const ExpandRow: React.FC<ExpandedRowInterface> = ({
  data,
  openCollapse,
  parentLevel,
}) => {
  const classes = useStyles();
  const isMounted = useIsMounted();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const childData = useState<any[]>([]);

  const currentLevel = parentLevel + 1;

  const loadData = () => {
    if (childData.get().length) {
      setOpen(!open);
      return;
    }

    setLoading(true);

    const child = _.get(data, "child");

    childData.set($clone(child));

    setLoading(false);

    // ItemRepository.show(data.id, {
    //   with: ["childData.itemType", "tools.itemType"]
    // })
    //   .then((response: AxiosResponse) => {
    //     if (isMounted.current) {
    //       const { data: responseData } = response;
    //       const { data } = responseData;
    //       childData.set([
    //         ..._.get(data, "childData", []),
    //         ..._.get(data, "tools", [])
    //       ]);
    //       setOpen(!open);
    //       setLoading(false);
    //     }
    //   })
    //   .catch((e: any) => {
    //     if (isMounted.current && e?.response?.data?.errors) {
    //       setLoading(false);
    //       enqueueSnackbar(e.response.data.message, {
    //         variant: "error"
    //       });
    //     }
    //   });
  };

  return (
    <>
      <TableRow className={classes.root}>
        <TableCell style={{ padding: 0, borderWidth: 0 }} colSpan={7}>
          <Collapse in={openCollapse} timeout="auto" unmountOnExit>
            <Box>
              <Table aria-label="collapsible table">
                <TableBody>
                  <TableRow hover className={classes.root}>
                    <TableCell
                      width={"20%"}
                      style={{ overflowWrap: "anywhere" }}
                    >
                      <Box
                        style={{ paddingLeft: currentLevel * 10 }}
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                      >
                        <Box>
                          {loading ? (
                            <CircularProgress size={25} />
                          ) : (
                            <IconButton
                              style={{
                                visibility: _.get(data, "child")
                                  ? "visible"
                                  : "hidden",
                              }}
                              aria-label="expand row"
                              size="small"
                              onClick={() => loadData()}
                            >
                              {open ? (
                                <KeyboardArrowUpIcon />
                              ) : (
                                <KeyboardArrowDownIcon />
                              )}
                            </IconButton>
                          )}
                        </Box>
                        <Box ml={1}>{_.get(data, "name")}</Box>
                      </Box>
                    </TableCell>
                    <TableCell
                      width={"20%"}
                      style={{ overflowWrap: "anywhere" }}
                    >
                      {_.get(data, "position")}
                    </TableCell>
                    <TableCell
                      width={"20%"}
                      style={{ overflowWrap: "anywhere" }}
                    >
                      {_.get(data, "department")}
                    </TableCell>
                    <TableCell
                      width={"20%"}
                      style={{ overflowWrap: "anywhere" }}
                    >
                      {_.get(data, "email")}{" "}
                    </TableCell>
                    <TableCell
                      width={"20%"}
                      style={{ overflowWrap: "anywhere" }}
                    >
                      {_.get(data, "phone")}
                    </TableCell>
                    <TableCell>
                      <ActionColumn data={data} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      {openCollapse &&
        childData.value.map((component, i) => (
          <ExpandRow
            key={`${currentLevel}${i}`}
            data={component}
            openCollapse={open}
            parentLevel={currentLevel}
          />
        ))}
    </>
  );
};

interface RowProps {
  data: any;
}

const Row: React.FC<RowProps> = ({ data }) => {
  const classes = useStyles();
  const isMounted = useIsMounted();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const childData = useState<any[]>([]);

  const loadData = () => {
    if (childData.get().length) {
      setOpen(!open);
      return;
    }

    setLoading(true);

    const child = _.get(data, "child");

    childData.set(child);

    setLoading(false);

    // ItemRepository.show(data.id, {
    //   with: ["childData.itemType", "tools.itemType"]
    // })
    //   .then((response: AxiosResponse) => {
    //     if (isMounted.current) {
    //       const { data: responseData } = response;
    //       const { data } = responseData;
    //       childData.set([
    //         ..._.get(data, "childData", []),
    //         ..._.get(data, "tools", [])
    //       ]);
    //       setOpen(!open);
    //       setLoading(false);
    //     }
    //   })
    //   .catch((e: any) => {
    //     if (isMounted.current && e?.response?.data?.errors) {
    //       setLoading(false);
    //       enqueueSnackbar(e.response.data.message, {
    //         variant: "error"
    //       });
    //     }
    //   });
  };

  return (
    <React.Fragment>
      <TableRow hover className={classes.root}>
        <TableCell width={"20%"}>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Box>
              {loading ? <CircularProgress size={25} /> : ""}
              {_.get(data, "child") && !loading ? (
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => loadData()}
                >
                  {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              ) : null}
            </Box>
            <Box ml={1}>{_.get(data, "name")}</Box>
          </Box>
        </TableCell>
        <TableCell width={"20%"}>{_.get(data, "position")}</TableCell>
        <TableCell width={"20%"}>{_.get(data, "department")}</TableCell>
        <TableCell width={"20%"}>{_.get(data, "email")}</TableCell>
        <TableCell width={"20%"}>{_.get(data, "phone")}</TableCell>
        <TableCell>
          <ActionColumn data={data} />
        </TableCell>
      </TableRow>
      {childData.get().map((component, i) => (
        <ExpandRow
          key={i}
          data={component}
          openCollapse={open}
          parentLevel={1}
        />
      ))}
    </React.Fragment>
  );
};

interface Props {
  data?: any;
}

const TreeTable: React.FC<Props> = ({ data }) => {
  const childData = [..._.get(data, "child", [])];

  return (
    <Box p={2} display="table">
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Position</TableCell>
              <TableCell>Department</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {childData.map((item, i) => (
              <Row key={i} data={item} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default TreeTable;
