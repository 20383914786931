import { makeStyles } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';
import { red } from '@material-ui/core/colors';

export const useStyles = makeStyles(theme => ({
  root: {
    zIndex: theme.zIndex.drawer + 1,

    '& .header-content': {
      marginLeft: 'auto',
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),

    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
  },
  brandWrapper: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',

    '& .logo': {
      height: '23px',
      marginRight: '10px',
    },
  },
  iconBtn: {
    [theme.breakpoints.down('sm')]: {
      padding: 5,
    },
  },
  searchDrawer: {
    minWidth: 480,
    maxWidth: 480,

    '& .search-container': {
      height: '100%',
      overflowY: 'auto',
      padding: `${theme.spacing(3)}px ${theme.spacing(2)}px`,
    },

    '& .search-divider': {
      margin: `${theme.spacing(3)}px ${theme.spacing(0)}px`,
    },

    '& .search-item': {
      marginBottom: theme.spacing(2),

      '& .link': {
        color: theme.palette.text.primary,
        textDecoration: 'none',
      },

      '& .summary': {
        display: '-webkit-box',
        '-webkit-line-clamp': 3,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
      },
    },

    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
      maxWidth: '100%',
    },
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
  badge: {
    height: '15px',
    minWidth: '15px',
    padding: '3px',
    fontSize: '10px',
    [theme.breakpoints.down('sm')]: {
      height: '12.5px',
      minWidth: '12.5px',
      fontSize: '8px',
      padding: '0px 3px',
    },
    color: '#fff',
    backgroundColor: red[500],
  },

  // Apps
  app: {
    width: '340px',

    '& .app-popover': {
      padding: `${theme.spacing(2)}px 0px`,

      '& .app-label': {
        fontWeight: 700,
        textTransform: 'uppercase',
        fontSize: '0.7rem',
        padding: `0px ${theme.spacing(2)}px`,
        marginBottom: theme.spacing(1),
        display: 'block',
        outline: 'none',
      },

      '& .app-list': {
        padding: `0px ${theme.spacing(1)}px`,
      },

      '& .app-item': {
        cursor: 'pointer',
        padding: theme.spacing(1),
        borderRadius: '5px',
        background: 'transparent',
        transition: 'all 0.5s ease',
        display: 'flex',
        position: 'relative',
        alignItems: 'center',

        '&:hover': {
          background: grey[100],
        },

        '& .app-logo': {
          width: 40,
          marginRight: theme.spacing(1.5),
        },
      },
    },
  },

  // Profile
  btnProfile: {
    padding: theme.spacing(1),

    [theme.breakpoints.down('sm')]: {
      marginRight: -12,
      borderRadius: '100%',
    },
  },
  profileAvatar: {
    width: 32,
    height: 32,
    fontSize: '0.8rem',
  },
  profileName: {
    fontSize: 12,
    marginLeft: theme.spacing(1),

    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  menu: {
    width: '220px',

    '& .label-profile': {
      marginTop: theme.spacing(1),
      fontWeight: 700,
      textTransform: 'uppercase',
      fontSize: '0.7rem',
      padding: `0px ${theme.spacing(2)}px`,
      marginBottom: 5,
      display: 'block',
      outline: 'none',
    },

    '& .divider-profile': {
      margin: `${theme.spacing(1)}px 0px`,
    },
  },

  // Notification
  notification: {
    width: '380px',
    minHeight: 'calc(100vh - 85px)',
    maxHeight: 'calc(100vh - 85px)',

    '& .title-wrapper': {
      borderBottom: `2px solid ${grey[200]}`,
    },

    '& .notification-wrapper': {
      padding: `0px ${theme.spacing(1)}px`,
    },

    '& .notification-item': {
      cursor: 'pointer',
      padding: theme.spacing(1),
      borderRadius: '5px',
      background: 'transparent',
      transition: 'all 0.5s ease',
      display: 'flex',
      position: 'relative',
      minHeight: 70,

      '&:hover': {
        background: grey[100],
      },

      '&.unread': {
        '&:after': {
          content: '""',
          position: 'absolute',
          width: 8,
          height: 8,
          background: theme.palette.primary.main,
          borderRadius: '100%',
          right: 5,
          top: '50%',
          transform: 'translateY(-50%)',
        },
      },

      '& .avatar': {
        width: 50,
        height: 50,
        overflow: 'hidden',
        marginRight: 10,
        borderRadius: '50%',

        '& .icon': {
          objectFit: 'cover',
          width: 50,
          height: 50,
        },
      },

      '& .image': {
        width: 50,
        height: 50,
        overflow: 'hidden',
        marginRight: 10,

        '& .icon': {
          objectFit: 'cover',
          width: 50,
          height: 50,
        },
      },

      '& .details': {
        flex: 1,
        marginRight: 8,
      },

      '& .item-title': {
        margin: '0px',
        fontSize: '11',
        display: '-webkit-box',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
      },

      '& .item-subtitle': {
        margin: '0px',
        fontSize: '14px',
        display: '-webkit-box',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
      },

      '& .item-time': {
        fontSize: '12px',
      },
    },
  },
  notificationAction: {
    width: 180,
  },
  notificationTitle: {
    padding: '15px',
  },
}));
