import React, { useEffect } from "react";
import { decryptedStr } from "../../utilities/helpers/token";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Navigate } from "react-router-dom";
import { usePassportService, useQuerySearch } from "../../utilities/hooks";

const useStyles = makeStyles(() =>
  createStyles({
    loadingWrapper: {
      height: "90vh",
      width: "100%",
      position: "relative",
      "& .loading": {
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%);",
      },
    },
  })
);

const Temporary: React.FC<any> = (props) => {
  const classes = useStyles();
  const { token, redirect } = useQuerySearch();

  const { isOnFetchingUser, setToken } = usePassportService(true);

  useEffect(() => {
    if (token) {
      setToken(decryptedStr(token))
        .then(() => {})
        .catch(() => {});
    } else {
      window.location.replace("http://localhost:3001/");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isOnFetchingUser ? (
        <div className={classes.loadingWrapper}>
          <img className={"loading"} src="/loading.svg" alt="loading" />
        </div>
      ) : (
        <Navigate to={"/" + redirect || "/"} />
      )}
    </>
  );
};

export default Temporary;
