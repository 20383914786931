import React, { useCallback, useEffect, useMemo } from "react";

import _ from "lodash";
import moment from "moment";
import {
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { useState } from "@hookstate/core/dist";
import { Link } from "react-router-dom";
import { VuiSearch } from "../../../../@VodeaUI/components";
import {
  $clone,
  $cloneState,
  mapDataTableParams,
  mapParams,
} from "../../../../utilities/helpers/global";
import { makeStyles } from "@material-ui/core/styles";

import VuiDataTable, {
  Column,
  Options,
} from "../../../../@VodeaUI/VuiDataTable/Index";
import { defaultDataTableOption } from "../../../../utilities/Option";
import VuiSelect from "../../../../@VodeaUI/components/Input/VuiSelect/Index";
import AttendanceRepository from "../../../../repositories/AttendanceRepository";
import { useIsMounted } from "../../../../utilities/helpers/hooks";
import { getUsingImage } from "../../../../utilities/helpers/attendance";
import { OptionInterface } from "./interface";
import {
  optionLabel,
  optionSelected,
} from "../../../../utilities/helpers/select";
import { Autocomplete } from "@material-ui/lab";
import UserRepository from "../../../../repositories/UserRepository";
import { AxiosResponse } from "axios";
import MuiButton from "../../../../components/atoms/MuiButton";
import { TitleHeader } from "../../../../components/molecules";
import { MuiAutoComplete, MuiSearch } from "../../../../components/atoms";
import { sortedByOptions } from "../../../../constants";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  circleStatus: {
    position: "relative",
    "&:after": {
      content: "",
      height: 8,
      width: 8,
      borderRadius: 20,
      backgroundColor: "#31CE89",
      position: "absolute",
      left: 5,
      bottom: 4,
    },
  },
});

interface DateFormatInterface {
  key: string | number;
  label: string | number;
}

const AttendanceTime: React.FC<any> = () => {
  const { t } = useTranslation();
  const [selectedUser, setSelectedUser] = React.useState<any>(null);
  const classes = useStyles();
  const yearNow = useState({
    key: moment().format("YYYY"),
    label: moment().format("YYYY"),
  });
  const monthNow = useState({
    key: moment().format("MM"),
    label: moment().format("MM"),
  });
  const dateNow = useState({
    key: moment().format("DD"),
    label: moment().format("DD"),
  });
  const yearOptions = useState<any[]>([]);
  const monthOptions = useState<any[]>([]);
  const dateOptions = useState<any[]>([]);
  const userSelected = useState<any>(null);

  const columns = useState<Column[]>([
    {
      key: "date",
      label: "Date",
      type: "date",
      dateFormat: "DD MMMM YYYY",
      defaultValue: "-",
    },
    {
      key: "user.name",
      label: "Name",
      render: (row) => _.get(row, "user.name", "-"),
    },
    {
      key: "from",
      label: "Using",
      render: (row) => {
        return getUsingImage(row);
      },
    },
    {
      key: "date",
      label: "Schedule",
      render: (row) => {
        const arrSchedule = row.schedule.split(" - ");
        return row.time_from && row.time_to ? (
          <div>{`${moment(arrSchedule[0]).format("HH:mm")} - ${moment(
            arrSchedule[1]
          ).format("HH:mm")}`}</div>
        ) : (
          "-"
        );
      },
    },
    {
      key: "clock_in",
      label: "Clock In",
      render: (row) => {
        return row.clock_in ? (
          <>
            <div>{`${moment(row.clock_in).format("HH:mm")} WIB`}</div>
          </>
        ) : (
          "-"
        );
      },
    },
    {
      key: "clock_out",
      label: "Clock Out",
      render: (row) => {
        return row.clock_out ? (
          <>
            <div>{`${moment(row.clock_out).format("HH:mm")} WIB`}</div>
          </>
        ) : (
          "-"
        );
      },
    },
    {
      key: "user_id",
      label: "",
      sortable: false,
      render: (row) => {
        return (
          <Box display="flex" justifyContent={"flex-end"}>
            <Link to={`${row.date}`}>
              <Button variant="contained" color="primary" className={"btnView"}>
                View
              </Button>
            </Link>
          </Box>
        );
      },
    },
  ]);

  const isMounted = useIsMounted();
  const loading = useState(false);
  const options = useState<Options>({
    ...defaultDataTableOption,
    sortedBy: "desc",
    orderBy: "date",
  });
  const tableData = useState([]);

  useEffect(() => {
    generateYear();
    generateMonth();
    generateDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = useCallback(async () => {
    if (isMounted.current) {
      loading.set(true);
    }
    let additionalParams: any = {};
    Object.assign(additionalParams, {
      user: _.get(userSelected.get(), "id", null),
      date: `${$clone(yearNow.key.get())}-${$clone(
        monthNow.key.get()
      )}-${$clone(dateNow.key.get())}`,
    });

    await AttendanceRepository.account({
      ...mapDataTableParams($cloneState(options)),
      ...additionalParams,
      with: ["user", "attendancePolicy"],
    })
      .then((response: any) => {
        const data = response.data.data;
        const meta = response.data.meta;
        if (isMounted.current) {
          tableData.set(data);
          options.page.set(meta.current_page - 1);
          options.total.set(meta.total);
          loading.set(false);
        }
      })
      .catch((err: any) => {
        if (isMounted.current) loading.set(false);
      });
  }, []);

  const generateYear = () => {
    let tempYear: any[] = [];
    let year = new Date().getFullYear();
    tempYear.push({ key: year, label: year.toString() });

    year -= 1;
    for (var i = 0; i < 5; i++) {
      tempYear.push({ key: year - i, label: (year - i).toString() });
    }
    yearOptions.set(tempYear);

    yearNow.set(
      tempYear.find((val: any) => {
        return val.key == yearNow.key.get();
      })
    );
  };

  const generateMonth = () => {
    {
      let tempMonth: any[] = [];
      moment.months().map((label, value) => {
        tempMonth.push({ key: value + 1, label: label });
      });
      monthOptions.set(tempMonth);
      monthNow.set(
        tempMonth.find((val: any) => {
          return val.key == monthNow.key.get();
        })
      );
    }
  };

  const generateDate = (isFormat: boolean = false) => {
    {
      let tempDate: any[] = [];
      let monthDate = isFormat
        ? moment().set({
            year: $clone(yearNow.key.get()),
            month: $clone(monthNow.key.get()) - 1,
          })
        : moment();

      const daysInMonth = monthDate.daysInMonth();
      for (var i = 1; i <= daysInMonth; i++) {
        tempDate.push({ key: i, label: i.toString() });
      }

      dateOptions.set(tempDate);
      dateNow.set(
        tempDate.find((val: any) => {
          return val.key == dateNow.key.get();
        })
      );
    }
  };

  const option = useState<OptionInterface>({
    monthAttendance: {
      options: [],
      loading: false,
      params: {},
      loadData: () => {
        if (isMounted.current) {
          option.monthAttendance.loading.set(true);
        }

        if (isMounted.current) {
          option.monthAttendance.options.set($clone(monthOptions.get()));
          option.monthAttendance.loading.set(false);
        }
      },
      onOpen: () => {
        if (isMounted.current) {
          option.monthAttendance.options.set([]);
          option.monthAttendance.loadData.get()();
        }
      },
    },
    yearAttendance: {
      options: [],
      loading: false,
      params: {},
      loadData: () => {
        if (isMounted.current) {
          option.yearAttendance.loading.set(true);
        }

        if (isMounted.current) {
          option.yearAttendance.options.set($clone(yearOptions.get()));
          option.yearAttendance.loading.set(false);
        }
      },
      onOpen: () => {
        if (isMounted.current) {
          option.yearAttendance.options.set([]);
          option.yearAttendance.loadData.get()();
        }
      },
    },
    dateAttendance: {
      options: [],
      loading: false,
      params: {},
      loadData: () => {
        if (isMounted.current) {
          option.dateAttendance.loading.set(true);
        }

        if (isMounted.current) {
          option.dateAttendance.options.set($clone(dateOptions.get()));
          option.dateAttendance.loading.set(false);
        }
      },
      onOpen: () => {
        if (isMounted.current) {
          option.dateAttendance.options.set([]);
          option.dateAttendance.loadData.get()();
        }
      },
    },
    employee: {
      options: [],
      loading: false,
      params: {},
      loadData: async () => {
        if (isMounted.current) {
          option.employee.loading.set(true);
        }
        await UserRepository.select(
          mapParams($cloneState(option.employee.params))
        )
          .then(({ data }: AxiosResponse) => {
            if (isMounted.current) {
              option.employee.options.set(data.data);
              option.employee.loading.set(false);
            }
          })
          .catch(() => {});
      },
      onOpen: () => {
        if (isMounted.current) {
          option.employee.options.set([]);
          option.employee.loadData.get()();
        }
      },
    },
  });

  const handleTableSearch = useCallback((value: string) => {
    if (isMounted.current) {
      options.search.set(value);
      options.page.set(0);
      loadData().then();
    }
  }, []);

  useMemo(() => {
    (async () => {
      await loadData();
    })();
  }, []);

  return (
    <>
      <TitleHeader
        title={"Attendance"}
        subtitle={"List of attendance transaction"}
        component={
          <Link to={"/apps/time/attendance/attendance-request"}>
            <MuiButton label="Request Attendance" size="large" />
          </Link>
        }
      />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper variant="elevation" elevation={1}>
            <Box p={2}>
              <Grid
                container
                spacing={3}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Grid item>
                  <MuiSearch
                    value={$clone(options.search.value)}
                    callback={handleTableSearch}
                  />
                </Grid>
                <Grid item md={3}>
                  <MuiAutoComplete
                    isAsync={false}
                    value={sortedByOptions.find(
                      (option) => option.id === $clone(options.sortedBy.value)
                    )}
                    constantOptions={sortedByOptions}
                    onSelected={(newValue) => {
                      options.sortedBy.set(newValue.id);
                      loadData().then();
                    }}
                    muiTextField={{
                      label: t("form.sort.label"),
                    }}
                  />
                </Grid>
              </Grid>

              <Box marginTop={2}>
                <Grid container spacing={3}>
                  <Grid item xs={3} md={2}>
                    <Autocomplete
                      value={yearNow.get()}
                      disableClearable
                      loading={option.yearAttendance.loading.get()}
                      options={$clone(option.yearAttendance.options.get())}
                      onOpen={option.yearAttendance.onOpen.get()}
                      getOptionLabel={(option: any) =>
                        optionLabel($clone(option), "label")
                      }
                      getOptionSelected={(option: any, value: any) =>
                        option.key == value.key
                      }
                      onChange={(event: any, newValue: any) => {
                        yearNow.set(newValue);
                        generateDate(true);
                        loadData();
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Year"
                          variant="outlined"
                          fullWidth={true}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={3} md={2}>
                    <Autocomplete
                      value={monthNow.get()}
                      disableClearable
                      loading={option.monthAttendance.loading.get()}
                      options={$clone(option.monthAttendance.options.get())}
                      onOpen={option.monthAttendance.onOpen.get()}
                      getOptionLabel={(option: any) =>
                        optionLabel($clone(option), "label")
                      }
                      getOptionSelected={(option: any, value: any) =>
                        option.key == value.key
                      }
                      onChange={(event: any, newValue: any) => {
                        monthNow.set(newValue);
                        dateNow.set({
                          key: "1",
                          label: "1",
                        });
                        generateDate(true);
                        loadData();
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Month"
                          variant="outlined"
                          fullWidth={true}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={3} md={2}>
                    <Autocomplete
                      value={dateNow.get()}
                      disableClearable
                      loading={option.dateAttendance.loading.get()}
                      options={$clone(option.dateAttendance.options.get())}
                      onOpen={option.dateAttendance.onOpen.get()}
                      getOptionLabel={(option: any) =>
                        optionLabel($clone(option), "label")
                      }
                      getOptionSelected={(option: any, value: any) =>
                        option.key == value.key
                      }
                      onChange={(event: any, newValue: any) => {
                        dateNow.set(newValue);
                        loadData();
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Date"
                          variant="outlined"
                          fullWidth={true}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={3} md={3}>
                    <MuiAutoComplete
                      repository={UserRepository}
                      params={{
                        for: "attendance",
                      }}
                      onSelected={(newValue) => {
                        setSelectedUser(newValue);
                      }}
                      muiTextField={{
                        label: t("form.employee.label"),
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>

            <VuiDataTable
              loading={loading.get()}
              columns={columns.value}
              options={$clone(options.value)}
              data={tableData.value}
              onChangeOptions={(values: Options) => {
                options.set(values);
                loadData();
              }}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default AttendanceTime;
