import React from "react";
import moment, { Moment } from "moment";
import { useSelector } from "react-redux";
// const idLocale = require("moment/locale/id");
// moment.updateLocale("id", idLocale);

interface IDateDay {
  data: string | null | Moment;
  defaultValue?: string;
}

const DateDay: React.FC<IDateDay> = ({ data, defaultValue = "-" }) => {
  const { dateDay } = useSelector(({ formatter }: any) => ({
    dateDay: formatter.dateDay,
  }));

  return (
    <>{moment(data).isValid() ? moment(data).format(dateDay) : defaultValue}</>
  );
};

export default DateDay;
