import React from "react";
import { Grid, Typography } from "@material-ui/core";
import NavigationBar from "../../../../components/NavigationBar";
import { Link } from "react-router-dom";
import { MuiButton } from "../../../../components/atoms";

interface Props {
  children?: React.ReactChild | React.ReactChild[];
  url?: string;
  buttonLabel: string;
}

const Policy: React.FC<Props> = ({ children, url, buttonLabel }) => {
  const data: any[] = [
    {
      label: "Time",
      href: "",
      path: "time",
      permissions: "any",
      children: [
        {
          label: "Time Off",
          href: "/apps/setting/policy/time-off",
          path: "time-off",
          permissions: "any",
        },
        {
          label: "Attendance",
          href: "/apps/setting/policy/attendance",
          path: "attendance",
          permissions: "any",
        },
      ],
    },
    {
      label: "Finance",
      href: "",
      path: "finance",
      permissions: "any",
      children: [
        {
          label: "Reimbursement",
          href: "/apps/setting/policy/reimbursement",
          path: "reimbursement",
          permissions: "any",
        },
        {
          label: "Advance",
          href: "/apps/setting/policy/advance",
          path: "advance",
          permissions: "any",
        },
      ],
    },
  ];

  return (
    <>
      <Grid
        container
        spacing={3}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Typography variant={"h5"}>Policy</Typography>
        </Grid>
        <Grid item hidden={url === undefined}>
          <Link to={url === undefined ? "" : url}>
            <MuiButton label={buttonLabel} />
          </Link>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <NavigationBar data={data} type="setting" />
        </Grid>
        <Grid item xs={12} md={9}>
          {children}
        </Grid>
      </Grid>
    </>
  );
};

export default Policy;
