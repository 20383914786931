import * as yup from "yup";

export const validationSchema = () => {
  let shapes = {
    name: yup.string().label("Attendance Policy Name"),
    code: yup.string().label("Policy Code"),
    attendance_break_policies: yup.array().label("Break Time"),
    attendance_work_hour_policies: yup.array().label("Work Time"),
  };

  return yup.object().shape(shapes);
};
