import React, { useCallback, useEffect, useMemo } from "react";
import {
  Box,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import VuiCardContent from "../../../../../@VodeaUI/components/VuiCardContent";
import _ from "lodash";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import TitleForm from "../../../../../components/molecules/TitleForm";
import FooterFormAction from "../../../../../components/FooterFormAction";
import { useState } from "@hookstate/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MuiTableCell from "@material-ui/core/TableCell";
import { ICashAdvancePolicy } from "../../../../../interfaces/CashAdvancePolicy";
import { useParams } from "react-router-dom";
import CashAdvancePolicyRepository from "../../../../../repositories/CashAdvancePolicyRepository";
import { AxiosError, AxiosResponse } from "axios";
import { IApiResource } from "../../../../../interfaces/ApiResource";
import moment from "moment";
import { $clone } from "../../../../../utilities/helpers/global";
import { useSelector } from "react-redux";
import { IConstantOption } from "../../../../../interfaces/ConstantOption";
import { constantToOptions } from "../../../../../utilities/helpers/option.helper";
import { useIsMounted } from "../../../../../utilities/helpers/hooks";
import { ICashAdvanceFormulaPolicy } from "../../../../../interfaces/CashAdvanceFormulaPolicy";
import { IFormulaComponent } from "../../../../../interfaces/FormulaComponent";
import { formatFormData } from "../../../../../utilities/helpers/form";
import { handleAxiosErrorSave } from "../../../../../utilities/helpers/axios-error.helper";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router";
import FormAddAdvanceFormula from "../../../../../components/forms/FormAddAdvanceFormula";
import { ConstantOptionInterface } from "../Reimbursement/interface";
import { EditOutlined } from "@material-ui/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationSchema } from "./validation";
import { Capitalize } from "../../../../../utilities/helpers/string";
import PayrollComponentRepository from "../../../../../repositories/PayrollComponentRepository";
import FormulaRepository from "../../../../../repositories/FormulaRepository";
import CashAdvanceApprovalSetting from "../../../../../components/Policy/CashAdvanceApprovalSetting";
import ApprovalSetting from "../../../../../components/Policy/ApprovalSetting";
import {
  ErrorText,
  MuiAutoComplete,
  MuiButton,
  MuiCheckbox,
  MuiDatePicker,
  MuiTextField,
  NumberFieldFormat,
} from "../../../../../components/atoms";
import { useTranslation } from "react-i18next";
import { handleAxiosSuccessSave } from "../../../../../utilities/helpers/axios-success.helper";

const defaultValues: ICashAdvancePolicy = {
  code: "",
  name: "",
  valid_from: moment().add(2, "day").format("YYYY-MM-DD"),
  description: "",
  is_default: false,
  approval_requirement_type: null,
  settlement_policy: {
    approval_requirement_type: null,
    maximum_settlement_type: null,
    max_settlement: 0,
    approval_policies: [],
  },
  approval_policies: [],
  cash_advance_formula_policies: [],
};

const AdvancePolicyForm: React.FC<any> = () => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const { id } = useParams();
  const [data, setData] = React.useState<ICashAdvancePolicy>(defaultValues);
  const { enqueueSnackbar } = useSnackbar();
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const [formulaIndex, setFormulaIndex] = React.useState<number | null>(null);
  const { constant } = useSelector(({ constant }: any) => {
    return {
      constant: constant.constant.payload,
    };
  });

  const [cashAdvanceFormula, setCashAdvanceFormula] = React.useState<
    ICashAdvanceFormulaPolicy[]
  >([]);
  const [helperFormula, setHelperFormula] = React.useState<string[]>([]);
  const [tempPayrollComponent, setTempPayrollComponent] = React.useState<any[]>(
    []
  );
  const [tempFormula, setTempFormula] = React.useState<any[]>([]);
  const settlementPolicyOptions = useState<IConstantOption[]>(
    constantToOptions(
      constant,
      "SETTLEMENT_POLICY_MAXIMUM_SETTLEMENT_EXPIRED_TYPE_OPTIONS",
      true
    )
  );

  const approvalRequirementTypeOptions = useState<ConstantOptionInterface[]>(
    constantToOptions(constant, "APPROVAL_REQUIREMENT_TYPE_OPTIONS", true)
  );

  const approvalPolicyTypeOptions = useState<ConstantOptionInterface[]>(
    constantToOptions(constant, "APPROVAL_POLICY_TYPE_OPTIONS")
  );

  useEffect(() => {
    if (cashAdvanceFormula.length > 0 && helperFormula.length > 0) {
      const mappingCashAdvance = cashAdvanceFormula.map((policy) => {
        const tempFormulaComponents: any[] = [];
        if (policy.formula_component.components.length > 0) {
          policy.formula_component.components.forEach((component: any) => {
            if (component.type === "OPERATOR" || component.type === "SYMBOL") {
              component.value = {
                id: component.value,
                name: component.value,
              };
            }
            tempFormulaComponents.push({
              ...component,
              type: {
                id: component.type,
                name: Capitalize(component.type),
              },
              value:
                component?.type === "FORMULA"
                  ? typeof component?.value !== "string" &&
                    tempFormula.find(
                      (formula) => formula.id === component?.value
                    )
                  : component?.type === "VALUE"
                  ? component?.value
                  : component?.type === "PAYROLL_COMPONENT"
                  ? tempPayrollComponent.find(
                      (formula) => formula.id === component?.value
                    )
                  : typeof component?.value !== "string" && component?.value,
            });
          });

          return {
            ...policy,
            formula_components: tempFormulaComponents,
          };
        }
        return policy;
      });
      setValue("cash_advance_formula_policies", mappingCashAdvance);
    }
  }, [helperFormula, cashAdvanceFormula]);

  useEffect(() => {
    if (constant) {
      settlementPolicyOptions.set(
        constantToOptions(
          constant,
          "SETTLEMENT_POLICY_MAXIMUM_SETTLEMENT_EXPIRED_TYPE_OPTIONS",
          true
        )
      );
      approvalRequirementTypeOptions.set(
        constantToOptions(constant, "APPROVAL_REQUIREMENT_TYPE_OPTIONS", true)
      );
      approvalPolicyTypeOptions.set(
        constantToOptions(constant, "APPROVAL_POLICY_TYPE_OPTIONS")
      );
    }
  }, [constant]);

  const loadData = useCallback(async () => {
    if (!id) {
      return;
    }

    await CashAdvancePolicyRepository.show(id, {
      with: [
        "approvalPolicies.role",
        "cashAdvanceFormulaPolicies.formulaComponent",
        "settlementPolicy.approvalPolicies.role",
      ],
    })
      .then((response: AxiosResponse<IApiResource<ICashAdvancePolicy>>) => {
        const { data: responseData } = response.data;
        const { settlement_policy: settlementPolicy } = responseData;
        const tempPayrollComponentIds: number[] = [];
        const tempFormulaIds: number[] = [];

        responseData.cash_advance_formula_policies.forEach((policy) => {
          if (policy.formula_component.components.length > 0) {
            policy.formula_component.components.forEach((component: any) => {
              if (component.type === "PAYROLL_COMPONENT") {
                tempPayrollComponentIds.push(component.value);
              }
              if (component.type === "FORMULA") {
                tempFormulaIds.push(component.value);
              }
            });
          }
        });

        if (tempPayrollComponentIds.length > 0) {
          PayrollComponentRepository.select({
            ids: tempPayrollComponentIds,
          }).then((response: AxiosResponse) => {
            if (isMounted.current) {
              setTempPayrollComponent(response.data.data);
              setHelperFormula([...helperFormula, "payroll-component"]);
            }
          });
        }

        if (tempFormulaIds.length > 0) {
          FormulaRepository.select({
            ids: tempFormulaIds,
          }).then((response: AxiosResponse) => {
            if (isMounted.current) {
              setTempFormula(response.data.data);
              setHelperFormula([...helperFormula, "formula"]);
            }
          });
        }

        if (responseData.approval_policies) {
          const approvalPolicies = responseData.approval_policies.map(
            (approval) => {
              return {
                ...approval,
                type:
                  approvalPolicyTypeOptions.value.find(
                    (item: ConstantOptionInterface) => item.id === approval.type
                  ) || null,
                role_id: approval.role,
              };
            }
          );

          Object.assign(responseData, {
            approval_policies: approvalPolicies,
          });
        }

        if (settlementPolicy.approval_policies) {
          const settlementApprovalPolicies =
            settlementPolicy.approval_policies.map((approval) => {
              return {
                ...approval,
                type:
                  approvalPolicyTypeOptions.value.find(
                    (item: ConstantOptionInterface) => item.id === approval.type
                  ) || null,
                role_id: approval.role,
              };
            });

          Object.assign(settlementPolicy, {
            approval_policies: settlementApprovalPolicies,
          });
        }

        const settlementApprovalRequirementType =
          approvalRequirementTypeOptions.value.find(
            (item) => item.id === settlementPolicy.approval_requirement_type
          );

        const approvalRequirementType =
          approvalRequirementTypeOptions.value.find(
            (item) => item.id === responseData.approval_requirement_type
          );

        const maximumSettlementType = settlementPolicyOptions.value.find(
          (item) => item.id === settlementPolicy.maximum_settlement_type
        );

        Object.assign(responseData, {
          approval_requirement_type: approvalRequirementType || null,
          settlement_policy: {
            ...settlementPolicy,
            approval_requirement_type:
              settlementApprovalRequirementType || null,
            maximum_settlement_type: maximumSettlementType || null,
          },
        });

        if (isMounted.current) {
          setCashAdvanceFormula(responseData.cash_advance_formula_policies);
          setData(responseData);
        }
      })
      .catch(() => {});
  }, []);

  const { control, watch, handleSubmit, reset, setError, errors, setValue } =
    useForm<ICashAdvancePolicy>({
      shouldUnregister: false,
      // resolver: yupResolver(validationSchema()),
      mode: "onChange",
      defaultValues: useMemo(() => {
        (async () => {
          await loadData();
        })();
        return data;
      }, [id, loadData]),
    });

  useEffect(() => {
    reset(data);
  }, [data]);

  const watchMaximumSettlementType = watch(
    "settlement_policy.maximum_settlement_type"
  );
  const watchApprovalRequirementType = watch("approval_requirement_type");
  const watchApprovalPolicies = watch("approval_policies");
  const watchSettlementApprovalRequirementType = watch(
    "settlement_policy.approval_requirement_type"
  );
  const watchSettlementApprovalPolicies = watch(
    "settlement_policy.approval_policies"
  );

  const {
    fields: formulaPolicyFields,
    append: formulaPolicyAppend,
    remove: formulaPolicyRemove,
  } = useFieldArray({
    control,
    keyName: "key",
    name: "cash_advance_formula_policies",
  });

  const onSubmit = (data: ICashAdvancePolicy) => {
    if (isMounted.current) setLoading(true);
    const formData = formatFormData(data);
    formData.settlement_policy.maximum_settlement_type =
      formData.settlement_policy?.maximum_settlement_type?.id;
    const tempCashAdvanceFormulaPolicies: any[] = [];

    if (
      formData.cash_advance_formula_policies &&
      formData.cash_advance_formula_policies.length > 0
    ) {
      formData.cash_advance_formula_policies.forEach((policy: any) => {
        const tempFormulaComponents: any[] = [];
        if (
          policy?.formula_components &&
          policy?.formula_components.length > 0
        ) {
          policy.formula_components.forEach((formula: IFormulaComponent) => {
            tempFormulaComponents.push({
              ...formula,
              type: formula?.type?.id,
              value:
                formula?.type?.id === "FORMULA"
                  ? typeof formula?.value !== "string" && formula?.value?.code
                  : formula?.type?.id === "VALUE"
                  ? formula?.value
                  : typeof formula?.value !== "string" && formula?.value?.id,
            });
          });
          tempCashAdvanceFormulaPolicies.push({
            ...policy,
            formula_components: tempFormulaComponents,
          });
        } else {
          tempCashAdvanceFormulaPolicies.push(policy);
        }
      });
    }

    formData.cash_advance_formula_policies = tempCashAdvanceFormulaPolicies;

    if (
      formData?.approval_requirement_type &&
      formData?.approval_requirement_type?.id !== "NONE"
    ) {
      formData.approval_requirement_type =
        formData.approval_requirement_type.id;
      formData.approval_policies = formData?.approval_policies?.map(
        (approval: any) => {
          if (approval?.type?.id === "SUPERVISOR") {
            return {
              level: approval?.level,
              type: approval?.type?.id,
            };
          } else {
            return {
              level: approval?.level,
              type: approval?.type?.id,
              role_id: approval?.role_id?.id,
            };
          }
        }
      );
    } else {
      delete formData.approval_requirement_type;
      delete formData.approval_policies;
    }

    if (
      formData?.settlement_policy.approval_requirement_type &&
      formData?.settlement_policy.approval_requirement_type?.id !== "NONE"
    ) {
      formData.settlement_policy.approval_requirement_type =
        formData.settlement_policy.approval_requirement_type.id;
      formData.settlement_policy.approval_policies =
        formData?.settlement_policy?.approval_policies.map((approval: any) => {
          if (approval?.type?.id === "SUPERVISOR") {
            return {
              level: approval?.level,
              type: approval?.type?.id,
            };
          } else {
            return {
              level: approval?.level,
              type: approval?.type?.id,
              role_id: approval?.role_id?.id,
            };
          }
        });
    } else {
      delete formData?.settlement_policy.approval_requirement_type;
      delete formData.settlement_policy.approval_policies;
    }

    if (formData.settlement_policy.maximum_settlement_type === "NONE") {
      delete formData.settlement_policy.maximum_settlement_type;
      delete formData.settlement_policy.max_settlement;
    }

    if (id) {
      delete formData.valid_from;
    }

    if (id) {
      CashAdvancePolicyRepository.update(id, formData)
        .then((response: AxiosResponse) => {
          if (isMounted.current) {
            setLoading(false);
            handleAxiosSuccessSave(
              response,
              enqueueSnackbar,
              navigate,
              "/apps/setting/policy/advance"
            );
          }
        })
        .catch((error: AxiosError) => {
          if (isMounted.current) {
            handleAxiosErrorSave(error, setError, enqueueSnackbar);
            setLoading(false);
          }
        });
    } else {
      CashAdvancePolicyRepository.create(formData)
        .then((response: AxiosResponse) => {
          if (isMounted.current) {
            setLoading(false);
            handleAxiosSuccessSave(
              response,
              enqueueSnackbar,
              navigate,
              "/apps/setting/policy/advance"
            );
          }
        })
        .catch((error: AxiosError) => {
          if (isMounted.current) {
            handleAxiosErrorSave(error, setError, enqueueSnackbar);
            setLoading(false);
          }
        });
    }
  };

  const handleClickOpen = () => {
    if (isMounted.current) {
      setOpen(true);
    }
  };

  const handleClose = () => {
    if (isMounted.current) {
      setOpen(false);
      setFormulaIndex(null);
    }
  };

  const handleEditFormula = (index: number) => {
    setOpen(true);
    setFormulaIndex(index);
  };

  const handleSaveFormula = (data: ICashAdvanceFormulaPolicy) => {
    if (typeof formulaIndex === "number") {
      const cloneFormulaPolicies: any[] = [...formulaPolicyFields];
      cloneFormulaPolicies[formulaIndex] = data;
      setValue(`cash_advance_formula_policies`, cloneFormulaPolicies);
    } else {
      formulaPolicyAppend(data);
    }
  };

  const renderFormulaPolicies = (item: any, index: number) => {
    return (
      <TableRow key={item.key}>
        <TableCell>
          <Controller
            name={`cash_advance_formula_policies[${index}].name`}
            control={control}
            defaultValue={item.name}
            render={({ value, onChange }) => {
              return (
                <Typography variant="subtitle2">{value || "-"}</Typography>
              );
            }}
          />
        </TableCell>
        <TableCell>
          <Controller
            name={`cash_advance_formula_policies[${index}].code`}
            control={control}
            defaultValue={item.code}
            render={({ value, onChange }) => {
              return (
                <Typography variant="subtitle2">{value || "-"}</Typography>
              );
            }}
          />
        </TableCell>
        <TableCell>
          <Controller
            name={`cash_advance_formula_policies[${index}].description`}
            control={control}
            defaultValue={item.description}
            render={({ value, onChange }) => {
              return (
                <Typography variant="subtitle2">{value || "-"}</Typography>
              );
            }}
          />
        </TableCell>
        <TableCell>
          <IconButton
            onClick={() => {
              handleEditFormula(index);
            }}
          >
            <EditOutlined />
          </IconButton>
          <IconButton
            onClick={() => {
              formulaPolicyRemove(index);
            }}
          >
            <DeleteOutlineIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  };

  const TableCell = withStyles({
    root: {
      borderBottom: "1px solid rgba(224, 224, 224, 1)",
      paddingLeft: "unset",
      "&:last-child": {
        textAlign: "right",
        "button:first-child": {
          marginRight: 16,
        },
      },
    },
  })(MuiTableCell);

  return (
    <>
      <TitleForm title="Add Advance Policy" withBackUrl />
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <VuiCardContent title="Information">
            <Box p={2}>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <Controller
                    render={({ value, name, onChange }) => {
                      return (
                        <MuiTextField
                          name={name}
                          onChange={onChange}
                          label="Advance Policy Name"
                          value={value}
                          error={_.has(errors, name)}
                          helperText={_.get(errors, `${name}.message`)}
                        />
                      );
                    }}
                    name={"name"}
                    control={control}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <Controller
                    render={({ value, name, onChange }) => {
                      return (
                        <MuiTextField
                          name={name}
                          onChange={onChange}
                          label="Policy Code"
                          value={value}
                          error={_.has(errors, name)}
                          helperText={_.get(errors, `${name}.message`)}
                        />
                      );
                    }}
                    name={"code"}
                    control={control}
                  />
                </Grid>

                <Grid item md={12} xs={12}>
                  <Controller
                    render={({ value, name, onChange }) => {
                      return (
                        <MuiDatePicker
                          disabled={!!id}
                          value={value}
                          name={name}
                          label={"Effective On"}
                          format="DD MMMM YYYY"
                          onChange={onChange}
                          error={_.has(errors, name)}
                          helperText={_.get(errors, `${name}.message`)}
                        />
                      );
                    }}
                    name={"valid_from"}
                    control={control}
                  />
                </Grid>

                <Grid item md={12} xs={12}>
                  <Controller
                    render={({ value, name, onChange }) => {
                      return (
                        <MuiTextField
                          name={name}
                          onChange={onChange}
                          label={t("form.policyDescription.label")}
                          value={value}
                          error={_.has(errors, name)}
                          helperText={_.get(errors, `${name}.message`)}
                        />
                      );
                    }}
                    name={"description"}
                    control={control}
                  />
                </Grid>
              </Grid>
            </Box>
          </VuiCardContent>
        </Grid>

        <Grid item xs={12} md={8}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <VuiCardContent title="Policy Configuration">
                <Box p={2}>
                  <Grid container spacing={3}>
                    <Grid item md={12} xs={12}>
                      <Controller
                        name={"is_default"}
                        control={control}
                        render={({ value, name, onChange }) => {
                          return (
                            <MuiCheckbox
                              onChange={onChange}
                              checked={value}
                              label={"Default advance for new employee"}
                            />
                          );
                        }}
                      />
                    </Grid>

                    <Grid item md={12} xs={12}>
                      <Controller
                        render={({ value, name, onChange }) => (
                          <MuiAutoComplete
                            isAsync={false}
                            constantOptions={$clone(
                              settlementPolicyOptions.get()
                            )}
                            onOpen={() =>
                              settlementPolicyOptions.set(
                                constantToOptions(
                                  constant,
                                  "SETTLEMENT_POLICY_MAXIMUM_SETTLEMENT_EXPIRED_TYPE_OPTIONS",
                                  true
                                )
                              )
                            }
                            value={value}
                            onSelected={(newValue) => onChange(newValue)}
                            muiTextField={{
                              label: "Settlement Policy",
                              error: _.has(errors, name),
                              helperText: _.get(errors, `${name}.message`),
                            }}
                          />
                        )}
                        name="settlement_policy.maximum_settlement_type"
                        control={control}
                      />
                    </Grid>

                    {watchMaximumSettlementType &&
                      _.get(watchMaximumSettlementType, "id") !== "NONE" && (
                        <Grid item md={12} xs={12}>
                          <Controller
                            render={({ value, name, onChange }) => {
                              return (
                                <MuiTextField
                                  onChange={onChange}
                                  value={value}
                                  label={
                                    _.get(watchMaximumSettlementType, "id") ===
                                    "TOTAL_DAY"
                                      ? "Total Day"
                                      : "Maximum Settlement Days"
                                  }
                                  error={_.has(errors, name)}
                                  helperText={_.get(errors, `${name}.message`)}
                                  InputProps={{
                                    inputComponent: NumberFieldFormat,
                                  }}
                                />
                              );
                            }}
                            name={"settlement_policy.max_settlement"}
                            control={control}
                          />
                        </Grid>
                      )}

                    <Grid item md={12} xs={12}>
                      <ApprovalSetting
                        errors={errors}
                        control={control}
                        policies={watchSettlementApprovalPolicies}
                        type={watchSettlementApprovalRequirementType}
                        typeName="settlement_policy.approval_requirement_type"
                        fieldName="settlement_policy.approval_policies"
                        label="Approval Settlement"
                      />
                    </Grid>
                  </Grid>
                </Box>
              </VuiCardContent>
            </Grid>

            <Grid item xs={12} md={12}>
              <VuiCardContent title="Advance Component">
                <Box p={2}>
                  <Grid container spacing={3}>
                    <Grid item md={12} xs={12}>
                      <Grid container spacing={1}>
                        <Grid item md={12} xs={12}>
                          <MuiButton
                            label="Add Component"
                            onClick={handleClickOpen}
                          />
                        </Grid>

                        <ErrorText
                          errors={errors}
                          field={formulaPolicyFields}
                          fieldName="cash_advance_formula_policies"
                        />

                        {formulaPolicyFields.length > 0 && (
                          <Grid item md={12} xs={12}>
                            <TableContainer>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Code</TableCell>
                                    <TableCell>Description</TableCell>
                                    <TableCell />
                                  </TableRow>
                                </TableHead>

                                <TableBody>
                                  {formulaPolicyFields.map(
                                    renderFormulaPolicies
                                  )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </VuiCardContent>
            </Grid>

            <Grid item xs={12} md={12}>
              <VuiCardContent title="Approval Setting">
                <Box p={2}>
                  <CashAdvanceApprovalSetting
                    control={control}
                    errors={errors}
                    policies={watchApprovalPolicies}
                    type={watchApprovalRequirementType}
                    typeName="approval_requirement_type"
                    fieldName="approval_policies"
                    label="Approval Advance"
                  />
                </Box>
              </VuiCardContent>
            </Grid>
          </Grid>
        </Grid>
        <FooterFormAction
          cancelUrl={"/apps/setting/policy/advance"}
          loading={loading}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
        />
      </Grid>

      <FormAddAdvanceFormula
        open={open}
        handleClose={handleClose}
        handleSave={(data) => handleSaveFormula(data)}
        formulaData={
          typeof formulaIndex === "number"
            ? formulaPolicyFields[formulaIndex]
            : null
        }
      />
    </>
  );
};

export default AdvancePolicyForm;
