import React, { useCallback, useMemo } from "react";
import { Box, Grid, Paper } from "@material-ui/core";
import {
  $clone,
  $cloneState,
  handleAxiosError,
  mapDataTableParams,
} from "../../../utilities/helpers/global";
import { useState } from "@hookstate/core/dist";
import VuiDataTable, {
  Column,
  Options,
} from "../../../@VodeaUI/VuiDataTable/Index";
import {
  defaultDataTableOption,
  defaultDeleteConfirmOption,
} from "../../../utilities/Option";
import { Link } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useConfirm } from "material-ui-confirm";
import { useIsMounted } from "../../../@VodeaUI";
import AssetsRepository from "../../../repositories/AssetRepository";
import { AxiosError, AxiosResponse } from "axios";
import {
  MuiAutoComplete,
  MuiIconButton,
  MuiSearch,
} from "../../../components/atoms";
import { sortedByOptions } from "../../../constants";
import { useTranslation } from "react-i18next";
import MuiButton from "../../../components/atoms/MuiButton";
import { TableActionForm, TitleHeader } from "../../../components/molecules";
import AssetCategoryRepository from "../../../repositories/AssetCategoryRepository";
import { IAssetCategory } from "../../../interfaces/AssetCategory";
import AssetRepository from "../../../repositories/AssetRepository";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { EditOutlined } from "@material-ui/icons";

const Assets: React.FC<any> = () => {
  const isMounted = useIsMounted();
  const data = useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const loading = useState(false);
  const options = useState<Options>({
    ...defaultDataTableOption,
    sortedBy: "asc",
    orderBy: "number",
  });
  const [filterCategory, setFilterCategory] =
    React.useState<IAssetCategory | null>(null);
  const { t } = useTranslation();

  const confirm = useConfirm();

  const handleDelete = (data: any) => {
    confirm(defaultDeleteConfirmOption)
      .then(() => deleteResource(data.id))
      .catch(() => {});
  };

  const deleteResource = (id: number) => {
    const loadingKey = enqueueSnackbar("Please wait...", {
      variant: "default",
      autoHideDuration: 5000,
      preventDuplicate: true,
    });

    AssetRepository.delete(id)
      .then((resp: any) => {
        if (isMounted.current) {
          loadData().then();
          closeSnackbar(loadingKey);
          enqueueSnackbar(resp.data.message, {
            variant: "success",
          });
        }
      })
      .catch((e: any) => {
        if (isMounted.current) {
          closeSnackbar(loadingKey);
          handleAxiosError(e, isMounted.current, enqueueSnackbar);
        }
      });
  };

  const columns: Column[] = [
    { key: "name", label: "Name" },
    { key: "number", label: "Asset Number" },
    { key: "holder_name", label: "Holder" },
    {
      key: "acquisition_date",
      label: "Since",
      type: "date",
      dateFormat: "DD MMMM YYYY",
    },
    {
      key: "id",
      label: "",
      sortable: false,
      render: (row) => {
        return (
          <Box display="flex" justifyContent={"flex-end"} alignItems={"center"}>
            <Link to={`/apps/assets/detail/${row.id}`}>
              <MuiButton label={t("View")} />
            </Link>

            <Box ml={2}>
              <Link to={`/apps/assets/${row.id}`}>
                <MuiIconButton>
                  <EditOutlined fontSize="small" />
                </MuiIconButton>
              </Link>
            </Box>

            <Box ml={1}>
              <MuiIconButton onClick={() => handleDelete(row)}>
                <DeleteOutlineIcon fontSize="small" />
              </MuiIconButton>
            </Box>
          </Box>
        );
      },
    },
  ];

  const loadData = useCallback(
    async (reset: boolean = false) => {
      if (isMounted.current) loading.set(true);
      if (reset) options.page.set(0);
      console.log(filterCategory);

      await AssetRepository.all({
        ...mapDataTableParams($cloneState(options)),
        asset_category_id: filterCategory?.id,
      })
        .then((response: AxiosResponse) => {
          const { data: responseData } = response;
          const { data: list, meta } = responseData;

          if (isMounted.current) {
            data.set(list);
            options.page.set(meta.current_page - 1);
            options.total.set(meta.total);
          }
          loading.set(false);
        })
        .catch((e: AxiosError) => {
          if (isMounted.current) {
            loading.set(false);
            handleAxiosError(e, isMounted.current, enqueueSnackbar);
          }
        });
    },
    [filterCategory]
  );

  useMemo(() => {
    (async () => {
      await loadData(true);
    })();
  }, [filterCategory]);

  const handleTableSearch = useCallback((value: string) => {
    if (isMounted.current) {
      options.search.set(value);
      loadData(true).then();
    }
  }, []);

  return (
    <>
      <TitleHeader
        title={"Assets"}
        subtitle={"List of assets"}
        component={
          <Box display="flex">
            <Box marginRight={1}>
              <Link to={"/apps/assets/log"}>
                <MuiButton label="Input Asset Log" />
              </Link>
            </Box>
            <Box marginRight={1}>
              <Link to={"/apps/assets/assign"}>
                <MuiButton label="Assign Asset" />
              </Link>
            </Box>
            <Box>
              <Link to={"/apps/assets/create"}>
                <MuiButton label="Add Asset" />
              </Link>
            </Box>
          </Box>
        }
      />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper variant="elevation" elevation={1}>
            <Box p={2}>
              <Grid
                container
                spacing={3}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Grid item>
                  <MuiSearch
                    value={$clone(options.search.value)}
                    callback={handleTableSearch}
                  />
                </Grid>
                <Grid item md={3}>
                  <MuiAutoComplete
                    isAsync={false}
                    value={sortedByOptions.find(
                      (option) => option.id === $clone(options.sortedBy.value)
                    )}
                    constantOptions={sortedByOptions}
                    onSelected={(newValue) => {
                      options.sortedBy.set(newValue.id);
                      loadData().then();
                    }}
                    muiTextField={{
                      label: t("form.sort.label"),
                    }}
                  />
                </Grid>
              </Grid>

              <Box mt={1}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={3}>
                    <MuiAutoComplete
                      repository={AssetCategoryRepository}
                      methodName="all"
                      value={filterCategory}
                      onSelected={(newValue) => {
                        setFilterCategory(newValue);
                      }}
                      muiTextField={{
                        label: t("form.category.label"),
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>

            <VuiDataTable
              data={data.get()}
              columns={columns}
              options={$clone(options.value)}
              loading={loading.get()}
              onChangeOptions={(values) => {
                options.set(values);
                loadData().then();
              }}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default Assets;
