import React, { useCallback, useMemo } from "react";
import { Box, Grid, IconButton, Paper, Tooltip } from "@material-ui/core";
import {
  $clone,
  $cloneState,
  mapDataTableParams,
} from "../../../../../utilities/helpers/global";
import { useState } from "@hookstate/core/dist";
import VuiDataTable, {
  Column,
  Options,
} from "../../../../../@VodeaUI/VuiDataTable/Index";
import { defaultDataTableOption } from "../../../../../utilities/Option";
import useIsMounted from "../../../../../@VodeaUI/hooks/useIsMounted";
import Policy from "../../../../../layouts/Apps/Preference/Policy";
import VuiCardContent from "../../../../../@VodeaUI/components/VuiCardContent";
import { Link } from "react-router-dom";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import { PolicyDataTable } from "../../../../../interfaces/PolicyDataTable";
import CashAdvancePolicyRepository from "../../../../../repositories/CashAdvancePolicyRepository";
import { AxiosResponse } from "axios";
import { sortedByOptions } from "../../../../../constants";
import { IApiResource } from "../../../../../interfaces/ApiResource";
import { MuiAutoComplete, MuiSearch } from "../../../../../components/atoms";
import { useTranslation } from "react-i18next";

const AdvancePolicy: React.FC<any> = () => {
  const isMounted = useIsMounted();
  const { t } = useTranslation();
  const [tableData, setTableData] = React.useState<PolicyDataTable[]>([]);
  const [tableDataLoading, setTableDataLoading] =
    React.useState<boolean>(false);
  const options = useState<Options>({
    ...defaultDataTableOption,
    sortedBy: "asc",
    orderBy: "name",
  });

  const columns = useState<Column[]>([
    { key: "name", label: "POLICY NAME" },
    { key: "code", label: "POLICY CODE" },
    {
      key: "valid_from",
      label: "EFFECTIVE ON",
      type: "date",
      dateFormat: "DD MMMM YYYY",
    },
    {
      key: "id",
      label: "",
      sortable: false,
      render: (row) => {
        return (
          <Box display="flex" justifyContent={"flex-end"}>
            <Link to={`${row.id}`}>
              <Tooltip title="Detail">
                <IconButton aria-label="more">
                  <MoreHorizOutlinedIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Link>
          </Box>
        );
      },
    },
  ]);

  const loadData = useCallback(async () => {
    if (isMounted.current) {
      setTableDataLoading(true);
      setTableData([]);
    }

    await CashAdvancePolicyRepository.all({
      ...mapDataTableParams($cloneState(options)),
    })
      .then((response: AxiosResponse<IApiResource<PolicyDataTable[]>>) => {
        if (isMounted.current) {
          setTableData(response.data.data);
          options.total.set(response.data.meta.total);
          setTableDataLoading(false);
        }
      })
      .catch(() => {
        if (isMounted.current) {
          setTableDataLoading(false);
        }
      });
  }, []);

  useMemo(() => {
    (async () => {
      await loadData();
    })();
  }, []);

  const handleTableSearch = useCallback((value: string) => {
    if (isMounted.current) {
      options.search.set(value);
      options.page.set(0);
      loadData();
    }
  }, []);

  return (
    <Policy url="create" buttonLabel="Add Advance Policy">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <VuiCardContent title="Advance Policy">
            <Paper variant="elevation" elevation={1}>
              <Box p={2}>
                <Grid container spacing={3} alignItems={"center"}>
                  <Grid item md={12} xs={12}>
                    <Grid
                      container
                      spacing={3}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                    >
                      <Grid item>
                        <MuiSearch
                          value={$clone(options.search.value)}
                          callback={handleTableSearch}
                        />
                      </Grid>
                      <Grid item md={3}>
                        <MuiAutoComplete
                          isAsync={false}
                          value={sortedByOptions.find(
                            (option) =>
                              option.id === $clone(options.sortedBy.value)
                          )}
                          constantOptions={sortedByOptions}
                          onSelected={(newValue) => {
                            options.sortedBy.set(newValue.id);
                            loadData();
                          }}
                          muiTextField={{
                            label: t("form.sort.label"),
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>

              <VuiDataTable
                loading={tableDataLoading}
                columns={columns.value}
                options={$clone(options.value)}
                data={tableData}
                onChangeOptions={(values: Options) => {
                  options.set(values);
                  loadData().then();
                }}
              />
            </Paper>
          </VuiCardContent>
        </Grid>
      </Grid>
    </Policy>
  );
};

export default AdvancePolicy;
