import React, { useEffect } from "react";
import PreferencesCompany from "../../../../../layouts/Apps/Preference/Company";
import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import VuiCardContent from "../../../../../@VodeaUI/components/VuiCardContent";
import _ from "lodash";
import { Controller, useForm } from "react-hook-form";
import TitleForm from "../../../../../components/molecules/TitleForm";
import FooterFormAction from "../../../../../components/FooterFormAction";
import { useState } from "@hookstate/core";
import FooterFormPolicy from "../../../../../components/FooterFormPolicy";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router";
import { useIsMounted } from "../../../../../utilities/helpers/hooks";
import { useSnackbar } from "notistack";
import { OptionInterface } from "./interface";
import DepartmentRepository from "../../../../../repositories/DepartmentRepository";
import {
  $clone,
  $cloneState,
  mapHookErrors,
  mapParams,
} from "../../../../../utilities/helpers/global";
import { AxiosError, AxiosResponse } from "axios";
import PositionRepository from "../../../../../repositories/PositionRepository";
import { Autocomplete } from "@material-ui/lab";
import {
  optionLabel,
  optionSelected,
} from "../../../../../utilities/helpers/select";

const PositionForm: React.FC<any> = () => {
  const { errors, handleSubmit, control, setValue, setError } = useForm({
    mode: "onChange",
  });
  const { id } = useParams();
  const navigate = useNavigate();
  const isMounted = useIsMounted();
  const loading = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const option = useState<OptionInterface>({
    parent: {
      options: [],
      loading: false,
      params: {},
      loadData: async () => {
        if (isMounted.current) {
          option.parent.loading.set(true);
        }

        await PositionRepository.select(
          mapParams($cloneState(option.parent.params))
        )
          .then(({ data }: AxiosResponse) => {
            if (isMounted.current) {
              option.parent.options.set(data.data);
              option.parent.loading.set(false);
            }
          })
          .catch(() => {});
      },
      onOpen: () => {
        if (isMounted.current) {
          option.parent.options.set([]);
          option.parent.loadData.get()();
        }
      },
    },
    department: {
      options: [],
      loading: false,
      params: {},
      loadData: async () => {
        if (isMounted.current) {
          option.department.loading.set(true);
        }

        await DepartmentRepository.select(
          mapParams($cloneState(option.department.params))
        )
          .then(({ data }: AxiosResponse) => {
            if (isMounted.current) {
              option.department.options.set(data.data);
              option.department.loading.set(false);
            }
          })
          .catch(() => {});
      },
      onOpen: () => {
        if (isMounted.current) {
          option.department.options.set([]);
          option.department.loadData.get()();
        }
      },
    },
  });

  const onSubmit = (formData: any) => {
    loading.set(true);

    let body = {
      ...formData,
      parent_id: _.get(formData, "parent_id.id"),
      department_id: _.get(formData, "department_id.id"),
    };

    if (id) {
      PositionRepository.update(id, { ...body })
        .then((resp: any) => {
          navigate("/apps/setting/company/position");
          enqueueSnackbar(resp.data.message, {
            variant: "success",
          });
        })
        .catch((e: any) => {
          if (e?.response?.data?.errors) {
            const errors = mapHookErrors(e.response.data.errors);
            Object.keys(errors).forEach((key: any) =>
              setError(key, errors[key])
            );

            enqueueSnackbar(e.response.data.message, {
              variant: "error",
            });
          }

          if (isMounted.current) {
            loading.set(false);
          }
        });
    } else {
      PositionRepository.create({ ...body })
        .then((resp: any) => {
          navigate("/apps/setting/company/position");
          enqueueSnackbar(resp.data.message, {
            variant: "success",
          });
        })
        .catch((e: any) => {
          if (e?.response?.data?.errors) {
            const errors = mapHookErrors(e.response.data.errors);
            Object.keys(errors).forEach((key: any) =>
              setError(key, errors[key])
            );

            enqueueSnackbar(e.response.data.message, {
              variant: "error",
            });
          }

          if (isMounted.current) {
            loading.set(false);
          }
        });
    }
  };

  const loadData = () => {
    PositionRepository.show(id, {
      with: ["parent", "department"],
    })
      .then((response: AxiosResponse) => {
        const { data: responseData } = response;
        const { data } = responseData;
        _.forEach(data, (value, name) => {
          if (name === "parent_id") {
            setValue(name, data.parent);
          } else if (name === "department_id") {
            setValue(name, data.department);
          } else {
            setValue(name, value);
          }
        });
      })
      .catch((err: AxiosError) => {});
  };

  useEffect(() => {
    if (id) {
      loadData();
    }
  }, []);

  return (
    <PreferencesCompany>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <VuiCardContent>
            <Box ml={2}>
              <TitleForm
                title={id ? "Edit Position" : "Add Position"}
                withBackUrl={true}
              />
            </Box>
            <Divider />
            <Box p={2}>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <Controller
                    render={({ value, name, onChange }) => {
                      return (
                        <TextField
                          name={name}
                          onChange={onChange}
                          value={value}
                          label="Position ID"
                          variant="outlined"
                          fullWidth
                          error={_.has(errors, "code")}
                          helperText={_.get(errors, "code.message")}
                        />
                      );
                    }}
                    name={"code"}
                    control={control}
                    defaultValue={""}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Controller
                    render={({ value, name, onChange }) => {
                      return (
                        <TextField
                          name={name}
                          onChange={onChange}
                          value={value}
                          label="Position Name"
                          variant="outlined"
                          fullWidth
                          error={_.has(errors, "name")}
                          helperText={_.get(errors, "name.message")}
                        />
                      );
                    }}
                    name={"name"}
                    control={control}
                    defaultValue={""}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <Controller
                    render={({ value, name, onChange }) => {
                      return (
                        <TextField
                          name={name}
                          onChange={onChange}
                          value={value}
                          label="Description"
                          variant="outlined"
                          fullWidth
                          error={_.has(errors, "description")}
                          helperText={_.get(errors, "description.message")}
                        />
                      );
                    }}
                    name={"description"}
                    control={control}
                    defaultValue={""}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Controller
                    render={({ value, name, onChange }) => (
                      <Autocomplete
                        value={value}
                        loading={option.parent.loading.get()}
                        options={$clone(option.parent.options.get())}
                        onOpen={option.parent.onOpen.get()}
                        getOptionLabel={(option: any) =>
                          optionLabel(option, "name")
                        }
                        getOptionSelected={(option: any, value: any) =>
                          optionSelected(option, value)
                        }
                        onChange={(event: any, newValue: any) => {
                          onChange(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name={name}
                            label="Head"
                            variant="outlined"
                          />
                        )}
                      />
                    )}
                    name={`parent_id`}
                    control={control}
                    defaultValue={null}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Controller
                    render={({ value, name, onChange }) => (
                      <Autocomplete
                        value={value}
                        loading={option.department.loading.get()}
                        options={$clone(option.department.options.get())}
                        onOpen={option.department.onOpen.get()}
                        getOptionLabel={(option: any) =>
                          optionLabel(option, "name")
                        }
                        getOptionSelected={(option: any, value: any) =>
                          optionSelected(option, value)
                        }
                        onChange={(event: any, newValue: any) => {
                          onChange(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name={name}
                            label="Department"
                            variant="outlined"
                          />
                        )}
                      />
                    )}
                    name={`department_id`}
                    control={control}
                    defaultValue={null}
                  />
                  {/*<Controller*/}
                  {/*  render={({ value, name, onChange }) => {*/}
                  {/*    return (*/}
                  {/*      <TextField*/}
                  {/*        name={name}*/}
                  {/*        onChange={onChange}*/}
                  {/*        value={value}*/}
                  {/*        label="Department"*/}
                  {/*        variant="outlined"*/}
                  {/*        fullWidth*/}
                  {/*        error={_.has(errors, "department_id")}*/}
                  {/*        helperText={_.get(errors, "department_id.message")}*/}
                  {/*      />*/}
                  {/*    );*/}
                  {/*  }}*/}
                  {/*  name={"department_id"}*/}
                  {/*  control={control}*/}
                  {/*  defaultValue={""}*/}
                  {/*/>*/}
                </Grid>
              </Grid>
            </Box>
          </VuiCardContent>
        </Grid>
        <FooterFormAction
          cancelUrl={`/apps/setting/company/position`}
          loading={loading.get()}
          onSubmit={onSubmit}
          handleSubmit={handleSubmit}
          labelForm={"Save Policy"}
        />
      </Grid>
    </PreferencesCompany>
  );
};

export default PositionForm;
