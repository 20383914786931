import { makeStyles } from "@material-ui/core/styles";
import { lightBlue } from "@material-ui/core/colors";

export const useStyles = makeStyles((theme) => ({
  displayNone: {
    display: "none",
  },
  paperWrapper: {
    width: "100%",
  },
  boxWrapper: {
    padding: "10px 20px",
  },
  fontColor: {
    color: theme.palette.primary.dark,
    fontWeight: "bold",
    display: "flex",
  },
  expiredFont: {
    color: "#707070",
  },
  titleColor: {
    color: "#1A1818",
  },
  buttonPrimary: {
    padding: "0.375rem 1rem",
    backgroundColor: "#013332",
    borderRadius: 8,
    textTransform: "capitalize",
    fontWeight: 400,
    boxShadow: "none",
  },
  buttonLg: {
    padding: "0.3rem 2.2rem",
  },
  icon: {
    marginTop: "1rem",
  },
  borderRadiusSelect: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px !important",
    },
  },
}));
