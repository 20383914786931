import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  statusWrapper: {
    textAlign: "left",

    "& div": {
      padding: "4px",
      borderRadius: "5px",
      fontSize: "12px",
      display: "inline-block",
    },
    "& .Approved": {
      backgroundColor: "rgba(158, 179, 153, 0.2)",
      color: "#9EB399",
    },
    "& .Waiting": {
      backgroundColor: "rgba(255, 157, 0, 0.2)",
      color: "#FF9D00",
    },
    "& .Rejected": {
      backgroundColor: "rgba(255, 0, 0, 0.2)",
      color: "#ff0000",
    },
    "& .Declined": {
      backgroundColor: "rgba(255, 0, 0, 0.2)",
      color: "#ff0000",
    },
  },
  title: {
    color: "#707070",
  },
  informationBox: {
    boxShadow: "0px 2px 8px #0000001a",
    position: "absolute",
    right: 55,
    padding: 12,
    zIndex: 10,
    backgroundColor: "white",
    borderRadius: 8,
    marginTop: 32,
  },
  noteText: {
    maxWidth: 150,
    overflowWrap: "break-word",
  },
  buttonPrimary: {
    backgroundColor: "#02827A",
    "&:hover": {
      backgroundColor: "#013332",
    },
  },
  detailTitle: {
    fontWeight: 600,
    marginTop: 8,
  },
  name: {
    color: "#02827A",
  },
}));
