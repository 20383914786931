import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiStepper-root": {
      padding: 0,
    },
    "& .MuiStep-alternativeLabel": {
      flex: 0,
    },
    "& .MuiStep-horizontal": {
      padding: 0,
    },
    "& .icon": {
      fontSize: 18,
    },
  },
  stepLabel: {
    fontSize: 14,
  },
  stepContentWrapper: {
    backgroundColor: "white",
    zIndex: 1,
    width: 154,
    display: "flex",
    borderRadius: "5px",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0 0.6px 8px 0 rgba(0,0,0,.25)",
    marginRight: 40,
  },
  active: {
    backgroundColor: "#013332",
    transition: "all 0.5ms ease-in-out",
    color: "white",
    "& svg": {
      "& path": {
        fill: "white",
      },
    },
  },
  completed: {
    backgroundColor: "#EAEAEA",
    transition: "all 0.5ms ease-in-out",
    color: "#707070",
    "& svg": {
      "& path": {
        fill: "#707070",
      },
    },
  },
  inActive: {
    backgroundColor: "#EAEAEA",
    transition: "all 0.5ms ease-in-out",
  },
  error: {
    backgroundColor: "#961c13",
    transition: "all 0.5ms ease-in-out",
    color: "white",
    "& svg": {
      "& path": {
        fill: "white",
      },
    },
  },
  iconWrapper: {
    padding: "8px",
    display: "flex",
    borderRadius: "5px",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
  },
}));
