import React from "react";
import { Box, Grid, SwipeableDrawer } from "@material-ui/core";
import { $clone } from "../../../utilities/helpers/global";
import { useState } from "@hookstate/core/dist";
import VuiDataTable, {
  Column,
  Options,
} from "../../../@VodeaUI/VuiDataTable/Index";
import { defaultDataTableOption } from "../../../utilities/Option";
import { makeStyles } from "@material-ui/core/styles";
import VuiNumberFormat from "../../../@VodeaUI/components/Input/VuiNumberFormat";
import moment from "moment";
import ArrowBackIosOutlinedIcon from "@material-ui/icons/ArrowBackIosOutlined";
import { MuiIconButtonRounded } from "../../../components/atoms";

interface Props {
  open: boolean;
  handleClose: any;
  handleOpen: any;
  data?: any;
}

const useStyles = makeStyles({
  dialogFooter: {
    paddingTop: 10,
    paddingBottom: 25,
    justifyContent: "center",
  },
  statusWrapper: {
    textAlign: "left",

    "& div": {
      padding: "4px",
      borderRadius: "5px",
      fontSize: "12px",
      display: "inline-block",
    },
    "& .APPROVED": {
      backgroundColor: "rgba(158, 179, 153, 0.2)",
      color: "#9EB399",
    },
    "& .COMPLETED": {
      backgroundColor: "rgba(158, 179, 153, 0.2)",
      color: "#9EB399",
    },
    "& .PENDING": {
      backgroundColor: "rgba(255, 157, 0, 0.2)",
      color: "#FF9D00",
    },
    "& .WAITING": {
      backgroundColor: "rgba(255, 157, 0, 0.2)",
      color: "#FF9D00",
    },
    "& .REJECTED": {
      backgroundColor: "rgba(255, 0, 0, 0.2)",
      color: "#ff0000",
    },
    "& .DECLINED": {
      backgroundColor: "rgba(255, 0, 0, 0.2)",
      color: "#ff0000",
    },
  },
  drawerWrapper: {
    "& .MuiTablePagination-root": {
      display: "none !important",
    },
  },
  swipeContainer: {
    "& .MuiDrawer-paperAnchorRight": {
      width: "100%",
    },
  },
});
const CashAdvanceModal: React.FC<Props> = ({
  open,
  handleClose,
  handleOpen,
  data,
}) => {
  const classes = useStyles();
  const loading = useState(false);
  const options = useState<Options>({
    ...defaultDataTableOption,
    sortedBy: "desc",
    orderBy: "date",
  });

  const columns: Column[] = [
    {
      key: "",
      label: "Employee",
      render: (row) => {
        return row.cash_advance_request.user.name;
      },
    },
    {
      key: "date",
      label: "Date",
      type: "date",
      render: (row) => {
        return (
          <>{moment(row.cash_advance_request.date).format("DD MMMM YYYY")}</>
        );
      },
    },
    {
      key: "desc",
      label: "Description",
      render: (row) => {
        return row.cash_advance_request.description;
      },
    },
    {
      key: "amount",
      label: "Amount",
      render: (row) => {
        return (
          <VuiNumberFormat
            data={row.cash_advance_request.amount}
            value={row.cash_advance_request.amount}
            prefix={"Rp "}
          />
        );
      },
    },
    {
      key: "stats",
      label: "Status",
      render: (row) => {
        return (
          <div
            className={
              classes.statusWrapper + " " + row.cash_advance_request.status
            }
          >
            <div className={row.cash_advance_request.status}>
              {row.cash_advance_request.status}
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <SwipeableDrawer
      anchor={"right"}
      onOpen={handleOpen}
      onClose={handleClose}
      open={open}
      className={classes.swipeContainer}
    >
      <Box m={1} px={2} pt={2}>
        <Grid container spacing={2} alignItems={"center"}>
          <Grid item>
            <MuiIconButtonRounded onClick={handleClose}>
              <ArrowBackIosOutlinedIcon />
            </MuiIconButtonRounded>
          </Grid>
        </Grid>
      </Box>
      <Box pt={2}>
        <div className={classes.drawerWrapper}>
          <Grid container>
            <Grid item xs={12}>
              <VuiDataTable
                data={data.cash_advance_request_items}
                columns={columns}
                options={$clone(options.value)}
                loading={loading.get()}
              />
            </Grid>
          </Grid>
        </div>
      </Box>
    </SwipeableDrawer>
  );
};

export default CashAdvanceModal;
