import {
  Menu,
  ButtonBase,
  Avatar,
  MenuItem,
  Box,
  Divider,
} from "@material-ui/core";
import React, { MouseEvent } from "react";
import { useStyles } from "../style";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import { getAliasesName } from "../../../../utilities/helpers/global";
import { OpenInNew as OpenInNewIcon } from "@material-ui/icons";
import { passportService } from "../../../../@VodeaUI";
import { logout as actionLogout } from "../../../../store/actions";
import { useDispatch, useSelector } from "react-redux";

interface Props {}

const Account: React.FC<Props> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user } = useSelector(({ vodea }: any) => {
    return {
      user: vodea.auth.user,
    };
  });

  const logout = async (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    await passportService
      .logout()
      .then(() => {})
      .catch(() => {});

    dispatch(actionLogout());
  };

  return (
    <PopupState variant="popover" popupId="profile-popup-menu">
      {(popupState) => (
        <>
          <ButtonBase
            classes={{
              root: classes.btnProfile,
            }}
            {...bindTrigger(popupState)}
          >
            <Avatar
              classes={{
                root: classes.profileAvatar,
              }}
            >
              {getAliasesName(user.name)}
            </Avatar>
          </ButtonBase>

          <Menu
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            classes={{
              paper: classes.menu,
            }}
            {...bindMenu(popupState)}
          >
            <span className="label-profile">{user.name}</span>

            <MenuItem>Profile</MenuItem>
            <MenuItem>
              <Box
                width={1}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <span>Account settings </span>
                <OpenInNewIcon fontSize="small" />
              </Box>
            </MenuItem>

            <Divider className={"divider-profile"} />
            <MenuItem component={"a"} href={"/logout"} onClick={logout}>
              Logout
            </MenuItem>
          </Menu>
        </>
      )}
    </PopupState>
  );
};

export default Account;
