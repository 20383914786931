import React, {useEffect, useState} from "react";
import _ from "lodash";
import {useStyles} from "./style";

const Status: React.FC<any> = (data: any) => {
    const classes = useStyles();

    const [stats, setStats] = useState('')

    const upperFirst = (item: any, col: string, oneCol= false) => {
        if (oneCol) {
            let a = _.upperFirst(_.lowerCase(_.get(item, col)).replace(/_/g, ' ')),
                b = a.split(' ');

            setStats(b[0])

        } else {
            setStats(_.upperFirst(_.lowerCase(_.get(item, col)).replace(/_/g, ' ')));
        }
    }

    useEffect(() => {
        upperFirst(data, "data");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[data]);

    return <div className={classes.statusWrapper}>
        <div className={stats}>
            {stats}
        </div>
    </div>
}

export default Status;
