import React from "react";
import NavigationBar from "../../../components/NavigationBar";
import { Grid, Typography } from "@material-ui/core";

interface Props {
  children?: React.ReactChild | React.ReactChild[];
  navData?: any;
  title?: string;
}

const defaultNavData: any[] = [
  {
    label: "General",
    href: "",
    path: "general",
    permissions: "any",
    children: [
      {
        label: "Personal Data",
        href: "/apps/people/personal-information/personal-data",
        path: "personal-data",
        permissions: "any",
      },
      // {
      //   label: "Employment Data",
      //   href: "/apps/people/personal-information/employment-data",
      //   path: "employment-data",
      //   permissions: "any",
      // },
      // {
      //   label: "Family Data",
      //   href: "/apps/people/personal-information/family-data",
      //   path: "family-data",
      //   permissions: "any",
      // },
    ],
  },
  {
    label: "Time",
    href: "",
    path: "Time",
    permissions: "any",
    children: [
      {
        label: "Time Off",
        href: "/apps/people/personal-information/time/time-off",
        path: "time-off",
        permissions: "any",
      },
      {
        label: "Attendance",
        href: "/apps/people/personal-information/time/attendance",
        path: "attendance",
        permissions: "any",
      },
    ],
  },
  {
    label: "Finance",
    href: "",
    path: "finance",
    permissions: "any",
    children: [
      {
        label: "Reimbursement",
        href: "/apps/people/personal-information/finance/reimbursement",
        path: "reimbursement",
        permissions: "any",
      },
      {
        label: "Advance",
        href: "/apps/people/personal-information/finance/advance",
        path: "advance",
        permissions: "any",
      },
      {
        label: "Settlement",
        href: "/apps/people/personal-information/finance/settlement",
        path: "settlement",
        permissions: "any",
      },
    ],
  },
  // {
  //     label: 'Asset',
  //     href: '',
  //     path: 'asset',
  //     permissions: 'any',
  //     children: []
  // },
  // {
  //     label: 'Facility',
  //     href: '',
  //     path: 'facility',
  //     permissions: 'any',
  //     children: []
  // },
  // {
  //     label: 'File',
  //     href: '',
  //     path: 'file',
  //     permissions: 'any',
  //     children:[]
  // },
];

const People: React.FC<Props> = ({
  title = "Personal Information",
  children,
  navData = defaultNavData,
}) => {
  return (
    <>
      <Grid container spacing={3} justifyContent="space-between">
        <Grid item xs={12}>
          <Typography variant="h5">{title}</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <NavigationBar data={navData} type="people" />
        </Grid>
        <Grid item xs={12} md={9}>
          {children}
        </Grid>
      </Grid>
    </>
  );
};

export default People;
