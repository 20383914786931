import VuiCardContent from "../../../../../@VodeaUI/components/VuiCardContent";
import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import { Controller, useFormContext } from "react-hook-form";
import _ from "lodash";
import { Autocomplete } from "@material-ui/lab";
import { identityExpiredTypeOptions } from "../../../../../constants";
import {
  optionLabel,
  optionSelected,
} from "../../../../../utilities/helpers/select";
import VuiFormHelperText from "../../../../../@VodeaUI/components/Input/VuiFormHelperText";
import { KeyboardDatePicker } from "@material-ui/pickers";
import React, { useEffect } from "react";
import { constantToOptions } from "../../../../../utilities/helpers/option.helper";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { modelSchemaInformationShape } from "../Schema";

const Information: React.FC<any> = ({ errorState }) => {
  const {
    watch,
    control,
    formState: { errors },
  } = useFormContext();
  const { constant } = useSelector(({ constant }: any) => {
    return {
      constant: constant.constant.payload,
    };
  });
  const navigate = useNavigate();
  const watchIdentityExpiredType = watch("identity_expired_type");
  const religionOptions = constantToOptions(constant, "RELIGIONS");
  const bloodTypeOptions = constantToOptions(constant, "BLOOD_TYPE_OPTIONS");
  const relationshipOptions = constantToOptions(
    constant,
    "MARITAL_STATUS_OPTIONS"
  );
  const userDetailIdentityTypeOptions = constantToOptions(
    constant,
    "USER_DETAIL_IDENTITY_TYPE_OPTIONS"
  );

  // useEffect(() => {
  //   Object.keys(modelSchemaInformationShape).map((key: any) => {
  //     if (_.has(errors, key)) {
  //       errorState.set(true);
  //     } else {
  //       errorState.set(false);
  //     }
  //   });
  // }, [errors]);

  return (
    <>
      <Box mt={3}>
        <VuiCardContent title="Personal Data">
          <Box p={2}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Controller
                  render={({ value, name, onChange }) => (
                    <TextField
                      name={name}
                      label="Name"
                      variant="outlined"
                      onChange={onChange}
                      value={value}
                      fullWidth
                      error={_.has(errors, "name")}
                      helperText={_.get(errors, "name.message")}
                    />
                  )}
                  name={`name`}
                  control={control}
                  defaultValue={""}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  render={({ value, name, onChange }) => (
                    <TextField
                      name={name}
                      label="Phone"
                      variant="outlined"
                      onChange={onChange}
                      value={value}
                      fullWidth
                      error={_.has(errors, "phone")}
                      helperText={_.get(errors, "phone.message")}
                    />
                  )}
                  name={`phone`}
                  control={control}
                  defaultValue={""}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  render={({ value, name, onChange }) => (
                    <TextField
                      name={name}
                      label="Email"
                      variant="outlined"
                      onChange={onChange}
                      value={value}
                      fullWidth
                      error={_.has(errors, "email")}
                      helperText={_.get(errors, "email.message")}
                    />
                  )}
                  name={`email`}
                  control={control}
                  defaultValue={""}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Controller
                  render={({ value, name, onChange }) => (
                    <Autocomplete
                      value={value}
                      options={relationshipOptions}
                      getOptionLabel={(option: any) => optionLabel(option)}
                      getOptionSelected={(option: any, value: any) =>
                        optionSelected(option, value)
                      }
                      onChange={(event: any, newValue: any) => {
                        onChange(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name={name}
                          label="Relationship"
                          variant="outlined"
                          error={_.has(errors, `relationship`)}
                          helperText={_.get(errors, `relationship.message`)}
                        />
                      )}
                    />
                  )}
                  name={`relationship`}
                  control={control}
                  defaultValue={null}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Controller
                  render={({ value, name, onChange }) => (
                    <Autocomplete
                      value={value}
                      options={religionOptions}
                      getOptionLabel={(option: any) => optionLabel(option)}
                      getOptionSelected={(option: any, value: any) =>
                        optionSelected(option, value)
                      }
                      onChange={(event: any, newValue: any) => {
                        onChange(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name={name}
                          label="Religion"
                          variant="outlined"
                          error={_.has(errors, `religion`)}
                          helperText={_.get(errors, `religion.message`)}
                        />
                      )}
                    />
                  )}
                  name={`religion`}
                  control={control}
                  defaultValue={null}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="body2">Gender</Typography>
                <Controller
                  render={({ value, name, onChange }) => (
                    <RadioGroup
                      row
                      onChange={(event, newValue: any) => onChange(newValue)}
                      value={value}
                    >
                      <FormControlLabel
                        label="Male"
                        value={"MALE"}
                        name={name}
                        checked={value === "MALE"}
                        control={<Radio color="primary" />}
                      />
                      <FormControlLabel
                        label="Female"
                        name={name}
                        value={"FEMALE"}
                        checked={value === "FEMALE"}
                        control={<Radio color="primary" />}
                      />
                    </RadioGroup>
                  )}
                  name={"gender"}
                  control={control}
                  defaultValue={"MALE"}
                />
                <VuiFormHelperText
                  error={_.has(errors, "gender")}
                  message={_.get(errors, "gender.message")}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Controller
                  render={({ value, name, onChange }) => (
                    <KeyboardDatePicker
                      name={name}
                      disableToolbar
                      fullWidth
                      variant="inline"
                      inputVariant="outlined"
                      label="Date of Birth"
                      format="DD MMMM YYYY"
                      error={_.has(errors, "date_of_birth")}
                      helperText={_.get(errors, "date_of_birth.message")}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                  name="date_of_birth"
                  control={control}
                  defaultValue={new Date()}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Controller
                  render={({ value, name, onChange }) => (
                    <TextField
                      name={name}
                      label="Place of Birth"
                      variant="outlined"
                      onChange={onChange}
                      value={value}
                      fullWidth
                      error={_.has(errors, "place_of_birth")}
                      helperText={_.get(errors, "place_of_birth.message")}
                    />
                  )}
                  name={`place_of_birth`}
                  control={control}
                  defaultValue={""}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Controller
                  render={({ value, name, onChange }) => (
                    <Autocomplete
                      value={value}
                      options={bloodTypeOptions}
                      getOptionLabel={(option: any) => optionLabel(option)}
                      getOptionSelected={(option: any, value: any) =>
                        optionSelected(option, value)
                      }
                      onChange={(event: any, newValue: any) => {
                        onChange(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name={name}
                          label="Blood Type"
                          variant="outlined"
                          error={_.has(errors, `blood_type`)}
                          helperText={_.get(errors, `blood_type.message`)}
                        />
                      )}
                    />
                  )}
                  name={`blood_type`}
                  control={control}
                  defaultValue={null}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  render={({ value, name, onChange }) => (
                    <TextField
                      name={name}
                      label="Job"
                      variant="outlined"
                      onChange={onChange}
                      value={value}
                      fullWidth
                      error={_.has(errors, "job")}
                      helperText={_.get(errors, "job.message")}
                    />
                  )}
                  name={`job`}
                  control={control}
                  defaultValue={""}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  render={({ value, name, onChange }) => (
                    <TextField
                      name={name}
                      label="NIK"
                      variant="outlined"
                      onChange={onChange}
                      value={value}
                      type="number"
                      InputProps={{ inputProps: { min: 0 } }}
                      fullWidth
                      error={_.has(errors, "nik")}
                      helperText={_.get(errors, "nik.message")}
                    />
                  )}
                  name={`nik`}
                  control={control}
                  defaultValue={""}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  render={({ value, name, onChange }) => (
                    <TextField
                      name={name}
                      label="Address"
                      variant="outlined"
                      onChange={onChange}
                      value={value}
                      fullWidth
                      error={_.has(errors, "address")}
                      helperText={_.get(errors, "address.message")}
                    />
                  )}
                  name={`address`}
                  control={control}
                  defaultValue={""}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  render={({ value, name, onChange }) => (
                    <TextField
                      name={name}
                      label="City"
                      variant="outlined"
                      onChange={onChange}
                      value={value}
                      fullWidth
                      error={_.has(errors, "city")}
                      helperText={_.get(errors, "city.message")}
                    />
                  )}
                  name={`city`}
                  control={control}
                  defaultValue={""}
                />
              </Grid>
            </Grid>
          </Box>
        </VuiCardContent>
      </Box>
      <Box mt={3}>
        <VuiCardContent title="Identity Card">
          <Box p={2}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Controller
                  render={({ value, name, onChange }) => (
                    <Autocomplete
                      value={value}
                      options={userDetailIdentityTypeOptions}
                      getOptionLabel={(option: any) => optionLabel(option)}
                      getOptionSelected={(option: any, value: any) =>
                        optionSelected(option, value)
                      }
                      onChange={(event: any, newValue: any) => {
                        onChange(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name={name}
                          label="Identity Type"
                          variant="outlined"
                          error={_.has(errors, `identity_type`)}
                          helperText={_.get(errors, `identity_type.message`)}
                        />
                      )}
                    />
                  )}
                  name={`identity_type`}
                  control={control}
                  defaultValue={null}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Controller
                  render={({ value, name, onChange }) => (
                    <Autocomplete
                      value={value}
                      options={identityExpiredTypeOptions}
                      getOptionLabel={(option: any) => optionLabel(option)}
                      getOptionSelected={(option: any, value: any) =>
                        optionSelected(option, value)
                      }
                      onChange={(event: any, newValue: any) => {
                        onChange(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name={name}
                          label="Identity Expired Type"
                          variant="outlined"
                          error={_.has(errors, `identity_expired_type`)}
                          helperText={_.get(
                            errors,
                            `identity_expired_type.message`
                          )}
                        />
                      )}
                    />
                  )}
                  name={`identity_expired_type`}
                  control={control}
                  defaultValue={null}
                />
              </Grid>

              {watchIdentityExpiredType?.id === "DATE" && (
                <Grid item xs={12}>
                  <Controller
                    render={({ value, name, onChange }) => (
                      <KeyboardDatePicker
                        name={name}
                        disableToolbar
                        fullWidth
                        variant="inline"
                        minDate={new Date("2020-01-01")}
                        inputVariant="outlined"
                        label="Identity Expired Date"
                        format="DD/MM/YYYY"
                        error={_.has(errors, "identity_expired_date")}
                        helperText={_.get(
                          errors,
                          "identity_expired_date.message"
                        )}
                        value={value}
                        onChange={onChange}
                      />
                    )}
                    name={`identity_expired_date`}
                    control={control}
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <Controller
                  render={({ value, name, onChange }) => (
                    <TextField
                      name={name}
                      label="Identity Number"
                      variant="outlined"
                      onChange={onChange}
                      value={value}
                      fullWidth
                      error={_.has(errors, "identity_number")}
                      helperText={_.get(errors, "identity_number.message")}
                    />
                  )}
                  name={`identity_number`}
                  control={control}
                  defaultValue={""}
                />
              </Grid>
            </Grid>
          </Box>
        </VuiCardContent>
      </Box>
    </>
  );
};

export default Information;
