import React, { CSSProperties, FC } from "react";
import {
  Box,
  Button,
  ButtonProps,
  CircularProgress,
  PropTypes,
  Typography,
} from "@material-ui/core";
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";

interface IMuiButtonStyled extends ButtonProps {}
interface IMuiButton {
  label: string;
  onClick?: () => void;
  variant?: "text" | "outlined" | "contained";
  size?: "small" | "medium" | "large";
  color?: PropTypes.Color;
  startIcon?: React.ReactNode;
  loading?: boolean;
  children?: any;
  disabled?: boolean;
  styles?: CSSProperties;
}

const MuiButtonStyled = withStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: "0.3rem 1.6rem",
      borderRadius: theme.spacing(0.75),
      textTransform: "capitalize",
      fontWeight: 400,
      boxShadow: "none",
      fontSize: 14,
      minWidth: 84,
      transition: "all 1s",
      color: `${theme.palette.secondary.dark}`,
      "&.MuiButton-containedSecondary": {},
      "&.MuiButton-sizeLarge": {
        padding: "0.3rem 2rem",
      },
      "&.MuiButton-sizeSmall": {
        padding: "0.3rem 1.1rem",
        fontSize: 12,
      },
      "&.MuiButton-containedPrimary": {
        backgroundColor: `${theme.palette.primary.dark}`,
        color: "#fff",
        "&.Mui-disabled": {
          backgroundColor: "rgba(0, 0, 0, 0.12)",
          color: "rgba(0, 0, 0, 0.26)",
        },
        "&:hover": {
          boxShadow:
            "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
        },
      },
    },
  })
)((props: IMuiButtonStyled) => {
  return <Button {...props} />;
});

const MuiButton: FC<IMuiButton> = ({
  label = "",
  onClick,
  variant = "contained",
  size = "medium",
  color = "primary",
  loading = false,
  children = null,
  disabled = false,
  startIcon,
  styles,
}) => {
  return (
    <MuiButtonStyled
      onClick={onClick}
      variant={variant}
      size={size}
      color={color}
      startIcon={startIcon}
      disabled={disabled}
      style={styles}
    >
      {children}
      {label}
      {loading && (
        <CircularProgress
          color="inherit"
          size={size === "large" ? 18 : 16}
          style={{ marginLeft: 10 }}
        />
      )}
    </MuiButtonStyled>
  );
};

export default React.memo(MuiButton);
