import React, { useMemo, useState } from "react";
import { Box, Grid } from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import VuiCardContent from "../../../../@VodeaUI/components/VuiCardContent";
import _ from "lodash";
import TitleForm from "../../../../components/molecules/TitleForm";
import AssetRepository from "../../../../repositories/AssetRepository";
import UserRepository from "../../../../repositories/UserRepository";
import moment from "moment";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  MuiAutoComplete,
  MuiDatePicker,
  MuiTextField,
} from "../../../../components/atoms";
import { IConstantOption } from "../../../../interfaces/ConstantOption";
import { validationSchema } from "./validation";
import { useTranslation } from "react-i18next";
import { IAssignAsset } from "../../../../interfaces/AssignAsset";
import FooterFormAction from "../../../../components/FooterFormAction";
import { useNavigate } from "react-router-dom";
import { constantToOptions } from "../../../../utilities/helpers/option.helper";
import { useSelector } from "react-redux";
import useIsMounted from "../../../../utilities/hooks/use-is-mounted.hook";
import { formatFormData } from "../../../../utilities/helpers/form";
import AssignAssetRepository from "../../../../repositories/AssignAssetRepository";
import { AxiosError, AxiosResponse } from "axios";
import { IApiResource } from "../../../../interfaces/ApiResource";
import { handleAxiosSuccessSave } from "../../../../utilities/helpers/axios-success.helper";
import { handleAxiosErrorSave } from "../../../../utilities/helpers/axios-error.helper";
import { useSnackbar } from "notistack";

const defaultValues: IAssignAsset = {
  type: null,
  asset_id: null,
  date: moment(),
  description: "",
  user_id: null,
};

const AssignAsset: React.FC<any> = () => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const { enqueueSnackbar } = useSnackbar();
  const { constant } = useSelector(({ constant }: any) => {
    return {
      constant: constant.constant.payload,
    };
  });
  const navigate = useNavigate();
  const [assetTypeOptions, setAssetTypeOptions] = useState<IConstantOption[]>(
    []
  );
  const [loading, setLoading] = useState<boolean>(false);

  const { control, errors, handleSubmit, setValue, watch, setError } =
    useForm<IAssignAsset>({
      mode: "onChange",
      defaultValues: defaultValues,
      resolver: yupResolver(validationSchema(t)),
    });

  const watchType = watch("type");
  const watchDate = watch("date");

  useMemo(() => {
    if (isMounted.current) {
      setAssetTypeOptions(
        constantToOptions(constant, "ASSET_USER_TYPE_OPTIONS")
      );
    }
  }, [constant]);

  const onSubmit = async (data: IAssignAsset) => {
    if (isMounted.current) setLoading(true);
    const formData = formatFormData(data);
    formData.type = formData.type.id;

    await AssignAssetRepository.create(formData)
      .then((response: AxiosResponse<IApiResource<IAssignAsset>>) => {
        if (isMounted.current) setLoading(false);
        handleAxiosSuccessSave(
          response,
          enqueueSnackbar,
          navigate,
          "/apps/assets"
        );
      })
      .catch((error: AxiosError) => {
        if (isMounted.current) setLoading(false);
        handleAxiosErrorSave(error, setError, enqueueSnackbar);
      });
  };

  return (
    <>
      <TitleForm title="Assign Asset" withBackUrl={true} />

      <Grid container spacing={3}>
        <Grid item md={12} xs={12}>
          <VuiCardContent>
            <Box p={2}>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <Controller
                    render={({ value, name, onChange }) => (
                      <MuiAutoComplete
                        isAsync={false}
                        constantOptions={assetTypeOptions}
                        value={value}
                        onSelected={(newValue) => {
                          onChange(newValue);
                          setValue("asset_id", null);
                        }}
                        muiTextField={{
                          label: t("form.assetType.label"),
                          error: _.has(errors, name),
                          helperText: _.get(errors, `${name}.message`),
                        }}
                      />
                    )}
                    name="type"
                    control={control}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Controller
                    render={({ value, name, onChange }) => (
                      <MuiAutoComplete
                        repository={UserRepository}
                        value={value}
                        onSelected={(newValue) => onChange(newValue)}
                        muiTextField={{
                          label: t("form.assetUser.label"),
                          error: _.has(errors, name),
                          helperText: _.get(errors, `${name}.message`),
                        }}
                      />
                    )}
                    name="user_id"
                    control={control}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Controller
                    render={({ value, name, onChange }) => (
                      <MuiAutoComplete
                        repository={AssetRepository}
                        params={{
                          for: "asset-user",
                          date: moment(watchDate).format("YYYY-MM-DD"),
                          type: watchType?.id,
                        }}
                        disabled={watchType === null}
                        value={value}
                        onSelected={(newValue) => onChange(newValue)}
                        muiTextField={{
                          label: t("form.assetName.label"),
                          error: _.has(errors, name),
                          helperText: _.get(errors, `${name}.message`),
                        }}
                      />
                    )}
                    name="asset_id"
                    control={control}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Controller
                    render={({ value, name, onChange }) => (
                      <MuiDatePicker
                        value={value}
                        name={name}
                        label={t("form.date.label")}
                        format="DD MMMM YYYY"
                        onChange={onChange}
                        error={_.has(errors, name)}
                        helperText={_.get(errors, `${name}.message`)}
                      />
                    )}
                    name="date"
                    control={control}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Controller
                    render={({ value, name, onChange }) => (
                      <MuiTextField
                        name={name}
                        onChange={onChange}
                        label={t("form.description.label")}
                        value={value}
                        error={_.has(errors, name)}
                        helperText={_.get(errors, `${name}.message`)}
                      />
                    )}
                    name="description"
                    control={control}
                  />
                </Grid>
              </Grid>
            </Box>
          </VuiCardContent>
        </Grid>

        <FooterFormAction
          cancelUrl={"/apps/assets"}
          loading={loading}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          labelForm={"Assign Asset"}
          submitBtnLabel={"Assign"}
        />
      </Grid>
    </>
  );
};

export default AssignAsset;
