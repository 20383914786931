import { State, StateMethods } from "@hookstate/core";
import _ from "lodash";
import moment from "moment";
import { AxiosError } from "axios";
import { constantToOptions } from "./option.helper";
import { backendDate } from "./date.helper";

export const isArray = (value: any) => _.isArray(value);

export const calcBusinessDays = (
  startDate: any,
  finishDate: any,
  isStartDateHalf = false,
  isFinishDateHalf = false
) => {
  startDate = new Date(moment(startDate).toDate());
  finishDate = new Date(moment(finishDate).toDate());

  let count = 0;
  let curDate = startDate;
  let finishedDate = finishDate;
  const differenceDay = moment(backendDate(finishDate)).diff(
    moment(backendDate(startDate)),
    "days"
  );

  while (curDate <= finishedDate) {
    const dayOfWeek = curDate.getDay();
    if (!(dayOfWeek == 6 || dayOfWeek == 0)) count++; // work day validation
    curDate.setDate(curDate.getDate() + 1);
  }

  if (differenceDay >= 0 && isStartDateHalf) {
    count -= 0.5;
  }

  if (differenceDay >= 0 && isFinishDateHalf) {
    count -= 0.5;
  }

  return count;
};

export const getAliasesName = (value: string) => {
  const arrayName = value.split(" ");
  let aliasesName = value.charAt(0) + value.charAt(1);

  if (arrayName.length >= 2) {
    aliasesName = arrayName[0].charAt(0) + arrayName[1].charAt(0);
  }

  return aliasesName.toUpperCase();
};

export const addQuerySearchParam = (
  url: string,
  name: string,
  value: string
) => {
  let objectUrl = new URL(url);
  objectUrl.searchParams.append(name, value);
  return objectUrl.href;
};

export const ordinalSuffixOf = (value: any) => {
  if (!Number(value)) {
    return "";
  }

  const j = parseFloat(value) % 10;
  const k = parseFloat(value) % 100;

  if (j == 1 && k != 11) {
    return value + "st";
  }

  if (j == 2 && k != 12) {
    return value + "nd";
  }

  if (j == 3 && k != 13) {
    return value + "rd";
  }

  return value + "th";
};

/*eslint-disable eqeqeq*/
export const insertQuerySearch = (key: string, value: string | number) => {
  key = encodeURIComponent(key);
  value = encodeURIComponent(value);

  let s = document.location.search;
  const kvp = key + "=" + value;

  const r = new RegExp("(&|\\?)" + key + "=[^&]*");

  s = s.replace(r, "$1" + kvp);

  if (!RegExp.$1) {
    s += (s.length > 0 ? "&" : "?") + kvp;
  }

  window.history.replaceState(null, document.title, s);
};

export const $cloneState = (value: State<any>) => {
  return $clone(value.value);
};

export const $clone = (value: any) => {
  return JSON.parse(JSON.stringify(value));
};

export const mapDataTableParams = (parameters: any, filters: any = null) => {
  let params: any = {
    page: parameters.page ? parameters.page + 1 : 1,
    per_page: parameters.perPage,
  };

  if (parameters.search) {
    params.search = parameters.search;
  }

  if (parameters.orderBy && parameters.sortedBy) {
    params.order_by = parameters.orderBy;
    params.sorted_by = parameters.sortedBy;
  }

  if (filters !== null) {
    Object.keys(filters).forEach((key: string) => {
      const { model } = filters[key];
      if (model !== null) {
        if (Array.isArray(model)) {
          params[key] = model.map((a) =>
            isNaN(Number(a)) ? _.get(a, "id") : a
          );
        } else if (_.has(model, "id")) {
          params[key] = model.id;
        } else {
          params[key] = model;
        }
      }
    });
  }

  return params;
};

export const mapParams = (parameters: any) => {
  let params: any = {};

  Object.keys(parameters).forEach((key: string) => {
    const parameter = parameters[key];
    if (parameter !== null) {
      if (Array.isArray(parameter)) {
        params[key] = parameter.map((a) => _.get(a, "id", a));
      } else if (_.has(parameter, "id")) {
        params[key] = parameter.id;
      } else {
        params[key] = parameter;
      }
    }
  });

  return params;
};

export const mapHookErrors = (errors: any) => {
  let newErrors: any = {};

  Object.keys(errors).forEach((key) => {
    Object.assign(newErrors, {
      [key]: {
        message: errors[key][0]
          .replace(/_id/g, "")
          .replace(/ id/g, "")
          .replace(/\.\d{1,3}\./g, (match: string) => {
            return ` ${match
              .replace(/\./g, "")
              .replace(/\d{1,3}/g, (a) =>
                ordinalSuffixOf(parseFloat(a) + 1)
              )} `;
          })
          .replace(/\w\.\w/g, (match: string) => match.replace(/\./g, " "))
          .replace(/_/g, " "),
      },
    });
  });

  return newErrors;
};

export const handleAxiosError = (
  e: AxiosError,
  isMounted: boolean,
  enqueueSnackbar: any,
  navigate?: any
) => {
  if (isMounted) {
    if (e?.response?.data) {
      const { status } = e.response;
      if (status === 404) {
        if (navigate) {
          navigate("/404", { replace: true });
        }
      } else {
        if (navigate) {
          navigate(-1);
        }
        if (enqueueSnackbar) {
          enqueueSnackbar(e.response.data.message, {
            variant: "error",
          });
        }
      }
    } else {
      if (navigate) {
        navigate(-1);
      }
      if (enqueueSnackbar) {
        enqueueSnackbar("Network Error", {
          variant: "error",
        });
      }
    }
  }
};

export const getRowData = (tableMeta: any) => {
  const { rowIndex, tableData } = tableMeta;

  const data = $clone(tableData);

  return data[rowIndex];
};

export const countEntity = (arr: any, entity: any) => {
  const countArr = arr.filter((item: any) => item.entity === entity);

  return countArr.length;
};

export const totalCurrencyFormat = (arr: any, lodashKey = "") => {
  return arr.reduce((total: any, item: any) => {
    let tempTotal = parseFloat(total);
    let tempItem = parseFloat(_.get(item, lodashKey));

    return (
      (isNaN(tempTotal) ? 0 : tempTotal) + (isNaN(tempItem) ? 0 : tempItem)
    );
  }, 0);
};

export const onOpenOption = (
  data: any,
  type: string,
  state: StateMethods<any>,
  hasAdditional: boolean = false
) => {
  state.set([]);
  if (hasAdditional) {
    state.set((s: any) => {
      const options = {
        id: "NONE",
        name: "None",
      };
      const additionOptions = constantToOptions(data, type);
      return [options, ...additionOptions];
    });
  } else {
    state.set((s: any) => {
      const additionOptions = constantToOptions(data, type);
      return [...s, ...additionOptions];
    });
  }
};

export const generateEmployeeNavData = (id: string) => {
  return [
    {
      label: "General",
      href: "",
      path: "general",
      permissions: "any",
      children: [
        {
          label: "Personal Data",
          href: `/apps/people/employee/employee-detail/${id}/general/personal-data`,
          path: "personal-data",
          permissions: "any",
        },
      ],
    },
    {
      label: "Time",
      href: "",
      path: "Time",
      permissions: "any",
      children: [
        {
          label: "Attendance",
          href: `/apps/people/employee/employee-detail/${id}/time/attendance`,
          path: "attendance",
          permissions: "any",
        },
        {
          label: "Time Off",
          href: `/apps/people/employee/employee-detail/${id}/time/time-off`,
          path: "time-off",
          permissions: "any",
        },
      ],
    },
    {
      label: "Finance",
      href: "",
      path: "finance",
      permissions: "any",
      children: [
        {
          label: "Reimbursement",
          href: `/apps/people/employee/employee-detail/${id}/finance/reimbursement`,
          path: "reimbursement",
          permissions: "any",
        },
        {
          label: "Advance",
          href: `/apps/people/employee/employee-detail/${id}/finance/advance`,
          path: "advance",
          permissions: "any",
        },
        {
          label: "Settlement",
          href: `/apps/people/employee/employee-detail/${id}/finance/settlement`,
          path: "settlement",
          permissions: "any",
        },
      ],
    },
  ];
};
