import React, { useCallback, useMemo } from "react";
import {
  Box,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import VuiCardContent from "../../../../../@VodeaUI/components/VuiCardContent";
import _ from "lodash";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import TitleForm from "../../../../../components/molecules/TitleForm";
import FooterFormAction from "../../../../../components/FooterFormAction";
import { useState } from "@hookstate/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { withStyles } from "@material-ui/core/styles";
import MuiTableCell from "@material-ui/core/TableCell";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { ConstantOptionInterface } from "./interface";
import { constantToOptions } from "../../../../../utilities/helpers/option.helper";
import { $clone } from "../../../../../utilities/helpers/global";
import { AxiosError, AxiosResponse } from "axios";
import {
  approvalRequirementTypeOptions,
  generatedAfterOptions,
  generateOnMonthlyOptions,
} from "../../../../../constants";
import { useIsMounted } from "../../../../../@VodeaUI";
import { formatFormData } from "../../../../../utilities/helpers/form";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router";
import ReimbursementPolicyRepository from "../../../../../repositories/ReimbursementPolicyRepository";
import { Capitalize } from "../../../../../utilities/helpers/string";
import { handleAxiosErrorSave } from "../../../../../utilities/helpers/axios-error.helper";
import Loading from "../../../../../components/Loading";
import ApprovalSetting from "../../../../../components/Policy/ApprovalSetting";
import {
  ErrorText,
  MuiAutoComplete,
  MuiButton,
  MuiCheckbox,
  MuiDatePicker,
  MuiTextField,
  NumberFieldFormat,
} from "../../../../../components/atoms";
import { useTranslation } from "react-i18next";
import { FormAddReimbursementFormula } from "../../../../../components/forms";
import { IReimbursementFormulaPolicy } from "../../../../../interfaces/ReimbursementFormulaPolicy";
import EmploymentStatusRepository from "../../../../../repositories/EmploymentStatusRepository";

const defaultValues = {
  code: "",
  name: "",
  description: "",
  valid_from: moment().add(2, "day").format("YYYY-MM-DD"),
  is_default: false,
  is_take_home_pay: false,
  is_effective_from_join_date: false,
  type: null,
  taxable: null,
  approval_requirement_type: approvalRequirementTypeOptions[3],
  expired_type: null,
  emerge_after_month: 0,
  approval_policies: [],
  is_prorate: false,
  is_emerge_at_join: false,
  generate_after: null,
  reimbursement_emerge_employment_status_policies: [],
  reimbursement_formula_policies: [],
};

const FinanceReimbursementForm: React.FC<any> = () => {
  const [open, setOpen] = React.useState(false);
  const { id } = useParams();
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const [isFetchingData, setIsFetchingData] = React.useState<boolean>(false);
  const { constant } = useSelector(({ constant }: any) => {
    return {
      constant: constant.constant.payload,
    };
  });
  const [formulaIndex, setFormulaIndex] = React.useState<number | null>(null);
  const navigate = useNavigate();
  const monthOptions = moment.months().map((label, value) => {
    return {
      id: value + 1,
      name: label,
    };
  });
  const { enqueueSnackbar } = useSnackbar();
  const { errors, handleSubmit, control, register, watch, setValue, setError } =
    useForm({
      mode: "onChange",
      shouldUnregister: false,
      defaultValues: defaultValues,
    });

  const {
    fields: formulaPolicyFields,
    append: formulaPolicyAppend,
    remove: formulaPolicyRemove,
  } = useFieldArray({
    control,
    keyName: "key",
    name: "reimbursement_formula_policies",
  });

  const watchType = watch("type");
  const watchApprovalRequirementType = watch("approval_requirement_type");
  const watchApprovalPolicies = watch("approval_policies");
  const watchExpiredType = watch("expired_type");
  const watchGenerateType = watch("generate_type");
  const watchGenerateAfter = watch("generate_after");
  const watchEmerge = watch("is_emerge_at_join");

  const taxableOptions = useState<ConstantOptionInterface[]>(
    constantToOptions(
      constant,
      "REIMBURSEMENT_POLICY_TAX_GROUP_TYPE_OPTIONS",
      true
    )
  );
  const renewalOptions = useState<ConstantOptionInterface[]>(
    constantToOptions(constant, "REIMBURSEMENT_POLICY_TYPE_OPTIONS")
  );
  const approvalRequirementTypeOptions = useState<ConstantOptionInterface[]>(
    constantToOptions(constant, "APPROVAL_REQUIREMENT_TYPE_OPTIONS")
  );
  const approvalPolicyTypeOptions = useState<ConstantOptionInterface[]>(
    constantToOptions(constant, "APPROVAL_POLICY_TYPE_OPTIONS")
  );
  const expiredTypeOptions = useState<ConstantOptionInterface[]>(
    constantToOptions(
      constant,
      "REIMBURSEMENT_POLICY_EXPIRED_TYPE_OPTIONS",
      true
    )
  );

  const loading = useState(false);

  const onSubmit = (data: any) => {
    if (isMounted.current) loading.set(true);
    const tempFormulaPolicies: any[] = [];
    const formData = formatFormData(data);
    formData.tax_group = formData?.taxable?.id;
    formData.expired_type = formData?.expired_type?.id;
    formData.type = formData?.type?.id;
    formData.is_default = Number(formData?.is_default);

    if (formData.type !== "ANNIVERSARY" && formData.generate_type) {
      formData.generate_type = formData?.generate_type?.id;
    }

    if (formData.type !== "ANNUALLY") {
      delete formData.is_prorate;
    }

    if (formData.generate_after) {
      formData.generate_after = formData.generate_after?.id;
    }

    if (formData.expired_type === "TOTAL_MONTH") {
      delete formData.expired_date;
    } else {
      delete formData.expired_total_month;
    }

    if (formData.type === "MONTHLY" && formData.generate_type === "DEFINED") {
      formData.monthly_issued_type = formData.generate_type;
      delete formData.monthly_issued_day;
    }

    if (formData.tax_group === "NONE") {
      delete formData.tax_group;
    }

    if (
      formData.reimbursement_formula_policies &&
      formData.reimbursement_formula_policies.length > 0
    ) {
      const tempFormulaComponents: any[] = [];
      formData.reimbursement_formula_policies.forEach((policy: any) => {
        if (policy.formula_components && policy.formula_components.length > 0) {
          policy.formula_components.forEach((formula: any) => {
            tempFormulaComponents.push({
              ...formula,
              type: formula?.type?.id,
              value:
                formula?.type?.id === "FORMULA"
                  ? typeof formula?.value !== "string" && formula?.value?.code
                  : formula?.type?.id === "VALUE"
                  ? formula?.value
                  : typeof formula?.value !== "string" && formula?.value?.id,
            });
          });
          tempFormulaPolicies.push({
            ...policy,
            formula_components: tempFormulaComponents,
          });
        } else {
          if (policy.is_unlimited) {
            delete policy.formula_components;
          }
          tempFormulaPolicies.push(policy);
        }
      });

      formData.reimbursement_formula_policies = tempFormulaPolicies;
    }

    if (formData?.approval_requirement_type?.id !== "NONE") {
      formData.approval_requirement_type =
        formData?.approval_requirement_type?.id;
      formData.approval_policies = formData?.approval_policies?.map(
        (approval: any) => {
          if (approval?.type?.id === "SUPERVISOR") {
            return {
              level: approval?.level,
              type: approval?.type?.id,
            };
          } else {
            return {
              level: approval?.level,
              type: approval?.type?.id,
              role_id: approval?.role_id?.id,
            };
          }
        }
      );
    }

    if (
      formData.generate_after === "STATUS_CHANGED" &&
      formData.reimbursement_emerge_employment_status_policies.length > 0
    ) {
      const tempId: number[] = [];
      formData.reimbursement_emerge_employment_status_policies.forEach(
        (item: any) => tempId.push(item.id)
      );
      console.log(tempId);
      formData.reimbursement_emerge_employment_status_policies = tempId;
      delete formData.emerge_after_month;
    }

    if (id) {
      delete formData.expired_date;
      delete formData.expired_type;
      delete formData.type;
      delete formData.valid_from;
      delete formData.annually_date;
      delete formData.is_prorate;
      delete formData.round_day;
    }

    delete formData.taxable;

    if (id) {
      ReimbursementPolicyRepository.update(id, formData)
        .then((response: AxiosResponse) => {
          if (isMounted.current) {
            loading.set(false);
          }
          enqueueSnackbar(response.data.message, {
            variant: "success",
          });
          navigate("/apps/setting/policy/reimbursement");
        })
        .catch((error: AxiosError) => {
          if (isMounted.current) loading.set(false);
          handleAxiosErrorSave(error, setError, enqueueSnackbar);
        });
    } else {
      ReimbursementPolicyRepository.create(formData)
        .then((response: AxiosResponse) => {
          if (isMounted.current) {
            loading.set(false);
          }
          enqueueSnackbar(response.data.message, {
            variant: "success",
          });
          navigate("/apps/setting/policy/reimbursement");
        })
        .catch((error: AxiosError) => {
          if (isMounted.current) loading.set(false);
          handleAxiosErrorSave(error, setError, enqueueSnackbar);
        });
    }
  };

  const handleClickOpen = () => {
    if (isMounted.current) setOpen(true);
  };

  const handleClose = () => {
    if (isMounted.current) {
      setOpen(false);
    }
  };

  const loadData = useCallback(async () => {
    if (!id) {
      return;
    }

    if (isMounted.current) setIsFetchingData(true);
    await ReimbursementPolicyRepository.show(id, {
      with: [
        "approvalPolicies.role",
        "reimbursementFormulaPolicies.formulaComponent",
        "reimbursementEmergeEmploymentStatusPolicies.employmentStatus",
      ],
    })
      .then((response: AxiosResponse) => {
        const { data: responseData } = response.data;
        if (isMounted.current) setIsFetchingData(false);

        _.forEach(responseData, (value, name) => {
          if (name === "emerge_after_month") {
            if (value > 0) {
              setValue("generate_after", generatedAfterOptions[1]);
              setValue(name, value);
            } else {
              setValue("generate_after", generatedAfterOptions[0]);
            }
          } else if (
            name === "reimbursement_emerge_employment_status_policies"
          ) {
            if (value.length > 0) {
              setValue(
                name,
                value.map((val: any) => val.employment_status)
              );
            }
          } else if (name === "reimbursement_formula_policies") {
            setValue(
              name,
              responseData.reimbursement_formula_policies.map((item: any) => {
                return {
                  ...item,
                  formula_components: _.get(
                    item,
                    "formula_component.components"
                  ),
                };
              })
            );
          } else if (name === "approval_policies") {
            const approvalModel = value.map((approval: any) => {
              return {
                level: approval?.level,
                type: $clone(approvalPolicyTypeOptions.value).find(
                  (policy: any) => policy.id === approval?.type
                ),
                role_id: approval?.role,
              };
            });
            setValue(name, approvalModel);
          } else if (name === "type") {
            setValue(
              name,
              $clone(renewalOptions.value).find(
                (renewal: ConstantOptionInterface) => renewal.id === value
              )
            );
          } else if (name === "expired_type") {
            setValue(
              name,
              $clone(expiredTypeOptions.value).find(
                (type: ConstantOptionInterface) => type.id === value
              )
            );
          } else if (name === "tax_group") {
            setValue("taxable", {
              id: value || "NONE",
              name: Capitalize(value) || "None",
            });
          } else if (name === "reimbursement_formula_policies") {
            setValue("reimbursement_formula_policies", value);
          } else if (name === "approval_requirement_type") {
            setValue(
              name,
              $clone(approvalRequirementTypeOptions.value).find(
                (approval: any) => approval.id === value
              )
            );
          } else {
            setValue(name, value);
          }
        });
      })
      .catch((error: AxiosError) => {
        if (isMounted.current) setIsFetchingData(false);
      });
  }, [false]);

  useMemo(() => {
    loadData().then();
  }, [id]);

  const TableCell = withStyles({
    root: {
      paddingLeft: "unset",
      borderBottom: "1px solid rgba(224, 224, 224, 1)",
    },
  })(MuiTableCell);

  const handleSaveFormula = (data: IReimbursementFormulaPolicy) => {
    if (typeof formulaIndex === "number") {
      const cloneFormulaPolicies: any[] = [...formulaPolicyFields];
      cloneFormulaPolicies[formulaIndex] = data;
      setValue(`reimbursement_formula_policies`, cloneFormulaPolicies);
    } else {
      formulaPolicyAppend(data);
    }
  };

  const renderFormulaPolicies = (item: any, index: number) => {
    return (
      <TableRow key={item.key}>
        <TableCell>
          <Controller
            name={`reimbursement_formula_policies[${index}].name`}
            control={control}
            defaultValue={item.name}
            render={({ value, onChange }) => {
              return (
                <Typography variant="subtitle2">{value || "-"}</Typography>
              );
            }}
          />
        </TableCell>
        <TableCell>
          <Controller
            name={`reimbursement_formula_policies[${index}].code`}
            control={control}
            defaultValue={item.code}
            render={({ value, onChange }) => {
              return (
                <Typography variant="subtitle2">{value || "-"}</Typography>
              );
            }}
          />
        </TableCell>
        <TableCell>
          <Controller
            name={`reimbursement_formula_policies[${index}].description`}
            control={control}
            defaultValue={item.description}
            render={({ value, onChange }) => {
              return (
                <Typography variant="subtitle2">{value || "-"}</Typography>
              );
            }}
          />
        </TableCell>
        <TableCell>
          <IconButton
            onClick={() => {
              formulaPolicyRemove(index);
            }}
          >
            <DeleteOutlineIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <>
      {isFetchingData && <Loading />}
      <Box style={isFetchingData ? { display: "none" } : {}}>
        <TitleForm
          title={`${id ? "Edit" : "Add"} Reimbursement Policy`}
          withBackUrl={true}
        />

        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <VuiCardContent title="Information">
              <Box p={2}>
                <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                    <Controller
                      render={({ value, name, onChange }) => {
                        return (
                          <MuiTextField
                            name={name}
                            onChange={onChange}
                            label="Reimbursement Policy Name"
                            value={value}
                            error={_.has(errors, name)}
                            helperText={_.get(errors, `${name}.message`)}
                          />
                        );
                      }}
                      name={"name"}
                      control={control}
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Controller
                      render={({ value, name, onChange }) => {
                        return (
                          <MuiTextField
                            name={name}
                            onChange={onChange}
                            label="Policy Code"
                            value={value}
                            error={_.has(errors, name)}
                            helperText={_.get(errors, `${name}.message`)}
                          />
                        );
                      }}
                      name={"code"}
                      control={control}
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Controller
                      render={({ value, name, onChange }) => (
                        <MuiDatePicker
                          disabled={!!id}
                          value={value}
                          name={name}
                          label={"Effective On"}
                          format="DD MMMM YYYY"
                          onChange={onChange}
                          error={_.has(errors, name)}
                          helperText={_.get(errors, `${name}.message`)}
                        />
                      )}
                      name="valid_from"
                      control={control}
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Controller
                      render={({ value, name, onChange }) => {
                        return (
                          <MuiTextField
                            name={name}
                            onChange={onChange}
                            label={t("form.policyDescription.label")}
                            value={value}
                            error={_.has(errors, name)}
                            helperText={_.get(errors, `${name}.message`)}
                          />
                        );
                      }}
                      name={"description"}
                      control={control}
                    />
                  </Grid>
                </Grid>
              </Box>
            </VuiCardContent>
          </Grid>

          <Grid item xs={12} md={8}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <VuiCardContent title="Policy Configuration">
                  <Box p={2}>
                    <Grid container spacing={3}>
                      <Grid item md={12} xs={12}>
                        <Controller
                          name={"is_default"}
                          control={control}
                          render={({ onChange, value }) => {
                            return (
                              <MuiCheckbox
                                onChange={onChange}
                                checked={value}
                                label={
                                  "form.defaultReimbursementForNewEmployee.label"
                                }
                              />
                            );
                          }}
                        />
                      </Grid>

                      <Grid item md={12} xs={12}>
                        <Controller
                          name={"is_take_home_pay"}
                          control={control}
                          render={({ value, onChange }) => {
                            return (
                              <MuiCheckbox
                                onChange={onChange}
                                checked={value}
                                label={"Include in take home pay"}
                              />
                            );
                          }}
                        />
                      </Grid>

                      <Grid item md={12} xs={12}>
                        <Controller
                          render={({ value, name, onChange }) => (
                            <MuiAutoComplete
                              isAsync={false}
                              constantOptions={$clone(taxableOptions.get())}
                              onOpen={() =>
                                taxableOptions.set(
                                  constantToOptions(
                                    constant,
                                    "REIMBURSEMENT_POLICY_TAX_GROUP_TYPE_OPTIONS",
                                    true
                                  )
                                )
                              }
                              value={value}
                              onSelected={(newValue) => onChange(newValue)}
                              muiTextField={{
                                label: "Taxable",
                                error: _.has(errors, name),
                                helperText: _.get(errors, `${name}.message`),
                              }}
                            />
                          )}
                          name="taxable"
                          control={control}
                        />
                      </Grid>

                      <Grid item md={12} xs={12}>
                        <Controller
                          render={({ value, name, onChange }) => (
                            <MuiAutoComplete
                              isAsync={false}
                              disabled={!!id}
                              constantOptions={$clone(renewalOptions.get())}
                              onOpen={() =>
                                renewalOptions.set(
                                  constantToOptions(
                                    constant,
                                    "REIMBURSEMENT_POLICY_TYPE_OPTIONS"
                                  )
                                )
                              }
                              value={value}
                              onSelected={(newValue) => onChange(newValue)}
                              muiTextField={{
                                label: "Renewal",
                                error: _.has(errors, name),
                                helperText: _.get(errors, `${name}.message`),
                              }}
                            />
                          )}
                          name="type"
                          control={control}
                        />
                      </Grid>

                      {_.get(watchType, "id") == "ANNUALLY" && (
                        <Grid item md={12} sm={12}>
                          <Controller
                            render={({ value, name, onChange }) => (
                              <MuiDatePicker
                                value={value}
                                name={name}
                                label={"Annually Date"}
                                format="DD MMMM YYYY"
                                onChange={onChange}
                                error={_.has(errors, name)}
                                helperText={_.get(errors, `${name}.message`)}
                              />
                            )}
                            name="annually_date"
                            control={control}
                            defaultValue={moment()}
                          />
                        </Grid>
                      )}

                      {_.get(watchType, "id") != "ANNIVERSARY" && (
                        <Grid item md={12} xs={12}>
                          <Controller
                            name={"is_emerge_at_join"}
                            control={control}
                            render={({ value, onChange }) => {
                              return (
                                <MuiCheckbox
                                  disabled={!!id}
                                  onChange={onChange}
                                  checked={value}
                                  label={"Set up initial generate policy"}
                                />
                              );
                            }}
                          />
                        </Grid>
                      )}

                      {watchEmerge && _.get(watchType, "id") === "ANNUALLY" && (
                        <Grid item md={12} xs={12}>
                          <Controller
                            name={"is_prorate"}
                            control={control}
                            render={({ value, onChange }) => {
                              return (
                                <MuiCheckbox
                                  onChange={onChange}
                                  checked={value}
                                  label={"Prorate on initial generate policy"}
                                />
                              );
                            }}
                          />
                        </Grid>
                      )}

                      {watchEmerge && (
                        <Grid item md={12} xs={12}>
                          <Controller
                            name={"is_effective_from_join_date"}
                            control={control}
                            render={({ value, onChange }) => {
                              return (
                                <MuiCheckbox
                                  onChange={onChange}
                                  checked={value}
                                  label={"Calculate from join date"}
                                />
                              );
                            }}
                          />
                        </Grid>
                      )}

                      {_.get(watchType, "id") != "ANNIVERSARY" && (
                        <Grid item md={12} xs={12}>
                          <Grid container spacing={2}>
                            <Grid item md={12} xs={12}>
                              <Typography variant="subtitle2" gutterBottom>
                                Generate After
                              </Typography>
                            </Grid>

                            {watchEmerge && (
                              <Grid item md={6} xs={12}>
                                <Controller
                                  render={({ value, name, onChange }) => (
                                    <MuiAutoComplete
                                      isAsync={false}
                                      constantOptions={generatedAfterOptions}
                                      value={value}
                                      onSelected={(newValue) =>
                                        onChange(newValue)
                                      }
                                      muiTextField={{
                                        label: "Generate After",
                                        error: _.has(errors, name),
                                        helperText: _.get(
                                          errors,
                                          `${name}.message`
                                        ),
                                      }}
                                    />
                                  )}
                                  name="generate_after"
                                  control={control}
                                />
                              </Grid>
                            )}

                            {watchEmerge &&
                              (_.get(watchGenerateAfter, "id") ===
                              "STATUS_CHANGED" ? (
                                <Grid item md={6} xs={12}>
                                  <Controller
                                    render={({ value, name, onChange }) => {
                                      return (
                                        <MuiAutoComplete
                                          repository={
                                            EmploymentStatusRepository
                                          }
                                          multiple
                                          value={value}
                                          onSelected={(newValue) =>
                                            onChange(newValue)
                                          }
                                          muiTextField={{
                                            error: _.has(errors, name),
                                            helperText: _.get(
                                              errors,
                                              `${name}.message`
                                            ),
                                          }}
                                        />
                                      );
                                    }}
                                    name={`reimbursement_emerge_employment_status_policies`}
                                    control={control}
                                  />
                                </Grid>
                              ) : (
                                <Grid item md={6} xs={12}>
                                  <Controller
                                    render={({ value, name, onChange }) => {
                                      return (
                                        <MuiTextField
                                          onChange={onChange}
                                          value={value}
                                          label="Total Month"
                                          error={_.has(errors, name)}
                                          helperText={_.get(
                                            errors,
                                            `${name}.message`
                                          )}
                                          InputProps={{
                                            inputComponent: NumberFieldFormat,
                                          }}
                                        />
                                      );
                                    }}
                                    name={`emerge_after_month`}
                                    control={control}
                                  />
                                </Grid>
                              ))}

                            {_.get(watchType, "id") != "ANNIVERSARY" && (
                              <Grid item md={12} xs={12}>
                                <Controller
                                  name={"round_day"}
                                  control={control}
                                  defaultValue={0}
                                  render={({ name, value, onChange }) => {
                                    return (
                                      <MuiTextField
                                        onChange={onChange}
                                        value={value}
                                        label="Rounding Date"
                                        disabled={!!id}
                                        error={_.has(errors, name)}
                                        helperText={_.get(
                                          errors,
                                          `${name}.message`
                                        )}
                                        InputProps={{
                                          inputComponent: NumberFieldFormat,
                                        }}
                                      />
                                    );
                                  }}
                                />
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      )}

                      <Grid item md={12} xs={12}>
                        <Grid container spacing={2}>
                          <Grid item md={12} xs={12}>
                            <Typography variant="subtitle2">
                              Generate On
                            </Typography>
                          </Grid>

                          {_.get(watchType, "id") == "MONTHLY" && (
                            <Grid item md={12} xs={12}>
                              <Controller
                                render={({ value, name, onChange }) => (
                                  <MuiAutoComplete
                                    isAsync={false}
                                    constantOptions={generateOnMonthlyOptions}
                                    value={value}
                                    onSelected={(newValue) =>
                                      onChange(newValue)
                                    }
                                    muiTextField={{
                                      label: "Generate On",
                                      error: _.has(errors, name),
                                      helperText: _.get(
                                        errors,
                                        `${name}.message`
                                      ),
                                    }}
                                  />
                                )}
                                name="generate_type"
                                control={control}
                              />
                            </Grid>
                          )}

                          {_.get(watchType, "id") == "ANNUALLY" &&
                            _.get(watchGenerateType, "id") == "DEFINED" && (
                              <Grid item md={6} sm={12}>
                                <Controller
                                  name={"generate_annually_date"}
                                  control={control}
                                  render={({ name, value, onChange }) => {
                                    return (
                                      <MuiTextField
                                        name={name}
                                        onChange={onChange}
                                        value={value}
                                        label={"Date"}
                                      />
                                    );
                                  }}
                                />
                              </Grid>
                            )}

                          {_.get(watchType, "id") === "ANNUALLY" &&
                            _.get(watchGenerateType, "id") === "DEFINED" && (
                              <Grid item md={6} sm={12}>
                                <Controller
                                  render={({ value, name, onChange }) => (
                                    <MuiAutoComplete
                                      isAsync={false}
                                      constantOptions={monthOptions}
                                      value={value}
                                      onSelected={(newValue) =>
                                        onChange(newValue)
                                      }
                                      muiTextField={{
                                        label: "Month",
                                        error: _.has(errors, name),
                                        helperText: _.get(
                                          errors,
                                          `${name}.message`
                                        ),
                                      }}
                                    />
                                  )}
                                  name="generate_annually_month"
                                  control={control}
                                />
                              </Grid>
                            )}

                          {_.get(watchType, "id") === "MONTHLY" &&
                            _.get(watchGenerateType, "id") === "DEFINED" && (
                              <>
                                <Grid item md={6} xs={12}>
                                  <Controller
                                    name={"monthly_issued_day"}
                                    control={control}
                                    defaultValue={""}
                                    render={({ name, onChange, value }) => {
                                      return (
                                        <MuiTextField
                                          name={name}
                                          value={value}
                                          onChange={onChange}
                                          label={"Day"}
                                        />
                                      );
                                    }}
                                  />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                  <Controller
                                    render={({ value, name, onChange }) => {
                                      return (
                                        <MuiAutoComplete
                                          isAsync={false}
                                          constantOptions={monthOptions}
                                          value={value}
                                          onSelected={(newValue) =>
                                            onChange(newValue)
                                          }
                                          muiTextField={{
                                            error: _.has(errors, name),
                                            helperText: _.get(
                                              errors,
                                              `${name}.message`
                                            ),
                                          }}
                                        />
                                      );
                                    }}
                                    name={`monthly_issued_month`}
                                    control={control}
                                    defaultValue={null}
                                  />
                                </Grid>
                                <ErrorText
                                  field={""}
                                  errors={errors}
                                  fieldName={"monthly_issued_day"}
                                />
                              </>
                            )}

                          <Grid item md={12} xs={12}>
                            <Controller
                              render={({ value, name, onChange }) => (
                                <MuiAutoComplete
                                  isAsync={false}
                                  disabled={!!id}
                                  constantOptions={$clone(
                                    expiredTypeOptions.get()
                                  )}
                                  onOpen={() =>
                                    expiredTypeOptions.set(
                                      constantToOptions(
                                        constant,
                                        "REIMBURSEMENT_POLICY_EXPIRED_TYPE_OPTIONS",
                                        true
                                      )
                                    )
                                  }
                                  value={value}
                                  onSelected={(newValue) => {
                                    setValue("expired_total_month", "");
                                    setValue(
                                      "expired_date",
                                      moment().format("YYYY-MM-DD")
                                    );
                                    onChange(newValue);
                                  }}
                                  muiTextField={{
                                    label: "Expired Setting",
                                    error: _.has(errors, name),
                                    helperText: _.get(
                                      errors,
                                      `${name}.message`
                                    ),
                                  }}
                                />
                              )}
                              name="expired_type"
                              control={control}
                            />
                          </Grid>

                          {_.get(watchExpiredType, "id") === "TOTAL_MONTH" ? (
                            <Grid item md={12} sm={12}>
                              <Controller
                                name={"expired_total_month"}
                                defaultValue={0}
                                control={control}
                                render={({ value, onChange, name }) => {
                                  return (
                                    <MuiTextField
                                      onChange={onChange}
                                      value={value}
                                      label="Month"
                                      disabled={!!id}
                                      error={_.has(errors, name)}
                                      helperText={_.get(
                                        errors,
                                        `${name}.message`
                                      )}
                                      InputProps={{
                                        inputComponent: NumberFieldFormat,
                                      }}
                                    />
                                  );
                                }}
                              />
                            </Grid>
                          ) : _.get(watchExpiredType, "id") === "DATE" ? (
                            <Grid item md={12} sm={12}>
                              <Controller
                                render={({ value, name, onChange }) => (
                                  <MuiDatePicker
                                    disabled={!!id}
                                    value={value}
                                    name={name}
                                    label={"Expired Date"}
                                    format="DD MMMM YYYY"
                                    onChange={onChange}
                                    error={_.has(errors, name)}
                                    helperText={_.get(
                                      errors,
                                      `${name}.message`
                                    )}
                                  />
                                )}
                                name="expired_date"
                                control={control}
                                defaultValue={moment()}
                              />
                            </Grid>
                          ) : null}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </VuiCardContent>
              </Grid>

              <Grid item xs={12} md={12}>
                <VuiCardContent title="Reimbursement Component">
                  <Box p={2}>
                    <Grid container spacing={3}>
                      <Grid item md={12} xs={12}>
                        <Grid container>
                          <Grid item md={12} xs={12}>
                            <MuiButton
                              label="Add Component"
                              onClick={handleClickOpen}
                            />
                          </Grid>

                          <ErrorText
                            errors={errors}
                            field={formulaPolicyFields}
                            fieldName="reimbursement_formula_policies"
                          />

                          {formulaPolicyFields.length > 0 ? (
                            <Grid item md={12} xs={12}>
                              <TableContainer>
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>Name</TableCell>
                                      <TableCell>Code</TableCell>
                                      <TableCell>Description</TableCell>
                                      <TableCell />
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {formulaPolicyFields.map(
                                      renderFormulaPolicies
                                    )}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Grid>
                          ) : null}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </VuiCardContent>
              </Grid>

              <Grid item xs={12} md={12}>
                <VuiCardContent title="Approval Setting">
                  <Box p={2}>
                    <ApprovalSetting
                      errors={errors}
                      control={control}
                      policies={watchApprovalPolicies}
                      type={watchApprovalRequirementType}
                      typeName="approval_requirement_type"
                      fieldName="approval_policies"
                      label="Approval Reimbursement"
                    />
                  </Box>
                </VuiCardContent>
              </Grid>
            </Grid>
          </Grid>

          <FooterFormAction
            cancelUrl={null}
            loading={loading.get()}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
          />
        </Grid>

        <FormAddReimbursementFormula
          open={open}
          handleClose={handleClose}
          handleSave={(data) => handleSaveFormula(data)}
          formulaData={
            typeof formulaIndex === "number"
              ? formulaPolicyFields[formulaIndex]
              : null
          }
        />
      </Box>
    </>
  );
};

export default FinanceReimbursementForm;
