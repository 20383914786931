import { useState } from "@hookstate/core/dist";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { KeyboardDatePicker } from "@material-ui/pickers";
import _ from "lodash";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, useParams } from "react-router-dom";
import VuiSelect from "../../../../@VodeaUI/components/Input/VuiSelect/Index";
import VuiCardContent from "../../../../@VodeaUI/components/VuiCardContent";
import { Options } from "../../../../@VodeaUI/VuiDataTable/Index";
import { useStyles } from "../../../../components/Status/style";
import { defaultDataTableOption } from "../../../../utilities/Option";
import TitleForm from "../../../../components/molecules/TitleForm";

const DetailFacility: React.FC<any> = () => {
  const classes = useStyles();
  const { control, errors, handleSubmit } = useForm({
    mode: "onChange",
  });

  const orderBy = useState("all");
  const loading = useState(false);
  const options = useState<Options>({
    ...defaultDataTableOption,
    sortedBy: "desc",
    orderBy: "date",
  });

  const columns = [
    {
      name: "date",
      label: "DATE",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "information",
      label: "INFORMATION",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "",
      label: "",
      empty: true,
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row: any, tableMeta: any) => {
          return <IconButton>{/* <FormatListBulletedIcon /> */}</IconButton>;
        },
        setCellProps: (value: any) => {
          return {
            className: "column-action-button-wrapper",
          };
        },
      },
    },
  ];

  const columnsDetail = [
    {
      name: "category",
      label: "CATEGORY",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "description",
      label: "DESCRIPTION",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "amount",
      label: "AMOUNT",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (row: any, tableMeta: any) => {
          return (
            <div className={classes.statusWrapper}>
              <div className={row}>{row}</div>
            </div>
          );
        },
      },
    },
    {
      name: "file",
      label: "FILE",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (row: any, tableMeta: any) => {
          return (
            <Button variant="contained" color="primary">
              View
            </Button>
          );
        },
      },
    },
    {
      name: "",
      label: "",
      empty: true,
      options: {
        filter: false,
        sort: false,
        customBodyRender: (row: any, tableMeta: any) => {
          return <IconButton>{/* <FormatListBulletedIcon /> */}</IconButton>;
        },
        setCellProps: (value: any) => {
          return {
            className: "column-action-button-wrapper",
          };
        },
      },
    },
  ];
  const data = [
    {
      category: "Overtime",
      date: "28 December 2020",
      name: "Yonkers",
      status: "Approved",
      start_date: "28 October 2000",
      numofdays: "2 Days",
      information: "Overtime detail",
      amount: "20",
    },
    {
      category: "Overtime",
      date: "28 December 2020",
      name: "Hartford",
      status: "Waiting",
      start_date: "28 October 2000",
      numofdays: "2 Days",
      information: "Overtime detail",
      amount: "20",
    },
  ];

  const objParams = useParams();

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = () => {
    loading.set(false);

    if (objParams.id !== undefined) {
    }
  };

  const orderByOptions = [
    {
      key: "all",
      label: "All",
    },
    {
      key: "a-z",
      label: "A-Z",
    },
  ];

  const resourceType = useState("1");

  const resourceTypeOptions = [
    {
      key: "1",
      label: "Meeting Room A",
    },
    {
      key: "2",
      label: "Meeting Room B",
    },
  ];

  return (
    <>
      <Box p={2}>
        <Box>
          <TitleForm title="Facility Request | 100122" withBackUrl={true} />
        </Box>
        <Box marginTop={2}>
          <Grid container spacing={3}>
            <Grid item xs={6} md={8}>
              <VuiCardContent title="Information">
                <Box p={2}>
                  <Grid container spacing={3}>
                    <Grid item md={12} xs={12}>
                      <VuiSelect
                        label="Resource Name"
                        state={resourceType}
                        options={resourceTypeOptions}
                        fullWidth={true}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <VuiSelect
                        label="Booking Name"
                        state={resourceType}
                        options={resourceTypeOptions}
                        fullWidth={true}
                      />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <Controller
                        render={({ value, name, onChange }) => (
                          <KeyboardDatePicker
                            name={name}
                            disableToolbar
                            fullWidth
                            variant="inline"
                            minDate={new Date("2020-01-01")}
                            inputVariant="outlined"
                            label="Date"
                            format="DD/MM/YYYY"
                            error={_.has(errors, "taxable_date")}
                            helperText={_.get(errors, "taxable_date.message")}
                            value={value}
                            onChange={onChange}
                          />
                        )}
                        name="taxable_date"
                        control={control}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Controller
                        render={({ value, name, onChange }) => (
                          <TextField
                            id="time"
                            fullWidth
                            label="From"
                            type="time"
                            variant="outlined"
                            defaultValue="07:30"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              step: 300, // 5 min
                            }}
                          />
                        )}
                        name="taxable_date"
                        control={control}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Controller
                        render={({ value, name, onChange }) => (
                          <TextField
                            id="time"
                            fullWidth
                            label="To"
                            type="time"
                            variant="outlined"
                            defaultValue="07:30"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              step: 300, // 5 min
                            }}
                          />
                        )}
                        name="taxable_date"
                        control={control}
                      />
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <TextField
                        name="building_id"
                        label="Description"
                        variant="outlined"
                        fullWidth
                        error={_.has(errors, "building_id")}
                        helperText={_.get(errors, "building_id.message")}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </VuiCardContent>
            </Grid>
            <Grid item xs={6} md={4}>
              <Paper>
                <Box p={2}>
                  <Typography variant={"h6"}>Invite Participant</Typography>
                </Box>
                <Divider />
                <Box marginX={2} marginY={1} color="text.disabled">
                  <Typography variant={"h6"}>Participant</Typography>
                </Box>

                <Box p={2}>
                  <Grid
                    container
                    spacing={3}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Grid item>
                      <TextField
                        name="building_id"
                        label="Allena Ultimer"
                        variant="outlined"
                        fullWidth
                        // value = "Hello"
                        disabled
                        error={_.has(errors, "building_id")}
                        helperText={_.get(errors, "building_id.message")}
                      />
                    </Grid>
                    <Grid item>
                      <div className={classes.statusWrapper}>
                        <div className={"Waiting"}>Waiting</div>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={3}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Grid item>
                      <TextField
                        name="building_id"
                        label="Leon Sebastian"
                        disabled
                        variant="outlined"
                        fullWidth
                        error={_.has(errors, "building_id")}
                        helperText={_.get(errors, "building_id.message")}
                      />
                    </Grid>
                    <Grid item>
                      <div className={classes.statusWrapper}>
                        <div className={"Approved"}>Approved</div>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={3}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Grid item>
                      <TextField
                        name="building_id"
                        label="Sarah Benjamin"
                        disabled
                        variant="outlined"
                        fullWidth
                        error={_.has(errors, "building_id")}
                        helperText={_.get(errors, "building_id.message")}
                      />
                    </Grid>
                    <Grid item>
                      <div className={classes.statusWrapper}>
                        <div className={"Approved"}>Approved</div>
                      </div>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Grid>
          </Grid>
          <Box marginTop={1}>
            <Paper>
              <Box
                p={2}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box>
                  <Typography variant="subtitle1">
                    Approve This Request?
                  </Typography>
                </Box>
                <Box>
                  <Box display="flex" alignItems="center">
                    <Box marginRight={2}>
                      <Button variant="text" color="primary">
                        Cancel
                      </Button>
                    </Box>
                    <Box>
                      <Button variant="contained" color="primary">
                        Submit
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Paper>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default DetailFacility;
