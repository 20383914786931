import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 320,
    maxWidth: '100%',
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
    },
    borderRadius: 8,
  }
}));
