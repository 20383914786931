import {
  InputAdornment,
  OutlinedInput,
  OutlinedInputProps,
} from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";
import React, { useCallback, useState } from "react";
import _ from "lodash";
import { useIsMounted } from "../../../utilities/helpers/hooks";
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

interface IMuiSearchStyled extends OutlinedInputProps {}
interface IMuiSearch {
  value: string;
  placeholder?: string;
  callback?: (value: string) => void | null;
  delay?: number;
  className?: string;
}

const MuiSearchStyled = withStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 320,
      maxWidth: "100%",
      [theme.breakpoints.down("sm")]: {
        minWidth: "100%",
      },
      borderRadius: theme.spacing(1),
      "& .MuiOutlinedInput-input": {
        borderRadius: theme.spacing(1),
        "&.MuiInputBase-input": {
          padding: 14,
          fontSize: 14,
          paddingLeft: 0,
        },
      },
      "& .MuiSvgIcon-root": {
        width: "0.9em",
        height: "0.9em",
      },
    },
  })
)((props: IMuiSearchStyled) => {
  return <OutlinedInput {...props} />;
});

const MuiSearch: React.FC<IMuiSearch> = ({
  placeholder = "form.search.placeholder",
  callback = null,
  delay = 300,
  value = "",
  className,
}) => {
  const isMounted = useIsMounted();
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState<string>(value);

  const handleChange = useCallback(
    _.debounce((event: React.ChangeEvent<HTMLInputElement>) => {
      if (isMounted.current) {
        setInputValue(event.target.value);
        if (callback) {
          callback(event.target.value);
        }
      }
    }, delay),
    []
  );

  return (
    <MuiSearchStyled
      fullWidth
      placeholder={t(placeholder)}
      onChange={handleChange}
      defaultValue={inputValue}
      className={className}
      startAdornment={
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      }
    />
  );
};

export default React.memo(MuiSearch);
