import React, { useEffect } from "react";
import qs from "query-string";

export const useIsMounted = () => {
  const isMounted = React.useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  return isMounted;
};

// export const useEcho = (channel: string, isPublic: boolean = true) => {
//   const Echo = isPublic
//     ? (<any>window).Echo.channel(channel)
//     : (<any>window).Echo.private(channel);
//
//   useEffect(() => {
//     return () => {
//       (<any>window).Echo.leaveChannel(channel);
//     };
//   }, []);
//
//   return Echo;
// };

export const useQuerySearch = () => qs.parse(window.location.search);
