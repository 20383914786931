import React, { useCallback, useEffect } from "react";
import { Box, Grid, Paper } from "@material-ui/core";
import {
  $clone,
  $cloneState,
  mapDataTableParams,
} from "../../../../utilities/helpers/global";
import { useState } from "@hookstate/core/dist";
import VuiDataTable, {
  Column,
  Options,
} from "../../../../@VodeaUI/VuiDataTable/Index";
import { defaultDataTableOption } from "../../../../utilities/Option";
import { Link } from "react-router-dom";
import CashAdvanceRepository from "../../../../repositories/CashAdvanceRepository";
import { useIsMounted } from "../../../../@VodeaUI";
import VuiNumberFormat from "../../../../@VodeaUI/components/Input/VuiNumberFormat";
import { AxiosError, AxiosResponse } from "axios";
import { IApiResource } from "../../../../interfaces/ApiResource";
import { ICashAdvanceRequest } from "../../../../interfaces/CashAdvanceRequest";
import { sortedByOptions } from "../../../../constants";
import { axiosErrorLoadDataHandler } from "../../../../utilities/helpers/axios-error.helper";
import { useSnackbar } from "notistack";
import MuiButton from "../../../../components/atoms/MuiButton";
import {
  MuiAutoComplete,
  MuiSearch,
  MuiStatusLabel,
} from "../../../../components/atoms";
import { useTranslation } from "react-i18next";
import CashAdvancePolicyRepository from "../../../../repositories/CashAdvancePolicyRepository";
import { useSelector } from "react-redux";
import UserRepository from "../../../../repositories/UserRepository";
import { TitleHeader } from "../../../../components/molecules";

const AdvanceFinance: React.FC<any> = () => {
  const isMounted = useIsMounted();
  const { t } = useTranslation();
  const [tableData, setTableData] = React.useState<Array<any>>([]);
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useSelector(({ vodea }: any) => {
    return {
      user: vodea.auth.user,
    };
  });
  const [selectedCategory, setSelectedCategory] = React.useState<any>(null);
  const [selectedUser, setSelectedUser] = React.useState<any>(null);
  const [ranges, setRanges] = React.useState<number[] | number>([0, 0]);

  const handleChangeRanges = useCallback(
    (event: React.ChangeEvent<{}>, newValue: number | number[]) => {
      setRanges(newValue);
    },
    [ranges]
  );

  const [loading, setLoading] = React.useState<boolean>(false);
  const options = useState<Options>({
    ...defaultDataTableOption,
    sortedBy: "desc",
    orderBy: "date",
  });

  const columns = useState<Column[]>([
    { key: "name", label: "Name", defaultValue: "-" },
    {
      key: "created_at",
      label: "Date",
      type: "date",
      dateFormat: "DD MMMM YYYY",
      defaultValue: "-",
    },
    { key: "category", label: "Category", defaultValue: "-" },
    {
      key: "date",
      label: "Use On",
      type: "date",
      dateFormat: "DD MMMM YYYY",
      defaultValue: "-",
    },
    {
      key: "amount",
      label: "Amount (IDR)",
      defaultValue: "-",
      render: (row) => {
        return (
          <VuiNumberFormat data={row.amount} value={row.amount} prefix={""} />
        );
      },
    },
    {
      key: "status",
      label: "Status",
      sortable: false,
      render: (row) => <MuiStatusLabel status={row.status} />,
    },
    {
      key: "id",
      label: "",
      sortable: false,
      render: (row) => {
        return (
          <Box display="flex" justifyContent={"flex-end"}>
            <Link to={`${row.id}`}>
              <MuiButton label="View" />
            </Link>
          </Box>
        );
      },
    },
  ]);

  useEffect(() => {
    loadData().then();
  }, [selectedCategory, selectedUser]);

  const loadData = useCallback(async () => {
    if (isMounted.current) {
      setLoading(true);
    }

    await CashAdvanceRepository.all({
      ...mapDataTableParams($cloneState(options)),
      only: "my_and_approver",
      with: ["user"],
      "cash-advance-policy": selectedCategory?.id,
      user: selectedUser?.id,
    })
      .then((response: AxiosResponse<IApiResource<ICashAdvanceRequest[]>>) => {
        if (isMounted.current) {
          const { data: responseData, meta } = response.data;
          const tempData: Array<any> = responseData.map((item) => {
            return {
              id: item.id,
              name: item?.user?.name,
              created_at: item.created_at,
              category: item?.cash_advance_policy?.name,
              date: item.date,
              amount: item.amount,
              status: item.status,
            };
          });

          options.total.set(meta.total);
          options.page.set(meta.current_page - 1);
          setTableData(tempData);
          setLoading(false);
        }
      })
      .catch((error: AxiosError) => {
        if (isMounted.current) setLoading(false);
        axiosErrorLoadDataHandler(error, enqueueSnackbar);
      });
  }, [selectedCategory, selectedUser]);

  const handleTableSearch = useCallback((value: string) => {
    if (isMounted.current) {
      options.search.set(value);
      options.page.set(0);
      loadData().then();
    }
  }, []);

  return (
    <>
      <TitleHeader
        title={"Advance"}
        subtitle={"List of advance transaction"}
        component={
          <Link to={"/apps/finance/advance/advance-request"}>
            <MuiButton label="Request Advance" size="large" />
          </Link>
        }
      />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper variant="elevation" elevation={1}>
            <Box p={2}>
              <Grid
                container
                spacing={3}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Grid item>
                  <MuiSearch
                    value={$clone(options.search.value)}
                    callback={handleTableSearch}
                  />
                </Grid>
                <Grid item md={3}>
                  <MuiAutoComplete
                    isAsync={false}
                    value={sortedByOptions.find(
                      (option) => option.id === $clone(options.sortedBy.value)
                    )}
                    constantOptions={sortedByOptions}
                    onSelected={(newValue) => {
                      options.sortedBy.set(newValue.id);
                      loadData().then();
                    }}
                    muiTextField={{
                      label: t("form.sort.label"),
                    }}
                  />
                </Grid>
              </Grid>

              <Box mt={1}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={3}>
                    <MuiAutoComplete
                      repository={CashAdvancePolicyRepository}
                      params={{
                        for: "cash-advance",
                        user: user?.id,
                      }}
                      onSelected={(newValue) => {
                        setSelectedCategory(newValue);
                      }}
                      muiTextField={{
                        label: t("form.category.label"),
                      }}
                    />
                  </Grid>

                  {/*<Grid item xs={12} md={3}>*/}
                  {/*  <MuiRangeSlider*/}
                  {/*    value={ranges}*/}
                  {/*    handleChange={handleChangeRanges}*/}
                  {/*  />*/}
                  {/*</Grid>*/}

                  <Grid item xs={12} md={3}>
                    <MuiAutoComplete
                      repository={UserRepository}
                      params={{
                        for: "cash-advance",
                      }}
                      onSelected={(newValue) => {
                        setSelectedUser(newValue);
                      }}
                      muiTextField={{
                        label: t("form.employee.label"),
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>

            <VuiDataTable
              loading={loading}
              columns={columns.value}
              options={$clone(options.value)}
              data={tableData}
              onChangeOptions={(values: Options) => {
                options.set(values);
                loadData().then();
              }}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default AdvanceFinance;
