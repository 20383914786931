import React from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { useGlobalStyles } from "../utilities/styles";
import clsx from "clsx";
import { MuiButton } from "./atoms";

interface Props {
  cancelUrl?: string | null;
  cancelBtnLabel?: string;
  loading: boolean;
  onCancel?: any;
  handleSubmit: any;
  onSubmit: any;
  labelForm?: string;
  submitBtnLabel?: string;
}

const FooterFormAction: React.FC<Props> = ({
  cancelUrl,
  cancelBtnLabel = "Cancel",
  loading,
  onCancel,
  handleSubmit,
  onSubmit,
  submitBtnLabel = "Save",
  labelForm,
}) => {
  const classes = useGlobalStyles();

  return (
    <Grid item xs={12}>
      <Paper variant="elevation" elevation={1}>
        <Box
          p={2}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="subtitle2" className={classes.text}>
            {labelForm !== undefined ? labelForm : "Save changes?"}
          </Typography>
          <Box display="flex" alignItems="center">
            {cancelUrl ? (
              <Link to={cancelUrl}>
                <MuiButton label={cancelBtnLabel} variant="text" size="small" />
              </Link>
            ) : null}

            {onCancel ? (
              <MuiButton
                onClick={onCancel}
                label={cancelBtnLabel}
                variant="text"
                size="small"
              />
            ) : null}

            <Box ml={1}>
              <MuiButton
                label={submitBtnLabel}
                loading={loading}
                onClick={handleSubmit(onSubmit)}
                disabled={loading}
                size="large"
              />
            </Box>
          </Box>
        </Box>
      </Paper>
    </Grid>
  );
};

export default FooterFormAction;
