import React, { useEffect } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import VuiCardContent from "../../../../../@VodeaUI/components/VuiCardContent";
import _ from "lodash";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import TitleForm from "../../../../../components/molecules/TitleForm";
import FooterFormAction from "../../../../../components/FooterFormAction";
import { useState } from "@hookstate/core";
import { useStyles } from "./style";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import useIsMounted from "../../../../../@VodeaUI/hooks/useIsMounted";
import { useParams } from "react-router";
import { AxiosError, AxiosResponse } from "axios";
import { useSnackbar } from "notistack";
import { KeyboardDatePicker } from "@material-ui/pickers";
import TimeOffPolicyRepository from "../../../../../repositories/TimeOffPolicyRepository";
import moment from "moment";
import {
  approvalRequirementTypeOptions,
  approvalTypeOptions,
  approvalTypePolicyOptions,
  expiredAnnuallyAndAnniversaryOptions,
  expiredMonthlyOptions,
  generatedAfterOptions,
  generateOnMonthlyOptions,
  monthOptions,
  renewalOptions,
} from "../../../../../constants";
import { PopUpAssignPolicy } from "../../../../../components/molecules";
import {
  MuiAutoComplete,
  MuiButton,
  MuiCheckbox,
  MuiDatePicker,
  MuiTextField,
  NumberFieldFormat,
} from "../../../../../components/atoms";
import { useTranslation } from "react-i18next";
import EmploymentStatusRepository from "../../../../../repositories/EmploymentStatusRepository";
import ApprovalSetting from "../../../../../components/Policy/ApprovalSetting";
import {
  axiosErrorLoadDataHandler,
  handleAxiosErrorSave,
} from "../../../../../utilities/helpers/axios-error.helper";

const TimeOffPolicyForm: React.FC<any> = () => {
  const { id } = useParams();
  const onFetchData = useState<boolean>(false);
  const isMounted = useIsMounted();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const title = id ? "Edit Time Off Policy" : "Add Time Off Policy";
  const [showModalSucceed, setShowModalSucceed] =
    React.useState<boolean>(false);
  const handleCloseModalSucceed = () => setShowModalSucceed(false);
  const { t } = useTranslation();

  const { errors, handleSubmit, setValue, register, control, setError, watch } =
    useForm({
      mode: "onChange",
      shouldUnregister: false,
      defaultValues: {
        code: "",
        name: "",
        description: "",
        valid_from: moment().add(2, "day"),
        approval_requirement_type: approvalRequirementTypeOptions[3],
        generate_after: generatedAfterOptions[1],
        time_off_emerge_employment_status_policies: [],
        total_month: "",
        expired_type: expiredMonthlyOptions[3],
        expired_date: moment(),
        expired_total_month: "",
        type: renewalOptions[0],
        round_day: "",
        approval_policies: [],
        time_off_balance_policies: [],
        max_request_day: "",
        is_unlimited: false,
        is_default: false,
        is_emerge_at_join: false,
        is_effective_from_join_date: false,
        is_calculate_from_join_date: false,
        generate_type: generateOnMonthlyOptions[0],
        monthly_issued_day: "",
        generate_annually_month: monthOptions[0],
        generate_annually_date: "",
      },
    });

  const {
    fields: balanceFields,
    append: balanceAppend,
    remove: balanceRemove,
  } = useFieldArray({
    control,
    name: "time_off_balance_policies",
  });

  const handleAppendBalance = () =>
    balanceAppend({
      from: "",
      to: "",
      balance: "",
    });

  const loadData = async () => {
    if (isMounted.current) {
      onFetchData.set(true);
    }

    await TimeOffPolicyRepository.show(id, {
      with: [
        "approvalPolicies.role",
        "timeOffBalancePolicies",
        "timeOffEmergeEmploymentStatusPolicies.employmentStatus",
      ],
    })
      .then((resp: AxiosResponse) => {
        if (isMounted.current) {
          onFetchData.set(false);
          const { data: timeOff } = resp.data;
          _.forEach(timeOff, (value, name) => {
            if (name === "type") {
              setValue(
                name,
                renewalOptions.find((renewal: any) => renewal.id === value)
              );
            } else if (name === "time_off_emerge_employment_status_policies") {
              setValue(
                name,
                value.map((option: any) => option.employment_status)
              );
            } else if (name === "monthly_issued_type" && !value) {
              setValue(
                "generate_type",
                generateOnMonthlyOptions.find(
                  (generate: any) => generate.id === timeOff.monthly_issued_type
                )
              );
            } else if (name === "expired_type") {
              if (timeOff.type === "MONTHLY") {
                setValue(
                  name,
                  expiredMonthlyOptions.find(
                    (expired: any) => expired.key === value
                  )
                );
              } else {
                setValue(
                  name,
                  expiredAnnuallyAndAnniversaryOptions.find(
                    (expired: any) => expired.key === value
                  )
                );
              }
            } else if (name === "approval_requirement_type") {
              setValue(
                name,
                approvalTypeOptions.find(
                  (approval: any) => approval.key === value
                )
              );
            } else if (name === "approval_policies") {
              const approvalModel = value.map((approval: any) => {
                return {
                  level: approval.level,
                  type: approvalTypePolicyOptions.find(
                    (policy) => policy.key === approval.type
                  ),
                  role_id: approval.role,
                };
              });
              setValue(name, approvalModel);
            } else if (name === "expired_total_month") {
              if (timeOff.expired_type === "TOTAL_MONTH") {
                setValue(name, value);
              } else {
                console.log("a");
                setValue("expired_date", value);
              }
            } else if (
              name === "annually_date" &&
              timeOff.type === "ANNUALLY"
            ) {
              setValue("generate_annually_date", moment(value).format("D"));
              setValue(
                "generate_annually_month",
                monthOptions.find(
                  (month: any) => month.key === moment(value).format("MM")
                )
              );
            } else {
              setValue(name, value);
            }
          });
        }
      })
      .catch((error: AxiosError) => {
        if (isMounted.current) {
          onFetchData.set(false);
          axiosErrorLoadDataHandler(error, enqueueSnackbar);
        }
      });
  };

  useEffect(() => {
    if (id) {
      loadData();
    }
  }, [id]);

  useEffect(() => {
    if (!id) {
      handleAppendBalance();
    }
  }, []);

  const watchRenewal = watch("type");
  const watchGenerate = watch("generate_after");
  const watchIsUnlimitedBalance = watch("is_unlimited");
  const watchApprovalType = watch("approval_requirement_type");
  const watchGenerateType = watch("generate_type");
  const watchExpiredType = watch("expired_type");
  const watchEmerge = watch("is_emerge_at_join");
  const watchApprovalPolicies = watch("approval_policies");

  const loading = useState<boolean>(false);

  const onSubmit = async (formData: any) => {
    if (isMounted.current) loading.set(true);
    let body: any = {};

    Object.keys(formData).forEach((key: any) => {
      if (formData[key] === null) {
        delete formData[key];
      }
    });

    body = {
      ...formData,
      valid_from: moment(formData.valid_from).format("YYYY-MM-DD"),
      expired_type: formData?.expired_type?.key,
      generate_after: formData?.generate_after?.name,
      type: formData.type?.id,
      time_off_emerge_employment_status_policies:
        formData?.time_off_emerge_employment_status_policies?.map(
          (item: any) => {
            return item?.id;
          }
        ),
    };

    if (body.expired_type === "DATE") {
      body = {
        ...body,
        expired_date: moment(formData?.expired_date).format("YYYY-MM-DD"),
      };
    }

    if (body?.approval_requirement_type.id !== "NONE") {
      body = {
        ...body,
        approval_requirement_type: formData.approval_requirement_type?.id,
        approval_policies: formData?.approval_policies?.map((approval: any) => {
          if (approval.type.key === "SUPERVISOR") {
            return {
              level: approval.level,
              type: approval.type.key,
            };
          } else {
            return {
              level: approval.level,
              type: approval.type.key,
              role_id: approval.role_id.id,
            };
          }
        }),
      };
    } else {
      delete body?.approval_requirement_type;
    }

    if (formData?.type?.id === "MONTHLY") {
      body = {
        ...body,
        monthly_issued_type: formData?.generate_type?.id,
        monthly_date: formData?.monthly_issued_day,
      };

      if (id) {
        delete body.expired_total_month;
        delete body.monthly_issued_type;
      }

      delete body.monthly_issued_day;
      delete body.generate_type;
    }

    if (formData?.type?.id !== "MONTHLY") {
      body = {
        ...body,
        generate_type: formData?.generate_type?.id,
      };
    }

    if (formData?.type?.id === "ANNUALLY") {
      body = {
        ...body,
        annually_date: `1980-${formData?.generate_annually_month?.key}-${
          (formData?.generate_annually_date <= 9 ? "0" : "") +
          formData?.generate_annually_date
        }`,
      };
      delete body?.generate_annually_month;
      delete body?.generate_annually_date;
    }

    if (formData?.type?.id === "ANNIVERSARY") {
      delete body.round_day;
    }

    if (!id && body?.expired_type === "DATE") {
      body = {
        ...body,
        expired_total_month: moment(formData?.expired_date).format(
          "YYYY-MM-DD"
        ),
      };
      delete body.expired_date;
    }

    if (id) {
      delete body?.expired_date;
      delete body?.expired_type;
      delete body?.is_allow_minus;
      delete body?.is_can_cashed;
      delete body?.is_unlimited;
      delete body?.type;
      delete body?.valid_from;
      delete body?.annually_date;
      delete body?.round_day;
      delete body?.emerge_after_month;
    }

    if (id) {
      await TimeOffPolicyRepository.update(id, { ...body })
        .then((response: AxiosResponse) => {
          if (isMounted.current) {
            enqueueSnackbar(response.data.message, {
              variant: "success",
            });
            setShowModalSucceed(true);
            loading.set(false);
          }
        })
        .catch((error: AxiosError) => {
          if (isMounted.current) {
            handleAxiosErrorSave(error, setError, enqueueSnackbar);
            loading.set(false);
          }
        });
    } else {
      await TimeOffPolicyRepository.create({ ...body })
        .then((response: AxiosResponse) => {
          if (isMounted.current) {
            enqueueSnackbar(response.data.message, {
              variant: "success",
            });
            setShowModalSucceed(true);
            loading.set(false);
          }
        })
        .catch((error: AxiosError) => {
          if (isMounted.current) {
            handleAxiosErrorSave(error, setError, enqueueSnackbar);
            loading.set(false);
          }
        });
    }
  };

  return (
    <>
      <TitleForm title={title} withBackUrl={true} />
      <PopUpAssignPolicy
        open={showModalSucceed}
        onClose={handleCloseModalSucceed}
        url={"/apps/setting/policy/time-off"}
      />

      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <VuiCardContent title="Information">
            <Box p={2}>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <Controller
                    name={"name"}
                    control={control}
                    render={({ onChange, value, name }) => {
                      return (
                        <MuiTextField
                          name={name}
                          value={value}
                          onChange={onChange}
                          label={"Time Off Policy Name"}
                          error={_.has(errors, name)}
                          helperText={_.get(errors, `${name}.message`)}
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <Controller
                    name={"code"}
                    control={control}
                    render={({ onChange, value, name }) => {
                      return (
                        <MuiTextField
                          name={name}
                          value={value}
                          onChange={onChange}
                          label={"Policy Code"}
                          error={_.has(errors, name)}
                          helperText={_.get(errors, `${name}.message`)}
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <Controller
                    render={({ value, name, onChange }) => (
                      <MuiDatePicker
                        disabled={!!id}
                        value={value}
                        name={name}
                        label={"Effective On"}
                        format="DD MMMM YYYY"
                        onChange={onChange}
                        error={_.has(errors, name)}
                        helperText={_.get(errors, `${name}.message`)}
                      />
                    )}
                    name="valid_from"
                    control={control}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <Controller
                    name={"description"}
                    control={control}
                    render={({ onChange, value, name }) => {
                      return (
                        <MuiTextField
                          name={name}
                          value={value}
                          onChange={onChange}
                          label={"Policy Description"}
                          error={_.has(errors, name)}
                          helperText={_.get(errors, `${name}.message`)}
                        />
                      );
                    }}
                  />
                </Grid>

                <Grid item md={12} xs={12}>
                  <Controller
                    name={"is_unlimited"}
                    control={control}
                    render={({ value, onChange }) => {
                      return (
                        <MuiCheckbox
                          checked={value}
                          onChange={onChange}
                          label="Policy has unlimited balance"
                        />
                      );
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </VuiCardContent>
        </Grid>

        <Grid item xs={12} md={8}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <VuiCardContent title="Policy Configuration">
                <Box p={2}>
                  {!watchIsUnlimitedBalance ? (
                    <Grid container spacing={3}>
                      <Grid item md={12} xs={12}>
                        <Controller
                          name={"is_default"}
                          control={control}
                          render={({ value, name, onChange }) => {
                            return (
                              <MuiCheckbox
                                checked={value}
                                onChange={onChange}
                                label="Default time off for new employee"
                              />
                            );
                          }}
                        />
                      </Grid>

                      <Grid item md={12} xs={12}>
                        <Controller
                          render={({ value, name, onChange }) => {
                            return (
                              <MuiAutoComplete
                                isAsync={false}
                                constantOptions={renewalOptions}
                                value={value}
                                onSelected={(newValue) => {
                                  setValue("expired_type", null);
                                  onChange(newValue);
                                }}
                                muiTextField={{
                                  label: t("form.renewal.label"),
                                  error: _.has(errors, name),
                                  helperText: _.get(errors, `${name}.message`),
                                }}
                              />
                            );
                          }}
                          name={`type`}
                          control={control}
                        />
                      </Grid>

                      <Grid item md={12} sm={12}>
                        <Grid container>
                          <Grid item md={12} xs={12}>
                            <Controller
                              name={"is_emerge_at_join"}
                              control={control}
                              render={({ name, value, onChange }) => {
                                return (
                                  <MuiCheckbox
                                    checked={value}
                                    onChange={onChange}
                                    label="Set up initial generate policy"
                                  />
                                );
                              }}
                            />
                          </Grid>

                          {watchEmerge && watchRenewal?.id === "ANNUALLY" && (
                            <Grid item md={12} xs={12}>
                              <Controller
                                name={"is_effective_from_join_date"}
                                control={control}
                                render={({ name, value, onChange }) => {
                                  return (
                                    <MuiCheckbox
                                      checked={value}
                                      onChange={onChange}
                                      label="Prorate on initial generate policy"
                                    />
                                  );
                                }}
                              />
                            </Grid>
                          )}

                          {watchEmerge && (
                            <Grid item md={12} xs={12}>
                              <Controller
                                name={"is_calculate_from_join_date"}
                                control={control}
                                render={({ name, value, onChange }) => {
                                  return (
                                    <MuiCheckbox
                                      checked={value}
                                      onChange={onChange}
                                      label="Calculate from join date"
                                    />
                                  );
                                }}
                              />
                            </Grid>
                          )}
                        </Grid>
                      </Grid>

                      <Grid item md={12} xs={12}>
                        <Grid container spacing={2}>
                          <Grid item md={12} xs={12}>
                            <Typography variant="subtitle2" gutterBottom>
                              Generate After
                            </Typography>
                          </Grid>

                          {watchEmerge && watchRenewal?.id !== "ANNIVERSARY" && (
                            <Grid item md={5} xs={12}>
                              <Controller
                                render={({ value, name, onChange }) => (
                                  <MuiAutoComplete
                                    isAsync={false}
                                    constantOptions={generatedAfterOptions}
                                    value={value}
                                    onSelected={(newValue) => {
                                      onChange(newValue);
                                    }}
                                    muiTextField={{
                                      label: t("form.statusChanged.label"),
                                      error: _.has(errors, name),
                                      helperText: _.get(
                                        errors,
                                        `${name}.message`
                                      ),
                                    }}
                                  />
                                )}
                                name="generate_after"
                                control={control}
                              />
                            </Grid>
                          )}

                          {watchEmerge && watchRenewal?.id !== "ANNIVERSARY" && (
                            <Grid item md={7} xs={12}>
                              {watchGenerate?.id === "CERTAIN_MONTH" ? (
                                <Controller
                                  name={"total_month"}
                                  control={control}
                                  render={({ name, value, onChange }) => {
                                    return (
                                      <MuiTextField
                                        name={name}
                                        onChange={onChange}
                                        value={value}
                                        label={"Total Month"}
                                        InputProps={{
                                          inputComponent: NumberFieldFormat,
                                        }}
                                        error={_.has(errors, name)}
                                        helperText={_.get(
                                          errors,
                                          `${name}.message`
                                        )}
                                      />
                                    );
                                  }}
                                />
                              ) : (
                                <Controller
                                  render={({ value, name, onChange }) => {
                                    return (
                                      <MuiAutoComplete
                                        multiple
                                        repository={EmploymentStatusRepository}
                                        value={value}
                                        onSelected={(newValue) => {
                                          onChange(newValue);
                                        }}
                                        muiTextField={{
                                          error: _.has(errors, name),
                                          helperText: _.get(
                                            errors,
                                            `${name}.message`
                                          ),
                                        }}
                                      />
                                    );
                                  }}
                                  name={`time_off_emerge_employment_status_policies`}
                                  control={control}
                                />
                              )}
                            </Grid>
                          )}

                          {watchRenewal?.id !== "ANNIVERSARY" && (
                            <Grid item md={12} xs={12}>
                              <Controller
                                name={"round_day"}
                                control={control}
                                render={({ name, value, onChange }) => {
                                  return (
                                    <MuiTextField
                                      onChange={onChange}
                                      value={value}
                                      label={t("form.roundingDate.label")}
                                      error={_.has(errors, name)}
                                      helperText={_.get(
                                        errors,
                                        `${name}.message`
                                      )}
                                      InputProps={{
                                        inputComponent: NumberFieldFormat,
                                      }}
                                    />
                                  );
                                }}
                              />
                            </Grid>
                          )}
                        </Grid>
                      </Grid>

                      {watchRenewal?.id === "MONTHLY" && (
                        <Grid item md={12} xs={12}>
                          <Controller
                            render={({ value, name, onChange }) => (
                              <MuiAutoComplete
                                isAsync={false}
                                constantOptions={generateOnMonthlyOptions}
                                value={value}
                                onSelected={(newValue) => {
                                  onChange(newValue);
                                }}
                                muiTextField={{
                                  label: t("form.generateOn.label"),
                                  error: _.has(errors, name),
                                  helperText: _.get(errors, `${name}.message`),
                                }}
                              />
                            )}
                            name="generate_type"
                            control={control}
                          />
                        </Grid>
                      )}

                      {watchRenewal?.id === "ANNUALLY" &&
                        watchGenerateType?.id === "DEFINED" && (
                          <Grid item md={6} sm={12}>
                            <Controller
                              name={"generate_annually_date"}
                              control={control}
                              render={({ name, value, onChange }) => {
                                return (
                                  <MuiTextField
                                    name={name}
                                    onChange={onChange}
                                    value={value}
                                    label={"Date"}
                                    error={_.has(errors, name)}
                                    helperText={_.get(
                                      errors,
                                      `${name}.message`
                                    )}
                                  />
                                );
                              }}
                            />
                          </Grid>
                        )}

                      {watchRenewal?.id === "ANNUALLY" &&
                        watchGenerateType?.id === "DEFINED" && (
                          <Grid item md={6} sm={12}>
                            <Controller
                              render={({ value, name, onChange }) => (
                                <MuiAutoComplete
                                  isAsync={false}
                                  constantOptions={monthOptions}
                                  value={value}
                                  onSelected={(newValue) => {
                                    onChange(newValue);
                                  }}
                                  muiTextField={{
                                    label: t("form.month.label"),
                                    error: _.has(errors, name),
                                    helperText: _.get(
                                      errors,
                                      `${name}.message`
                                    ),
                                  }}
                                />
                              )}
                              name="generate_annually_month"
                              control={control}
                            />
                          </Grid>
                        )}

                      {watchRenewal?.id === "MONTHLY" &&
                        watchGenerateType?.id === "DEFINED" && (
                          <Grid item md={12} xs={12}>
                            <Controller
                              name={"monthly_issued_day"}
                              control={control}
                              render={({ name, onChange, value }) => {
                                return (
                                  <MuiTextField
                                    name={name}
                                    value={value}
                                    onChange={onChange}
                                    label={"Day"}
                                    error={_.has(errors, name)}
                                    helperText={_.get(
                                      errors,
                                      `${name}.message`
                                    )}
                                  />
                                );
                              }}
                            />
                          </Grid>
                        )}

                      <Grid item md={12} xs={12}>
                        <Controller
                          render={({ value, name, onChange }) => (
                            <MuiAutoComplete
                              isAsync={false}
                              constantOptions={
                                watchRenewal?.id === "MONTHLY"
                                  ? expiredMonthlyOptions
                                  : expiredAnnuallyAndAnniversaryOptions
                              }
                              value={value}
                              onSelected={(newValue) => {
                                setValue("expired_total_month", "");
                                setValue(
                                  "expired_date",
                                  moment().format("YYYY-MM-DD")
                                );
                                onChange(newValue);
                              }}
                              muiTextField={{
                                label: t("form.expiredSetting.label"),
                                error: _.has(errors, name),
                                helperText: _.get(errors, `${name}.message`),
                              }}
                            />
                          )}
                          name="expired_type"
                          control={control}
                        />
                      </Grid>

                      {watchExpiredType?.id == 1 && (
                        <Grid item md={12} sm={12}>
                          <MuiTextField
                            name={"expired_total_month"}
                            defaultValue={""}
                            label={"Month"}
                            type={"number"}
                            InputProps={{ inputProps: { min: 0 } }}
                            inputRef={register()}
                          />
                        </Grid>
                      )}

                      {watchExpiredType?.id == 2 && (
                        <Grid item md={12} sm={12}>
                          <Controller
                            render={({ value, name, onChange }) => {
                              console.log(value);
                              return (
                                <MuiDatePicker
                                  value={value}
                                  name={name}
                                  label={"Expired Date"}
                                  format="DD MMMM"
                                  onChange={onChange}
                                  error={_.has(errors, name)}
                                  helperText={_.get(errors, `${name}.message`)}
                                />
                              );
                            }}
                            name="expired_date"
                            control={control}
                          />
                        </Grid>
                      )}
                    </Grid>
                  ) : (
                    <Typography variant="subtitle2">
                      No Configuration Needed, Because This Policy Has Unlimited
                      Balance.
                    </Typography>
                  )}
                </Box>
              </VuiCardContent>
            </Grid>

            <Grid item xs={12} md={12}>
              <VuiCardContent title="Balance Generated">
                <Box p={2}>
                  <Grid container spacing={1}>
                    {!watchIsUnlimitedBalance && (
                      <Grid item md={12} xs={12}>
                        <Grid container>
                          <Grid item md={12} xs={12}>
                            <Grid container spacing={2}>
                              <Grid item md={4}>
                                <Typography variant={"subtitle2"}>
                                  {watchRenewal?.id === "MONTHLY"
                                    ? "From (Month)"
                                    : watchRenewal?.id === "ANNUALLY"
                                    ? "From (Year)"
                                    : "From"}
                                </Typography>
                              </Grid>
                              <Grid item md={4}>
                                <Typography variant={"subtitle2"}>
                                  {watchRenewal?.id === "MONTHLY"
                                    ? "To (Month)"
                                    : watchRenewal?.id === "ANNUALLY"
                                    ? "To (Year)"
                                    : "To"}
                                </Typography>
                              </Grid>
                              <Grid item md={4}>
                                <Typography variant={"subtitle2"}>
                                  Balance
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item md={12} xs={12}>
                            {balanceFields.map((field: any, index: number) => {
                              return (
                                <Box mt={2} key={field.id}>
                                  <Grid container spacing={2}>
                                    <Grid item md={3} sm={12}>
                                      <Controller
                                        control={control}
                                        defaultValue={field.from}
                                        name={`time_off_balance_policies[${index}].from`}
                                        render={({ value, name, onChange }) => {
                                          return (
                                            <MuiTextField
                                              name={name}
                                              value={value}
                                              onChange={onChange}
                                              error={_.has(errors, name)}
                                              helperText={_.get(
                                                errors,
                                                `${name}.message`
                                              )}
                                              InputProps={{
                                                inputComponent:
                                                  NumberFieldFormat,
                                              }}
                                            />
                                          );
                                        }}
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      md={1}
                                      sm={12}
                                      className={classes.container}
                                    >
                                      <Typography>-</Typography>
                                    </Grid>
                                    <Grid item md={3}>
                                      <Controller
                                        control={control}
                                        defaultValue={field.to}
                                        name={`time_off_balance_policies[${index}].to`}
                                        render={({ value, name, onChange }) => {
                                          return (
                                            <MuiTextField
                                              name={name}
                                              value={value}
                                              onChange={onChange}
                                              error={_.has(errors, name)}
                                              helperText={_.get(
                                                errors,
                                                `${name}.message`
                                              )}
                                              InputProps={{
                                                inputComponent:
                                                  NumberFieldFormat,
                                              }}
                                            />
                                          );
                                        }}
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      md={1}
                                      sm={12}
                                      className={classes.container}
                                    >
                                      <Typography>=</Typography>
                                    </Grid>
                                    <Grid item md={3} sm={12}>
                                      <Controller
                                        control={control}
                                        defaultValue={field.balance}
                                        name={`time_off_balance_policies[${index}].balance`}
                                        render={({ value, name, onChange }) => {
                                          return (
                                            <MuiTextField
                                              name={name}
                                              value={value}
                                              onChange={onChange}
                                              error={_.has(errors, name)}
                                              helperText={_.get(
                                                errors,
                                                `${name}.message`
                                              )}
                                              InputProps={{
                                                inputComponent:
                                                  NumberFieldFormat,
                                              }}
                                            />
                                          );
                                        }}
                                      />
                                    </Grid>
                                    <Grid item md={1}>
                                      <IconButton
                                        aria-label="delete"
                                        onClick={() => balanceRemove(index)}
                                      >
                                        <DeleteOutlineIcon />
                                      </IconButton>
                                    </Grid>
                                  </Grid>
                                </Box>
                              );
                            })}
                          </Grid>
                          <Box mt={2} mb={1}>
                            <MuiButton
                              label="Add More"
                              onClick={handleAppendBalance}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    )}

                    <Grid item md={12} sm={12}>
                      <Controller
                        render={({ value, onChange }) => {
                          return (
                            <MuiCheckbox
                              checked={value}
                              onChange={onChange}
                              label="Allowed Half Day"
                              disabled={!!id}
                            />
                          );
                        }}
                        name={"is_allow_half_day"}
                        control={control}
                        defaultValue={false}
                      />
                    </Grid>

                    {!watchIsUnlimitedBalance && (
                      <Grid item md={12} sm={12}>
                        <Controller
                          render={({ value, onChange }) => {
                            return (
                              <MuiCheckbox
                                checked={value}
                                onChange={onChange}
                                label="Allowed Minus"
                                disabled={!!id}
                              />
                            );
                          }}
                          name={"is_allow_minus"}
                          control={control}
                          defaultValue={false}
                        />
                      </Grid>
                    )}

                    {!watchIsUnlimitedBalance && (
                      <Grid item md={12} sm={12}>
                        <Controller
                          render={({ value, onChange }) => {
                            return (
                              <MuiCheckbox
                                checked={value}
                                onChange={onChange}
                                label="Allowed Cashed"
                                disabled={!!id}
                              />
                            );
                          }}
                          name={"is_can_cashed"}
                          control={control}
                          defaultValue={false}
                        />
                      </Grid>
                    )}

                    <Grid item md={12} sm={12}>
                      <Controller
                        name={"max_request_day"}
                        control={control}
                        render={({ name, onChange, value }) => {
                          return (
                            <MuiTextField
                              name={name}
                              value={value}
                              onChange={onChange}
                              error={_.has(errors, name)}
                              helperText={_.get(errors, `${name}.message`)}
                              InputProps={{
                                inputComponent: NumberFieldFormat,
                              }}
                              label={"Max Requested Day"}
                            />
                          );
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </VuiCardContent>
            </Grid>

            <Grid item xs={12} md={12}>
              <VuiCardContent title="Approval Setting">
                <Box p={2}>
                  <ApprovalSetting
                    control={control}
                    policies={watchApprovalPolicies}
                    type={watchApprovalType}
                    typeName="approval_requirement_type"
                    fieldName="approval_policies"
                    label="Approval Time Off"
                    errors={errors}
                  />
                </Box>
              </VuiCardContent>
            </Grid>
          </Grid>
        </Grid>

        <FooterFormAction
          cancelUrl={"/apps/setting/time-off"}
          loading={loading.get()}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
        />
      </Grid>
    </>
  );
};

export default TimeOffPolicyForm;
