import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  widgetListWrapper: {
    marginBottom: "15px",
  },
  boxWrapper: {
    padding: "10px 20px",
  },
  fontColor: {
    color: theme.palette.primary.dark,
    fontWeight: "bold",
  },
  expiredFont: {
    color: "#707070",
  },
  titleColor: {
    color: "#1A1818",
  },
  lineClamp2: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
  },
}));
