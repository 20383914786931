import React, { useEffect } from "react";
import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import VuiCardContent from "../../../../@VodeaUI/components/VuiCardContent";
import _ from "lodash";
import { Controller, useForm } from "react-hook-form";
import TitleForm from "../../../../components/molecules/TitleForm";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { useIsMounted } from "../../../../@VodeaUI";
import { useSnackbar } from "notistack";
import { yupResolver } from "@hookform/resolvers/yup";
import { Model } from "./Interface";
import AnnouncementRepository from "../../../../repositories/AnnouncementRepository";
import { AxiosError, AxiosResponse } from "axios";
import {
  $clone,
  handleAxiosError,
  mapHookErrors,
} from "../../../../utilities/helpers/global";
import * as validator from "yup";
import { useState } from "@hookstate/core";
import FooterFormAction from "../../../../components/FooterFormAction";

export const modelSchema = () =>
  validator.object().shape({
    name: validator.string().required().label("Name"),
    date: validator.mixed().required().label("Date"),
    status: validator.mixed().required().label("Status"),
  });

const BaseModel: Model = {
  name: "",
  date: "",
  status: "",
};

const AddAnnouncement: React.FC<any> = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const isMounted = useIsMounted();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [data, setData] = React.useState<Model>(BaseModel);
  const loading = useState(false);
  const {
    control,
    errors,
    setValue,
    watch,
    handleSubmit,
    setError,
    register,
    reset,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(modelSchema()),
  });

  useEffect(() => {
    if (id) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data) {
      reset(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const loadData = () => {
    const loadingKey = enqueueSnackbar("Please wait...", {
      variant: "default",
      autoHideDuration: 5000,
      preventDuplicate: true,
    });

    AnnouncementRepository.show(Number(id))
      .then(({ data }: AxiosResponse) => {
        setData(data?.data);
        closeSnackbar(loadingKey);
      })
      .catch((e: AxiosError) => {
        if (isMounted.current) {
          closeSnackbar(loadingKey);
          handleAxiosError(e, isMounted.current, enqueueSnackbar, navigate);
        }
      });
  };

  const onSubmit = async (formData: Model) => {
    loading.set(true);

    formData = $clone(formData);

    await (Boolean(id)
      ? AnnouncementRepository.update(Number(id), formData)
      : AnnouncementRepository.create(formData)
    )
      .then((resp: any) => {
        loading.set(false);
        navigate(-1);
        enqueueSnackbar(resp.data.message, {
          variant: "success",
        });
      })
      .catch((e: any) => {
        if (isMounted.current && e?.response?.data?.errors) {
          const errors = mapHookErrors(e.response.data.errors);
          Object.keys(errors).forEach((key: any) => setError(key, errors[key]));

          enqueueSnackbar(e.response.data.message, {
            variant: "error",
          });
        }
        loading.set(false);
      });
  };

  // useEffect(() => {
  //   if (type != "people") {
  //     setValue("user_id", null);
  //   }
  //
  //   if (type != "role") {
  //     setValue("role_id", null);
  //   }
  // }, [type]);

  const status = watch("status", data.status ? "1" : "0");

  const renderTypeOptions = () => {
    return (
      <Controller
        render={({ onChange }) => (
          <RadioGroup
            row
            name="status"
            innerRef={register}
            onChange={(event, newValue: string) => onChange(newValue)}
            value={status}
          >
            <FormControlLabel
              label="Active"
              value="1"
              control={<Radio color="primary" />}
            />
            <FormControlLabel
              label="Non Active"
              value="0"
              control={<Radio color="primary" />}
            />
          </RadioGroup>
        )}
        name="status"
        control={control}
        defaultValue={status}
      />
    );
  };

  return (
    <>
      <TitleForm
        title={data.id ? "Edit Announcement" : "Add Announcement"}
        withBackUrl={true}
      />

      <Grid container spacing={3}>
        <Grid item xs={7}>
          <VuiCardContent title="Information">
            <Box p={2}>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <Controller
                    render={({ value, name, onChange }) => (
                      <TextField
                        name={name}
                        label="Name"
                        variant="outlined"
                        onChange={onChange}
                        value={value}
                        fullWidth
                        error={_.has(errors, "name")}
                        helperText={_.get(errors, "name.message")}
                      />
                    )}
                    name={`name`}
                    control={control}
                    defaultValue={""}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <Controller
                    render={({ value, name, onChange }) => (
                      <KeyboardDatePicker
                        name={name}
                        disableToolbar
                        fullWidth
                        variant="inline"
                        minDate={new Date()}
                        inputVariant="outlined"
                        label={"Date"}
                        format="DD/MM/YYYY"
                        error={_.has(errors, "date")}
                        helperText={_.get(errors, "date.message")}
                        value={value}
                        onChange={onChange}
                      />
                    )}
                    name="date"
                    control={control}
                    defaultValue={new Date()}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <Typography variant="body2">Status</Typography>
                  {renderTypeOptions()}
                </Grid>
              </Grid>
            </Box>
          </VuiCardContent>
          <Box mt={2}>
            <FooterFormAction
              onCancel={() => navigate(-1)}
              loading={loading.get()}
              handleSubmit={handleSubmit}
              onSubmit={onSubmit}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default AddAnnouncement;
