import React, { useEffect, useMemo } from "react";
import { Box, Button, Grid, Paper, Typography } from "@material-ui/core";
import { VuiSearch } from "../../../../@VodeaUI/components";
import {
  $clone,
  $cloneState,
  mapDataTableParams,
} from "../../../../utilities/helpers/global";
import { useState } from "@hookstate/core/dist";
import VuiDataTable, {
  Column,
  Options,
} from "../../../../@VodeaUI/VuiDataTable/Index";
import { defaultDataTableOption } from "../../../../utilities/Option";
import { Link } from "react-router-dom";
import VuiSelect from "../../../../@VodeaUI/components/Input/VuiSelect/Index";
import { useStyles } from "../../../../layouts/Apps/style";
import UserRepository from "../../../../repositories/UserRepository";
import { useIsMounted } from "../../../../utilities/helpers/hooks";
import _ from "lodash";
import { AxiosError } from "axios";
import ModalEmploymentData from "./Employment/ModalEmploymentData";

const Employee: React.FC<any> = () => {
  const classes = useStyles();
  const data = useState<any[]>([]);
  const [isUserLoading, setIsUserLoading] = React.useState<boolean>(false);
  const isMounted = useIsMounted();
  const [open, setOpen] = React.useState(false);
  const [realData, setRealData] = React.useState<any[]>([]);
  const [userTreeData, setUserTreeData] = React.useState<any[]>([]);
  const columns = useState<Column[]>([
    {
      key: "nip",
      label: "ID",
    },
    { key: "name", label: "Name", defaultValue: "-" },
    {
      key: "role_name",
      label: "Position",
      defaultValue: "-",
    },
    {
      key: "join_date",
      label: "Join Date",
      defaultValue: "-",
      type: "date",
      dateFormat: "DD MMMM YYYY",
    },
    {
      key: "employment_status",
      label: "Status",
      defaultValue: "-",
    },
    { key: "email", label: "Email", defaultValue: "-" },
    {
      key: "mobile_phone",
      label: "Phone Number",
      defaultValue: "-",
    },
    {
      key: "detail",
      label: "",
      render: (row) => {
        return (
          <Box display="flex" justifyContent={"flex-end"} key={row.id}>
            <Link to={`employee-detail/${row.id}`}>
              <Button
                variant="contained"
                color="primary"
                className={classes.buttonTable}
              >
                View
              </Button>
            </Link>
          </Box>
        );
      },
    },
  ]);
  const orderBy = useState("all");
  const loading = useState(false);
  const options = useState<Options>({
    ...defaultDataTableOption,
    sortedBy: "asc",
    orderBy: "created_at",
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = () => {
    if (isMounted.current) {
      loading.set(true);
    }

    data.set([]);

    UserRepository.all({
      ...mapDataTableParams($cloneState(options)),
      with: [
        "userDetail",
        "userPayroll",
        "roleUser.role.department",
        "employmentStatusUser.employmentStatus",
      ],
    })
      .then((response: any) => {
        const temp: any[] = [];
        const meta = response.data.meta;
        response.data.data.forEach((list: any) => {
          temp.push({
            nip: list.nip || "-",
            id: list.id,
            name: list.name || "-",
            role_name: list.role_name || "-",
            join_date: list.user_detail.join_date || "-",
            employment_status:
              list?.role_user?.role_id == 1
                ? list.employment_status_user?.employment_status?.name || "-"
                : list.employment_status?.employment_status?.name || "-",
            email: list.email || "",
            mobile_phone: list.mobile_phone || "-",
          });
        });

        if (isMounted.current) {
          data.set(temp);
          options.page.set(meta.current_page - 1);
          options.total.set(meta.total);
          loading.set(false);
        }
      })
      .catch((err: any) => {
        if (isMounted.current) {
          loading.set(false);
        }
      });
  };

  const orderByOptions = [
    {
      key: "all",
      label: "All",
    },
    {
      key: "a-z",
      label: "A-Z",
    },
  ];

  const groupBy = (xs: any, key: any) => {
    return xs.reduce(function (rv: any, x: any) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  const loadUser = () => {
    if (isMounted.current) setIsUserLoading(true);
    UserRepository.select({
      for: "transaction-policy",
      with: ["roleUser.role.department"],
    })
      .then((response: any) => {
        const data = response.data.data;
        if (isMounted.current) setUserTreeData([]);
        const groupedByDepartment = groupBy(data, "department_name");
        const temp: any[] = [];
        Object.keys(groupedByDepartment).map((key: any) => {
          const groupedByRole = groupBy(groupedByDepartment[key], "role_name");
          const children: any[] = [];
          Object.keys(groupedByRole).map((role: any) => {
            children.push({
              label: role === "null" ? "" : role,
              value:
                role === "null"
                  ? ""
                  : `role${role}${groupedByRole[role][0].id}`,
              children: groupedByRole[role].map((user: any) => {
                return {
                  ...user,
                  value: user.id,
                  label: user.name,
                };
              }),
            });
          });
          temp.push({
            label: key === "null" ? "" : key,
            value:
              key === "null"
                ? ""
                : `department${key}${groupedByDepartment[key][0]?.department_id}`,
            children: children,
          });
        });

        if (isMounted.current) {
          setUserTreeData(temp);
          setRealData(data);
          setIsUserLoading(false);
        }
      })
      .catch((error: AxiosError) => {
        if (isMounted.current) {
          setIsUserLoading(false);
        }
      });
  };

  useEffect(() => {
    loadUser();
  }, []);

  return (
    <>
      <ModalEmploymentData
        isLoading={isUserLoading}
        open={open}
        handleClose={handleClose}
        data={userTreeData}
        realData={realData}
      />

      <Grid container spacing={3} justifyContent="space-between">
        <Grid item>
          <Typography variant={"h5"}>Employee</Typography>
        </Grid>
        <Grid item>
          <Grid container spacing={3}>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                className={classes.buttonPrimary}
                onClick={handleOpen}
              >
                Update Employment Data
              </Button>
            </Grid>
            <Grid item>
              <Link to={"create"}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.buttonPrimary}
                >
                  Add Employee
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper variant="elevation" elevation={1}>
            <Box p={2}>
              <Grid
                container
                spacing={3}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Grid item>
                  <VuiSearch
                    state={options.search}
                    callback={() => {
                      options.page.set(0);
                      loadData();
                    }}
                  />
                </Grid>
                <Grid item>
                  <VuiSelect
                    label="Urutkan"
                    state={orderBy}
                    options={orderByOptions}
                  />
                </Grid>
              </Grid>
            </Box>

            <VuiDataTable
              loading={loading.get()}
              columns={columns.value}
              options={$clone(options.value)}
              data={data.value}
              onChangeOptions={(values: Options) => {
                options.set(values);
                loadData();
              }}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default Employee;
