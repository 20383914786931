import { api, createCancelTokenHandler } from "../@VodeaUI";

const endPoint = () => window._env_.REACT_APP_PASSPORT_END_POINT;

const Repository = {
  create: function (payload: any, params: any = null) {
    return api.post(`${endPoint()}/api/asset-user`, payload, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.create.name].handleRequestCancellation()
          .token,
    });
  },
};

const cancelTokenHandlerObject = createCancelTokenHandler(Repository);

export default Repository;
