import React from "react";
import { Grid, Typography } from "@material-ui/core";
import NavigationBar from "../../../../components/NavigationBar";

interface Props {
  children?: React.ReactChild | React.ReactChild[];
}

const PreferencesPayroll: React.FC<Props> = ({ children }) => {
  const data: any[] = [
    {
      label: "Schedule",
      href: "/apps/setting/payroll/schedule",
      path: "Schedule",
      permissions: "any",
      children: [],
    },
    {
      label: "Prorate",
      href: "/apps/setting/payroll/prorate",
      path: "Prorate",
      permissions: "any",
      children: [],
    },
    {
      label: "Absence",
      href: "/apps/setting/payroll/absence",
      path: "absence",
      permissions: "any",
      children: [],
    },
  ];

  return (
    <>
      <Grid container spacing={3} justifyContent="space-between">
        <Grid item xs={12}>
          <Typography variant="h5">Payroll</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <NavigationBar data={data} type="setting" />
        </Grid>
        <Grid item xs={12} md={9}>
          {children}
        </Grid>
      </Grid>
    </>
  );
};

export default PreferencesPayroll;
