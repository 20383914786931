import { api } from "../@VodeaUI";

const url = "http://localhost:8000";

const PayrollRepository = {
  all: function (params: any = null) {
    return api.get(`${url}/api/user/payroll`, {
      params,
    });
  },
  show: function (params: any = null, id: any) {
    return api.get(`${url}/api/payroll/${id}`, {
      params,
    });
  },
};

export default PayrollRepository;
