import React, { useEffect } from "react";
import People from "../../../../../../../layouts/Apps/People";
import {
  Box,
  Grid,
  IconButton,
  Paper,
  SvgIcon,
  TextField,
  Tooltip,
  Typography,
  Zoom,
} from "@material-ui/core";
import {
  BlockOutlined,
  CheckCircleOutlineOutlined,
  ChevronRight as ChevronRightIcon,
} from "@material-ui/icons";
import {
  $clone,
  $cloneState,
  mapDataTableParams,
} from "../../../../../../../utilities/helpers/global";
import { useState } from "@hookstate/core/dist";
import AttendanceRepository from "../../../../../../../repositories/AttendanceRepository";
import { AxiosResponse } from "axios";
import useIsMounted from "../../../../../../../@VodeaUI/hooks/useIsMounted";
import VuiDataTable, {
  Column,
  Options,
} from "../../../../../../../@VodeaUI/VuiDataTable/Index";
import { defaultDataTableOption } from "../../../../../../../utilities/Option";
import { Link, useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import { useStyles } from "./style";
import VuiSelect from "../../../../../../../@VodeaUI/components/Input/VuiSelect/Index";
import Button from "@material-ui/core/Button";
import moment from "moment/moment.js";
import WidgetRepository from "../../../../../../../repositories/WidgetRepository";
import { AttendanceOptionInterface } from "./interface";
import { Autocomplete } from "@material-ui/lab";
import { Controller, useForm } from "react-hook-form";
import {
  optionLabel,
  optionSelected,
} from "../../../../../../../utilities/helpers/select";
import { generateEmployeeNavData } from "../../../../../../../utilities/helpers/global";

function getRandomInt(min: any, max: any) {
  min = Math.ceil(min);
  max = Math.floor(max);

  return Math.floor(Math.random() * (max - min) + min);
}

const orderByOptions = [
  {
    key: "all",
    label: "All",
  },
  {
    key: "a-z",
    label: "A-Z",
  },
];

const EmployeeAttendance: React.FC<any> = () => {
  document.title = "Attendance";
  const navigate = useNavigate();
  const isMounted = useIsMounted();
  const loading = useState(false);
  const [loadingData, setLoadingData] = React.useState(false);
  const [attendance, setAttendance] = React.useState({});
  const [selectedYear, setYear] = React.useState(null);
  const [selectedMonth, setMonth] = React.useState(null);
  const options = useState<Options>({
    ...defaultDataTableOption,
    sortedBy: "desc",
    orderBy: "date",
  });
  const data = useState([]);
  const objParams = useParams();
  const employeeNavData = generateEmployeeNavData(objParams.id);
  const classes = useStyles();
  const orderBy = useState("all");
  const yearNow = useState(moment().format("YYYY"));
  const monthNow = useState(moment().format("M"));
  const yearOptions = useState<any[]>([]);
  const monthOptions = useState<any[]>([]);
  const columns = useState<Column[]>([
    {
      key: "date",
      label: "Date",
      defaultValue: "-",
      type: "date",
      dateFormat: "DD MMMM YYYY",
    },
    {
      key: "attendance_from",
      label: "Using",
      render: (data) => {
        let arr = _.get(data, "attendance_from", "").split(", ");

        return (
          <>
            <span
              key={getRandomInt(10, 9999)}
              className={
                arr.includes("ABSENCE_MACHINE") ? " " : classes.displayNone
              }
            >
              <Tooltip TransitionComponent={Zoom} title="Absence Machine">
                <SvgIcon className={classes.icon}>
                  <path
                    id="icons8-fingerprint"
                    d="M12.839,4A10.917,10.917,0,0,0,8.25,5.01a.648.648,0,1,0,.543,1.176,9.6,9.6,0,0,1,8.09.007.648.648,0,0,0,.546-1.175A10.881,10.881,0,0,0,12.839,4ZM6.355,6.284a.648.648,0,0,0-.4.149A11.136,11.136,0,0,0,4.123,8.3a.648.648,0,1,0,1.025.791A9.844,9.844,0,0,1,6.769,7.441a.648.648,0,0,0-.414-1.158Zm6.887.328a.648.648,0,0,0-.007,1.294,7.113,7.113,0,0,1,6.709,6.707.648.648,0,1,0,1.294-.071,8.42,8.42,0,0,0-7.932-7.929Q13.274,6.611,13.242,6.612Zm-2.57.288a.648.648,0,0,0-.172.029A8.431,8.431,0,0,0,4.42,15.01a15.4,15.4,0,0,0,.12,1.805.648.648,0,0,0,1.286-.157,14.188,14.188,0,0,1-.11-1.649,7.13,7.13,0,0,1,5.146-6.838A.648.648,0,0,0,10.672,6.9Zm2.167,2.282A5.843,5.843,0,0,0,7.01,15.01,14.555,14.555,0,0,0,7.8,19.539a.648.648,0,1,0,1.224-.423,13.462,13.462,0,0,1-.72-4.106,4.528,4.528,0,0,1,8.209-2.643.648.648,0,1,0,1.05-.759A5.831,5.831,0,0,0,12.839,9.181Zm0,2.59A3.249,3.249,0,0,0,9.6,15.009a11.146,11.146,0,0,0,1.809,5.983.648.648,0,1,0,1.063-.74A10.361,10.361,0,0,1,10.9,15.009a1.943,1.943,0,0,1,3.886,0v.009A6.962,6.962,0,0,0,15,16.528a3.921,3.921,0,0,0,.937,1.858h0l.976.97a.648.648,0,1,0,.913-.919l-.974-.968a3.039,3.039,0,0,1-.6-1.26,6.229,6.229,0,0,1-.18-1.213V15A3.25,3.25,0,0,0,12.839,11.771Zm5.171,2.15a.648.648,0,0,0-.638.657V15a2.851,2.851,0,0,0,1.06,2.246L18.4,17.22l.484.432a.648.648,0,1,0,.862-.966l-.5-.446-.017-.013A1.462,1.462,0,0,1,18.668,15v-.422a.648.648,0,0,0-.658-.657Zm-5.181.432a.648.648,0,0,0-.638.657,7.92,7.92,0,0,0,1.917,5.207l.432.432a.648.648,0,1,0,.916-.916l-.432-.432c-1.46-1.46-1.537-4.291-1.537-4.291a.648.648,0,0,0-.658-.657Z"
                    transform="translate(-3.979 -4)"
                  />
                </SvgIcon>
              </Tooltip>
            </span>
            <span
              key={getRandomInt(10, 9999)}
              className={
                arr.includes("LIVE_ATTENDANCE") ? " " : classes.displayNone
              }
            >
              <Tooltip TransitionComponent={Zoom} title="Live Attendance">
                <SvgIcon className={classes.icon}>
                  <path
                    id="icons8-marker"
                    d="M14.342,4A7.337,7.337,0,0,0,8.761,16.106l0,0,0,0S11.89,19.676,13,20.738a1.95,1.95,0,0,0,2.678,0c1.267-1.209,4.237-4.627,4.237-4.627l0,0,0,0A7.336,7.336,0,0,0,14.342,4Zm0,1.3a6.04,6.04,0,0,1,4.595,9.968c-.006.007-3.044,3.482-4.15,4.536a.631.631,0,0,1-.89,0c-.924-.882-4.141-4.526-4.15-4.536h0a6.041,6.041,0,0,1,4.6-9.968Zm0,3.455a2.572,2.572,0,0,0-1.942.825,2.726,2.726,0,0,0,0,3.533,2.7,2.7,0,0,0,3.883,0,2.726,2.726,0,0,0,0-3.533A2.572,2.572,0,0,0,14.342,8.751Zm0,1.3a1.194,1.194,0,0,1,.973.39,1.435,1.435,0,0,1,0,1.812,1.194,1.194,0,0,1-.973.39,1.194,1.194,0,0,1-.973-.39,1.435,1.435,0,0,1,0-1.812A1.194,1.194,0,0,1,14.342,10.046Z"
                    transform="translate(-7 -4)"
                  />
                </SvgIcon>
              </Tooltip>
            </span>
            <span
              key={getRandomInt(10, 9999)}
              className={arr.includes("REQUEST") ? " " : classes.displayNone}
            >
              <Tooltip TransitionComponent={Zoom} title="Request">
                <SvgIcon className={classes.icon}>
                  <path
                    id="icons8-spreadsheet-file"
                    d="M9.944,4A1.954,1.954,0,0,0,8,5.944V19.337a1.954,1.954,0,0,0,1.944,1.944h9.937a1.954,1.954,0,0,0,1.944-1.944V10.264a.648.648,0,0,0-.19-.458L21.628,9.8l-5.61-5.61A.648.648,0,0,0,15.561,4Zm0,1.3h4.968V8.968a1.954,1.954,0,0,0,1.944,1.944h3.672v8.425a.638.638,0,0,1-.648.648H9.944a.638.638,0,0,1-.648-.648V5.944A.638.638,0,0,1,9.944,5.3Zm6.264.916,3.4,3.4H16.857a.638.638,0,0,1-.648-.648Zm-4.536,6.428a.648.648,0,1,0,0,1.3h2.16a.648.648,0,1,0,0-1.3Zm4.32,0a.648.648,0,1,0,0,1.3h2.16a.648.648,0,1,0,0-1.3Zm-4.32,2.16a.648.648,0,1,0,0,1.3h2.16a.648.648,0,1,0,0-1.3Zm4.32,0a.648.648,0,1,0,0,1.3h2.16a.648.648,0,1,0,0-1.3Zm-4.32,2.16a.648.648,0,1,0,0,1.3h2.16a.648.648,0,1,0,0-1.3Zm4.32,0a.648.648,0,1,0,0,1.3h2.16a.648.648,0,1,0,0-1.3Z"
                    transform="translate(-8 -4)"
                  />
                </SvgIcon>
              </Tooltip>
            </span>
          </>
        );
      },
    },
    {
      key: "schedule",
      label: "Schedule",
      render: (row) => {
        const arrSchedule = row.schedule.split(" - ");
        return (
          <span>{`${moment(arrSchedule[0]).format("HH:mm")} - ${moment(
            arrSchedule[1]
          ).format("HH:mm")}`}</span>
        );
      },
    },
    {
      key: "clock_in",
      label: "Clock In",
      type: "date",
      dateFormat: "HH:mm",
      defaultValue: "-",
    },
    {
      key: "clock_out",
      label: "Clock Out",
      type: "date",
      dateFormat: "HH:mm",
      defaultValue: "-",
    },
    {
      key: "id",
      label: "",
      sortable: false,
      render: (row) => {
        return (
          <Box display="flex" justifyContent={"flex-end"}>
            <Link to={`${row.date}`}>
              <IconButton aria-label="edit">
                <ChevronRightIcon fontSize="small" />
              </IconButton>
            </Link>

            {row.can_suspend ? (
              <Tooltip title="Suspend">
                <IconButton aria-label="suspend">
                  <BlockOutlined fontSize="small" />
                </IconButton>
              </Tooltip>
            ) : null}

            {row.can_unsuspend ? (
              <Tooltip title="Unsuspend">
                <IconButton aria-label="unsuspend">
                  <CheckCircleOutlineOutlined fontSize="small" />
                </IconButton>
              </Tooltip>
            ) : null}
          </Box>
        );
      },
    },
  ]);
  const { control, watch } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    generateYear();
    generateMonth();
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadData();
  }, [selectedMonth]);

  useEffect(() => {
    loadData();
  }, [selectedYear]);

  const loadData = () => {
    if (isMounted.current) {
      loading.set(false);
      setLoadingData(true);
    }

    let params: any = {
      month: _.get(selectedMonth, `key`, null),
      year: _.get(selectedYear, `key`, null),
    };

    if (objParams.id !== undefined) {
      params = {
        ...params,
        user: objParams.id,
      };

      AttendanceRepository.all({
        ...params,
        ...mapDataTableParams($cloneState(options)),
      })
        .then((response: AxiosResponse) => {
          const { data: responseData } = response;
          const { data: list, meta } = responseData;

          if (isMounted.current) {
            data.set(list);
            options.page.set(meta.current_page - 1);
            options.total.set(meta.total);
            loading.set(false);
          }
        })
        .catch(() => {
          if (isMounted.current) {
            loading.set(false);
          }
        });
    } else {
      AttendanceRepository.account({
        ...params,
        ...mapDataTableParams($cloneState(options)),
      })
        .then((response: AxiosResponse) => {
          const { data: responseData } = response;
          const { data: list, meta } = responseData;

          if (isMounted.current) {
            data.set(list);
            options.page.set(meta.current_page - 1);
            options.total.set(meta.total);
            loading.set(false);
          }
        })
        .catch(() => {
          if (isMounted.current) {
            loading.set(false);
          }
        });
    }

    WidgetRepository.attendance({
      ...params,
    })
      .then((response: AxiosResponse) => {
        const tempAttendance = {
          time_to: _.get(response, "data.attendance.time_to"),
          time_from: _.get(response, "data.attendance.time_from"),
          expired: _.get(response, "data.attendance.date"),
          clock_in: _.get(response, "data.attendance.clock_in"),
        };

        if (isMounted.current && _.get(response, "data.attendance", null)) {
          setAttendance(tempAttendance);
          setLoadingData(false);
        }
      })
      .catch(() => {
        if (isMounted.current) {
          setLoadingData(false);
        }
      });
  };

  const generateYear = () => {
    let tempYear: any = [];
    var year = new Date().getFullYear();
    tempYear.push({ key: year, label: year.toString() });

    year -= 1;
    for (var i = 0; i < 5; i++) {
      tempYear.push({ key: year - i, label: (year - i).toString() });
    }

    if (isMounted.current) {
      yearOptions.set(tempYear);
      yearNow.set(
        tempYear.find((val: any) => {
          return val.key == yearNow.get();
        })
      );
    }
  };

  const generateMonth = () => {
    {
      let tempMonth: any = [];
      moment.months().map((label, value) => {
        tempMonth.push({ key: value + 1, label: label });
      });

      if (isMounted.current) {
        monthOptions.set(tempMonth);
        monthNow.set(
          tempMonth.find((val: any) => {
            return val.key == monthNow.get();
          })
        );
      }
    }
  };

  const option = useState<AttendanceOptionInterface>({
    monthAttendance: {
      options: [],
      loading: false,
      params: {},
      loadData: () => {
        if (isMounted.current) {
          option.monthAttendance.loading.set(true);
        }

        if (isMounted.current) {
          option.monthAttendance.options.set($clone(monthOptions.get()));
          option.monthAttendance.loading.set(false);
        }
      },
      onOpen: () => {
        if (isMounted.current) {
          option.monthAttendance.options.set([]);
          option.monthAttendance.loadData.get()();
        }
      },
    },
    yearAttendance: {
      options: [],
      loading: false,
      params: {},
      loadData: () => {
        if (isMounted.current) {
          option.yearAttendance.loading.set(true);
        }

        if (isMounted.current) {
          option.yearAttendance.options.set($clone(yearOptions.get()));
          option.yearAttendance.loading.set(false);
        }
      },
      onOpen: () => {
        if (isMounted.current) {
          option.yearAttendance.options.set([]);
          option.yearAttendance.loadData.get()();
        }
      },
    },
  });

  return (
    <People navData={employeeNavData}>
      <>
        {loadingData ? (
          <>
            <Grid container spacing={3} justifyContent="space-between">
              <Grid item>
                <Typography variant={"h5"}>Attendance</Typography>
              </Grid>
              <Grid item>
                <Link to={"/apps/time/attendance/attendance-request"}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.buttonPrimary}
                  >
                    Request Attendance
                  </Button>
                </Link>
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper variant="elevation" elevation={1}>
                  <Box p={2}>
                    <Grid container spacing={2} alignItems={"center"}>
                      {/*<Grid item md={3} xs={3}>*/}
                      {/*  <VuiSelect*/}
                      {/*    label="Category"*/}
                      {/*    state={orderBy}*/}
                      {/*    options={orderByOptions}*/}
                      {/*    fullWidth={true}*/}
                      {/*  />*/}
                      {/*</Grid>*/}
                      <Grid item md={3} xs={3}>
                        <Controller
                          render={({ value, name, onChange }) => (
                            <Autocomplete
                              value={value}
                              loading={option.monthAttendance.loading.get()}
                              options={$clone(
                                option.monthAttendance.options.get()
                              )}
                              onOpen={option.monthAttendance.onOpen.get()}
                              getOptionLabel={(option: any) =>
                                optionLabel(option, "label")
                              }
                              getOptionSelected={(option: any, value: any) =>
                                optionSelected(option, value, "key")
                              }
                              onChange={(event: any, newValue: any) => {
                                onChange(newValue);
                                setMonth(newValue);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  name={name}
                                  label="Month"
                                  variant="outlined"
                                  fullWidth
                                />
                              )}
                            />
                          )}
                          name={`month`}
                          control={control}
                          defaultValue={monthNow.get()}
                        />
                      </Grid>
                      <Grid item md={3} xs={3}>
                        <Controller
                          render={({ value, name, onChange }) => (
                            <Autocomplete
                              value={value}
                              loading={option.yearAttendance.loading.get()}
                              options={$clone(
                                option.yearAttendance.options.get()
                              )}
                              onOpen={option.yearAttendance.onOpen.get()}
                              getOptionLabel={(option: any) =>
                                optionLabel(option, "label")
                              }
                              getOptionSelected={(option: any, value: any) =>
                                optionSelected(option, value, "key")
                              }
                              onChange={(event: any, newValue: any) => {
                                onChange(newValue);
                                setYear(newValue);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  name={name}
                                  label="Year"
                                  variant="outlined"
                                  fullWidth
                                />
                              )}
                            />
                          )}
                          name={`year`}
                          control={control}
                          defaultValue={yearNow.get()}
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  <VuiDataTable
                    loading={loading.get()}
                    columns={columns.value}
                    options={$clone(options.value)}
                    data={data.value}
                    onChangeOptions={(values: Options) => {
                      options.set(values);
                      loadData();
                    }}
                  />
                </Paper>
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            {!_.isEmpty(attendance) && (
              <Box mb={3}>
                <Grid container justifyContent="flex-start">
                  <Grid item xs={4}>
                    <Box
                      display="flex"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                    >
                      <Paper className={classes.paperWrapper}>
                        <Box className={classes.boxWrapper}>
                          <Typography
                            variant={"subtitle1"}
                            className={classes.titleColor}
                          >
                            Today Attendance
                          </Typography>
                          <Typography
                            variant={"h4"}
                            className={classes.fontColor}
                          >
                            {moment(
                              _.get(attendance, "time_from"),
                              "HH:mm"
                            ).format("HH:mm")}{" "}
                            {"-"}{" "}
                            {moment(
                              _.get(attendance, "time_to"),
                              "HH:mm"
                            ).format("HH:mm")}
                          </Typography>
                          <Typography
                            variant={"caption"}
                            className={classes.expiredFont}
                          >
                            Clock In on{" "}
                            {moment(
                              _.get(attendance, "clock_in"),
                              "YYYY-MM-DD HH:mm:ss"
                            ).format("HH:mm")}
                          </Typography>
                        </Box>
                      </Paper>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            )}

            <Grid container spacing={3} justifyContent="space-between">
              <Grid item>
                <Typography variant={"h5"}>Attendance</Typography>
              </Grid>
              <Grid item>
                <Link to={"/apps/time/attendance/attendance-request"}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.buttonPrimary}
                  >
                    Request Attendance
                  </Button>
                </Link>
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper variant="elevation" elevation={1}>
                  <Box p={2}>
                    <Grid container spacing={3} alignItems={"center"}>
                      <Grid item xs={3}>
                        <VuiSelect
                          label="Category"
                          state={orderBy}
                          options={orderByOptions}
                          fullWidth={true}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Controller
                          render={({ value, name, onChange }) => (
                            <Autocomplete
                              value={value}
                              loading={option.monthAttendance.loading.get()}
                              options={$clone(
                                option.monthAttendance.options.get()
                              )}
                              onOpen={option.monthAttendance.onOpen.get()}
                              getOptionLabel={(option: any) =>
                                optionLabel(option, "label")
                              }
                              getOptionSelected={(option: any, value: any) =>
                                optionSelected(option, value, "key")
                              }
                              onChange={(event: any, newValue: any) => {
                                onChange(newValue);
                                setMonth(newValue);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  name={name}
                                  label="Month"
                                  variant="outlined"
                                  fullWidth
                                />
                              )}
                            />
                          )}
                          name={`month`}
                          control={control}
                          defaultValue={monthNow.get()}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Controller
                          render={({ value, name, onChange }) => (
                            <Autocomplete
                              value={value}
                              loading={option.yearAttendance.loading.get()}
                              options={$clone(
                                option.yearAttendance.options.get()
                              )}
                              onOpen={option.yearAttendance.onOpen.get()}
                              getOptionLabel={(option: any) =>
                                optionLabel(option, "label")
                              }
                              getOptionSelected={(option: any, value: any) =>
                                optionSelected(option, value, "key")
                              }
                              onChange={(event: any, newValue: any) => {
                                onChange(newValue);
                                setYear(newValue);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  name={name}
                                  label="Year"
                                  variant="outlined"
                                  fullWidth
                                />
                              )}
                            />
                          )}
                          name={`year`}
                          control={control}
                          defaultValue={yearNow.get()}
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  <VuiDataTable
                    loading={loading.get()}
                    columns={columns.value}
                    options={$clone(options.value)}
                    data={data.value}
                    onChangeOptions={(values: Options) => {
                      options.set(values);
                      loadData();
                    }}
                  />
                </Paper>
              </Grid>
            </Grid>
          </>
        )}
      </>
    </People>
  );
};

export default EmployeeAttendance;
