import {
  Box,
  Divider,
  Hidden,
  SwipeableDrawer,
  Toolbar,
  useTheme,
} from "@material-ui/core";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useStyles } from "./style";
import Menu from "./Part/Menu";
import { SidebarInterface } from "../../../interfaces";
import { Images } from "../../../assets/images";

interface Props {
  data: SidebarInterface;
  open: boolean;
  toggleDrawer(): void;
  upperSidebarData: SidebarInterface;
  approvalCount?: number;
}

const Sidebar: React.FC<Props> = ({
  data,
  open,
  toggleDrawer,
  upperSidebarData,
  approvalCount,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const [drawerVariant, setDrawerVariant] = useState<
    "persistent" | "temporary"
  >("persistent");

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  });

  useLayoutEffect(() => {
    handleResize();
  });

  const handleResize = () => {
    if (window.innerWidth <= theme.breakpoints.values.md) {
      setDrawerVariant("temporary");
    } else {
      setDrawerVariant("persistent");
    }
  };

  return (
    <SwipeableDrawer
      className={classes.drawer}
      anchor="left"
      elevation={0}
      variant={drawerVariant}
      classes={{ paper: classes.drawerPaper }}
      open={open}
      onClose={toggleDrawer}
      onOpen={toggleDrawer}
    >
      <Box>
        <Hidden only={["xs", "sm"]}>
          <Toolbar />
        </Hidden>
        <Hidden mdUp>
          <div className={classes.brandWrapper}>
            <img className="logo" src={Images.logoApps} alt="vodea cms logo" />
            {/* <span className='brand-name'>{app.name}</span> */}
          </div>
        </Hidden>
        <Menu
          data={upperSidebarData.sections}
          toggleDrawer={toggleDrawer}
          approvalCount={approvalCount}
        />
        <Divider variant="middle" />
        <Menu data={data.sections} toggleDrawer={toggleDrawer} />
      </Box>
    </SwipeableDrawer>
  );
};

export default Sidebar;
