import { useState } from "@hookstate/core/dist";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { KeyboardDatePicker } from "@material-ui/pickers";
import _ from "lodash";
import moment from "moment";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import VuiCardContent from "../../../../../@VodeaUI/components/VuiCardContent";
import TitleForm from "../../../../../components/molecules/TitleForm";
import UserRepository from "../../../../../repositories/UserRepository";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CancelIcon from "@material-ui/icons/Cancel";
import MediaRepository from "../../../../../repositories/MediaRepositories";
import SettlementRepository from "../../../../../repositories/SettlementRepository";
import FooterFormAction from "../../../../../components/FooterFormAction";
import { OptionInterface } from "./interface";
import {
  $clone,
  $cloneState,
  mapParams,
} from "../../../../../utilities/helpers/global";
import { AxiosResponse } from "axios";
import { useIsMounted } from "../../../../../@VodeaUI";
import { makeStyles } from "@material-ui/core/styles";
import { useGlobalStyles } from "../../../../../utilities/styles";
import {
  optionLabel,
  optionSelected,
} from "../../../../../utilities/helpers/select";
import { useSnackbar } from "notistack";
import VuiNumberFormat from "../../../../../@VodeaUI/components/Input/VuiNumberFormat";
import { formatFormData } from "../../../../../utilities/helpers/form";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const schema = yup.object().shape({
  user_id: yup.mixed().required("Employee is required"),
  cash_advance_request_id: yup.mixed().required("Advance Category is required"),
  description: yup.string().required("Description is required"),
  date: yup.mixed().required("Date is required"),
});

const useStyles = makeStyles({
  tableHead: {
    minWidth: "10rem",
  },
  tableCell: {
    paddingBottom: "1rem",
  },
  fileAttachment: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    marginRight: 8,
  },
  centerLoading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

const RequestSettlement: React.FC<any> = () => {
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useSelector(({ vodea }: any) => {
    return {
      user: vodea.auth.user,
    };
  });

  const { control, errors, handleSubmit, setValue } = useForm({
    mode: "onChange",
    defaultValues: {
      user_id: null,
      date: moment(),
      cash_advance_request_id: null,
      description: "",
      settlement_items: [],
    },
    resolver: yupResolver(schema),
  });

  const [itemData, setItemData] = React.useState<any[]>([]);
  const [loadingData, setLoadingData] = React.useState<boolean>(false);
  const userId = useState<any>(null);
  const loading = useState<boolean>(false);
  const option = useState<OptionInterface>({
    employee: {
      options: [],
      loading: false,
      params: {
        for: "settlement",
      },
      loadData: async () => {
        if (isMounted.current) {
          option.employee.loading.set(true);
        }
        await UserRepository.select(
          mapParams($cloneState(option.employee.params))
        )
          .then(({ data }: AxiosResponse) => {
            if (isMounted.current) {
              option.employee.options.set(data.data);
              option.employee.loading.set(false);
            }
          })
          .catch(() => {});
      },
      onOpen: () => {
        if (isMounted.current) {
          option.employee.options.set([]);
          option.employee.loadData.get()();
        }
      },
    },
    category: {
      options: [],
      loading: false,
      params: {
        for: "settlement",
      },
      loadData: async () => {
        if (isMounted.current) {
          option.category.loading.set(true);
        }
        await SettlementRepository.getCategory({
          ...mapParams($cloneState(option.category.params)),
          user: userId.get(),
        })
          .then(({ data }: AxiosResponse) => {
            if (isMounted.current) {
              option.category.options.set(data.data);
              option.category.loading.set(false);
            }
          })
          .catch(() => {});
      },
      onOpen: () => {
        if (isMounted.current) {
          option.category.options.set([]);
          option.category.loadData.get()();
        }
      },
    },
  });

  useEffect(() => {
    if (user && !id) {
      setTimeout(() => {
        userId.set(user.id);
        setValue("user_id", user);
      }, 200);
    }
  }, [user]);

  const onSubmit = (data: any) => {
    if (isMounted.current) {
      loading.set(true);
    }
    const formData = formatFormData(data);
    const tempItems: any[] = [];

    if (formData.user_id) {
      delete formData.user_id;
    }

    if (itemData.length > 0) {
      itemData.forEach((item: any) => {
        let tempData: any = {
          amount: item?.usedAmount,
          cash_advance_request_item_id: item?.id,
          description: item?.itemDescription,
        };

        if (item.file && item.file.id) {
          tempData.attachment_id = item.file.id;
        }

        tempItems.push(tempData);
      });

      formData.settlement_items = tempItems;
    }

    SettlementRepository.create(formData)
      .then((response: any) => {
        enqueueSnackbar(response.data.message, {
          variant: "success",
        });
        if (isMounted.current) {
          loading.set(false);
          navigate("/apps/finance/settlement");
        }
      })
      .catch((err: any) => {
        if (err?.response?.data.errors) {
          const flatten: any[] = Object.values(
            err?.response?.data.errors
          ).flat();
          if (flatten.length > 0) {
            enqueueSnackbar(flatten[0], {
              variant: "error",
            });
          }
        }
        if (isMounted.current) {
          loading.set(false);
        }
      });
  };

  const changeStateDetail = (index: number, key: any, value: any) => {
    const stateDetail: any[] = [...itemData];
    stateDetail[index][key] = value;

    if (isMounted.current) {
      setItemData(stateDetail);
    }
  };

  const handleUploadFile = (index: any, files: any) => {
    changeStateDetail(index, "loading", true);
    let formData = new FormData();
    formData.append("file", files[0]);
    formData.append("path", "settlement-request");
    formData.append("disk", "gcs");

    MediaRepository.create(formData)
      .then((response: any) => {
        changeStateDetail(index, "file", response.data.data);
        changeStateDetail(index, "loading", false);
      })
      .catch((error: any) => {
        changeStateDetail(index, "loading", false);
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      });
  };

  const handleSetDetailValue = (category: any) => {
    if (isMounted.current) {
      setLoadingData(true);
      setItemData([]);
    }
    SettlementRepository.getDetail(category.id)
      .then((response: any) => {
        const items = _.get(
          response.data,
          "data.cash_advance_request_items",
          []
        );

        if (isMounted.current) {
          setLoadingData(false);
          setItemData(items);
        }
      })
      .catch((error: any) => {
        if (isMounted.current) {
          setLoadingData(false);
        }
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      });
  };

  return (
    <>
      <TitleForm title="Request Settlement" withBackUrl={true} />

      <Grid container spacing={3}>
        <Grid item md={8} xs={12}>
          <VuiCardContent title="Information">
            <Box p={2}>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <Controller
                    render={({ value, name, onChange }) => (
                      <Autocomplete
                        value={value}
                        disableClearable
                        loading={option.employee.loading.get()}
                        options={$clone(option.employee.options.get())}
                        onOpen={option.employee.onOpen.get()}
                        getOptionLabel={(option) => optionLabel(option)}
                        getOptionSelected={(option, value) =>
                          optionSelected(option, value)
                        }
                        onChange={(event: any, newValue: any) => {
                          onChange(newValue);
                          userId.set(newValue.id);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name={name}
                            label="Employee"
                            variant="outlined"
                            error={_.has(errors, `user_id`)}
                            helperText={_.get(errors, `user_id.message`)}
                          />
                        )}
                      />
                    )}
                    name="user_id"
                    control={control}
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <Controller
                    render={({ value, name, onChange }) => (
                      <Autocomplete
                        value={value}
                        disableClearable
                        loading={option.category.loading.get()}
                        options={$clone(option.category.options.get())}
                        onOpen={option.category.onOpen.get()}
                        getOptionLabel={(option) =>
                          optionLabel(option, "number")
                        }
                        getOptionSelected={(option, value) =>
                          optionSelected(option, value)
                        }
                        onChange={(event: any, newValue: any) => {
                          onChange(newValue);
                          handleSetDetailValue(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name={name}
                            label="Advance ID"
                            variant="outlined"
                            error={_.has(errors, `cash_advance_request_id`)}
                            helperText={_.get(
                              errors,
                              `cash_advance_request_id.message`
                            )}
                          />
                        )}
                      />
                    )}
                    name="cash_advance_request_id"
                    control={control}
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <Controller
                    render={({ value, name, onChange }) => (
                      <KeyboardDatePicker
                        name={name}
                        disableToolbar
                        fullWidth
                        variant="inline"
                        inputVariant="outlined"
                        label="Used Date"
                        format="DD MMM YYYY"
                        error={_.has(errors, "date")}
                        helperText={_.get(errors, "date.message")}
                        value={value}
                        onChange={onChange}
                      />
                    )}
                    name="date"
                    control={control}
                  />
                </Grid>

                <Grid item md={12} xs={12}>
                  <Controller
                    render={({ value, name, onChange }) => (
                      <TextField
                        name={name}
                        label="Purpose"
                        value={value}
                        variant="outlined"
                        fullWidth
                        onChange={onChange}
                        error={_.has(errors, "description")}
                        helperText={_.get(errors, "description.message")}
                      />
                    )}
                    name="description"
                    control={control}
                  />
                </Grid>
              </Grid>
            </Box>
          </VuiCardContent>
        </Grid>

        <Grid item md={8} xs={12}>
          <VuiCardContent title="Detail">
            <Box mb={2}>
              {loadingData ? (
                <Box p={2} className={classes.centerLoading}>
                  <CircularProgress />
                </Box>
              ) : itemData.length > 0 ? (
                <TableContainer>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.tableHead}>
                          Category
                        </TableCell>
                        <TableCell className={classes.tableHead}>
                          Description
                        </TableCell>
                        <TableCell className={classes.tableHead}>
                          Advance (IDR)
                        </TableCell>
                        <TableCell className={classes.tableHead}>
                          Used Amount (IDR)
                        </TableCell>
                        <TableCell>File</TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {itemData.map((item: any, index: number) => {
                        return (
                          <TableRow key={index}>
                            <TableCell className={classes.tableCell}>
                              <TextField
                                value={item?.cash_advance_formula_policy?.name}
                                variant="outlined"
                              />
                            </TableCell>

                            <TableCell className={classes.tableCell}>
                              <TextField
                                value={item?.itemDescription || ""}
                                variant="outlined"
                                onChange={(event) => {
                                  changeStateDetail(
                                    index,
                                    "itemDescription",
                                    event.target.value
                                  );
                                }}
                              />
                            </TableCell>

                            <TableCell className={classes.tableCell}>
                              <VuiNumberFormat data={item?.amount} />
                            </TableCell>

                            <TableCell className={classes.tableCell}>
                              <TextField
                                value={item?.usedAmount || ""}
                                type="number"
                                variant="outlined"
                                onChange={(event) => {
                                  changeStateDetail(
                                    index,
                                    "usedAmount",
                                    event.target.value
                                  );
                                }}
                              />
                            </TableCell>

                            <TableCell className={classes.tableCell}>
                              {_.get(itemData[index], "loading", false) ? (
                                <CircularProgress />
                              ) : _.get(itemData[index], "file") ? (
                                <div className={classes.fileAttachment}>
                                  <IconButton
                                    className={classes.icon}
                                    onClick={() =>
                                      changeStateDetail(index, "file", null)
                                    }
                                  >
                                    <CancelIcon />
                                  </IconButton>
                                  <div>
                                    {_.get(itemData[index], "file.name")}
                                  </div>
                                </div>
                              ) : (
                                <Button
                                  variant="contained"
                                  component="label"
                                  color="primary"
                                  className={globalClasses.buttonPrimary}
                                >
                                  <input
                                    type="file"
                                    hidden
                                    className="btn btn-primary"
                                    id="raised-button-file"
                                    onChange={(event: any) => {
                                      handleUploadFile(
                                        index,
                                        event.target.files
                                      );
                                    }}
                                  />
                                  Attach
                                </Button>
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Box p={2}>
                  <Typography variant="subtitle2" align="center" color="error">
                    Please Choose The Advance Category First.
                  </Typography>
                </Box>
              )}
            </Box>
          </VuiCardContent>
        </Grid>

        <Grid item md={8} xs={12}>
          <FooterFormAction
            cancelUrl={"/apps/finance/settlement"}
            submitBtnLabel={"Submit"}
            loading={loading.get()}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            labelForm={"Submit Request Settlement?"}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default RequestSettlement;
