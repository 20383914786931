import { api } from "../@VodeaUI";

let url = window._env_.REACT_APP_PASSPORT_END_POINT;

const SettingRepository = {
  getPayrollSchedule: function (params: any = null) {
    return api.get(`${url}/api/setting/PAYROLL_SCHEDULE`, {
      params,
    });
  },
  updatePayrollSchedule: function (body: any) {
    return api.put(`${url}/api/setting/PAYROLL_SCHEDULE`, {
      ...body,
    });
  },
  getPayrollCutOff: function (params: any = null) {
    return api.get(`${url}/api/setting/PAYROLL_CUT_OFF`, {
      params,
    });
  },
  updatePayrollCutOff: function (body: any) {
    return api.put(`${url}/api/setting/PAYROLL_CUT_OFF`, {
      ...body,
    });
  },
};

export default SettingRepository;
