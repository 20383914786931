import React from "react";
import { Box, Grid, TextField, Typography } from "@material-ui/core";
import { useState } from "@hookstate/core/dist";
import { Controller, useForm } from "react-hook-form";
import VuiCardContent from "../../../../../@VodeaUI/components/VuiCardContent";
import _ from "lodash";
import FooterFormAction from "../../../../../components/FooterFormAction";
import { KeyboardDatePicker, KeyboardTimePicker } from "@material-ui/pickers";
import TitleForm from "../../../../../components/molecules/TitleForm";
import { OptionInterface } from "./interface";
import {
  $clone,
  $cloneState,
  mapHookErrors,
  mapParams,
} from "../../../../../utilities/helpers/global";
import { AxiosResponse } from "axios";
import UserRepository from "../../../../../repositories/UserRepository";
import { useIsMounted } from "../../../../../utilities/helpers/hooks";
import { Autocomplete } from "@material-ui/lab";
import {
  optionLabel,
  optionSelected,
} from "../../../../../utilities/helpers/select";
import { attendanceCategoryOptions } from "../../../../../constants";
import moment from "moment";
import { useParams } from "react-router-dom";
import { formatFormData } from "../../../../../utilities/helpers/form";
import AttendanceRequestRepository from "../../../../../repositories/AttendanceRequestRepository";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router";

const RequestAttendance: React.FC<any> = () => {
  const isMounted = useIsMounted();
  const { id } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const {
    control,
    errors,
    setValue,
    register,
    setError,
    reset,
    handleSubmit,
    watch,
  } = useForm({
    mode: "onChange",
  });

  const watchAttendanceType = watch("attendance_type", null);

  const option = useState<OptionInterface>({
    employee: {
      options: [],
      loading: false,
      params: {
        for: "attendance",
      },
      loadData: async () => {
        if (isMounted.current) {
          option.employee.loading.set(true);
        }
        await UserRepository.select(
          mapParams($cloneState(option.employee.params))
        )
          .then(({ data }: AxiosResponse) => {
            if (isMounted.current) {
              option.employee.options.set(data.data);
              option.employee.loading.set(false);
            }
          })
          .catch(() => {});
      },
      onOpen: () => {
        if (isMounted.current) {
          option.employee.options.set([]);
          option.employee.loadData.get()();
        }
      },
    },
  });

  const loading = useState<boolean>(false);

  const onSubmit = async (data: any) => {
    if (isMounted.current) {
      loading.set(true);
    }

    const formData = formatFormData(data);
    delete formData.attendance_type;

    if (data.clock_in) {
      formData.clock_in = moment(data.clock_in).format("HH:mm:ss");
    }

    if (data.clock_out) {
      formData.clock_out = moment(data.clock_out).format("HH:mm:ss");
    }

    await AttendanceRequestRepository.create(formData)
      .then((resp: any) => {
        if (isMounted.current) {
          enqueueSnackbar(resp.data.message, {
            variant: "success",
          });
          loading.set(false);
          navigate("/apps/time/attendance");
        }
      })
      .catch((err: any) => {
        if (isMounted.current && err?.response?.data?.errors) {
          const errors = mapHookErrors(err.response.data.errors);
          Object.keys(errors).forEach((key: any) => setError(key, errors[key]));

          enqueueSnackbar(err.response.data.message, {
            variant: "error",
          });
          loading.set(false);
        }
      });
  };

  return (
    <>
      <TitleForm title="Attendance Request" withBackUrl={true} />

      <Grid container spacing={3}>
        <Grid item md={8} xs={12}>
          <VuiCardContent title="Information">
            <Box p={2}>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <Controller
                    render={({ value, name, onChange }) => (
                      <Autocomplete
                        value={value}
                        loading={option.employee.loading.get()}
                        options={$clone(option.employee.options.get())}
                        onOpen={option.employee.onOpen.get()}
                        getOptionLabel={(option: any) => optionLabel(option)}
                        getOptionSelected={(option: any, value: any) =>
                          optionSelected(option, value)
                        }
                        onChange={(event: any, newValue: any) => {
                          onChange(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name={name}
                            label="Employee"
                            variant="outlined"
                            error={_.has(errors, `user_id`)}
                            helperText={_.get(errors, `user_id.message`)}
                          />
                        )}
                      />
                    )}
                    name={`user_id`}
                    control={control}
                    defaultValue={null}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <Controller
                    render={({ value, name, onChange }) => (
                      <Autocomplete
                        value={value}
                        multiple
                        options={attendanceCategoryOptions}
                        getOptionLabel={(option: any) => optionLabel(option)}
                        getOptionSelected={(option: any, value: any) =>
                          optionSelected(option, value)
                        }
                        onChange={(event: any, newValue: any) => {
                          onChange(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name={name}
                            label="Attendance Category"
                            variant="outlined"
                            error={_.has(errors, `attendance_type`)}
                            helperText={_.get(
                              errors,
                              `attendance_type.message`
                            )}
                          />
                        )}
                      />
                    )}
                    name={`attendance_type`}
                    control={control}
                    defaultValue={[]}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <Controller
                    render={({ value, name, onChange }) => (
                      <KeyboardDatePicker
                        name={name}
                        disableToolbar
                        fullWidth
                        variant="inline"
                        inputVariant="outlined"
                        label="Date"
                        format="DD MMMM YYYY"
                        error={_.has(errors, "date")}
                        helperText={_.get(errors, "date.message")}
                        value={value}
                        onChange={onChange}
                      />
                    )}
                    name="date"
                    control={control}
                    defaultValue={moment().format("YYYY-MM-DD")}
                  />
                </Grid>

                {watchAttendanceType?.find(
                  (item: any) => item.id === "CLOCK_IN"
                ) && (
                  <Grid item md={6} xs={12}>
                    <Controller
                      render={({ value, name, onChange }) => (
                        <KeyboardTimePicker
                          fullWidth
                          id={name}
                          variant="inline"
                          label="Clock In Time"
                          value={value}
                          inputVariant="outlined"
                          onChange={onChange}
                          KeyboardButtonProps={{
                            "aria-label": "change time",
                          }}
                        />
                      )}
                      name="clock_in"
                      control={control}
                      defaultValue={new Date()}
                    />
                  </Grid>
                )}

                {watchAttendanceType?.find(
                  (item: any) => item.id === "CLOCK_OUT"
                ) && (
                  <Grid item md={6} xs={12}>
                    <Controller
                      render={({ value, name, onChange }) => (
                        <KeyboardTimePicker
                          inputVariant="outlined"
                          variant="inline"
                          fullWidth
                          id={name}
                          label="Clock Out Time"
                          value={value}
                          onChange={onChange}
                          KeyboardButtonProps={{
                            "aria-label": "change time",
                          }}
                        />
                      )}
                      name="clock_out"
                      control={control}
                      defaultValue={new Date()}
                    />
                  </Grid>
                )}

                <Grid item md={12} xs={12}>
                  <TextField
                    name="description"
                    inputRef={register()}
                    label="Reason"
                    variant="outlined"
                    fullWidth
                    error={_.has(errors, "description")}
                    helperText={_.get(errors, "description.message")}
                  />
                </Grid>
              </Grid>
            </Box>
          </VuiCardContent>
        </Grid>

        <Grid item md={8} xs={12}>
          <FooterFormAction
            cancelUrl={"/apps/time/attendance"}
            submitBtnLabel={"Submit"}
            loading={loading.get()}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default RequestAttendance;
