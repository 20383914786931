import React from "react";
import PreferencesPayroll from "../../../../../layouts/Apps/Preference/Payroll";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
} from "@material-ui/core";
import VuiCardContent from "../../../../../@VodeaUI/components/VuiCardContent";
import { Autocomplete } from "@material-ui/lab";
import _ from "lodash";
import { Controller, useForm } from "react-hook-form";

const ProratePayroll: React.FC<any> = () => {
    const { control, errors } = useForm({
        mode: 'onChange',
    });

    const prorateOptions = [
        {
            'label': 'Round Up',
            'key': 1,
        },
        {
            'label': 'Round Down',
            'key': 2,
        }
    ];

    return <PreferencesPayroll>
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <VuiCardContent title='Prorate'>
                    <Box p={2}>
                        <Grid container spacing={3}>
                            <Grid item md={12} xs={12}>
                                <Controller
                                    render={({ value, name, onChange }) => (
                                        <Autocomplete
                                            value={value}
                                            disableClearable
                                            options={prorateOptions}
                                            getOptionLabel={option => option.label}
                                            getOptionSelected={(option, value) =>
                                                option.key === value.key
                                            }
                                            onChange={(event: any, newValue: any) =>
                                                onChange(newValue)
                                            }
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    name={name}
                                                    label="Pro Rate Setting"
                                                    variant="outlined"
                                                    error={_.has(errors, `prorate_setting`)}
                                                    helperText={_.get(
                                                        errors,
                                                        `prorate_setting.message`
                                                    )}
                                                />
                                            )}
                                        />
                                    )}
                                    name="round_type"
                                    control={control}
                                />
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={(event, newValue) =>
                                                console.log(newValue)
                                            }
                                            name="is_default"
                                            color="primary"
                                        />
                                    }
                                    label="Default time off for new employee"
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </VuiCardContent>
            </Grid>
        </Grid>
    </PreferencesPayroll>
}

export default ProratePayroll;
