import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  Modal,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Alert, TreeItem, TreeView } from "@material-ui/lab";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useEffect } from "react";
import { useGlobalStyles } from "../../../../../../utilities/styles";
import { makeStyles } from "@material-ui/core/styles";
import { useIsMounted } from "../../../../../../utilities/helpers/hooks";
import { useNavigate } from "react-router";
import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import { IndeterminateCheckBoxOutlined } from "@material-ui/icons";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const useModalStyles = makeStyles((theme) => ({
  box: {
    textAlign: "center",
    margin: 40,
  },
  paper: {
    position: "absolute",
    width: "50%",
    top: "50%",
    left: "50%",
    maxHeight: "90%",
    overflow: "hidden",
    transform: "translate(-50%,-50%)",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "10px",
    boxShadow: theme.shadows[5],
  },
  wrapper: {
    padding: 20,
    position: "relative",
  },
  closeButton: {
    position: "absolute",
    right: 20,
    top: 10,
  },
  title: {
    textAlign: "center",
    marginTop: 10,
    marginBottom: 20,
  },
  footer: {
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    marginTop: 20,
    justifyContent: "center",
  },
  tree: {
    overflow: "auto",
    height: "50vh",
  },
  activeIcon: {
    fill: "#02827A",
  },
}));

interface IProps {
  open: boolean;
  handleClose: () => void;
  data: any[];
  isLoading: boolean;
  realData: any[];
  callback?: (value: any) => void;
}

const ModalEmploymentData = ({
  open,
  handleClose,
  data = [],
  isLoading = false,
  realData = [],
  callback,
}: IProps) => {
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const modalClasses = useModalStyles();
  const globalClasses = useGlobalStyles();
  const [userTreeData, setUserTreeData] = React.useState<any[]>(data);
  const [userData, setUserData] = React.useState<any[]>([]);
  const [checkedData, setCheckedData] = React.useState<any[]>([]);
  const [userError, setUserError] = React.useState<boolean>(false);

  useEffect(() => {
    if (isMounted.current) setUserTreeData(data);
  }, [data]);

  useEffect(() => {
    if (isMounted.current) setUserData(realData);
  }, [realData]);

  const [expanded, setExpanded] = React.useState<any[]>([]);
  const [selected, setSelected] = React.useState<any[]>([]);

  const onSubmitEmployee = (event: any) => {
    if (checkedData.length <= 0) {
      setUserError(true);
      return;
    }

    if (callback) {
      callback(checkedData);
    } else {
      navigate("employment-data", {
        state: {
          user: checkedData,
        },
      });
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <div className={modalClasses.paper}>
        <Box className={modalClasses.wrapper}>
          <Box className={modalClasses.closeButton}>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>

          <Box className={modalClasses.title}>
            <Typography variant={"h5"}>Select Employee</Typography>
            {userError && (
              <Alert severity="error">Choose at least 1 user</Alert>
            )}
          </Box>

          {isLoading ? (
            <Box className={modalClasses.box}>
              <CircularProgress />
            </Box>
          ) : (
            <CheckboxTree
              nodes={userTreeData}
              checked={selected}
              expanded={expanded}
              onCheck={(checked) => {
                if (isMounted.current) {
                  const temp: any[] = [];
                  checked.map((item: any) => {
                    const findItem = userData.find(
                      (data: any) => data.id == item
                    );
                    if (findItem) temp.push(findItem);
                  });
                  setCheckedData(temp);
                  let clone: any[] = [...selected];
                  clone = checked;
                  setSelected(clone);
                }
              }}
              onExpand={(expand) => {
                let clone: any[] = [...expanded];
                clone = expand;
                setExpanded(clone);
              }}
              icons={{
                check: <CheckBoxIcon className={modalClasses.activeIcon} />,
                uncheck: <CheckBoxOutlineBlankIcon />,
                halfCheck: <IndeterminateCheckBoxOutlined />,
                expandClose: <ChevronRightIcon />,
                expandOpen: <ExpandMoreIcon />,
                expandAll: <ExpandMoreIcon />,
                collapseAll: <ExpandMoreIcon />,
                parentClose: null,
                parentOpen: null,
                leaf: null,
              }}
            />
          )}

          <Box className={modalClasses.footer}>
            <Box marginRight={2}>
              <Button variant="text" onClick={handleClose}>
                Cancel
              </Button>
            </Box>
            <Box>
              <Button
                variant="contained"
                color="primary"
                onClick={onSubmitEmployee}
                className={globalClasses.buttonPrimary}
              >
                Submit
              </Button>
            </Box>
          </Box>
        </Box>
      </div>
    </Modal>
  );
};

export default ModalEmploymentData;
