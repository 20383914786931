import { api } from "../@VodeaUI";

let url = window._env_.REACT_APP_PASSPORT_END_POINT;

const AnnouncementRepository = {
  all: function (params: any = null) {
    return api.get(`${url}/api/announcement`, {
      params,
    });
  },
  account: function (params: any = null) {
    return api.get(`${url}/api/account/announcement`, {
      params,
    });
  },
  show: function (id: number, params: any = null) {
    return api.get(`${url}/api/announcement/${id}`, {
      params,
    });
  },
  create: function (payload: any, params: any = null) {
    return api.post(`${url}/api/announcement`, payload, {
      params,
    });
  },
  update: function (id: number, payload: any, params: any = null) {
    return api.put(`${url}/api/announcement/${id}`, payload, {
      params,
    });
  },
  delete: function (id: number, params: any = null) {
    return api.delete(`${url}/api/announcement/${id}`, {
      params,
    });
  },
};

export default AnnouncementRepository;
