import { InputAdornment, OutlinedInput } from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";
import React from "react";
import { useStyles } from "./style";
import { State, StateMethods, useState } from "@hookstate/core/dist";
import _ from "lodash";

interface Props {
  state: State<string>;
  placeholder?: string;
  callback?: (value: string) => void | null;
  delay?: number;
}

const VuiSearch: React.FC<Props> = ({
  state,
  placeholder = "Search",
  callback = null,
  delay = 300,
}) => {
  const classes = useStyles();
  const scopedState = useState(state);

  const handleChange = _.debounce((value) => {
    scopedState.set(value);

    if (callback) {
      callback(value);
    }
  }, delay);

  return (
    <OutlinedInput
      classes={{
        root: classes.root,
      }}
      fullWidth
      placeholder={placeholder}
      onChange={(e) => handleChange(e.target.value)}
      defaultValue={scopedState.get()}
      startAdornment={
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      }
    />
  );
};

export default VuiSearch;
