import React, { useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
  Avatar,
  Dialog,
  AppBar,
  Toolbar,
  Slide,
} from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import { VuiSearch } from "../../../../@VodeaUI/components";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import {
  Add as AddIcon,
  BlockOutlined,
  CheckCircleOutlineOutlined,
  EditOutlined as EditOutlinedIcon,
  AccountTree as AccountTreeIcon,
  Close as CloseIcon,
} from "@material-ui/icons";
import _ from "lodash";
import { $clone } from "../../../../utilities/helpers/global";
import { useState } from "@hookstate/core/dist";
import VuiDataTable, {
  Column,
  Options,
} from "../../../../@VodeaUI/VuiDataTable/Index";
import { defaultDataTableOption } from "../../../../utilities/Option";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import VuiSelect from "../../../../@VodeaUI/components/Input/VuiSelect/Index";
import { useStyles } from "../../../../layouts/Apps/style";
import TreeTable from "./TreeTable";

const dummyData = [
  {
    id: 1,
    name: "Niel",
    position: "Marketing Manager",
    department: "Marketing",
    email: "niel@vodea.id",
    phone: "081288887777",
    child: [
      {
        id: 4,
        name: "Beatrice",
        position: "Marketing Staff",
        department: "Marketing",
        email: "joshua@vodea.id",
        phone: "081288884444",
        child: [
          {
            id: 10,
            name: "Joe",
            position: "Marketing Staff",
            department: "Marketing",
            email: "joshua@vodea.id",
            phone: "081288884444",
            child: [
              {
                id: 9,
                name: "Mirana",
                position: "Marketing Staff",
                department: "Marketing",
                email: "joshua@vodea.id",
                phone: "081288884444",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 2,
    name: "Alena",
    position: "Marketing Staff",
    department: "Marketing",
    email: "alena@vodea.id",
    phone: "081288885555",
    child: [
      {
        id: 4,
        name: "Beatrice",
        position: "Marketing Staff",
        department: "Marketing",
        email: "joshua@vodea.id",
        phone: "081288884444",
        child: [
          {
            id: 10,
            name: "Joe",
            position: "Marketing Staff",
            department: "Marketing",
            email: "joshua@vodea.id",
            phone: "081288884444",
            child: [
              {
                id: 9,
                name: "Mirana",
                position: "Marketing Staff",
                department: "Marketing",
                email: "joshua@vodea.id",
                phone: "081288884444",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 3,
    name: "Joshua",
    position: "Marketing Staff",
    department: "Marketing",
    email: "joshua@vodea.id",
    phone: "081288884444",
    child: [
      {
        id: 4,
        name: "Beatrice",
        position: "Marketing Staff",
        department: "Marketing",
        email: "joshua@vodea.id",
        phone: "081288884444",
        child: [
          {
            id: 10,
            name: "Joe",
            position: "Marketing Staff",
            department: "Marketing",
            email: "joshua@vodea.id",
            phone: "081288884444",
            child: [
              {
                id: 9,
                name: "Mirana",
                position: "Marketing Staff",
                department: "Marketing",
                email: "joshua@vodea.id",
                phone: "081288884444",
              },
            ],
          },
        ],
      },
    ],
  },
];

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const Directory: React.FC<any> = () => {
  const objParams = useParams();
  const classes = useStyles();
  const orderBy = useState("all");
  const loading = useState(false);
  const options = useState<Options>({
    ...defaultDataTableOption,
    sortedBy: "desc",
    orderBy: "date",
  });
  const data = useState(dummyData);
  const [focusedItem, setFocusedItem] = React.useState(null);

  const columns = useState<Column[]>([
    {
      key: "name",
      label: "Name",
      render: (row) => {
        return (
          <Box display="flex" flexDirection="row" alignItems="center">
            <Box mr={2}>
              <Avatar alt={row.name} src="/broken-image.jpg">
                {row?.name?.charAt(0)}
              </Avatar>
            </Box>
            <Typography>{row.name}</Typography>
          </Box>
        );
      },
    },
    { key: "position", label: "Position" },
    { key: "department", label: "Department" },
    { key: "email", label: "Email" },
    { key: "phone", label: "Phone Number" },
    {
      key: "id",
      label: "",
      sortable: false,
      render: (row) => {
        return (
          <Box display="flex" justifyContent={"flex-end"}>
            <Tooltip title="View Detail">
              <IconButton onClick={() => setFocusedItem(row)} aria-label="edit">
                <AccountTreeIcon fontSize="small" />
              </IconButton>
            </Tooltip>

            {/* {row.can_suspend ? (
              <Tooltip title="Suspend">
                <IconButton aria-label="suspend">
                  <BlockOutlined fontSize="small" />
                </IconButton>
              </Tooltip>
            ) : null}

            {row.can_unsuspend ? (
              <Tooltip title="Unsuspend">
                <IconButton aria-label="unsuspend">
                  <CheckCircleOutlineOutlined fontSize="small" />
                </IconButton>
              </Tooltip>
            ) : null} */}
          </Box>
        );
      },
    },
  ]);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = () => {
    loading.set(false);

    if (objParams.id !== undefined) {
    }
  };

  const orderByOptions = [
    {
      key: "all",
      label: "All",
    },
    {
      key: "a-z",
      label: "A-Z",
    },
  ];

  return (
    <>
      <Grid container spacing={3} justifyContent="space-between">
        <Grid item>
          <Typography variant={"h5"}>Directory</Typography>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper variant="elevation" elevation={1}>
            <Box p={2}>
              <Grid
                container
                spacing={3}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Grid item>
                  <VuiSearch
                    state={options.search}
                    callback={() => {
                      options.page.set(0);
                      loadData();
                    }}
                  />
                </Grid>
                <Grid item>
                  <VuiSelect
                    label="Urutkan"
                    state={orderBy}
                    options={orderByOptions}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box p={2}>
              <Grid container spacing={3} alignItems={"center"}>
                <Grid item>
                  <VuiSelect
                    label="Department"
                    state={orderBy}
                    options={orderByOptions}
                  />
                </Grid>
                <Grid item>
                  <VuiSelect
                    label="Position"
                    state={orderBy}
                    options={orderByOptions}
                  />
                </Grid>
              </Grid>
            </Box>

            <VuiDataTable
              loading={loading.get()}
              columns={columns.value}
              options={$clone(options.value)}
              data={data.value}
              onChangeOptions={(values: Options) => {
                options.set(values);
                loadData();
              }}
            />
          </Paper>
        </Grid>
      </Grid>
      <Dialog
        fullScreen
        open={Boolean(focusedItem)}
        TransitionComponent={Transition}
      >
        <AppBar style={{ position: "relative", backgroundColor: "white" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setFocusedItem(null)}
              aria-label="close"
            >
              <CloseIcon color="primary" />
            </IconButton>
            <Typography variant="h6" color="primary">
              Details directory of {_.get(focusedItem, "name")}
            </Typography>
          </Toolbar>
        </AppBar>
        <TreeTable data={focusedItem} />
      </Dialog>
    </>
  );
};

export default Directory;
