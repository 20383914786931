import { makeStyles, alpha } from "@material-ui/core/styles";

const drawerWidth = 230;

export const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    [theme.breakpoints.down("sm")]: {
      width: "unset",
    },
  },
  drawerPaper: {
    width: drawerWidth,
    display: "flex",
    "&.MuiDrawer-paperAnchorDockedLeft": {
      borderRight: 0,
    },
  },
  count: {
    marginLeft: "auto",
    backgroundColor: "#013332",
    padding: "3px 14px",
    borderRadius: 25,
    fontSize: 10,
    color: "white",
  },
  drawerContainer: {
    overflow: "auto",
  },
  sidebar: {
    paddingTop: 20,

    [theme.breakpoints.down("sm")]: {
      paddingTop: 0,
    },

    "& .nav-list": {
      margin: 0,
      marginBottom: 15,
      padding: 0,
      listStyle: "none",

      "& .nav-item": {
        paddingLeft: "12.5px",
        paddingBottom: "1px",

        "& a": {
          textDecoration: "none",
          color: theme.palette.text.primary,
        },

        "&.opened": {
          color: theme.palette.primary.main,

          "& .nav-dropdown": {
            height: "100%",
            marginBottom: 10,
          },
        },
      },

      "& .nav-link": {
        justifyContent: "start",
        position: "relative",
        cursor: "pointer",
        padding: "6px 12.5px",
        paddingLeft: "18px",
        background: "white",
        // borderRadius: theme.shape.borderRadius,
        display: "flex",
        alignItems: "center",
        transition: "all 0.5s ease",
        borderRadius: "5px",

        [theme.breakpoints.down("sm")]: {
          paddingLeft: "unset",
        },

        "&:hover": {
          background: alpha(theme.palette.secondary.dark, 0.2),
          color: theme.palette.primary.dark,
        },

        "&.active": {
          color: theme.palette.primary.dark,
          background: alpha(theme.palette.secondary.dark, 0.2),
          fontWeight: 400,
        },
      },

      "& .nav-icon": {
        minWidth: 30,
        display: "flex",
      },

      "& .nav-pills": {
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        right: 12.5,
        fontSize: 8,
        width: 22,
        height: 15,
        background: theme.palette.error.main,
        color: "white",
        borderRadius: 3,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },

      "& .nav-dropdown": {
        height: 0,
        marginBottom: 0,
        transition: "all 0.5ms ease",
        overflow: "hidden",
      },
    },

    "& .section-title": {
      padding: "0px 25px",
      fontWeight: 700,
      fontSize: 12,
      marginBottom: 5,
      textTransform: "uppercase",
      letterSpacing: 1,
    },

    "& .dropdown-list": {
      padding: 0,
      listStyle: "none",
      // paddingLeft: 30,

      "& .dropdown-link": {
        position: "relative",
        display: "flex",
        cursor: "pointer",
        padding: "5px 12.5px",
        paddingLeft: "48px",
        transition: "all 0.5ms",
        // borderRadius: theme.shape.borderRadius,
        justifyContent: "flex-start",
        fontWeight: 0,
        borderRadius: "5px",

        "&:hover": {
          color: theme.palette.primary.dark,
          fontWeight: 500,
        },

        "&.active": {
          // background: alpha(theme.palette.secondary.dark, 0.2),
          color: theme.palette.primary.main,
        },
      },
    },
  },
  brandWrapper: {
    display: "flex",
    alignItems: "center",
    height: 50,
    padding: "10px 14px",
    fontWeight: 600,
    marginBottom: theme.spacing(2),

    "& .logo": {
      height: 42,
      marginRight: 10,
      [theme.breakpoints.down("sm")]: {
        height: 21,
      },
    },
  },
}));
