export const SET_CONSTANT_FULFILLED = 'SET_CONSTANT_FULFILLED';
export const SET_CONSTANT = 'SET_CONSTANT';
export const SET_CONSTANT_ERROR = 'SET_CONSTANT_ERROR';

export function setConstantFulfilled(data: any) {
    return {
        type: SET_CONSTANT_FULFILLED,
        payload: data
    }
}

export function setConstant() {
    return {
        type: SET_CONSTANT,
    }
}

export function setConstantError(error: any) {
    return {
        type: SET_CONSTANT_ERROR,
        payload: error
    }
}
