import React, { useEffect } from "react";

const useIsMounted = () => {
  const isMounted = React.useRef(true);

  useEffect(() => {
    // console.log(isMounted)
    return () => {
      isMounted.current = false;
    };
  }, []);

  return isMounted;
};

export default useIsMounted;
