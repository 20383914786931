import * as yup from "yup";

export const attributeValidationSchema = () => {
  let shapes = {
    key: yup.string().label("field name"),
    value: yup.string().label("field value"),
  };
  return yup.object().shape(shapes);
};

export const validationSchema = () => {
  let shapes = {
    asset_category_id: yup.mixed().required().label("category"),
    number: yup.string().required().label("number"),
    description: yup.string().label("description"),
    name: yup.string().required().label("name"),
    acquisition_cost: yup.number().required().label("acquisition cost"),
    acquisition_date: yup.date().required().label("acquisition date"),
    attributes: yup
      .array()
      .of(attributeValidationSchema())
      .label("additional field"),
  };

  return yup.object().shape(shapes);
};
