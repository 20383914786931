import { api, createCancelTokenHandler } from "../@VodeaUI";

const endPoint = () => window._env_.REACT_APP_PASSPORT_END_POINT;

const Repository = {
  all: function (params: any = null) {
    return api.get(`${endPoint()}/api/transaction-policy`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.all.name].handleRequestCancellation()
          .token,
    });
  },
  show: function (id: number | string, params: any = null) {
    return api.get(`${endPoint()}/api/transaction-policy/${id}`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.show.name].handleRequestCancellation()
          .token,
    });
  },
  create: function (payload: any, params: any = null) {
    return api.post(`${endPoint()}/api/transaction-policy`, payload, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.create.name].handleRequestCancellation()
          .token,
    });
  },
  update: function (id: number | string, payload: any, params: any = null) {
    return api.put(`${endPoint()}/api/transaction-policy/${id}`, payload, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.update.name].handleRequestCancellation()
          .token,
    });
  },
  getCashAdvancePolicy: function (params: any = null) {
    return api.get(`${endPoint()}/api/cash-advance-policy`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[
          this.getCashAdvancePolicy.name
        ].handleRequestCancellation().token,
    });
  },
  getAttendancePolicy: function (params: any = null) {
    return api.get(`${endPoint()}/api/attendance-policy`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[
          this.getAttendancePolicy.name
        ].handleRequestCancellation().token,
    });
  },
  getReimbursementPolicy: function (params: any = null) {
    return api.get(`${endPoint()}/api/reimbursement-policy`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[
          this.getReimbursementPolicy.name
        ].handleRequestCancellation().token,
    });
  },
  getTimeOffPolicy: function (params: any = null) {
    return api.get(`${endPoint()}/api/time-off-policy`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[
          this.getTimeOffPolicy.name
        ].handleRequestCancellation().token,
    });
  },
  getTimeOffReimbursementPolicy: function (params: any = null) {
    return api.get(`${endPoint()}/api/time-off-reimburse-policy`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[
          this.getTimeOffReimbursementPolicy.name
        ].handleRequestCancellation().token,
    });
  },
  getTimeOffBalance: function (params: any = null) {
    return api.get(`${endPoint()}/api/time-off-balance`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[
          this.getTimeOffBalance.name
        ].handleRequestCancellation().token,
    });
  },
  getReimbursementBalance: function (params: any = null) {
    return api.get(`${endPoint()}/api/reimbursement-balance`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[
          this.getReimbursementBalance.name
        ].handleRequestCancellation().token,
    });
  },
};

const cancelTokenHandlerObject = createCancelTokenHandler(Repository);

export default Repository;
