import React from "react";
import { Navigate } from "react-router-dom";
import LayoutApps from "../layouts/Apps";
import Approval from "../pages/Apps/Approval";
import ApprovalDetail from "../pages/Apps/Approval/Detail";
import Payroll from "../pages/Apps/Payroll";
import Run from "../pages/Apps/Payroll/run";
import PersonalData from "../pages/Apps/People/PersonalInformation/General/PersonalData";
import EmploymentData from "../pages/Apps/People/PersonalInformation/General/EmploymentData";
import EmploymentDataDetail from "../pages/Apps/People/PersonalInformation/General/EmploymentData/Detail";
import FamilyData from "../pages/Apps/People/PersonalInformation/General/FamilyData";
import FamilyDataForm from "../pages/Apps/People/PersonalInformation/General/FamilyData/Form";
import TimeOff from "../pages/Apps/People/PersonalInformation/Time/TimeOff";
import Attendance from "../pages/Apps/People/PersonalInformation/Time/Attendance";
import Reimbursement from "../pages/Apps/People/PersonalInformation/Finance/Reimbursement";
import Employee from "../pages/Apps/People/Employee";
import EmployeeDetail from "../pages/Apps/People/Employee/EmployeeDetail/General/PersonalData";
import EmployeeAddForm from "../pages/Apps/People/Employee/AddForm";
import Directory from "../pages/Apps/People/Directory";
import Temporary from "../layouts/Temporary";
import Preference from "../pages/Apps/Preference";
import SchedulePayroll from "../pages/Apps/Preference/Payroll/Schedule/";
import ProratePayroll from "../pages/Apps/Preference/Payroll/Prorate";
import AbsencePayroll from "../pages/Apps/Preference/Payroll/Absence";
import Advance from "../pages/Apps/People/PersonalInformation/Finance/Advance";
import Settlement from "../pages/Apps/People/PersonalInformation/Finance/Settlement";
import GeneralInformationCompany from "../pages/Apps/Preference/company/General";
import DepartmentCompany from "../pages/Apps/Preference/company/Department";
import PositionCompany from "../pages/Apps/Preference/company/Position";
import BuildingCompany from "../pages/Apps/Preference/company/Building";
import PublicHoliday from "../pages/Apps/Preference/PublicHoliday";
import Dashboard from "../pages/Apps/Dashboard";
import Announcement from "../pages/Apps/Preference/Announcement";
import AddAnnouncement from "../pages/Apps/Preference/Announcement/form";
import Booking from "../pages/Apps/Booking";
import Resource from "../pages/Apps/Booking/Resource";
import RequestBooking from "../pages/Apps/Booking/RequestBooking";
import CreateBuilding from "../pages/Apps/Booking/Resource/CreateBuilding";
import CreateResource from "../pages/Apps/Booking/Resource/CreateResource";
import Assets from "../pages/Apps/Asset";
import AssetLog from "../pages/Apps/Asset/AssetLog";
import AssignAsset from "../pages/Apps/Asset/AssignAsset";
import AssetForm from "../pages/Apps/Asset/Form";
import AdvanceFinance from "../pages/Apps/Finance/Advance";
import RequestAdvance from "../pages/Apps/Finance/Advance/RequestAdvance";
import ReimbursementFinance from "../pages/Apps/Finance/Reimbursement";
import RequestReimbursement from "../pages/Apps/Finance/Reimbursement/RequestReimbursement";
import SettlementFinance from "../pages/Apps/Finance/Settlement";
import RequestSettlement from "../pages/Apps/Finance/Settlement/RequestSettlement";
import AttendanceTime from "../pages/Apps/Time/Attendance";
import TimeOffModule from "../pages/Apps/Time/TimeOff";
import ReimbursementTimeOff from "../pages/Apps/Time/ReimbursementTimeOff";
import RequestAttendance from "../pages/Apps/Time/Attendance/RequestAttendance";
import RequestTimeOff from "../pages/Apps/Time/TimeOff/RequestTimeOff";
import RequestReimbursementTimeOff from "../pages/Apps/Time/ReimbursementTimeOff/RequestReimbursementTimeOff";
import TimeOffPolicy from "../pages/Apps/Preference/Policy/TimeOff";
import AttendancePolicy from "../pages/Apps/Preference/Policy/Attendance";
import ReimbursementPolicy from "../pages/Apps/Preference/Policy/Reimbursement";
import AdvancePolicy from "../pages/Apps/Preference/Policy/Advance";
import DetailAsset from "../pages/Apps/Asset/Detail";
import Facility from "../pages/Apps/Facility";
import FacilityForm from "../pages/Apps/Facility/Form";
import DetailFacility from "../pages/Apps/Facility/Detail";
import AdvancePolicyForm from "../pages/Apps/Preference/Policy/Advance/form";
import AssignPolicy from "../pages/Apps/Preference/AssignPolicy";
import DepartmentForm from "../pages/Apps/Preference/company/Department/form";
import PositionForm from "../pages/Apps/Preference/company/Position/form";
import BuildingForm from "../pages/Apps/Preference/company/Building/form";
import TimeOffPolicyForm from "../pages/Apps/Preference/Policy/TimeOff/form";
import AttendancePolicyForm from "../pages/Apps/Preference/Policy/Attendance/Form";
import FinanceReimbursementForm from "../pages/Apps/Preference/Policy/Reimbursement/form";
import AttendanceDetail from "../pages/Apps/People/PersonalInformation/Time/Attendance/Detail";
import EmployeeEmploymentData from "../pages/Apps/People/Employee/EmployeeDetail/General/EmploymentData";
import RequestAttendanceDetail from "../pages/Apps/Time/Attendance/RequestAttendance/detail";
import TodoList from "../pages/Apps/TodoList";
import Employment from "../pages/Apps/People/Employee/Employment";
import { DetailRequestAdvance } from "../pages/Apps/Finance/Advance/Detail";
import ResourceForm from "../pages/Apps/Facility/Resource/Form";
import AssignPolicyForm from "../pages/Apps/Preference/AssignPolicy/Form";
import EmployeeAdvance from "../pages/Apps/People/Employee/EmployeeDetail/Finance/Advance";
import EmployeeSettlement from "../pages/Apps/People/Employee/EmployeeDetail/Finance/Settlement";
import EmployeeTimeOff from "../pages/Apps/People/Employee/EmployeeDetail/Time/TimeOff";
import EmployeeAttendance from "../pages/Apps/People/Employee/EmployeeDetail/Time/Attendance";
import EmployeeReimbursement from "../pages/Apps/People/Employee/EmployeeDetail/Finance/Reimbursement";
import PublicHolidayForm from "../pages/Apps/Preference/PublicHoliday/Form";

const routes = [
  {
    path: "temporary",
    element: <Temporary />,
  },
  {
    path: "apps",
    element: <LayoutApps />,
    children: [
      { path: "/", element: <Navigate to={"home"} /> },
      // { path: "home", element: <Dashboard />, title: "Home" },
      {
        path: "approval",
        children: [
          { path: "/", element: <Approval />, title: "Approval" },
          {
            path: "/detail/:id",
            element: <ApprovalDetail />,
            title: "Approval Detail",
          },
        ],
      },
      {
        path: "todo-list",
        children: [{ path: "/", element: <TodoList />, title: "Todo List" }],
      },
      { path: "home", element: <Dashboard />, title: "Home" },

      // { path: "payroll", element: <Payroll />, title: "payroll" },
      //TODO Hide Payroll
      {
        path: "payroll",
        children: [
          {
            path: "/",
            element: <Payroll />,
            title: "Payroll",
          },
          {
            path: "/run",
            element: <Run />,
            title: "Run Payroll",
          },
        ],
      },
      {
        path: "people",
        children: [
          {
            path: "/",
            element: <Navigate to={"personal-information"} />,
            title: "Personal Information",
          },
          {
            path: "/personal-information",
            element: <Navigate to={"personal-data"} />,
            title: "Personal Information",
          },
          {
            path: "/personal-information/personal-data",
            element: <PersonalData />,
            title: "Personal information",
          },
          {
            path: "/personal-information/employment-data",
            element: <EmploymentData />,
            title: "Personal information",
          },
          {
            path: "/personal-information/employment-data/detail/:id",
            element: <EmploymentDataDetail />,
            title: "Personal information",
          },
          {
            path: "/personal-information/family-data",
            element: <FamilyData />,
            title: "Personal information",
          },
          {
            path: "/personal-information/family-data/create",
            element: <FamilyDataForm />,
            title: "Personal information",
          },
          {
            path: "/personal-information/family-data/update/:id",
            element: <FamilyDataForm />,
            title: "Personal information",
          },
          {
            path: "/personal-information/time/time-off",
            element: <TimeOff />,
            title: "Time Off",
          },
          {
            path: "/personal-information/time/time-off/:id",
            element: <ApprovalDetail />,
            title: "Time Off",
          },
          {
            path: "/personal-information/time/time-off/request-time-off",
            element: <RequestTimeOff />,
            title: "Request Time Off",
          },
          {
            path: "/personal-information/time/attendance",
            element: <Attendance />,
            title: "Attendance",
          },
          {
            path: "/personal-information/time/attendance/:date",
            element: <AttendanceDetail />,
            title: "Attendance",
          },
          {
            path: "/personal-information/finance/reimbursement",
            element: <Reimbursement />,
            title: "Reimbursement",
          },
          {
            path: "/personal-information/finance/reimbursement/:id",
            element: <ApprovalDetail />,
            title: "Reimbursement",
          },
          {
            path: "/personal-information/finance/advance/",
            element: <Advance />,
            title: "Advance",
          },
          {
            path: "/personal-information/finance/advance/:id",
            element: <ApprovalDetail />,
            title: "Advance",
          },
          {
            path: "/personal-information/finance/settlement/",
            element: <Settlement />,
            title: "Settlement",
          },
          {
            path: "/personal-information/finance/settlement/:id",
            element: <ApprovalDetail />,
            title: "Settlement",
          },
          {
            path: "/employee",
            element: <Employee />,
            title: "Employee",
          },
          {
            path: "/employee/create",
            element: <EmployeeAddForm />,
            title: "Employee",
          },
          {
            path: "/employee/employee-detail/:id",
            element: <Navigate to={"general/personal-data"} />,
          },
          {
            path: "/employee/employment-data",
            element: <Employment />,
            title: "Update Employment Data",
          },
          {
            path: "/employee/employee-detail/:id/general/personal-data",
            element: <EmployeeDetail />,
            title: "Employee Personal Data",
          },
          {
            path: "/employee/employee-detail/:id/general/employment-data",
            element: <EmployeeEmploymentData />,
            title: "Employee Employment Data",
          },
          {
            path: "/employee/employee-detail/:id/time/time-off",
            element: <EmployeeTimeOff />,
            title: "Time Off",
          },
          {
            path: "/employee/employee-detail/:id/time/attendance",
            element: <EmployeeAttendance />,
            title: "Attendance",
          },
          {
            path: "/employee/employee-detail/:id/finance/reimbursement",
            element: <EmployeeReimbursement />,
            title: "Reimbursement",
          },
          {
            path: "/employee/employee-detail/:id/finance/advance",
            element: <EmployeeAdvance />,
            title: "Advance",
          },
          {
            path: "/employee/employee-detail/:id/finance/settlement",
            element: <EmployeeSettlement />,
            title: "Settlement",
          },
          {
            path: "/employee/employee-detail/:id/time/time-off/:id",
            element: <ApprovalDetail />,
            title: "Time Off",
          },
          {
            path: "/employee/employee-detail/:id/time/attendance/:date",
            element: <AttendanceDetail />,
            title: "Attendance",
          },
          {
            path: "/employee/employee-detail/:id/finance/reimbursement/:id",
            element: <ApprovalDetail />,
            title: "Reimbursement",
          },
          {
            path: "/employee/employee-detail/:id/finance/advance/:id",
            element: <ApprovalDetail />,
            title: "Advance",
          },
          {
            path: "/employee/employee-detail/:id/finance/settlement/:id",
            element: <ApprovalDetail />,
            title: "Settlement",
          },
          {
            path: "/directory",
            element: <Directory />,
            title: "directory",
          },
        ],
      },
      { path: "setting/", element: <Preference />, title: "Setting" },
      {
        path: "setting/company",
        element: <Navigate to={"setting/company/general-information"} />,
        title: "Setting Company",
      },
      {
        path: "setting/policy",
        element: <Navigate to={"setting/policy/time-off"} />,
        title: "Setting Policy",
      },
      {
        path: "setting/policy/time-off",
        children: [
          {
            path: "/",
            element: <TimeOffPolicy />,
            title: "Time Off",
          },
          {
            path: "/create",
            element: <TimeOffPolicyForm />,
            title: "Add Time Off",
          },
          {
            path: "/:id",
            element: <TimeOffPolicyForm />,
            title: "Edit Time Off",
          },
        ],
      },
      {
        path: "setting/policy/attendance",
        children: [
          {
            path: "/",
            element: <AttendancePolicy />,
            title: "Attendance Policy",
          },
          {
            path: "/create",
            element: <AttendancePolicyForm />,
            title: "Add Attendance Policy",
          },
          {
            path: "/:id",
            element: <AttendancePolicyForm />,
            title: "Edit Attendance Policy",
          },
        ],
      },
      {
        path: "setting/policy/reimbursement",
        children: [
          {
            path: "/",
            element: <ReimbursementPolicy />,
            title: "Reimbursement Policy",
          },
          {
            path: "/create",
            element: <FinanceReimbursementForm />,
            title: "Add Reimbursement Policy",
          },
          {
            path: "/:id",
            element: <FinanceReimbursementForm />,
            title: "Edit Reimbursement Policy",
          },
        ],
      },
      {
        path: "setting/policy/advance",
        children: [
          {
            path: "/",
            element: <AdvancePolicy />,
            title: "Advance Policy",
          },
          {
            path: "/create",
            element: <AdvancePolicyForm />,
            title: "Add Advance Policy",
          },
          {
            path: "/:id",
            element: <AdvancePolicyForm />,
            title: "Edit Advance Policy",
          },
        ],
      },
      // {
      //   path: "setting/policy/advance",
      //   element: <AdvancePolicy />,
      //   title: "Advance Policy",
      // },
      // {
      //   path: "setting/policy/advance/add",
      //   element: <AddAdvance />,
      //   title: "Advance Policy",
      // },

      //TODO : Assign Policy
      {
        path: "setting/assign-policy",
        children: [
          {
            path: "/",
            element: <AssignPolicy />,
            title: "Assign Policy",
          },
          {
            path: "/create",
            element: <AssignPolicyForm />,
            title: "Add Assign Policy",
          },
          {
            path: "/:id",
            element: <AssignPolicyForm />,
            title: "Edit Assign Policy",
          },
        ],
      },
      // {
      //   path: "setting/assign-policy/create",
      //   element: <AddAssignPolicy />,
      //   title: "Assign Policy",
      // },
      // {
      //   path: "setting/assign-policy/:id",
      //   element: <AddAssignPolicy />,
      //   title: "Assign Policy",
      // },

      //TODO : Assign Policy End Here

      // TODO : POLICY END HERE
      {
        path: "setting/company/general-information",
        element: <GeneralInformationCompany />,
        title: "General Information",
      },
      {
        path: "setting/company/department",
        children: [
          {
            path: "/",
            element: <DepartmentCompany />,
            title: "Department",
          },
          {
            path: "/create",
            element: <DepartmentForm />,
            title: "Add Department",
          },
          {
            path: "/:id",
            element: <DepartmentForm />,
            title: "Edit Department",
          },
        ],
      },
      {
        path: "setting/company/position",
        children: [
          {
            path: "/",
            element: <PositionCompany />,
            title: "Position",
          },
          {
            path: "/create",
            element: <PositionForm />,
            title: "Add Position",
          },
          {
            path: "/:id",
            element: <PositionForm />,
            title: "Edit Position",
          },
        ],
      },
      {
        path: "setting/company/building",
        children: [
          {
            path: "/",
            element: <BuildingCompany />,
            title: "Building",
          },
          {
            path: "/create",
            element: <BuildingForm />,
            title: "Add Building",
          },
          {
            path: "/:id",
            element: <BuildingForm />,
            title: "Edit Building",
          },
        ],
      },
      {
        path: "setting/public-holiday",
        children: [
          { path: "/", element: <PublicHoliday />, title: "Public Holiday" },
          {
            path: "/create",
            element: <PublicHolidayForm />,
            title: "Add Public Holiday",
          },
          {
            path: "/:id",
            element: <PublicHolidayForm />,
            title: "Edit Public Holiday",
          },
        ],
      },
      {
        path: "setting/announcement",
        element: <Announcement />,
        title: "Announcement",
      },
      {
        path: "setting/announcement/create",
        element: <AddAnnouncement />,
        title: "Add Announcement",
      },
      {
        path: "setting/payroll",
        element: <Navigate to={"/apps/setting/payroll/schedule"} />,
        title: "Setting Payroll",
      },
      {
        path: "setting/payroll/schedule",
        element: <SchedulePayroll />,
        title: "Payroll Schedule",
      },
      {
        path: "setting/payroll/prorate",
        element: <ProratePayroll />,
        title: "Payroll Prorate",
      },
      {
        path: "setting/payroll/absence",
        element: <AbsencePayroll />,
        title: "Payroll Absence",
      },
      {
        path: "booking",
        element: <Booking />,
        title: "Booking",
      },
      {
        path: "booking/resource",
        element: <Resource />,
        title: "Resource",
      },
      {
        path: "booking/resource/add-resource",
        element: <CreateResource />,
        title: "Add Resource",
      },
      {
        path: "booking/resource/add-Building",
        element: <CreateBuilding />,
        title: "Building",
      },
      {
        path: "booking/request-booking",
        element: <RequestBooking />,
        title: "Request Booking",
      },
      {
        path: "assets",
        // element: <Redirect to={"https://hris.nutrilab.co.id/assets"} />,
        element: <Assets />,
        title: "Assets",
      },
      {
        path: "assets/:id",
        element: <AssetForm />,
        title: "Assets",
      },
      {
        path: "assets/log",
        element: <AssetLog />,
        title: "Asset Log",
      },
      {
        path: "assets/assign",
        element: <AssignAsset />,
        title: "Assign Asset",
      },
      {
        path: "assets/create",
        element: <AssetForm />,
        title: "Add Asset",
      },
      {
        path: "assets/detail/:id",
        element: <DetailAsset />,
        title: "Detail Asset",
      },
      {
        path: "facility",
        element: <Facility />,
        title: "Facility",
      },
      {
        path: "facility/:id",
        element: <DetailFacility />,
        title: "Facility",
      },
      {
        path: "facility/booking/create",
        element: <FacilityForm />,
        title: "Facility",
      },
      {
        path: "facility/resource/create",
        element: <ResourceForm />,
        title: "Facility",
      },
      {
        path: "finance/advance",
        element: <AdvanceFinance />,
        title: "Advance",
      },
      // {
      //   path: "finance/advance/:id",
      //   element: <ApprovalDetail />,
      //   title: "Advance",
      // },
      {
        path: "finance/advance/:id",
        element: <DetailRequestAdvance />,
        title: "Advance",
      },
      {
        path: "finance/advance/advance-request",
        element: <RequestAdvance />,
        title: "Advance Request",
      },
      {
        path: "finance/advance/:id/edit",
        element: <RequestAdvance />,
        title: "Edit Advance Request",
      },
      {
        path: "finance/reimbursement",
        element: <ReimbursementFinance />,
        title: "Reimbursement",
      },
      {
        path: "finance/reimbursement/:id",
        element: <ApprovalDetail />,
        title: "Reimbursement",
      },
      {
        path: "finance/reimbursement/reimbursement-request",
        element: <RequestReimbursement />,
        title: "Reimbursement Request",
      },
      {
        path: "finance/settlement",
        element: <SettlementFinance />,
        title: "Settlement",
      },
      {
        path: "finance/settlement/:id",
        element: <ApprovalDetail />,
        title: "Settlement",
      },
      {
        path: "finance/settlement/settlement-request",
        element: <RequestSettlement />,
        title: "Settlement Request",
      },
      // {
      //   path: "time/attendance",
      //   element: <AttendanceTime/>,
      //   title: "Attendance",
      // },
      {
        path: "time",
        element: <Navigate to={"attendance"} />,
        title: "Time",
      },
      {
        path: "time/attendance",
        element: <AttendanceTime />,
        title: "Attendance",
      },
      {
        path: "time/attendance/:date",
        element: <AttendanceDetail />,
        title: "Attendance Detail",
      },
      {
        path: "time/attendance/:date/:id",
        element: <ApprovalDetail />,
        title: "Attendance Request Detail",
      },
      {
        path: "time/attendance/attendance-request",
        element: <RequestAttendance />,
        title: "Attendance Request",
      },
      {
        path: "time/attendance/attendance-request/:id",
        element: <RequestAttendanceDetail />,
        title: "Attendance Request Detail",
      },
      {
        path: "time/time-off/time-off-request",
        element: <RequestTimeOff />,
        title: "Time Off Request",
      },
      {
        path: "time/time-off",
        element: <TimeOffModule />,
        title: "Time Off",
      },
      {
        path: "time/time-off/:id",
        element: <ApprovalDetail />,
        title: "Time Off",
      },
      {
        path: "time/reimbursement-time-off",
        element: <ReimbursementTimeOff />,
        title: "Reimbursement Time Off",
      },
      {
        path: "time/reimbursement-time-off/reimbursement-time-off-request",
        element: <RequestReimbursementTimeOff />,
        title: "Reimbursement Time Off Request",
      },
    ],
  },
];

export default routes;
