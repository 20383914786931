import React from "react";
import { withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Steppers from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Box from "@material-ui/core/Box";
import StepConnector from "@material-ui/core/StepConnector";
import Typography from "@material-ui/core/Typography";
import { useStyles } from "./style";

const ColorlibConnector = withStyles((theme) => ({
  alternativeLabel: {
    top: 17,
  },
  active: {
    "& $line": {
      backgroundColor: "#EAEAEA",
    },
  },
  completed: {
    "& $line": {
      backgroundColor: "#EAEAEA",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#EAEAEA",
    borderRadius: 1,
  },
}))(StepConnector);

interface StepContentProps {
  active: boolean;
  error: boolean;
  icon?: any;
  completed: boolean;
  label: string;
  icons: any;
}

const StepContent: React.FC<StepContentProps> = ({
  active,
  error,
  completed,
  icon,
  label,
  icons,
}) => {
  const classes = useStyles();

  return (
    <Box flexDirection="row" className={classes.stepContentWrapper}>
      <Box
        className={clsx(classes.iconWrapper, {
          [classes.active]: active,
          [classes.error]: error,
          [classes.completed]: completed,
          [classes.inActive]: !active && !completed,
        })}
      >
        {icons[String(icon)]}
      </Box>
      <Box style={{ width: "100%" }}>
        <Typography align="center" className={classes.stepLabel}>
          {label}
        </Typography>
      </Box>
    </Box>
  );
};

interface StepperProps {
  steps: Array<any>;
  activeStep: number;
  icons: any;
}

const Stepper: React.FC<StepperProps> = ({ steps, activeStep, icons }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Steppers
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector />}
      >
        {steps.map((value: any) => (
          <Step key={value.label}>
            <StepLabel
              StepIconComponent={(props) => (
                <StepContent
                  {...props}
                  label={value.label}
                  icons={icons}
                  error={value.status}
                />
              )}
            />
          </Step>
        ))}
      </Steppers>
    </div>
  );
};

export default Stepper;
