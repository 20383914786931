import React from "react";
import {
  Box,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import { useState } from "@hookstate/core/dist";
import VuiSelect from "../../../../@VodeaUI/components/Input/VuiSelect/Index";
import { Controller, useForm } from "react-hook-form";
import VuiCardContent from "../../../../@VodeaUI/components/VuiCardContent";
import _ from "lodash";
import FooterFormAction from "../../../../components/FooterFormAction";
import { KeyboardDatePicker } from "@material-ui/pickers";

const RequestBooking: React.FC<any> = () => {
  const { control, errors, handleSubmit } = useForm({
    mode: "onChange",
  });

  const loading = useState(false);
  const resourceType = useState("1");

  const resourceTypeOptions = [
    {
      key: "1",
      label: "Meeting Room A",
    },
    {
      key: "2",
      label: "Meeting Room B",
    },
  ];

  const onSubmit = (formData: any) => {
    loading.set(true);

    console.log("formData", formData);
  };

  return (
    <>
      <Grid container spacing={3} justifyContent="space-between">
        <Grid item xs={12}>
          <Typography variant="h5">Request Booking</Typography>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item md={9} xs={12}>
          <VuiCardContent title="Request Booking">
            <Box p={2}>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <VuiSelect
                    label="Resource Name"
                    state={resourceType}
                    options={resourceTypeOptions}
                    fullWidth={true}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    name="building_id"
                    label="Booking Name"
                    variant="outlined"
                    fullWidth
                    error={_.has(errors, "building_id")}
                    helperText={_.get(errors, "building_id.message")}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    name="building_id"
                    label="Description"
                    variant="outlined"
                    fullWidth
                    error={_.has(errors, "building_id")}
                    helperText={_.get(errors, "building_id.message")}
                  />
                </Grid>
              </Grid>
            </Box>
          </VuiCardContent>
        </Grid>
        <Grid item md={9} xs={12}>
          <VuiCardContent title="Resource Availabilities">
            <Box p={2}>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <TextField
                    name="building_id"
                    label="Max Duration"
                    variant="outlined"
                    fullWidth
                    error={_.has(errors, "building_id")}
                    helperText={_.get(errors, "building_id.message")}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    name="building_id"
                    label="Max Request"
                    variant="outlined"
                    fullWidth
                    error={_.has(errors, "building_id")}
                    helperText={_.get(errors, "building_id.message")}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    name="building_id"
                    label="Capacity"
                    variant="outlined"
                    fullWidth
                    error={_.has(errors, "building_id")}
                    helperText={_.get(errors, "building_id.message")}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <TextField
                    name="building_id"
                    label="Day"
                    variant="outlined"
                    fullWidth
                    error={_.has(errors, "building_id")}
                    helperText={_.get(errors, "building_id.message")}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <TextField
                    name="building_id"
                    label="From"
                    variant="outlined"
                    fullWidth
                    error={_.has(errors, "building_id")}
                    helperText={_.get(errors, "building_id.message")}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <TextField
                    name="building_id"
                    label="To"
                    variant="outlined"
                    fullWidth
                    error={_.has(errors, "building_id")}
                    helperText={_.get(errors, "building_id.message")}
                  />
                </Grid>
              </Grid>
            </Box>
          </VuiCardContent>
        </Grid>
        <Grid item md={9} xs={12}>
          <VuiCardContent title="Date And Time">
            <Box p={2}>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <Controller
                    render={({ value, name, onChange }) => (
                      <KeyboardDatePicker
                        name={name}
                        disableToolbar
                        fullWidth
                        variant="inline"
                        minDate={new Date("2020-01-01")}
                        inputVariant="outlined"
                        label="Taxable Date"
                        format="DD/MM/YYYY"
                        error={_.has(errors, "taxable_date")}
                        helperText={_.get(errors, "taxable_date.message")}
                        value={value}
                        onChange={onChange}
                      />
                    )}
                    name="taxable_date"
                    control={control}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    label="From"
                    type="time"
                    defaultValue="07:30"
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 300, // 5 min
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    label="To"
                    type="time"
                    defaultValue="07:30"
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 300, // 5 min
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </VuiCardContent>
        </Grid>
        <Grid item md={9} xs={12}>
          <VuiCardContent title="Invite Participant">
            <Box p={2}>
              <Grid container spacing={3}>
                <Grid item md={11} xs={9}>
                  <VuiSelect
                    label="Participant"
                    state={resourceType}
                    options={resourceTypeOptions}
                    fullWidth={true}
                  />
                </Grid>
                <Grid item md={1} xs={3}>
                  <IconButton aria-label="add">
                    <AddIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
          </VuiCardContent>
        </Grid>
        <Grid item md={9} xs={12}>
          <FooterFormAction
            cancelUrl={null}
            loading={loading.get()}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default RequestBooking;
