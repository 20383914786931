import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

interface Props {
  title: string;
  component?: JSX.Element | null;
  subtitle?: string;
}

const useStyles = makeStyles({
  arrowBack: {
    marginRight: 10,
    color: "#383838",
    padding: "4px 5px 4px 3px",
    width: 28,
    height: 28,
  },
  titleWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  subtitle: {
    color: "#9d9d9d",
  },
});

const TitleHeader: React.FC<Props> = ({
  title,
  subtitle,
  component = null,
}) => {
  const classes = useStyles();

  return (
    <Box mb={3}>
      <Grid
        container
        spacing={2}
        alignItems="flex-end"
        justifyContent="space-between"
      >
        <Grid item className={classes.titleWrapper}>
          <Typography variant={"h5"}>{title}</Typography>
          <Typography variant={"body2"} className={classes.subtitle}>
            {subtitle}
          </Typography>
        </Grid>

        {component && <Grid item>{component}</Grid>}
      </Grid>
    </Box>
  );
};

export default React.memo(TitleHeader);
