import React, { useCallback, useMemo } from "react";
import { Box, Grid, IconButton, Paper, Tooltip } from "@material-ui/core";
import {
  $clone,
  $cloneState,
  mapDataTableParams,
} from "../../../../../utilities/helpers/global";
import { useState } from "@hookstate/core/dist";
import VuiDataTable, {
  Column,
  Options,
} from "../../../../../@VodeaUI/VuiDataTable/Index";
import { defaultDataTableOption } from "../../../../../utilities/Option";
import useIsMounted from "../../../../../@VodeaUI/hooks/useIsMounted";
import Policy from "../../../../../layouts/Apps/Preference/Policy";
import VuiCardContent from "../../../../../@VodeaUI/components/VuiCardContent";
import { AxiosError, AxiosResponse } from "axios";
import { Link } from "react-router-dom";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import { MuiAutoComplete, MuiSearch } from "../../../../../components/atoms";
import { sortedByOptions } from "../../../../../constants";
import { axiosErrorLoadDataHandler } from "../../../../../utilities/helpers/axios-error.helper";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import ReimbursementPolicyRepository from "../../../../../repositories/ReimbursementPolicyRepository";

const ReimbursementPolicy: React.FC<any> = () => {
  const isMounted = useIsMounted();
  const [tableData, setTableData] = React.useState<any[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const tableColumns: Column[] = [
    { key: "name", label: "POLICY NAME" },
    { key: "code", label: "POLICY CODE" },
    {
      key: "valid_from",
      label: "EFFECTIVE ON",
      type: "date",
      dateFormat: "DD MMMM YYYY",
    },
    {
      key: "id",
      label: "",
      sortable: false,
      render: (row) => {
        return (
          <Box display="flex" justifyContent={"flex-end"}>
            <Link to={`/apps/setting/policy/reimbursement/${row.id}`}>
              <Tooltip title="Detail">
                <IconButton aria-label="more">
                  <MoreHorizOutlinedIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Link>
          </Box>
        );
      },
    },
  ];

  const [loading, setLoading] = React.useState<boolean>(false);
  const options = useState<Options>({
    ...defaultDataTableOption,
    sortedBy: "desc",
    orderBy: "created_at",
  });

  const loadData = useCallback(async () => {
    if (isMounted.current) {
      setLoading(true);
    }

    await ReimbursementPolicyRepository.all({
      ...mapDataTableParams($cloneState(options)),
    })
      .then((response: AxiosResponse) => {
        const { data: responseData } = response;
        const { data, meta } = responseData;

        if (isMounted.current) {
          setTableData(data);
          options.page.set(meta.current_page - 1);
          options.total.set(meta.total);
          setLoading(false);
        }
      })
      .catch((error: AxiosError) => {
        if (isMounted.current) setLoading(false);
        axiosErrorLoadDataHandler(error, enqueueSnackbar);
      });
  }, []);

  useMemo(() => {
    (async () => {
      await loadData();
    })();
  }, []);

  const handleTableSearch = useCallback((value: string) => {
    if (isMounted.current) {
      options.search.set(value);
      options.page.set(0);
      loadData().then();
    }
  }, []);

  return (
    <Policy
      url="/apps/setting/policy/reimbursement/create"
      buttonLabel="Add Reimbursement Policy"
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <VuiCardContent title="Reimbursement Policy">
            <Paper variant="elevation" elevation={1}>
              <Box p={2}>
                <Grid container spacing={3} alignItems={"center"}>
                  <Grid item md={12} xs={12}>
                    <Grid
                      container
                      spacing={3}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                    >
                      <Grid item>
                        <MuiSearch
                          value={$clone(options.search.value)}
                          callback={handleTableSearch}
                        />
                      </Grid>
                      <Grid item md={3}>
                        <MuiAutoComplete
                          isAsync={false}
                          value={sortedByOptions.find(
                            (option) =>
                              option.id === $clone(options.sortedBy.value)
                          )}
                          constantOptions={sortedByOptions}
                          onSelected={(newValue) => {
                            options.sortedBy.set(newValue.id);
                            loadData().then();
                          }}
                          muiTextField={{
                            label: t("form.sort.label"),
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>

              <VuiDataTable
                loading={loading}
                columns={tableColumns}
                options={$clone(options.value)}
                data={tableData}
                onChangeOptions={(values: Options) => {
                  options.set(values);
                  loadData();
                }}
              />
            </Paper>
          </VuiCardContent>
        </Grid>
      </Grid>
    </Policy>
  );
};

export default ReimbursementPolicy;
