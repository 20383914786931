import React, { useCallback, useMemo, useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  Paper,
} from "@material-ui/core";
import { DeleteOutline as DeleteOutlineIcon } from "@material-ui/icons";
import { useIsMounted } from "../../../utilities/helpers/hooks";
import { TitleHeader } from "../../../components/molecules";
import AccountTaskRepository from "../../../repositories/account/AccountTaskRepository";
import { AxiosError, AxiosResponse } from "axios";
import { IApiResource } from "../../../interfaces/ApiResource";
import { ITodoList } from "../../../interfaces/TodoList";
import {
  axiosErrorLoadDataHandler,
  handleAxiosErrorSave,
  isAxiosCanceled,
} from "../../../utilities/helpers/axios-error.helper";
import {
  MuiButton,
  MuiCheckbox,
  MuiLoader,
  MuiTextField,
  MuiTypography,
} from "../../../components/atoms";
import { makeStyles } from "@material-ui/core/styles";
import { axiosSuccessUpdateDataHandler } from "../../../utilities/helpers/axios-success.helper";
import { useSnackbar } from "notistack";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationSchema } from "./validation";
import _ from "lodash";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  listItem: {
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
  },
  title: {
    textTransform: "uppercase",
    fontWeight: "bold",
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 16,
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
  },
  form: {
    paddingTop: 16,
    paddingBottom: 16,
    marginLeft: 16,
    marginRight: 16,
    marginBottom: 16,
    display: "flex",
    gap: 16,
  },
  formText: {
    flex: 0.88,
  },
  formButton: {
    flex: 0.12,
  },
});

const defaultValues: ITodoList = {
  task: "",
  is_done: false,
};

const TodoList: React.FC<any> = () => {
  const classes = useStyles();
  const isMounted = useIsMounted();
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState<ITodoList[]>([]);

  const { handleSubmit, control, setError, errors } = useForm<ITodoList>({
    mode: "onChange",
    defaultValues: defaultValues,
    resolver: yupResolver(validationSchema()),
  });

  const updateTaskData = async (task: ITodoList, value: boolean) => {
    const findTaskIndex = data.findIndex((todo) => todo.id === task.id);
    if (findTaskIndex === -1) {
      return;
    }

    await AccountTaskRepository.update(task.id || 0, {
      task: task.task,
      is_done: value,
    })
      .then((response: AxiosResponse) => {
        axiosSuccessUpdateDataHandler(response, enqueueSnackbar);
        if (isMounted.current) {
          setData((prevState) => {
            const cloneState = [...prevState];
            cloneState[findTaskIndex].is_done = value;
            return cloneState;
          });
        }
      })
      .catch((error: AxiosError) => {
        axiosErrorLoadDataHandler(error, enqueueSnackbar);
      });
  };

  const deleteTask = async (taskId: number) => {
    const findTaskIndex = data.findIndex((todo) => todo.id === taskId);
    if (findTaskIndex === -1) {
      return;
    }

    await AccountTaskRepository.delete(taskId)
      .then((response: AxiosResponse) => {
        axiosSuccessUpdateDataHandler(response, enqueueSnackbar);
        if (isMounted.current) {
          setData((prevState) => {
            const cloneState = [...prevState];
            cloneState.splice(findTaskIndex, 1);
            return cloneState;
          });
        }
      })
      .catch((error: AxiosError) => {
        axiosErrorLoadDataHandler(error, enqueueSnackbar);
      });
  };

  const loadData = useCallback(async () => {
    if (isMounted.current) setLoading(true);
    await AccountTaskRepository.all()
      .then((response: AxiosResponse<IApiResource<ITodoList[]>>) => {
        if (isMounted.current) {
          const { data: responseData } = response.data;
          setData(responseData);
          setLoading(false);
        }
      })
      .catch((error: AxiosError) => {
        if (isMounted.current && !isAxiosCanceled(error)) {
          axiosErrorLoadDataHandler(error, enqueueSnackbar);
          setLoading(false);
        }
      });
  }, []);

  const MuiListItem = (data: ITodoList) => {
    return (
      <ListItem className={classes.listItem}>
        <MuiCheckbox
          checked={data.is_done}
          label={data.task}
          styles={data.is_done ? { textDecoration: "line-through" } : {}}
          onChange={(value) => updateTaskData(data, value)}
        />
        <ListItemSecondaryAction>
          <IconButton edge="end" onClick={() => deleteTask(data.id || 0)}>
            <DeleteOutlineIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    );
  };

  const renderUncompletedData = useMemo(() => {
    const unCompletedData = data.filter((todo) => !todo.is_done);
    return unCompletedData.length ? (
      unCompletedData.map((todo) => {
        return <MuiListItem {...todo} />;
      })
    ) : (
      <Box p={2} className={classes.listItem}>
        No matching records found
      </Box>
    );
  }, [data, loading]);

  const renderCompletedData = useMemo(() => {
    const completedData = data.filter((todo) => todo.is_done);
    return completedData.length ? (
      completedData.map((todo) => {
        return <MuiListItem {...todo} />;
      })
    ) : (
      <Box p={2} className={classes.listItem}>
        No matching records found
      </Box>
    );
  }, [data, loading]);

  useMemo(() => {
    (async () => {
      await loadData();
    })();
  }, []);

  const onSubmit = handleSubmit(async (data) => {
    if (isMounted.current) setSubmitLoading(true);
    await AccountTaskRepository.create(data)
      .then((response: AxiosResponse) => {
        axiosSuccessUpdateDataHandler(response, enqueueSnackbar);
        if (isMounted.current) {
          setSubmitLoading(false);
          loadData().then();
        }
      })
      .catch((error: AxiosError) => {
        handleAxiosErrorSave(error, setError, enqueueSnackbar);
        if (isMounted.current) setSubmitLoading(false);
      });
  });

  return (
    <>
      <TitleHeader title={"To Do List"} />

      <Paper>
        <Box className={classes.form}>
          <Box className={classes.formText}>
            <Controller
              name={"task"}
              control={control}
              render={({ onChange, name, value }) => {
                return (
                  <MuiTextField
                    value={value}
                    name={name}
                    label={t("form.writeYourToDoListHere.label")}
                    onChange={onChange}
                    error={_.has(errors, name)}
                    helperText={_.get(errors, `${name}.message`)}
                  />
                );
              }}
            />
          </Box>
          <Box className={classes.formButton}>
            <MuiButton
              label={"Submit"}
              onClick={onSubmit}
              loading={submitLoading}
              disabled={submitLoading}
              styles={{
                width: "100%",
                height: "100%",
              }}
            />
          </Box>
        </Box>
        <Grid container spacing={3}>
          <Grid item md={12} xs={12}>
            <MuiTypography className={classes.title}>List</MuiTypography>
            {loading ? (
              <MuiLoader styles={{ height: 60 }} />
            ) : (
              <List>{renderUncompletedData}</List>
            )}
          </Grid>
          <Grid item md={12} xs={12}>
            <MuiTypography className={classes.title}>Completed</MuiTypography>
            {loading ? (
              <MuiLoader styles={{ height: 60 }} />
            ) : (
              <List>{renderCompletedData}</List>
            )}
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default TodoList;
