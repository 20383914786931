import React, { useEffect } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { useState } from "@hookstate/core/dist";
import { Controller, useForm } from "react-hook-form";
import _ from "lodash";
import FooterFormAction from "../../../../../components/FooterFormAction";
import TitleForm from "../../../../../components/molecules/TitleForm";
import { calcBusinessDays } from "../../../../../utilities/helpers/global";
import { AxiosError } from "axios";
import { useIsMounted } from "../../../../../utilities/helpers/hooks";
import TimeOffRepository from "../../../../../repositories/TimeOffRepository";
import { formatFormData } from "../../../../../utilities/helpers/form";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import VuiCardContent from "../../../../../@VodeaUI/components/VuiCardContent";
import { handleAxiosErrorSave } from "../../../../../utilities/helpers/axios-error.helper";
import { useSelector } from "react-redux";
import UserRepository from "../../../../../repositories/UserRepository";
import {
  MuiAutoComplete,
  MuiDatePicker,
  MuiTextField,
} from "../../../../../components/atoms";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  subtitle: {
    marginTop: 10,
    fontSize: 12,
    marginLeft: 1,
    color: "#383838",
  },
});

const dayOptions = [
  {
    id: "FULL_DAY",
    name: "Full Day",
  },
  {
    id: "HALF_DAY",
    name: "Half Day",
  },
];

const RequestTimeOff: React.FC<any> = () => {
  const navigate = useNavigate();
  const isMounted = useIsMounted();
  const location = useLocation();
  const loading = useState<boolean>(false);
  const balance = useState<number>(0);
  const { id } = useParams();
  const { t } = useTranslation();
  const classes = useStyles();
  const [hasBalance, setHasBalance] = React.useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useSelector(({ vodea }: any) => {
    return {
      user: vodea.auth.user,
    };
  });

  const {
    control,
    errors,
    setValue,
    watch,
    handleSubmit,
    getValues,
    setError,
  } = useForm<any>({
    mode: "onChange",
  });

  const watchTimeOffPolicy = watch("time_off_policy_id");
  const watchUser = watch("user_id");
  const watchStartDate = watch("start");
  const watchFinishDate = watch("finish");
  const watchIsHalfDayStart = watch("is_half_day_start");
  const watchIsHalfDayFinish = watch("is_half_day_finish");

  useEffect(() => {
    if (user) {
      setValue("user_id", user);
    }
  }, [user]);

  const loadData = async () => {
    TimeOffRepository.show(id, {
      with: ["approvalLogs.approver", "approvalLogs.role"],
    })
      .then((resp: any) => {
        const data = resp.data.data;
        _.forEach(data, (value, name) => {
          if (name === "time_off_policy_id") {
            setValue(name, data.time_off_policy);
          } else {
            setValue(name, value);
          }
        });
      })
      .catch((err: any) => {});
  };

  useEffect(() => {
    if (id) {
      loadData();
    }
  }, [id]);

  const onSubmit = async (data: any) => {
    if (isMounted.current) {
      loading.set(true);
    }
    const formData = formatFormData(data);

    Object.keys(formData).forEach((key) => {
      if (key === "is_half_day_start" || key === "is_half_day_finish") {
        formData[key] = formData[key].id === "HALF_DAY";
      }
    });

    if (formData.time_off_policy_id === "SPECIAL") {
      if (formData?.time_off_policy_special_category?.code == "CM") {
        formData.finish = moment(formData?.start)
            .add(formData?.time_off_policy_special_category?.amount_time_off || 3, "days")
            .format("YYYY-MM-DD");
      } else {
        formData.finish = moment(formData?.start)
            .add(3, "days")
            .format("YYYY-MM-DD");
      }

      formData.time_off_policy_id =
        formData?.time_off_policy_special_category?.id;
    }

    delete formData.time_off_policy_special_category;

    if (formData.start !== formData.finish) {
      formData.is_half_day_finish = false;
    }

    await TimeOffRepository.create(formData)
      .then((resp: any) => {
        if (isMounted.current) {
          enqueueSnackbar(resp.data.message, {
            variant: "success",
          });
          loading.set(false);
          navigate(
            location?.pathname ===
              "/apps/people/personal-information/time/time-off/request-time-off"
              ? "/apps/people/personal-information/time/time-off"
              : "/apps/time/time-off"
          );
        }
      })
      .catch((error: AxiosError) => {
        if (isMounted.current) {
          loading.set(false);
          handleAxiosErrorSave(error, setError, enqueueSnackbar);
        }
      });
  };

  const getBalance = async () => {
    setHasBalance(false);
    const timeOffPolicyId = getValues("time_off_policy_id");
    await TimeOffRepository.getTimeOffBalance({
      for: "transaction-policy",
      user: watchUser?.id,
      "time-off-policy": timeOffPolicyId?.id,
    }).then((resp: any) => {
      if (isMounted && resp.data.data.length > 0) {
        setHasBalance(true);
        balance.set(resp.data?.data[0]?.balance);
      }
    });
  };

  return (
    <>
      <TitleForm title="Request Time Off" withBackUrl={true} />
      <Grid container spacing={3}>
        <Grid item md={8} xs={12}>
          <VuiCardContent title="Information">
            <Box p={2} mb={3}>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <Controller
                    render={({ value, name, onChange }) => {
                      return (
                        <MuiAutoComplete
                          repository={UserRepository}
                          params={{
                            for: "time-off",
                          }}
                          value={value}
                          onSelected={(newValue) => {
                            onChange(newValue);
                            setValue("time_off_policy_id", null);
                          }}
                          muiTextField={{
                            label: t("form.employee.label"),
                            error: _.has(errors, name),
                            helperText: _.get(errors, `${name}.message`),
                          }}
                        />
                      );
                    }}
                    name={`user_id`}
                    control={control}
                    defaultValue={null}
                  />
                </Grid>

                <Grid item md={12} xs={12}>
                  <Controller
                    render={({ value, name, onChange }) => {
                      return (
                        <MuiAutoComplete
                          repository={TimeOffRepository}
                          params={{
                            for: "time-off",
                            special: 0,
                            user: watchUser?.id,
                          }}
                          additionalOptions={[
                            {
                              name: "Cuti Special",
                              id: "SPECIAL",
                            },
                          ]}
                          methodName="getTimeOffPolicy"
                          value={value}
                          onSelected={(newValue) => {
                            onChange(newValue);
                            if (newValue === null) {
                              setHasBalance(false);
                            } else {
                              if (newValue.id !== "SPECIAL") {
                                getBalance();
                              } else {
                                setHasBalance(false);
                              }
                            }
                          }}
                          muiTextField={{
                            label: t("Time Off Category"),
                            error: _.has(errors, name),
                            helperText: _.get(errors, `${name}.message`),
                          }}
                        />
                      );
                    }}
                    name={`time_off_policy_id`}
                    control={control}
                    defaultValue={null}
                  />
                  {hasBalance && (
                    <Typography
                      variant={"subtitle2"}
                      className={classes.subtitle}
                    >
                      Available Balance {balance.get()} Day(s)
                    </Typography>
                  )}
                </Grid>

                {watchTimeOffPolicy?.id === "SPECIAL" && (
                  <Grid item md={12} xs={12}>
                    <Controller
                      render={({ value, name, onChange }) => {
                        return (
                          <MuiAutoComplete
                            repository={TimeOffRepository}
                            params={{
                              for: "time-off",
                              special: 1,
                              user: watchUser?.id,
                            }}
                            methodName="getTimeOffPolicy"
                            value={value}
                            onSelected={(newValue) => {
                              onChange(newValue);
                            }}
                            muiTextField={{
                              label: t("Time Off Special Category"),
                              error: _.has(errors, name),
                              helperText: _.get(errors, `${name}.message`),
                            }}
                          />
                        );
                      }}
                      name={`time_off_policy_special_category`}
                      control={control}
                      defaultValue={null}
                    />
                  </Grid>
                )}

                <Grid item md={6} xs={12}>
                  <Controller
                    render={({ value, name, onChange }) => (
                      <MuiDatePicker
                        value={value}
                        name={name}
                        label={t("form.from.label")}
                        format="DD MMMM YYYY"
                        onChange={onChange}
                        error={_.has(errors, name)}
                        helperText={_.get(errors, `${name}.message`)}
                      />
                    )}
                    name="start"
                    control={control}
                    defaultValue={moment()}
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <Controller
                    render={({ value, name, onChange }) => {
                      return (
                        <MuiAutoComplete
                          isAsync={false}
                          constantOptions={dayOptions}
                          value={value}
                          onSelected={(newValue) => {
                            onChange(newValue);
                          }}
                          muiTextField={{
                            error: _.has(errors, name),
                            helperText: _.get(errors, `${name}.message`),
                          }}
                        />
                      );
                    }}
                    name={`is_half_day_start`}
                    control={control}
                    defaultValue={dayOptions[0]}
                  />
                </Grid>

                {watchTimeOffPolicy?.id !== "SPECIAL" && (
                  <>
                    <Grid item md={6} xs={12}>
                      <Controller
                        render={({ value, name, onChange }) => (
                          <MuiDatePicker
                            value={value}
                            name={name}
                            label={t("form.to.label")}
                            format="DD MMMM YYYY"
                            onChange={onChange}
                            error={_.has(errors, name)}
                            helperText={_.get(errors, `${name}.message`)}
                          />
                        )}
                        name="finish"
                        control={control}
                        defaultValue={moment()}
                      />
                    </Grid>

                    {moment(watchStartDate).format("YYYY-MM-DD") !==
                      moment(watchFinishDate).format("YYYY-MM-DD") && (
                      <Grid item md={6} xs={12}>
                        <Controller
                          render={({ value, name, onChange }) => {
                            return (
                              <MuiAutoComplete
                                isAsync={false}
                                constantOptions={dayOptions}
                                value={value}
                                onSelected={(newValue) => {
                                  onChange(newValue);
                                }}
                                muiTextField={{
                                  error: _.has(errors, name),
                                  helperText: _.get(errors, `${name}.message`),
                                }}
                              />
                            );
                          }}
                          name={`is_half_day_finish`}
                          control={control}
                          defaultValue={dayOptions[0]}
                        />
                      </Grid>
                    )}
                  </>
                )}

                <Grid item md={12} xs={12}>
                  <Controller
                    render={({ value, name, onChange }) => (
                      <MuiTextField
                        name={name}
                        onChange={onChange}
                        label={t("form.reason.label")}
                        value={value}
                        error={_.has(errors, name)}
                        helperText={_.get(errors, `${name}.message`)}
                      />
                    )}
                    name="description"
                    control={control}
                  />
                </Grid>

                <Grid item md={12} xs={12}>
                  <Controller
                    name={`contact`}
                    control={control}
                    render={({ value, name, onChange }) => {
                      return (
                        <MuiTextField
                          onChange={onChange}
                          value={value}
                          error={_.has(errors, name)}
                          helperText={_.get(errors, `${name}.message`)}
                          label={t("form.phoneNumber.label")}
                        />
                      );
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </VuiCardContent>
        </Grid>

        <Grid item md={4} xs={12}>
          <VuiCardContent title="Summary">
            <Box p={2}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="caption">Request Date</Typography>
                  <Typography variant="subtitle2">
                    {moment().format("DD MMMM YYYY")}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="caption">Employee</Typography>
                  <Typography variant="subtitle2">
                    {watchUser?.name || "-"}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="caption">Time Off Policy</Typography>
                  <Typography variant="subtitle2">
                    {watchTimeOffPolicy?.name || "-"}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="caption">Request For</Typography>
                  <Typography variant="subtitle2">
                    {calcBusinessDays(
                      watchStartDate,
                      watchFinishDate,
                      watchIsHalfDayStart?.id === "HALF_DAY",
                      watchIsHalfDayFinish?.id === "HALF_DAY"
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </VuiCardContent>
        </Grid>

        <FooterFormAction
          cancelUrl={"/apps/time/time-off"}
          loading={loading.get()}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          labelForm={"Submit Request Time Off"}
        />
      </Grid>
    </>
  );
};

export default RequestTimeOff;
