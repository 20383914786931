import { useState } from "@hookstate/core/dist";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@material-ui/core";
import { Add, FormatListBulletedOutlined } from "@material-ui/icons";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { VuiSearch } from "../../../@VodeaUI";
import VuiSelect from "../../../@VodeaUI/components/Input/VuiSelect/Index";
import DataTable from "../../../components/DataTable";
import { useStyles } from "../../../components/Status/style";
import PayrollRepository from "../../../repositories/PayrollRepository";
import { $clone } from "../../../utilities/helpers/global";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import VuiDataTable, {
  Column,
  Options,
} from "../../../@VodeaUI/VuiDataTable/Index";
import { defaultDataTableOption } from "../../../utilities/Option";

const data = [
  {
    category: "Overtime",
    month: "28 December 2020",
    name: "Yonkers",
    status: "Approved",
    start_date: "28 October 2000",
    numofdays: "2 Days",
    amount: "2,000,000",
  },
  {
    category: "Overtime",
    month: "28 December 2020",
    name: "Hartford",
    status: "Waiting",
    start_date: "28 October 2000",
    numofdays: "2 Days",
    amount: "1,000,000",
  },
];

const Payroll: React.FC<any> = () => {
  const classes = useStyles();
  const search = useState<string>("");
  const loading = useState(false);

  const orderBy = useState("all");
  const orderByOptions = [
    {
      key: "all",
      label: "All",
    },
    {
      key: "a-z",
      label: "A-Z",
    },
  ];
  const month = useState("all");
  const monthOptions = [
    {
      key: "all",
      label: "All",
    },
    {
      key: "1",
      label: "January",
    },
    {
      key: "2",
      label: "February",
    },
    {
      key: "3",
      label: "March",
    },
    {
      key: "4",
      label: "April",
    },
    {
      key: "5",
      label: "Mei",
    },
  ];

  useEffect(() => {
    PayrollRepository.all().then((resp: any) => {});
  }, []);

  const options = useState<Options>({
    ...defaultDataTableOption,
    sortedBy: "desc",
    orderBy: "date",
  });

  const columns = useState<Column[]>([
    { key: "month", label: "MONTH" },
    {
      key: "status",
      label: "STATUS",
      sortable: false,
      render: (row) => {
        return (
          <div className={classes.statusWrapper}>
            <div className={row.status}>{row.status}</div>
          </div>
        );
      },
    },
    { key: "amount", label: "AMOUNT (IDR)" },
    {
      key: "id",
      label: "",
      sortable: false,
      render: (row) => {
        return (
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <Button className="btnView" variant="contained" color="primary">
              View
            </Button>
          </Box>
        );
      },
    },
    {
      key: "more",
      label: "",
      sortable: false,
      render: (row) => {
        return (
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <IconButton>
              <MoreHorizOutlinedIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ]);

  useEffect(() => {
    // loadData().catch(() => {});
  });

  const loadData = async () => {
    loading.set(true);

    let params: any = {};

    //   await PayrollRepository.all({
    //     ...params,
    //   })
    //       .then((response: AxiosResponse) => {
    //         const { data: responseData } = response;
    //         const { data: list } = responseData;

    //         data.set(list);
    //         loading.set(false);
    //       })
    //       .catch(() => {});
    // };
  };

  return (
    <>
      <Grid container spacing={3} justifyContent="space-between">
        <Grid item>
          <Typography variant="h5">Payroll</Typography>
        </Grid>
        <Grid item>
          <Link to={"/apps/payroll/run"} className="link">
            <Button variant="contained" color="primary">
              Run Payroll
            </Button>
          </Link>
        </Grid>
      </Grid>

      <Box mt={2}>
        <Paper>
          <Box p={2}>
            <Grid container spacing={3} alignItems={"center"}>
              <Grid item md={12} xs={12}>
                <Grid
                  container
                  spacing={3}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Grid item>
                    <VuiSearch state={$clone(search.get())} />
                  </Grid>
                  <Grid item>
                    <VuiSelect
                      label="Urutkan"
                      state={orderBy}
                      options={orderByOptions}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <VuiSelect label="Year" state={month} options={monthOptions} />
              </Grid>
            </Grid>
          </Box>

          <VuiDataTable
            loading={loading.get()}
            columns={columns.value}
            options={$clone(options.value)}
            data={data}
            onChangeOptions={(values: Options) => {
              options.set(values);
              loadData();
            }}
          />
        </Paper>
      </Box>
    </>
  );
};

export default Payroll;
