import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import ArrowBackIosOutlinedIcon from "@material-ui/icons/ArrowBackIosOutlined";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { MuiIconButtonRounded } from "../../atoms";

interface Props {
  title: string;
  withBackUrl: boolean | false;
  component?: JSX.Element | null;
}

const useStyles = makeStyles({
  arrowBack: {
    marginRight: 10,
    color: "#383838",
    padding: "4px 5px 4px 3px",
    width: 28,
    height: 28,
  },
  titleWrapper: {
    display: "flex",
  },
});

const TitleForm: React.FC<Props> = ({
  title,
  withBackUrl,
  component = null,
}) => {
  const navigate = useNavigate();
  const classes = useStyles();

  return (
    <Box mb={3}>
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item className={classes.titleWrapper}>
          {withBackUrl && (
            <MuiIconButtonRounded
              className={classes.arrowBack}
              onClick={() => navigate(-1)}
            >
              <ArrowBackIosOutlinedIcon />
            </MuiIconButtonRounded>
          )}
          <Typography variant={"h5"}>{title}</Typography>
        </Grid>

        {component && <Grid item>{component}</Grid>}
      </Grid>
    </Box>
  );
};

export default React.memo(TitleForm);
