import { api } from "../@VodeaUI";

let url = window._env_.REACT_APP_PASSPORT_END_POINT;

const AttendanceRepository = {
  all: function (params: any = null) {
    return api.get(`${url}/api/attendance-request`, {
      params,
    });
  },
  show: function (id: string | number, params: any = null) {
    return api.get(`${url}/api/attendance-request/${id}`, {
      params,
    });
  },
  create: function (payload: any, params: any = null) {
    return api.post(`${url}/api/attendance-request`, payload, {
      params,
    });
  },
  approve: function (id: number | string, params: any = null) {
    return api.put(`${url}/api/attendance-request/${id}/approve`, {
      params,
    });
  },
};

export default AttendanceRepository;
