import React from "react";
import _ from "lodash";
import FingerprintIcon from "@material-ui/icons/Fingerprint";
import PlaceIcon from "@material-ui/icons/Place";
import DescriptionIcon from "@material-ui/icons/Description";
import { makeStyles } from "@material-ui/core/styles";
import { SvgIcon } from "@material-ui/core";

const useStyles = makeStyles({
  icon: {
    marginTop: "1rem",
  },
});

export const getUsingImage = (item: any) => {
  const classes = useStyles();
  let arr = _.get(item, "attendance_from", "").split(", ");
  return arr.map((data: any, index: number) => {
    if (data === "ABSENCE_MACHINE") {
      return (
        <SvgIcon key={index} className={classes.icon}>
          <path
            id="icons8-fingerprint"
            d="M12.839,4A10.917,10.917,0,0,0,8.25,5.01a.648.648,0,1,0,.543,1.176,9.6,9.6,0,0,1,8.09.007.648.648,0,0,0,.546-1.175A10.881,10.881,0,0,0,12.839,4ZM6.355,6.284a.648.648,0,0,0-.4.149A11.136,11.136,0,0,0,4.123,8.3a.648.648,0,1,0,1.025.791A9.844,9.844,0,0,1,6.769,7.441a.648.648,0,0,0-.414-1.158Zm6.887.328a.648.648,0,0,0-.007,1.294,7.113,7.113,0,0,1,6.709,6.707.648.648,0,1,0,1.294-.071,8.42,8.42,0,0,0-7.932-7.929Q13.274,6.611,13.242,6.612Zm-2.57.288a.648.648,0,0,0-.172.029A8.431,8.431,0,0,0,4.42,15.01a15.4,15.4,0,0,0,.12,1.805.648.648,0,0,0,1.286-.157,14.188,14.188,0,0,1-.11-1.649,7.13,7.13,0,0,1,5.146-6.838A.648.648,0,0,0,10.672,6.9Zm2.167,2.282A5.843,5.843,0,0,0,7.01,15.01,14.555,14.555,0,0,0,7.8,19.539a.648.648,0,1,0,1.224-.423,13.462,13.462,0,0,1-.72-4.106,4.528,4.528,0,0,1,8.209-2.643.648.648,0,1,0,1.05-.759A5.831,5.831,0,0,0,12.839,9.181Zm0,2.59A3.249,3.249,0,0,0,9.6,15.009a11.146,11.146,0,0,0,1.809,5.983.648.648,0,1,0,1.063-.74A10.361,10.361,0,0,1,10.9,15.009a1.943,1.943,0,0,1,3.886,0v.009A6.962,6.962,0,0,0,15,16.528a3.921,3.921,0,0,0,.937,1.858h0l.976.97a.648.648,0,1,0,.913-.919l-.974-.968a3.039,3.039,0,0,1-.6-1.26,6.229,6.229,0,0,1-.18-1.213V15A3.25,3.25,0,0,0,12.839,11.771Zm5.171,2.15a.648.648,0,0,0-.638.657V15a2.851,2.851,0,0,0,1.06,2.246L18.4,17.22l.484.432a.648.648,0,1,0,.862-.966l-.5-.446-.017-.013A1.462,1.462,0,0,1,18.668,15v-.422a.648.648,0,0,0-.658-.657Zm-5.181.432a.648.648,0,0,0-.638.657,7.92,7.92,0,0,0,1.917,5.207l.432.432a.648.648,0,1,0,.916-.916l-.432-.432c-1.46-1.46-1.537-4.291-1.537-4.291a.648.648,0,0,0-.658-.657Z"
            transform="translate(-3.979 -4)"
          />
        </SvgIcon>
      );
    } else if (data === "LIVE_ATTENDANCE") {
      return (
        <SvgIcon key={index} className={classes.icon}>
          <path
            id="icons8-marker"
            d="M14.342,4A7.337,7.337,0,0,0,8.761,16.106l0,0,0,0S11.89,19.676,13,20.738a1.95,1.95,0,0,0,2.678,0c1.267-1.209,4.237-4.627,4.237-4.627l0,0,0,0A7.336,7.336,0,0,0,14.342,4Zm0,1.3a6.04,6.04,0,0,1,4.595,9.968c-.006.007-3.044,3.482-4.15,4.536a.631.631,0,0,1-.89,0c-.924-.882-4.141-4.526-4.15-4.536h0a6.041,6.041,0,0,1,4.6-9.968Zm0,3.455a2.572,2.572,0,0,0-1.942.825,2.726,2.726,0,0,0,0,3.533,2.7,2.7,0,0,0,3.883,0,2.726,2.726,0,0,0,0-3.533A2.572,2.572,0,0,0,14.342,8.751Zm0,1.3a1.194,1.194,0,0,1,.973.39,1.435,1.435,0,0,1,0,1.812,1.194,1.194,0,0,1-.973.39,1.194,1.194,0,0,1-.973-.39,1.435,1.435,0,0,1,0-1.812A1.194,1.194,0,0,1,14.342,10.046Z"
            transform="translate(-7 -4)"
          />
        </SvgIcon>
      );
    } else if (data === "REQUEST") {
      return (
        <SvgIcon key={index} className={classes.icon}>
          <path
            id="icons8-spreadsheet-file"
            d="M9.944,4A1.954,1.954,0,0,0,8,5.944V19.337a1.954,1.954,0,0,0,1.944,1.944h9.937a1.954,1.954,0,0,0,1.944-1.944V10.264a.648.648,0,0,0-.19-.458L21.628,9.8l-5.61-5.61A.648.648,0,0,0,15.561,4Zm0,1.3h4.968V8.968a1.954,1.954,0,0,0,1.944,1.944h3.672v8.425a.638.638,0,0,1-.648.648H9.944a.638.638,0,0,1-.648-.648V5.944A.638.638,0,0,1,9.944,5.3Zm6.264.916,3.4,3.4H16.857a.638.638,0,0,1-.648-.648Zm-4.536,6.428a.648.648,0,1,0,0,1.3h2.16a.648.648,0,1,0,0-1.3Zm4.32,0a.648.648,0,1,0,0,1.3h2.16a.648.648,0,1,0,0-1.3Zm-4.32,2.16a.648.648,0,1,0,0,1.3h2.16a.648.648,0,1,0,0-1.3Zm4.32,0a.648.648,0,1,0,0,1.3h2.16a.648.648,0,1,0,0-1.3Zm-4.32,2.16a.648.648,0,1,0,0,1.3h2.16a.648.648,0,1,0,0-1.3Zm4.32,0a.648.648,0,1,0,0,1.3h2.16a.648.648,0,1,0,0-1.3Z"
            transform="translate(-8 -4)"
          />
        </SvgIcon>
      );
    }
  });
};

export const upperFirst = (item: any, col: any, oneCol = false) => {
  if (oneCol) {
    let a = _.upperFirst(_.lowerCase(_.get(item, col)).replace("_g", " ")),
      b = a.split(" ");

    return b[0];
  } else {
    return _.upperFirst(_.lowerCase(_.get(item, col)).replace("_g", " "));
  }
};
