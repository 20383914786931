import React, { useEffect } from "react";
import { useState } from "@hookstate/core/dist";
import { Box, Grid, TextField } from "@material-ui/core";
import People from "../../../../../../../layouts/Apps/People";
import VuiCardContent from "../../../../../../../@VodeaUI/components/VuiCardContent";
import { Controller, useForm } from "react-hook-form";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import EmployeeRepository from "../../../../../../../repositories/EmployeeRepository";
import { defaultDataTableOption } from "../../../../../../../utilities/Option";
import { Options } from "../../../../../../../@VodeaUI/VuiDataTable/Index";
import useIsMounted from "../../../../../../../@VodeaUI/hooks/useIsMounted";
import { useParams } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import FooterFormAction from "../../../../../../../components/FooterFormAction";
import { KeyboardDatePicker } from "@material-ui/pickers";
import moment from "moment";
import UserRepository from "../../../../../../../repositories/UserRepository";
import { useSnackbar } from "notistack";
import { constantToOptions } from "../../../../../../../utilities/helpers/option.helper";
import { useSelector } from "react-redux";
import { Autocomplete } from "@material-ui/lab";
import {
  optionLabel,
  optionSelected,
} from "../../../../../../../utilities/helpers/select";
import {
  genderOptions,
  identityExpiredTypeOptions,
} from "../../../../../../../constants";
import { generateEmployeeNavData } from "../../../../../../../utilities/helpers/global";

const useStyles = makeStyles({
  title: {
    fontWeight: 400,
    color: "#BABABA",
    fontSize: "0.75rem",
  },
  titleValue: {
    fontWeight: 500,
    color: "#4B4D4C",
    fontSize: "0.9rem",
  },
});

interface UserDetailInterface {
  absence_number: Number | null;
  address: String | null;
  bank_account_holder: String | null;
  bank_account_number: String | null;
  bank_id: Number | null;
  blood_type: String | null;
  city: String | null;
  date_of_birth: Date | null;
  gender: String | null;
  id: Number | null;
  identity_expired: Date | null;
  identity_number: String | null;
  identity_type: String | null;
  join_date: Date | null;
  marital_status: String | null;
  place_of_birth: String | null;
  postal_code: String | null;
  religion: String | null;
  resign_date: Date | null;
  resign_description: String | null;
  user_id: Number | null;
  employee_id: Number | null;
  attendence_id: Number | null;
}

interface UserPayrollInterface {
  bpjs_kes_company_paid: Number | null;
  bpjs_kes_family: String | null;
  bpjs_kes_number: Number | null;
  bpjs_kes_valid_from: String | null;
  bpjs_tk_company_paid: Number | null;
  bpjs_tk_number: Number | null;
  bpjs_tk_valid_from: Date | null;
  id: Number | null;
  is_bpjs_kes: Boolean | false;
  is_bpjs_tk: Boolean | false;
  is_jht: Boolean | false;
  is_jp: Boolean | false;
  is_prorate: Boolean | null;
  npwp: String | null;
  overtime_status: String | null;
  payroll_components: UserPayrollPayrollComponentInterface[] | null;
  ptkp_status: String | null;
  salary: String | null;
  salary_configuration_type: String | null;
  tax_configuration: String | null;
  tax_status: String | null;
  taxable_date: String | null;
  type: String | null;
  user_id: Number | null;
  valid_from: Date | null;
}

interface UserPayrollPayrollComponentInterface {
  payroll_component_id: Number | null;
  amount: Number | null;
}

interface DataInterface {
  department_name: String;
  email: String;
  id: Number | String;
  mobile_phone: Number | String;
  name: String;
  photo: Object | null;
  photo_id: Number | null;
  role_name: String;
  telephone: String | null;
  user_detail: UserDetailInterface;
  user_payroll: UserPayrollInterface;
}

const baseData = {
  department_name: "",
  email: "",
  id: "",
  mobile_phone: "",
  name: "",
  photo: null,
  photo_id: null,
  role_name: "",
  telephone: null,
  user_detail: {
    absence_number: null,
    address: null,
    bank_account_holder: null,
    bank_account_number: null,
    bank_id: null,
    blood_type: null,
    city: null,
    date_of_birth: null,
    gender: null,
    id: null,
    identity_expired: null,
    identity_number: null,
    identity_type: null,
    join_date: null,
    marital_status: null,
    place_of_birth: null,
    postal_code: null,
    religion: null,
    resign_date: null,
    resign_description: null,
    user_id: null,
    attendence_id: null,
    employee_id: null,
  },
  user_payroll: {
    bpjs_kes_company_paid: null,
    bpjs_kes_family: null,
    bpjs_kes_number: null,
    bpjs_kes_valid_from: null,
    bpjs_tk_company_paid: null,
    bpjs_tk_number: null,
    bpjs_tk_valid_from: null,
    id: null,
    is_bpjs_kes: false,
    is_bpjs_tk: false,
    is_jht: false,
    is_jp: false,
    is_prorate: null,
    npwp: null,
    overtime_status: null,
    payroll_components: [],
    ptkp_status: null,
    salary: null,
    salary_configuration_type: null,
    tax_configuration: null,
    tax_status: null,
    taxable_date: null,
    type: null,
    user_id: null,
    valid_from: null,
  },
};

const EmployeeDetail: React.FC<any> = ({ data = baseData }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { errors, handleSubmit, setValue, control, watch } =
    useForm<DataInterface>({
      mode: "onChange",
      defaultValues: data,
    });

  const isMounted = useIsMounted();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const loading = useState(false);
  const loadingSubmit = useState(false);

  const { constant } = useSelector(({ constant }: any) => {
    return {
      constant: constant.constant.payload,
    };
  });
  const religionOptions = constantToOptions(constant, "RELIGIONS");
  const bloodTypeOptions = constantToOptions(constant, "BLOOD_TYPE_OPTIONS");
  const relationshipOptions = constantToOptions(
    constant,
    "MARITAL_STATUS_OPTIONS"
  );
  const userDetailIdentityTypeOptions = constantToOptions(
    constant,
    "USER_DETAIL_IDENTITY_TYPE_OPTIONS"
  );
  const userGenderTypeOptions = constantToOptions(constant, "GENDERS");

  const options = useState<Options>({
    ...defaultDataTableOption,
    sortedBy: "desc",
    orderBy: "created_at",
  });

  const employeeNavData = generateEmployeeNavData(id);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const watchIdentityExpiredType: any = watch("identity_expired_type");

  const loadData = () => {
    loading.set(true);

    let params: any = {
      with: ["photo", "userDetail", "userPayroll"],
    };

    if (id !== undefined) {
      EmployeeRepository.show(
        {
          ...params,
        },
        id
      )
        .then((resp: any) => {
          const data = resp.data.data;
          _.forEach(data, (value, name) => {
            if (name === "user_detail") {
              console.log(data.user_detail);
              Object.keys(data.user_detail).map((key) => {
                if (key === "marital_status") {
                  setValue(
                    key,
                    relationshipOptions.find(
                      (item: any) => item.id === value.marital_status
                    )
                  );
                } else if (key === "blood_type") {
                  setValue(
                    key,
                    bloodTypeOptions.find(
                      (item: any) => item.id === value.blood_type
                    )
                  );
                } else if (key === "religion") {
                  setValue(
                    key,
                    religionOptions.find(
                      (item: any) => item.id === value.religion
                    )
                  );
                } else if (key === "gender") {
                  setValue(
                    key,
                    genderOptions.find((item: any) => item.id === value.gender)
                  );
                } else if (key === "identity_type") {
                  setValue(
                    key,
                    userDetailIdentityTypeOptions.find(
                      (item: any) => item.id === value.identity_type
                    )
                  );
                } else if (key === "identity_expired") {
                  const keyIdentityExpiredType = "identity_expired_type";
                  const findData = identityExpiredTypeOptions.find(
                    (item: any) => item.id === "LIFETIME"
                  );

                  if (value.identity_expired === null) {
                    setValue(keyIdentityExpiredType, findData);
                  } else {
                    setValue(keyIdentityExpiredType, findData);
                  }
                } else {
                  setValue(key, data.user_detail[key]);
                }
              });
            } else {
              setValue(name, value);
            }
          });

          if (isMounted.current) {
            loading.set(false);
          }
        })
        .catch(() => {});
    } else {
      EmployeeRepository.account({
        ...params,
      })
        .then((resp: any) => {
          const data = resp.data.data;
          _.forEach(data, (value, name) => {
            if (name === "user_detail") {
              Object.keys(data.user_detail).map((key) => {
                setValue(key, data.user_detail[key]);
              });
            } else {
              setValue(name, value);
            }
          });

          if (isMounted.current) {
            loading.set(false);
          }
        })
        .catch(() => {});
    }
  };

  const onSubmit = async (data: any) => {
    loadingSubmit.set(true);
    var dataSubmit = {
      email: data.email,
      name: data.name,
      mobile_phone: data.mobile_phone,
      user_detail: {
        gender: data.gender?.id,
        join_date: moment(data.join_date).format("YYYY-MM-DD"),
        address: data.address,
        blood_type: data.blood_type?.id,
        religion: data.religion?.id,
        city: data.city,
        postal_code: data.postal_code,
        date_of_birth: moment(data.date_of_birth).format("YYYY-MM-DD"),
        marital_status: data.marital_status?.id,
        place_of_birth: data.place_of_birth,
        identity_expired: data.identity_expired
          ? moment(data.identity_expired).format("YYYY-MM-DD")
          : null,
        identity_type: data.identity_type?.id,
        identity_number: data.identity_number,
      },
    };
    await UserRepository.update(parseInt(id), dataSubmit)
      .then((resp: any) => {
        enqueueSnackbar(resp.data.message, {
          variant: "success",
        });
        loadingSubmit.set(false);
        // return <Redirect to={"/settings/public-holiday"} />;
      })
      .catch((e: any) => {
        enqueueSnackbar("Invalid Data", {
          variant: "error",
        });

        return loadingSubmit.set(false);
      });
  };

  return (
    <>
      <People navData={employeeNavData} title="Employee Detail">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <VuiCardContent title="Personal Data">
              <Box p={2}>
                <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                    <Controller
                      render={({ value, name, onChange }) => (
                        <TextField
                          name={name}
                          label="Employee Name"
                          variant="outlined"
                          onChange={onChange}
                          value={value}
                          fullWidth
                          error={_.has(errors, `name`)}
                          helperText={_.get(errors, `name.message`)}
                        />
                      )}
                      name={`name`}
                      control={control}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Controller
                      render={({ value, name, onChange }) => (
                        <TextField
                          name={name}
                          label="Phone"
                          variant="outlined"
                          onChange={onChange}
                          value={value}
                          fullWidth
                          error={_.has(errors, `mobile_phone`)}
                          helperText={_.get(errors, `mobile_phone.message`)}
                        />
                      )}
                      name={`mobile_phone`}
                      control={control}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Controller
                      render={({ value, name, onChange }) => (
                        <TextField
                          name={name}
                          label="Email"
                          variant="outlined"
                          onChange={onChange}
                          value={value}
                          fullWidth
                          error={_.has(errors, `email`)}
                          helperText={_.get(errors, `email.message`)}
                        />
                      )}
                      name="email"
                      control={control}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Controller
                      render={({ value, name, onChange }) => (
                        <Autocomplete
                          value={value}
                          loading={false}
                          options={relationshipOptions}
                          // onOpen={option.unitOfMeasure.onOpen.get()}
                          getOptionLabel={(option: any) => optionLabel(option)}
                          getOptionSelected={(option: any, value: any) =>
                            optionSelected(option, value)
                          }
                          onChange={(event: any, newValue: any) => {
                            onChange(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name={name}
                              label="Relationship"
                              variant="outlined"
                              error={_.has(
                                errors,
                                `user_detail.marital_status`
                              )}
                              helperText={_.get(
                                errors,
                                `user_detail.marital_status.message`
                              )}
                            />
                          )}
                        />
                      )}
                      name={`marital_status`}
                      defaultValue={null}
                      control={control}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Controller
                      render={({ value, name, onChange }) => (
                        <Autocomplete
                          value={value}
                          loading={false}
                          options={religionOptions}
                          // onOpen={option.unitOfMeasure.onOpen.get()}
                          getOptionLabel={(option: any) => optionLabel(option)}
                          getOptionSelected={(option: any, value: any) =>
                            optionSelected(option, value)
                          }
                          onChange={(event: any, newValue: any) => {
                            onChange(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name={name}
                              label="Religion"
                              variant="outlined"
                              error={_.has(errors, `user_detail.religion`)}
                              helperText={_.get(
                                errors,
                                `user_detail.religion.message`
                              )}
                            />
                          )}
                        />
                      )}
                      name={`religion`}
                      defaultValue={null}
                      control={control}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Controller
                      render={({ value, name, onChange }) => (
                        <KeyboardDatePicker
                          name={name}
                          disableToolbar
                          fullWidth
                          variant="inline"
                          inputVariant="outlined"
                          label="Date of Birth"
                          format="DD MMMM YYYY"
                          error={_.has(errors, "user_detail.date_of_birth")}
                          helperText={_.get(
                            errors,
                            "user_detail.date_of_birth.message"
                          )}
                          value={value}
                          onChange={onChange}
                        />
                      )}
                      name="date_of_birth"
                      control={control}
                      defaultValue={moment().valueOf()}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Controller
                      render={({ value, name, onChange }) => (
                        <TextField
                          name={name}
                          label="Place of Birth"
                          variant="outlined"
                          onChange={onChange}
                          value={value}
                          fullWidth
                          error={_.has(errors, `user_detail.place_of_birth`)}
                          helperText={_.get(
                            errors,
                            `user_detail.place_of_birth.message`
                          )}
                        />
                      )}
                      name={`place_of_birth`}
                      control={control}
                      defaultValue={""}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Controller
                      render={({ value, name, onChange }) => (
                        <Autocomplete
                          value={value}
                          loading={false}
                          options={bloodTypeOptions}
                          // onOpen={option.unitOfMeasure.onOpen.get()}
                          getOptionLabel={(option: any) => optionLabel(option)}
                          getOptionSelected={(option: any, value: any) =>
                            optionSelected(option, value)
                          }
                          onChange={(event: any, newValue: any) => {
                            onChange(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name={name}
                              label="Blood Type"
                              variant="outlined"
                              error={_.has(errors, `blood_type`)}
                              helperText={_.get(errors, `blood_type.message`)}
                            />
                          )}
                        />
                      )}
                      name={`blood_type`}
                      defaultValue={null}
                      control={control}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Controller
                      render={({ value, name, onChange }) => (
                        <Autocomplete
                          value={value}
                          options={userGenderTypeOptions}
                          getOptionLabel={(option: any) => optionLabel(option)}
                          getOptionSelected={(option: any, value: any) =>
                            optionSelected(option, value)
                          }
                          onChange={(event: any, newValue: any) => {
                            onChange(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name={name}
                              label="Gender"
                              variant="outlined"
                              error={_.has(errors, `user_detail.gender`)}
                              helperText={_.get(
                                errors,
                                `user_detail.gender.message`
                              )}
                            />
                          )}
                        />
                      )}
                      name={`gender`}
                      control={control}
                      defaultValue={null}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Controller
                      render={({ value, name, onChange }) => (
                        <KeyboardDatePicker
                          name={name}
                          disableToolbar
                          fullWidth
                          variant="inline"
                          inputVariant="outlined"
                          label="Join Date"
                          format="DD MMMM YYYY"
                          error={_.has(errors, "user_detail.join_date")}
                          helperText={_.get(
                            errors,
                            "user_detail.join_date.message"
                          )}
                          value={value}
                          onChange={onChange}
                        />
                      )}
                      name="join_date"
                      control={control}
                      defaultValue={null}
                    />
                  </Grid>
                </Grid>
              </Box>
            </VuiCardContent>
          </Grid>

          <Grid item xs={12}>
            <VuiCardContent title="Identity Card">
              <Box p={2}>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <Controller
                      render={({ value, name, onChange }) => (
                        <Autocomplete
                          value={value}
                          options={userDetailIdentityTypeOptions}
                          getOptionLabel={(option: any) => optionLabel(option)}
                          getOptionSelected={(option: any, value: any) =>
                            optionSelected(option, value)
                          }
                          onChange={(event: any, newValue: any) => {
                            onChange(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name={name}
                              label="Identity Type"
                              variant="outlined"
                              error={_.has(errors, `user_detail.identity_type`)}
                              helperText={_.get(
                                errors,
                                `user_detail.identity_type.message`
                              )}
                            />
                          )}
                        />
                      )}
                      name={`identity_type`}
                      control={control}
                      defaultValue={null}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Controller
                      render={({ value, name, onChange }) => (
                        <Autocomplete
                          value={value}
                          options={identityExpiredTypeOptions}
                          getOptionLabel={(option: any) => optionLabel(option)}
                          getOptionSelected={(option: any, value: any) =>
                            optionSelected(option, value)
                          }
                          onChange={(event: any, newValue: any) => {
                            onChange(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name={name}
                              label="Identity Expired Type"
                              variant="outlined"
                              error={_.has(
                                errors,
                                `user_detail.identity_expired_type`
                              )}
                              helperText={_.get(
                                errors,
                                `user_detail.identity_expired_type.message`
                              )}
                            />
                          )}
                        />
                      )}
                      name={`identity_expired_type`}
                      control={control}
                      defaultValue={null}
                    />
                  </Grid>

                  {watchIdentityExpiredType?.id === "DATE" && (
                    <Grid item xs={12}>
                      <Controller
                        render={({ value, name, onChange }) => (
                          <KeyboardDatePicker
                            name={name}
                            disableToolbar
                            fullWidth
                            variant="inline"
                            minDate={new Date("2020-01-01")}
                            inputVariant="outlined"
                            label="Identity Expired Date"
                            format="DD/MM/YYYY"
                            error={_.has(
                              errors,
                              "user_detail.identity_expired"
                            )}
                            helperText={_.get(
                              errors,
                              "user_detail.identity_expired.message"
                            )}
                            value={value}
                            onChange={onChange}
                          />
                        )}
                        name={`identity_expired`}
                        control={control}
                        defaultValue={moment().valueOf()}
                      />
                    </Grid>
                  )}

                  <Grid item md={12} xs={12}>
                    <Controller
                      render={({ value, name, onChange }) => (
                        <TextField
                          name={name}
                          label="Identity Number"
                          variant="outlined"
                          onChange={onChange}
                          value={value}
                          fullWidth
                          error={_.has(errors, `user_detail.identity_number`)}
                          helperText={_.get(
                            errors,
                            `user_detail.identity_number.message`
                          )}
                        />
                      )}
                      name={`identity_number`}
                      control={control}
                      defaultValue={""}
                    />
                  </Grid>
                </Grid>
              </Box>
            </VuiCardContent>
          </Grid>

          <Grid item xs={12}>
            <VuiCardContent title="Address">
              <Box p={3}>
                <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                    <Controller
                      render={({ value, name, onChange }) => (
                        <TextField
                          name={name}
                          label="Address"
                          variant="outlined"
                          onChange={onChange}
                          value={value}
                          fullWidth
                          error={_.has(errors, `user_detail.address`)}
                          helperText={_.get(
                            errors,
                            `user_detail.address.message`
                          )}
                        />
                      )}
                      name={`address`}
                      control={control}
                      defaultValue={""}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Controller
                      render={({ value, name, onChange }) => (
                        <TextField
                          name={name}
                          label="City"
                          variant="outlined"
                          onChange={onChange}
                          value={value}
                          fullWidth
                          error={_.has(errors, `user_detail.city`)}
                          helperText={_.get(errors, `user_detail.city.message`)}
                        />
                      )}
                      name={`city`}
                      control={control}
                      defaultValue={""}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Controller
                      render={({ value, name, onChange }) => (
                        <TextField
                          name={name}
                          label="Postal Code"
                          variant="outlined"
                          onChange={onChange}
                          value={value}
                          fullWidth
                          error={_.has(errors, `user_detail.postal_code`)}
                          helperText={_.get(
                            errors,
                            `user_detail.postal_code.message`
                          )}
                        />
                      )}
                      name={`postal_code`}
                      control={control}
                      defaultValue={""}
                    />
                  </Grid>
                </Grid>
              </Box>
            </VuiCardContent>
          </Grid>
        </Grid>
        <Box mt={3}>
          <FooterFormAction
            labelForm="Save Personal Data"
            onCancel={() => navigate("/apps/people/employee")}
            loading={loadingSubmit.get()}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
          />
        </Box>
      </People>
    </>
  );
};

export default EmployeeDetail;
