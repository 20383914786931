import { makeStyles } from "@material-ui/core/styles";

export const useGlobalStyles = makeStyles({
  displayNone: {
    display: "none",
  },
  buttonPrimary: {
    padding: "0.375rem 1rem",
    backgroundColor: "#013332",
    borderRadius: 8,
    textTransform: "capitalize",
    fontWeight: 400,
    boxShadow: "none",
  },
  buttonLg: {
    padding: "0.3rem 2.2rem",
  },
  actionText: {
    textTransform: "capitalize",
    fontSize: 14,
  },
  textRight: {
    textAlign: "right",
  },
  text: {
    color: "#707070",
  },
});
