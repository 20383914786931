import {
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  FormControlLabelProps,
} from "@material-ui/core";
import React, { CSSProperties, FC } from "react";
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

interface IMuiCheckboxStyled extends CheckboxProps {}
interface IMuiFormControlLabel extends FormControlLabelProps {}

interface IMuiCheckbox {
  label?: string;
  onChange: (e: any) => void;
  checked: boolean;
  disabled?: boolean;
  customClasses?: string;
  styles?: CSSProperties;
}

const useStyles = makeStyles({
  checkbox: {
    "& .MuiCheckbox-colorSecondary.Mui-checked": {
      color: "#013332",
    },
  },
  removeMarginHorizontal: {
    marginRight: 0,
    marginLeft: 0,
  },
  label: {
    fontSize: 14,
  },
});

const MuiFormControlLabelStyled = withStyles((theme: Theme) => {
  createStyles({
    root: {},
  });
})((props: IMuiFormControlLabel) => <FormControlLabel {...props} />);

const MuiCheckboxStyled = withStyles((theme: Theme) => {
  createStyles({
    root: {
      color: theme.palette.primary.dark,
    },
  });
})((props: IMuiCheckboxStyled) => {
  return <Checkbox {...props} />;
});

const MuiCheckbox: FC<IMuiCheckbox> = (props: IMuiCheckbox) => {
  const {
    label = "",
    styles,
    onChange,
    disabled,
    customClasses,
    ...other
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <MuiFormControlLabelStyled
      control={
        <MuiCheckboxStyled
          onChange={(e) => onChange(e.target.checked)}
          {...other}
        />
      }
      disabled={disabled}
      label={t(label)}
      style={styles}
      className={clsx(classes.label, classes.checkbox, customClasses)}
    />
  );
};

export default React.memo(MuiCheckbox);
