import { useState } from "@hookstate/core";
import { Box, Grid, IconButton, Typography } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { VuiIcon } from "@vodea/vodea-ui/core/components";
import { VuiIconConstant } from "@vodea/vodea-ui/core/components/VuiIcon/helper";
import React from "react";
import { Link } from "react-router-dom";
import FooterFormStepAction from "../../../components/FooterFormStepAction";
import Stepper from "../../../components/Stepper";
import Calculation from "./Calculation";
import Finalize from "./Finalize";
import Salary from "./Salary";
import { useStyles } from "./style";
import TitleForm from "../../../components/molecules/TitleForm";

const steps = ["Salary", "Calculation", "Finalize", "Pay Slip"];
const icons: any = {
  1: <VuiIcon icon={VuiIconConstant.WALLET} size={16} color={"#383838"} />,
  2: (
    <VuiIcon
      icon={VuiIconConstant.STEERING_WHEEL}
      size={16}
      color={"#383838"}
    />
  ),
  3: (
    <VuiIcon
      icon={VuiIconConstant.STEERING_WHEEL}
      size={16}
      color={"#383838"}
    />
  ),
  4: (
    <VuiIcon
      icon={VuiIconConstant.STEERING_WHEEL}
      size={16}
      color={"#383838"}
    />
  ),
};

const Run: React.FC<any> = () => {
  const activeStep = useState(0);
  const loading = useState(false);
  const classes = useStyles();

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return <Salary />;
      case 1:
        return <Calculation />;
      case 2:
        return <Finalize />;
      default:
        return "Unknown step";
    }
  };

  const handlePrevAction = () => {
    if (activeStep.get() > 0) activeStep.set(activeStep.get() - 1);
  };

  const handleNextAction = () => {
    if (activeStep.get() < 2) {
      activeStep.set(activeStep.get() + 1);
    }
  };

  return (
    <>
      <Grid container spacing={3} justifyContent="space-between">
        <TitleForm title="Run Payroll" withBackUrl={true} />

        <Grid item xs={12} className={classes.stepWrapper}>
          <Stepper steps={steps} activeStep={activeStep.get()} icons={icons} />
        </Grid>
        <Grid item xs={12}>
          {getStepContent(activeStep.get())}
        </Grid>
        <Grid item xs={12} className={classes.footerFormWrapper}>
          <FooterFormStepAction
            title={
              activeStep.get() != 2 ? "Proceed Action" : "Download Payroll"
            }
            leftBtnTitle={activeStep.get() > 0 ? "Sebelumnya" : "Batal"}
            leftBtnAction={handlePrevAction}
            rightBtnTitle={activeStep.get() < 2 ? "Lanjut" : "Download"}
            rightBtnAction={handleNextAction}
            loading={loading.get()}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Run;
