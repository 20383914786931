import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useStyles } from "../../Style";
import {
  Box,
  Button,
  CardContent,
  CircularProgress,
  Container,
  FormControl,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Images } from "../../../../assets/images";
import AuthenticationRepository from "../../../../repositories/AuthenticationRepository";
import { AxiosError, AxiosResponse } from "axios";
import { mapHookErrors } from "../../../../utilities/helpers/global";
import { useSnackbar } from "notistack";
import { useIsMounted } from "../../../../@VodeaUI";
import * as yup from "yup";
import _ from "lodash";

const schema = yup.object().shape({
  email: yup.string().required().label("email"),
  password: yup.string().min(6).required().label("Password"),
  password_confirmation: yup
    .string()
    .oneOf(
      [yup.ref("password"), null],
      "The password confirmation is doesn't match"
    )
    .label("The password confirmation"),
});

const AuthResetPassword: React.FC<any> = () => {
  const url = new URL(window.location.href);
  const token = url.searchParams.get("token");
  const email = url.searchParams.get("email");
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const isMounted = useIsMounted();
  const [loading, setLoading] = useState<boolean>(false);

  const { handleSubmit, errors, setError, control, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
      password: "",
      password_confirmation: "",
    },
  });

  useEffect(() => {
    setValue("email", email);
  }, []);

  const onSubmit = (formData: any) => {
    if (isMounted.current) setLoading(true);
    AuthenticationRepository.resetPassword({
      email: formData.email,
      token,
      password: formData.password,
      password_confirmation: formData.password_confirmation,
    })
      .then((response: AxiosResponse) => {
        enqueueSnackbar("Successfully change password", {
          variant: "success",
        });
        if (isMounted.current) setLoading(false);
      })
      .catch((e: AxiosError) => {
        if (isMounted.current) setLoading(false);
        if (isMounted.current && e?.response?.data?.errors) {
          const errors = mapHookErrors(e.response.data.errors);
          Object.keys(errors).forEach((key: any) => setError(key, errors[key]));

          enqueueSnackbar(e.response.data.message, {
            variant: "error",
          });
        }
      });
  };

  return (
    <>
      <div className={classes.contentWrapper}>
        <Container maxWidth="sm">
          <Paper
            classes={{
              root: classes.cardPaper,
            }}
          >
            <CardContent
              classes={{
                root: classes.cardContent,
              }}
            >
              <Box mb={4}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box mr={2}>
                    <Typography
                      classes={{
                        root: classes.title,
                      }}
                      variant="h5"
                    >
                      Reset Password
                    </Typography>
                    <Typography>Reset your password</Typography>
                  </Box>

                  <div>
                    <img
                      className={classes.brandLogo}
                      src={Images.logo}
                      alt="vodea logo"
                    />
                  </div>
                </Box>
              </Box>

              <Box>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <FormControl fullWidth margin={"normal"}>
                    <Controller
                      name="email"
                      control={control}
                      render={({ value, onChange, name }) => {
                        return (
                          <TextField
                            name={name}
                            label="Email"
                            value={value}
                            onChange={onChange}
                            variant="outlined"
                            fullWidth
                            error={!!errors?.email}
                            helperText={errors?.email?.message}
                          />
                        );
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth margin={"normal"}>
                    <Controller
                      as={TextField}
                      name="password"
                      control={control}
                      variant="outlined"
                      defaultValue=""
                      label="Password"
                      type="password"
                      fullWidth
                      error={!!errors?.password}
                      helperText={errors?.password?.message}
                    />
                  </FormControl>
                  <FormControl fullWidth margin={"normal"}>
                    <Controller
                      as={TextField}
                      name="password_confirmation"
                      control={control}
                      variant="outlined"
                      defaultValue=""
                      type="password"
                      label="Password Confirmation"
                      fullWidth
                      error={!!errors?.password_confirmation}
                      helperText={errors?.password_confirmation?.message}
                    />
                  </FormControl>

                  <FormControl fullWidth margin={"normal"}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                      disabled={loading}
                    >
                      {loading ? (
                        <Box>
                          <CircularProgress color="inherit" size={20} />
                        </Box>
                      ) : (
                        "Reset"
                      )}
                    </Button>
                  </FormControl>
                </form>
              </Box>
            </CardContent>
          </Paper>
        </Container>
      </div>
    </>
  );
};

export default AuthResetPassword;
