import {
  FORMATTER_CHANGE_DATE,
  FORMATTER_CHANGE_DATE_DAY,
  FORMATTER_CHANGE_DATE_TIME,
} from "./actions";
import { FormatterAction, FormatterState } from "./types";

const initialState: FormatterState = {
  date: "DD MMMM YYYY",
  datetime: "DD MMMM YYYY, HH:mm",
  dateDay: "dddd, DD MMMM YYYY",
};

export const formatterReducer = function (
  state: FormatterState = initialState,
  action: FormatterAction
) {
  switch (action.type) {
    case FORMATTER_CHANGE_DATE:
      return {
        ...state,
        date: action.payload,
      };
    case FORMATTER_CHANGE_DATE_TIME:
      return {
        ...state,
        datetime: action.payload,
      };
    case FORMATTER_CHANGE_DATE_DAY:
      return {
        ...state,
        dateDay: action.payload,
      };
    default:
      return state;
  }
};
