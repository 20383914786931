import React from "react";
import moment from "moment";
import { useSelector } from "react-redux";
// const idLocale = require("moment/locale/id");
// moment.updateLocale("id", idLocale);

interface DatetimeInterface {
  data: string | null;
  defaultValue?: string;
}

const Datetime: React.FC<DatetimeInterface> = ({
  data,
  defaultValue = "-",
}) => {
  const { datetime } = useSelector(({ formatter }: any) => ({
    datetime: formatter.datetime,
  }));

  return (
    <>{moment(data).isValid() ? moment(data).format(datetime) : defaultValue}</>
  );
};

export default Datetime;
