import React, { useCallback, useMemo } from "react";
import { Box, Grid, Paper } from "@material-ui/core";
import {
  $clone,
  $cloneState,
  handleAxiosError,
  mapDataTableParams,
} from "../../../../utilities/helpers/global";
import { useState } from "@hookstate/core/dist";
import VuiDataTable, {
  Column,
  Options,
} from "../../../../@VodeaUI/VuiDataTable/Index";
import { defaultDataTableOption } from "../../../../utilities/Option";
import { Link } from "react-router-dom";
import ReimbursementRepository from "../../../../repositories/ReimbursementRepository";
import { useIsMounted } from "../../../../@VodeaUI";
import { AxiosError, AxiosResponse } from "axios";
import { useSnackbar } from "notistack";
import Status from "../../../../components/Status";
import { useNavigate } from "react-router";
import MuiButton from "../../../../components/atoms/MuiButton";
import { TitleHeader } from "../../../../components/molecules";
import { MuiAutoComplete, MuiSearch } from "../../../../components/atoms";
import { sortedByOptions } from "../../../../constants";
import UserRepository from "../../../../repositories/UserRepository";
import ReimbursementPolicyRepository from "../../../../repositories/ReimbursementPolicyRepository";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { IApiResource } from "../../../../interfaces/ApiResource";

const ReimbursementFinance: React.FC<any> = () => {
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const data = useState<any[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useSelector(({ vodea }: any) => {
    return {
      user: vodea.auth.user,
    };
  });
  const [loading, setLoading] = React.useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = React.useState<any>(null);
  const [selectedUser, setSelectedUser] = React.useState<any>(null);
  const { t } = useTranslation();
  const options = useState<Options>({
    ...defaultDataTableOption,
    sortedBy: "desc",
    orderBy: "date",
  });

  const columns: Column[] = [
    {
      key: "user_name",
      label: "Name",
      sortable: false,
    },
    {
      key: "created_at",
      label: "Request Date",
      type: "date",
      dateFormat: "DD MMMM YYYY",
      sortable: false,
    },
    { key: "reimbursement_policy_name", label: "Category", sortable: false },
    {
      key: "start",
      label: "Used On",
      type: "date",
      dateFormat: "DD MMMM YYYY",
      defaultValue: "-",
      sortable: false,
    },
    { key: "amount", label: "Amount", sortable: false },
    {
      key: "status",
      label: "Status",
      sortable: false,
      render: (row) => {
        return <Status data={row.status} />;
      },
    },
    {
      key: "id",
      label: "",
      sortable: false,
      render: (row) => {
        return (
          <Box display="flex" justifyContent={"flex-end"}>
            <MuiButton
              label="View"
              onClick={() =>
                navigate(`${row.id}`, {
                  state: {
                    type: 2,
                  },
                })
              }
            />
          </Box>
        );
      },
    },
  ];

  const loadData = useCallback(async () => {
    if (isMounted.current) setLoading(true);

    await ReimbursementRepository.all({
      ...mapDataTableParams($cloneState(options)),
      only: "my_and_approver",
      with: ["user", "reimbursementPolicy"],
      "reimbursement-policy": selectedCategory?.id,
      user: selectedUser?.id,
    })
      .then((response: AxiosResponse<IApiResource<any>>) => {
        const { data: responseData, meta } = response.data;

        responseData.map((item: any) => ({
          id: item.id,
          user_name: item.user.name,
          created_at: item.created_at,
          reimbursement_policy_name: item.reimbursement_policy.name,
          start: item?.start || null,
          amount: item.amount,
          status: item.status,
        }));

        if (isMounted.current) {
          data.set(responseData);
          options.page.set(meta.current_page - 1);
          options.total.set(meta.total);
          setLoading(false);
        }
      })
      .catch((error: AxiosError) => {
        if (isMounted.current) {
          setLoading(false);
          handleAxiosError(error, isMounted.current, enqueueSnackbar);
        }
      });
  }, [selectedCategory, selectedUser]);

  useMemo(() => {
    loadData().then();
  }, [selectedCategory, selectedUser]);

  const handleTableSearch = useCallback((value: string) => {
    if (isMounted.current) {
      options.search.set(value);
      options.page.set(0);
      loadData().then();
    }
  }, []);

  return (
    <>
      <TitleHeader
        title={"Reimbursement"}
        subtitle={"List of reimbursement transaction"}
        component={
          <Link to={"/apps/finance/reimbursement/reimbursement-request"}>
            <MuiButton label="Request Reimbursement" size="large" />
          </Link>
        }
      />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper variant="elevation" elevation={1}>
            <Box p={2}>
              <Grid
                container
                spacing={3}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Grid item>
                  <MuiSearch
                    value={$clone(options.search.value)}
                    callback={handleTableSearch}
                  />
                </Grid>
                <Grid item md={3}>
                  <MuiAutoComplete
                    isAsync={false}
                    value={sortedByOptions.find(
                      (option) => option.id === $clone(options.sortedBy.value)
                    )}
                    constantOptions={sortedByOptions}
                    onSelected={(newValue) => {
                      options.sortedBy.set(newValue.id);
                      loadData().then();
                    }}
                    muiTextField={{
                      label: t("form.sort.label"),
                    }}
                  />
                </Grid>
              </Grid>

              <Box mt={1}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={3}>
                    <MuiAutoComplete
                      repository={ReimbursementPolicyRepository}
                      params={{
                        for: "cash-advance",
                        user: user?.id,
                      }}
                      onSelected={(newValue) => {
                        setSelectedCategory(newValue);
                      }}
                      muiTextField={{
                        label: t("form.category.label"),
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <MuiAutoComplete
                      repository={UserRepository}
                      params={{
                        for: "reimbursement",
                      }}
                      onSelected={(newValue) => {
                        setSelectedUser(newValue);
                      }}
                      muiTextField={{
                        label: t("form.employee.label"),
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>

            <VuiDataTable
              loading={loading}
              columns={columns}
              options={$clone(options.value)}
              data={data.get()}
              onChangeOptions={(values: Options) => {
                options.set(values);
                loadData().then();
              }}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default ReimbursementFinance;
