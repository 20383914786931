import React, { useEffect, useState } from "react";
import SingleInformation from "../../Widget/SingleInformation";
import { AxiosResponse } from "axios";
import _ from "lodash";
import WidgetRepository from "../../../repositories/WidgetRepository";
import Announcement from "../Announcement";
import { statusFormat } from "../../../utilities/helpers/string";
import BoxInformation from "../../Widget/BoxInformation";
import moment from "moment";

const WidgetDashboard: React.FC<any> = () => {
  const [annual, setAnnual] = useState<any[]>([]);
  const [attendance, setAttendance] = useState({});
  const [loadingData, setLoadingData] = React.useState(false);

  useEffect(() => {
    loadData().catch(() => {});
  }, []);

  const loadData = async () => {
    setLoadingData(true);
    let params: any = {};

    WidgetRepository.annual({
      ...params,
    })
      .then((response: AxiosResponse) => {
        let tempAnnual: any[] = [];
        _.get(response, "data.data", []).forEach((item: any) => {
          if (!moment(item.expired_at).isBefore(moment())) {
            tempAnnual.push({
              label: item.name,
              value: item.balance,
              expired: item.expired_at,
            });
          }
        });

        setAnnual(tempAnnual);
      })
      .catch(() => {});

    WidgetRepository.attendance({
      ...params,
    })
      .then((response: AxiosResponse) => {
        const tempAttendance = {
          label: "Today Attendance",
          time_to: _.get(response, "data.attendance.time_to"),
          time_from: _.get(response, "data.attendance.time_from"),
          expired: _.get(response, "data.attendance.date"),
          status: statusFormat(response, "data.attendance.clock_in_status"),
        };
        setLoadingData(false);
        setAttendance(tempAttendance);
      })
      .catch(() => {});
  };

  return (
    <>
      {/*<SingleInformation data={annual} type={"day"} />*/}
      <BoxInformation data={annual} type={"day"} canSwipe={true} />
      <SingleInformation
        data={attendance}
        type={"attendance"}
        loading={loadingData}
      />
      {/*<Announcement mainLoading={loadingData} />*/}
    </>
  );
};

export default WidgetDashboard;
