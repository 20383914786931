import { api } from "../@VodeaUI";

let url = window._env_.REACT_APP_PASSPORT_END_POINT;

const EmployeeRepository = {
    all: function(params: any = null) {
        return api.get(`${url}/api/`, {
            params
        });
    },
    account: function(params: any = null){
        return api.get(`${url}/api/account`, {
            params
        });
    },
    show: function(params: any = null, id: any) {
        return api.get(`${url}/api/user/${id}`, {
            params
        });
    },
};

export default EmployeeRepository;
