import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  dialogAction: {
    justifyContent: "center !important",
  },
  titleDialog: {
    fontSize: "20px",
    fontWeight: "bold",
  },
  subTitleDialog: {
    fontSize: "14px",
  },
  gapButton: {
    marginRight: "20px",
  },
  pinWrapper: {
    border: "1px solid transparent",
    borderBottomColor: "#707070",
  },
  buttonPrimary: {
    padding: "0.375rem 1rem",
    backgroundColor: "#013332 !important",
    borderRadius: 8,
    textTransform: "capitalize",
    fontWeight: 400,
    boxShadow: "none",
  },
  buttonLg: {
    padding: "0.3rem 2.2rem",
  },
}));
