import React, { useEffect } from "react";
import People from "../../../../../../layouts/Apps/People";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { VuiSearch } from "../../../../../../@VodeaUI/components";
import {
  ArrowForwardIos,
  BlockOutlined,
  CheckCircleOutlineOutlined,
  EditOutlined as EditOutlinedIcon,
} from "@material-ui/icons";
import {
  $clone,
  $cloneState,
  mapDataTableParams,
} from "../../../../../../utilities/helpers/global";
import { useState } from "@hookstate/core/dist";
import { AxiosResponse } from "axios";
import useIsMounted from "../../../../../../@VodeaUI/hooks/useIsMounted";
import VuiDataTable, {
  Column,
  Options,
} from "../../../../../../@VodeaUI/VuiDataTable/Index";
import { defaultDataTableOption } from "../../../../../../utilities/Option";
import { useParams } from "react-router";
import CashAdvanceRepository from "../../../../../../repositories/CashAdvanceRepository";
import _ from "lodash";
import Status from "../../../../../../components/Status";
import { Link, useNavigate } from "react-router-dom";

const Advance: React.FC<any> = () => {
  const navigate = useNavigate();
  const columns = useState<Column[]>([
    {
      key: "created_at",
      label: "Request Date",
      type: "date",
      dateFormat: "DD MMMM YYYY",
    },
    {
      key: "cash_advance_policy",
      label: "Category",
      render: (row) => {
        return <div>{row?.cash_advance_policy?.name || "-"}</div>;
      },
    },
    {
      key: "date",
      label: "Used Date",
      type: "date",
      dateFormat: "DD MMMM YYYY",
    },
    { key: "amount", label: "Amount", type: "number" },
    {
      key: "status",
      label: "Status",
      render: (data) => {
        let status = _.get(data, "status", "");

        return <Status data={status} />;
      },
    },
    {
      key: "id",
      label: "",
      sortable: false,
      render: (row) => {
        return (
          <Box display="flex" justifyContent={"flex-end"}>
            <IconButton
              onClick={() =>
                navigate(`${row.id}`, {
                  state: {
                    type: 3,
                  },
                })
              }
              aria-label="edit"
            >
              <ArrowForwardIos fontSize="small" />
            </IconButton>

            {row.can_suspend ? (
              <Tooltip title="Suspend">
                <IconButton aria-label="suspend">
                  <BlockOutlined fontSize="small" />
                </IconButton>
              </Tooltip>
            ) : null}

            {row.can_unsuspend ? (
              <Tooltip title="Unsuspend">
                <IconButton aria-label="unsuspend">
                  <CheckCircleOutlineOutlined fontSize="small" />
                </IconButton>
              </Tooltip>
            ) : null}
          </Box>
        );
      },
    },
  ]);

  const loading = useState(false);
  const objParams = useParams();
  const isMounted = useIsMounted();
  const data = useState([]);
  const options = useState<Options>({
    ...defaultDataTableOption,
    sortedBy: "desc",
    orderBy: "date",
  });

  useEffect(() => {
    document.title = "Advance";
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = () => {
    loading.set(false);

    let params: any = {};

    Object.assign(params, {
      with: ["cashAdvancePolicy"],
    });

    if (objParams.id !== undefined) {
      params = {
        ...params,
        user: objParams.id,
      };

      CashAdvanceRepository.all({
        ...params,
        ...mapDataTableParams($cloneState(options)),
      })
        .then((response: AxiosResponse) => {
          const { data: responseData } = response;
          const { data: list, meta } = responseData;

          if (isMounted.current) {
            data.set(list);
            options.page.set(meta.current_page - 1);
            options.total.set(meta.total);
            loading.set(false);
          }
        })
        .catch(() => {});
    } else {
      CashAdvanceRepository.account({
        ...params,
        ...mapDataTableParams($cloneState(options)),
      })
        .then((response: AxiosResponse) => {
          const { data: responseData } = response;
          const { data: list, meta } = responseData;

          if (isMounted.current) {
            data.set(list);
            options.page.set(meta.current_page - 1);
            options.total.set(meta.total);
            loading.set(false);
          }
        })
        .catch(() => {});
    }
  };

  return (
    <People>
      <Grid container spacing={3} justifyContent="space-between">
        <Grid item>
          <Typography variant={"h5"}>Advance</Typography>
        </Grid>
        <Grid item>
          <Link to={"/apps/finance/advance/advance-request"}>
            <Button variant="contained" color="primary">
              Request Cash Advance
            </Button>
          </Link>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper variant="elevation" elevation={1}>
            <Box p={2}>
              <Grid container spacing={3} alignItems={"center"}>
                <Grid item md={4} xs={12}>
                  <VuiSearch
                    state={options.search}
                    callback={() => {
                      options.page.set(0);
                      loadData();
                    }}
                  />
                </Grid>
              </Grid>
            </Box>

            <VuiDataTable
              loading={loading.get()}
              columns={columns.value}
              options={$clone(options.value)}
              data={data.value}
              onChangeOptions={(values: Options) => {
                options.set(values);
                loadData();
              }}
            />
          </Paper>
        </Grid>
      </Grid>
    </People>
  );
};

export default Advance;
