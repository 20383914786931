import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  card: {
    "& .card-header": {
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },

    "& .card-title": {
      fontSize: "16px",
      fontWeight: "bold",
    },

    "& .card-footer": {
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
  },

  cardMenu: {
    minWidth: 120,
  },

  subtitle1: {
    fontWeight: 600,
    color: "#1A1818",
    fontSize: "1rem",
  },

  subtitle2: {
    fontWeight: 400,
    color: "#707070",
    fontSize: "0.875rem",
  },
}));
