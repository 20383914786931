import React from "react";
import { Box, Grid, Paper, Typography } from "@material-ui/core";
import { VuiIconConstant } from "@vodea/vodea-ui/core/components/VuiIcon/helper";
import { VuiIcon } from "@vodea/vodea-ui/core/components";
import { Link } from "react-router-dom";

const Preference: React.FC<any> = () => {
  const data = [
    {
      label: "Company",
      subLabel: "Manage Company Profile",
      link: "/apps/setting/company/general-information",
      type: "link",
      icon: VuiIconConstant.BRIEFCASE,
    },
    {
      label: "Payroll",
      subLabel: `Configure Company's Payroll`,
      link: "/apps/setting/payroll",
      type: "link",
      icon: VuiIconConstant.PAYROLL,
    },
    {
      label: "Policy",
      subLabel: "Configure company policy",
      link: "/apps/setting/policy/time-off",
      type: "link",
      icon: VuiIconConstant.DOCUMENTS,
    },
    {
      label: "Assign Policy",
      subLabel: `Manage employee's policy`,
      link: "/apps/setting/assign-policy",
      type: "link",
      icon: VuiIconConstant.PAYROLL,
    },
    {
      label: "Public Holiday",
      subLabel: "Manage Public Holiday",
      link: "/apps/setting/public-holiday",
      type: "link",
      icon: VuiIconConstant.CALENDAR,
    },
    {
      label: "Announcement",
      subLabel: "Manage announcement",
      link: "/apps/setting/announcement",
      type: "link",
      icon: VuiIconConstant.MEGAPHONE,
    },
  ];

  return (
    <>
      <Grid container spacing={3} justifyContent="space-between">
        <Grid item>
          <Typography variant={"h5"}>Setting</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={3} justifyContent="space-between">
        {data.map((item, index) => {
          return (
            <Grid item xs={12} md={4} key={index}>
              {item.type === "web" ? (
                <a href={item.link}>
                  <Paper variant="elevation" elevation={1}>
                    <Box p={3}>
                      <Grid container spacing={3} alignItems={"center"}>
                        <Grid item md={2} xs={2}>
                          <VuiIcon
                            icon={item.icon}
                            size={40}
                            color={"#4B4D4C"}
                          />
                        </Grid>
                        <Grid item md={10} xs={10}>
                          <Typography variant="subtitle1" gutterBottom>
                            {item.label}
                          </Typography>
                          <Typography variant="subtitle2" gutterBottom>
                            {item.subLabel}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Paper>
                </a>
              ) : (
                <Link to={item.link}>
                  <Paper variant="elevation" elevation={1}>
                    <Box p={3}>
                      <Grid container spacing={3} alignItems={"center"}>
                        <Grid item md={2} xs={2}>
                          <VuiIcon
                            icon={item.icon}
                            size={40}
                            color={"#4B4D4C"}
                          />
                        </Grid>
                        <Grid item md={10} xs={10}>
                          <Typography variant="subtitle1" gutterBottom>
                            {item.label}
                          </Typography>
                          <Typography variant="subtitle2" gutterBottom>
                            {item.subLabel}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Paper>
                </Link>
              )}
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default Preference;
