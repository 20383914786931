import { useStyles } from "./Style";
import React from "react";
import {
  Box,
  Divider,
  IconButton,
  Menu,
  Paper,
  Typography,
} from "@material-ui/core";
import {
  ChevronRight as ChevronRightIcon,
  MoreVert as MoreVertIcon,
} from "@material-ui/icons";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";

interface props {
  title?: string;
  btnText?: string;
  btnLink?: string;
  menus?: React.ReactNode;
  hasSubtitle?: boolean;
  subtitle?: string;
}

const VuiCardContent: React.FC<props> = ({
  title,
  btnText = "See all",
  btnLink = "#",
  menus,
  children,
  hasSubtitle = false,
  subtitle = "",
}) => {
  const classes = useStyles();

  return (
    <>
      <Paper
        variant="elevation"
        elevation={1}
        classes={{
          root: classes.card,
        }}
      >
        <Box
          className="card-header"
          hidden={title === undefined ? true : false}
        >
          {hasSubtitle ? (
            <Box
              justifyContent={"space-between"}
              display={"flex"}
              alignItems={"center"}
              width={"100%"}
            >
              <Typography variant="subtitle2">{title}</Typography>
              <Typography variant="subtitle2">{subtitle}</Typography>
            </Box>
          ) : (
            <Box>
              <Typography variant="subtitle2">{title}</Typography>
            </Box>
          )}

          {menus && (
            <PopupState variant="popover" popupId="demo-popup-menu">
              {(popupState) => (
                <>
                  <IconButton size="small" {...bindTrigger(popupState)}>
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    {...bindMenu(popupState)}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    classes={{
                      paper: classes.cardMenu,
                    }}
                  >
                    <div>{menus}</div>
                  </Menu>
                </>
              )}
            </PopupState>
          )}
        </Box>

        <Divider hidden={title === undefined ? true : false} />

        {children}

        {btnLink !== "#" && (
          <>
            <Divider />
            <Box className="card-footer">
              <Link to={btnLink}>
                <Button endIcon={<ChevronRightIcon />}>{btnText}</Button>
              </Link>
            </Box>
          </>
        )}
      </Paper>
    </>
  );
};

export default VuiCardContent;
