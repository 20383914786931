import React from "react";
import { AppBar, Box, Toolbar } from "@material-ui/core";
import { useStyles } from "./style";
import { Logo, SwitchApplication, Account, Notification, Search } from "./Part";

interface Props {
  data: {
    logo: string;
    appName: {
      first: string;
      second?: string;
    };
    useApps: boolean;
  };
  handleClick(): void;
}

const Header: React.FC<Props> = ({ data, handleClick }) => {
  const classes = useStyles();

  return (
    <AppBar
      position="fixed"
      color="inherit"
      elevation={0}
      className={classes.root}
    >
      <Toolbar>
        <Logo
          logo={data.logo}
          appName={data.appName}
          handleClick={handleClick}
        />
        <Box ml="auto">
          {/*<Search />*/}
          <SwitchApplication />
          <Notification />
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
