import { ConfirmOptions } from "material-ui-confirm";
import { IDataTableParams } from "../interfaces/IDataTableParams";

export const defaultDataTableOption: IDataTableParams = {
  search: "",
  page: 0,
  perPage: 10,
  rowsPerPageOptions: [10, 25, 50],
  total: 0,
  orderBy: null,
  sortedBy: null,
};

export const defaultDeleteConfirmOption: ConfirmOptions = {
  title: `Are you sure want to delete?`,
  description: "This action is cannot be undo.",
  confirmationText: "Yes",
  cancellationText: "Cancel",
  cancellationButtonProps: {
    color: "primary",
  },
  confirmationButtonProps: {
    color: "default",
  },
};

export const defaultSuspendConfirmOption: ConfirmOptions = {
  title: `Are you sure want to suspend?`,
  description: "This action will be lock to access the system",
  confirmationText: "Yes",
  cancellationText: "Cancel",
  cancellationButtonProps: {
    color: "primary",
  },
  confirmationButtonProps: {
    color: "default",
  },
};

export const defaultUnsuspendConfirmOption: ConfirmOptions = {
  title: `Are you sure want to unsuspend?`,
  description: "This action will be unlock to access the system",
  confirmationText: "Yes",
  cancellationText: "Cancel",
  cancellationButtonProps: {
    color: "primary",
  },
  confirmationButtonProps: {
    color: "default",
  },
};
