import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React, { FC } from "react";
import { MuiButton } from "../../atoms";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles({
  dialog: {
    textAlign: "center",
  },
  dialogTitle: {
    margin: "20px 0 0 0",
    padding: "8px 32px",
    "& h2": {
      fontSize: 20,
    },
  },
  dialogContent: {
    padding: "0 24px",
  },
  dialogAction: {
    padding: "16px 16px 28px 16px",
    justifyContent: "center",
  },
  closeButton: {
    position: "absolute",
    right: 0,
    top: 0,
  },
});

export interface IPopUpAssignPolicy {
  open: boolean;
  onClose: () => void;
  url: string;
}

const PopUpAssignPolicy: FC<IPopUpAssignPolicy> = (
  props: IPopUpAssignPolicy
) => {
  const { onClose, open, url } = props;
  const classes = useStyles();
  const navigate = useNavigate();

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
    navigate(url);
  };

  const handleClickAssign = () => {
    if (onClose) {
      onClose();
    }
    navigate("/apps/setting/assign-policy/create");
  };

  return (
    <Dialog
      className={classes.dialog}
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle className={classes.dialogTitle} id="simple-dialog-title">
        Succeed
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography variant="body2">
          You have successfully created a new policy.
        </Typography>
        <Typography variant="body2">
          Would you like to assign employee to this policy now?
        </Typography>
      </DialogContent>
      <DialogActions className={classes.dialogAction}>
        <MuiButton
          onClick={handleClose}
          variant="text"
          label="No, Assign Later"
        />
        <MuiButton onClick={handleClickAssign} label="Assign" />
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(PopUpAssignPolicy);
