import React, { ReactElement, useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useStyles } from "./Style";
import {
  CircularProgress,
  TablePagination,
  TableSortLabel,
} from "@material-ui/core";
import { useState } from "@hookstate/core/dist";
import moment from "moment";
import { IDataTableParams } from "../../interfaces/IDataTableParams";

type OrderBy = string | null;
type SortedBy = "asc" | "desc" | null;
type ColumnType = "number" | "string" | "date" | "datetime" | null;
type ColumnAlign = "left" | "right" | null;

export interface Column {
  key: string;
  name?: string;
  label?: string;
  render?: (row: any, key: string, index: number) => ReactElement | null;
  defaultValue?: string;
  type?: ColumnType;
  dateFormat?: string;
  align?: ColumnAlign;
  sortable?: boolean;
  disablePadding?: boolean;
  minWidth?: string | number;
}

export interface Options {
  search: string;
  page: number;
  perPage: number;
  rowsPerPageOptions: number[];
  total: number;
  count?: number;
  orderBy: OrderBy;
  sortedBy: SortedBy;
}

interface VuiTableLoadingProps {
  columns: Column[];
}

const VuiTableLoading: React.FC<VuiTableLoadingProps> = ({ columns }) => {
  const classes = useStyles();

  return (
    <TableBody>
      <TableRow>
        <TableCell padding="none" colSpan={columns.length} align="center">
          <div className={classes.loadingWrapper}>
            <CircularProgress />
          </div>
        </TableCell>
      </TableRow>
    </TableBody>
  );
};

interface VuiTableHeadProps {
  columns: Column[];
  options: Options;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
}

const VuiTableHead: React.FC<VuiTableHeadProps> = ({
  columns,
  options,
  onRequestSort,
}) => {
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  const classes = useStyles();

  return (
    <TableHead>
      <TableRow>
        {columns.map((column) => (
          <TableCell
            key={column.key}
            align={column.align || "left"}
            padding={column.disablePadding ? "none" : "normal"}
            className={classes.tableHead}
            style={{ minWidth: column.minWidth }}
            sortDirection={
              options.sortedBy &&
              options.orderBy === (column.name || column.key)
                ? options.sortedBy
                : false
            }
          >
            {typeof column.sortable === "undefined" || column.sortable ? (
              <TableSortLabel
                active={options.orderBy === (column.name || column.key)}
                direction={
                  options.sortedBy &&
                  options.orderBy === (column.name || column.key)
                    ? options.sortedBy
                    : "asc"
                }
                onClick={createSortHandler(column.name || column.key)}
              >
                {column.label}
              </TableSortLabel>
            ) : (
              column.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

interface VuiTableBodyProps {
  data: any[];
  columns: Column[];
  propKey?: ((row: any) => string) | string;
}

const VuiTableBody: React.FC<VuiTableBodyProps> = ({
  data,
  columns,
  propKey = "id",
}) => {
  const classes = useStyles();
  const renderCell = (row: any, column: Column, index: number) => {
    if (column.render) {
      return column.render(row, column.key, index);
    }

    let data = row[column.key];

    switch (column.type) {
      case "date":
        if (data) {
          data = moment(data).format(column.dateFormat || "DD MMMM YYYY");
        }
        break;
      case "datetime":
        if (data) {
          data = moment(data).format(column.dateFormat || "DD MMMM YYYY H:m");
        }
        break;
      case "number":
        if (data) {
          data = parseFloat(data).toLocaleString();
        }
        break;
      default:
        data = row[column.key];
    }

    return data || (data === null ? column.defaultValue : "");
  };

  const renderKey = (row: any) => {
    if (typeof propKey === "function") {
      return propKey(row);
    }

    return row[propKey];
  };

  return (
    <TableBody>
      {data.length ? (
        data.map((row, index) => {
          return (
            <TableRow
              hover
              role="checkbox"
              tabIndex={-1}
              key={renderKey(row)}
              className={classes.tableRow}
            >
              {columns.map((column) => {
                if (column.key === "detail") {
                  return (
                    <TableCell
                      key={`${renderKey(row)}.${column.key}`}
                      align={column.align || "left"}
                      padding={column.disablePadding ? "none" : "normal"}
                      className={"btnView"}
                    >
                      {renderCell(row, column, index)}
                    </TableCell>
                  );
                }
                return (
                  <TableCell
                    key={`${renderKey(row)}.${column.key}`}
                    align={column.align || "left"}
                    className={classes.tableCell}
                    padding={column.disablePadding ? "none" : "normal"}
                  >
                    {renderCell(row, column, index)}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })
      ) : (
        <TableRow hover role="checkbox" tabIndex={-1}>
          <TableCell align={"center"} colSpan={columns.length}>
            No matching records found
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  );
};

interface VuiTableProps {
  loading: boolean;
  data: any[];
  columns: Column[];
  options: IDataTableParams;
  onChangeOptions?: (options: Options) => void | null;
  propKey?: ((row: any) => string) | string;
}

const VuiTable: React.FC<VuiTableProps> = ({
  loading,
  columns,
  data,
  options,
  onChangeOptions,
  propKey = "id",
}) => {
  const scopedOptions = useState(options);

  useEffect(() => {
    scopedOptions.set(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = options.orderBy === property && options.sortedBy === "asc";
    scopedOptions.sortedBy.set(isAsc ? "desc" : "asc");
    scopedOptions.orderBy.set(property);

    if (onChangeOptions) {
      onChangeOptions(JSON.parse(JSON.stringify(scopedOptions.value)));
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    scopedOptions.page.set(newPage);
    if (onChangeOptions) {
      onChangeOptions(JSON.parse(JSON.stringify(scopedOptions.value)));
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    scopedOptions.perPage.set(parseInt(event.target.value, 10));
    scopedOptions.page.set(0);
    if (onChangeOptions) {
      onChangeOptions(JSON.parse(JSON.stringify(scopedOptions.value)));
    }
  };

  return (
    <>
      <TableContainer>
        <Table aria-label="simple table">
          <VuiTableHead
            columns={columns}
            options={options}
            onRequestSort={handleRequestSort}
          />
          {loading ? (
            <VuiTableLoading columns={columns} />
          ) : (
            <VuiTableBody data={data} columns={columns} propKey={propKey} />
          )}
        </Table>
      </TableContainer>

      <TablePagination
        labelRowsPerPage={"Show"}
        component="div"
        count={scopedOptions.total.get()}
        page={scopedOptions.page.get()}
        rowsPerPage={scopedOptions.perPage.get()}
        rowsPerPageOptions={scopedOptions.rowsPerPageOptions.get()}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default VuiTable;
