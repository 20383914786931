import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  cardContent: {
    padding: theme.spacing(4),

    '&:last-child': {
      paddingBottom: theme.spacing(4),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0),

      '&:last-child': {
        paddingBottom: theme.spacing(0),
      },
    },
  },
  contentWrapper: {
    background: 'white',
    position: 'relative',
    minHeight: `calc(100vh)`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: `${theme.spacing(6)}px 0px`,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
    },
  },
  cardPaper: {
    boxShadow: theme.shadows[3],
    [theme.breakpoints.down('sm')]: {
      boxShadow: 'none',
    },
    borderRadius: theme.spacing(1),
  },
  brandLogo: {
    height: 45,
  },
  title: {
    lineHeight: 1,
    fontWeight: 700,
    marginBottom: theme.spacing(1),
  },
  textLink: {
    '& .link': {
      color: theme.palette.secondary.main,
      textDecoration: 'none',
      marginLeft: 5,
    },
  },
  link: {
    color: theme.palette.secondary.main,
    textDecoration: 'none',
  },
  btnFacebook: {
    backgroundColor: '#3b5998',
    color: 'white',

    '&:hover': {
      backgroundColor: '#3b5998',
    },
  },
  btnGoogle: {
    backgroundColor: 'white',
    color: 'black',

    '& .icon': {
      height: 18,
    },

    '&:hover': {
      backgroundColor: 'white',
    },
  },
  divider: {
    margin: `${theme.spacing(2)}px 0px`,
  },
  textDivider: {
    display: 'flex',
    alignItems: 'center',

    '& .MuiDivider-root': {
      flex: 1,
    },
    '& .MuiTypography-root': {
      padding: theme.spacing(2),
    },
  },
  passwordInputWrapper: {
    position: 'relative',

    '& .icon-wrapper': {
      position: 'absolute',
      right: '10px',
      top: '58%',
      transform: 'translateY(-50%)',
      background: 'white',
      width: '25px',
    }
  }
}));
