import React, { CSSProperties, FC, useEffect } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
  createStyles({
    loadingWrapper: {
      height: "90vh",
      width: "100%",
      position: "relative",
      "& .loading": {
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%);",
      },
    },
  })
);

interface IMuiLoader {
  styles?: CSSProperties;
}

const MuiLoader: FC<IMuiLoader> = ({ styles }) => {
  const classes = useStyles();

  useEffect(() => {
    const loadingDom = document.getElementById("loading");
    if (loadingDom) {
      loadingDom.style.display = "none";
    }

    return () => {};
  }, []);

  return (
    <div className={classes.loadingWrapper} style={styles}>
      <img className={"loading"} src="/loading.svg" alt="loading" />
    </div>
  );
};

export default React.memo(MuiLoader);
