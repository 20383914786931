import { useState } from "@hookstate/core";
import {
  Box,
  Checkbox,
  Grid,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import TitleForm from "../../../../../components/molecules/TitleForm";
import useIsMounted from "../../../../../@VodeaUI/hooks/useIsMounted";
import {
  $clone,
  $cloneState,
  mapParams,
} from "../../../../../utilities/helpers/global";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { useLocation } from "react-router-dom";
import {
  optionLabel,
  optionSelected,
} from "../../../../../utilities/helpers/select";
import SelectRepository from "../../../../../repositories/SelectRepository";
import { AxiosError, AxiosResponse } from "axios";
import { EmploymentInterface } from "./interface";
import { makeStyles } from "@material-ui/core/styles";
import { KeyboardDatePicker } from "@material-ui/pickers";
import CurrencyFormat from "../../../../../@VodeaUI/components/Input/CurrencyFormat";
import moment from "moment";
import EmploymentDataRepository from "../../../../../repositories/EmploymentDataRepository";
import { axiosErrorSaveHandler } from "../../../../../utilities/helpers/axios-error.helper";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import FooterFormAction from "../../../../../components/FooterFormAction";
import { mapHookFormErrors } from "../../../../../utilities/helpers/hook-form.helper";

const useStyles = makeStyles({
  tableWrapper: {
    "& .MuiTableCell-sizeSmall": {
      padding: "6px 8px",
    },
  },
  datePicker: {
    marginBottom: 16,
  },
  loadingWrapper: {
    minHeight: 350,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#FFFFFF",
  },
  tableHead: {
    textTransform: "uppercase",
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
    fontWeight: 600,
    color: "#1A1818",
    width: "15%",
  },
  icon: {
    padding: "unset",
  },
  tableCell: {
    whiteSpace: "nowrap",
  },
  tableRow: {
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
    "& .aw": {
      overflow: "hidden",
    },
    "& .btnView": {
      opacity: "0",
      height: "34px",
      alignSelf: "center",
      padding: "0.325rem 2rem",
      textTransform: "capitalize",
      fontWeight: 400,
      boxShadow: "none",
      marginRight: "25px",
    },
    "&:hover": {
      backgoundColor: "red !important",
      "& .btnView": {
        opacity: "1 !important",
      },
    },
  },
});

const icon = <CheckBoxOutlineBlankIcon />;
const checkedIcon = <CheckBoxIcon />;

const optionName = [
  { id: "status", name: "Status" },
  { id: "position", name: "Position" },
  { id: "salary", name: "Salary" },
];

const Employment: React.FC<any> = () => {
  const location = useLocation();
  const isMounted = useIsMounted();
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [tableColumns, setTableColumns] = React.useState<any[]>([
    optionName[0],
  ]);
  const { enqueueSnackbar } = useSnackbar();
  const [users, setUsers] = React.useState<any[]>([]);

  const option = useState<EmploymentInterface>({
    employmentStatus: {
      options: [],
      loading: false,
      params: {},
      loadData: async () => {
        if (isMounted.current) {
          option.employmentStatus.loading.set(true);
        }
        await SelectRepository.employmentStatus(
          mapParams($cloneState(option.employmentStatus.params))
        )
          .then(({ data }: AxiosResponse) => {
            if (isMounted.current) {
              option.employmentStatus.options.set(data.data);
              option.employmentStatus.loading.set(false);
            }
          })
          .catch(() => {});
      },
      onOpen: () => {
        if (isMounted.current) {
          option.employmentStatus.options.set([]);
          option.employmentStatus.loadData.get()();
        }
      },
    },
    role: {
      options: [],
      loading: false,
      params: {},
      loadData: async () => {
        if (isMounted.current) {
          option.role.loading.set(true);
        }

        const department = getValues("department");

        await SelectRepository.role({
          ...mapParams($cloneState(option.role.params)),
          department: department?.id,
          with: ["department"],
        })
          .then(({ data }: AxiosResponse) => {
            const formattedData = data.data.map((item: any) => {
              return {
                departmentName: item?.department?.name || "",
                ...item,
              };
            });
            if (isMounted.current) {
              option.role.options.set(formattedData);
              option.role.loading.set(false);
            }
          })
          .catch(() => {});
      },
      onOpen: () => {
        if (isMounted.current) {
          option.role.options.set([]);
          option.role.loadData.get()();
        }
      },
    },
  });

  useEffect(() => {
    if (isMounted.current && location) {
      const { user }: any = location?.state;
      if (user && user.length > 0) {
        setUsers(user);
        user.map((item: any) => {
          employmentAppend(item);
        });
      }
    }
  }, [location]);

  const { handleSubmit, control, getValues, setError } = useForm({
    mode: "onChange",
  });

  const {
    fields: employmentFields,
    append: employmentAppend,
    remove: employmentRemove,
  } = useFieldArray({
    control,
    name: "users",
  });

  const onSubmit = (data: any) => {
    if (isMounted.current) setLoading(true);

    const formattedData: any = {
      valid_from: moment(data.valid_from).format("YYYY-MM-DD"),
      salaries: [],
      roles: [],
      employment_statuses: [],
    };

    if (data.salaries && data.salaries.length > 0) {
      const salaries = data.salaries.map((item: any, index: number) => {
        if (item.salary) {
          return {
            user_id: users[index]?.id,
            salary: item?.salary,
          };
        }
      });
      Object.assign(formattedData, {
        salaries,
      });
    }

    if (data.employment_statuses && data.employment_statuses.length > 0) {
      const employment_statuses = data.employment_statuses.map(
        (item: any, index: number) => {
          if (item?.employment_status_id) {
            return {
              user_id: users[index]?.id,
              employment_status_id: item?.employment_status_id?.id,
            };
          }
        }
      );
      Object.assign(formattedData, {
        employment_statuses,
      });
    }

    if (data.roles && data.roles.length > 0) {
      const roles = data.roles.map((item: any, index: number) => {
        if (item.role_id) {
          return {
            user_id: users[index]?.id,
            role_id: item?.role_id?.id,
          };
        }
      });
      Object.assign(formattedData, {
        roles,
      });
    }

    EmploymentDataRepository.bulkUpdate(formattedData)
      .then((response: AxiosResponse) => {
        if (isMounted.current) {
          setLoading(false);
          enqueueSnackbar(response.data.message, {
            variant: "success",
          });
          navigate("/apps/people/employee");
        }
      })
      .catch((error: AxiosError) => {
        if (isMounted.current) {
          setLoading(false);
        }
        if (error?.response?.data?.message) {
          const errors = mapHookFormErrors(error.response.data.message);
          Object.keys(errors).forEach((key: any) => setError(key, errors[key]));
        }
        if (
          error?.response?.data?.errors &&
          error?.response?.data?.errors.length
        ) {
          const flatten: any[] = Object.values(
            error?.response?.data?.errors
          ).flat();
          if (flatten.length > 0) {
            enqueueSnackbar(flatten[0], {
              variant: "error",
            });
          }
        } else {
          enqueueSnackbar(error.message, {
            variant: "error",
          });
        }
      });
  };

  const handleChangeField = (
    event: any,
    value: any,
    reason: any,
    details: any
  ) => {
    let temps: any[] = [...tableColumns];
    if (reason === "remove-option") {
      const findIndexItem = temps.findIndex(
        (temp: any) => temp.id === details.option.id
      );
      if (findIndexItem > -1) {
        temps.splice(findIndexItem, 1);
      }
    } else if (reason === "select-option") {
      temps.push(details.option);
    } else {
      temps = [];
    }
    setTableColumns(temps);
  };

  const renderEmployee = (item: any, index: number) => {
    return (
      <TableRow key={index} className={classes.tableRow}>
        <TableCell>{item?.name || "-"}</TableCell>
        <TableCell>{item?.nip || "-"}</TableCell>
        {tableColumns.map((item: any, itemIndex: number) => {
          return item.id === "position" ? (
            <TableCell key={itemIndex}>
              <Box marginTop={2} marginBottom={2}>
                <Controller
                  render={({ value, name, onChange }) => {
                    return (
                      <Autocomplete
                        value={value}
                        size="small"
                        loading={option.role.loading.get()}
                        options={$clone(option.role.options.get())}
                        onOpen={option.role.onOpen.get()}
                        groupBy={(option) =>
                          optionLabel(option, "departmentName")
                        }
                        getOptionLabel={(option: any) => optionLabel(option)}
                        getOptionSelected={(option: any, value: any) =>
                          optionSelected(option, value)
                        }
                        onChange={(event: any, newValue: any) => {
                          onChange(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name={name}
                            label="Position"
                            variant="outlined"
                          />
                        )}
                      />
                    );
                  }}
                  name={`roles[${index}].role_id`}
                  control={control}
                  defaultValue={null}
                />
              </Box>
            </TableCell>
          ) : item.id === "status" ? (
            <TableCell key={itemIndex}>
              <Box marginTop={2} marginBottom={2}>
                <Controller
                  name={`employment_statuses[${index}].employment_status_id`}
                  defaultValue={null}
                  control={control}
                  render={({ value, name, onChange }) => {
                    return (
                      <Autocomplete
                        value={value}
                        size="small"
                        loading={option.employmentStatus.loading.get()}
                        options={$clone(option.employmentStatus.options.get())}
                        onOpen={option.employmentStatus.onOpen.get()}
                        getOptionLabel={(option: any) => optionLabel(option)}
                        getOptionSelected={(option: any, value: any) =>
                          optionSelected(option, value)
                        }
                        onChange={(event: any, newValue: any) => {
                          onChange(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name={name}
                            label="Employment Status"
                            variant="outlined"
                          />
                        )}
                      />
                    );
                  }}
                />
              </Box>
            </TableCell>
          ) : (
            <TableCell key={itemIndex}>
              <Box marginTop={2} marginBottom={2}>
                <Controller
                  render={({ value, name, onChange }) => (
                    <TextField
                      name={name}
                      size="small"
                      label="Salary"
                      variant="outlined"
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                      value={value}
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">Rp </InputAdornment>
                        ),
                        inputComponent: CurrencyFormat,
                      }}
                    />
                  )}
                  name={`salaries[${index}].salary`}
                  control={control}
                  defaultValue={""}
                />
              </Box>
            </TableCell>
          );
        })}
      </TableRow>
    );
  };

  return (
    <>
      <Box p={1} mb={2}>
        <Grid
          container
          spacing={3}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <TitleForm title="Update Employment Data" withBackUrl={true} />
          <Controller
            name={"valid_from"}
            control={control}
            defaultValue={moment().add(1, "days")}
            render={({ value, name, onChange }) => {
              return (
                <KeyboardDatePicker
                  name={name}
                  disableToolbar
                  variant="inline"
                  inputVariant="outlined"
                  label="Effective On"
                  format="DD MMMM YYYY"
                  minDate={moment().add(1, "days")}
                  value={value}
                  onChange={onChange}
                  className={classes.datePicker}
                />
              );
            }}
          />
        </Grid>
      </Box>

      <Box mb={4}>
        <Grid container>
          <Grid item xs={12} md={12}>
            <Paper>
              <Box p={2}>
                <Grid container>
                  <Grid item xs={12} md={3}>
                    <Autocomplete
                      defaultValue={tableColumns}
                      multiple
                      renderTags={(value) => (
                        <Box marginLeft={0.5}>
                          {`${
                            value.length === optionName.length
                              ? "All"
                              : value.length
                          } Items Selected`}
                        </Box>
                      )}
                      options={optionName}
                      disableCloseOnSelect
                      getOptionLabel={(option) => optionLabel(option)}
                      onChange={handleChangeField}
                      renderOption={(option, { selected }) => (
                        <>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.name}
                        </>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Fields"
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box>
                <Grid container spacing={2}>
                  {employmentFields.length > 0 && (
                    <Grid item xs={12}>
                      <div className={classes.tableWrapper}>
                        <TableContainer>
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell className={classes.tableHead}>
                                  EMPLOYEE NAME
                                </TableCell>
                                <TableCell className={classes.tableHead}>
                                  EMPLOYEE ID
                                </TableCell>
                                {tableColumns.map((item, index) => {
                                  return (
                                    <TableCell
                                      key={index}
                                      className={classes.tableHead}
                                    >
                                      {item.name}
                                    </TableCell>
                                  );
                                })}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {employmentFields.map(renderEmployee)}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>

      <FooterFormAction
        cancelUrl={"/apps/people/employee"}
        loading={loading}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        labelForm={"Save Update Employment Data?"}
      />
    </>
  );
};

export default Employment;
