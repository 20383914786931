import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  statusWrapper: {
    textAlign: "left",

    "& div": {
      padding: "4px",
      borderRadius: "5px",
      fontSize: "12px",
      display: "inline-block",
    },
    "& .Approved": {
      backgroundColor: "rgba(158, 179, 153, 0.2)",
      color: "#9EB399",
    },
    "& .Completed": {
      backgroundColor: "rgba(158, 179, 153, 0.2)",
      color: "#9EB399",
    },
    "& .Pending": {
      backgroundColor: "rgba(255, 157, 0, 0.2)",
      color: "#FF9D00",
    },
    "& .Waiting": {
      backgroundColor: "rgba(255, 157, 0, 0.2)",
      color: "#FF9D00",
    },
    "& .Rejected": {
      backgroundColor: "rgba(255, 0, 0, 0.2)",
      color: "#ff0000",
    },
    "& .Declined": {
      backgroundColor: "rgba(255, 0, 0, 0.2)",
      color: "#ff0000",
    },
  },
}));
